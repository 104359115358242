<div class="accordion accordion-animated"
     [ngClass]="{closed: !isOpen}"
     *ngIf="entity">
  <button class="block accordion-toggle button" (click)="toggleOpen()" type="button">{{entity.name | unescapeHtml}}</button>
  <div class="clip" [@slideInOut]="isOpen ? 'visible' : 'hidden'">
    <table class="accordion-content secondary no-padding table-default table-border table-separated table-striped">
      <tbody style="border-top: none">
      <tr *ngFor="let child of entity | pureFunction:unwrapEntities">
        <td [style.padding-left.px]="child.indention" class="first-column">
          <a routerLink="/SiteManagement/SiteDetails" [queryParams]="{id:  child.entity.id}">{{child.entity.name | unescapeHtml}}</a>
        </td>
        <td class="icon">
          <a class="mat-icon-info text-hide info-button"
             [ngClass]="{active: (currentEntityId$ | async) === child.entity.id}"
             (click)="openEntitySideBar(child.entity)">Information</a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
