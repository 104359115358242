import {EntityViewData} from "./entity-view-data";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class EntityListViewData extends EntityViewData {
  get pageTitle(): string {
    return TitleBuilder.entities.list;
  }

  get url(): string {
    return UrlBuilder.entities.list;
  }

  readonly bodyClasses = ["page-entity-list"];
}
