<ng-container *ngIf="package && shipment">
  <span class="piece-count">{{package.packCount | rtInteger}} {{package.packCount === 1 ? "Piece" : "Pieces"}}</span>
  <table class="table-clean table-narrow">
    <tbody>
    <tr *ngIf="package.weightPerPiece;">
      <th>Weight per Piece</th>
      <td>{{package.weightPerPiece | rtDimension}}</td>
    </tr>
    <tr *ngIf="!package.weightPerPiece && package.weight">
      <th>Weight</th>
      <td>{{package.weight | rtDimension}}/td>
    </tr>
    <tr *ngIf="package.volumePerPiece;">
      <th>Volume per Piece</th>
      <td>{{package.volumePerPiece | rtDimension}}</td>
    </tr>
    <tr *ngIf="!package.volumePerPiece && package.volume">
      <th>Volume</th>
      <td>{{package.volume | rtDimension}}/td>
    </tr>
    <tr *ngIf="package.dimensionsPerPiece as dimensions">
      <th>Dimensions per Piece</th>
      <td>
        {{dimensions.length | rtDecimal:0}} x {{dimensions.width | rtDecimal:0}}
        x {{dimensions.height | rtDecimal:0}} {{dimensions.dimension}}
      </td>
    </tr>
    <tr *ngIf="(shipment | rtIsFcl | not) && package.dangerousGoods.any()">
      <th>Dangerous Goods</th>
      <td [rt-dangerous-goods-list]="package.dangerousGoods | rtGetDangerousGoods">
      </td>
    </tr>
    <tr *ngIf="package.goodsDescription | pureFunction:differsFromParentDescription">
      <th>Goods Description</th>
      <td>{{package.goodsDescription}}</td>
    </tr>
    <tr *ngIf="package.marksAndNumbers | pureFunction:differsFromParentMarksAndNumbers">
      <th>Marks &amp; Numbers</th>
      <td>{{package.marksAndNumbers}}</td>
    </tr>
    </tbody>
  </table>
</ng-container>
