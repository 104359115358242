import {PreLoginViewData} from "@services/view-data";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class LoginViewData extends PreLoginViewData {
  readonly returnUrl: string | null;
  readonly userHasBeenUnlocked: boolean;


  constructor();
  constructor(returnUrl: string);
  constructor(userHasBeenUnlocked: boolean);
  constructor(returnUrl?: string | boolean) {
    super();
    this.returnUrl = typeof returnUrl === "string" && returnUrl ? returnUrl : null;
    this.userHasBeenUnlocked = typeof returnUrl === "boolean" ? returnUrl : false;
  }

  hasReturnUrl(): boolean {
    return this.returnUrl !== null;
  }

  get pageTitle(): string {
    return TitleBuilder.account.login;
  }

  get url(): string {
    if (this.hasReturnUrl())
      return UrlBuilder.account.loginWithReturnUrl(this.returnUrl);
    if (this.userHasBeenUnlocked)
      return UrlBuilder.account.loginWithUnlocked;
    return UrlBuilder.account.login;
  }

  hasNewInformationComparedTo(compare: this): boolean {
    const returnUrlChanged = this.returnUrl !== compare.returnUrl;
    const userHasBeenUnlockedChanged = this.userHasBeenUnlocked !== compare.userHasBeenUnlocked;
    return userHasBeenUnlockedChanged || returnUrlChanged
  }

  readonly bodyClasses = ["page-pre-login", "page-login"];
}
