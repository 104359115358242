<img class="rohlig-logo" src="/content/images/Rohlig_blue.svg" width="100%" alt="Logo">
<figure class="realtime-logo logologin">
  <a [routerLink]="''">
    <img src="/content/images/Real Time_blue.svg" width="100%" alt="Logo">
  </a>
</figure>

<h1>Reset Your Password</h1>
<ul class="steps forgot-password">
  <li class="current">
    <p>
      <small>Step 1:</small>
      Enter email
    </p>
  </li>
  <li>
    <p>
      <small>Step 2:</small>
      Change password
    </p>
  </li>
</ul>
<p class="margin-bottom">Enter your email below to receive instructions on how to reset your password.</p>
<div class="wysiwyg form-center">
  <div class="form-group">
      <span class="form-label">
      <label for="email">Email</label>
      </span>
    <input
      type="email"
      class="form-control"
      id="email"
      required
      name="email"
      rtInputValidator
      (keydown.enter)="resetPassword()"
      [(ngModel)]="mailAddress">
  </div>
  <p class="buttons phone-auto-width">
    <button
      class="button"
      style="width: 95px"
      (click)="resetPassword()"
      [rtLoadingAnimation]="isRequestingToken"
      [preSized]="true">
      Next
    </button>
    <a [routerLink]="''" class="button secondary">Cancel</a>
  </p>
</div>
