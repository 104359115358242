<ng-container *ngIf="showConsolidation">
  <table class="table-clean" style="margin-top:10px">
    <tbody>
      <tr>
        <th>Consolidation</th>
        <td>
          <rt-consolidation-link [shipment]="consolidationLead" [is-lead]="true"></rt-consolidation-link>
        </td>
      </tr>
      <tr>
        <th>Consolidation Shipments</th>
        <td>
          <ul>
            <ng-container *ngFor="let consolidationShipment of standardConsolidationShipments; last as isLast;">
              <li class="consolidation-items">
                <ng-container *ngIf="consolidationShipment | pureFunction:shouldDisplayConsolidationLink: _shipment; else shipmentNoLink">
                  <rt-consolidation-link [shipment]="consolidationShipment"></rt-consolidation-link>
                </ng-container>
                <ng-template #shipmentNoLink>
                  <span [innerHTML]="consolidationShipment.houseBill | rtTbd"></span>
                </ng-template>
                <span *ngIf="!isLast">,&nbsp;</span>
              </li>
            </ng-container>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
