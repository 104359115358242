import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  // readonly bookingSites: JSONData[] = [];
  // readonly bookingCountries: JSONData[] =[];

  constructor() {
    // if (!data)
    //   return;
    // this.bookingSites = data.sites;
    // this.bookingCountries = data.countries;

  }
}
