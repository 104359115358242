<div class="page-profile">
  <div class="page-wrap">
    <main>
      <section *ngIf="userData$ | async as userData" class="padding" rtFormValidator>
        <div class="search-bar button-container">
          <span class="title desktop-visible" style="min-width: auto">
          Profile
          </span>
        </div>
        <div class="top">
          <div>
            <header>
              <h2>User Details</h2>
            </header>
            <p>
              <label>Name</label>
              {{userData.name}}
            </p>
            <p>
              <label>Email</label>
              <rt-html-container [html]="userData.mail.replace('@', '<wbr />@')"></rt-html-container>
            </p>
            <p>
              <label>Country</label>
              <rt-html-container [html]="userData.country | rtCountry"></rt-html-container>
            </p>
            <p>
              <label>User Type</label>
              {{userData.type | pureFunction:getUserTypeName}}
            </p>
            <p *ngIf="userData.type === userType.Client; else noClientPhone">
                                    <span class="form-label">
                                        <label class="">Telephone <span class="text-lighter">(optional)</span></label>
                                    </span>
              <rt-phone-number-select [fallbackCountryCode]="userData.country?.isoAlpha2" [storeAccessor]="storeAccessors.phone"></rt-phone-number-select>
            </p>
            <ng-template #noClientPhone>
              <label>Telephone</label>
              <span [innerHTML]="userData.phoneCountry | rtCountryFlag"></span> {{userData.phone}}
            </ng-template>
          </div>
          <div id="actions">
            <header>
              <h2>Profile Picture</h2>
            </header>
            <rt-avatar-editor [storeAccessor]="storeAccessors.profilePictureBase64"></rt-avatar-editor>
          </div>
          <div
            #changePasswordForm
            *ngIf="userData.type === userType.Client && !isDemo"
            [authenticationMode]="'oldPassword'"
            [storeAccessor]="storeAccessors.passwordChange"
            rt-change-password-form></div>
        </div>
        <div class="buttons phone-auto-width">
          <button (click)="save()" [disabled]="isSaving" [rtLoadingAnimation]="isSaving" class="button">Save</button>
          <button (click)="cancel()" class="button secondary">Cancel</button>
        </div>

        <!-- ################# ENTITIES ################# -->
        <div class="tables">
          <h2>Entities</h2>
          <rt-user-entity-permission-view [entitySets]="userData.availableEntitySets"></rt-user-entity-permission-view>
        </div>
      </section>
    </main>
  </div>
</div>

