import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ConsolidationShipment,
  Shipment,
} from "@api";

@Component({
  selector: 'rt-shipment-consolidation',
  templateUrl: './shipment-consolidation.component.html',
  styleUrls: ['./shipment-consolidation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShipmentConsolidationComponent{
  standardConsolidationShipments: ConsolidationShipment[];
  showConsolidation: boolean = false;
  consolidationLead: ConsolidationShipment;
  _shipment: Shipment;


  @Input('shipment')
  set Shipment(value: Shipment) {
    this._shipment = value;
    this.standardConsolidationShipments = this._shipment.consolidationShipments?.
      filter(s => s.shipmentType != "ASM");
    this.showConsolidation = this.standardConsolidationShipments.length > 1;
    this.consolidationLead = this._shipment.consolidationLead;
  }

  shouldDisplayConsolidationLink(consolidationShipment: ConsolidationShipment, currentShipment: Shipment): boolean {
    return currentShipment.id !== consolidationShipment.id;
  }
}
