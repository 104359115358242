// @ts-nocheck
import * as Settings from "./settings";
import { Signal } from "./Signal";



export module Viewport {


    let active = false;
    export let onChange = new Signal();

    export function _check(specification: Function | string | Array<Number>): boolean {
        /*
         * Returns `true` if given viewport specification (may be a function, array or media query string)
         * is active.
         */
        if (typeof specification === 'function')
            return specification();
        else if (specification instanceof Array && specification.length === 2 &&
            typeof specification[0] === 'number' && typeof specification[1] === 'number' &&
            specification[0] <= specification[1])
            return specification[0] <= width() && width() <= specification[1];
        else if (typeof specification === 'string') {
            if (!window.matchMedia)
                throw new Error('matchMedia is not available in this browser, please add a polyfill');
            return window.matchMedia(specification).matches;
        }
        throw new Error('Invalid viewport specification: ' + specification);
    }

    export function _changeViewport(newClass: string) {
        let previousClass = active || null;
        if (newClass !== active) {
            let VIEWPORT_CLASS_PREFIX = 'viewport-';
            if (active)
                window.document.body.classList.remove(VIEWPORT_CLASS_PREFIX + active);
            if (newClass)
                window.document.body.classList.add(VIEWPORT_CLASS_PREFIX + newClass);
            active = newClass;
            onChange.send(previousClass);
        }
    }

    export function _update() {
        let VIEWPORTS = Settings['VIEWPORTS']
        let newClass: string = null;

        for (let alias in VIEWPORTS)
            if (_check(VIEWPORTS[alias])) {
                newClass = alias;
                break;
            }

        _changeViewport(newClass);
    }

    export function _is(alias: string): boolean {
        /*
         * Returns `true` if given `alias` is currently active. `alias` must be one of the viewport names defined
         * in settings.
         */
        let VIEWPORTS = Settings['VIEWPORTS'];
        if (alias in VIEWPORTS)
            return alias === active;
        throw new Error('Unknown viewport alias: ' + alias);
    }

    export function is(aliases: string): boolean {
        if (!isEnabled())
            throw new Error('You must call `viewport.enable()` first to use `viewport.is()`');
        /*
         * Returns `true` if any of the aliases is currently active. `aliases` must be a string with any
         * viewport names defined in settings, separated by space.
         */
        let aliasesArray = aliases.split(' ');
        for (let i = 0; i < aliasesArray.length; i++)
            if (_is(aliasesArray[i]))
                return true;
        return false;
    }

    export function width(): number {
        /*
         * Returns window width, excluding toolbars and scrollbars.
         */
        return window.innerWidth || window.document.documentElement.clientWidth;
    }

    export function height(): number {
        /*
         * Returns window height, excluding toolbars and scrollbars.
         */
        return window.innerHeight || window.document.documentElement.clientHeight;
    }

    export function enable() {
        if (active)
          return;
        active = true;
        _update();
        window.addEventListener('resize', _update);
    }

    export function disable() {
      if (!active)
        return;
      active = false;
        window.removeEventListener('resize', _update);
    }

    export function isEnabled(): boolean {
        return active;
    }

}
