import {DeliveryStatus, EntityDataPeriod, OrderStatus, UserActivationStatus, UserGroup, UserGroupSearch, UserType} from "@api";

type EnumMapper<T extends string, TR = string> = { [P in T]: TR }

export const deliveryStatusDisplayMapper: EnumMapper<DeliveryStatus> = {
  [DeliveryStatus.Booked]: "Booked",
  [DeliveryStatus.InTransit]: "In Transit",
  [DeliveryStatus.Delivered]: "Delivered",
  [DeliveryStatus.Canceled]: "Canceled",
}

export const deliveryStatusClassMapper: EnumMapper<DeliveryStatus> = {
  [DeliveryStatus.Delivered]: "delivered",
  [DeliveryStatus.Booked]: "booked",
  [DeliveryStatus.InTransit]: "in-transit",
  [DeliveryStatus.Canceled]: "canceled",
}


export const orderStatusDisplayMapper: EnumMapper<OrderStatus> = {
  [OrderStatus.Delivered]: "Delivered",
  [OrderStatus.PartiallyDelivered]: "Partially Delivered",
  [OrderStatus.Placed]: "Placed",
  [OrderStatus.Booked]: "Booked",
  [OrderStatus.PartiallyBooked]: "Partially Booked",
  [OrderStatus.InTransit]: "In Transit",
  [OrderStatus.PartiallyInTransit]: "Partially In Transit",
  [OrderStatus.Canceled]: "Canceled"
}

export const orderStatusClassMapper: EnumMapper<OrderStatus> = {
  [OrderStatus.Delivered]: "delivered",
  [OrderStatus.PartiallyDelivered]: "delivered",
  [OrderStatus.Placed]: "booked",
  [OrderStatus.Booked]: "booked",
  [OrderStatus.PartiallyBooked]: "booked",
  [OrderStatus.InTransit]: "in-transit",
  [OrderStatus.PartiallyInTransit]: "in-transit",
  [OrderStatus.Canceled]: "canceled"
}

export const userTypeMapper: EnumMapper<UserType> = {
  [UserType.SuperUser]: "Super user",
  [UserType.Client]: "Client",
  [UserType.Rohlig]: "Röhlig User"
}

export const userGroupMapper: EnumMapper<UserGroup> = {
  [UserGroup.Standard]: "Standard",
  [UserGroup.LocalAdmin]: "Local Real Time Admin",
  [UserGroup.GlobalAdmin]: "Global Real Time Admin",
  [UserGroup.SupportTeam]: "Real Time Support Team",
  [UserGroup.AccountManager]: "Account Manager",
}

export const userGroupSearchMapper: EnumMapper<UserGroupSearch> = {
  [UserGroupSearch.Client]: "Client",
  [UserGroupSearch.Standard]: "Standard",
  [UserGroupSearch.SupportTeam]: "Real Time Support Team",
  [UserGroupSearch.AccountManager]: "Account Manager",
  [UserGroupSearch.LocalAdmin]: "Local Real Time Admin",
  [UserGroupSearch.GlobalAdmin]: "Global Real Time Admin",
  [UserGroupSearch.SuperUser]: "Super User"
}

export const dataPeriodMapper: EnumMapper<EntityDataPeriod> = {
  [EntityDataPeriod.ThreeMonths]: "3 Months",
  [EntityDataPeriod.SixMonths]: "6 Months",
  [EntityDataPeriod.OneYear]: "1 Year",
  [EntityDataPeriod.TwoYears]: "2 Years"
}

export const userActivationStatusMapper: EnumMapper<UserActivationStatus> = {
  [UserActivationStatus.Activated]: "Activated",
  [UserActivationStatus.Deactivated]: "Deactivated"
}
