<header>
  <h2 data-role="booking-mail-label" [ngClass]="{optional: hasBookingMailAddresses$ | async | not}">{{heading}}</h2>
</header>
<div class="tables">
  <div data-role="repeater">
      <div class="input-group form-group stretch"
           [ngClass]="{ghost: !mailAddress?.address}"
           style="display: flex;"
           *ngFor="let mailAddress of mailAddresses; index as index">
        <input
          type="text"
          (blur)="lineBlur()"
          (input)="lineInput()"
          [(ngModel)]="mailAddress.address"
          (focusin)="focusedItem = mailAddress"
          (focusout)="focusedItem = null"
          [attr.required]="(hasBookingMailAddresses$ | async) && index === 0
?'Please provide at least one email address for this scenario.'
: null"
          [attr.data-error-message-element]="'#booking-mail-list-error-message-' + controlIndex"
          rtInputValidator
        />
        <span class="input-appendix" style="padding-left: 5px;">@rohlig.com</span>

        <span class="remove-button"
              (click)="removeItem(mailAddress)">
        <span class="material-icons no-selector">delete</span>
      </span>
      </div>
    </div>
  <span class="error-message" id="booking-mail-list-error-message-{{controlIndex}}"></span>
</div>

