import {Component, ElementRef, Inject, Input, OnInit} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Component({
             selector: 'rt-html-container',
             templateUrl: './html-container.component.html',
             styleUrls: ['./html-container.component.scss']
           })
export class HtmlContainerComponent implements OnInit {
  private readonly _elementRef: ElementRef;

  private readonly _document: Document;
  private readonly _anchorComment: Comment;
  private _children: ChildNode[] = null;

  constructor(elementRef: ElementRef, @Inject(DOCUMENT) document: Document) {
    this._document = document;
    this._elementRef = elementRef;
    this._anchorComment = this._document.createComment("rt-html-container-anchor")

  }

  private _html: string | null = null;

  get html(): string | null {
    return this._html;
  }

  @Input()
  set html(value: string) {
    if (value === this._html)
      return;
    this._html =  value;
    let uninitialized = !this._anchorComment.parentNode;
    if (uninitialized)
      return
    this._replaceHtmlContent(value)
  }

  ngOnInit(): void {
    const element = this._elementRef.nativeElement as HTMLElement;
    element.parentNode.insertBefore(this._anchorComment, element);
    element.remove();
    if (this._html)
      this._replaceHtmlContent(this._html)
  }

  private _replaceHtmlContent(value: string) {
    let childrenExist = !!this._children;
    if (childrenExist) {
      this._children.forEach(e => e.remove());
    }
    const elementForParsing = this._document.createElement("div");
    elementForParsing.innerHTML = value;
    const fragment = this._document.createDocumentFragment();
    Array.from(elementForParsing.childNodes)
         .forEach(e => fragment.appendChild(e))
    this._children = Array.from(fragment.childNodes);
    this._anchorComment.parentNode.insertBefore(fragment, this._anchorComment)
  }
}
