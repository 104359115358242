import {ShipmentViewData} from "@services/view-data";
import {normalizeId} from "@utils/normalize-Id";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";
import {JobType} from "@api";

export class ShipmentDetailViewData extends ShipmentViewData {
  readonly shipmentId: string | null;
  readonly jobType: JobType;


  constructor(shipmentId: string, jobType: JobType) {
    super();
    this.shipmentId = normalizeId(shipmentId);
    this.jobType = jobType;
  }

  hasNewInformationComparedTo(compare: this): boolean {
    const idChanged = this.shipmentId !== compare.shipmentId;
    return idChanged;
  }

  get pageTitle(): string {
    // if (this.hasDefinedTitle())
    //   return TitleBuilder.shipment.shipmentDetail(this.shipmentTitle);
    return TitleBuilder.shipment.list;
  }

  get url(): string {
    return UrlBuilder.shipment.shipmentDetail(this.shipmentId!, this.jobType);
  }

  isValid(): boolean {
    return this.shipmentId !== null && this.jobType !== null;
  }

  readonly bodyClasses = ["page-shipment"];
}


