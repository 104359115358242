import {Injectable} from "@angular/core";
import {UserStore} from "@stores/user-store.service";
import {EntityApi, UserApi} from "../api/Apis";
import {RoutingService} from "@app/services/routing.service";
import {AccountStore, UserRightsStore} from "@stores";
import {UserSearchUiStore} from "@app/page-components/account/user-list/user-search-bar/user-search-ui-store.service";
import {debounceTime, filter, skip, take} from "rxjs/operators";
import {hasValue, TypeFilter} from "@utils/rxjs-extensions";
import {UserDetailViewData, UserEditViewData, UserListViewData} from "@view-data";
import {
  EntityPermissions,
  EntityRightsTemplate,
  EntitySetPermissions,
  User,
  UserReference,
  UserSaveRequest, UserSearch,
  UserSearchResult,
  UserType
} from "@api";
import {AlertService} from "@services/alert.service";
import {runRequestWithoutProgress} from "@app/interceptors";
import {ExternalSearchResult} from "@widgets/SelectList";
import {not} from "@utils/rxjs-extensions/not";
import {Subject} from "rxjs";

@Injectable({
              providedIn: 'root'
            })
export class UserService {
  readonly userStore: UserStore;
  private readonly _userApi: UserApi;
  private readonly _routingService: RoutingService;
  private readonly _alertService: AlertService;
  private readonly _entityApi: EntityApi;
  private readonly _userRightsStore: UserRightsStore;

  private readonly _searchRequestSubject = new Subject<Partial<UserSearch>>();
  private _searchCount = 0

  constructor(userApi: UserApi,
              userStore: UserStore,
              userRightsStore: UserRightsStore,
              accountStore: AccountStore,
              userSearchUiStore: UserSearchUiStore,
              routingService: RoutingService,
              alertService: AlertService,
              entityApi: EntityApi) {
    this._userRightsStore = userRightsStore;
    this._entityApi = entityApi;
    this._alertService = alertService;
    this._routingService = routingService;
    this.userStore = userStore;
    this._userApi = userApi

    routingService
      .routingStore
      .observe("currentViewData")
      .pipe(filter(TypeFilter(UserDetailViewData, UserEditViewData)))
      .subscribe((v: UserDetailViewData) => {
        void this._loadUser(v);
      })

    routingService
      .routingStore
      .observe("currentViewData")
      .pipe(filter(not(TypeFilter(UserDetailViewData, UserEditViewData))))
      .subscribe((v: UserDetailViewData) => {
        userStore.update("currentUser", null);
      })
    routingService
      .routingStore
      .observe("currentViewData")
      .pipe(filter(TypeFilter(UserListViewData)))
      .subscribe(() => {
        void this._loadUserList()
      })
    this._searchRequestSubject
        .pipe(debounceTime(50))
        .subscribe(s => void this._search(s))
    userStore
      .observe("currentSearch")
      .pipe(
        skip(1),
        filter(() => userRightsStore.get("isAuthenticated")),
        debounceTime(50)
      )
      .subscribe(s => {
        if (routingService.getCurrentViewData() instanceof UserListViewData)
          this._searchRequestSubject.next(s);
        else
          this.userStore.update("userList", null)
      });
    userRightsStore
      .observe("isAuthenticated")
      .pipe(filter(u => u === false))
      .subscribe(() => this.userStore.reset());
    accountStore
      .observe("currentUserSearch")
      .pipe(filter(hasValue))
      .subscribe(s => {
        userStore.update("currentSearch", s);
        delete s.topCount;
        const emptySearch: UserSearch = {
          userGroups: [],
          countryCodes: [],
          activationStatus: null,
          entityIds: [],
          reference: ""
        }
        userSearchUiStore.update(Object.assign(emptySearch, s))
      })
    accountStore
      .observe("currentUserSearchEntities")
      .subscribe(e => userSearchUiStore.update("entitySetItemsCache", e))
  }

  private static _prepareUserSaveRequest(user: UserSaveRequest): void {
    user.deactivated = !!user.deactivated;
    user.locked = !!user.locked;
  }

  get(id: string): Promise<User | null> {
    return this._userApi.userDetail(id).toPromise();
  }

  startSearch(searchObject: Partial<UserSearch>): void {
    this.userStore.update("currentSearch", searchObject);
    this._searchRequestSubject.next(searchObject)
  }

  loadMore(): void {
    this.userStore.update(s => {
      return {
        searchResultDisplayCount: Math.min(s.searchResultDisplayCount + 24, s.userList.length)
      }
    });
  }

  async sendWelcomeMail(user: User): Promise<void> {
    try {
      const savedUser = await this._userApi.sendWelcomeMail(user.id).toPromise();
      this._alertService.openDefaultSuccessAlert(`Welcome Mail has been sent to ${user.mailAddress}.`);
      if (this.userStore.get("currentUser")?.id === savedUser.id)
        this.userStore.update("currentUser", savedUser);
    } catch (e) {
      console.error(e)
    }
  }

  async setNewPassword(user: User): Promise<void> {
    try {
      const savedUser = await this._userApi.createNewPasswordAndNotify(user.id).toPromise()
      this._alertService.openDefaultSuccessAlert(`Password Mail has been sent to ${user.mailAddress}.`);
      if (this.userStore.get("currentUser")?.id === savedUser.id)
        this.userStore.update("currentUser", savedUser);
    } catch (e) {
      console.error(e)
    }
  }

  async delete(user: User): Promise<void> {
    try {
      await this._userApi.deleteUser(user.id).toPromise()
      this.userStore.update(state => {
        return {
          userList: state.userList?.filter(u => u.id !== user.id) || null
        }
      })
      this._routingService.navigateTo(new UserListViewData());
      this._alertService.openDefaultSuccessAlert(`User ${user.mailAddress} has been deleted.`, false);
    } catch (e) {
      console.error(e)
    }
  }

  async create(user: UserSaveRequest, sendWelcomeMail: boolean, sendPasswordMail: boolean): Promise<void> {
    try {
      UserService._prepareUserSaveRequest(user)
      const savedUser = await this._userApi.createUser(sendWelcomeMail, sendPasswordMail, user).toPromise()
      if (sendWelcomeMail && sendPasswordMail)
        this._alertService.openDefaultSuccessAlert("Welcome and Password Mails have been sent.", false);
      else if (sendWelcomeMail)
        this._alertService.openDefaultSuccessAlert("Welcome Mail has been sent.", false);
      else if (sendPasswordMail)
        this._alertService.openDefaultSuccessAlert("Password Mail has been sent.", false);
      else
        this._alertService.openDefaultSuccessAlert(`User ${user.mailAddress} created.`, false);
      this.userStore.update({
                              userList: null,
                              currentUser: savedUser,
                            })
      this._routingService.navigateTo(new UserDetailViewData(savedUser.id));
    } catch (e) {
      console.error(e)
    }

  }

  async update(user: UserSaveRequest): Promise<void>;
  async update(id: string, user: UserSaveRequest): Promise<void>;
  async update(id: string | UserSaveRequest, user?: UserSaveRequest): Promise<void> {
    if (typeof id !== "string") {
      user = id;
      id = this.userStore.get("currentUser")?.id;
    }
    try {
      UserService._prepareUserSaveRequest(user);
      const savedUser = await this._userApi.updateUser(id, user).toPromise()
      this.userStore.update({
                              userList: this.updateUserListEntry(savedUser),
                              currentUser: savedUser,
                            })
      this._routingService.navigateTo(new UserDetailViewData(savedUser.id));
    } catch (e) {
      console.error(e)
    }
  }

  updateUserListEntry(user: User): UserSearchResult[] {
    const userList = this.userStore.get("userList");
    if (!userList)
      return null;
    const userListEntry = userList.firstOrDefault(u => u.id === user.id)
    if (!userListEntry)
      return userList;
    userListEntry.country = user.country;
    userListEntry.mailAddress = user.mailAddress;
    userListEntry.createdAt = user.createdAt;
    userListEntry.deactivated = user.deactivated;
    userListEntry.entitySetNames = user.entitySetPermissions
                                       .orderBy(e => e.name)
                                       .map(e => e.name);
    userListEntry.firstName = user.firstName;
    userListEntry.lastName = user.lastName;
    userListEntry.group = user.group;
    userListEntry.lastUpdatedAt = user.lastUpdatedAt;
    userListEntry.locked = user.locked;
    return userList;
  }

  getLocalAdminsByCountry(...countryCodes: string[]): Promise<UserReference[]> {
    return this._userApi.getLocalAdminsByCountry(countryCodes).toPromise();
  }

  searchEntitySetRightsTemplates(search: string): Promise<EntityRightsTemplate[]> {
    return runRequestWithoutProgress(this._entityApi.searchEntitySetRightTemplates(search));
  }

  convertToEntityRightsTemplate(permissions: EntitySetPermissions | EntityPermissions): EntityRightsTemplate {
    return {
      name: permissions.name,
      id: permissions.id,
      canBeManagedByCurrentUser: permissions.canBeManagedByCurrentUser,
      hasBookingMailsDefined: permissions.bookingAvailable,
      subEntities: permissions.childEntities.map(t => this.convertToEntityRightsTemplate(t))
    }
  }

  convertToEntitySetPermissions(template: EntityRightsTemplate): EntitySetPermissions {
    const loggedInAsSuperUser = this._userRightsStore.get("isSuperUser");
    const currentUser = this.userStore.get("currentUser");
    const userCanHaveBookingRights = currentUser.type !== UserType.Rohlig;
    return {
      name: template.name,
      id: template.id,
      canBeManagedByCurrentUser: template.canBeManagedByCurrentUser,
      documents: true,
      messaging: loggedInAsSuperUser,
      exceptions: true,
      products: true,
      exportToExcel: true,
      bookingAvailable: template.hasBookingMailsDefined,
      bookings: template.hasBookingMailsDefined && userCanHaveBookingRights,
      invoiceDocuments: true,
      orders: true,
      shipments: true,
      childEntities: template.subEntities.map(t => this._convertToEntityPermissions(t))
    }
  }

  getCurrentUserId(): string | null {
    return this.userStore.get("currentUser")?.id || null
  }

  async searchAccountManagers(search: string): Promise<ExternalSearchResult<JSONDataContainer<UserReference>>> {
    const userReferences = await this._userApi.searchGlobalAdmins(search).toPromise();
    const results = userReferences as JSONDataContainer<UserReference>[];
    return {results}
  }

  private async _search({
                          countryCodes,
                          activationStatus, entityIds, userGroups, reference, topCount
                        }: Partial<UserSearch>): Promise<void> {
    this.userStore.update({isSearching: true});
    const currentSearchCount = ++this._searchCount;
    const result = await this._userApi.searchUsers(countryCodes, entityIds, userGroups, activationStatus, reference, topCount, true)
                             .toPromise();
    const newSearchStartedDuringRequest = currentSearchCount !== this._searchCount;
    if (newSearchStartedDuringRequest)
      return;
    this.userStore.update({
                            userList: result,
                            searchResultDisplayCount: Math.min(result.length, 24),
                            isSearching: false
                          });
  }

  private async _backgroundSearch({
                                    countryCodes,
                                    activationStatus, entityIds, userGroups, reference, topCount
                                  }: Partial<UserSearch>): Promise<void> {
    const currentSearchCount = this._searchCount;
    const result = await this._userApi.searchUsers(countryCodes, entityIds, userGroups, activationStatus, reference, topCount, false)
                             .toPromise();
    const newSearchStartedDuringRequest = currentSearchCount !== this._searchCount;
    if (newSearchStartedDuringRequest)
      return;
    this.userStore.update(u => {
      return {
        userList: result,
        searchResultDisplayCount: Math.min(u.searchResultDisplayCount || 0, result.length)
      }
    });
  }

  private async _loadUser(viewData: UserDetailViewData | UserEditViewData): Promise<void> {
    if (viewData instanceof UserEditViewData && viewData.isNewUser()) {
      this.userStore.update("currentUser", {});
      return;
    }
    const userPreloaded = this.userStore.get("currentUser")?.id === viewData.userId;
    if (userPreloaded)
      return;
    this.userStore.update("currentUser", null);
    const user = await this.get(viewData.userId);
    this.userStore.update("currentUser", user);
  }

  private async _loadUserList() {
    if (this.userStore.hasValue("userList"))
      return;
    const search = this.userStore.get("currentSearch");
    this._searchRequestSubject.next(search);
    const isInitialSearch = typeof search.topCount === "number";
    if (isInitialSearch) {
      await this.userStore
                .observe("isSearching")
                .pipe(
                  filter(s => s),
                  take(1))
                .toPromise()
      search.topCount = null;
      // noinspection ES6MissingAwait
      void this._backgroundSearch(search)
    }
  }

  private _convertToEntityPermissions(template: EntityRightsTemplate): EntityPermissions {
    const currentUser = this.userStore.get("currentUser");
    const userCanHaveBookingRights = currentUser.type !== UserType.Rohlig;
    return {
      name: template.name,
      id: template.id,
      canBeManagedByCurrentUser: template.canBeManagedByCurrentUser,
      bookingAvailable: template.hasBookingMailsDefined,
      bookings: template.hasBookingMailsDefined && userCanHaveBookingRights,
      invoiceDocuments: true,
      orders: true,
      shipments: true,
      childEntities: template.subEntities.map(t => this._convertToEntityPermissions(t))
    }
  }
}

