import {ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import {of, ReplaySubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {Container, JobType, Shipment, ShipmentMapLocation} from "@api";
import {ContainerApi, ShipmentApi} from "../../../../../api/Apis";
import {GoogleMap} from "@angular/google-maps";
import {GoogleMapsStyles} from "@app/page-components/shipment/shipment-detail/shipment-map/googleMapsStyles";
import detect = require("@utils/detect");
import {ContainerService} from "@services/container.service";

export interface MapLine {
  PointA: ShipmentMapLocation
  PointB: ShipmentMapLocation;
}


@Component({
             selector: 'rt-shipment-map',
             templateUrl: './shipment-map.component.html',
             styleUrls: ['./shipment-map.component.scss']
           })
export class ShipmentMapComponent {

  private static readonly _apiLoadedSubject = new ReplaySubject<boolean>(1);
  private static _apiLoadingStarted = false
  readonly apiLoaded$ = ShipmentMapComponent._apiLoadedSubject.asObservable();
  readonly mapOptions = GoogleMapsStyles.mapOptions;
  readonly traveledPathOptions = GoogleMapsStyles.traveledPathStyle;
  readonly toGoPathOptions = GoogleMapsStyles.toGoPathStyle;
  locations: Required<ShipmentMapLocation>[];
  lastLocations: Required<ShipmentMapLocation>[];
  traveledPaths: google.maps.LatLngLiteral[][];
  toGoPaths: google.maps.LatLngLiteral[][];

  private readonly _shipmentApi: ShipmentApi;

  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _containerApi: ContainerApi;
  private _markerZIndex = 0;

  constructor(httpClient: HttpClient, shipmentApi: ShipmentApi, changeDetectorRef: ChangeDetectorRef, containerApi: ContainerApi) {
    this._containerApi = containerApi;
    this._changeDetectorRef = changeDetectorRef;
    this._shipmentApi = shipmentApi;
    ShipmentMapComponent._loadApi(httpClient);
  }

  private _googleMap: GoogleMap;

  @ViewChild(GoogleMap)
  set googleMap(value: GoogleMap) {
    this._googleMap = value;
    if (value)
      this._mapLoaded();
  }

  @Input()
  set shipment(value: Shipment | Container) {
    if ("id" in value)
      void this._loadShipmentMapData(value.id, value.jobType);
    if (ContainerService.isContainer(value))
      void this._loadContainerMapData(value.containerId);
  }

  private static _loadApi(httpClient: HttpClient) {
    if (this._apiLoadingStarted)
      return;
    this._apiLoadingStarted = true;
    if (detect.browser.ie) {
      // eslint-disable-next-line
      (window as any).mapsLoaded = () => this._apiLoadedSubject.next(true);
      const script = document.createElement("script");
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDi4I3ACY6M7-AzqBlMWLVe71EsobPapK0&callback=mapsLoaded"
      document.body.appendChild(script);
    } else {
      httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyDi4I3ACY6M7-AzqBlMWLVe71EsobPapK0', "callback")
                .pipe(
                  map(() => true),
                  catchError(() => of(false)),
                )
                .subscribe(v => this._apiLoadedSubject.next(v));
    }
  }

  readonly getMarkerOptions = (location: Required<ShipmentMapLocation>,
                               iconMapper: Func1<ShipmentMapLocation, google.maps.Icon>): google.maps.MarkerOptions => ({
    position: location,
    icon: iconMapper(location),
    zIndex: this._markerZIndex++
  });

  getIconForLocation(location: ShipmentMapLocation): google.maps.Icon {
    return {
      url: location.completed ? GoogleMapsStyles.markerIcons.default : GoogleMapsStyles.markerIcons.grey,
      size: new google.maps.Size(30, 30),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(14, 14)
    }
  }


  getIconForEndLocation(location: ShipmentMapLocation): google.maps.Icon {
    return {
      url: location.completed ? GoogleMapsStyles.markerIcons.complete : GoogleMapsStyles.markerIcons.end,
      size: new google.maps.Size(38, 46),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(14, 29)
    }
  }

  private _mapLoaded() {
    this._googleMap.mapTypes.set("map_style", new google.maps.StyledMapType(GoogleMapsStyles.mapStyles, {name: "TnT"}));
    this._googleMap.googleMap.setMapTypeId("map_style");
    this._setupMapView();
  }

  private async _loadShipmentMapData(id: string, jobType: JobType) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    const locations = await this._shipmentApi.getMapGeoData(jobType, id).toPromise() as Required<ShipmentMapLocation>[];
    this._loadMapData([locations]);
  }

  private _getDistinctLocations(locations: Required<ShipmentMapLocation>[]) {
    const distinctLocations: Required<ShipmentMapLocation>[] = [];

    locations.forEach((l, i) => {
      let existingLocations = distinctLocations.filter((el) => { return el.lat === l.lat && el.lng == l.lng })
      if (existingLocations.length > 0) {
        existingLocations[0].completed = existingLocations[0].completed && l.completed;
      } else {
        distinctLocations.push(l);
      }
    });

    return distinctLocations;
  }

  private _locationsCoincide(a: ShipmentMapLocation, b: ShipmentMapLocation) {
    return a.lat === b.lat && a.lng === b.lng;
  } 

  private _loadMapData(locationsList: Required<ShipmentMapLocation>[][]): void {
    this.locations = [];
    this.lastLocations = [];
    this.traveledPaths = [];
    this.toGoPaths = [];

    const mapLines: MapLine[] = [];
    const lineExists = (a: ShipmentMapLocation, b: ShipmentMapLocation) => {
      return mapLines.some(link => this._locationsCoincide(link.PointA, a) && this._locationsCoincide(link.PointB, b));
    };

    locationsList?.forEach(loc => {
      this.locations = this.locations.concat(loc);
      this.lastLocations.push(loc.last());
      const numberOfLocations = loc.length;

      const toGoPath: google.maps.LatLngLiteral[] = [];
      loc.forEach((l, i, a) => {
        if (i >= numberOfLocations - 1)
          return;

        const next = a[i + 1];
        if (lineExists(l, next))
          return;

        if (!l.completed || !next.completed) {
          toGoPath.push(l);
          toGoPath.push(next);
          mapLines.push({ PointA: l, PointB: next });
        }
      });

      this.toGoPaths.push(toGoPath);
    });

    locationsList?.forEach(loc => {
      const numberOfLocations = loc.length;
      const traveledPath: google.maps.LatLngLiteral[] = [];

      loc.forEach((l, i, a) => {
        if (i >= numberOfLocations - 1)
          return;

        const next = a[i + 1];
        if (lineExists(l, next))
          return;

        if (l.completed && next.completed) {
          traveledPath.push(l);
          traveledPath.push(next);
          mapLines.push({ PointA: l, PointB: next });
        }
      });

      this.traveledPaths.push(traveledPath);
    });

    this.locations = this._getDistinctLocations(this.locations);
    this.lastLocations = this._getDistinctLocations(this.lastLocations);
    this._changeDetectorRef.detectChanges();
    this._setupMapView();
  }

  private async _loadContainerMapData(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    const locations = await this._containerApi.getMapGeoData(id).toPromise() as Required<ShipmentMapLocation>[][];
    this._loadMapData(locations);
  }

  private _setBounds() {
    const notInitialized = !this._googleMap || !this.locations;
    if (notInitialized)
      return;
    const bounds = new google.maps.LatLngBounds();
    this.locations.forEach(l => bounds.extend(l));
    this._googleMap.fitBounds(bounds)
    window.dispatchEvent(new CustomEvent("layout"));
  }

  private _setupMapView() {
    this._setBounds();
  }
}

