import { HubConnection, IStreamResult } from "@microsoft/signalr"

export class ConnectionHub {
    constructor(private connection: HubConnection) {
    }

    getConnectedUsers(): Promise<User[]> {
        return this.connection.invoke('GetConnectedUsers');
    }

    registerCallbacks(implementation: IConnectionHubCallbacks) {
        this.connection.on('ClientConnected', (mailAddress, connectionId) => implementation.clientConnected(mailAddress, connectionId));
        this.connection.on('ClientDisconnected', (mailAddress, connectionId) => implementation.clientDisconnected(mailAddress, connectionId));
        this.connection.on('EntitySetChanged', (info) => implementation.entitySetChanged(info));
    }

    unregisterCallbacks(implementation: IConnectionHubCallbacks) {
        this.connection.off('ClientConnected', (mailAddress, connectionId) => implementation.clientConnected(mailAddress, connectionId));
        this.connection.off('ClientDisconnected', (mailAddress, connectionId) => implementation.clientDisconnected(mailAddress, connectionId));
        this.connection.off('EntitySetChanged', (info) => implementation.entitySetChanged(info));
    }
}

export interface IConnectionHubCallbacks {
    clientConnected(mailAddress: string, connectionId: string): void;
    clientDisconnected(mailAddress: string, connectionId: string): void;
    entitySetChanged(info: EntityInfo): void;
}

export interface User {
    id: string | undefined;
    email: string | undefined;
    currentPage: string | undefined;
    currentBrowser: string | undefined;
    userId: string | undefined;
}

export interface EntityInfo {
    name: string;
    id: string;
    hasShipmentRights: boolean;
    hasOrderRights: boolean;
    hasBookingRights: boolean;
    hasInvoiceRights: boolean;
    subSites: EntityInfo[];
}