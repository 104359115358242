

interface IReceiver {
    receiver: Function;
    sender?: JQueryEventObject;
    context?: string;
}

export class Signal {
    receivers = new Array<IReceiver>();

    constructor() {
        this.connect = this.connect.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.send = this.send.bind(this);
    }

    connect(receiver: Function | IReceiver) {
        let kwargs = typeof receiver === 'function' ? { 'receiver': receiver } : receiver;
        if (typeof kwargs.receiver !== 'function')
            throw new Error("can't connect receiver to a signal, receiver is not a function");
        for (let i = 0; i < this.receivers.length; i++)
            if (this.receivers[i].receiver === kwargs.receiver && this.receivers[i].context === kwargs.context && this.receivers[i].sender === kwargs.sender)
                throw new Error("can't connect receiver to a signal, this receiver with same context and same sender is already connected");
        this.receivers.push({
            'receiver': kwargs.receiver,
            'sender': kwargs.sender,
            'context': kwargs.context
        });
    }

    disconnect(receiver: Function | IReceiver) {
        receiver = typeof receiver === 'function' ? receiver : receiver.receiver;
        for (let i = 0; i < this.receivers.length; i++)
            if (this.receivers[i].receiver === receiver) {
                this.receivers.splice(i, 1);
                return;
            }
    }

    send(sender: JQueryEventObject | HTMLElement| string) {
        for (let i = 0; i < this.receivers.length; i++) {
            let entry = this.receivers[i];
            if (typeof entry.sender === 'undefined' || entry.sender === sender)
                entry.receiver.apply(entry.context, arguments);
        }
    }


}
