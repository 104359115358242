import {Component,} from '@angular/core';
import {Observable} from "rxjs";
import {Container, ContainerGoods, DimensionValue, JobType, Shipment} from "@api";
import {ContainerStore} from "@stores/container-store.service";
import {ContainerService} from "@services/container.service";
import {map} from "rxjs/operators";
import {ShipmentOverviewItem} from "@app/page-components/shipment/shipment-list/shipment-overview-item";
import {ContainerOverviewItem} from "@app/page-components/shipment/shipment-list/container-overview-item";

@Component({
             selector: 'rt-container-box-view',
             templateUrl: './container-box-view.component.html',
             styleUrls: ['./container-box-view.component.scss']
           })
export class ContainerBoxViewComponent {

  readonly items$: Observable<(ContainerOverviewItem|ShipmentOverviewItem)[]>
  readonly isSearching$: Observable<boolean>;
  readonly displayListHeader$: Observable<boolean>;

  readonly jobType = JobType;
  skeletonItemCount = 24;
  private readonly _containerService: ContainerService;

  constructor(containerStore: ContainerStore, containerService: ContainerService) {
    this._containerService = containerService;
    this.items$ = containerStore.observe("containerOverviewList");
    this.isSearching$ = containerStore.observe("isSearching")
    this.displayListHeader$ = containerStore
      .observe(["isSearching", "itemList"])
      .pipe(map(({isSearching, itemList}) => isSearching || !!itemList))
  }

  readonly getContainerNumber = (item: Container) => {
    if (ContainerService.isContainer(item))
      return item.containerNumber || "T.B.D.";
    return "-"
  };

  trackById = (_: number, item: Container) => {
    return item.containerId;
  };

  getPieceCount(containerGoods: ContainerGoods[]) {
    return containerGoods.sum(g => g.packCount);
  }

  getGrossWeights(containerGoods: ContainerGoods[]): DimensionValue[] {
    return containerGoods
      .map(g => g.grossWeight)
      .filter(w => !!w?.value)
      .groupBy(w => w.dimension)
      .map(g => {
        return {
          value: g.items.sum(w => w.value),
          dimension: g.key
        }
      });
  }

  castToContainer(container: Shipment | Container) {
    return ContainerService.isContainer(container)
           ? container
           : null;
  }

  hasDangerousGoods(container: Container) {
    return container.goods
                    .any(g => g.dangerousGoods?.any() === true);
  }

}
