import {Injectable} from '@angular/core';
import {HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from "@microsoft/signalr";
import {AccountStore} from "@stores";
import {Observable, Subject} from "rxjs";
import {UserInformation} from "@api";
import {debounceTime, map} from "rxjs/operators";
import {PrepareJsonDataFromServer} from "@utils/Utils";


@Injectable({
              providedIn: 'root'
            })
export class LiveUpdateService {

  private readonly _connection: HubConnection

  private readonly _accountStore: AccountStore;

  private readonly _liveUpdateSubject = new Subject<UserInformation>();
  readonly liveUpdates$: Observable<UserInformation> = this._liveUpdateSubject.pipe(map(PrepareJsonDataFromServer))

  constructor(accountStore: AccountStore) {
    this._accountStore = accountStore;
    this._connection =
      new HubConnectionBuilder()
        .withAutomaticReconnect()
        .withUrl("/LiveUpdates", {
          skipNegotiation: false
        })
        .configureLogging(LogLevel.Debug)
        .build()
    accountStore
      .observe("mail")
      .pipe(
        map(m => !!m),
        debounceTime(200)
      )
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      .subscribe(async (isLoggedIn) => {
        if (this._connection.state !== HubConnectionState.Disconnected)
          await this._connection.stop();
        if (isLoggedIn) {
          void this._connection
              .start()
              .then(() => {
                this._connection.on('UserDataChanged', (update) => this._liveUpdateSubject.next(update));
              });
        }
      });
  }


}
