import {Component, Input} from '@angular/core';
import {Observable} from "rxjs";
import {ContainerStore} from "@stores/container-store.service";
import {map} from "rxjs/operators";
import {ContainerOverviewItem} from "@app/page-components/shipment/shipment-list/container-overview-item";
import {FieldRenderer} from "@app/page-components/shipment/shipment-list/field-renderer.service";
import {AccountStore} from "@stores";
import {ContainerBoxFields, ContainerCardLayoutMapper} from "@app/page-components/shipment/shipment-list/container-box-fields";

@Component({
             selector: 'rt-container-box',
             templateUrl: './container-box.component.html',
             styleUrls: ['./container-box.component.scss'],
             host: {
               class: "shipment-box"
             }
           })
export class ContainerBoxComponent {

  @Input() container: ContainerOverviewItem;

  readonly boxLayout$: Observable<ContainerCardLayoutMapper[]>;
  readonly renderer: FieldRenderer;
  private readonly _accountStore: AccountStore;
  private readonly _containerStore: ContainerStore;

  constructor(containerStore: ContainerStore, accountStore: AccountStore, renderer: FieldRenderer) {
    this._containerStore = containerStore;
    this.renderer = renderer;
    this._accountStore = accountStore;
    this.boxLayout$ = accountStore
      .observe("containerCardLayout")
      .pipe(map(l => l.map(item => ContainerBoxFields[item])));
  }

  renderFields(container: ContainerOverviewItem, layout: ContainerCardLayoutMapper[], renderer: FieldRenderer): string {
    let output = "";
    for (const field of layout) {
      if (field.hidden && field.hidden(container))
        continue;
      output += ` <p class="${field.class}">
            <strong>${field.title(container)}</strong>
           ${field.getContent(container, renderer)}
          </p>`
    }
    return output;
  }

  readonly getQueryParams = (item: ContainerOverviewItem): { ContainerID?: string } => {
    return {
      ContainerID: item.containerId
    }
  }

}
