<main>
  <div class="padding">
    <div class="search-bar button-container">
          <span class="title" style="min-width: 0">
          Online Monitor
          </span>
    </div>
    <table class="table-default table-striped">
      <tbody>
      <tr>
        <th>Connection ID</th>
        <th>Email</th>
      </tr>
      <tr *ngFor="let connectedUser of connectedUsers">
        <td>{{connectedUser.connectionId}}</td>
        <td>{{connectedUser.mailAddress}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</main>
