import {OrderViewData} from "./order-view-data";
import {normalizeId} from "@utils/normalize-Id";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class OrderDetailViewData extends OrderViewData {
  readonly orderId: string | null;

  constructor(orderId: string) {
    super();
    this.orderId = normalizeId(orderId);
  }

  hasNewInformationComparedTo(compare: this): boolean {
    const idChanged = this.orderId !== compare.orderId;
    return idChanged;
  }

  get pageTitle(): string {
    // if (this.hasTitle())
    //   return TitleBuilder.order.orderDetail(this.orderTitle!);
    return TitleBuilder.order.list;
  }

  get url(): string {
    return UrlBuilder.order.orderDetail(this.orderId!);
  }

  isValid(): boolean {
    return this.orderId !== null;
  }

  get bodyClasses(): string[] {
    return ["page-shipment","page-order"];
  }
}
