import {Injectable} from "@angular/core";
import {Store, StoreProjectionMapping} from "@app/stores/store";
import {EntityStoreState} from "@app/stores/entityStoreState";
import {TitleBuilder} from "@utils/builders/title-builder";

type EntityStoreProjection = { entityTitle: string | null };

@Injectable({providedIn: "root"})
export class EntitySetStore extends Store<EntityStoreState, EntityStoreProjection> {

  getName(): string {
    return "EntitySetStore";
  }

  private static readonly _initialState: Partial<EntityStoreState> = {}

  private static _projectionMapping: StoreProjectionMapping<EntityStoreState, EntityStoreProjection> =
    {
      entityTitle: ({currentEntity}) => {
        if (!currentEntity)
          return null;
        else if (!currentEntity.id)
          return TitleBuilder.entities.addSite
        else
          return currentEntity.name || "";
      }
    };


  constructor() {
    super(EntitySetStore._projectionMapping, EntitySetStore._initialState)
  }
}
