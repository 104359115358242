import {NgModule} from '@angular/core';
import {CountrySelectDirective, InitializeChildPopoversDirective, PopoverDirective, PopupSelectDirective, SelectDirective} from "@directives";
import {AppRoutingModule} from "@app/app-routing.module";
import {BrowserModule} from "@angular/platform-browser";
import {
  FormsModule,
} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {
    CityValuePipe, ContainerIconClassPipe,
    DecimalPipe,
    DeliveryStatusPipe,
    DimensionPipe, DistinctPipe, FlattenPipe,
    IntegerPipe, MergeDimensionPipe, MergePipe, NoPlaceholderPipe,
    PortTitlePipe,
    PortValuePipe,
    ShipmentTypeUrlParamPipe,
    StringArrayPipe,
    StringPipe, SumPipe,
    TbdCityTitlePipe,
    TbdPipe
} from "@app/value-pipes/string-value.pipe";
import {EqualsNotPipe, NotPipe} from "../ngDirectives/equals-not.pipe";
import {EqualsPipe} from "../ngDirectives/equals.pipe";
import {
  AsContainerGroupPipe,
  AsContainerPipe,
  ContainerStatusClassPipe,
  ContainerStatusNamePipe,
  CustomsStateCssClassPipe,
  CustomsStateLabelPipe,
  DeliveryStatusTextColorClassPipe,
  GetDangerousGoodsPipe,
  HasInformationOtherThanPackCountPipe,
  HasInformationOtherThanPackCountVolumeWeightPipe,
  IsAirShipmentPipe, IsClientPipe, IsContainerOverviewItemPipe, IsContainerPipe,
  IsFclOrBuyersConsolPipe,
  IsFclPipe,
  IsOperatingReeferPipe, IsRohligUserPipe, IsShipmentOverviewItemPipe, LastPipe,
  NoteTextPipe,
  NoteTypeNamePipe, OrderByPipe, OrderStatusNamePipe, OrderStatusTextColorClassPipe,
  PackCountPipe,
  PacksWithInformationPipe,
  PackVolumePipe,
  PackWeightPipe,
  ReeferTemperaturePipe,
  RtAddress, RtAnyPipe, RtCountry, RtCountryFlag,
  RtDatePipe, RtDateTimePipe,
  RtHasNoClientReferencesPipe, RtPhone, rtSortPipe, SafeHtmlPipe, UnescapeHtmlPipe,
  TimelineLegTransportModeCssClassPipe,
  TimelineLegTypeCssClassPipe,
  TimelineLocationTypeCssClassPipe,
  TrustUrl, UserGroupPipe,
  WbrPipe
} from "@app/utils/rt-date.pipe";
import {FixedNumberPipe} from "../ngDirectives/fixed-number.pipe";
import {PureFunctionPipe} from "../ngDirectives/pipe-function.pipe";
import {IsDgDataPipe} from "@app/page-components/shipment/shipment-list/is-dg-data.pipe";
import {RtCheckboxControlValueAccessor} from "@app/utils/rt-checkbox-control-value-accessor.directive";
import { AccordionComponent } from './components/accordion/accordion.component';
import { VerticalTimelineComponent } from '@app/page-components/shipment/shipment-detail/vertical-timeline/vertical-timeline.component';
import { TimeLineDateComponent } from '@app/page-components/shipment/shipment-detail/vertical-timeline/timl-line-date/time-line-date.component';
import { VerticalTimelinePortComponent } from '@app/page-components/shipment/shipment-detail/vertical-timeline/vertical-timeline-port/vertical-timeline-port.component';

@NgModule({
            declarations: [
              SelectDirective,
              PopoverDirective,
              TbdPipe,
              DeliveryStatusPipe,
              ShipmentTypeUrlParamPipe,
              EqualsNotPipe,
              NotPipe,
              EqualsPipe,
              IsAirShipmentPipe,
              DeliveryStatusTextColorClassPipe,
              RtDatePipe,
              RtHasNoClientReferencesPipe,
              RtAddress,
              IntegerPipe,
              GetDangerousGoodsPipe,
              IsFclOrBuyersConsolPipe,
              AsContainerGroupPipe,
              AsContainerPipe,
              ContainerStatusClassPipe,
              ContainerStatusClassPipe,
              ContainerStatusNamePipe,
              IsFclPipe,
              IsOperatingReeferPipe,
              PackCountPipe,
              PackWeightPipe,
              PackVolumePipe,
              ReeferTemperaturePipe,
              PacksWithInformationPipe,
              HasInformationOtherThanPackCountPipe,
              HasInformationOtherThanPackCountVolumeWeightPipe,
              NoteTypeNamePipe,
              NoteTextPipe,
              WbrPipe,
              FixedNumberPipe,
              PureFunctionPipe,
              IsDgDataPipe,
              RtDatePipe,
              StringPipe,
              StringArrayPipe,
              PortTitlePipe,
              PortValuePipe,
              TbdCityTitlePipe,
              CityValuePipe,
              DimensionPipe,
              DecimalPipe,
              TimelineLegTransportModeCssClassPipe,
              CustomsStateLabelPipe,
              CustomsStateCssClassPipe,
              TimelineLegTypeCssClassPipe,
              TimelineLocationTypeCssClassPipe,
              OrderStatusTextColorClassPipe,
              OrderStatusNamePipe,
              LastPipe,
              InitializeChildPopoversDirective,
              SafeHtmlPipe,
              OrderByPipe,
              RtCountry,
              TrustUrl,
              RtDateTimePipe,
              NoPlaceholderPipe,
              IsClientPipe,
              CountrySelectDirective,
              IsRohligUserPipe,
              RtPhone,
              rtSortPipe,
              PopupSelectDirective,
              RtCountryFlag,
              RtCheckboxControlValueAccessor,
              RtAnyPipe,
              UserGroupPipe,
              AccordionComponent,
              MergeDimensionPipe,
              MergePipe,
              SumPipe,
              FlattenPipe,
              DistinctPipe,
              IsContainerPipe,
              ContainerIconClassPipe,
              IsContainerOverviewItemPipe,
        IsShipmentOverviewItemPipe,
                  VerticalTimelineComponent,
        TimeLineDateComponent,
    VerticalTimelinePortComponent,
    UnescapeHtmlPipe,
            ],
            imports: [
              AppRoutingModule,
              BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
              FormsModule,
              BrowserAnimationsModule
            ],
            exports: [
              SelectDirective,
              AppRoutingModule,
              AccordionComponent,
              BrowserModule,
              FormsModule,
              PopoverDirective,
              TbdPipe,
              DeliveryStatusPipe,
              ShipmentTypeUrlParamPipe,
              EqualsNotPipe,
              NotPipe,
              EqualsPipe,
              IsAirShipmentPipe,
              DeliveryStatusTextColorClassPipe,
              RtDatePipe,
              RtHasNoClientReferencesPipe,
              RtAddress,
              IntegerPipe,
              GetDangerousGoodsPipe,
              IsFclOrBuyersConsolPipe,
              AsContainerGroupPipe,
              AsContainerPipe,
              ContainerStatusClassPipe,
              ContainerStatusClassPipe,
              ContainerStatusNamePipe,
              IsFclPipe,
              IsOperatingReeferPipe,
              PackCountPipe,
              PackWeightPipe,
              PackVolumePipe,
              ReeferTemperaturePipe,
              PacksWithInformationPipe,
              HasInformationOtherThanPackCountPipe,
              HasInformationOtherThanPackCountVolumeWeightPipe,
              NoteTypeNamePipe,
              NoteTextPipe,
              WbrPipe,
              FixedNumberPipe,
              PureFunctionPipe,
              IsDgDataPipe,
              RtDatePipe,
              StringPipe,
              StringArrayPipe,
              PortTitlePipe,
              PortValuePipe,
              TbdCityTitlePipe,
              CityValuePipe,
              DimensionPipe,
              DecimalPipe,
              TimelineLegTransportModeCssClassPipe,
              CustomsStateLabelPipe,
              CustomsStateCssClassPipe,
              TimelineLegTypeCssClassPipe,
              TimelineLocationTypeCssClassPipe,
              OrderStatusTextColorClassPipe,
              OrderStatusNamePipe,
              LastPipe,
              InitializeChildPopoversDirective,
              SafeHtmlPipe,
              OrderByPipe,
              RtCountry,
              TrustUrl,
              RtDateTimePipe,
              NoPlaceholderPipe,
              IsClientPipe,
              CountrySelectDirective,
              IsRohligUserPipe,
              RtPhone,
              rtSortPipe,
              PopupSelectDirective,
              RtCountryFlag,
              RtCheckboxControlValueAccessor,
              RtAnyPipe,
              UserGroupPipe,
              MergeDimensionPipe,
              MergePipe,
              SumPipe,
              FlattenPipe,
              DistinctPipe,
              IsContainerPipe,
              ContainerIconClassPipe,
              IsContainerOverviewItemPipe,
                IsShipmentOverviewItemPipe,
                VerticalTimelineComponent,
                TimeLineDateComponent,
              VerticalTimelinePortComponent,
              UnescapeHtmlPipe,
            ]
          })
export class RtCommonModule {
}
