import {Component, HostBinding, Input} from '@angular/core';
import {SlideInOut} from "@utils/Angular/AnimationTemplates";
import {EntityPermissions, UserType} from "@api";
import {Observable} from "rxjs";
import {EntityService} from "@services/entity.service";
import {UserStore} from "@stores/user-store.service";
import {map} from "rxjs/operators";

@Component({
             selector: 'rt-user-entity-permission-edit',
             templateUrl: './user-entity-permission-edit.component.html',
             styleUrls: ['./user-entity-permission-edit.component.scss'],
             animations: [SlideInOut()],
             exportAs: "permissionEdit",
             host: {
               class: "element"
             }
           })
export class UserEntityPermissionEditComponent {


  setShipmentRights(hasRight: boolean, entityPermissions:EntityPermissions = this.entityPermissions):void {
    if (!entityPermissions.canBeManagedByCurrentUser)
      return;
    entityPermissions.shipments = hasRight;
    entityPermissions.childEntities.forEach(e => this.setShipmentRights(hasRight, e))
  }

  setOrderRights(hasRight: boolean, entityPermissions:EntityPermissions = this.entityPermissions):void {
    if (!entityPermissions.canBeManagedByCurrentUser)
      return;
    entityPermissions.orders = hasRight;
    entityPermissions.childEntities.forEach(e => this.setOrderRights(hasRight, e))
  }

  setBookingRights(hasRight: boolean, entityPermissions:EntityPermissions = this.entityPermissions):void {
    if (!entityPermissions.canBeManagedByCurrentUser)
      return;
    entityPermissions.bookings = hasRight && entityPermissions.bookingAvailable;
    entityPermissions.childEntities.forEach(e => this.setBookingRights(hasRight, e))
  }

  setInvoiceDocumentsRights(hasRight: boolean, entityPermissions:EntityPermissions = this.entityPermissions):void {
    if (!entityPermissions.canBeManagedByCurrentUser)
      return;
    entityPermissions.invoiceDocuments = hasRight;
    entityPermissions.childEntities.forEach(e => this.setInvoiceDocumentsRights(hasRight, e))
  }

  @Input()
  entityPermissions: EntityPermissions;

  @Input()
  hasDocumentsPermissions: boolean;
  readonly userCanHaveBookingPermissions$: Observable<boolean>;

  @HostBinding("class.open")
  open = true;
  private readonly _entityService: EntityService;

  constructor(userStore: UserStore, entityService: EntityService) {
    this._entityService = entityService;
    this.userCanHaveBookingPermissions$ =
      userStore
        .observe("currentUser")
        .pipe(map(u => u?.type !== UserType.Rohlig));
  }

  toggleEntityInfoSidebar(id: string): void {
    const lastId = this._entityService.entityStore.get("currentEntity")?.id;
    if (lastId === id)
      this._entityService.entityStore.update("currentEntity", null)
    else
      void this._entityService.loadEntity(id);
  }

  toggleOpen(): void {
    this.open = !this.open;
  }
}
