// noinspection ParameterNamingConventionJS

import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
        name: 'pureFunction',
        pure: true
      })
export class PureFunctionPipe implements PipeTransform {
  public transform<T1,T2,T3,T4, TR>(value: T1, handler: Func4<T1,T2,T3,T4,TR>, param1: T2, param2: T3, param3: T4): TR;
  public transform<T1,T2,T3, TR>(value: T1, handler: Func3<T1,T2,T3,TR>, param1: T2, param2: T3): TR;
  public transform<T1,T2, TR>(value: T1, handler: Func2<T1,T2,TR>, param: T2): TR;
  public transform<T, TR>(value: T, handler: (value: T) => TR): TR ;
  public transform<T1,T2,T3,T4, TR>(value: T1, handler: Func4<T1,T2,T3,T4,TR>, param1?: T2, param2?: T3, param3?: T4): TR {
  // public transform<T, TR>(value: T, handler: (value: T) => TR, context?: unknown): TR {
    return handler(value, param1, param2, param3);
  }
}
