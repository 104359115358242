import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Observable} from "rxjs";
import {UserRightsStore} from "@stores";
import {EntitySetPermissions, User, UserGroup, UserSearchResult, UserType} from "@api";
import {UserService} from "@services/user.service";
import {trackById} from '@app/utils/track-by-id';
import {userGroupMapper} from "@app/utils/enum-display-name-mapper";

@Component({
             selector: 'rt-user-list',
             templateUrl: './user-list.component.html',
             styleUrls: ['./user-list.component.scss'],
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class UserListComponent {
  readonly displayedSearchResults$: Observable<UserSearchResult[]>
  readonly isSearching$: Observable<boolean>;
  readonly trackById = trackById;
  moreResultsAvailable$: Observable<boolean>;
  isLoadingMore = false;
  skeletonItemCount = 24;
  private readonly _userService: UserService;

  constructor(userRightsStore: UserRightsStore, userService: UserService) {
    this._userService = userService;
    this.displayedSearchResults$ = userService.userStore.observe("displayedSearchResults");
    this.moreResultsAvailable$ = userService.userStore.observe("moreResultsAvailable");
    this.isSearching$ = userService.userStore.observe("isSearching");
  }

  showUserGroupLabel(user: User): boolean {
    return !user.deactivated && user.type === UserType.Rohlig && user.group !== UserGroup.Standard
  }

  loadMore(): void {
    this.isLoadingMore = true;
    this._userService.loadMore();
    this.isLoadingMore = false;
  }

  getEntitySetNames(entityPermissions: EntitySetPermissions[]): string[] {
    if (!entityPermissions)
      return [];
    return entityPermissions
      .map(e => e.name)
      .orderBy(e => e)
  }

  getUserGroupName(userGroup: UserGroup): string {
    return userGroupMapper[userGroup];
  }

}
