<div class="page-wrap">
  <main>
    <section class="padding">
      <ng-container *ngIf="order$ | async as order; else skeletonView">
        <div class="summary">
          <div class="static">
            <a routerLink="/Order" class="button back-button secondary"><span class="mat-icon-back"></span></a>
          </div>
          <div class="shrink">
            <header>
              <p> Order Number </p>
            </header>
            <footer>
              <p [innerHTML]="order.orderNumber | rtWbr | rtTbd"></p>
            </footer>
          </div>
          <div class="shrink">
            <header>
              <p> Supplier </p>
            </header>
            <footer>
              <p>{{order.supplier?.name | rtString | unescapeHtml}}</p>
            </footer>
          </div>
          <div class="shrink">
            <header>
              <p> Buyer </p>
            </header>
            <footer>
              <p>{{order.buyer?.name | rtString | unescapeHtml}}</p>
            </footer>
          </div>
          <div class="shrink">
            <header>
              <p> Status </p>
            </header>
            <footer>
              <div class="status" [ngClass]="order.consolidatedStatus | rtOrderStatusTextColorClass">{{order.consolidatedStatus | rtOrderStatusName}}</div>
            </footer>
          </div>
          <div class="shrink">
            <header>
              <p>Last Event</p>
            </header>
            <footer style="flex-direction: column;">
              <ng-container *ngIf="order.lastEvent as lastEvent; else noEvent">
                <p>{{lastEvent.description}}</p>
                <p>
                  <small class="text-light">{{lastEvent.actualDate | rtDate}}</small>
                </p>
              </ng-container>
              <ng-template #noEvent>
                <p *ngIf="order.consolidatedStatus === orderStatuses.Canceled">Order Canceled</p>
                <ng-template #notCanceled>Order Placed</ng-template>
                <p>
                  <small class="text-light">{{order.orderDate | rtDate}}</small>
                </p>
              </ng-template>
            </footer>
          </div>
        </div>
        <div class="boxes grid grid-padding" #grid>
          <div class="grid-gutter"></div>

          <!--================ Order Information-->
          <rt-collapsible-box [title]="'Order Information'"
                              [openStateStoreAccessor]="boxStateAccessors.orderInformation">

            <div class="padding">
              <table class="table-clean">
                <tbody>
                  <tr>
                    <th>Order Date</th>
                    <td>{{order.orderDate | rtDate}}</td>
                  </tr>
                  <tr>
                    <th>Incoterm</th>
                    <td>{{order.incoterm | rtString}}</td>
                  </tr>
                  <tr>
                    <th>Transport Mode</th>
                    <td>{{order.transportModes.join(" / ")}}</td>
                  </tr>
                  <tr>
                    <th>Shipments</th>
                    <td *ngIf="order.shipments.any(); else noShipments">
                      <!--suppress TypeScriptValidateTypes -->
                      <p *ngFor="let shipment of (order.shipments | orderBy:'houseBill')" [rt-reference-link]="shipment">
                      </p>
                    </td>
                    <ng-template #noShipments>
                      <td>{{''| rtString}}</td>
                    </ng-template>
                  </tr>
                  <tr>
                    <th>Commercial Invoices</th>
                    <td>
                      {{order | pureFunction:getCommercialInvoices |rtStringArray}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </rt-collapsible-box>

          <!--================ Parties-->
          <rt-collapsible-box [title]="'Parties'"
                              [isempty]="order | pureFunction:hasNoParties"
                              [openStateStoreAccessor]="boxStateAccessors.parties">
            <div class="padding">
              <div class="shipment-information">
                <address>
                  <ng-container *ngIf="order.supplier">
                    <p class="text-light">Supplier</p>
                    <span [outerHTML]="order.supplier | rtAddress"></span>
                  </ng-container>
                </address>
                <address>
                  <ng-container *ngIf="order.buyer">
                    <p class="text-light">Buyer</p>
                    <span [outerHTML]="order.buyer | rtAddress"></span>
                  </ng-container>
                </address>
              </div>
            </div>
          </rt-collapsible-box>

          <!--================ Documents-->
          <rt-collapsible-box *ngIf="hasDocumentRights$ | async"
                              [title]="'Documents'"
                              [isempty]="order.documents | not"
                              [openStateStoreAccessor]="boxStateAccessors.documents">
            <rt-shipment-documents [documents]="[order.documents]"></rt-shipment-documents>
          </rt-collapsible-box>

          <!--================ Notes-->
          <rt-collapsible-box [title]="'Notes'"
                              [isempty]="order.notes | not"
                              [openStateStoreAccessor]="boxStateAccessors.notes">
            <div class="padding wysiwyg notes">
              <p *ngFor="let note of order.notes">
                <span class="text-light">{{note | rtNoteTypeName}}</span><br>
                <span [outerHTML]="note | rtNoteText | rtString"></span>
              </p>
            </div>
          </rt-collapsible-box>

          <!--================ Products-->
          <rt-collapsible-box *ngIf="hasProductRights$ | async"
                              [title]="'Products'"
                              [wide]="true"
                              [isempty]="order | pureFunction:hasNoOrderLines"
                              [openStateStoreAccessor]="boxStateAccessors.products">
            <table class="table-products table-default table-bodies-striped" *ngIf="order | pureFunction:groupLines as groups">
              <thead>
                <tr>
                  <th *ngIf="groups | pureFunction:containsMultiline"></th>
                  <th class="line-number">Line</th>
                  <th>Product Code</th>
                  <th>Description</th>
                  <th colspan="2">Quantity</th>
                  <th>Status</th>
                  <th>Shipment</th>
                  <th>Commercial Invoice</th>
                </tr>
              </thead>
              <tbody *ngFor="let group of groups">
                <tr (rtOrderLineCollapser)="_updateLayout()" *ngIf="group | pureFunction:getHeader as headerLine" class="headerline">
                  <td class="mat-icon-expandless"></td>
                  <td>{{headerLine.lineNumber | rtInteger}}</td>
                  <td>{{headerLine.productCode | rtString}}</td>
                  <td>{{headerLine.description | rtString}}</td>
                  <td class="column-spaned">{{headerLine.quantity | rtInteger}} </td>
                  <td>{{headerLine.quantity ? headerLine.quantityDimension : ""}}</td>
                  <td>
                    <div class="status" [ngClass]="headerLine.lineStatus | rtOrderStatusTextColorClass">{{headerLine.lineStatus | rtOrderStatusName}}</div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr *ngFor="let line of group.items">
                  <ng-container *ngIf="group | pureFunction:isMultiline; else singleLine">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </ng-container>
                  <ng-template #singleLine>
                    <td *ngIf="groups | pureFunction:containsMultiline"></td>
                    <td>{{line.lineNumber | rtInteger}}</td>
                    <td>{{line.productCode | rtString}}</td>
                    <td>{{line.description | rtString}}</td>
                  </ng-template>
                  <td class="column-spaned">{{line.quantity | rtInteger}} </td>
                  <td>{{line.quantity ? line.quantityDimension : ""}}</td>
                  <td>
                    <div class="status" [ngClass]="line.lineStatus | rtOrderStatusTextColorClass">{{line.lineStatus | rtOrderStatusName}}</div>
                  </td>
                  <td [rt-reference-link]="line.shipment">
                  </td>
                  <td>{{line.commercialInvoiceNumber | rtString}}</td>
                </tr>
              </tbody>
            </table>
          </rt-collapsible-box>

          <!--================ Splits-->
          <rt-collapsible-box [title]="'Order Splits'"
                              *ngIf="(order | pureFunction:hasNoOrderLines) && (hasProductRights$ | async)"
                              [wide]="true"
                              [openStateStoreAccessor]="boxStateAccessors.splits">
            <table class="table-default table-responsive">
              <thead>
                <tr>
                  <th>Split</th>
                  <th>Status</th>
                  <th>Shipment</th>
                  <th>Commercial Invoice</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let split of (order | pureFunction:getSplits)">
                  <td data-header="Split">
                    <span>{{split.splitNumber}}</span>
                  </td>
                  <td data-header="Status">
                    <div class="status" [ngClass]="split.status | rtOrderStatusTextColorClass">{{split.status | rtOrderStatusName}}</div>
                  </td>
                  <td data-header="Shipment" [rt-reference-link]="split.shipment">
                  </td>
                  <td data-header="Commercial Invoice">
                    {{split.commercialInvoiceNumbers | rtStringArray}}
                  </td>
                </tr>
              </tbody>
            </table>
          </rt-collapsible-box>

        </div>
      </ng-container>
      <ng-template #skeletonView>
        <div class="summary no-shrink">
          <div class="static">
            <a routerLink="/Order" class="button back-button secondary"><span class="mat-icon-back"></span></a>
          </div>
          <div>
            <p><span class="shimmer-content" style="width: 60%;"></span></p>
            <p><span class="shimmer-content" style="width: 80%;"></span></p>
          </div>
          <div>
            <p><span class="shimmer-content" style="width:100%"></span></p>
            <p><span class="shimmer-content" style="width:40%"></span></p>
          </div>
          <div>
            <p><span class="shimmer-content" style="width:100%"></span></p>
            <p><span class="shimmer-content" style="width:40%"></span></p>
          </div>
          <div>
            <p><span class="shimmer-content" style="width:100%"></span></p>
            <p><span class="shimmer-content" style="width:40%"></span></p>
          </div>
          <div class="shrink">
            <p><span class="shimmer-content" style="width:100%"></span></p>
            <p><span class="shimmer-content" style="width:70%"></span></p>
            <p><span class="shimmer-content" style="width:30%"></span></p>
          </div>
        </div>
        <div #grid class="boxes grid grid-padding">
          <div class="grid-gutter" #gridGutter></div>
          <rt-skeleton-box class="skeleton-large"></rt-skeleton-box>
          <rt-skeleton-box></rt-skeleton-box>
          <rt-skeleton-box class="skeleton-large"></rt-skeleton-box>
          <rt-skeleton-box></rt-skeleton-box>
        </div>
      </ng-template>
    </section>
  </main>
</div>
