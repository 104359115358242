import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {ChangePasswordForm} from "@widgets/ChangePasswordForm";
import {TypedStoreAccessor} from "@stores/store-accessor";
import {PasswordChange} from "@api";

require("../utils/JqueryLoader");

@Directive({
             selector: '[rt-change-password-form]'
           })
export class ChangePasswordFormDirective implements OnInit {
  @Input()
  requestId: string;
  @Input()
  authenticationMode: "oldPassword" | "requestId";
  @Input()
  storeAccessor: TypedStoreAccessor<PasswordChange>;
  private readonly _elementRef: ElementRef;
  private changePasswordForm: ChangePasswordForm;

  constructor(elementRef: ElementRef) {
    this._elementRef = elementRef;
  }

  get oldPassword(): string {
    return this.changePasswordForm?.$oldPassword.val();
  }

  get newPassword(): string {
    return this.changePasswordForm?.$newPassword.val();
  }

  async validate(): Promise<boolean> {
    const newPassword = this.changePasswordForm.newPasswordValidationField.validate();
    const confirmNewPassword = this.changePasswordForm.confirmNewPasswordValidationField.validate();
    if (this.authenticationMode == "requestId") {
      const requestId = this.changePasswordForm.requestIdValidationField.validate();
      const results = await Promise.all([newPassword, confirmNewPassword, requestId])
      return results.reduce((r1, r2) => r1 && r2);
    } else {
      const oldPassword = this.changePasswordForm.oldPasswordValidationField.validate();
      const results = await Promise.all([newPassword, confirmNewPassword, oldPassword]);
      return results.reduce((r1, r2) => r1 && r2);
    }
  }

  ngOnInit(): void {
    const $element = $(this._elementRef.nativeElement);
    const options =
      {
        authenticationMode: this.authenticationMode,
        requestId: this.requestId
      };
    this.changePasswordForm = new ChangePasswordForm($element, options);
    this.changePasswordForm.$oldPassword.on("input", () => this._updateStore());
    this.changePasswordForm.$newPassword.on("input", () => this._updateStore());
    if (this.authenticationMode == "requestId")
      this.changePasswordForm.$requestId.on("input",
                                            () => {
                                              this.requestId = this.changePasswordForm.$requestId.val()
                                            })
  }

  reset(): void {
    this.changePasswordForm.reset();
    this._updateStore();
  }

  private _updateStore() {
    if (!this.storeAccessor)
      return;

    const oldPassword = this.oldPassword;
    const newPassword = this.newPassword;

    const passwordChange: PasswordChange | null =
      oldPassword && newPassword
      ? {newPassword, oldPassword}
      : null;

    this.storeAccessor.set(passwordChange)
  }
}
