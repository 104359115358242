import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ShipmentOverviewItem} from "@app/page-components/shipment/shipment-list/shipment-overview-item";
import {Container, TimelineLeg, TimelineLegType, TimelineLocation} from "@api";
import {createTimelineUiData} from '../../shipment-timeline-popover-converter';
import {ContainerOverviewItem} from "@app/page-components/shipment/shipment-list/container-overview-item";

@Component({
             selector: 'rt-shipment-list-timeline',
             templateUrl: './shipment-list-timeline.component.html',
             styleUrls: ['./shipment-list-timeline.component.scss'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             host: {
               class: "timeline timeline-simple hide-dates"
             }
           })
export class ShipmentListTimelineComponent {
  @Input()
  shipment: ShipmentOverviewItem | ContainerOverviewItem;
  readonly createTimelineUiData = createTimelineUiData;

  // getLocations = (legs: TimelineLeg[]): (TimelineLocation & { progress?: number; startOrEnd: "start" | "end" })[] => {
  //   const locations: (TimelineLocation & { progress?: number; startOrEnd: "start" | "end" })[] = [];
  //   locations.push(this._createLocationWithProgress(legs[0], "start"));
  //   let startIndex = 1;
  //   if (locations[0].type === TimeLineLocationType.Pickup && legs.length >= 2)
  //     locations.push(this._createLocationWithProgress(legs[1], "start"));
  //   else
  //     startIndex = 0;
  //   for (let i = startIndex; i < legs.length; i++) {
  //     locations.push(this._createLocationWithProgress(legs[i]));
  //   }
  //   return locations;
  // };

  getLocations = (legs: TimelineLeg[]): (TimelineLocation & { progress: number; startOrEnd: "start" | "end" })[] => {
    if (!legs || !legs[0])
      return [];
    const locations: (TimelineLocation & { progress: number; startOrEnd: "start" | "end" })[] = [];
    let departed = legs[0].type !== TimelineLegType.Pickup;
    locations.push(this._createLocationWithProgress(legs[0], "start"))
    for (let i = 0; i < legs.length; i++) {
      const leg = legs[i];
      if (!departed && legs[i + 1]) {
        const departureLocation = this._createLocationWithProgress(legs[i + 1], "start")
        departureLocation.progress = leg.progress ?? null
        locations.push(departureLocation);
        departed = true;
      } else
        locations.push(this._createLocationWithProgress(leg));
    }
    return locations;
  };

  // noinspection JSMethodCanBeStatic
  private _createLocationWithProgress(leg: TimelineLeg,
                                      startOrEnd: "start" | "end" = "end"): { progress: number; startOrEnd: "start" | "end" } & TimelineLocation {
    const location = startOrEnd === "start"
                     ? leg.startLocation
                     : leg.endLocation;
    return Object.assign({progress: leg.progress ?? null, startOrEnd}, location);
  }
}
