import {Injectable} from "@angular/core";
import {Store} from "@stores";
import {Entity, EntityOrganization, EntitySaveRequest} from "@api";

interface EntityEditUiStoreState extends EntitySaveRequest {
  historySidebarOpen: boolean,
  currentSidebarOrganization: EntityOrganization,
  currentSidebarUserId: string
  parentEntity: Entity
}

@Injectable({providedIn: "any"})
export class EntityEditUiStore extends Store<EntityEditUiStoreState> {
    constructor() {
        super({}, {});
    }

    getName(): string {
        return "EntityEditUiStore";
    }
}
