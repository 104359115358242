import {Injectable} from '@angular/core';
import {ErrorViewData, LoginViewData} from "@view-data";
import { EntitySetInfo, InvoiceDisputeData, OrderSearch, OrderSearchPreset, ProfileUpdateData, ShipmentSearch, ShipmentSearchPreset, UserInformation} from "@api";
import {Observable, Subject} from "rxjs";
import {filter} from "rxjs/operators";
import {TypeFilter} from "@utils/rxjs-extensions";
import {AntiForgeryTokenInterceptor, runRequestWithoutProgress} from "@app/interceptors";
import {AccountStore} from "@app/stores/account-store.service";
import {RoutingService} from "@app/services/routing.service";
import {AlertService} from "@services/alert.service";
import {PrepareJsonDataFromServer} from "@utils/Utils";
import {CountryService} from "@services/country.service";
import {LiveUpdateService} from "@app/services/live-update.service";
import {AccountApi} from "../../api/Apis";

export type EntityChangingData = { entitySet: EntitySetInfo, success: Promise<boolean> };

@Injectable({
              providedIn: 'root'
            })
export class AccountService {
  readonly accountStore: AccountStore;
  private readonly _accountApi: AccountApi;
  private readonly _loginFailedSubject = new Subject<void>()
  readonly loginFailed$: Observable<void> = this._loginFailedSubject.asObservable();
  private readonly _entitySetChangeFailedSubject = new Subject<void>()
  readonly entitySetChangeFailed$: Observable<void> = this._entitySetChangeFailedSubject.asObservable();
  private readonly _entitySetChangeSuccessSubject = new Subject<void>()
  readonly entitySetChangeSuccess$: Observable<void> = this._entitySetChangeSuccessSubject.asObservable();
  private readonly _entitySetChangingSubject = new Subject<EntityChangingData>()
  readonly entitySetChanging$: Observable<EntityChangingData> = this._entitySetChangingSubject.asObservable();
  private readonly _antiForgeryTokenInterceptor: AntiForgeryTokenInterceptor;
  private readonly _routingService: RoutingService;

  private readonly _alertService: AlertService;
  private _isUpdatingUserData = false;

  private readonly _countryService: CountryService;

  constructor(routingService: RoutingService,
              accountApi: AccountApi,
              antiForgeryTokenInterceptor: AntiForgeryTokenInterceptor,
              accountStore: AccountStore,
              countryService: CountryService,
              liveUpdateService: LiveUpdateService,
              alertService: AlertService) {
    this._countryService = countryService;
    this._alertService = alertService;
    this._routingService = routingService;
    this.accountStore = accountStore;
    this._accountApi = accountApi;
    this._antiForgeryTokenInterceptor = antiForgeryTokenInterceptor;
    this.loadUserDataFromLocalStorage();

    this.accountStore
        .observe(["currentLayout",
                  "listLayoutOverflow",
                  "shipmentListLayout",
                  "shipmentCardLayout",
                  "orderBoxOpenStates",
                  "shipmentBoxOpenStates",
                  "profilePictureBase64",
                  "orderExcelExportMode",
                  "containerBoxOpenStates",
                  "shipmentListViewMode",
                  "shipmentExcelExportMode",
                  "containerCardLayout",
                  "containerListLayout"])
        .pipe(
          filter(() => !this._isUpdatingUserData),
          filter(() => !!this.accountStore.get("name"))
        )
        .subscribe(u => {
          void runRequestWithoutProgress(accountApi.updateSettings(u))
        });
    this._routingService
        .routingStore
        .observe("currentViewData")
        .pipe(
          filter(TypeFilter(ErrorViewData)),
          filter(d => d.errorType === "AccessDenied")
        )
        .subscribe(() => this.reloadUserDataFromServer());

    liveUpdateService.liveUpdates$
                     .subscribe(u => this.importLiveUpdate(u));
  }

  async login(userName: string, password: string, rememberMe: boolean): Promise<void> {
    try {
      const loginResult = await this._accountApi
                                    .login(userName, password, rememberMe)
                                    .toPromise();
      if (!loginResult)
        return;
      this._isUpdatingUserData = true;
      this.loggedInUserChanged();
      this.accountStore.update(loginResult);
      this._isUpdatingUserData = false;
    } catch (e) {
      this._loginFailedSubject.next();
    }
  }

  async logout(): Promise<boolean> {
    try {
      await this._accountApi
                .logout()
                .toPromise();
      this._isUpdatingUserData = true;
      this.loggedInUserChanged();
      this.accountStore.reset();
      this._isUpdatingUserData = false;
      this._routingService.navigateTo(new LoginViewData());
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async changeEntitySet(entitySetId: string): Promise<void> {
    let successPromiseResolver: (value: boolean) => void
    const successPromise = new Promise<boolean>(s => {
      successPromiseResolver = s
    });
    try {
      const newEntitySet = this.accountStore
                               .get("availableEntitySets")
                               .firstOrDefault(s => s.id === entitySetId);
      if (!newEntitySet)
        return;
      this._entitySetChangingSubject.next({
                                            entitySet: newEntitySet,
                                            success: successPromise
                                          });
      await this._accountApi
                .changeEntitySet(entitySetId)
                .toPromise();
      this.accountStore.update({
                                 currentEntitySet: newEntitySet,
                                 currentOrderSearch: null,
                                 currentShipmentSearch: null
                               });
      successPromiseResolver(true);
    } catch (e) {
      successPromiseResolver(false);
    }
    console.trace("entity Changed");
  }

  async createShipmentSearchPreset(name: string, search: ShipmentSearch): Promise<void> {
    const preset = Object.assign({}, search, {name}) as ShipmentSearchPreset;
    const presetId = await this._accountApi.createShipmentSearchPreset(preset).toPromise()
    preset.id = presetId;
    this.accountStore.update(({shipmentSearchPresets}) => {
      shipmentSearchPresets.push(preset);
      return {shipmentSearchPresets};
    });
  }

  async createOrderSearchPreset(name: string, search: OrderSearch): Promise<void> {
    const preset = Object.assign({}, search, {name}) as OrderSearchPreset;
    const presetId = await this._accountApi.createOrderSearchPreset(preset).toPromise()
    preset.id = presetId;
    this.accountStore.update(({orderSearchPresets}) => {
      orderSearchPresets.push(preset);
      return {orderSearchPresets};
    });
  }

  async deleteShipmentPreset(id: string): Promise<void> {
    await this._accountApi.deleteShipmentSearchPreset(id).toPromise();
    this.accountStore.update(({shipmentSearchPresets}) => {
      return {
        shipmentSearchPresets: shipmentSearchPresets
          .filter(p => p.id !== id)
      }
    })
  }

  async deleteOrderPreset(id: string): Promise<void> {
    await this._accountApi.deleteShipmentSearchPreset(id).toPromise();
    this.accountStore.update(({orderSearchPresets}) => {
      return {
        orderSearchPresets: orderSearchPresets
          .filter(p => p.id !== id)
      }
    })
  }

  async requestPasswordReset(mailAddress: string): Promise<void> {
    await this._accountApi
              .sendForgotPasswordMail(mailAddress)
              .toPromise()
  }

  async requestAccount(mailAddress: string,
                       firstName: string, lastName: string, company: string, CountryCode: string, isCostumer: boolean, inquiryText: string): Promise<void> {
    await this._accountApi
              .requestAccountEmail(mailAddress, firstName, lastName, company, CountryCode, isCostumer, inquiryText)
              .toPromise()
  }

  async resetPassword(requestId: string, password: string): Promise<void> {
    await this._accountApi
              .resetPassword(requestId, password)
              .toPromise()
  }

  async updateProfileData(profileUpdate: ProfileUpdateData): Promise<void> {
    await this._accountApi.saveProfileData(profileUpdate).toPromise();
    const country = this._countryService.getByIsoAlpha2(profileUpdate.phone?.countryCode);
    this.accountStore.update({
                               profilePictureBase64: profileUpdate.profilePictureBase64,
                               phone: profileUpdate.phone?.number || null,
                               phoneCountry: this._countryService.convertToApiCountry(country)
                             })
  }

  private importLiveUpdate(u: UserInformation) {
    const currentEntityId = this.accountStore.get("currentEntitySet")?.id;
    const currentEntitySetStillAvailable = !currentEntityId || u.availableEntitySets.any(e => e.id === currentEntityId);
    if (currentEntitySetStillAvailable)
      u.currentEntitySet = u.availableEntitySets.firstOrDefault(e => e.id === currentEntityId)
    this.accountStore.update(u);
  }
  async lodgeDispute(invoiceDisputeData: InvoiceDisputeData): Promise<void> {
    await this._accountApi
      .lodgeInvoiceDispute(invoiceDisputeData)
      .toPromise()
  }

  private loggedInUserChanged() {
    this._antiForgeryTokenInterceptor.updateAntiForgeryToken();
  }

  private loadUserDataFromLocalStorage() {
    const userData = PrepareJsonDataFromServer(window.userLoginData);
    if (!userData) {
      this.accountStore.initialize();
      return;
    }
    this._isUpdatingUserData = true;
    this.loggedInUserChanged();
    this.accountStore.initialize(userData);
    this._isUpdatingUserData = false;
  }

  private reloadUserDataFromServer() {
    this._accountApi
        .getLoggedInUserInformation()
        .subscribe(d => {
          this.accountStore.update(d);
        })
  }
}
