import {ISelectOptions, Select} from "./Select";
import {Modal} from "./modal";
import {AddressEdit, IAddressEditOptions} from "./AddressEdit";
import {ControlEvent, RenderedEvent} from "../libs/ExtendableEvent";
import {Alignment, Position} from "../utils/Enums";
import {Dropdown} from "./Dropdown";
import * as Utils from "../utils/Utils";
import {AwaitCondition, renderAddress} from "../utils/Utils";
import {ISelectListItem} from "./SelectList";
import {Cache} from "../utils/Cache"
import {closeAlert, openAlert} from "./Alert";
import {JsonAddress, JSONData} from "@api";
import {BookingServiceListener} from "@services/booking-service-listener.service";
import { UnsavedChangesModal } from "./ViewCustomizer";
import {createDeepCopy} from "@utils/createDeepCopy";

require("../utils/JqueryLoader");

export interface IAddressSelectOptions extends ISelectOptions<JSONDataContainer<JsonAddress>> {
  addressEditOptions?: IAddressEditOptions;
  addressType?: FunctionOrValue<"internal" | "external">;
  addressSearchRange?: FunctionOrValue<"all" | "entity">;
  entityId?: () => string;
  entitySetId?: () => string;
}


export class AddressSelect extends Select<JSONDataContainer<JsonAddress>> {
  public static recentInternalAddressCache = new Cache<JSONDataContainer<JsonAddress>[]>(new Date(0,
                                                                                                  0,
                                                                                                  0,
                                                                                                  0,
                                                                                                  0,
                                                                                                  30));
  public static recentExternalAddressCache = new Cache<JSONDataContainer<JsonAddress>[]>(new Date(0,
                                                                                                  0,
                                                                                                  0,
                                                                                                  0,
                                                                                                  0,
                                                                                                  30));
  protected static addressSelects: AddressSelect[] = [];

  //language=html
  // noinspection NsUnresolvedStyleClassReference
  private static menuTemplate =
    `<a class="edit"><i class="material-icons">mode_edit</i> Edit</a>
<a class="delete"><i class="material-icons">delete</i> Delete</a>`;
  private static defaultAddressOptions: IAddressSelectOptions = {
    "class": "address-select",
    shrinkToVisible: !(document.body.classList.contains("ie") || document.body.classList.contains("edge")),
    fixAlignment: true,
    focusSearchOnTouchOpen: false
  };
  readonly options: IAddressSelectOptions;
  protected $saveButton: JQuery;
  protected $cancelButton: JQuery;
  private readonly $content: JQuery;
  private readonly $addressPreview: JQuery;
  private editModal: Modal;
  private addressEdit: AddressEdit;
  private lastLoadedSiteId: string;
  private preloading = false;
  private contextMenuOpened = false;
  private lastPreloadedAddresses: JSONDataContainer<JsonAddress>[];
  private $createButton: JQuery;
  private newAddressName: string;

  private _bookingCountries: JSONData[];

  constructor(selectElement: HTMLSelectElement,
              options: IAddressSelectOptions = {}) {
    super(selectElement, AddressSelect.prepareOptions(options));
    const bookingServiceListener = new BookingServiceListener();
    bookingServiceListener.bookingModel$.subscribe(() => {
      AddressSelect.recentExternalAddressCache.invalidateCache();
      AddressSelect.recentInternalAddressCache.invalidateCache();
    })
    bookingServiceListener.bookingBaseData$
                          .subscribe(d =>  {
                            this._bookingCountries = d.countries;
                            this.unselectItemIfCountryIsNotAllowedForBooking(this.getSelectedItems()[0]);
                          })
    AddressSelect.addressSelects.push(this);
    this.options.closeOnSelect = () => !this.isTouchMode;
    this.onAjaxRequest.on(e => {
      e.data.options = undefined;
      if (Utils.UnwrapFunction(this.options.addressSearchRange) === "entity") {
        e.data.sitePK = Utils.UnwrapFunction(this.options.entityId);
      } else {
        e.data.sitePK = Utils.UnwrapFunction(this.options.entitySetId);
      }
    });
    this.$element.find("ul").on("scroll", () => this.closeContextMenu());
    this.onClosing.on(e => {
      if (this.editModal &&
          (this.editModal.isOpen() ||
           (e.originalEvent &&
            this.editModal.$modalWindow.has(e.originalEvent.target as HTMLElement).length))) {
        e.preventDefault();
      }
      const sourceEvent = ((e.originalEvent || {}) as FocusEvent);
      const focusTarget = (sourceEvent.relatedTarget || sourceEvent.target) as HTMLElement;
      if (focusTarget && focusTarget.classList.contains("alert") ||
          $(focusTarget).parents(".alert").length)
        e.preventDefault();
    });
    this.onOpened.on(() => {
      if (!this.isTouchMode)
        this.$addressPreview.html(null);
      else if (this.getSelectedItems().length)
        this.$addressPreview.html(renderAddress(this.getSelectedItems()[0].ajaxData.data))
    });
    this.onSelectionChanged.on((e) => {
      if (this.isTouchMode && e.addedItems.length)
        this.$addressPreview.html(renderAddress(e.addedItems[0].ajaxData.data));
      else
        this.$addressPreview.html(null);
    });
    // noinspection NsUnresolvedStyleClassReference
    this.$content = $("<div class=\"address-content\"></div>")
      .insertBefore(this.$element.find(".footer"))
      .on("mouseenter", () => this.mouseOverFooter = true)
      .on("mouseleave", () => this.mouseOverFooter = false);
    this.$createButton = $("<a></a>")
      .click(() => this.editAddress())
      .appendTo(this.$content)
      .wrap("<div></div>");
    this.setCreateButtonText();
    this.$addressPreview = $("<div></div>")
      .appendTo(this.$content);
    this.$dummyItem
        .on("mouseenter", () => this.mouseOverFooter = true)
        .on("mouseleave", () => this.mouseOverFooter = false);
    this.onItemsRendered.on((e) => {
      this.addMenuToItem(e)
    });
    // noinspection JSIgnoredPromiseFromCall
    this.preloadAddresses();
    this.bindCacheInvalidation();
  }

  protected get recentAddressCache() {
    return Utils.UnwrapFunction(this.options.addressSearchRange) === "all"
           ? AddressSelect.recentExternalAddressCache
           : AddressSelect.recentInternalAddressCache;
  }

  private _mouseOverFooter = false;

  private get mouseOverFooter() {
    return this._mouseOverFooter;
  }

  private set mouseOverFooter(value) {
    this._mouseOverFooter = value;
    if (!value) {
      this.highlightItem(undefined);
    }
  }

  private static getAjaxResultUrl(options: IAddressSelectOptions, mode: "search" | "recentlyUsed" = "search") {
    const allAddresses = window.baseApplicationUrl +
                         "/WebMethod/" +
                         (mode === "search" ? "SearchAddress" : "GetTopExternalAddresses");
    const entitiyAddresses = window.baseApplicationUrl +
                             "/WebMethod/" +
                             (mode === "search" ? "SearchInternalAddress" : "GetTopInternalAddresses");
    const searchRange = Utils.UnwrapFunction(options.addressSearchRange);
    if (searchRange) {
      if (searchRange === "all") {
        return allAddresses;
      }
      if (searchRange === "entity") {
        return entitiyAddresses;
      }
    } else if (options.addressEditOptions) {
      const template = Utils.UnwrapFunction(options.addressEditOptions.newAddressTemplate);
      if (template && template.isInternalAddress) {
        return entitiyAddresses;
      }
    }
    return allAddresses;
  }

  private static prepareOptions(options: IAddressSelectOptions) {
    options = Utils.ExtendOptions(AddressSelect.defaultAddressOptions, options);
    options.ajaxResultUrl =
      ((options: IAddressSelectOptions) => () => AddressSelect.getAjaxResultUrl(options))(options);
    options.search = true;
    return options;
  }

  getActiveSiteId() {
    const activeSiteId = Utils.UnwrapFunction(this.options.addressSearchRange) === "entity"
                         ? Utils.UnwrapFunction(this.options.entityId)
                         : Utils.UnwrapFunction(this.options.entitySetId);
    if (activeSiteId !== this.lastLoadedSiteId) {
      this.lastLoadedSiteId = activeSiteId;
      // noinspection JSIgnoredPromiseFromCall
      this.preloadAddresses();
    }
    return activeSiteId;
  }

  bindCacheInvalidation() {
    const sitePK = this.getActiveSiteId();
    this.recentAddressCache.onInvalidated.oneTime(sitePK,
                                                  () => {
                                                    if (!this.isOpen()) {
                                                      // noinspection JSIgnoredPromiseFromCall
                                                      this.preloadAddresses();
                                                    }
                                                    this.bindCacheInvalidation();
                                                  });
  }

  onOpenedHandler(event: ControlEvent<Dropdown, Event>): void {

  }

  onClosedHandler(event: ControlEvent<Dropdown, Event>): void {
    this.$filterTextbox.val("");
    this.setCreateButtonText();
    // noinspection JSIgnoredPromiseFromCall
    this.preloadAddresses();
  }

  onOpeningHandler(event: ControlEvent<Dropdown, Event>): void {

  }

  updateItem(
                              value: string | ISelectListItem<JSONDataContainer<JsonAddress>>,
                              data: JSONDataContainer<JsonAddress>,
                              createNewItem?: boolean): ISelectListItem<JSONDataContainer<JsonAddress>> {
    const item = super.updateItem(value as any, data, createNewItem);
    this.unselectItemIfCountryIsNotAllowedForBooking(item);
    return item;
  }

  private unselectItemIfCountryIsNotAllowedForBooking(item: ISelectListItem<JSONDataContainer<JsonAddress>>) {
    if (!item || ! this._bookingCountries)
      return;
    const selectedCountryItem =this._bookingCountries.firstOrDefault(c => c.value === item.ajaxData.data.country) ||
                                {} as Partial<JSONData>;
    if (Utils.UnwrapFunction(this.options.addressSearchRange) === "entity" &&
        (item.ajaxData.data.sitePK !== Utils.UnwrapFunction(this.options.entityId) ||
         selectedCountryItem.showWarningOnSelect)) {
      this.unselectItems(item, true, true);
    }
  }

  updateAddressInOtherSelects(value: string, data: JSONDataContainer<JsonAddress>) {
    for (const select of AddressSelect.addressSelects) {
      if (select === this) {
        continue;
      }
      select.updateItem(value, data);
    }
  }

  deleteAddressInOtherSelects(value: string) {
    for (const select of AddressSelect.addressSelects) {
      if (select === this) {
        continue;
      }
      for (const item of select.getItems()) {
        if (item.value === value) {
          select.removeItem(item);
          break;
        }
      }
    }
  }

  async preloadAddresses(force = false): Promise<void> {
    if (this.preloading || this.isOpen()) {
      if (force)
        await AwaitCondition((() => !this.preloading), 10, 10000);
      else
        return;
    }
    this.preloading = true;
    if (window.isOffline)
      return;
    this.showLoading();
    const data = {
      sitePK: this.getActiveSiteId(),
      top: 20
    };
    if (!data.sitePK) {
      this.renderDummy();
      this.hideLoading();
      this.preloading = false;
      return;
    }
    const cacheState = this.recentAddressCache.getCacheItemState(data.sitePK);
    let preloadedAddresses: JSONDataContainer<JsonAddress>[];
    if (cacheState === "cached" || cacheState === "loading") {
      preloadedAddresses = await this.recentAddressCache.getCachedItem(data.sitePK);
    } else {
      preloadedAddresses =
        await this.recentAddressCache.reloadCacheItem(
          data.sitePK,
          $.getJSON(this.getAjaxResultUrl("recentlyUsed"), data) as any);
    }
    await Utils.AwaitCondition(() => this.isOpen() === false || this.filter() === "", 200);
    const selectedItem = this.getSelectedItems()[0];
    if (selectedItem) {
      let selectedItemIsInPreloaded = false;
      for (const address of preloadedAddresses) {
        if (selectedItem.ajaxData.value === address.value) {
          selectedItemIsInPreloaded = true;
          break;
        }
      }
      if (!selectedItemIsInPreloaded) {
        preloadedAddresses.unshift(selectedItem.ajaxData);
        preloadedAddresses.pop();
      }
    }
    this.processJsonData(preloadedAddresses);
    this.lastPreloadedAddresses = preloadedAddresses;
    if (preloadedAddresses.length) {
      this.renderDummy("Search for more results");
    } else {
      this.renderDummy();
    }
    this.hideLoading();
    if (!this.filter()) {
      while (this.loadingCount > 0) {
        this.hideLoading();
      }
    }
    this.preloading = false;
  }

  applyFilter(): void {
    this.setCreateButtonText();
    if (this.filter().length === 0) {
      if (this.lastPreloadedAddresses) {
        this.processJsonData(this.lastPreloadedAddresses);
        if (this.lastPreloadedAddresses.length) {
          this.renderDummy("Search for more results");
        } else {
          this.renderDummy();
        }
      }
    } else {
      super.applyFilter();
      if (this.currentItems.length === 0) {
        this.setCreateButtonText(this.filter());
        this.renderDummy("No results found in address book")
      }
    }
  }

  initializeAddressEdit() {
    if (this.addressEdit) {
      return;
    }
    const addressModalContent = $("<div class='address-edit-modal'></div>");
    if (!this.options.addressEditOptions.newAddressTemplate) {
      this.options.addressEditOptions.newAddressTemplate = () => {
        const isInternalAddress = Utils.UnwrapFunction(this.options.addressType) === "internal" ||
                                  Utils.UnwrapFunction(this.options.addressSearchRange) === "entity";
        return {
          isExternalAddress: !isInternalAddress,
          isInternalAddress: isInternalAddress,
          sitePK: Utils.UnwrapFunction(this.options.entityId)
        } as Partial<JsonAddress>;
      };
    }
    if (typeof this.options.addressEditOptions.allowEntitySelection !== "function") {
      this.options.addressEditOptions.allowEntitySelection =
        () => Utils.UnwrapFunction(this.options.addressSearchRange) !== "entity";
    }
    this.$saveButton = $("<button>")
      .text("Save")
      .css({
             "min-width": "110px",
             "text-align": "center"
           })
      .addClass("button")
      .on("click",
          () => {
            // noinspection JSIgnoredPromiseFromCall
            this.onAddressSave();
          })
      .disableWhenOffline();
    this.$cancelButton = $("<button>")
      .text("Cancel")
      .attr("type", "button")
      .addClass("button secondary")
      .on("click",
        () => {
            this.addressEdit.resetValidation();
          if (this.addressEdit.hasChanged) {
              let unsavedChanges: UnsavedChangesModal = new UnsavedChangesModal(() => {
                this.editModal.closeModal();
                return true;
              });
              unsavedChanges.openModal();
            } else {
              this.editModal.closeModal();
            }
          });
    this.editModal = new Modal(null,
      {
        type: "form",
        contentElement: addressModalContent[0],
        buttons: {
          save: this.$saveButton,
          cancel: this.$cancelButton
        }
      },
      true,
      () => {
        this.addressEdit.resetValidation();
        if (this.addressEdit.hasChanged) {
          let unsavedChanges: UnsavedChangesModal = new UnsavedChangesModal(() => {
            this.editModal.closeModal();
            return true;
          });
          unsavedChanges.openModal();
        } else {
          this.editModal.closeModal();
          return true;
        }
        return false

      }
    );

    this.editModal.onClosed.on(() => {
      if (document.body.classList.contains("open-touch-dropdown")) {
        if (!this.isOpen())
          document.body.classList.remove("open-touch-dropdown");
        this.editModal.$modalContainer.classList.remove("dropdown", "touch", "fullscreen");
      }
    });
    this.addressEdit = new AddressEdit(addressModalContent,
                                       $.extend(this.options.addressEditOptions,
                                                {$header: this.editModal.$header}));
    this.addressEdit.addressTypeSelectionEnabled = !this.options.addressType;
  }

  async onAddressSave() {
    this.$cancelButton.prop("disabled", true);
    this.$saveButton.prop("disabled", true);
    if (await this.addressEdit.isValid) {
      this.$saveButton
          .addClass("loading")
          .text("Saving");
      const savedAddress: JsonAddress = Utils.PrepareJsonDataToServer(Utils.replaceEmptyStringWithNull(
        this.addressEdit.address,
        true));
      const disableTimeout = window.setTimeout(() => this.disable(), 200);
      let addressCode: string = null;
      try {
        addressCode = await $.post({
                                     url: window.baseApplicationUrl + "/WebMethod/AddAddress",

                                     data: Utils.ConvertObjectToFormData(savedAddress)
                                   });
      } catch (e) {

      }
      window.clearTimeout(disableTimeout);
      this.enable();
      if (addressCode === null) {
        openAlert({
                    type: "error",
                    content:
                      "Unable to save the address. Please contact your Röhlig representative if this issue persists."
                  });
      } else {
        this.editModal.closeModal();
        let addressItem: ISelectListItem<JSONDataContainer<JsonAddress>>;
        let displayName = savedAddress.displayName;
        if (!displayName) {
          if (savedAddress.isInternalAddress) {
            displayName = [savedAddress.name, savedAddress.city].join(" - ", true);
          } else {
            displayName = savedAddress.name;
          }
        }
        if (savedAddress.code) {
          const originalAddressCode = savedAddress.code;
          //Address was updated
          for (const item of this.items) {
            if (item.value !== savedAddress.code) {
              continue;
            }
            savedAddress.code = addressCode;
            item.ajaxData.data = savedAddress as JsonAddress;
            item.ajaxData.label = displayName;
            item.ajaxData.value = addressCode;
            this.updateItem(item, item.ajaxData);
            this.updateAddressInOtherSelects(originalAddressCode, $.extend(true, {}, item.ajaxData));
            addressItem = item;
            break;
          }
        } else {
          //New address
          const option = $(document.createElement("option"))
            .attr({value: addressCode})
            .data("ajax-data",
                  Utils.WrapInJsonContainer(savedAddress,
                                            {
                                              value: addressCode,
                                              label: displayName
                                            }))
            .text(displayName)
            .appendTo(this.$selectElement);
          savedAddress.code = addressCode;
          addressItem = this.createItem(option[0] as HTMLOptionElement);
        }
        const selectedCountryItem = (this._bookingCountries.firstOrDefault(
          c => c.value === savedAddress.country) ||
                                     {} as Partial<JSONData>);
        if (this.selectedItems.length && this.selectedItems[0] !== addressItem) {
          this.unselectItems();
        }
        if (Utils.UnwrapFunction(this.options.addressSearchRange) === "entity" &&
            (savedAddress.sitePK !== Utils.UnwrapFunction(this.options.entityId) ||
             selectedCountryItem.showWarningOnSelect)) {
          this.unselectItems(addressItem, true, true);
        } else {
          this.selectItems(addressItem, true, true);
        }
        this.close();
        this.clearCurrentItems();
        this.renderItems();
        AddressSelect.recentExternalAddressCache.invalidateCache();
        AddressSelect.recentInternalAddressCache.invalidateCache();
      }
      this.$saveButton
          .removeClass("loading")
          .text("Save");
    }
    this.$cancelButton.prop("disabled", false);
    this.$saveButton.prop("disabled", false);
  }

  editAddress(address?: Partial<JsonAddress>): void {
    const isNewAddress = !address;
    if (isNewAddress) {
      address = createDeepCopy(Utils.UnwrapFunction(this.options.addressEditOptions.newAddressTemplate) || {});
      if (this.newAddressName) {
        address.name = this.newAddressName
      }
    }
    if (!this.addressEdit) {
      this.initializeAddressEdit();
    }
    if (this.dropdown.isFullscreenMode) {
      this.editModal.$modalContainer.classList.add("dropdown", "touch", "fullscreen");
    }
    this.addressEdit.addressTypeSelectionEnabled = !Utils.UnwrapFunction(this.options.addressType);
    this.addressEdit._initialState.address1 = address.address1 ? address.address1 : "";
    this.addressEdit._initialState.address2 = address.address2 ? address.address2 : "";
    this.addressEdit._initialState.displayName = address.displayName ? address.displayName : "";
    this.addressEdit._initialState.name = address.name ? address.name : "";
    this.addressEdit._initialState.city = address.city ? address.city : "";
    this.addressEdit._initialState.country = address.country ? address.country : "";
    this.addressEdit._initialState.state = address.state ? address.state : "";
    this.addressEdit._initialState.postCode = address.postCode ? address.postCode : "";
    this.addressEdit.address = null;
    this.addressEdit.address = address;
    this.editModal.openModal();
    if (this.dropdown.isFullscreenMode) {
      document.body.classList.remove("no-scroll");
      document.firstElementChild.classList.remove("no-scroll");
      document.scrollingElement.scrollTop = 0;
    }
    if (isNewAddress) {
      this.addressEdit.focus();
    }
  }

  protected controlKeyHandlerExtender(key: key): boolean {
    if (key === "Enter" && this.newAddressName) {
      this.editAddress();
      return true;
    }
    return super.controlKeyHandlerExtender(key);
  }

  protected setCreateButtonText(name?: string) {
    if (name) {
      this.newAddressName = name;
      this.$createButton.text(`Add '${name}'`);
    } else {
      this.newAddressName = "";
      this.$createButton.text("Add Address");
    }
  }

  protected highlightItem(index?: number | HTMLElement): void {
    if (this.contextMenuOpened)
      return;
    super.highlightItem(index);
    if (this.isTouchMode)
      return;
    const highlightedItem = this.highlightedItem;
    window.setTimeout(() => {
                        if (this.highlightedItem !== highlightedItem) {
                          return;
                        }
                        if (highlightedItem) {
                          this.$addressPreview.html(
                            renderAddress(this.highlightedItem.ajaxData.data));
                          if (Utils.UnwrapFunction(this.options.shrinkToVisible)) {
                            this.dropdown.popup.reposition();
                            this.dropdown.popup.cutInvisibleBits();
                          }
                          this.dropdown.popup.$element.scrollintoview();
                        } else if (this.$addressPreview && !this.mouseOverFooter) {
                          this.$addressPreview.html(null);
                          if (Utils.UnwrapFunction(this.options.shrinkToVisible)) {
                            this.dropdown.popup.reposition();
                          }
                        }
                      },
                      index === undefined ? 200 : 0);
  }

  private closeContextMenu: () => void = () => {
  };

  private getAjaxResultUrl(mode: "search" | "recentlyUsed") {
    return AddressSelect.getAjaxResultUrl(this.options, mode);
  }

  private addMenuToItem(event: RenderedEvent<this, HTMLLIElement>) {
    for (const item of event.items) {
      const $menu = $(AddressSelect.menuTemplate);
      //language=html
      // noinspection NsUnresolvedStyleClassReference
      const $menuToggle = $(`<i class="material-icons menu-toggle icon-button" data-no-selector>more_vert</i>`)
        .appendTo(item);
      const dropdown = new Dropdown($menu,
                                    $menuToggle,
                                    {
                                      autoPosition: false,
                                      "class": "context-menu",
                                      css: {"marginRight": "7px"},
                                      position: Position.Bottom,
                                      alignment: Alignment.End,
                                      fullscreenOnTouch: false
                                    });
      $menu.filter(".delete")
           .touchClick(() => {
             dropdown.close();
             // noinspection JSIgnoredPromiseFromCall
             this.deleteAddress(item);
           });
      $menu.filter(".edit")
           .touchClick(() => {
             dropdown.close();
             this.editAddress(this.getItemFromElement(item).ajaxData.data);
           });
      dropdown.onOpening.on(e => {
        if (!this.dropdown.interactionEnabled)
          e.preventDefault();
      });
      dropdown.onOpened.on(
        () => {
          this.contextMenuOpened = false;
          this.highlightItem(item);
          this.contextMenuOpened = true;
          this.closeContextMenu = dropdown.close;
        });
      dropdown.onClosed.on(
        () => {
          if (item.classList.contains("highlight")) {
            this.contextMenuOpened = false;
          }
        });
    }
  }

  private async deleteAddress(element: HTMLLIElement) {
    const item = this.getItemFromElement(element);
    const address = item.ajaxData.data;
    const displayName = address.displayName ||
                        (address.isExternalAddress
                         ? address.name
                         : [address.name, address.city].join(" - ", true));
    const $alert =
      openAlert({
                  type: "error",
                  content: `Are you sure you want to delete "${displayName}"?`,
                  timeout: false,
                  buttons: [
                    $("<button class=\"button\">Delete</button>")
                      .on("click",
                          async () => {
                            $("button.close", $alert).click();
                            try {
                              await $.ajax({
                                             url: window.baseApplicationUrl +
                                                  "/WebMethod/DeleteAddress",
                                             method: "DELETE",
                                             data: {code: item.value}
                                           });
                              this.removeItem(item);
                              this.deleteAddressInOtherSelects(item.value);
                              AddressSelect.recentExternalAddressCache.invalidateCache();
                              AddressSelect.recentInternalAddressCache.invalidateCache();
                              this.renderItems();
                            } catch (e) {
                              openAlert({
                                          type: "error",
                                          content:
                                            "An error occured and the address could not be deleted. Please contact your Röhlig representative if this issues persists."
                                        });
                            }
                          }),
                    $("<button class=\"button\">Cancel</button>")
                      .on("click",
                          () => {
                            $("button.close", $alert).click();
                          })
                  ]
                });
    this.onClosed.oneTime(() => closeAlert($alert));
  }
}

declare global {
  interface JQuery {
    addressSelect(options?: IAddressSelectOptions): JQuery;

    addressSelect(loadDataSettings: true): JQuery;
  }
}

jQuery.fn.addressSelect = function (this: JQuery, options?: IAddressSelectOptions | true) {
  return this.each((index, elem) => {
    const $elem = $(elem);
    if ($elem.data("address-select")) {
      return;
    }
    if (options === true) {
      options = $elem.getJsonFromAttribute<IAddressSelectOptions>("data-select-settings") ||
                $elem.getJsonFromAttribute<IAddressSelectOptions>("data-settings");
    }
    // ReSharper disable once WrongExpressionStatement
    new AddressSelect(elem as HTMLSelectElement, options);
  });
};
