<div class="page-entity-list">
  <div class="page-wrap">
    <main>
      <section class="padding">
        <div class="search-bar button-container no-wrap">
          <span class="title">Entities</span>
          <div class="button-group phone-block grow">
            <div class="search-input" style="width:auto">
              <div class="grid">
                <button class="button mat-icon-search text-hide" (click)="search()">Search</button>
                <p class="input-search">
                  <input name="searchText"
                         type="search"
                         placeholder="Search Entities"
                         ngDefaultControl
                         (keydown.enter)="search()"
                         [storeAccessor]="searchStringStoreAccessor"/>
                </p>
              </div>
            </div>
            <a class="button navigation-button"
               *ngIf="userCanCreateEntitySets$ | async"
               routerLink="/SiteManagement/AddSite"
            ><span class="mat-icon-add"></span>Add entity</a>
          </div>
        </div>

        <div id="entities" class="tables entities-content">
          <ng-container *ngIf="isSearching">
            <h2>Entities</h2>
            <rt-skeleton-box *ngFor="let item of [].constructor(skeletonItemCount)"
                             class="entity-list-box"></rt-skeleton-box>
          </ng-container>
          <ng-container *ngIf="!isSearching && (searchResults$ | async | any)">
            <h2>Entities</h2>
            <rt-entity-set *ngFor="let entity of (searchResults$ | async); trackBy: trackById"
                           [entity]="entity"></rt-entity-set>
          </ng-container>
          <p class="no-results-found" *ngIf="!isSearching && (hasNoSearchResults$ | async)">No Entities found</p>
          <p class="load-more" *ngIf="!isSearching && (moreResultsAvailable$ | async)">
            <button class="button"
                    (click)="loadMore()"
                    [ngClass]="{loading: isLoadingMore}"
                    [disabled]="isLoadingMore">Load more
            </button>
          </p>
        </div>

        <rt-entity-info-sidebar rtSticky></rt-entity-info-sidebar>
      </section>
    </main>
  </div>
</div>
