import {EventEmitter} from "../utils/EventEmitter"
import {ControlEvent, RenderedEvent} from "../libs/ExtendableEvent"
import {Popup} from "./Popup"
import {Alignment, PopupPosition, Position} from "../utils/Enums";
import {
    disableScrolling,
    enableScrolling,
    ExtendOptions,
    getTabbableElements,
    IsPointInElement,
    UnwrapContainerFunction,
    UnwrapFunction
} from "../utils/Utils";

import {HistoryManager} from "../utils/HistoryManager"
import detect = require("../utils/detect");

require("../utils/JqueryLoader");

export class Dropdown implements IControl<JQuery> {
    private static defaultOptions: IDropdownOptions = {
        openOnFocus: true,
        closeOnFocusLoss: true,
        position: Position.Bottom,
        autoPosition: false,
        autoChangeDirection: false,
        container: document.body,
        alignment: Alignment.Stretch,
        allowGrowing: true,
        hidingMode: "detach",
        fullscreenOnTouch: true
    };
    readonly onClosing = new EventEmitter<ControlEvent<this>>("closing", () => this.eventElements());
    readonly onClosed = new EventEmitter<ControlEvent<this>>("closed", () => this.eventElements());
    readonly onOpened = new EventEmitter<ControlEvent<this>>("opened", () => this.eventElements());
    readonly onOpening = new EventEmitter<ControlEvent<this>>("opening", () => this.eventElements());
    readonly onInit = new EventEmitter<ControlEvent<this>>("dropdown-init", () => this.eventElements());
    readonly onRendering = new EventEmitter<ControlEvent<this>>("rendering", () => this.eventElements());
    readonly onRendered = new EventEmitter<RenderedEvent<this, JQuery>>("rendered", () => this.eventElements());
    readonly onKeyDown = new EventEmitter<ControlEvent<this, JQueryKeyEventObject>>("rendered",
                                                                                    () => this.eventElements());
    readonly popup: Popup;
    readonly options: IDropdownOptions;
    readonly $toggleControl: JQuery;
    interactionEnabled = true;
    protected readonly $visualContainer!: JQuery;
    protected openOnFocus = true;
    protected interactionTimer?: number;
    protected $backdrop = $();
    private readonly externalElements: HTMLElement[] = [];

    constructor(content: ContainerType, toggleControl: ContainerType, options?: IDropdownOptions) {
        //needed for Angular
        HistoryManager;
        this.options = ExtendOptions(Dropdown.defaultOptions, options)!;
        this.$toggleControl = $(UnwrapContainerFunction(toggleControl))
            .data("dropdown", this)
            .touchSaveOn("mousedown", "touchstart", (e) => {
                if (e.type === "mousedown" && !detect.touchDevice) {
                    this.tapHandler(e);
                }
                //e.stopPropagation();
            })
            .touchClick((e) => {
                //e.stopPropagation();
                let originalEvent = e as Event;
                while (originalEvent.originalEvent) {
                    originalEvent = originalEvent.originalEvent;
                }
                if (originalEvent.type === "touchend") {
                    this.tapHandler(e);
                }
            });
        if (!this.options.container) {
            this.options.container = () => {
                var $parentModal = this.$toggleControl.parents(".modal-window:first");
                if ($parentModal.length)
                    return $parentModal;
                return null;
            }
        }
        const visualContainer = this.options.visualContainer;
        if (typeof visualContainer === "string") {
            if (visualContainer.toLowerCase() === "parent")
                this.options.visualContainer = this.$toggleControl.parent();
            else if (visualContainer.toLowerCase() === "button-group") {
                const group = this.$toggleControl.parents(".double-button").first();
                if (group.length)
                    this.options.visualContainer = group;
                else
                    this.options.visualContainer = undefined;

            }
        }
        $(document).on("focus", () => this.openOnFocus = true)
                   .on("blur", (e) => {
                       this.openOnFocus = false;
                       //this.internalClose(e);
                   });
        if (this.options.visualContainer)
            this.$visualContainer = $(UnwrapContainerFunction(this.options.visualContainer));
        this.popup = new Popup(content,
                               {
                                   position: PopupPosition.FromPosition(UnwrapFunction(this.options.position)!),
                                   visualContainer: this.options.visualContainer || this.$toggleControl,
                                   css: this.options.css,
                                   "class": this.options.class ? `dropdown ${this.options.class}` : "dropdown",
                                   container: this.options.container,
                                   horizontalAlignment: this.options.alignment,
                                   autoChangeDirection: this.options.autoChangeDirection,
                                   autoPosition: detect.os.ios ? false : this.options.autoPosition,
                                   hidingMode: this.options.hidingMode === "detach" ? "detach" : "hide",
                                   allowGrowing: this.options.allowGrowing,
                                   shrinkToVisible: this.options.shrinkToVisible,
                                   fixAlignment: this.options.fixAlignment,
                                   disablePositioning: () => this.isTouchMode &&
                                                             UnwrapFunction(this.options.fullscreenOnTouch) === true
                               });
        if (this.options.hidingMode === "appendToToggle")
            this.popup.$element.insertAfter(this.$toggleControl);
        this.popup.$element
            .data("dropdown", this)
            .attr("tabindex", -1)
            .on("keydown", this.popupKeyHandler);
        this.$toggleControl
            .data("dropdown", this)
            .keydown(this.keyHandler)
            .addClass("dropdown-toggle");
        this.popup.onRepositioned.on((e) => {
            this.popup.$element.removeClass("dropup dropleft dropright");
            if (e.data === PopupPosition.OutsideTop)
                this.popup.$element.addClass("dropup");
            else if (e.data === PopupPosition.OutsideLeft)
                this.popup.$element.addClass("dropleft");
            else if (e.data === PopupPosition.OutsideRight)
                this.popup.$element.addClass("dropright");
        });
        if (this.options.openOnFocus) {
            this.$toggleControl
                .attr("tabindex", 0)
                [0].addEventListener("focusin", this.focusinHandler);
        }
        if (this.options.closeOnFocusLoss) {
            this.$toggleControl[0].addEventListener("focusout", this.focusoutHandler);
            this.popup.$element[0].addEventListener("focusout", this.focusoutHandler);
        }
        this.onInit.fire(new ControlEvent(this), [this.$toggleControl]);
        this.popup.onRendering.on((e) => {
            if (this.onRendering.fire(new ControlEvent(this))!.defaultPrevented)
                e.preventDefault();
        });
        this.popup.onRendered.on((e) => {
            if (this.onRendered.fire(new RenderedEvent(this, e.items))!.defaultPrevented)
                e.preventDefault();
        });
    }

    addExternalElement($element: JQuery): void;
    addExternalElement(element: HTMLElement): void;
    addExternalElement(element: HTMLElement | JQuery) {
        if (!element)
            return;
        if (element instanceof HTMLElement)
            return this.addExternalElement($(element));
        element.toArray()
               .filter(e => !this.externalElements.contains(e))
               .forEach(e => {
                   e.addEventListener("focusout", this.focusoutHandler);
                   this.externalElements.push(e);
               })
    }

    removeExternalElement($element: JQuery): void;
    removeExternalElement(element: HTMLElement): void;
    removeExternalElement(element: HTMLElement | JQuery) {
        if (!element)
            return;
        if (element instanceof HTMLElement)
            return this.removeExternalElement($(element));
        element.toArray()
               .filter(e => this.externalElements.contains(e))
               .forEach(e => {
                   e.removeEventListener("focusout", this.focusoutHandler);
                   this.externalElements.remove(e);
               })
    }

    get isTouchMode(): boolean {
        return this.popup.$element.hasClass("touch");
    }

    get isFullscreenMode(): boolean {
        return this.popup.$element.hasClass("fullscreen");
    }

    toggle = () => {
        this.internalToggle();
    };

    open = (touchMode = false) => {
        if (touchMode)
            this.internalOpen(new CustomEvent("touch"));
        else
            this.internalOpen();
    };

    close = () => {
        this.internalClose();
    };

    isOpen = () => this.popup.isOpen();

    isEnabled = () => this.$toggleControl.attr("disabled") !== "disabled";

    disable = () => {
        this.$toggleControl.attr("disabled", "disabled");
        this.internalClose();
    };

    enable = () => {
        this.$toggleControl.removeAttr("disabled")
    };

    eventElements() {
        if (this.popup)
            return [this.popup.$element, this.$toggleControl];
        return [this.$toggleControl]
    }

    protected internalToggle = (sourceEvent?: Event) => {
        if (this.popup.isOpen())
            this.internalClose(sourceEvent);
        else
            this.internalOpen(sourceEvent);
    };

    protected internalOpen = (sourceEvent?: Event) => {
        if (this.popup.isOpen() || this.$toggleControl.is("[disabled]"))
            return;
        if (!this.onOpening.fire(new ControlEvent<this>(this, true, sourceEvent))!.defaultPrevented) {
            this.popup.$element.toggleClass("touch",
                                            !!(sourceEvent && sourceEvent.type.contains("touch")) ||
                                            document.body.classList.contains("open-touch-dropdown")
                                            || detect.touchDevice);
            this.disableInteraction(this.isTouchMode ? 400 : undefined);
            if (this.isTouchMode && UnwrapFunction(this.options.fullscreenOnTouch)) {
                disableScrolling();
                window.historyManager.pushState({
                                                    onSkip: (dir) => {
                                                        if (dir === "backwards" && this.isOpen()) {
                                                            this.close();
                                                            window.historyManager.deleteForwardHistory();
                                                        }
                                                    },
                                                    onLeave: (dir) => {
                                                        if (dir === "backwards" && this.isOpen()) {
                                                            this.close();
                                                            window.historyManager.deleteForwardHistory();
                                                        }
                                                    }
                                                })
            }
            if (this.options.hidingMode === "appendToToggle")
                this.popup.$element.appendTo(this.popup.getContainer());
            this.popup.open();
            if (this.isTouchMode && UnwrapFunction(this.options.fullscreenOnTouch)) {
                this.popup.$element.addClass("fullscreen");
                $(document.body).addClass("open-touch-dropdown");
                const dropdowns = $(document.body)
                    .children(".dropdown.touch")
                    .filter((i, e) => e !== this.popup.$element[0]);
                if (dropdowns.length)
                    this.popup.$element.insertBefore(dropdowns.first());
                else
                    this.popup.$element.appendTo(document.body);
                document.scrollingElement!.scrollTop = 0;
            }
            // window.setTimeout( () => {
            //     this.popup.$element.css("border-top-color",
            //                             window.getComputedStyle(this.$toggleControl[0]).borderBottomColor);
            // }, 100);
            this.$toggleControl.addClass("dropdown-open");
            if (this.$visualContainer && this.$visualContainer.length)
                this.$visualContainer.addClass("dropdown-open");
            $(document).touchClick(this.tapHandler);
            this.onOpened.fire(new ControlEvent<this>(this, false, sourceEvent));
        }
    };

    protected internalClose = (sourceEvent?: Event) => {
        if (!this.popup.isOpen()) {
            return;
        }
        if (!this.onClosing.fire(new ControlEvent<this>(this, true, sourceEvent)).defaultPrevented) {
            if (this.isElementInDropdown(document.activeElement)) {
                this.disableInteraction();
                this.$toggleControl.focus();
            }
            if ($(document.body)
                    .children(".dropdown.fullscreen")
                    .filter((i, elem) => elem !== this.popup.$element[0]).length === 0)
                $(document.body).removeClass("open-touch-dropdown");
            this.popup.$element.removeClass("fullscreen");
            this.popup.close();
            if (this.options.hidingMode === "appendToToggle")
                this.popup.$element.insertAfter(this.$toggleControl);
            this.$toggleControl.removeClass("dropdown-open");
            if (this.$visualContainer && this.$visualContainer.length)
                this.$visualContainer.removeClass("dropdown-open");
            $(document).off("touchclick", this.tapHandler);
            if (this.isTouchMode) {
                enableScrolling();
            }
            this.onClosed.fire(new ControlEvent<this>(this, false, sourceEvent));
        }
    };

    private popupKeyHandler = (e: JQueryKeyEventObject) => {
        let keyHandled = true;
        switch (e.key as key) {
            case "Tab":
                const step = e.shiftKey ? -1 : 1;
                const $tabbable = getTabbableElements();
                const activeIndex = $tabbable.index(document.activeElement);
                if (activeIndex !== -1 && this.popup.$element.has($tabbable.eq(activeIndex + step)[0])) {
                    keyHandled = false;
                    break;
                }
                const index = $tabbable.index(this.$toggleControl);
                if (index !== -1) {
                    if (e.shiftKey)
                        $tabbable.eq(index - 1).focus();
                    else
                        $tabbable.eq(index + 1).focus();
                }
                this.internalClose(e);
                break;
            case "Escape":
            case "Esc":
                this.internalClose(e);
                break;
            default:
                keyHandled = false;
        }
        if (keyHandled) {
            e.preventDefault();
            e.stopPropagation();

            return false;
        }
        return true;
    }

    private keyHandler = (e: JQueryKeyEventObject) => {
        if (this.onKeyDown.fire(new ControlEvent(this, true, e)).defaultPrevented)
            return;
        var keyHandled = true;
        switch (e.key as key) {
            case "ArrowDown":
            case "Down":
                this.internalOpen(e);
                break;
            case "ArrowUp":
            case "Up":
            case "Esc":
            case "Escape":
                this.internalClose(e);
                break;
            case " ":
            case "Spacebar":
            case "Enter":
                this.internalToggle(e);
                break;
            default:
                keyHandled = false;
        }
        if (keyHandled) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
        return true;
    };

    private isElementInDropdownOrToggleControl = (element: Element) =>
        this.isElementInToggleControl(element) || this.isElementInDropdown(element);

    private isElementInDropdown = (element: Element): boolean => {
        if ((element as any) === document)
            throw "null";
        if (this.popup.$element[0] === element
            || this.popup.$element.has(element).length > 0)
            return true;
        const dropdowns = $(element).parentsAndSelf(".dropdown.popup") as IArrayLike<HTMLElement>;
        for (const dropdown of dropdowns as HTMLElement[]) {
            if (this.isElementInDropdown($(dropdown).data("dropdown").$toggleControl[0]))
                return true;
        }
        for (const externalElement of this.externalElements) {
            if (externalElement === element)
                return true;
            if ($(externalElement).has(element).length)
                return true;
        }
        return false;
    };

    private isElementInToggleControl = (element: Element) =>
        this.$toggleControl[0] === element
        || this.$toggleControl.has(element).length > 0;

    private tapHandler = (e: JQueryEventObject) => {
        if (!this.interactionEnabled)
            return;
        if (this.isOpen() &&
            (IsPointInElement(this.popup.$element, e.clientX, e.clientY) ||
             e.target && $(e.target).parentsAndSelf().is(".dropdown-backdrop") ||
             this.isElementInDropdown(e.target))) {
            //this.disableInteraction();
            return;
        }
        let originalEvent = e as Event;
        while (originalEvent.originalEvent) {
            originalEvent = originalEvent.originalEvent;
        }
        if (e.type === "touchclick" && originalEvent.type !== "touchend" &&
            this.isElementInToggleControl(e.target as HTMLElement))
            return;
        if (this.isOpen() &&
            originalEvent instanceof MouseEvent &&
            IsPointInElement(this.popup.$element, originalEvent.clientX, originalEvent.clientY))
            return;
        if (this.isElementInToggleControl(e.target)) {
            if (!this.$toggleControl.is("[disabled]"))
                this.internalToggle(e.originalEvent || e);
        } else if (!this.isOpen())
            return;
        else
            this.internalClose(e.originalEvent || e);
    };

    private focusinHandler = async (e: FocusEvent) => {
        if (!this.interactionEnabled || !this.openOnFocus) {
            return;
        }
        console.log(this.$toggleControl[0].classList);

        if (this.isOpen() || this.isElementInDropdownOrToggleControl(e.relatedTarget as Element))
            return;
        if (this.$toggleControl.hasClass("touch-down") || this.$toggleControl[0].querySelector(".touch-down"))
            this.internalOpen(new CustomEvent("touch"));
        else
            this.internalOpen(e);
    };

    private focusoutHandler = (e: FocusEvent) => {
        if (!this.interactionEnabled) {
            return;
        }
        if (!this.isOpen() || this.isElementInDropdownOrToggleControl((e.relatedTarget || e.target) as Element)) {
            return;
        }
        this.internalClose(e);
    };

    private disableInteraction = (time?: number) => {

        if (time === undefined) {
            if (detect.os.ios)
                time = 300;
            else
                time = 20;
        }
        this.interactionEnabled = false;
        document.body.classList.add("no-interaction");
        if (this.interactionTimer)
            window.clearTimeout(this.interactionTimer);
        this.interactionTimer = window.setTimeout(() => {
                                                      this.interactionEnabled = true;
                                                      this.interactionTimer = null;
                                                      document.body.classList.remove("no-interaction");
                                                  },
                                                  time);
    };

}

export interface IDropdownOptions {
    /**
     * If true the dropdown will open once the @see toggleControl gets focus, otherwise only if it is clicked
     * @default true
     */
    openOnFocus?: boolean;
    /**
     * If true the dropdown will close if it looses focus
     * @default true
     */
    closeOnFocusLoss?: boolean;
    /**
     * Control where the dropdown will be visually attached to
     * @default toggleControl
     */
    visualContainer?: ContainerType;
    position?: FunctionOrValue<Position>;
    /**
     * If true the popup will be moved if it isn't fully in the viewport
     * @default false
     */
    autoPosition?: boolean;

    alignment?: FunctionOrValue<Alignment>;
    /**
     * If true the popup can grow to the size of its contents
     * @default true
     */
    allowGrowing?: FunctionOrValue<boolean>;
    /**
     * If true and autoPosition is also true, the vertical and horizontal alignments will be swapped when the popup changes position from left/right to top/bottom
     */
    autoSwapAlignment?: boolean;
    /**
     * Container element where the popup DOM will be inserted.
     * @default body
     */
    container?: ContainerType;
    css?: ICssStyles;
    class?: string;
    /**
     * Indicates if the direction of the should be change during auto positioning, or if the position should just flip between left/right and top/bottom
     * @default false
     */
    autoChangeDirection?: boolean;

    /**
     * Determines if the the dropdown should just be hidden or completly detached from the dom, when not visible. If 'hide' is selected the dropdown will be inserted after the toggle control when hidden
     * @default: detach
     */
    hidingMode?: "appendToToggle" | "detach" | "hide";
    /**
     * if true the sropdown will be shrinked so that is is completly inside the body element / all parent elements with overflow: hidden
     * @default true
     */
    shrinkToVisible?: FunctionOrValue<boolean>;
    /**
     * If true, alignment will not be changed to make the dropdown more visible
     * @default false
     */
    fixAlignment?: FunctionOrValue<boolean>;

    fullscreenOnTouch?: FunctionOrValue<boolean>
}

declare global {
    interface JQuery {
        dropdown(content: ContainerType, options?: IDropdownOptions): JQuery;

        dropdown(content: ContainerType, loadDataSettings: true): JQuery;
    }
}

jQuery.fn.dropdown = function (this: JQuery, content: ContainerType, options?: IDropdownOptions | true) {
    return this.each((index, elem) => {
        const $elem = $(this);
        if ($elem.data("dropdown"))
            return;
        if (options === true) {
            options = $elem.getJsonFromAttribute<IDropdownOptions>("data-dropdown-settings")
                      || $elem.getJsonFromAttribute<IDropdownOptions>("data-settings");
        }
        // ReSharper disable once WrongExpressionStatement
        new Dropdown(content, elem as HTMLElement, options);
    });
}
