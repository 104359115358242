import {ISelectListItem, ISelectListOptions, SelectList} from "./SelectList"
import {Dropdown} from "./Dropdown"
import {Alignment, Position, SelectItemCountMode, SelectOverflowMode} from "@utils/Enums";
import {EventEmitter} from "@utils/EventEmitter";
import {ControlEvent, RenderedEvent} from "../libs/ExtendableEvent";
import * as Utils from "../utils/Utils";
import {GetRecursionEnd, UnwrapFunction} from "@utils/Utils";
import {JSONData} from "@api";
import {ValidationField} from "@widgets/Validate";

require("../utils/JqueryLoader");


export interface ISelectOptions<T extends JSONData = JSONData> extends ISelectListOptions<T> {
  /**
   * If true the popup can grow to the size of its contents
   * @default true
   */
  allowGrowing?: FunctionOrValue<boolean>;
  /**
   * If set, this control will be used to open / close the select. There will be no fake-select created and the content of the element will not be altered.
   */
  toggleControl?: ContainerType;
  /**
   * Content that is displayed after the list
   */
  content?: ContainerType;
  alwaysPlaceholder?: boolean;
  /**
   * If true, the dropdown will be closed after an item has been selected
   */
  closeOnSelect?: FunctionOrValue<boolean>;
  /**
   * Text to show when no items are selected
   */
  placeholderNone?: string;
  /**
   * input text when all values selected - does not apply for server search
   */
  placeholderAll?: string;
  /**
   * If true, the number of selected items will be displayed for multi selects.
   * Only applies to overflowMode = cropValues
   * @default SelectItemCountMode.Overflow
   */
  displayTotalNo?: SelectItemCountMode;
  /**
   * If true, the select will open once it gets focus, otherwise only if it is clicked
   * @default true
   */
  openOnFocus?: boolean;
  /**
   * If true, the select will close if it looses focus
   * @default true
   */
  closeOnFocusLoss?: boolean;
  /**
   * @default Bottom
   */
  position?: FunctionOrValue<Position>;
  /**
   * If true, the popup will be moved if it isn't fully in the viewport
   * @default false
   */
  autoPosition?: boolean;

  alignment?: FunctionOrValue<Alignment>;
  /**
   * If true and autoPosition is also true, the vertical and horizontal alignments will be swapped when the popup changes position from left/right to top/bottom
   */
  autoSwapAlignment?: boolean;
  /**
   * Container element where the popup DOM will be inserted.
   * @default body
   */
  container?: ContainerType;
  /**
   * Control where the dropdown will be visually attached to
   * @default toggleControl
   */
  visualContainer?: ContainerType;
  popupCss?: ICssStyles;
  toggleControlCss?: ICssStyles;
  class?: string;
  /**
   * Indicates if the direction of the should be change during auto positioning, or if the position should just flip between left/right and top/bottom
   * @default false
   */
  autoChangeDirection?: boolean;
  /**
   * input text behaviour in case of overflow
   * @default SelectOverflowMode.CropValues
   */
  overflowMode?: SelectOverflowMode;
  /**
   * input text to show in case of overflow - only applies to overflowMode = itemCount
   * @default "{0} selected"
   */
  overflowText?: string;

  /**
   * Determines if the the select should just be hidden or completly detached from the dom, when not visible. If 'hide' is selected the select will be inserted after the toggle control when hidden
   * @default: detach
   */
  hidingMode?: "hide" | "detach";


  /**
   * Function that recive selected elements and returns HTML placeholder string.
   */
  placeholderRenderer?: (items: ISelectListItem<T>[]) => string;
  /**
   * if true the sropdown will be shrinked so that is is completly inside the body element / all parent elements with overflow: hidden
   * @default true
   */
  shrinkToVisible?: FunctionOrValue<boolean>;
  /**
   * If true, alignment will not be changed to make the dropdown more visible
   * @default false
   */
  fixAlignment?: FunctionOrValue<boolean>;
  /**
   * If true the search field will also be focused on touch devices
   */
  focusSearchOnTouchOpen?: FunctionOrValue<boolean>;
  onInitialized?: (select: Select<T>) => void;
  noTouchButtons?: boolean;
}

export class Select<T extends JSONData = JSONData> extends SelectList<T> {
  static defaultSelectOptions: ISelectOptions = {
    "class": "select",
    enterToSelect: true,
    displayTotalNo: SelectItemCountMode.Overflow,
    openOnFocus: true,
    closeOnFocusLoss: true,
    position: Position.Bottom,
    autoPosition: false,
    autoChangeDirection: false,
    overflowMode: SelectOverflowMode.CropValues,
    overflowText: "{0} selected",
    placeholderNone: "&nbsp;"
  };

  readonly onOpening = new EventEmitter<ControlEvent<Select>>("opening");
  readonly onOpened = new EventEmitter<ControlEvent<Select>>("opened");
  readonly onClosing = new EventEmitter<ControlEvent<Select>>("closing");
  readonly onClosed = new EventEmitter<ControlEvent<Select>>("closed");
  readonly onInit = new EventEmitter<ControlEvent<this>>("select-init", () => this.eventElements());
  readonly onRendering = new EventEmitter<ControlEvent<this>>("rendering", () => this.eventElements());
  readonly onRendered = new EventEmitter<RenderedEvent<this, JQuery>>("rendered", () => this.eventElements());
  readonly options: ISelectOptions<T>;
  readonly dropdown: Dropdown;
  readonly $fakeSelect: JQuery;
  $footer: JQuery;
  protected readonly selectionHandler: (e: Event | ISelectListItem<T>) => boolean;
  protected readonly $label: JQuery;
  protected readonly $text: JQuery;
  protected itemToSelectOnEnter: ISelectListItem<T>;
  private itemsRenderedAfterSelectionChanged = false;

  constructor(selectElement: HTMLSelectElement, options?: ISelectOptions<T>) {
    super(selectElement, Utils.ExtendOptions(
      Select.defaultSelectOptions,
      {focusSearchOnTouchOpen: () => this.hasExternalSearch()},
      options,
      {onInitialized: null})
    );
    if (typeof this.options.closeOnSelect !== "boolean" && !this.options.closeOnSelect && !this.options.multiple) {
      this.options.closeOnSelect = true;
    }
    this.$fakeSelect = this._createFakeSelect(selectElement);
    let prerendered = false;
    if (this.$fakeSelect.hasChild("span")) {
      this.$text = this.$fakeSelect.children("span:first");
      prerendered = true;
    } else
      this.$text = $(document.createElement("span"))
        .appendTo(this.$fakeSelect);
    this.$text
        .css({overflow: "hidden"});
    if (!prerendered)
      this.renderPlaceholderText();
    //this.$select.appendTo(this.$fakeSelect);
    if (this.$selectElement.attr("id")) {
      const label = this.$fakeSelect.closest(`label[for=${this.$selectElement.attr("id")}]`);
      if (label.length) {
        const id = this.$selectElement.attr("id") + "___fake-select";
        this.$fakeSelect.attr("id", id);
        label.attr("for", id);
      }
    }
    let toggleControl = this.options.toggleControl || this.$fakeSelect;
    if (toggleControl === "parent")
      toggleControl = this.selectElement.parentElement;
    this.dropdown = new Dropdown(this.$element,
                                 toggleControl,
                                 {
                                   openOnFocus: this.options.openOnFocus,
                                   closeOnFocusLoss: this.options.closeOnFocusLoss,
                                   position: this.options.position,
                                   autoPosition: this.options.autoPosition,
                                   alignment: this.options.alignment,
                                   autoSwapAlignment: this.options.autoSwapAlignment,
                                   container: this.options.container,
                                   css: this.options.popupCss,
                                   "class": this.options.class,
                                   autoChangeDirection: this.options.autoChangeDirection,
                                   visualContainer: this.options.visualContainer,
                                   hidingMode: this.options.hidingMode,
                                   allowGrowing: this.options.allowGrowing,
                                   shrinkToVisible: this.options.shrinkToVisible,
                                   fixAlignment: this.options.fixAlignment
                                 });
    if (!this.isEnabled()) {
      this.disable(true);
    }
    this.attachHandlers();
    if (this.options.toggleControl) {
      this.$fakeSelect.detach();
      this.$fakeSelect = this.dropdown.$toggleControl.data("select", this);
      //this.$select.detach().appendTo(this.$fakeSelect);
    }
    this.$selectElement.typedData("visual-element", this.$fakeSelect);

    //this.$select.detach().appendTo(this.$fakeSelect);

    this.selectionHandler = ((fn: (e: Event | ISelectListItem<T>) => void) => {
      return (e: Event | ISelectListItem<T>) => {
        if (!this.dropdown.interactionEnabled) {
          return;
        }
        const selectionChanged = fn.call(this, e);
        if (UnwrapFunction(this.options.closeOnSelect) && this.selectedItems.length && selectionChanged != null && !this.options.multiple) {
          if (e instanceof Event && e.originalEvent.type === "touchend") {
            //this.dropdown.popup.$element.css("opacity", 0);
            this.$fakeSelect.removeClass("dropdown-open");
            Utils.sleep(300)
                 .then(
                   () => {
                     this.close();
                     this.dropdown.popup.$element.css("opacity", 1);
                   });
          } else {
            this.close();
          }
        }

        return selectionChanged;
      };
    })(this.selectionHandler);

    this.onInit.fire(new ControlEvent(this), [this.$selectElement]);
    if (options.onInitialized)
      options.onInitialized(this);
    if (this.selectElement.id)
      $(`label[for='${this.selectElement.id}']`).click(e => {
        e.preventDefault();
        const customEvent = new CustomEvent(e.type);
        customEvent.originalEvent = e;
        this.dropdown.$toggleControl[0].dispatchEvent(customEvent);
        this.dropdown.toggle();
      });
  }

  get isTouchMode(): boolean {
    return this.dropdown.isTouchMode;
  }

  static GetPlaceholderText(text: string,
                            $text: JQuery,
                            options: ISelectOptions,
                            selectedItems: ISelectListItem[],
                            itemCount: number,
                            $fakeSelect: JQuery) {
    options = Utils.ExtendOptions(Select.defaultSelectOptions, SelectList.defaultOptions, options);
    if (options.placeholderRenderer) {
      $text.html(options.placeholderRenderer(selectedItems));
      return;
    }
    if (text) {
      $text.text(text);
      return;
    }
    const selectedItemCount = selectedItems.length;
    let hasOverflow = false;
    $fakeSelect.removeAttr("data-count");
    if (selectedItemCount === 0 || options.alwaysPlaceholder) {
      $text.html(options.placeholderNone || "&nbsp;");
    } else {
      if (!UnwrapFunction(options.ajaxResultUrl && !options.externalSearchHandler)
          && options.placeholderAll
          && itemCount === selectedItemCount) {
        $text.text(options.placeholderAll);
      } else {
        $text.text(selectedItems.map(i => i.contentString).join(", "));
        if ($text[0].scrollWidth > $text[0].clientWidth && options.multiple) {
          hasOverflow = true;
        }
      }
    }
    if (options.overflowMode === SelectOverflowMode.CropValues) {
      {
        if ((options.displayTotalNo === SelectItemCountMode.Always
             || (options.displayTotalNo === SelectItemCountMode.Overflow && hasOverflow))
            && selectedItemCount > 0) {
          $fakeSelect.attr("data-count", selectedItemCount);
        }
      }
    } else if (hasOverflow) $text.text(options.overflowText.format(selectedItemCount.toString()));
  }

  open(touchMode = false) {
    this.dropdown.open(touchMode);
  }

  close() {
    return this.dropdown.close();
  }

  toggle() {
    return this.dropdown.toggle();
  }

  disable(preventFormSubmitAndValidation?: boolean) {
    if (this.dropdown)
      this.dropdown.disable();
    super.disable(preventFormSubmitAndValidation);
  }

  enable() {
    if (this.dropdown)
      this.dropdown.enable();
    super.enable();
  }

  eventElements(): JQuery[] {
    if (this.dropdown)
      return super.eventElements().concat([this.dropdown.$toggleControl]);
    return super.eventElements();
  }

  renderPlaceholderText(): void;

  renderPlaceholderText(text: string): void;

  renderPlaceholderText(text?: string): void {
    if (this.options.placeholderRenderer) {
      this.$text.html(this.options.placeholderRenderer(this.getSelectedItems()));
      return;
    }
    if (text) {
      this.$text.text(text);
      return;
    }
    const selectedItemCount = this.getSelectedItems().length;
    var hasOverflow = false;
    this.$fakeSelect.removeAttr("data-count");
    if (selectedItemCount === 0 || this.options.alwaysPlaceholder) {
      this.$text.html(this.options.placeholderNone || "&nbsp;");
    } else {
      if (!this.hasExternalSearch()
          && this.options.placeholderAll
          && this.getItems().length === selectedItemCount) {
        this.$text.text(this.options.placeholderAll);
      } else {
        this.$text.html(this.getSelectedItems().map(i => i.contentString).join(", "));
        if (this.$text[0].scrollWidth > this.$text[0].clientWidth && this.options.multiple) {
          hasOverflow = true;
        }
      }
    }
    if (this.options.overflowMode === SelectOverflowMode.CropValues) {
      {
        if ((this.options.displayTotalNo === SelectItemCountMode.Always
             || (this.options.displayTotalNo === SelectItemCountMode.Overflow && hasOverflow))
            && selectedItemCount > 0) {
          this.$fakeSelect.attr("data-count", selectedItemCount);
        }
      }
    } else if (hasOverflow) this.$text.html(this.options.overflowText.format(selectedItemCount.toString()));
  }

  renderElement() {
    if (!super.renderElement())
      return false;
    const displayName = Utils.getFormFieldDisplayName(this.$selectElement);
    if (displayName || this.$filterTextbox) {
      let $header = $(document.createElement("div"))
        .addClass("header")
        .append(`<h2 class="hide-desktop">${displayName}</h2>`)
        .prependTo(this.$element);
      if (this.$filterTextbox)
        $header.append(this.$filterTextbox
                           .wrap("<div class='input-group'>")
                           .parent())
    }
    if (this.options.content)
      $(Utils.UnwrapContainerFunction(this.options.content)).appendTo(this.$element);
    this.$footer = $(document.createElement("div"))
      .addClass("footer")
      .appendTo(this.$element);
    if ((this.options.multiple || this.options.allowNoSelection) && !this.options.noTouchButtons)
      $(document.createElement("button"))
        .attr("type", "button")
        .addClass("button hide-desktop")
        .text("Ok")
        .click(() => this.close())
        .appendTo(this.$footer);
    if (this.options.multiple)
      $(document.createElement("button"))
        .attr("type", "button")
        .addClass("secondary button hide-desktop")
        .text("Clear")
        .click(() => this.unselectItems(true, true))
        .appendTo(this.$footer);
    return true;
  }

  updateItem(value: string, data: T, createNewItem?: boolean): ISelectListItem<T>;
  updateItem(item: ISelectListItem<T>, data: T, createNewItem?: boolean): ISelectListItem<T>;
  updateItem(value: string | ISelectListItem<T>, data: T, createNewItem?: boolean): ISelectListItem<T> {
    const item = super.updateItem(value as any, data, createNewItem);
    if (item)
      this.renderPlaceholderText();
    return item;
  }

  isOpen() {
    return !!(this.dropdown && this.dropdown.isOpen());
  }

  focus() {
    if (this.isOpen()) {
      super.focus();
    }
  };

  // ReSharper disable once UseOfPossiblyUnassignedProperty
  async renderItems(force?: boolean) {
    if (!force && !this.isOpen() && this.itemsRendered &&
        !(this.hasExternalSearch() || this.options.search || UnwrapFunction(this.options.selectedOnTop))) {
      return;
    }
    this.itemsRenderedAfterSelectionChanged = true;
    if (this.currentItems.length === 1) {
      this.itemToSelectOnEnter = this.currentItems[0];
    } else
      this.itemToSelectOnEnter = null;
    if (this.isOpen())
      super.renderItems();
    else {
      await Utils.sleep(100);
      super.renderItems();
    }
  }

  protected applyFilter(): void {
    super.applyFilter();
    const listItems = this.$list.children("li:visible:not([disabled]):not(.placeholder)");
    if (this.currentItems.length === 1) {
      this.itemToSelectOnEnter = this.currentItems[0];
    } else if (listItems.length === 1) {
      this.itemToSelectOnEnter = this.getItemFromElement(listItems);
    } else {
      this.itemToSelectOnEnter = null;
    }
  }

  protected controlKeyHandlerExtender(key: key): boolean {
    if (key === "Enter") {
      if (document.activeElement !== this.$filterTextbox[0]) {
        if (this.options.enterToSelect && this.highlightedItem) {
          this.selectionHandler(this.highlightedItem);
        } else if (this.itemToSelectOnEnter)
          this.close();
        return true;
      } else if (this.itemToSelectOnEnter) {
        this.selectItems(this.itemToSelectOnEnter, false, true);
        this.close();
        return true;
      }
    } else if (key === "Esc" || key === "Escape") {
      this.close();
      return true;
    } else if ((key === "Up" || key === "ArrowUp")
               && (!this.highlightedItem || (!this.highlightedItem.$element.prev().is() && !this.options.search))) {
      this.close();
      return true;
    }
    return super.controlKeyHandlerExtender(key);
  }

  protected highlightItem(index?: number | HTMLElement) {
    if ((index || typeof index === "number") && !this.dropdown.interactionEnabled)
      return;
    if (this.dropdown.isTouchMode)
      return;
    super.highlightItem(index);
  }

  protected onOpenedHandler(event: ControlEvent<Dropdown, Event>) {
    if (!this.items) {
      this.renderElement();
      this.dropdown.popup.cutInvisibleBits();
    } else if (UnwrapFunction(this.options.selectedOnTop))
      this.selectedItems
          .map(i => i.$element)
          .reverse()
          .forEach(e => {
            this.$list.prepend(e);
            e.html(e.html().replace(/<\/?mark>/g, ""))
          });

  }

  protected onClosedHandler(event: ControlEvent<Dropdown, Event>) {
    if (this.filter().length || this.hasExternalSearch()) {
      this.renderElement();
    } else if (UnwrapFunction(this.options.selectedOnTop)) {
      this.sortItems();
    }
  }

  protected onClosingHandler(event: ControlEvent<Dropdown, Event>) {

  }

  protected onOpeningHandler(event: ControlEvent<Dropdown, Event>) {
    // if (this.itemsRendered &&
    //     (UnwrapFunction(this.options.ajaxResultUrl))) {
    //     this.$list.empty();
    //     this.itemsRenderedAfterSelectionChanged = false;
    // }
  }

  private sortItems() {
    if (!this.itemsRendered)
      return;
    this.$list.empty();
    const sortedItems = this.getSortedItems()
    const tempContainer = document.createDocumentFragment();
    sortedItems.forEach(i => tempContainer.appendChild(i.$element[0]));
    this.$list.append(tempContainer);
  }

  private _createFakeSelect(selectElement: HTMLSelectElement) {
    let $fakeSelect: JQuery;
    if (this.$selectElement.next().next().hasClass("fake-select"))
      $fakeSelect = this.$selectElement.next().next();
    else
      $fakeSelect = $("<div class=\"fake-select custom-select\"></div>")
        .insertAfter(selectElement);
    $fakeSelect
      .addClass(this.$selectElement.attr("class") || "")
      .attr("tabindex", "0")
      .data("select", this);
    if (this.options.toggleControlCss) {
      $fakeSelect.css(this.options.toggleControlCss);
    }
    return $fakeSelect;
  }

  private attachHandlers() {
    this.dropdown.onOpening.on(e => {
      if (this.onOpening.fire(new ControlEvent(this, e.originalEvent)).defaultPrevented) {
        e.preventDefault();
      } else {
        this.onOpeningHandler(e);
      }
    });
    this.dropdown.onOpened.on((e) => {
      this.onOpenedHandler(e);
      if (!window.TouchEvent ||
          !(GetRecursionEnd(e as Event, e => e.originalEvent) instanceof TouchEvent) ||
          UnwrapFunction(this.options.focusSearchOnTouchOpen)) {
        window.setTimeout(() => this.focus(), 100);
      }
      if (e.originalEvent) {
        const key = ((e.originalEvent as JQueryKeyEventObject).key as key);
        if ((key === "ArrowDown" || key === "Tab") && !this.options.search) {
          this.highlightItem(0);
        }
      }
      this.onOpened.fire(new ControlEvent(this, false, e.originalEvent));
    });
    this.dropdown.onClosing.on(e => {
      if (this.onClosing.fire(new ControlEvent(this, e.originalEvent)).defaultPrevented) {
        e.preventDefault();
      } else {
        this.onClosingHandler(e);
      }
    });
    this.dropdown.onClosed.on(e => {
      this.onClosedHandler(e);
      this.highlightItem(-1);
      if (this.currentAjaxRequest) {
        this.currentAjaxRequest.abort("dropdown closed");
      }
      this.selectElement.dispatchEvent(new CustomEvent("blur"));
      this.onClosed.fire(new ControlEvent(this, false, e.originalEvent));
    });
    this.dropdown.onRendering.on((e) => {
      if (this.onRendering.fire(new ControlEvent(this, e.originalEvent)).defaultPrevented) {
        e.preventDefault();
      }
    });
    this.dropdown.onRendered.on((e) => {
      if (this.onRendered.fire(new RenderedEvent(this, e.items)).defaultPrevented) {
        e.preventDefault();
      }
    });
    this.onSelectionChanged.on((e) => {
      this.renderPlaceholderText();
      this.itemsRenderedAfterSelectionChanged = false;
      if (!this.isOpen()) {
        if (this.hasExternalSearch()) {
          e.removedItems.forEach(i => {
            this.currentItems.remove(i);
          });
          void this.renderItems(true)
        } else if (UnwrapFunction(this.options.selectedOnTop))
          this.sortItems();
      }
    });
    // this.$selectElement.on("field-validated", () => {
    //     window.setTimeout( () => {
    //         this.dropdown.popup.$element.css("border-top-color",
    //                                          window.getComputedStyle(this.dropdown.$toggleControl[0]).borderBottomColor);
    //     }, 100);
    // });
  };

  destroyUi(): void {
    this.close();
    const validationField = ValidationField.getValidationField(this.selectElement);
    if (validationField) {
      validationField.resetValidation()
      validationField.validateData.disableValidation = true;
      validationField.formValidator?.validationFields.remove(validationField);
    }
    this.$fakeSelect.remove();
  }
}

declare global {
  interface JQuery {
    selectControl(options?: ISelectOptions): JQuery;

    selectControl(loadDataSettings: true): JQuery;
  }
}

jQuery.fn.selectControl = function (this: JQuery, options?: ISelectOptions | true) {
  return this.each((index, elem) => {
    const $elem = $(elem);
    if ($elem.hasData("select") || $elem.hasData("select-list")) {
      return;
    }
    if (options === true) {
      options = $elem.getJsonFromAttribute<ISelectOptions>("data-select-settings")
                || $elem.getJsonFromAttribute<ISelectOptions>("data-settings");
    }
    // ReSharper disable once WrongExpressionStatement
    new Select(elem as HTMLSelectElement, options);
  });
};
