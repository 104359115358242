import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {User, UserGroup, UserType} from "@api";
import {UserService} from "@services/user.service";
import {userGroupMapper, userTypeMapper} from "@app/utils/enum-display-name-mapper";
import {AppBaseComponent} from "@app/appBaseComponent";
import {Modal} from "@widgets/modal";
import {UserRightsStore} from "@stores";

@Component({
             selector: 'rt-user-detail',
             templateUrl: './user-detail.component.html',
             styleUrls: ['./user-detail.component.scss']
           })
export class UserDetailComponent extends AppBaseComponent {
  readonly userData$: Observable<User>;
  readonly userType = UserType;
  readonly userGroup = UserGroup;
  isSendingWelcomeMail = false;
  isSendingPasswordMail = false;
  isDeletingUser = false;
  private readonly _userService: UserService;
  historyOpen = false;
  loggedInAsSuperUser$: Observable<boolean>;

  constructor(userService: UserService, userRightsStore: UserRightsStore) {
    super();
    this._userService = userService;
    this.userData$ = userService.userStore.observe("currentUser")
    this.loggedInAsSuperUser$ = userRightsStore.observe("isSuperUser");
  }

  async sendWelcomeMail(user: User): Promise<void> {
    this.isSendingWelcomeMail = true;
    await this._userService.sendWelcomeMail(user);
    this.isSendingWelcomeMail = false;
  }

  async sendPasswordMail(user: User): Promise<void> {
    this.isSendingPasswordMail = true;
    await this._userService.setNewPassword(user);
    this.isSendingPasswordMail = false;
  }

  openDeleteModal(user: User): void {
    let modal: Modal;
    // eslint-disable-next-line prefer-const
    modal = new Modal(null, {
      header: "Delete User",
      text: `Are you sure you want to delete user ${user.firstName} ${user.lastName}?`,
      buttons: [
        $(document.createElement("button"))
          .text("Delete")
          .addClass("button")
          .click(() => {
            void this._deleteUser(user)
            modal.closeAndDeleteModal();
          }),
        $(document.createElement("button"))
          .text("Cancel")
          .addClass("button secondary")
          .click(() => modal.closeAndDeleteModal())
      ]
    });
    modal.openModal();
  }

  private async _deleteUser(user: User): Promise<void> {
    this.isDeletingUser = true;
    await this._userService.delete(user);
    this.isDeletingUser = false;
  }

  getUserTypeName(userType: UserType): string {
    return userTypeMapper[userType];
  }

  getUserGroupName(userGroup: UserGroup): string {
    return userGroupMapper[userGroup];
  }

  closeHistory(): void {
    this.historyOpen = false;
  }

  toggleHistory(): void {
    this.historyOpen = !this.historyOpen;
  }

}
