import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {TypedStoreAccessor} from "@stores/store-accessor";
import {AppBaseComponent} from "@app/appBaseComponent";

@Component({
  selector: 'rt-collapsible-box',
  templateUrl: './collapsible-box.component.html',
  styleUrls: ['./collapsible-box.component.scss'],
  host: {
    class: "box accordion element-item"
  }
})
export class CollapsibleBoxComponent extends AppBaseComponent {
  get openStateStoreAccessor(): TypedStoreAccessor<boolean> {
    return this._openStateStoreAccessor;
  }
  @Input()
  set openStateStoreAccessor(value: TypedStoreAccessor<boolean>) {
    this._openStateStoreAccessor = value;
    value.subscribe(isOpen => {
      this.closed =!isOpen
    } )
  }

  @HostBinding("class.closed")
  closed = true;

  private _openStateStoreAccessor: TypedStoreAccessor<boolean>

  @Input()
  title: string;

  @Input()
  @HostBinding("class.wide")
  wide: boolean;

  @Input()
  isempty: boolean;

  @Input()
  contentClasses: string[] | string;

  toggle(): void {
    this._openStateStoreAccessor?.set(!this._openStateStoreAccessor.value);
  }

}
