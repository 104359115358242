import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {ErrorViewData} from "@view-data";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {ErrorType} from "@Enums";
import {StatusCodes} from "http-status-codes";
import {RoutingService} from "@app/services/routing.service";

@Injectable()
export class WebServiceErrorInterceptor implements HttpInterceptor {
  private readonly _routingService: RoutingService;

  constructor(routingService: RoutingService) {
    this._routingService = routingService;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
               .pipe(
                 map(r => r),
                 catchError((err: HttpErrorResponse) => {
                   this.handleResponse(err)
                   return throwError(err);
                 })
               );
  }

  private handleResponse(r: HttpErrorResponse) {
    const isLoginRequest= r.url?.toLowerCase().contains("api/account/login");
    if (r.status >= 400)
      this.handleErrorResponse(r.status, isLoginRequest);
    return r;
  }

  private handleErrorResponse(httpStatus: number, isLoginRequest: boolean) {
    let errorType: ErrorType = "UnexpectedError";
    if (httpStatus === StatusCodes.UNAUTHORIZED) {
      if (isLoginRequest)
        return;
      errorType = "AccessDenied";
    } else if (httpStatus === StatusCodes.FORBIDDEN){
      errorType = "AccessDenied";
    }
    else if (httpStatus === StatusCodes.NOT_FOUND)
      errorType = "NotFound"
    this._routingService.navigateTo(new ErrorViewData(errorType));
  }
}

