<form class="wysiwyg" novalidate [class]="{error: hasLoginError}" (ngSubmit)="login($event)">
  <img class="rohlig-logo" src="/content/images/Rohlig_blue.svg" width="100%" alt="Logo">
  <figure class="realtime-logo logologin">
    <a [routerLink]="''">
      <img src="/content/images/Real Time_blue.svg" width="100%" alt="Logo">
    </a>
  </figure>
  <p>
    <label for="EmailAddress">Email</label>
    <input [attr.autofocus]="hasLoginError?null:'autofocus'"
           data-live-validation
           id="EmailAddress"
           name="EmailAddress"
           required
           rtInputValidator
           tabindex="1"
           (input)="hideLoginError()"
           [(ngModel)]="mailAddressValue"
           [disabled]="formDisabled"
           #mailAddress
           type="email"/>
  </p>
  <p>
    <a (click)="forgotPasswordClicked($event)"
       style="float: right;"
       href="/Account/ForgotPassword">Forgot password?</a>
    <label for="Password">Password</label>
    <input [attr.autofocus]="hasLoginError ? 'autofocus' : null"
           data-live-validation
           id="Password"
           name="Password"
           required
           tabindex="1"
           [(ngModel)]="passwordValue"
           [disabled]="formDisabled"
           (input)="hideLoginError()"
           rtInputValidator
           #password
           type="password"/>
  </p>
  <ng-container *ngIf="hasLoginError">
    <p class="error">
      <!--suppress XmlDuplicatedId -->
      <span id="login-error-message" class="error-message">Your email or password was incorrect.</span>
    </p>
  </ng-container>

  <ng-container *ngIf="accountHasBeenUnlocked">
    <p>
      <!--suppress XmlDuplicatedId -->
      <span id="login-error-message" class="error-message" style="color: #83a427 !important;">Your account has been unlocked.</span>
    </p>
  </ng-container>

  <p>
    <label>
      <input type="checkbox" id="RememberMe" name="RememberMe" [checked]="rememberMe">
      <span class="mat-icon-done"></span>
      Keep me logged in
    </label>
  </p>

  <p class="buttons">
    <button class="button block text-center"
            [rtLoadingAnimation]="isLoggingIn"
            [loadingText]="'logging in'"
            tabindex="1"
            (click)="login()">
      Log In
    </button>
  </p>

  <div style="display: flex; justify-content: center;">
    <div class="grey-line"></div>
  </div>
  <p class="text-center buttons" *ngIf="!isDemo; else linkToProd">
    <a class="button" routerLink="/Account/RequestAccess">REQUEST ACCESS TO REAL TIME</a>
  </p>
  <ng-template #linkToProd>
    <p class="text-center buttons">
      <a class="button" href="https://realtime.rohlig.com/Account/RequestAccess" target="_blank">REQUEST ACCESS TO REAL TIME</a>
    </p>
  </ng-template>
</form>

<div id="landing-page-block">
  <h1>
    The smart way to track and manage your global freight operations
  </h1>
  <p>
    Röhlig Real Time brings your supply chain to your fingertips. Make a booking, track your shipments and handle documentation quickly and efficiently, all on
    one platform.
  </p>
  <a target="_blank" href="https://www.rohlig.com/services/real-time">Find out more</a>
</div>
