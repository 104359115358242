import {IStoreUpdate, Store, StoreState} from "@stores/store";
import detect = require("@utils/detect");

export class StoreDebugHelper {

  private static readonly _currentStates: IStringIndexable<unknown> = {}
  private static readonly _updates: IStoreUpdate[] = [];

  static registerStore<T extends StoreState, TP extends StoreState>(store: Store<T, TP>): void {
    if (detect.browser.ie)
      return;
    store["_update$"].subscribe(v => {
      // noinspection EmptyCatchBlockJS
      try {

        console.log(Object.assign({store: store.getName()}, v));
        if (!window.isProduction)
          this._updates.push(v as never);
        this._currentStates[store.getName()] = v.newState;
        // eslint-disable-next-line no-empty
      } catch {}
    })
  }

  static download(): void {
    const d = new Date();
    const timestamp =
      `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}T${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
    this.downloadObjectAsJson(this._currentStates, `rrtCurrentState_${timestamp}`);
    this.downloadObjectAsJson(this._updates, `rrtStateHistory_${timestamp}`);
  }

  private static downloadObjectAsJson(exportObj: unknown, exportName: string) {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }
}
