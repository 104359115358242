import {Injectable} from "@angular/core";
import {Store, StoreProjectionMapping} from "./store";
import {Entity, EntitySearchResult, UserSearchResult} from "@api";
import {TitleBuilder} from "@utils/builders/title-builder";

interface EntityStoreProjections {
  entityTitle: string;
  displayedSearchResults: EntitySearchResult[] | null;
  moreResultsAvailable: boolean;
}

interface EntityStoreState {
  currentEntity: Entity;
  entityList: EntitySearchResult[];
  currentSearch: string;
  searchResultDisplayCount: number;
}

@Injectable({providedIn: "root"})
export class EntityStore extends Store<EntityStoreState, EntityStoreProjections> {
    private static readonly _projectionMapping: StoreProjectionMapping<EntityStoreState, EntityStoreProjections> =
        {
            entityTitle: ({currentEntity}) => {
                if (!currentEntity)
                    return null;
                if (currentEntity.id)
                    return currentEntity.name
                return TitleBuilder.entities.addSite;
            },
          displayedSearchResults: u=> {
            if (!u.entityList)
              return null;
            return u.entityList.slice(0, u.searchResultDisplayCount);
          },
          moreResultsAvailable: s =>{
            return s.entityList && s.entityList.length > s.searchResultDisplayCount;
          }
        };

    constructor() {
        super(EntityStore._projectionMapping, {});
    }

    getName(): string {
        return "EntityStore";
    }
}
