<router-outlet></router-outlet>
<!--<div style="z-index:1000000; position: fixed; top: 50%; left: 50%; transform: translateY(-50%) translateX(-50%); background: white; border: 3px solid red;">-->
<!--  <ul>-->
<!--    <li><a [routerLink]="'account/login'">Login</a></li>-->
<!--    <li><a [routerLink]="'account/login'" [queryParams]="{unlocked: true}">Login unlocked</a></li>-->
<!--    <li><a [routerLink]="'shipment'">Shipments</a></li>-->
<!--    <li><a [routerLink]="'order'">Orders</a></li>-->
<!--    <li><a [routerLink]="'user'">Users</a></li>-->
<!--    <li><a [routerLink]="'sitemanagement'">Entities</a></li>-->
<!--    <li><a [routerLink]="'settings'">Settings</a></li>-->
<!--    <li><a [routerLink]="'error/notfound'">NotFound</a></li>-->
<!--    <li><a [routerLink]="'error/unexpectederror'">Unexpected</a></li>-->
<!--    <li><a [routerLink]="'error/accessdenied'">AccessDenied</a></li>-->
<!--  </ul>-->
<!--</div>-->

<!-- HTML settings take priority over the JS ones and can be backend-generated. -->

