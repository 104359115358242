<div class="filters" [@slideInOut]="(filtersOpen.observe | async) ? 'visible' : 'hidden'">
  <div class="filter-columns">
    <div>
      <p>
        <label for="select-order-transport">Transport Mode</label>
        <select id="select-order-transport"
                ngSelect
                [storeAccessor]="selectedTransportModes"
                [settings]="selectSettings.default"
                [items]="transportModeSearchItems">
        </select>
      </p>
    </div>
    <div>
      <p>
        <label for="select-order-status">Status</label>
        <select id="select-order-status"
                ngSelect
                [storeAccessor]="selectedStatus"
                [settings]="selectSettings.default"
                [items]="orderStatusSearchItems">
        </select>
      </p>
    </div>
    <div class="dropdown-icon">
      <label for="input-date-range">Date <span class="date-type">{{dateTypeTitleAppendix$ | async}}</span></label>
      <div class="datepicker-wrap">
        <input class="output"
               id="input-date-range"
               #datepickerTrigger
               [value]="selectedDatesString$ | async"
               onfocus="this.blur()">

        <span class="datepicker-wrap-icon"></span>

        <div class="datepicker-popup"
             #dateDropdown=dropdown
             rtDropdown
             [dropdownOptions]='dateDropdownOptions'
             [dropdownTrigger]="datepickerTrigger">
          <div class="header">
            <h2 class="hide-desktop">Date</h2>
            <select class="datepicker-select"
                    data-display-name="Date"
                    ngSelect
                    [settings]="selectSettings.date"
                    [items]="dateTypeSearchItems">
            </select>
          </div>

          <div class="datepicker">
            <div
                 rt-calendar
                 [endDateStoreAccessor]="selectedEndDate"
                 [startDateStoreAccessor]="selectedStartDate"
                 [currentDateTypeStoreAccessor]="selectedCalendarDateType"></div>
            <div class="controls">
              <div class="form-group">
                <label for="date-start">From</label>
                <input class="date-start date-range"
                       type="text"
                       id="date-start"
                       readonly
                       (click)="selectStartDate()"
                       [ngModel]="selectedStartDate.observe | async | rtDate"
                       [ngClass]="{'focus':startDateFocused$ | async}"/>
              </div>
              <div class="form-group">
                <label for="date-end">To</label>
                <input name="DateSearchObject.EndSearchTime"
                       class="date-end date-range"
                       type="text"
                       id="date-end"
                       readonly
                       (click)="selectEndDate()"
                       [ngModel]="selectedEndDate.observe | async | rtDate"
                       [ngClass]="{'focus':endDateFocused$ | async}"/>
              </div>
              <button class="button datepicker-done show-desktop" (click)="dateDropdown.close()">OK</button>
              <button class="button secondary datepicker-reset show-desktop" (click)="clearDates()">Clear</button>
            </div>
          </div>
          <div class="footer hide-desktop">
            <button class="button datepicker-done" (click)="dateDropdown.close()">OK</button>
            <button class="button secondary datepicker-reset" (click)="clearDates()">Clear</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid padding">
    <div class="buttons phone-auto-width">
      <button class="button submit-form" type="submit" (click)="startSearchAndCloseFilters()">
        <span class="mat-icon-done">Apply</span>
      </button>
      <button class="button secondary reset-form disable-when-offline" type="reset" (click)="clearFilters()">
        <span class="mat-icon-close">Clear</span>
      </button>
    </div>
  </div>
</div>
