import {Injectable} from "@angular/core";
import {Store, StoreProjectionMapping} from "@stores";
import {ShipmentSearchDateType, ShipmentSearchReferenceType} from "@api";
import {ShipmentSearchUiStoreState} from "@app/page-components/shipment/shipment-list/shipmentSearchUiStoreState";
import {dateTypes} from "@app/page-components/shipment/shipment-list/shipment-search-code-mapper";

interface ShipmentSearchUiStoreProjection {
  calendarStartDateFocused: boolean;
  calendarEndDateFocused: boolean;
  dateSearchTitleAppendix: string;
  selectedDates: { start: Date, end: Date };
}

@Injectable({providedIn: "root"})
export class ShipmentSearchUiStore extends Store<ShipmentSearchUiStoreState, ShipmentSearchUiStoreProjection> {

  getName(): string {
    return "ShipmentSearchUiStore";
  }

  private static _initialState: ShipmentSearchUiStoreState = {
    filtersOpen: false,
    dateType: ShipmentSearchDateType.BookingCreated,
    bookedByRohlig: false,
    bookedByUserIds: [],
    endDate: null,
    startDate: null,
    portsOfDischargeUnLocodes: [],
    portsOfLoadingUnLocodes: [],
    status: [],
    destinationCountryCodes: [],
    originCountryCodes: [],
    containerMode: [],
    transportMode: [],
    searchReferenceType: ShipmentSearchReferenceType.Any,
    reference: null,
    calendarDateType: "startDate",
    showMobileSearchHint: false
  }

  private static _projections: StoreProjectionMapping<ShipmentSearchUiStoreState, ShipmentSearchUiStoreProjection> = {
    calendarEndDateFocused: u => u.calendarDateType === "endDate",
    calendarStartDateFocused: u => u.calendarDateType === "startDate",
    dateSearchTitleAppendix: u => {
      if (!u.startDate && !u.endDate)
        return "";
      return `(${dateTypes[u.dateType] ?? ""})`
    },
    selectedDates: u => {
      if (!u.startDate || !u.endDate)
        return null;
      return {start: u.startDate, end: u.endDate}
    }
  }

  constructor() {
    super(ShipmentSearchUiStore._projections, ShipmentSearchUiStore._initialState);
  }


}
