export function GenerateDefaultOptions(): IDatepickOptions {
    return {
        changeMonth: false,
        dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        firstDay: window.firstDayOfWeek,
        nextText: "",
        prevText: "",
        dateFormat: window.dateShortFormat,
        
    }
}
