import {Store, StoreState} from "@stores/store";
import {distinctUntilChanged, map} from "rxjs/operators";
import {Observable, Subscription} from "rxjs";
import {TypedStoreAccessor} from "@stores/store-accessor";

export class StoreCutoutAccessor<T extends StoreState, TP extends StoreState, K extends keyof T, KS extends keyof T[K]> implements TypedStoreAccessor<T[K][KS]> {
    private readonly _storeKey: K;
    private readonly _store: Store<T, TP>;
    private readonly _subKey: KS;

    constructor(store: Store<T, TP>, storeKey: K, subKey: KS) {
        this._storeKey = storeKey;
        this._store = store;
        this._subKey = subKey;
        this.observe = store
            .observe(storeKey)
            .pipe(
                map(v => v && v[subKey]),
                distinctUntilChanged());
    }


    readonly observe: Observable<T[K][KS]>;

    get value(): T[K][KS] {
        const parent = this._store.get(this._storeKey);
        if (!parent)
            return undefined
        return parent[this._subKey];
    }

    hasValue(): boolean {
        return !!this.value;
    }

    set(value: T[K][KS]): void {
        this._store.update((u): Partial<T> => {
            const parent = u[this._storeKey]
            if (!parent)
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                throw `Parent object is not available. ${this._store.getName()}.${this._storeKey} needs to hav a value to set ${this._subKey}`
            parent[this._subKey] = value;
            return {[this._storeKey]: parent} as unknown as Partial<T>;
        })
    }

    subscribe(next: Action1<T[K][KS]>): Subscription {
        return this.observe.subscribe(next);
    }

}
