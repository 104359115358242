import {combineLatest, Observable, ReplaySubject, Subject} from "rxjs";
import {AppBaseComponent} from "@app/appBaseComponent";
import {TypedStoreAccessor} from "@stores/store-accessor";
import {debounceTime, map} from "rxjs/operators";
import {ISelectListItem} from "@widgets/SelectList";

export class SelectItemCache<T> extends AppBaseComponent {

  readonly selectedItems$: Observable<T[]>
  private readonly _cache: IStringIndexable<T> = {}
  private readonly _nameSelector: Func1<T, string>;
  private readonly _idSelector: Func1<T, string>;
  private readonly _selectedIds: TypedStoreAccessor<string[]>;
  private readonly _cacheChangedSubject = new ReplaySubject<IStringIndexable<T>>();

  constructor(idSelector: Func1<T, string>, nameSelector: Func1<T, string>, selectedIds: TypedStoreAccessor<string[]>) {
    super();
    this._selectedIds = selectedIds;
    this._idSelector = idSelector;
    this._nameSelector = nameSelector;
    this.selectedItems$ =
      combineLatest(this._cacheChangedSubject, selectedIds.observe)
        .pipe(
          debounceTime(10),
          map(([cache, ids]) => {
            if (!ids)
              return [];
            return ids
              .map(id => cache[id])
              .filter(i => !!i);
          }));

  }

  updateCache(items: T[] | null | undefined): void {
    if (!items || !items.any())
      return;
    for (const item of items) {
      this._cache[this._idSelector(item)] = item;
    }
    this._cacheChangedSubject.next(this._cache);
  }

  updateCacheFromSelect(items: ISelectListItem[]): void {
   this.updateCache(
     (items as ISelectListItem<JSONDataContainer<T>>[])
       .map(i => i.ajaxData?.data)
       .filter(d => !!d));
  }

  removeItem(id: string): void {
    const currentIds = this._selectedIds.value;
    if (!currentIds)
      return;
    const newIds = currentIds.filter(i => i !== id);
    this._selectedIds.set(newIds)
  }
}
