import { createDeepCopy } from '@utils/createDeepCopy';
import {Injectable} from "@angular/core";
import {BehaviorSubject, ReplaySubject, Subject} from "rxjs";
import {BookingModel} from "@booking/BookingModel";
import {BookingBaseData} from "@api";
import {distinctUntilChanged, map} from "rxjs/operators";

export type BookingMode = "booking" | "template";

@Injectable({
              providedIn: 'root'
            })
export class BookingServiceListener {
  protected static readonly _templateLoadedSubject = new BehaviorSubject<BookingModel>(null);
  protected static readonly _bookingModelSubject = new BehaviorSubject<BookingModel>(null);
  protected static readonly _cancellationRequestedSubject = new Subject<{ requestedUrl: string, resolver: PromiseResolver<boolean> }>();
  protected static readonly _modeSubject = new BehaviorSubject<BookingMode>("booking");
  protected static readonly _bookingBaseDataSubject = new ReplaySubject<BookingBaseData>(1);
  protected static readonly _bookingCanceledSubject = new Subject<void>();
  readonly templateLoaded$ = BookingServiceListener._templateLoadedSubject.pipe(map(createDeepCopy));
  readonly bookingModel$ = BookingServiceListener._bookingModelSubject.asObservable();
  readonly cancellationRequested$ = BookingServiceListener._cancellationRequestedSubject.asObservable();
  readonly mode$ = BookingServiceListener._modeSubject.pipe(distinctUntilChanged());
  readonly bookingBaseData$ = BookingServiceListener._bookingBaseDataSubject.asObservable();
  readonly bookingCancelled$ = BookingServiceListener._bookingCanceledSubject.asObservable();

  get currentBookingModel(): BookingModel | null {
    return BookingServiceListener._bookingModelSubject.getValue();
  }


  get currentTemplate(): BookingModel | null {
    return BookingServiceListener._templateLoadedSubject.getValue();
  }

  get currentBookingMode(): BookingMode {
    return BookingServiceListener._modeSubject.getValue();
  }

  requestCancellation(): Promise<boolean>;
  requestCancellation(requestedUrl: string): Promise<boolean> ;
  requestCancellation(requestedUrl?: string): Promise<boolean> {
    let resolver: (value?: (PromiseLike<boolean> | boolean)) => void;
    const promise = new Promise<boolean>(r => resolver = r);
    BookingServiceListener._cancellationRequestedSubject.next({requestedUrl, resolver});
    return promise;
  }
}
