import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {ShipmentDocument} from "@api";

@Component({
             selector: '[rt-document-link]',
             templateUrl: './document-link.component.html',
             styleUrls: ['./document-link.component.scss'],
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class DocumentLinkComponent {

  @Input("rt-document-link")
  set document(value: ShipmentDocument) {
    this._document = value;
    this.documentUrl = DocumentLinkComponent._getDocumentUrl(value)
  }

  _document: ShipmentDocument;

  @HostBinding("href")
  documentUrl: string;

  private static _getDocumentUrl(document: ShipmentDocument): string {
    if (!document)
      return "";
    return `/Shipment/views/GetDocument?documentID=${document.url}&fileName=${encodeURIComponent(document.fileName)}`
  }
}
