import MapOptions = google.maps.MapOptions;
import PolylineOptions = google.maps.PolylineOptions;
import LatLngLiteral = google.maps.LatLngLiteral;

export const GoogleMapsStyles =
  (function () {
    const mapImagesPath = `/Content/images/gmap/`;
    return {
      mapStyles: [
        {
          featureType: "water",
          stylers: [{color: "#ecf1f2"}, {visibility: "on"}]
        },
        {
          featureType: "landscape",
          stylers: [{color: "#f8f8f8"}]
        },
        {
          featureType: "road",
          stylers: [{saturation: -100}, {lightness: 45}]
        },
        {
          featureType: "road.highway",
          stylers: [{visibility: "simplified"}]
        }, {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [{visibility: "off"}]
        },
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{color: "#999999"}]
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [{color: "#999999"}]
        },
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [{visibility: "off"}]
        },
        {
          featureType: "transit",
          stylers: [{visibility: "off"}]
        },
        {
          featureType: "poi",
          stylers: [{visibility: "off"}]
        }] as google.maps.MapTypeStyle[],

      mapOptions: {
        zoom: 3,
        center: {lat: 0,lng: -180} as LatLngLiteral,
        mapTypeId: "terrain" as unknown as google.maps.MapTypeId, //google.maps.MapTypeId.TERRAIN,
        mapTypeControl: false,
        streetViewControl: false,
        gestureHandling: "cooperative",
      } as MapOptions,
      traveledPathStyle:
        {
          geodesic: false,
          strokeColor: "#83a427",
          strokeOpacity: 1.0,
          strokeWeight: 2,
        } as PolylineOptions,
      toGoPathStyle:
        {
          geodesic: false,
          strokeOpacity: 0,
          icons: [{
            icon: {
              path: "M 0,-1,0,1",
              strokeOpacity: 1,
              strokeColor: "#757575",
              scale: 2
            },
            offset: "0",
            repeat: "10px"
          }]
        } as PolylineOptions,
      markerIcons: {
        default: mapImagesPath + "map_marker.png",
        grey: mapImagesPath + "map_marker_grey.png",
        complete: mapImagesPath + "map_marker_end_complete.png",
        end: mapImagesPath + "map_marker_end.png"
      }
    }
  })();
