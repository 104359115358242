import {ApplicationRef, Directive, ElementRef} from "@angular/core";
import {ContentComponent} from "@utils/Angular/ContentComponent";

@Directive({
               selector: "[rt-Initialize-Child-Popovers]"
           })
export class InitializeChildPopoversDirective extends ContentComponent {

    constructor(elementRef: ElementRef, app: ApplicationRef) {
        super(elementRef, app);
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        // noinspection ObjectAllocationIgnored
        $(".popover-trigger,[data-popover-renderer]", this.element).popover();
    }
}
