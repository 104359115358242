import {ISelectOptions, Select} from "./Select";
import {Alignment} from "../utils/Enums";
import {ViewCustomizer} from "./ViewCustomizer";

require("../utils/JqueryLoader");

export class ViewLayoutSelect extends Select {
    //language=html
    static defaultOptions: ISelectOptions = $.extend({}, Select.defaultSelectOptions, {
        "class": "double-button-droplist droplist-button select-view",
        shrinkToVisible: !(document.body.classList.contains("ie") || document.body.classList.contains("edge")),
        focusSearchOnTouchOpen: false,
        search: false,
        fixAlignment: () => window.innerWidth <= 320,
        alignment: Alignment.End,
        allowGrowing: false,
        toggleControl: "#select-view-toggle",
        allowNoSelection: false
    });
    private _viewCustomizer: ViewCustomizer;

    constructor(selectElement: HTMLSelectElement) {
        super(selectElement,ViewLayoutSelect.defaultOptions);
        $(`<a>Customize Layout</a>`)
            .insertBefore(this.$footer)
            .click(() => this.openViewCustomizer())
            .wrap("<div class='view-select-footer'></div>");
        this.onSelectionChanged.on(e => {
            if (!e.addedItems.length)
                return;
            const view = e.addedItems[0].value;
            const $shipmentContainer = $("div.page-shipment-list div.shipment-list");
            const today = new Date();
            document.cookie =
                `shipmentListViewMode=${view}; path=/; expires=${new Date(today.setFullYear(today.getFullYear() +
                                                                                                    5)).toUTCString()}`;
            $shipmentContainer.toggleClass("shipment-line-view shipment-box-view");
        });
    }

    openViewCustomizer() {
        // if (!this._viewCustomizer) {
        //     const cardViewCodeString = this.$selectElement.data("card-layout-items");
        //     const listViewCodeString = this.$selectElement.data("list-layout-items");
        //     const listOverflow = this.$selectElement.data("list-layout-overflow");
        //     this._viewCustomizer = new ViewCustomizer(cardViewCodeString,listViewCodeString, listOverflow);
        // }
        // this._viewCustomizer.open();
        // this.close();
    }

}

declare global {
    interface JQuery {
        viewLayoutSelect(): JQuery;
    }
}

jQuery.fn.viewLayoutSelect = function (this: JQuery, options?: ISelectOptions | true) {
    return this.each((index, elem) => {
        const $elem = $(elem);
        // ReSharper disable once WrongExpressionStatement
        new ViewLayoutSelect(elem as HTMLSelectElement);
    });
};
