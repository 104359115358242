export class HistoryManager {
    private static readonly _singleton = new HistoryManager();
    private _backHistory: IInternalHistorySate[] = [];
    private _forwardHistory: IInternalHistorySate[] = [];
    private _currentState: IInternalHistorySate = null;
    private readonly _originalHistoryPush: (state: any, title: string, url: string) => void;
    private readonly _originalHistoryReplace: (state: any, title: string, url: string) => void;

    private constructor() {
        if (window.historyManager)
            return;
        window.historyManager = this;
        let state: any = history.state;
        let id = this.getId();
        if (state === null || state === undefined)
            state = id;
        else if (typeof state === "object")
            (state as IInternalHistorySate).___historyId = id;
        else
            id = state;
        history.replaceState(state, null);
        this._currentState = {___historyId: id, data: state, url: null};
        window.addEventListener("popstate", this.historyChangeHandler.bind(this));
        this._originalHistoryPush = history.pushState;
        history.pushState = this._historyPushOverride.bind(this);
        this._originalHistoryReplace = history.replaceState;
        history.replaceState = this._historyReplaceOverride.bind(this);
    }

    pushState(state: IHistoryState): void {
        if (this._currentState) {
            this._backHistory.push(this._currentState);
            if (this._currentState.onLeave)
                this._currentState.onLeave("forwards")
        }
        this._forwardHistory.clear();
        this._currentState = Object.assign({___historyId: this.getId()}, state);
        const stateCopy = Object.assign({}, this._currentState);
        delete stateCopy.onEnter;
        delete stateCopy.onLeave;
        delete stateCopy.onSkip;
        this._originalHistoryPush.call(history, stateCopy, "", state.url);
    }

    // back(): void {
    //     if (this._currentState) {
    //         this._forwardHistory.unshift(this._currentState);
    //         if (this._currentState.onLeave)
    //             this._currentState.onLeave()
    //     }
    //     this._currentState = this._backHistory.pop() || null;
    //     // if (this._currentState)
    // }
    //
    // forward(): void {
    //     if (this._currentState) {
    //         this._backHistory.push(this._currentState);
    //         if (this._currentState.onLeave)
    //             this._currentState.onLeave()
    //     }
    //     this._currentState = this._forwardHistory.shift() || null;
    //     // if (this._currentState)
    // }

    deleteForwardHistory() {
        history.pushState(null, null);
        history.back();
    }

    private getId() {
        return Date.now() + "_" + Math.random();
    }

    private _historyPushOverride(state: IInternalHistorySate | string, title: string, url: string) {
        let id = this.getId();
        if (state === null || state === undefined)
            state = id;
        else if (typeof state === "object") {
            if (!state.___historyId)
                (state as IInternalHistorySate).___historyId = id;
        } else
            id = state;
        this._backHistory.push(this._currentState);
        if (this._currentState.onLeave)
            this._currentState.onLeave("forwards");
        this._currentState = {___historyId: id, data: state, url: null};
        this._forwardHistory.clear();
        this._originalHistoryPush.apply(history, arguments);
    }

    private _historyReplaceOverride(state: IInternalHistorySate | string, title: string, url: string) {
        if (state === null || state === undefined)
            state = this._currentState.___historyId;
        else if (typeof state === "object") {
            state.___historyId = this._currentState.___historyId;
        } else
            this._currentState.___historyId = state;
        this._currentState.data = state;
        this._originalHistoryReplace.call(history, state, title, url);
    }

    private historyChangeHandler(e: { state: IInternalHistorySate | string }) {
        const id = e.state && typeof e.state === "object" ? e.state.___historyId : e.state;
        const historyState = this._backHistory.firstOrDefault(h => h.___historyId === id) ||
                             this._forwardHistory.firstOrDefault(h => h.___historyId === id);
        if (!historyState)
          return;
        let dir: "backwards" | "forwards" = this._backHistory.contains(historyState) ? "backwards" : "forwards";

        let currentState = this._currentState;
        if (currentState && currentState.___historyId === e.state)
            return;
        if (historyState.onEnter)
            historyState.onEnter(dir);
        let isFirstSkip = true;
        if (dir === "backwards") {
            do {
                if (!isFirstSkip && this._currentState.onSkip)
                    this._currentState.onSkip("backwards");
                isFirstSkip = false;
                this._forwardHistory.unshift(this._currentState);
                this._currentState = this._backHistory.pop();
            } while (this._currentState && this._currentState !== historyState);
        } else {
            do {
                if (!isFirstSkip && this._currentState.onSkip)
                    this._currentState.onSkip("forwards");
                isFirstSkip = false;
                this._backHistory.unshift(this._currentState);
                this._currentState = this._forwardHistory.pop();
            } while (this._currentState && this._currentState !== historyState);
        }
        if (currentState.onLeave)
            currentState.onLeave(dir);
    }
}

declare global {
    interface Window {
        historyManager: HistoryManager;
    }
}

interface IInternalHistorySate extends IHistoryState {
    ___historyId: string;
}

export interface IHistoryState {
    onEnter?: (dir: "backwards" | "forwards") => void;
    onLeave?: (dir: "backwards" | "forwards") => void;
    onSkip?: (dir: "backwards" | "forwards") => void;
    data?: any;
    url?: string;
}

