import {Directive, ElementRef, Input} from '@angular/core';
import {IValidateData, ValidationField, ValidatorOptions} from "@widgets/Validate";
import {Observable, ReplaySubject} from "rxjs";

@Directive({
             selector: '[rtInputValidator]'
           })
export class InputValidatorDirective {
  private readonly _validationField: ValidationField;
  private readonly validatedSubject = new ReplaySubject<boolean>(1)
  @Input()
  customValidator: (value: string) => boolean | Promise<boolean>;
  @Input()
  customErrorMessage: string;
  private readonly _customValidatorOptions: ValidatorOptions<unknown> =
    {
      isActive: () => !!this.customValidator,
      errorMessage: () => this.customErrorMessage,
      validator: v => this.customValidator(v)
    };

  constructor(elementRef: ElementRef) {
    this._validationField = ValidationField.getValidationField(elementRef.nativeElement);
    this._validationField.validateData.disableValidation = () => this._disabled;
    this._validationField.validateData.validators.custom = this._customValidatorOptions;
    this._validationField.onValidated.on(e => this.validatedSubject.next(e.data));
  }

  private _disabled = false;

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    if (value)
      this._validationField?.resetValidation();
  }

  get validateData(): IValidateData {
    return this._validationField.validateData
  }

  get validated$(): Observable<boolean> {
    return this.validatedSubject.asObservable();
  }

  get isValid(): boolean {
    return this._validationField.isValid;
  }

  async validate(): Promise<boolean> {
    return this._validationField.validate();
  }

  reset(): void {
    this._validationField.resetValidation();
  }

}


