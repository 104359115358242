import {ISelectListItem, ISelectListOptions, SelectList} from "./SelectList";
import {EventEmitter} from "../utils/EventEmitter";
import {ControlEvent, RenderedEvent} from "../libs/ExtendableEvent";
import {Dropdown} from "./Dropdown";
import {Alignment, Position} from "../utils/Enums";
import * as Utils from "../utils/Utils";
import {ExtendOptions} from "../utils/Utils";
import {JSONData} from "@api";

require("../utils/JqueryLoader");

export interface IPopupSelectListOptions<T extends JSONData = JSONData> extends ISelectListOptions<T> {
    /**
     * If true, the dropdown will be closed after an item has been selected
     */
    closeOnSelect?: boolean;
    position?: FunctionOrValue<Position>;
    /**
     * If true, the popup will be moved if it isn't fully in the viewport
     * @default false
     */
    autoPosition?: boolean;

    alignment?: FunctionOrValue<Alignment>;
    /**
     * If true and autoPosition is also true, the vertical and horizontal alignments will be swapped when the popup changes position from left/right to top/bottom
     */
    autoSwapAlignment?: boolean;
    /**
     * Container element where the popup DOM will be inserted.
     * @default body
     */
    container?: ContainerType;
    css?: ICssStyles;
    class?: string;
    /**
     * Indicates if the direction of the should be change during auto positioning, or if the position should just flip between left/right and top/bottom
     * @default false
     */
    autoChangeDirection?: boolean;
    /**
     * If true, the ajax data will be loaded from the server everytime the popup opens, otherwise only when the search string changes.
     * @default true
     */
    reloadAjaxDataOnOpening?: boolean;
    /**
     * If true, alignment will not be changed to make the dropdown more visible
     * @default false
     */
    fixAlignment?: FunctionOrValue<boolean>;
    closeOnFocusLoss?: boolean;
    /**
     * If true the popup can grow to the size of its contents
     * @default true
     */
    allowGrowing?: FunctionOrValue<boolean>;
    onInitialized?: (select: PopupSelectList<T>) => void
}

export class PopupSelectList<T extends JSONData = JSONData> extends SelectList<T> {


    private static defaultPopupSelectListOptions: IPopupSelectListOptions = {
        "class": "select",
        enterToSelect: true,
        position: Position.Bottom,
        autoPosition: false,
        container: "body",
        autoChangeDirection: false,
        search: true,
        reloadAjaxDataOnOpening: true,
        closeOnFocusLoss: true
    };

    readonly onOpening = new EventEmitter<ControlEvent<this>>("opening");
    readonly onOpened = new EventEmitter<ControlEvent<this>>("opened");
    readonly onClosing = new EventEmitter<ControlEvent<this>>("closing");
    readonly onClosed = new EventEmitter<ControlEvent<this>>("closed");
    readonly onInit = new EventEmitter<ControlEvent<this>>("select-init");
    readonly onRendering = new EventEmitter<ControlEvent<this>>("rendering");
    options: IPopupSelectListOptions;
    protected readonly selectionHandler: (e: Event | ISelectListItem<T>) => boolean;
    protected readonly dropdown: Dropdown;
    protected readonly $textBoxContainer: JQuery;
    private openWhenLoadingFinished = false;

    constructor(selectElement: HTMLSelectElement, options?: IPopupSelectListOptions) {
        super(selectElement,
              ExtendOptions(PopupSelectList.defaultPopupSelectListOptions, options, {onInitialized: null}));
        this.$textBoxContainer = this.$selectElement
                                     .wrap(document.createElement("div"))
                                     .parent()
                                     .addClass("popup-select-list")
                                     .addClass(this.options.class || "")
                                     .append(this.$filterTextbox);
        this.$filterTextbox
            .removeAttr("tabindex")
            .addClass("fake-select")
            .removeClass("small")
            .typedData("self-validated", true);
        this.$selectElement.typedData({
                                          "popup-select-list": this,
                                          "visual-element": this.$filterTextbox
                                      });
        if (!this.options.multiple && typeof this.options.closeOnSelect !== "boolean" && !this.options.closeOnSelect)
            this.options.closeOnSelect = true;
        if ((this.$selectElement.attr("id") || "").length) {
            const label = this.$filterTextbox.closest(`label[for=${this.$selectElement.attr("id")}]`);
            if (label.length) {
                const id = this.$selectElement.attr("id") + "___fake-select";
                this.$filterTextbox.attr("id", id);
                label.attr("for", id);
            }
        }
        this.dropdown = new Dropdown(this.$element,
                                     this.$filterTextbox,
                                     {
                                         openOnFocus: true,
                                         closeOnFocusLoss: this.options.closeOnFocusLoss,
                                         position: this.options.position,
                                         autoPosition: this.options.autoPosition,
                                         alignment: this.options.alignment,
                                         autoSwapAlignment: this.options.autoSwapAlignment,
                                         container: this.options.container,
                                         css: this.options.css,
                                         "class": this.options.class,
                                         autoChangeDirection: this.options.autoChangeDirection,
                                         fixAlignment: this.options.fixAlignment,
                                         allowGrowing: this.options.allowGrowing
                                     });
        if (!this.isEnabled())
            this.disable();
        this.attachHandlers();

        this.selectionHandler = ((fn: (e: Event | ISelectListItem<T>) => void) => {
            return (e: Event | ISelectListItem<T>) => {
                const selectionChanged = fn.call(this, e);
                if (this.options.closeOnSelect && this.selectedItems.length && selectionChanged != null) {
                    if (e instanceof Event && e.originalEvent.type === "touchend") {
                        this.dropdown.popup.$element.css("opacity", 0);
                        this.$filterTextbox.removeClass("dropdown-open");
                        Utils.sleep(300)
                             .then(
                                 () => {
                                     this.close();
                                     this.dropdown.popup.$element.css("opacity", 1);
                                 });
                    } else {
                        this.close();
                    }
                }
                return selectionChanged;
            };
        })(this.selectionHandler);

        this.onInit.fire(new ControlEvent(this), [this.$selectElement]);
        this.currentItems.clear();
        this.renderItems();
        this.renderDummy(true);
        this.renderElement = this.newRenderElement;
        if (options.onInitialized)
            options.onInitialized(this);
    }

    open() {
        this.dropdown.open();
    }

    close() {
        this.dropdown.close();
    }

    toggle() {
        this.dropdown.toggle();
    }

    isOpen() {
        return this.dropdown && this.dropdown.isOpen();
    }

    // ReSharper disable once UseOfPossiblyUnassignedProperty
    disable(preventFormSubmit = false) {
        if (this.dropdown)
            this.dropdown.disable();
        this.clearFilters();
        super.disable(preventFormSubmit);
    }

    // ReSharper disable once UseOfPossiblyUnassignedProperty
    enable() {
        if (this.dropdown)
            this.dropdown.enable();
        super.enable();
    }

    newRenderElement() {
        this.renderItems();
        this.applyFilter();
        this.onRendered.fire(new RenderedEvent<this, JQuery>(this, this.$list));
        return true;
    }


    renderElement(): boolean {
        if (!super.renderElement())
            return false;
        const displayName = Utils.getFormFieldDisplayName(this.$selectElement);
        if (displayName || this.$filterTextbox) {
            let $header = $(document.createElement("div"))
                .addClass("header")
                .append(`<h2 class="hide-desktop">${displayName}</h2>`)
                .prependTo(this.$element);
            if (this.$filterTextbox)
                $header.append(this.$filterTextbox
                                   .wrap("<div class='input-group hide-desktop'>")
                                   .parent())
        }
        const $footer = $(document.createElement("div"))
            .addClass("footer")
            .appendTo(this.$element);
        if (this.options.multiple) {
            $(document.createElement("button"))
                .attr("type", "button")
                .addClass("secondary button hide-desktop")
                .text("Clear")
                .click(() => this.unselectItems(true, true))
                .appendTo($footer);
            $(document.createElement("button"))
                .attr("type", "button")
                .addClass("button hide-desktop")
                .text("Ok")
                .click(() => this.close())
                .appendTo($footer);
        } else {
            $(document.createElement("button"))
                .attr("type", "button")
                .addClass("button hide-desktop")
                .text("Ok")
                .click(() => this.close())
                .appendTo($footer);
        }
        return true;
    }

    showLoading() {
        if (this.loadingCount++ === 0)
            if (this.dropdown.isTouchMode)
                this.$element.addClass("loading");
            else
                this.$textBoxContainer.addClass("loading");
    }

    hideLoading() {
        if (--this.loadingCount <= 0) {
            this.loadingCount = 0;
            this.$element.removeClass("loading");
            this.$textBoxContainer.removeClass("loading");
            if (this.openWhenLoadingFinished) this.dropdown.open();
        }
    }

    protected searchBoxInputHandler(applyDelay: boolean = true): boolean {
        this.$selectElement.next().val(this.filter());
        return super.searchBoxInputHandler(applyDelay);
    }

    private attachHandlers() {
        this.dropdown.onOpening.on(e => {
            if (this.openWhenLoadingFinished) {
                this.openWhenLoadingFinished = false;
                return;
            }
            if (this.onOpening.fire(new ControlEvent(this, e.originalEvent)).defaultPrevented) {
                e.preventDefault();
                return;
            }
            if (this.options.reloadAjaxDataOnOpening && this.$filterTextbox.val().length) {
                this.openWhenLoadingFinished = true;
                this.searchBoxInputHandler(false);
                this.showLoading();
                e.preventDefault();
            }
        });
        this.dropdown.onOpened.on((e) => {
            this.renderElement();
            if (this.dropdown.isTouchMode) {
                $(document.createElement("input"))
                    .attr({
                              type: "text",
                              placeholder: this.options.searchPlaceholder,
                              class: "search-box fake-select dropdown-toggle dropdown-open"
                          })
                    .insertBefore(this.$filterTextbox);
                this.$filterTextbox.appendTo(this.dropdown.popup.$element.find(".header > .input-group"));
            }
            if (this.$filterTextbox && this.$filterTextbox[0] !== document.activeElement)
                (this.$filterTextbox.focus()[0] as HTMLInputElement).selectionStart =
                    this.$filterTextbox.val().length;
            this.onOpened.fire(new ControlEvent(this, false, e.originalEvent));
        });
        this.dropdown.onClosing.on(e => {
            let originalEvent = e.originalEvent;
            if (originalEvent && this.$filterTextbox.is(originalEvent.target) &&
                ["keydown", "mousedown", "click"].contains(originalEvent.type)) {
                e.preventDefault();
                return;
            }
            if (this.onClosing.fire(new ControlEvent(this, originalEvent)).defaultPrevented)
                e.preventDefault();
        });
        this.dropdown.onClosed.on((e) => {
            this.onClosed.fire(new ControlEvent(this, false, e.originalEvent));
            if (this.dropdown.isTouchMode) {
                this.$selectElement.next().remove();
                this.$filterTextbox.insertAfter(this.$selectElement);
            }
            this.selectElement.dispatchEvent(new CustomEvent("blur"));
            if (this.currentAjaxRequest)
                this.currentAjaxRequest.abort("dropdown closed");
        });
        this.dropdown.onRendering.on((e) => {
            if (this.onRendering.fire(new ControlEvent(this, e.originalEvent)).defaultPrevented)
                e.preventDefault();
        });
        this.dropdown.onRendered.on((e) => {
            if (this.onRendered.fire(new RenderedEvent(this, e.items)).defaultPrevented)
                e.preventDefault();
        });
        this.onSelectionChanged.on(() => {
            if (this.options.closeOnSelect)
                this.close();
        });
        this.dropdown.onKeyDown.on((e) => {
            if (e.originalEvent.key === " " || e.originalEvent.key === "Spacebar")
                e.preventDefault();
        });
        // this.$selectElement.on("field-validated", () => {
        //     window.setTimeout( () => {
        //         this.dropdown.popup.$element.css("border-top-color",
        //                                          window.getComputedStyle(this.dropdown.$toggleControl[0]).borderBottomColor);
        //     }, 100);
        // });
    }

    //// ReSharper disable once UseOfPossiblyUnassignedProperty
    //searchBoxInputHandler = ((fn: (applyDelay?: boolean) => void) => {
    //    return (applyDelay?: boolean) => {
    //        if (this.$filterTextbox.val().length)
    //            fn(applyDelay);
    //        else {
    //            this.currentItems.clear();
    //            this.renderItems();
    //            this.renderDummy(null, null, true);
    //        }

    //    };
    //})(this.searchBoxInputHandler);
}

declare global {
    interface JQuery {
        popupSelectList(options?: IPopupSelectListOptions): JQuery;

        popupSelectList(loadDataSettings: true): JQuery;
    }
}

jQuery.fn.popupSelectList = function (this: JQuery, options?: IPopupSelectListOptions | true) {
    return this.each((index, elem) => {
        const $elem = $(elem);
        if ($elem.data("popup-select-list"))
            return;
        if (options === true) {
            options = $elem.getJsonFromAttribute<IPopupSelectListOptions>("data-popup-select-list-settings")
                      || $elem.getJsonFromAttribute<IPopupSelectListOptions>("data-settings");
        }
        // ReSharper disable once WrongExpressionStatement
        new PopupSelectList(elem as HTMLSelectElement, options);
    });
};
