import * as Isotope from "isotope-layout";
import {checkScrollMargin, scrollBarSize, sleep} from "@utils/Utils";
import {openAlert} from "@widgets/Alert";
import {ISelectOptions, Select} from "@widgets/Select";
import {AreaCodeSelect} from "@widgets/AreaCodeSelect";
import {initSearchBar} from "@widgets/SearchBar";
import {Modal} from "@widgets/modal";
import * as Detect from "./utils/detect";
import {EqualHeight} from "@widgets/Equal_Height";
import {Viewport} from "@utils/Viewport";
import {BlueUser} from "./blueUser";
import {closeSidebar} from "@widgets/Sidebar";
import {ExportToExcel} from "./blueExcelExport";

require("./utils/JqueryLoader");
import "./libs/polyfills";
import "./bluePrototypes";
import "./utils/formSubmitHandler";


import "jquery-hoverintent";
import "./utils/mouseDown";
import "jquery.scrollintoview";
import "./widgets/accordions";
import "@widgets/accordions";
import "./blueMapScript";
import "./utils/ConnectionChecker";
import "./widgets/Repeater";
import "./widgets/Validate";
import {Router} from "@angular/router";


export class Site {

    static iso: Isotope & { on(event: "arrangeComplete" | "layoutComplete", handler: () => void): void };
    blueExcelExport = require("./blueExcelExport").ExportToExcel;
    Cropper = require("cropper");
    Dropzone = require("dropzone");
    isotope = require("isotope-layout");
    isotopePackery = require("isotope-packery");
    // userRequest = require("./blueUserRequest");

    constructor() {

        $("form").submit((e) => {
            e.preventDefault();
        });

        $(function () {

            const $reloadValueField = $("#reloadValue");
            if (!$reloadValueField.length) {
                return;
            }
            if ($reloadValueField.val().length === 0) {
                $reloadValueField.val(new Date().getTime());
            } else {
                $reloadValueField.val("");
                window.location.reload();
            }
        });

        if (!window.location.href.contains("/booking/")) {
            sessionStorage.removeItem("booking_data");
        }

        $(document)
            .touchSaveOn("mousemove mousedown mouseup click",
                         "touchstart touchend touchmove",
                         function (e: JQueryEventObject) {
                             if (e.type.startsWith("touch")) {
                                 document.body.classList.add("touch");
                             } else {
                                 document.body.classList.remove("touch");
                             }
                         }
            );

      this.Dropzone.autoDiscover = false;

        //enable to check for wrongly applied submits, as a negative side effect this will break form submit
        // noinspection ConstantIfStatementJS
        if (false) {
            // noinspection UnreachableCodeJS
            console.error(
                "Caution: Form submit is broken, because submit detection is active. Disable in site.js before moving to production.");
            const originalOn = $.fn.on;
            $.fn.on = function () {
                if (typeof arguments[0] === "string" &&
                    /\bsubmit\b/.test(arguments[0]) &&
                    typeof (arguments[3] || arguments[2] || arguments[1]) === "function") {
                    return originalOn.apply(this, ["presubmit", Array.prototype.slice.call(arguments, 1)]);
                }
                return originalOn.apply(this, arguments);
            };
            require("jquery-ajax-unobtrusive");
            const originalSubmit = $.fn.submit;
            $.fn.submit = function () {
                if (typeof (arguments[1] || arguments[0]) === "function") {
                    throw "binding to submit event is not allowed. Use presubmit or realsubmit instead";
                }
                return originalSubmit.apply(this, arguments);
            };
            $.fn.on = function () {
                if (typeof arguments[0] === "string" &&
                    /\bsubmit\b/.test(arguments[0]) &&
                    typeof (arguments[3] || arguments[2] || arguments[1]) === "function") {
                    throw "binding to submit event is not allowed. Use presubmit or realsubmit instead";
                }
                return originalOn.apply(this, arguments);
            };
            const originalOne = $.fn.one;
            $.fn.one = function () {
                if (typeof arguments[0] === "string" &&
                    /\bsubmit\b/.test(arguments[0]) &&
                    typeof (arguments[3] || arguments[2] || arguments[1]) === "function") {
                    throw "binding to submit event is not allowed. Use presubmit or realsubmit instead";
                }
                return originalOne.apply(this, arguments);
            };
            const originalAddEventListener = HTMLElement.prototype.addEventListener;
            HTMLElement.prototype.addEventListener = function () {
                if (arguments[0] === "submit") {
                    throw "binding to submit event is not allowed. Use presubmit or realsubmit instead";
                }
                return originalAddEventListener.apply(this, arguments);
            };
        } else {
            require("jquery-ajax-unobtrusive");
        }

        // let documentHasFocus = document.hasFocus();
        // window.setInterval(function () {
        //                        if (document.hasFocus()) {
        //                            if (documentHasFocus) {
        //                                return;
        //                            }
        //                            documentHasFocus = true;
        //                            document.dispatchEvent(new CustomEvent("focus"));
        //                            document.dispatchEvent(new CustomEvent("focusin"));
        //                            //var focusEvent = document.createEvent("Event");
        //                            //focusEvent.initEvent("focus", false, true);
        //                            //document.dispatchEvent(focusEvent);
        //                        } else {
        //                            if (!documentHasFocus) {
        //                                return;
        //                            }
        //                            documentHasFocus = false;
        //                            document.dispatchEvent(new CustomEvent("focusout"));
        //                            document.dispatchEvent(new CustomEvent("blur"));
        //                            //var focusOutEvent = document.createEvent("Event");
        //                            //focusOutEvent.initEvent("focusout", false, true);
        //                            //document.dispatchEvent(focusOutEvent);
        //                            //var blurEvent = document.createEvent("Event");
        //                            //blurEvent.initEvent("blur", false, true);
        //                            //document.dispatchEvent(blurEvent);
        //                        }
        //                    },
        //                    200);

        let maxLengthHandler = function (this: HTMLInputElement) {
            const $this = $(this);
            if (this.value.length >= this.maxLength) {
                this.value = this.value.substring(0, this.maxLength);

                if ($("p[data-parent=" + $this.id() + "]").length === 0) {
                    $(`<p class="length-message" data-parent="${$this.id()}">Maximum number of characters reached.</p>`)
                        .insertAfter($this);
                }
            } else {
                $("p[data-parent=" + $this.id() + "]").remove();
            }
        };
        $(document.body).on("input", "[maxlength]", maxLengthHandler);

        Viewport.enable();

        Viewport.onChange.connect(() => {
            // Put there code to be executed when viewport is changed
            $(".sidebar-right").removeAttr("style").removeClass("open");
            closeSidebar();
            $(".toggle-button").removeClass("active");
        });

        //window.parseContent = Site.parseContent.bind(this);
        //this.parseContent(document.body);
        // noinspection JSIgnoredPromiseFromCall
        //this.prepareSites(document.body);

        window.addEventListener("resize", () => checkScrollMargin());
        checkScrollMargin();
    }

    static UpdateLayout() {
        if (this.iso)
            this.iso.layout();
    }

    async prepareSites(root: HTMLElement | JQuery) {
        const $root = $(root);

        BlueUser.site = this;

        /* initialize user request class */
        // this.userRequest.site = this;

        // if ($root.hasChild(".page-onlinemonitor")) {
        //     AdminHub.start();
        // }
        //
        // if ($root.hasChild(".page-profile")) {
        //     BlueUser.AddEvents($root);
        //     const $changePasswordForm = $("div[data-role='change-password-form']");
        //     if ($changePasswordForm.is()) {
        //         new ChangePasswordForm($changePasswordForm);
        //     }
        // }
        //
        // if ($root.hasClass("page-request-account")) {
        //     const $changePasswordForm = $("div[data-role='change-password-form']");
        //     if ($changePasswordForm.is()) {
        //         new ChangePasswordForm($changePasswordForm);
        //     }
        // }

        // if ($root.hasClass("page-requests")) {
        //     $(".content-buttons button").click(function (event) {
        //         event.preventDefault();
        //         const $button = $(this);
        //         const $target = $("#" + $button.data("target"));
        //
        //         if (!$button.hasClass("active")) {
        //             $(".content-buttons button").removeClass("active");
        //             $(".hidden-content").slideUp().find("input, textarea, select").attr("data-disable-validation", "");
        //
        //             $button.addClass("active");
        //             $target.slideDown();
        //             $target.find("input, textarea, select")
        //                    .attr("required", "");
        //         }
        //         $target.find("input").parent().removeClass("error");
        //         $target.find("select").next().removeClass("error");
        //         $target.find("input, .textarea-wrapper, select, textarea").removeClass("error");
        //         $target.find(".error-message").remove();
        //     });
        //     this.userRequest.AddEvents($root);
        // }

        // if ($root.hasClass("page-shipment")) {
        //     require("./pages/ShipmentOrder.js");
        // }
        //
        // if ($root.hasClass("page-shipment-list")) {
        //     require("./pages/ShipmentOrderList")
        // }
        //
        // if ($root.hasChild(".page-entity")) {
        //     require("./pages/Entity");
        // }

        // if ($root.hasChild(".page-settings")) {
        //     require("./pages/Settings");
        // }
    }

    static parseContent(root
                     :
                     HTMLElement | JQuery
    ) {
        if (!root) {
            root = document.body;
        }
        const $root = window.$(root);

        function find(selector: string) {
            //return $(selector);
            const res1 = $root.is(selector) ? $root : $root.find("*").filter(selector);
            const res2 = $(root).is(selector) ? $(root) : $(root).find(selector);
            if (res1.length !== res2.length) {
                console.error(selector);
                console.error(res1);
                console.error(res2);
            } else {
                for (let i = 0; i < res1.length; i++) {
                    if (res1[i] !== res2[i]) {
                        console.error(selector);
                        console.error(res1);
                        console.error(res2);
                        break;
                    }
                }
            }
            return res1;
        }

        const _$ = window.$;
        const jQuery = function () {
            if (arguments[1] === undefined && typeof (arguments[0]) === "string") {
                return _$(arguments[0], root);
            }
            return _$(arguments[0], arguments[1]);
        };

        const $ = _$.extend(jQuery, _$);

       $("[data-role=repeater]").repeater();

        /* Equal height */
        find("[data-equal-height-group]").each(EqualHeight.handler);

        /* Forms */
        find("form.validate").validate();

        find("select:not(.eXselect, .popup, [data-do-not-init], [ngselect], [ngSelect])").each(function (this:HTMLSelectElement) {
            const $this = $(this);
          const selectOptions = $this.getJsonFromAttribute("data-settings") as ISelectOptions;
          if (selectOptions) {
            const containerProps: (keyof ISelectOptions)[] = ["toggleControl", "container", "content", "visualContainer"];
            for (const prop of containerProps) {
              if (!selectOptions.hasOwnProperty(prop))
                continue;
              const value = selectOptions[prop] as ContainerType;
              if (typeof value !== "string" || value[0] !== "#" && value[0] !== ".")
                continue;
              const selector = value
              // @ts-ignore
              selectOptions[prop] = () => $(selector);
            }
          }
          $this.selectControl($.extend({
                                             "ajaxResultUrl": $this.data("ajax-source")
                                         },
                                                                                                               selectOptions));
        });
        find("form")
            .on("reset", function (this: HTMLFormElement) {
                $("select", this).change()
            });

        /* Enable spacebar to toggle checkbox*/
        $("label > input[type=checkbox] ~ .icon-check").each(function (this: HTMLElement) {
            const $this = $(this);
            $this.keyup(function (e) {
                if (e.keyCode === 32) { // If spacebar fired the event
                    $this.parent().click();
                    e.preventDefault();
                    e.stopPropagation();
                }
            });
            $this.keydown(function (e) {
                if (e.keyCode === 32) { // If spacebar fired the event
                    e.preventDefault();
                    e.stopPropagation();
                }
            });
        });


        /* Modal */
        find(".modal-popup").each(function (this: HTMLElement) {
            new Modal(this)
        });

        // find('.order-box-line-container .cs-icon').each(function () {
        //     new CustomerServiceModal(this, self);
        // });

        //Popover

        find(".popover-trigger").popover();

        find("[data-alert]").click(function (this: HTMLElement,event) {
            event.preventDefault();
            const $element = $(this);
            openAlert({
                          type: $element.data("alert-type"),
                          content: $element.data("alert"),
                          timeout: $element.data("alert-timeout"),
                          dontDisplayX: $element.data("dont-display-x")
                      });
        });

        find("[data-alert-onpageload]").click();


        $.ajaxSetup({"cache": false});

        // Product List Click
        $("tr.headerline td[class^=\"mat-icon-\"]")
            .parent()
            .on("click",
                async (event) => {
                    const $tableRow = $(event.delegateTarget);
                    const parentLineNumber: number = $tableRow.data("line");
                    if (!parentLineNumber) {
                        return;
                    }
                    $(`tr[data-line="${parentLineNumber}"].subline`)
                        .toggleClass("hide-line");
                    $tableRow.find("td[class^=\"mat-icon-\"]").toggleClass("mat-icon-expandless mat-icon-expandmore");
                    window.setTimeout(() => Site.UpdateLayout(), 10);
                });

        /* Put own scripts here */

        $root.on("mousedown", ".input-appendix, .input-prependix", (e) => {
            const $input = $(e.target).prev();
            $input.focus();
            ($input[0] as HTMLInputElement).setSelectionRange($input.val().length, $input.val().length);
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();
        })
             .on("click", ".input-appendix, .input-prependix", (e) => {
                 e.preventDefault();
                 e.stopImmediatePropagation();
                 e.stopPropagation();
             });

        const $shipmentList = $(document.getElementsByClassName("shipment-list"))
        const $loadMore = find(".load-more");
        if ($loadMore.is()) {
            if ($shipmentList.is()) {
                $shipmentList.append($loadMore);
            }
            $loadMore.find("button").click(async (event) => {
                event.preventDefault();
                const $button = $(event.delegateTarget);

                $button.prop("disabled", true)
                       .text("loading")
                       .parents(".load-more")
                       .addClass("loading");
                const $ajaxTarget = _$(".ajax-target");
                $ajaxTarget
                    .children(".added")
                    .removeClass("added");
                const data: string = await $.get($button.attr("data-href"));
                $loadMore.remove();
                $ajaxTarget.append(data);
                this.parseContent($ajaxTarget);

                if (!Detect.browser.safari) {
                    if (_$(".page-shipment-list").is()) {
                        EqualHeight.setRowGroup($ajaxTarget);
                        setTimeout(function () {
                            $(".shipment-box.added").each(EqualHeight.handler);
                            EqualHeight.updateAll();
                        });
                    }
                }

                checkScrollMargin();
            });
        } else {
            $(document.getElementsByClassName("load-more")).remove();
        }

        function showButtonLoading(this: HTMLElement, e: JQueryEventObject) {
            if (this.classList.contains("submitting")) {
                e.preventDefault();
                return;
            }
            this.classList.add("submitting");
            const $button = $(":submit", this)
                .addClass("loading");

            $button.add($button.siblings(".button"))
                   .attr("disabled", "disabled")
                   .prop("disabled", true)
                   .addClass("disabled")
                   .removeAttr("href")
                   .off("click")
                   .on("click",
                       function (e) {
                           e.stopPropagation();
                           e.stopImmediatePropagation();
                           e.preventDefault();
                       });

            $button.each(function (this: HTMLElement) {
                const $this = $(this);
                if (!$this.hasData("loading-text")) {
                    return;
                }
                $this.text($this.data("loading-text"));
            });

        }

        // noinspection CssInvalidPseudoSelector
        $(".processing-submits :submit:not(.block):not(.pre-sized)").each(function (this: HTMLElement) {
            const $this = $(this);
            const widthBeforeLoading = $this.outerWidth();
            const html = $this.html();
            if ($this.hasData("loading-text")) {
                $this.text($this.data("loading-text"));
            }
            $this.prop("disabled", true)
                 .addClass("loading")
                 .css("min-width", Math.max(widthBeforeLoading, $this.outerWidth()))
                 .prop("disabled", false)
                 .html(html)
                 .removeClass("loading");
        });
        $(".processing-submits").on("realsubmit", showButtonLoading);

        const $searchBar = $(".search-bar");
        if ($searchBar.is()) {
            initSearchBar($searchBar);
        }

        const $message = $(".notify-message");
        if ($message.is()) {
            openAlert({
                          type: "success",
                          content: $message.html(),
                          timeout: 5000,
                          dontDisplayX: false
                      });
        }

        const $telephoneInput = $(".telephone-input");
        if ($telephoneInput.length > 0) {
            const telephoneInput = new AreaCodeSelect($telephoneInput);
            const countrySelect = $("#country").typedData("select") as Select;
            if (countrySelect) {
                countrySelect.onSelectionChanged.on(function (e) {
                    if ((telephoneInput.select.getSelectedItems().length === 0 ||
                         !telephoneInput.getPhoneNumber())) {
                        if (e.addedItems.length) {
                            telephoneInput.selectCountry(e.addedItems[0].value);
                        } else {
                            telephoneInput.select.unselectItems(true);
                        }
                    }
                });
                if (countrySelect.getSelectedItems().length > 0) {
                    if (telephoneInput.select.getSelectedItems().length === 0 ||
                        !telephoneInput.getPhoneNumber()) {
                        telephoneInput.selectCountry(countrySelect.getSelectedItems()[0].value);
                    }
                }
            }
        }


        find(".entity.added").each((i, elem) => {
            const $entity = $(elem);
            $entity.find(".collapse")
                   .click(function (this: HTMLElement, event) {
                       event.preventDefault();
                       const $element = $(this).parent().parent();
                       const options = $element.children(".content");

                       if ($element.hasClass("close")) {
                           $element.removeClass("close");
                           options.stop(true, true).slideDown();
                       } else {
                           $element.addClass("close");
                           options.stop(true, true).slideUp();
                       }
                   });

            $entity.find(".close")
                   .click(function (event) {
                       event.preventDefault();
                       $entity.slideUp(() => $entity.remove());
                   });
        });
        // Link permission check
        $("a[data-linkchecktype]").click(async function (this: HTMLElement, ev) {

            if ($(this).data("linkchecktype") === "Shipment") {
                const href = $(this).attr("href");
                if (href && href.indexOf("Shipment/ShipmentDetail?JobID") !== -1) {
                    ev.preventDefault();
                    const start = href.indexOf("JobID=");
                    const end = href.length;

                    const pk = href.substring(start, end);

                    const result = await $.getJSON(window.baseApplicationUrl +
                                                   "/WebMethod/IsShipmentValidForUser?" +
                                                   pk);
                    if (result) {
                      void window.ngInjector.get(Router).navigateByUrl(href);
                    } else {
                        openAlert({
                                      type: "warning",
                                      content: "You do not have permissions to view this shipment.",
                                      timeout: 3000
                                  });
                    }
                }
            }
            if ($(this).data("linkchecktype") === "Order") {
                const href = $(this).attr("href");
                if (href && href.indexOf("Order/OrderDetail?pk") !== -1) {
                    ev.preventDefault();
                    const start = href.indexOf("pk=");
                    const end = href.length;

                    const pk = href.substring(start, end);

                    const result = await $.getJSON(window.baseApplicationUrl +
                                                   "/WebMethod/IsOrderValidForUser?" +
                                                   pk);
                    if (result) {
                      void window.ngInjector.get(Router).navigateByUrl(href);
                    } else {
                        openAlert({
                                      type: "warning",
                                      content: "You do not have permissions to view this order.",
                                      timeout: 3000
                                  });
                    }
                }
            }
        });
        this.filtersCountUpdate();

        // Generate QKey
        $("a.button.genqkey").on("click", function (this: HTMLAnchorElement) {
            const button = $(this);
            $.ajax({
                       "method": "PUT",
                       "url": window.baseApplicationUrl + "/webmethod/GenerateNewQkey",
                       "data": {
                           siteID: $("input#PK").val()
                       },
                       "success": function (response) {
                           $(button)
                               .after($("<a class=\"tracklink\" href=\"" + response + "\" target=\"_blank\" />")
                                          .text(response))
                               .after($("<label/>").text("Quick Track & Trace Link"))
                               .remove();
                       }
                   });
        });

        // Generate Notify Booking Recipients (Entity Details)
        $("button.notifybookingrecipients")
            .on("click",
                async function (this: HTMLButtonElement) {
                    const $button = $(this);
                    if (!$button.hasClass("disabled")) {
                        $button.addClass("disabled");
                        await $.post(window.baseApplicationUrl + "/webmethod/NotifyBookingReceipients",
                                     {siteID: $("input#PK").val()});
                        openAlert({
                                      type: "success",
                                      content: "Information Mails sent to defined email addresses.",
                                      timeout: 10000,
                                      dontDisplayX: false
                                  });
                        await sleep(1000);
                        $button.removeClass("disabled");
                    }
                });

      checkScrollMargin();
      window.dispatchEvent(new CustomEvent("scrollbar-overlay-update"));

      const $exportButton = $("#b-export-to-excel");
      if ($exportButton.length)
        new ExportToExcel($exportButton)
      $("main:first").css("margin-right", scrollBarSize);
    }

    static filtersCountUpdate() {
        const $button = $(".filters-toggle");
        const $span = $button.children("span.filter-count");
        const fields = $(".filter-columns select:not(.datepicker-select), .filter-columns .output")
            .toArray() as HTMLInputElement[];

        const count = fields.filter(f => !!f.value).length;

        if (count > 0) {
            $button.attr("data-filters-count", count);
            $span.attr("data-filters-count", count);
        } else {

            $button.removeAttr("data-filters-count");
            $span.removeAttr("data-filters-count");
        }

    }

}
