import { Injectable } from '@angular/core';
import {Settings} from "@api";
import {SettingsApi, UserApi} from "../api/Apis";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private _settingsApi: SettingsApi;

  constructor(settingsApi: SettingsApi) {
    this._settingsApi = settingsApi;
  }

  getInvoiceSetting() {
    return this._settingsApi.getDisputeInvoiceSettings();
  }
  async getSettings() {
    return await this._settingsApi.get().toPromise();
  }

  async saveSettings(settings: Settings) {
    await this._settingsApi.save(settings).toPromise();
  }
}

@Injectable({
  providedIn: 'root'
})
export class UsersApiService {
  private _usersApi: UserApi;

  constructor(usersApi: UserApi) {
    this._usersApi = usersApi;
  }

  // sendWelcomeMail(id:string): Promise<Settings | null> {
  //   return this._usersApi.sendWelcomeMail(id).toPromise();
  // }
  //
  // async sendPasswordMail(id:string) {
  //   return await this._usersApi.sendPasswordMail(id).toPromise();
  // }
}
