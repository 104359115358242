import {normalizeId} from "@utils/normalize-Id";
import {UserViewData} from "./user-view-data";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class UserDetailViewData extends UserViewData {
  readonly userId: string | null;
  readonly created: boolean;

  constructor(userId: string);
  constructor(userId: string, created: boolean);
  constructor(userId: string, created = false) {
    super();
    this.userId = normalizeId(userId);
    this.created = created;
  }

  hasNewInformationComparedTo(compare: this): boolean {
    const idChanged = this.userId !== compare.userId;
    const createdChanged = this.created !== compare.created;
    return idChanged || createdChanged;
  }

  get pageTitle(): string {
    // if (this.hasDefinedUserName())
    //   return TitleBuilder.account.viewUser(this.userName!);
    return TitleBuilder.account.users;
  }

  get url(): string {
    return UrlBuilder.account.viewUser(this.userId);
  }

  isValid(): boolean {
    return this.userId !== null;
  }

  readonly bodyClasses = ["page-profile", "page-view-user"];
}
