import {ViewData} from "./view-data";
import {TemplateStep} from "@Enums";
import {normalizeId} from "@utils/normalize-Id";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class TemplateViewData extends ViewData {
  readonly templateId: string | null;
  readonly step: TemplateStep;

  constructor(step: TemplateStep);
  constructor(step: TemplateStep, templateId: string);
  constructor(step: TemplateStep, templateId: string | null = null) {
    super();

    this.templateId = normalizeId(templateId);
    this.step = step;
  }

  isNewTemplate(): boolean {
    return this.templateId === null;
  }

  hasNewInformationComparedTo(compare: this): boolean {
    const stepChanged = this.step !== compare.step;
    const idChanged = this.templateId !== compare.templateId;
    return stepChanged || idChanged;
  }

  get pageTitle(): string {
    if (this.isNewTemplate())
      return TitleBuilder.template.newTemplate;
    // if (this.hasDefinedTemplateName())
    //   return TitleBuilder.template.withName(this.templateName!)
    return TitleBuilder.template.editTemplateWithoutName
  }

  get url(): string {
    if (this.templateId)
      return UrlBuilder.booking.editTemplate(this.templateId);
    switch (this.step) {
      case "Overview":
        return UrlBuilder.booking.overview;
      case "Service":
        return UrlBuilder.booking.service;
      case "Parties":
        return UrlBuilder.booking.parties;
      case "Goods":
        return UrlBuilder.booking.goods;
      case "AdditionalInformation":
        return UrlBuilder.booking.additionalInfo;
      case "Submit":
        return UrlBuilder.booking.submit;

    }
  }

  readonly bodyClasses = ["page-shipment-booking", "page-booking"];
}
