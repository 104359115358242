import {Component, OnInit, ViewChild} from '@angular/core';
import {AccountService, RequestAccessViewData} from "@services";
import {ForgotPasswordViewData, LoginViewData} from "@view-data";
import {Modal} from "@widgets/modal";
import {TypeFilter} from "@utils/rxjs-extensions";
import {filter} from "rxjs/operators";
import {InputValidatorDirective} from "@directives";
import {RoutingService} from "@app/services/routing.service";
import {AppBaseComponent} from "@app/appBaseComponent";

@Component({
             selector: 'rt-login',
             templateUrl: './login.component.html',
             styleUrls: ['./login.component.scss']
           })
export class LoginComponent extends AppBaseComponent implements OnInit {

  mailAddressValue = "";
  passwordValue = "";
  rememberMe = true;
  hasLoginError = false;
  accountHasBeenUnlocked = false;
  @ViewChild("password", {read: InputValidatorDirective})
  passwordValidator: InputValidatorDirective
  @ViewChild("mailAddress", {read: InputValidatorDirective})
  mailAddressValidator: InputValidatorDirective
  isLoggingIn: boolean;
  isDemo = window.isDemo;
  private readonly _accountService: AccountService;
  private readonly _routingService: RoutingService;
  private readonly rohligPasswordResetModal = LoginComponent.createRohligResetPasswordModal();

  constructor(routingService: RoutingService,
              accountService: AccountService) {
    super()
    this._accountService = accountService;
    this._routingService = routingService;
  }

  get formDisabled(): boolean {
    return this.isDemo || this.isLoggingIn;
  }

  static createRohligResetPasswordModal(): Modal {
    return new Modal(null,
                     {
                       html:
                         `<p>Please use your Röhlig password to log in to Röhlig Real Time.</p>
<p style="margin:10px 0;">If you have forgotten your Röhlig password or need further assistance, please contact  <a href="mailto:blue.support@rohlig.com?Subject=R%C3%B6hlig%20Password">Blue Support</a>.</p>
<p style="margin:10px 0;">In case you tried to log in after your Röhlig password expired, you will receive an email with instructions on how to change it.</p>`,
                       header: "Röhlig Password",
                       buttons: {close: "close"}
                     })
  }

  ngOnDestroy(): void {
    this.rohligPasswordResetModal.destroy();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this._accountService.loginFailed$.subscribe(() => this.loginFailed());
    this._routingService
        .routingStore
        .observe("currentViewData")
        .pipe(filter(TypeFilter(LoginViewData)))
        .subscribe(d => this.handleViewDataUpdate(d))
    if (this.isDemo) {
      this.mailAddressValue = "demouser@democompany.com";
      this.passwordValue = "thisisapassword";
    }
  }

  async login($event?: Event): Promise<void> {
    $event?.preventDefault();
    if (this.isLoggingIn)
      return;
    const passwordValid = await this.passwordValidator.validate();
    const mailAddressValid = await this.mailAddressValidator.validate();
    if (!passwordValid || !mailAddressValid)
      return;
    this.isLoggingIn = true;
    // noinspection ES6MissingAwait
    void this._accountService.login(this.mailAddressValue, this.passwordValue, this.rememberMe);
  }

  forgotPasswordClicked($event: MouseEvent): void {
    $event.preventDefault();
    const isRohligMailAddress = /@rohlig\.co.*$/i.exec(this.mailAddressValue);
    if (isRohligMailAddress)
      this.rohligPasswordResetModal.openModal();
    else
      this._routingService.navigateTo(new ForgotPasswordViewData(this.mailAddressValue))
  }

  hideLoginError(): void {
    this.hasLoginError = false;
  }

  private loginFailed() {
    this.isLoggingIn = false;
    this.hasLoginError = true;
  }

  private handleViewDataUpdate(d: LoginViewData) {
    this.accountHasBeenUnlocked = d.userHasBeenUnlocked;
  }
}
