import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {ShipmentOverviewItem} from "@app/page-components/shipment/shipment-list/shipment-overview-item";
import {ShipmentCardLayoutMapper, ShipmentBoxFields, ShipmentBoxFieldsContainerList} from "@app/page-components/shipment/shipment-list/shipment-box-fields";
import {FieldRenderer} from "@app/page-components/shipment/shipment-list/field-renderer.service";
import {AccountStore, ShipmentStore} from "@stores";
import {map} from "rxjs/operators";
import {ShipmentListViewMode} from '@api';

@Component({
             selector: 'rt-shipment-list-box',
             templateUrl: './shipment-list-box.component.html',
             styleUrls: ['./shipment-list-box.component.scss'],
             host: {
               class: "shipment-box added"
             }
           })
export class ShipmentListBoxComponent {

  @Input() shipment: ShipmentOverviewItem;

  boxLayout$: Observable<ShipmentCardLayoutMapper[]>;
  readonly renderer: FieldRenderer;
  private readonly _accountStore: AccountStore;
  private readonly _shipmentStore: ShipmentStore;

  constructor(shipmentStore: ShipmentStore, accountStore: AccountStore, renderer: FieldRenderer) {
    this._shipmentStore = shipmentStore;
    this.renderer = renderer;
    this._accountStore = accountStore;

    accountStore
      .observe("shipmentListViewMode")
      .subscribe(m => {
        if (m === ShipmentListViewMode.Shipments) {
          this.boxLayout$ = accountStore
            .observe("shipmentCardLayout")
            .pipe(map(l => l.map(item => ShipmentBoxFields[item])));
        } else {
          this.boxLayout$ = accountStore
            .observe("containerCardLayout")
            .pipe(map(l => l.map(item => ShipmentBoxFieldsContainerList[item]).filter(i => !!i)));
        }
      });
  }

  renderFields(shipment: ShipmentOverviewItem, layout: ShipmentCardLayoutMapper[], renderer: FieldRenderer): string {
    let output = "";
    for (const field of layout) {
      if (field.hidden && field.hidden(shipment))
        continue;
      output += ` <p class="${field.class}">
            <strong>${field.title(shipment)}</strong>
           ${field.getContent(shipment, renderer)}
          </p>`
    }
    return output;
  }

}
