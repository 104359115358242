import {JobType} from "@api";
import {ShipmentTypeUrlParamPipe} from "@app/value-pipes/string-value.pipe";

export const UrlBuilder = {
  shipment: {
    list: (redoSearch: boolean) =>  redoSearch ? "Shipment?search=true" : "Shipment",
    listWithQuery: (query: string) => "Shipment?q=" + query,
    consolidationShipments: (lead: string, type: JobType) => `Shipment/Consolidation?JobId=${lead}&JobType=${ShipmentTypeUrlParamPipe.transform(type)}`,
    shipmentDetail: (id: string, type: JobType) => `Shipment/ShipmentDetail?JobID=${id}&ObjectType=${ShipmentTypeUrlParamPipe.transform(type)}`,
    containerDetail: (containerId: string) => `Shipment/ShipmentDetail?ContainerID=${containerId}`
  },
  order: {
    list: "Order",
    listWithQuery: (query: string) => "Order?q=" + query,
    orderDetail: (id: string) => `Order/OrderDetail?pk=${id}`
  },
  account: {
    login: "Account/Login",
    loginWithUnlocked: "Account/Login?unlocked=true",
    loginWithReturnUrl: (returnUrl: string) => `Account/Login?returnUrl=${returnUrl}`,
    users: "Account/Users",
    myProfile: "Account/MyProfile",
    viewUser: (id: string) => `Account/ViewUser?ID=${id}`,
    editUser: (id: string) => `Account/EditUser?ID=${id}`,
    addUser: "Account/AddUser",
    changePasswordWithRequestId: (requestId: string) => `Account/ChangePassword?requestId=${requestId}`,
    changePassword: "Account/ChangePassword",
    changePasswordComplete: "Account/ChangePasswordComplete",
    forgotPasswordWithMailAddress: (mailAddress: string) => `Account/ForgotPassword?mailAddress=${mailAddress}`,
    forgotPassword: "Account/ForgotPassword",
    requestAccess: "Account/RequestAccess"
  },
  booking: {
    bokkingWithTemplate: (templateId: string) => `booking?templateId=${templateId}`,
    editTemplate: (templateId: string) => `booking/overview?templateId=${templateId}`,
    overview: "booking/overview",
    service: "booking/service",
    parties: "booking/parties",
    goods: "booking/goods",
    additionalInfo: "booking/additional-info",
    submit: "booking/submit",
  },
  entities: {
    list: "SiteManagement",
    addSite: "SiteManagement/AddSite",
    siteDetails: (id: string) => `SiteManagement/SiteDetails?id=${id}`
  },
  settings: "Settings",
  onlineMonitor: "Admin",
  errors: {
    accessDenied: "Error/AccessDenied",
    notFound: "Error/NotFound",
    unexpectedError: "Error/UnexpectedError",
    offline: "Error/NotFound"
  }
};
