import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChatService,  } from '@services';
import {RoutingStore} from "@stores";

@Component({
  selector: 'rt-chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatBubbleComponent {
  messageVisible = new BehaviorSubject(false);
  private readonly _chatService: ChatService;
  private readonly _routingStore: RoutingStore;
  private _openTimer:ReturnType<typeof setTimeout>;
  private _closeTimer: ReturnType<typeof setTimeout>;


  constructor(routingStore: RoutingStore, chatService: ChatService)
  {
    this._routingStore = routingStore;
    this._chatService = chatService;
    this.messageVisible.next(false);
    this._chatService.isVisible$.asObservable().subscribe(d => this.updateVisibility(d));
    this._routingStore.subscribe(() => {this.messageVisible.next(false)});
  }

  //Funktioniert
  @HostListener('document:click', ['$event.target.className'])
  onClick(className: string): void {
    //console.log("In Hostlistener. ClassName: ", className);
    if (className.includes('LPMcontainer') || className.includes('LPMimage') ) {
      //console.log("In IF!!!")
      this.close();
    }
  }

  private updateVisibility(isVisible: boolean): void {
    const result = this.getCookie();
    if (!(result)) {
      this.resetTimer();
      
     if (isVisible) {
        //appears after 30s (30.000ms)
        this._openTimer = setTimeout(() => {
          if (document.getElementsByClassName('LPMcontainer')[0] as HTMLElement == null) {
            this.messageVisible.next(false);
          } else {
            this.messageVisible.next(true);
          }
          //this.messageVisible.next(true);
        }, 30000);

        //disappears after 10 min (600.000ms)
        this._closeTimer = setTimeout(() => {
          this.messageVisible.next(false);
        }, 600000);
      } else {
        this.messageVisible.next(false);
      }
    }
  }

  private resetTimer() {
    if (this._openTimer) {
      clearTimeout(this._openTimer);
    }
    if (this._closeTimer) {
      clearTimeout(this._closeTimer);
    }
  }
  private getCookie(): string {
    let result;
    const nameEQ = 'proactive-message' + "=";
    const ca = this._chatService._document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0)
        result = c.substring(nameEQ.length, c.length);
    }
    return result;
  }
  private setCookie() {
    const name = 'proactive-message';
    const value = 'true';
    const days = 7;
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    this._chatService._document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
    //Closed by the user
   close(): void {
     this.messageVisible.next(false);
     this.resetTimer();
     this.setCookie();
  }

  //opens the chat window when clicking on the speech message
   openChat(): void {
    const chatWindow: HTMLElement = document.getElementsByClassName('LPMcontainer')[0] as HTMLElement;
    chatWindow.click();
    this.close();
  }

}
