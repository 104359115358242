import {Injectable} from '@angular/core';
import {PrepareJsonDataFromServer, PrepareJsonDataToServer, ResolveJsonRefs, SetJsonRefs} from "@utils/Utils";
import {BookingModel} from "@booking/BookingModel";
import {ViewArrangement} from "@booking/booking-views";

@Injectable({
              providedIn: 'root'
            })
export class SessionStorageService {
  private static readonly bookingDataKey = "booking-data";
  private static readonly bookingDataExpiration = 60000;

  private static _removeData(key: string) {
    window.sessionStorage.removeItem(key);
    window.sessionStorage.removeItem(`${key}-expiration`);
  }

  private static _loadObjectAndPrepare<T>(key: string): T {
    const expirationString = window.sessionStorage.getItem(`${key}-expiration`);
    if (expirationString) {
      const expiration = parseInt(expirationString);
      if (expiration <= new Date().getTime())
        return null;
    }
    const plainJsonObject = JSON.parse(window.sessionStorage.getItem(key)) as T;
    if (!plainJsonObject)
      return null;
    const objectWithDateObjects = PrepareJsonDataFromServer(plainJsonObject);
    const objectWithResolvedRefs = ResolveJsonRefs(objectWithDateObjects);
    return objectWithResolvedRefs;
  }

  private static _hasData(key: string) {
    const expirationString = window.sessionStorage.getItem(`${key}-expiration`);
    if (expirationString) {
      const expiration = parseInt(expirationString);
      if (expiration <= new Date().getTime())
        return false;
    }
    const plainJsonObject = window.sessionStorage.getItem(key);
    return plainJsonObject !== null;

  }

  private static _saveObjectAndPrepare(key: string, object: object, validForMs?: number): void {
    if (!object) {
      this._removeData(key);
      return;
    }
    const objectWithExcapedRefs = object.hasOwnProperty("$id") ? object : SetJsonRefs(object);
    const objectWithStringifiedDates = PrepareJsonDataToServer(objectWithExcapedRefs);
    window.sessionStorage.setItem(key, JSON.stringify(objectWithStringifiedDates));
    if (validForMs > 0) {
      window.sessionStorage.setItem(`${key}-expiration`, (new Date().getTime() + validForMs).toString());
    }
  }

  loadBookingData() {
    const modelFromStorage = SessionStorageService._loadObjectAndPrepare<BookingModel>(SessionStorageService.bookingDataKey);
    if (!modelFromStorage)
      return null;
    const model = $.extend(new BookingModel(), modelFromStorage);
    $.getJSON(window.baseApplicationUrl + "/WebMethod/GetUploadedBookingFileIds").then(
      (fileIds: string[]) => {
        model.files
             .filter(f => !fileIds.contains(f.file.id))
             .map(f => f.state = "error")
      });
      if (model.booking.deliverAt instanceof Date) {
        model.booking.deliverAt.isTimeSet = !!model.booking.deliveryTimeSpecified;
      }
      if (model.booking.pickupAt instanceof Date) {
        model.booking.pickupAt.isTimeSet = !!model.booking.pickupTimeSpecified;
    }
    return Object.assign(new BookingModel(), modelFromStorage);
  }

  saveBookingData(modelFromStorage: BookingModel) {
    const modelCopy = SetJsonRefs(modelFromStorage);
    modelCopy.viewStates = ViewArrangement.map(v => {
      return {isActivated: v.isActivated, valid: v.valid};
    });
    modelCopy.files
             .filter(f => !!f.file)
             .forEach(f => f.file.xhr = null)
    return SessionStorageService._saveObjectAndPrepare(SessionStorageService.bookingDataKey, modelCopy, SessionStorageService.bookingDataExpiration)
  }

  removeBookingData() {
    return SessionStorageService._removeData(SessionStorageService.bookingDataKey);
  }

  hasBookingData() {
    return SessionStorageService._hasData(SessionStorageService.bookingDataKey);
  }
}
