import {Injectable} from "@angular/core";
import {Store, StoreProjectionMapping} from "@app/stores/store";
import {OrderStoreState} from "@app/stores/orderStoreState";
import {ShipmentOverviewItem} from "@app/page-components/shipment/shipment-list/shipment-overview-item";
import {ShipmentStoreState} from "@stores/shipmentStoreState";

type OrderStoreProjection = {
  orderTitle: string | null,
  filterCount:number,
  showLoadMoreButton: boolean
};

@Injectable({providedIn: "root"})
export class OrderStore extends Store<OrderStoreState, OrderStoreProjection> {

  getName(): string {
    return "OrderStore";
  }

  private static readonly _initialState: Partial<OrderStoreState> = {
    currentSearch: {},
    orderList: null,
    isLoadingMore: false,
    isSearching: false,
    moreOrdersAvailable: false
  }

  private static readonly _projectionMapping: StoreProjectionMapping<OrderStoreState, OrderStoreProjection> = {
    orderTitle: ({currentOrder}) => {
      if (!currentOrder)
        return null;
      else
        return currentOrder.orderNumber || "T.B.D.";
    },
    showLoadMoreButton: u =>
      !u.isSearching &&
      !!u.orderList &&
      !!u.orderList.any() &&
      !!u.moreOrdersAvailable,
    filterCount: ({currentSearch}: Partial<OrderStoreState>) => {
      if (!currentSearch)
        return 0;
      const {
        endDate,
        startDate,
        status,
        transportMode
      } = currentSearch;
      let count = [
        status,
        transportMode
      ]
        .filter(a => a && a.any())
        .length;
      if (startDate || endDate)
        count++;
      return count;
    }
  }

  constructor() {
    super(OrderStore._projectionMapping, OrderStore._initialState);
  }
}
