import {ISelectListItem} from "@widgets/SelectList";
import {SearchString} from "../libs/SearchString";
import {Injectable} from "@angular/core";
import {DangerousGoodsMasterData, WebMethodClient} from "@api";
import {createDeepCopy} from "@utils/createDeepCopy";

@Injectable({providedIn: "root"})
export class DangerousGoodsService {

  private readonly _dangerousGoodsSelectItems: Promise<Partial<ISelectListItem<JSONDataContainer<DangerousGoodsMasterData>>>[]>;
  private _seaDangerousGoodsDictionary: Promise<IStringIndexable<DangerousGoodsMasterData>>;
  private _airDangerousGoodsDictionary: Promise<IStringIndexable<DangerousGoodsMasterData>>;

  getDangerousGoodsDictionary(transportMode: "AIR" | "SEA"): Promise<IStringIndexable<DangerousGoodsMasterData>> {
    if (transportMode === "SEA")
      return this._seaDangerousGoodsDictionary
        .then(createDeepCopy)
    else
      return this._airDangerousGoodsDictionary
        .then(createDeepCopy)
  }

  constructor(webMethodClient: WebMethodClient) {
    let selectItemsResolver: Action1<Partial<ISelectListItem<JSONDataContainer<DangerousGoodsMasterData>>>[]>
    let airDictionaryResolver: Action1<IStringIndexable<DangerousGoodsMasterData>>;
    let seaDictionaryResolver: Action1<IStringIndexable<DangerousGoodsMasterData>>;

    this._dangerousGoodsSelectItems = new Promise(r => selectItemsResolver = r);
    this._airDangerousGoodsDictionary = new Promise(r => airDictionaryResolver = r);
    this._seaDangerousGoodsDictionary = new Promise(r => seaDictionaryResolver = r);
    webMethodClient
      .getDangerousGoodsData()
      .toPromise()
      .then(d => {
        const selectItems = this._convertDgDataToSelectListItems(d);
        const seaDictionary = d
          .filter(dg => dg.mode === "SEA")
          .toDictionary(dg => dg.unno + dg.variant);
        const airDictionary = d
          .filter(dg => dg.mode === "AIR")
          .toDictionary(dg => dg.unno + dg.variant);
        selectItemsResolver(selectItems);
        airDictionaryResolver(airDictionary)
        seaDictionaryResolver(seaDictionary)
      });
  }

  async createDangerousGoodsSelectItemsList(transportMode: "AIR" | "SEA") {
    const dangerousGoods = await this._dangerousGoodsSelectItems;
    return dangerousGoods
      .filter(dg => dg.ajaxData.data.mode === transportMode)
      .map(g => {
        g = Object.assign({}, g);
        g.option = document.createElement("option");
        g.option.value = g.value;
        return g;
      });
  }

  private _convertDgDataToSelectListItems(dangerousGoodsMasterDataList: DangerousGoodsMasterData[]) {
    return dangerousGoodsMasterDataList.map(dgData => {
      const values = new Array<string>();

      if (dgData.subLabel1 && dgData.subLabel2) {
        values.push(`Sub Classes ${dgData.subLabel1}, ${dgData.subLabel2}`);
      } else if (dgData.subLabel1 || dgData.subLabel1) {
        values.push(`Sub Class ${(dgData.subLabel1 || dgData.subLabel2)}`);
      }

      if (dgData.pg)
        values.push(`Packing Group ${dgData.pg}`);

      let unClass = "";
      if (dgData.class) {
        unClass = `Class ${dgData.class}`;
        if (values.length)
          unClass += ", ";
      }

      let html = `<span class="un-class">UN ${dgData.unno}</span>${dgData.psn}`;
      if (values.length)
        html += `<small class="text-light">${unClass}<span class="no-search">${values.join(", ")}</span></small>`;
      const jsonData: Partial<JSONDataContainer<DangerousGoodsMasterData>> =
        {
          html: html,
          value: `${dgData.unno}${dgData.variant}`,
          data: dgData
        };
      return {
        selected: false,
        contentString: jsonData.html,
        value: jsonData.value,
        ajaxData: jsonData,
        contentSearchString: new SearchString(jsonData.html, false),
        normalizedValue: jsonData.value
                                 .normalize("NFD")
                                 .replace(/[\u0300-\u036f]/g, "")
                                 .toLowerCase()
      } as Partial<ISelectListItem<JSONDataContainer<DangerousGoodsMasterData>>>
    });
  }
}
