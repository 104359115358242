import {Component, ViewChild} from '@angular/core';
import {ProfileUpdateData, UserGroup, UserInformation, UserType} from "@api";
import {Observable} from "rxjs";
import {userGroupMapper, userTypeMapper} from "@app/utils/enum-display-name-mapper";
import {Location} from "@angular/common";
import {ChangePasswordFormDirective, FormValidatorDirective} from "@directives";
import {AccountService} from "@services";
import {CountryService} from "@services/country.service";
import {MyProfileEditUiStore} from "@app/page-components/my-profile/my-profile-edit-ui-store.service";
import {TypedStoreAccessors} from "@stores";
import {AppBaseComponent} from "@app/appBaseComponent";
import {takeUntil} from "rxjs/operators";

@Component({
             selector: 'rt-my-profile',
             templateUrl: './my-profile.component.html',
             styleUrls: ['./my-profile.component.scss'],
             providers: [
               {
                 provide: MyProfileEditUiStore,
               }
             ]
           })
export class
MyProfileComponent extends AppBaseComponent {
  readonly userData$: Observable<Partial<UserInformation>>;
  readonly userType = UserType;
  readonly isDemo = window.isDemo;
  isSaving = false;

  @ViewChild(FormValidatorDirective)
  formValidator: FormValidatorDirective;

  @ViewChild(ChangePasswordFormDirective)
  changePasswordFormDirective: ChangePasswordFormDirective;
  readonly storeAccessors: TypedStoreAccessors<ProfileUpdateData, "profilePictureBase64" | "phone" | "passwordChange">;
  private readonly _accountService: AccountService;
  private readonly _location: Location;
  private readonly _countryService: CountryService;

  constructor(accountService: AccountService, location: Location, countryService: CountryService, myProfileEditUiStore: MyProfileEditUiStore) {
    super();
    this._countryService = countryService;
    this._location = location;
    this.userData$ = accountService.accountStore.observe()
    this._accountService = accountService;
    this.storeAccessors = myProfileEditUiStore.getStoreAccessors("profilePictureBase64", "phone", "passwordChange");
    this._accountService.accountStore
        .observe(["profilePictureBase64", "phone", "phoneCountry"])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({phone, phoneCountry, profilePictureBase64}) => {
          this.storeAccessors.profilePictureBase64.set(profilePictureBase64);
          if (phone)
            this.storeAccessors.phone.set({
                                            countryCode: phoneCountry.isoAlpha2,
                                            number: phone
                                          })
          else
            this.storeAccessors.phone.set(null);
        })
  }

  getUserTypeName(userType: UserType): string {
    return userTypeMapper[userType];
  }

  getUserGroupName(userGroup: UserGroup): string {
    return userGroupMapper[userGroup];
  }

  async save(): Promise<void> {
    this.isSaving = true;
    if (!(await this.formValidator.validate())) {
      this.isSaving = false;
      return;
    }
    const profileUpdate: ProfileUpdateData = {
      profilePictureBase64: this.storeAccessors.profilePictureBase64.value
    }
    if (this._accountService.accountStore.get("type") === UserType.Client) {
      profileUpdate.passwordChange = this.storeAccessors.passwordChange.value
      profileUpdate.phone = this.storeAccessors.phone.value
    }
    try {
      await this._accountService.updateProfileData(profileUpdate);
      this.changePasswordFormDirective.reset();
    } finally {
      this.isSaving = false;
    }
  }

  cancel(): void {
    this._location.back();
  }

  setCountry(userData: Partial<UserInformation>, countryCode: string): void {
    const country = this._countryService.getByIsoAlpha2(countryCode);
    userData.phoneCountry = this._countryService.convertToApiCountry(country)
  }
}

