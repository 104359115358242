import {Component, Input} from '@angular/core';
import {JSONData, Order, OrderExcelExportMode, OrderSearchPreset, OrderSearchReferenceType} from "@api";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {OrderService} from "@services";
import {trackById} from "@app/utils/track-by-id";
import {ISelectOptions} from "@widgets/Select";
import {StoreAccessor, TypedStoreAccessor} from "@stores/store-accessor";
import {ExportToExcelService} from "@app/services/export-to-excel.service";
import {AccountStore, UserRightsStore} from "@stores";
import {OrderSearchUiStore} from "@app/page-components/order/order-list/order-search-bar/order-search-ui-store.service";
import {toJsonData} from "@utils/Utils";
import * as OrderSearchCodeMapper from "@app/page-components/order/order-list/order-search-bar/order-search-code-mapper";

@Component({
             selector: 'rt-order-list',
             templateUrl: './order-list.component.html',
             styleUrls: ['./order-list.component.scss']
           })
export class OrderListComponent {
  @Input("skeleton-item-count")
  skeletonItemCount: number = 24;

  readonly canStartNewExcelReport$: Observable<boolean>
  readonly displayListHeader$: Observable<boolean>;
  readonly filterCount$: Observable<number>;
  readonly hasExportToExcelRights$: Observable<boolean>;
  readonly isLoadingMore$: Observable<boolean>;
  readonly isSearching$: Observable<boolean>;
  readonly mobileSearchHint$: Observable<string>;
  readonly noResultsFound$: Observable<boolean>;
  readonly orders$: Observable<Order[]>;
  readonly searchPresets$: Observable<OrderSearchPreset[]>
  readonly showLoadMoreButton$: Observable<boolean>;
  readonly showMobileSearchHint$: Observable<boolean>;

  readonly trackById = trackById;
  readonly referenceTypeSearchItems = toJsonData(OrderSearchCodeMapper.referenceTypes);

  readonly selectedReference = new StoreAccessor(this, "reference");
  readonly selectedReferenceType = new StoreAccessor(this, "referenceType");
  readonly filtersOpen = new StoreAccessor(this, "filtersOpen");
  readonly exportToExcelModeAccessor: TypedStoreAccessor<OrderExcelExportMode>;

  readonly excelExportSettings: ISelectOptions = {
    allowNoSelection: false,
    class: "double-button-droplist droplist-button",
    toggleControl: "parent",
    visualContainer: "button-group"
  };
  readonly store: OrderSearchUiStore;


  readonly excelExportItems: JSONData[] = [
    {
      value: OrderExcelExportMode.ByOrder,
      label: "By Order"
    },
    {
      value: OrderExcelExportMode.ByShipment,
      label: "By Shipment"
    },
    {
      value: OrderExcelExportMode.ByOrderLineAndShipment,
      label: "By Order Line And Shipment"
    }
  ];
  private readonly _orderService: OrderService;
  private readonly _exportToExcelService: ExportToExcelService;

  constructor(orderService: OrderService, exportToExcelService: ExportToExcelService, accountStore: AccountStore, userRightsStore: UserRightsStore, orderSearchUiStore: OrderSearchUiStore) {
    this._exportToExcelService = exportToExcelService;
    this._orderService = orderService;
    this.store = orderSearchUiStore;
    this.orders$ = orderService.orderStore
                               .observe("orderList")
                               .pipe(map(o => o));
    this.canStartNewExcelReport$ = exportToExcelService.observe("canStartNewExport");
    this.displayListHeader$ = this.orders$.pipe(map(o => o && o.any()))
    this.exportToExcelModeAccessor = accountStore.getStoreAccessor("orderExcelExportMode");
    this.filterCount$ = this._orderService.orderStore.observe("filterCount");
    this.hasExportToExcelRights$ = userRightsStore.observe("exportToExcel");
    this.isLoadingMore$ = orderService.orderStore.observe("isLoadingMore")
    this.isSearching$ = orderService.orderStore.observe("isSearching");
    this.noResultsFound$ = this.orders$.pipe(map(o => o && !o.any()))
    this.showLoadMoreButton$ = orderService.orderStore.observe("showLoadMoreButton");
    this.showMobileSearchHint$ = this.store.observe("showMobileSearchHint");
    this.mobileSearchHint$ = this.store
                                 .observe("referenceType")
                                 .pipe(map(this.getMobileSearchHint));
    this.searchPresets$ = accountStore.observe("orderSearchPresets");
  }

  getMobileSearchHint = (searchReference: OrderSearchReferenceType): string => {
    const referenceDisplayName = this.referenceTypeSearchItems
                                     .firstOrDefault(r => r.value === searchReference)
      .label;
    if (referenceDisplayName && searchReference !== OrderSearchReferenceType.Any)
      return "Search by " + referenceDisplayName;
    return "Search Orders"
  };

  loadMore(): void {
    void this._orderService.loadMore();
  }

  startNewExcelReport(): void {
    void this._exportToExcelService.startNewOrderReport();
  }

  toggleFiltersOpenState(): void {
    this.store.negate("filtersOpen")
  }

  startSearch(): void {
    const searchObject = this.store.get();
    delete searchObject.filtersOpen;
    this._orderService.startSearch(searchObject)
  }

}
