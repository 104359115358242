import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {UserRightsStore} from "@stores";
import {Observable} from "rxjs";

@Injectable({
              providedIn: 'root'
            })
export class HasBookingRights implements CanActivate {
  private readonly _userRightsStore: UserRightsStore;

  constructor(userRightsStore: UserRightsStore) {
    this._userRightsStore = userRightsStore;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._userRightsStore.observe("bookings");
  }

}
