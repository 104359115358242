import {Component, Input} from '@angular/core';
import {EntityInfo, EntityPermissions, EntitySetInfo, EntitySetPermissions, UserType} from "@api";
import {AccountStore} from "@stores";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

export type EntityRights = { shipments: boolean, orders: boolean, bookings: boolean, invoiceDocuments: boolean, padding: number, name: string };

export type EntityRight = { entityName: string, hasRight: boolean, padding: number };

@Component({
             selector: 'rt-user-entity-permission-view',
             templateUrl: './user-entity-permission-view.component.html',
             styleUrls: ['./user-entity-permission-view.component.scss']
           })
export class UserEntityPermissionViewComponent {

  entitySetPermissions: EntitySetPermissions[];
  readonly loggedInAsSuperUser$: Observable<boolean>;

  constructor(accountStore: AccountStore) {
    this.loggedInAsSuperUser$ = accountStore.observe("type").pipe(map(t => t === UserType.SuperUser));
  }

  @Input()
  set entitySets(value: EntitySetInfo[] | EntitySetPermissions[]) {
    this.entitySetPermissions =
      this._isEntityPermissions(value)
      ? value
      : this._convertToEntitySetPermissions(value)
  }

  private static getEntityRights(entityInfo: EntityPermissions, padding: number): EntityRights {
    return Object.assign({padding}, entityInfo as Required<EntityPermissions>);
  }

  getEntitiesWithPadding = (entityInfo: EntityPermissions, padding = 35): EntityRights[] => {
    const output: EntityRights[] = [
      UserEntityPermissionViewComponent.getEntityRights(entityInfo, padding)
    ];
    const subSiteRights = entityInfo.childEntities
                                  .map(e => this.getEntitiesWithPadding(e, padding + 35))
                                  .flat();
    output.pushRange(subSiteRights)
    return output;
  };

  getRightClass(right: boolean): "mat-icon-done" | "mat-icon-remove" {
    return right ? "mat-icon-done" : "mat-icon-remove";
  }

  getRightOfEntities = (entityInfo: EntityPermissions, right: KeysOfType<EntityPermissions, boolean>, padding = 20): EntityRight[] => {
    const output: EntityRight[] = [{entityName: entityInfo.name, hasRight: entityInfo[right], padding}];
    const subSiteRights = entityInfo.childEntities
                                  .map(e => this.getRightOfEntities(e, right, padding + 25))
                                  .flat();
    output.pushRange(subSiteRights)
    return output;
  };

  private _convertToEntitySetPermissions(value: EntitySetInfo[]): EntitySetPermissions[] {
    return value
      .map(e => {
        return {
          documents: e.hasDocumentRights,
          exceptions: e.hasExceptionRights,
          exportToExcel: e.hasExportToExcelRights,
          messaging: e.hasMessagingRights,
          products: e.hasProductRights,
          name: e.name,
          bookings: e.hasBookingRights,
          id: e.id,
          orders: e.hasOrderRights,
          shipments: e.hasShipmentRights,
          invoiceDocuments: e.hasInvoiceRights,
          childEntities: e.subSites.map(e => this._convertToEntityPermissions(e)),
        }
      });
  }

  private _convertToEntityPermissions(entityInfo: EntityInfo): EntityPermissions {
    if (!entityInfo)
      return null;
    return {
      name: entityInfo.name,
      bookings: entityInfo.hasBookingRights,
      id: entityInfo.id,
      orders: entityInfo.hasOrderRights,
      shipments: entityInfo.hasShipmentRights,
      invoiceDocuments: entityInfo.hasInvoiceRights,
      childEntities: entityInfo.subSites.map(e => this._convertToEntityPermissions(e)),
    };
  }

  private _isEntityPermissions(value: EntitySetInfo[] | EntitySetPermissions[]): value is EntitySetPermissions[] {
    if (value?.any() !== true)
      return false;
    const firstItem = value[0];
    return Object.prototype.hasOwnProperty.call(firstItem, "documents") as boolean;
  }

}
