<div class="page-wrap">
  <main>
    <section class="padding" rtFormValidator [attr.disabled]="isSaving ? 'disabled' : null">
      <div class="search-bar button-container">
          <span class="title" style="min-width: 0">
          Settings
          </span>
      </div>
      <h2>Booking</h2>
      <div class="tables">
        <div data-role="repeater">
          <div class="line" *ngFor="let bookingEntry of bookingEntries; index as index; trackBy: trackBy ">
            <div>
              <label for="countrySelect{{index}}">Country</label>
              <select id="countrySelect{{index}}"
                      name="countrySelect{{index}}"
                      [attr.required]="bookingEntry.hasValue? 'required' : null"
                      ngSelect
                      [settings]="selectOptions"
                      [(ngModel)]="bookingEntry.countryCode"
                      (initialized)="initializeSelect($event, bookingEntry)"
                      (selectionChanged)="onCountrySelectionChanged($event, 'Booking');onChange(bookingEntry);onEditEnd(bookingEntry)">
                <!--                <option *ngIf="bookingEntry.hasValue"-->
                <!--                        value="{{bookingEntry.countryCode}}"-->
                <!--                        data-icon="spriteCountryBorder {{bookingEntry.countryCode.toLowerCase()}}"-->
                <!--                        selected>{{bookingEntry.countryCode}}</option>-->
              </select>
            </div>
            <div>
              <label for="fallbackMail{{index}}">Booking Fallback Email</label>
              <div class="input-group">
                <input
                  id="fallbackMail{{index}}"
                  name="fallbackMail{{index}}"
                  data-display-name="Mail Address"
                  type="text"
                  [(ngModel)]="bookingEntry.MailAddressWithoutDomain"
                  [attr.required]="bookingEntry.hasValue? 'required' : null"
                  (input)="onChange(bookingEntry)"
                  (blur)="onEditEnd(bookingEntry)"/>
                <span class="input-appendix" style="padding-left: 5px;">@rohlig.com</span>
              </div>
            </div>
            <span class="remove-button" *ngIf="!bookingEntry.isGhost">
                <span class="material-icons no-selector" (click)="removeEntry(bookingEntry)">delete</span>
              </span>
          </div>
        </div>
      </div>
      <h2>Invoice Dispute</h2>
      <div class="tables">
        <div data-role="repeater">
          <div class="line" *ngFor="let invoiceDisputeEntry of invoiceDisputeEntries; index as index; trackBy: trackBy ">
            <div>
              <label for="invoiceCountrySelect{{index}}">Country</label>
              <select id="invoiceCountrySelect{{index}}"
                      name="invoiceCountrySelect{{index}}"
                      [attr.required]="invoiceDisputeEntry.hasValue? 'required' : null"
                      ngSelect
                      [settings]="selectOptions"
                      [(ngModel)]="invoiceDisputeEntry.countryCode"
                      (initialized)="initializeSelect($event, invoiceDisputeEntry)"
                      (selectionChanged)="onCountrySelectionChanged($event, 'InvoiceDispute');onChange(invoiceDisputeEntry);onEditEnd(invoiceDisputeEntry)">
              </select>
            </div>
            <div>
              <label for="Mail{{index}}">Invoice Dispute Email</label>
              <div class="input-group">
                <input
                  id="Mail{{index}}"
                  name="Mail{{index}}"
                  data-display-name="Mail Address"
                  type="text"
                  [(ngModel)]="invoiceDisputeEntry.MailAddressWithoutDomain"
                  [attr.required]="invoiceDisputeEntry.hasValue? 'required' : null"
                  (input)="onChange(invoiceDisputeEntry)"
                  (blur)="onEditEnd(invoiceDisputeEntry)"/>
                <span class="input-appendix" style="padding-left: 5px;">@rohlig.com</span>
              </div>
            </div>
            <span class="remove-button" *ngIf="!invoiceDisputeEntry.isGhost">
                <span class="material-icons no-selector" (click)="removeEntry(invoiceDisputeEntry)">delete</span>
              </span>
          </div>
        </div>
      </div>
      <div class="tables">
        <div class="column" style="margin-top: 20px; padding-bottom: 20px;">
          <p class="buttons phone-auto-width">
            <button class="button" [rtLoadingAnimation]="isSaving" [loadingText]="'saving'" (click)="save()">Save</button>
          </p>
        </div>
      </div>
    </section>
  </main>
</div>
