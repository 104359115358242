import {Injectable} from "@angular/core";
import {CanActivate, UrlTree} from "@angular/router";
import {UserRightsStore} from "@stores";
import {Observable} from "rxjs";

@Injectable({
                providedIn: 'root'
            })
export class HasAdministrationRights implements CanActivate {
    private readonly _userRightsStore: UserRightsStore;

    constructor(userRightsStore: UserRightsStore) {
        this._userRightsStore = userRightsStore;
    }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._userRightsStore.observe("administration");
    }

}
