import {Directive, ElementRef, Output, EventEmitter, ApplicationRef, Input} from "@angular/core";
import {ContentComponent} from "@utils/Angular/ContentComponent";
import {Datetimepicker as DateTimePicker} from "../widgets/Datetimepicker";

@Directive({
    selector: '[ngDatetimepicker]',
    exportAs: "datetimepicker"
})
export class DatetimepickerDirective extends ContentComponent {

    datetimepicker: DateTimePicker;

    @Output()
    onDateChanged = new EventEmitter<Date>();

    @Input()
    datePickerMode: "none" | "absolute" | "relative" = "absolute";

    @Input()
    absoluteLabel: string;

    constructor(element: ElementRef, app: ApplicationRef) {
        super(element, app);
    }

    onInitialized(): void {
        //const $select = $((this.element.nativeElement as HTMLElement).children[0]);
        const $select = $(this.element);
        if ($select.data("datetimepicker"))
            this.datetimepicker = $select.data("datetimepicker");
        else
            this.datetimepicker = new DateTimePicker($select[0] as HTMLInputElement, {datePickerMode: this.datePickerMode, absoluteLabel: this.absoluteLabel});
        this.datetimepicker.onDateChanged.on((e) => this.onDateChanged.emit(e.data));
    }
}
