import {ControlDataEvent} from "../libs/ExtendableEvent";
import {EventEmitter} from "../utils/EventEmitter";
import {checkScrollMargin} from "../utils/Utils";
require("../utils/JqueryLoader");

export type repeaterEventData = { type: "ghost" | "item", $element: JQuery };

export class Repeater {
    readonly onItemAdded = new EventEmitter<ControlDataEvent<this, repeaterEventData>>("item-added");
    readonly onItemRemoved = new EventEmitter<ControlDataEvent<this, repeaterEventData>>("item-removed");
    protected $template: JQuery;

    constructor(public $element: JQuery) {
        this.$template = $element.find("[data-role=template]:first");
        this.$template.detach();
        $element.typedData("repeater", this);
        this.initialize();

    }

    addGhost() {
        if (this.$element.children().is(":not(.hasValue)"))
            return;
        const newGhost = this.$template.clone(true, true);
        newGhost
            .appendTo(this.$element)
            .addClass("ghost");
        this.onItemAdded.fire(new ControlDataEvent<this, repeaterEventData>(this, {type: "ghost", $element: newGhost}),
                              this.$element);
        checkScrollMargin();
    };

    addSeed() {
        const newSeed = this.$template.clone(true, true);
        newSeed.appendTo(this.$element);
        this.onItemAdded.fire(new ControlDataEvent<this, repeaterEventData>(this, {type: "item", $element: newSeed}),
                              this.$element)
        checkScrollMargin();
    };

    removeGhost() {
        let $ghost = this.$element.children(":last").filter(".ghost");
        $ghost.detach();
        let controlDataEvent = new ControlDataEvent<this, repeaterEventData>(this, {type: "ghost", $element: $ghost});
        this.onItemRemoved.fire(controlDataEvent, this.$element);
        $ghost.remove();
        checkScrollMargin();
    };

    initialize() {
        const seed = Math.min(1, this.$element.data("seed-size") || 1);
        let $prefilled = this.$element.children("[data-role=prefilled]")
                             .addClass("hasValue");
        if (this.$template.data("removeable")) {
            const $removeButton = $("<span/>")
                .attr({"class": "remove-button"})
                .append("<span class=\"material-icons no-selector\">delete</span>")
                .click((e) => {
                    let $removedItem = $(e.delegateTarget).parent();
                    $removedItem.detach();
                    let controlDataEvent = new ControlDataEvent<this, repeaterEventData>(this,
                                                                                 {type: "item", $element: $removedItem});
                    this.onItemRemoved.fire(controlDataEvent, this.$element);
                    $removedItem.remove();
                });

            if (!this.$template.is("div")) {
                this.$template = this.$template
                                     .removeAttr("data-role")
                                     .removeAttr("data-removeable")
                                     .wrap(
                                         "<div class='repeater-template-container' data-removeable='true' data-role='template'></div>")
                                     .parent()
                                     .append($removeButton);
                $prefilled = $prefilled
                    .removeAttr("data-role")
                    .removeAttr("data-removeable")
                    .removeClass("hasValue")
                    .wrap(
                        "<div class='repeater-prefilled-container hasValue' data-removeable='true' data-role='prefilled'></div>")
                    .parent();
            } else
                this.$template.append($removeButton);

            if (!this.$template.hasClass("hasValue")) {
                this.$template.addClass("ghost");
            }


            $prefilled.each(function (this: HTMLElement) {
                $removeButton.clone(true).appendTo(this);
            });
        }

        this.$template.add($prefilled)
            .on("change input", (e) => {
                const $this = $(e.delegateTarget);
                const hasValue = $this.is("input, textarea, select")
                                 ? $this.hasValue()
                                 : $this.hasAnyFieldWithValue(true);

                if (hasValue) {
                    $this.addClass("hasValue");
                    if (!$this.siblings(":not(.hasValue, .ghost)").length) {
                        $this.removeClass("ghost");
                        this.addGhost();
                    }
                } else {
                    $this.removeClass("hasValue");
                    if ($this.is(":last-child")) {
                        if ($this.parent().children().length > seed)
                            $this.addClass("ghost");
                    } else if ($this.hasClass("ghost"))
                        this.removeGhost();
                }
            })
            .on("focusin", function (this: HTMLElement) {
                $(this).addClass("focused")
            })
            .on("focusout",
                (e) => {
                    const $elem = $(e.delegateTarget);
                    if ($elem.is(".hasValue, .ghost"))
                        $elem.removeClass("focused");
                    else {
                        $elem.detach();
                        this.onItemRemoved.fire(new ControlDataEvent<this, repeaterEventData>(this,
                                                                                      {type: "ghost", $element: $elem}),
                                                this.$element);
                        $elem.remove();
                    }

                });

        this.$element.parents("form")
            .on("realsubmit",
                function (this: HTMLFormElement) {
                    $("[data-role=template]", this)
                        .add($prefilled)
                        .each(function () {
                            const $this = $(this);

                            if (!$this.hasClass("hasValue")) {
                                $this.find("input, select, textarea")
                                     .prop("disabled", true)
                                     .addClass("removeDisabledStyle");
                            }
                        });
                });


        for (let i = 0; i < (seed - $prefilled.length); i++)
            this.addSeed();


        this.addGhost();
    }

}

declare global {
    interface JQuery {
        repeater(): JQuery;
    }
}

jQuery.fn.repeater = function (this: JQuery) {
    return this.each((i, elem) => {
        const $elem = $(elem);
        if ($elem.hasData("repeater"))
            return;
        new Repeater($elem);
    })
};
