import {AdministrationViewData} from "./administration-view-data";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class SettingsViewData extends AdministrationViewData {

  get pageTitle(): string {
    return TitleBuilder.settings;
  }

  get url(): string {
    return UrlBuilder.settings;
  }

  get bodyClasses(): string[] {
    return ["page-settings"];
  }

}
