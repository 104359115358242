import {ISelectOptions, Select} from "./Select";
import {Alignment, Position} from "@utils/Enums";
import {Dropdown} from "./Dropdown";
import {UnwrapFunction} from "@utils/Utils";
import {closeAlert, openAlert} from "./Alert";
import {ISelectListItem} from "./SelectList";
import {ControlEvent} from "../libs/ExtendableEvent";
import {JsonBooking, JSONData} from "@api";
import {RoutingService} from "@app/services/routing.service";
import {AccountService, BookingViewData, TemplateViewData} from "@services";
import {IPopupSelectListOptions} from "@widgets/PopupSelectList";
import {BookingService} from "@services/booking.service";

require("../utils/JqueryLoader");

export interface BookingTemplateSelectItem extends JSONData {
    description: string;
    shared: boolean;
    data: JsonBooking;
}

export class BookingTemplateSelect extends Select<BookingTemplateSelectItem> {
    //language=html
    static defaultOptions: ISelectOptions<BookingTemplateSelectItem> = $.extend({}, Select.defaultSelectOptions as any, {
        "class": "booking-select double-button-droplist droplist-button",
        shrinkToVisible: !(document.body.classList.contains("ie") || document.body.classList.contains("edge")),
        focusSearchOnTouchOpen: false,
        search: false,
        fixAlignment: () => window.innerWidth <= 320,
        placeholderRenderer: () => "",
        alignment: Alignment.Stretch,
        allowGrowing: false,
        visualContainer: "button-group",
        toggleControl: "parent",
        noResultsPlaceholder: "No saved templates",
        allowNoSelection: true,
        closeOnSelect: true,
    } as IPopupSelectListOptions);
    // noinspection NsUnresolvedStyleClassReference
    private static menuTemplate =
        `<a class="edit"><i class="material-icons">mode_edit</i> Edit</a>
<a class="delete"><i class="material-icons">delete</i> Delete</a>`;
    private readonly $content: JQuery;
    private readonly $description: JQuery;
    private contextMenuOpened = false;
    private $createButton: JQuery;
    private closeContextMenu: () => void = () => {};
    private readonly _accountService = window.ngInjector.get(AccountService);

    constructor(selectElement: HTMLSelectElement,
                options: ISelectOptions = {}) {
        super(selectElement, $.extend(BookingTemplateSelect.defaultOptions, options));
        this.options.closeOnSelect = () => !this.isTouchMode;
        this.$content = $(`<div class="booking-template-select-content"></div>`)
            .insertBefore(this.$element.find(".footer"))
            .on("mouseenter", () => {this.mouseOverFooter = true})
            .on("mouseleave", () => {this.mouseOverFooter = false});
        this.$createButton =
          $(`<a href="${window.baseApplicationUrl.trim("/")}/booking/overview">Add Template</a>`)
            .click(e => {
              e.preventDefault();
              window.ngInjector.get(BookingService).setBookingMode("template");
              window.ngInjector.get(RoutingService).navigateTo(new TemplateViewData("Overview"));
              this.close();
            })
            .appendTo(this.$content)
            .wrap("<div></div>");
        this.$description = $("<div class='description'></div>")
            .appendTo(this.$content);
        this.$dummyItem
            .on("mouseenter", () => {this.mouseOverFooter = true})
            .on("mouseleave", () => {this.mouseOverFooter = false});
        this.$element.find("ul").on("scroll", () => this.closeContextMenu());
        this.onSelectionChanged.on(e => {
            if (!e.addedItems.length) {
                this.$description.empty();
                return;
            }
            if (this.isTouchMode) {
              let description = this.selectedItems[0].ajaxData.description;
              this.$description
                  .empty()
                  .append(description
                    ? "<span>" + description.replace(/</g, "&lt;").replace(/>/g, "&gt;") + "</span>"
                          : null);
            } else {
              this.close();
            }
        });
        this.onItemsRendered.on(() => this.decorateItems());
        this.unselectItems(true, true);
        this._accountService.accountStore
            .subscribe("bookingTemplates", t => {
              this.clearItems()
              this.processJsonData(t)
            })
    }

    private _mouseOverFooter = false;

    private get mouseOverFooter() {
        return this._mouseOverFooter;
    }

    private set mouseOverFooter(value) {
        this._mouseOverFooter = value;
        if (!value) {
            this.highlightItem(undefined);
        }
    }

    protected highlightItem(index?: number | HTMLElement): void {
        if (this.contextMenuOpened)
            return;
        super.highlightItem(index);
        if (this.isTouchMode)
            return;
        const highlightedItem = this.highlightedItem;
        window.setTimeout(() => {
                              if (this.highlightedItem !== highlightedItem) {
                                  return;
                              }
                              if (highlightedItem) {
                                  let description = highlightedItem.ajaxData.description;
                                  this.$description
                                      .empty()
                                      .append(description
                                        ? "<span>" + description.replace(/</g, "&lt;").replace(/>/g, "&gt;") + "</span>"
                                              : null);
                                  if (UnwrapFunction(this.options.shrinkToVisible)) {
                                      this.dropdown.popup.reposition();
                                      this.dropdown.popup.cutInvisibleBits();
                                  }
                                  this.dropdown.popup.$element.scrollintoview();
                              } else if (this.$description && !this.mouseOverFooter) {
                                  this.$description.html(null);
                                  if (UnwrapFunction(this.options.shrinkToVisible)) {
                                      this.dropdown.popup.reposition();
                                  }
                              }
                          },
                          index === undefined ? 200 : 0);
    }

    private decorateItems() {
        for (const item of (this.currentItems as ISelectListItem<BookingTemplateSelectItem>[])) {
            if (item.ajaxData.shared) {
                item.$element.children(".item-text")
                    .append("<span class='mat-icon-group' title='Shared'></span>");
            }
            const $menu = $(BookingTemplateSelect.menuTemplate);
            //language=html
            const $menuToggle = $(`<i class="material-icons icon-button menu-toggle" data-no-selector>more_vert</i>`)
                .appendTo(item.$element);
            const dropdown = new Dropdown($menu,
                                          $menuToggle,
                                          {
                                              autoPosition: false,
                                              "class": "context-menu",
                                              css: {"marginRight": "7px"},
                                              position: Position.Bottom,
                                              alignment: Alignment.End,
                                              fullscreenOnTouch: false
                                          });
            $menu.filter(".delete")
                .touchClick(() => {
                    dropdown.close();
                    // noinspection JSIgnoredPromiseFromCall
                    this.deleteTemplate(item);
                });
            $menu.filter(".edit")
                .touchClick(() => {
                    dropdown.close();
                    this.close();
                  window.ngInjector.get(BookingService).setBookingMode("template");
                  window.ngInjector.get(RoutingService).navigateTo(new TemplateViewData("Overview", item.ajaxData.value));
                });
            dropdown.onOpening.on(e =>  {
                if (!this.dropdown.interactionEnabled)
                    e.preventDefault();
            });
            dropdown.onOpened.on(
                () => {
                    this.contextMenuOpened = false;
                    this.highlightItem(item.$element[0]);
                    this.contextMenuOpened = true;
                    this.closeContextMenu = dropdown.close;
                });
            dropdown.onClosed.on(
                () => {
                    if (item.$element.hasClass("highlight")) {
                        this.contextMenuOpened = false;
                    }
                });
        }
    }


    protected onClosedHandler(event: ControlEvent<Dropdown>) {
        super.onClosedHandler(event);
        if (this.selectedItems.length) {
            const templateId = this.selectedItems[0].ajaxData.value;
            window.ngInjector.get(BookingService).setBookingMode("booking");
            window.ngInjector.get(RoutingService).navigateTo(new BookingViewData("Service", templateId));
        }
    }

    private async deleteTemplate(item: ISelectListItem<BookingTemplateSelectItem>) {
        let deleteHandler = async () => {
            $("button.close", $alert).click();
            this.dropdown.removeExternalElement($alert);
            try {
                let isDeleted = await $.ajax({
                                                 url: `${window.baseApplicationUrl}/WebMethod/DeleteBookingTemplate`,
                                                 method: "DELETE",
                                                 data: {templateId: item.ajaxData.value}
                                             });
              if (isDeleted) {
                const template = this._accountService.accountStore.get("bookingTemplates").find(b => b.data.templateInformation.templateID === item.ajaxData.value).data.templateInformation;
                let bookpreset: JsonBooking[] = [];
                this._accountService.accountStore.update(({ privateBookingPresets, currentEntitySet, availableEntitySets }) => {
                  if (template.private) {
                    privateBookingPresets.forEach(e => e.templateInformation.templateID === template.templateID ? null : bookpreset.push(e))
                    privateBookingPresets = bookpreset;
                  return { privateBookingPresets };
                  } else {
                    currentEntitySet.sharedBookingTemplates.forEach(e => e.templateInformation.templateID === template.templateID ? null : bookpreset.push(e));
                    availableEntitySets.firstOrDefault(e => e.id === currentEntitySet.id).sharedBookingTemplates = bookpreset;
                    currentEntitySet.sharedBookingTemplates = bookpreset;
                return { currentEntitySet, availableEntitySets };
              }
      });
                    this.removeItem(item);
                } else {
                    // noinspection ExceptionCaughtLocallyJS
                    throw "";
                }
            } catch (e) {
                openAlert({
                              type: "error",
                              content: "An error occured and the booking template could not be deleted. Please contact your Röhlig representative if this issues persists."
                          });
            }
        };
        const $alert =
           openAlert({
                                                 type: "error",
                                                 content: `Are you sure you want to delete "${item.contentString}"?`,
                                                 timeout: false,
                                                 buttons: [
                                                     $("<button class=\"button\">Delete</button>")
                                                         .on("click",
                                                             deleteHandler),
                                                     $("<button class=\"button\">Cancel</button>")
                                                         .on("click",
                                                             () => {
                                                                 $("button.close", $alert).click();
                                                             })
                                                 ]
                                             });
        this.dropdown.addExternalElement($alert);
        this.onClosed.oneTime(() => {
            closeAlert($alert);
            this.dropdown.removeExternalElement($alert);
        });
    }
}

declare global {
    interface JQuery {
        bookingSelect(options?: ISelectOptions): JQuery;

        bookingSelect(loadDataSettings: true): JQuery;
    }
}

jQuery.fn.bookingSelect = function (this: JQuery, options?: ISelectOptions | true) {
    return this.each((index, elem) => {
        const $elem = $(elem);
        if ($elem.data("address-select")) {
            return;
        }
        if (options === true) {
            options = $elem.getJsonFromAttribute<ISelectOptions>("data-select-settings") ||
                      $elem.getJsonFromAttribute<ISelectOptions>("data-settings");
        }
        // ReSharper disable once WrongExpressionStatement
        new BookingTemplateSelect(elem as HTMLSelectElement, options);
    });
};
