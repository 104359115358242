import {AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Inject, Input, OnDestroy} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Directive({
             selector: '[rtSticky]',
             exportAs: "sticky"
           })
export class StickyDirective implements AfterViewInit, OnDestroy {
  @Input()
  /**
   * Set to true if the default position of the element is static to avoid wiggeling while scrolling
   */
  static = false;
  @Input()
  marginTop = 0;
  private readonly _elementRef: ElementRef;
  private readonly _document: Document;
  private readonly _changeDetectorRef: ChangeDetectorRef;

  constructor(elementRef: ElementRef, @Inject(DOCUMENT) document: Document, changeDetectorRef: ChangeDetectorRef) {
    this._changeDetectorRef = changeDetectorRef;
    this._document = document;
    this._elementRef = elementRef;
  }

  set top(value: number | null) {
    this._element.style.top = value != null ? `${value}px` : null;
  }

  set forcedPosition(value: string) {
    this._element.style.position = value;
  }

  private get _element() {
    return this._elementRef.nativeElement as HTMLElement;
  }

  ngAfterViewInit(): void {
    const parent = this._getParent()
    const parentPosition = parent.style.position;
    if (!parentPosition || parentPosition === "static")
      parent.style.position = "relative"
    window.setTimeout(this.checkStickyMode, 100);
    window.addEventListener("scroll", this.checkStickyMode, {passive: true})
    window.addEventListener("resize", this.checkStickyMode, {passive: true})

  }

  ngOnDestroy(): void {
    window.removeEventListener("scroll", this.checkStickyMode)
    window.removeEventListener("resize", this.checkStickyMode)
  }

  readonly checkStickyMode = () => {
    const parentTop = this._getParent().getBoundingClientRect().top;
    const top = Math.max(parentTop + this.marginTop, 0);
    this.top = top
    this.forcedPosition = this.static && top === 0 ? "fixed" : null;
  };

  private _getParent() {
    return this._element.parentElement;
  }
}
