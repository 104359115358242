// noinspection UnnecessaryLocalVariableJS

import {Modal} from "./modal";
import {SearchString, SearchStringMatch} from "../libs/SearchString";
import {MoveEvent, Options as SortableOptions, SortableEvent} from "sortablejs";
// @ts-ignore
import SimpleBar, {Options as SimpleBarOptions} from "simplebar";
import {AwaitCondition, scrollBarSize, sleep} from "@utils/Utils";
import {AccountStore, ShipmentStore} from "@stores";
import {TypedStoreAccessor} from "@stores/store-accessor";
import {CardLayoutItem, ContainerCardLayoutItem, ContainerListLayoutItem, LayoutMode, ListLayoutItem, ShipmentBoxDummy, ShipmentListViewMode} from "@api";
import {combineLatest, ReplaySubject} from "rxjs";
import {filter, map, takeUntil} from "rxjs/operators";
import {FormValidator} from "./Validate";
import {ShipmentListFields} from "@app/page-components/shipment/shipment-list/shipment-list-fields";
import {ShipmentBoxFields} from "@app/page-components/shipment/shipment-list/shipment-box-fields";
import {ShipmentOverviewItem} from "@app/page-components/shipment/shipment-list/shipment-overview-item";
import {ContainerOverviewItem} from "@app/page-components/shipment/shipment-list/container-overview-item";
import {ContainerBoxFields} from "@app/page-components/shipment/shipment-list/container-box-fields";
import {ContainerListFields} from "@app/page-components/shipment/shipment-list/container-list-fields";
import {FieldRenderer} from "@app/page-components/shipment/shipment-list/field-renderer.service";
import {ContainerStore} from "@stores/container-store.service";
import detect = require("../utils/detect");


const Sortable = require("sortablejs").Sortable;


interface BaseItem {
  currentSearchText?: string;
  contentElement?: HTMLSpanElement;
  title: string;
  liElement?: HTMLLIElement;
  searchString?: SearchString;
  disabled?: boolean;
  isDefault: boolean;
}

interface ListViewItem extends BaseItem {
  code: ListLayoutItem | ContainerListLayoutItem;
}

interface CardViewItem extends BaseItem {
  fullSize: boolean;
  code: CardLayoutItem | ContainerCardLayoutItem;
  contentMapper: Func2<ContainerOverviewItem | ShipmentOverviewItem, FieldRenderer, string>
}

export class ViewCustomizer {

  private static _fieldRenderer: FieldRenderer;
  private readonly _cardViewItems: EnumMapper<CardLayoutItem | ContainerCardLayoutItem, CardViewItem> = {} as any;
  private readonly _listViewItems: EnumMapper<ListLayoutItem | ContainerListLayoutItem, ListViewItem> = {} as any;
  private _modal: Modal;
  private layoutChanged = false;
  private readonly _initialized = new ReplaySubject<void>();
  readonly initialized$ = this._initialized.asObservable();
  private readonly _selectedCardViewItems: CardViewItem[] = [];
  private readonly _availableCardViewItems: CardViewItem[] = [];
  private readonly _selectedListViewItems: ListViewItem[] = [];
  private readonly _availableListViewItems: ListViewItem[] = [];
  private readonly _htmlElements = {
    cardLayout: {
      resetToDefault: null as HTMLAnchorElement,
      searchBox: null as HTMLInputElement,
      selectedList: null as HTMLUListElement,
      availableList: null as HTMLUListElement,
      container: null as HTMLDivElement,
      boxDummyContainer: null as HTMLElement

    },
    listLayout: {
      resetToDefault: null as HTMLAnchorElement,
      searchBox: null as HTMLInputElement,
      overflow: null as HTMLInputElement,
      selectedList: null as HTMLUListElement,
      availableList: null as HTMLUListElement,
      container: null as HTMLDivElement,
    },
    cancelButton: null as HTMLButtonElement,
    saveButton: null as HTMLButtonElement,
    container: null as HTMLDivElement
  }
  private readonly _currentLayoutStoreAccessor: TypedStoreAccessor<LayoutMode>;
  private _initialListItems: (ContainerListLayoutItem | ListLayoutItem)[] = [];
  private _initialCardItems: (ContainerCardLayoutItem | CardLayoutItem)[] = [];
  private readonly _listLayoutOverflowStoreAccessor: TypedStoreAccessor<boolean>;
  private readonly _accountStore: AccountStore;
  private _destroyed = new ReplaySubject<void>();
  private readonly _shipmentStore: ShipmentStore;
  private readonly _containerStore: ContainerStore;

  constructor(accountStore: AccountStore, fieldRenderer: FieldRenderer, shipmentStore: ShipmentStore, containerStore: ContainerStore) {
    this._accountStore = accountStore;
    this._shipmentStore = shipmentStore;
    this._containerStore = containerStore;
    this._listLayoutOverflowStoreAccessor = accountStore.getStoreAccessor("listLayoutOverflow");
    this._currentLayoutStoreAccessor = accountStore.getStoreAccessor("currentLayout");
    this._accountStore
        .observe(["containerListLayout", "shipmentListLayout", "shipmentListViewMode"])
        .pipe(
          takeUntil(this._destroyed),
          map(({containerListLayout, shipmentListLayout, shipmentListViewMode}) =>
                shipmentListViewMode === ShipmentListViewMode.Shipments
                ? shipmentListLayout
                : containerListLayout)
        )
        .subscribe((items) => this._initialListItems = items || []);
    this._accountStore
        .observe(["containerCardLayout", "shipmentCardLayout", "shipmentListViewMode"])
        .pipe(
          takeUntil(this._destroyed),
          map(({containerCardLayout, shipmentCardLayout, shipmentListViewMode}) =>
                shipmentListViewMode === ShipmentListViewMode.Shipments
                ? shipmentCardLayout
                : containerCardLayout)
        )
        .subscribe((items) => this._initialCardItems = items || []);
    ViewCustomizer._fieldRenderer = fieldRenderer;
    void this.initialize()
  }

  private static renderBoxItemPreview(item: CardViewItem, overviewItem: ShipmentOverviewItem | ContainerOverviewItem) {
    let {fullSize, title} = item;
    // if (item.code == CardLayoutItem.Container) {
    //   const containerCount = sampleContent.split(",").length;
    //   if (sampleContent.length > 1 && containerCount) {
    //     title += `<span class="label white">${containerCount}</span>`;
    //   }
    // }
    return `<p class="${fullSize ? "wide" : ""}"><strong>${title}</strong>${item.contentMapper(overviewItem, this._fieldRenderer)}</p>`;
  }

  private static getListItemHtml(listItem: BaseItem) {
    return `
        <span class="drag-handle"></span>
        <span class="content">${listItem.title}</span>
        <span class="select-item"></span>
        <span class="remove-item"></span>`;
  }

  private static runSearch(searchText: string, searchList: BaseItem[], availableList: HTMLSpanElement) {
    let hasResults = !searchText;
    for (const item of searchList) {
      if (searchText === item.currentSearchText) {
        const itemHasIsSearchHit = item.liElement.style.display === "block";
        hasResults = hasResults || itemHasIsSearchHit;
        continue;
      }
      item.currentSearchText = searchText;
      item.contentElement.innerText = item.title;
      if (!searchText) {
        item.liElement.style.display = "block";
        continue;
      }
      const searchResult = item.searchString.getMatches(searchText);
      if (searchResult.length) {
        item.liElement.style.display = "block";
        ViewCustomizer.markSearchResults(item, searchResult);
        hasResults = true;
      } else {
        item.liElement.style.display = "none";
      }
    }
    if (hasResults)
      availableList.classList.remove("no-results-found");
    else
      availableList.classList.add("no-results-found");
  }

  private static markSearchResults(item: BaseItem, searchResult: SearchStringMatch[]) {
    const markLength = "<mark></mark>".length;
    let html = item.contentElement.innerHTML;
    let markOffset = 0;
    for (const match of searchResult) {
      const startPosition = match.position + markOffset;
      const endPosition = startPosition + match.text.length;
      html = `${html.substring(0, startPosition)}<mark>${match.text}</mark>${html.substr(endPosition)}`;
      markOffset += markLength;
    }
    item.contentElement.innerHTML = html;
  }

  private static _isCardViewItem(item: CardViewItem | ListViewItem): item is CardViewItem {
    return "fullSize" in item;
  }

  public destroy() {
    this._destroyed.next();
    this._modal.destroy();
  }

  async open() {
    if (this._currentLayoutStoreAccessor.value === LayoutMode.Line)
      this.showListLayout();
    else
      this.showCardLayout();
    this._modal.$header.html(this.headerIcon());
    this._modal.openModal();
  }

  close(): boolean {
    if (this.layoutChanged) {
      let unsavedChanges: UnsavedChangesModal = new UnsavedChangesModal(() => {
        this._modal.closeModal(() => {
          this.reset()
        });
        return true;
      });
      unsavedChanges.openModal();
    } else {
      this._modal.closeModal();
      return true;
    }
    return false
    /*    this.reset()*/
  }

  private async initialize() {
    await this.initializeModal();
    this.initializeCardViewSorting();
    this.initializeListViewSorting();
    this._accountStore
        .observe("currentLayout")
        .pipe(takeUntil(this._destroyed))
        .subscribe(layout => this._changeLayout(layout));
    combineLatest(
      this._accountStore.observe("shipmentListViewMode"),
      this._shipmentStore.observe("viewCustomizerOverviewItem"),
      this._containerStore.observe("viewCustomizerOverviewItem"),
    )
      .pipe(
        takeUntil(this._destroyed),
        filter(([mode, shipmentOverviewItem, containerOverviewItem]) => mode === ShipmentListViewMode.Shipments && !!shipmentOverviewItem ||
                                                                        !!containerOverviewItem))
      .subscribe(([mode, shipmentOverviewItem, containerOverviewItem]) =>
                   this._viewModeChanged(mode, shipmentOverviewItem, containerOverviewItem))
    this._initialized.next();
  }

  private reset() {
    this.resetCardViewToInitial();
    this.resetListViewToInitial();
    this.layoutChanged = false;
  }

  private async createModalContentHtml() {
    return `
    <input id="rbtnCardLayout" name="view-switch" type="radio" checked="checked">
    <input id="rbtnListLayout" name="view-switch" type="radio">
    <div id="cardView">
        <div class="available-side">
            <header>
                <label>Available</label>
                <input type="text" id="txtCardViewSearch" placeholder="Search">
            </header>
            <ul id="cardViewAvailableList"></ul>
        </div>
        <div class="selected-side">
            <header>
                <a href="#" id="restoreCardView">Restore defaults</a>
                <label>Selected</label>
            </header>
   <div class="scroll-container" id="boxDummyContainer">
   </div>
            <span style="clear-both;"></span>
        </div>
    </div>
    <div id="listView" style="display:none;">
        <div class="available-side">
            <header>
                <label>Available</label>
                <input type="text" id="txtListViewSearch" placeholder="Search"/>
            </header>
            <ul id="listViewAvailableList"></ul>
        </div>
        <div class="selected-side">
            <header>
                <a href="#" id="restoreListView">Restore defaults</a>
                <label>Selected</label></header>
            <ul id="listViewSelectedList"></ul>
            <label for="chkListOverflowScroll">
                <input type="checkbox" id="chkListOverflowScroll">
                <span class="mat-icon-done"></span>
                Scroll columns horizontally on small devices
            </label>
        </div>
    </div>
        `;
  }

  private async initializeModal() {
    this._modal = new Modal(null, {
      buttons: [
        this.createSaveButton(),
        this.createCancelButton()
      ],
      headerElement: this.headerIcon(),
      contentElement: await this.createModalContent(),
      closeable: true
    }, true, () => {
      return this.close()
    });
  }

  private async initializeCardViewItems(overviewItem: ShipmentOverviewItem | ContainerOverviewItem) {
    const previousCardViewItems = this._availableCardViewItems.concat(this._selectedCardViewItems);
    for (const item of previousCardViewItems) {
      item.liElement.remove();
    }
    this._availableCardViewItems.clear();
    this._selectedCardViewItems.clear();
    for (const item of Object.values(this._cardViewItems)) {
      item.searchString = new SearchString(item.title, true);
      await this.renderListItem(item, overviewItem);
      this._availableCardViewItems.push(item);
      this._htmlElements.cardLayout.availableList.appendChild(item.liElement);
    }
  }

  private async initializeListViewItems(overviewItem: ShipmentOverviewItem | ContainerOverviewItem) {
    const previousListViewItems = this._availableListViewItems.concat(this._selectedListViewItems);
    for (const item of previousListViewItems) {
      item.liElement.remove();
    }
    this._availableListViewItems.clear();
    this._selectedListViewItems.clear();
    for (const item of Object.values(this._listViewItems)) {
      item.searchString = new SearchString(item.title, true);
      await this.renderListItem(item, overviewItem);
      this._availableListViewItems.push(item)
      this._htmlElements.listLayout.availableList.appendChild(item.liElement);
    }
  }

  private resetListViewToInitial() {
    this._htmlElements.listLayout.overflow.checked = this._listLayoutOverflowStoreAccessor.value;
    this._htmlElements.listLayout.searchBox.value = "";
    this.loadListViewItems(this._initialListItems);
  }

  private loadListViewItems(selectedItems: (ListLayoutItem | ContainerListLayoutItem)[]) {
    this.removeAllListItems();
    this.runListViewSearch();
    for (let key of selectedItems) {
      const item = this._listViewItems[key];
      if (!item)
        continue;
      this._selectedListViewItems.push(item);
      this._availableListViewItems.remove(item);
      this._htmlElements.listLayout.selectedList.appendChild(item.liElement);
    }
    this.runLayoutChangeCheck();
  }

  private resetCardViewToInitial() {
    this._htmlElements.cardLayout.searchBox.value = "";
    this.loadCardViewItems(this._initialCardItems);
  }

  private loadCardViewItems(selectedItems: (ContainerCardLayoutItem | CardLayoutItem)[]) {
    this.removeAllCardItems();
    this.runCardViewSearch();
    for (let key of selectedItems) {
      const item = this._cardViewItems[key];
      if (!item)
        continue;
      this._selectedCardViewItems.push(item);
      this._availableCardViewItems.remove(item);
      this._htmlElements.cardLayout.selectedList.appendChild(item.liElement);
    }
    this.runLayoutChangeCheck();
  }

  private removeAllCardItems() {
    this._availableCardViewItems.clear();
    this._selectedCardViewItems.clear();
    for (const item of Object.values(this._cardViewItems)) {
      item.liElement.remove();
      this._htmlElements.cardLayout.availableList.appendChild(item.liElement);
      this._availableCardViewItems.push(item);
    }
  }

  private removeAllListItems() {
    this._availableListViewItems.clear();
    this._selectedListViewItems.clear();
    for (const item of Object.values(this._listViewItems)) {
      item.liElement.remove();
      this._htmlElements.listLayout.availableList.appendChild(item.liElement);
      this._availableListViewItems.push(item);
    }
  }

  private createCancelButton() {
    const button = document.createElement("button");
    button.type = "button";
    button.innerText = "Cancel";
    button.classList.add("button");
    button.classList.add("secondary");
    this._htmlElements.cancelButton = button;
    return button;
  }

  private createSaveButton() {
    const button = document.createElement("button");
    button.type = "button";
    button.innerText = "Save";
    button.classList.add("button");
    this._htmlElements.saveButton = button;
    return button;
  }

  private headerIcon() {
    const viewMode = this._currentLayoutStoreAccessor.value;
    const content = viewMode === LayoutMode.Box ? '<h2><span class="mat-icon-card card-header"></span>Customize Card Layout</h2>' :
                    '<h2><span class="mat-icon-list list-header"></span>Customize List Layout</h2>';
    return content;
  }

  private async createModalContent() {
    const container = document.createElement("div");
    container.id = "view-customizer";
    if (detect.touch)
      container.classList.add("touch");
    if (detect.touchDevice)
      container.classList.add("touch-device");
    container.innerHTML = await this.createModalContentHtml();

    this._htmlElements.container = container;

    this._htmlElements.cardLayout.container = container.querySelector("#cardView");
    this._htmlElements.cardLayout.boxDummyContainer = container.querySelector("#boxDummyContainer");
    this._htmlElements.cardLayout.availableList = container.querySelector("#cardViewAvailableList");
    this._htmlElements.cardLayout.resetToDefault = container.querySelector("#restoreCardView");
    this._htmlElements.cardLayout.searchBox = container.querySelector("#txtCardViewSearch");
    const selectedList = document.createElement("div");
    selectedList.classList.add("informations")
    selectedList.id = "cardViewSelectedList";
    this._htmlElements.cardLayout.selectedList = selectedList as any;


    this._htmlElements.listLayout.container = container.querySelector("#listView");
    this._htmlElements.listLayout.availableList = container.querySelector("#listViewAvailableList");
    this._htmlElements.listLayout.resetToDefault = container.querySelector("#restoreListView");
    this._htmlElements.listLayout.searchBox = container.querySelector("#txtListViewSearch");
    this._htmlElements.listLayout.selectedList = container.querySelector("#listViewSelectedList");
    this._htmlElements.listLayout.overflow = container.querySelector("#chkListOverflowScroll");

    if (scrollBarSize > 0) {
      const options = {autoHide: true} as SimpleBarOptions;
      // noinspection ObjectAllocationIgnored
      new SimpleBar(this._htmlElements.cardLayout.container.querySelector(".selected-side .scroll-container"), options);
      this._htmlElements.cardLayout.availableList = new SimpleBar(this._htmlElements.cardLayout.availableList, options).getContentElement();
      this._htmlElements.listLayout.selectedList = new SimpleBar(this._htmlElements.listLayout.selectedList, options).getContentElement();
      this._htmlElements.listLayout.availableList = new SimpleBar(this._htmlElements.listLayout.availableList, options).getContentElement();
    }
    this.attachHandlers()

    return container;
  }

  private attachHandlers() {
    this._htmlElements.cardLayout.resetToDefault.addEventListener("click", e => {
      e.preventDefault();
      this.resetCardViewToDefaults()
    });
    this._htmlElements.cardLayout.searchBox.addEventListener("input", () => this.runCardViewSearch());

    this._htmlElements.listLayout.resetToDefault.addEventListener("click", e => {
      e.preventDefault();
      this.resetListViewToDefaults()
    });
    this._htmlElements.listLayout.searchBox.addEventListener("input", () => this.runListViewSearch());

    this._htmlElements.saveButton.addEventListener("click", () => this.saveLayout(), {passive: true});
    this._htmlElements.cancelButton.addEventListener("click", () => this.close(), {passive: true});
  }

  private renderListItem(listItem: CardViewItem | ListViewItem, overviewItem: ShipmentOverviewItem | ContainerOverviewItem) {
    const liElement = document.createElement("li");
    liElement.setAttribute("tabindex", "-1");
    liElement.innerHTML = ViewCustomizer.getListItemHtml(listItem);
    if (ViewCustomizer._isCardViewItem(listItem)) {
      this.cardViewItemModifications(liElement, listItem, overviewItem);
    }
    listItem.contentElement = liElement.querySelector(".content")
    liElement.setAttribute("data-code", listItem.code);
    this.attachItemHandlers(liElement, listItem);
    listItem.liElement = liElement;
  }

  private cardViewItemModifications(liElement: HTMLLIElement, cardItem: CardViewItem, overviewItem: ShipmentOverviewItem | ContainerOverviewItem) {
    const code = cardItem.code;
    const camelCaseCode = code[0].toLowerCase() + code.substring(1) as keyof ShipmentBoxDummy;
    liElement.innerHTML += ViewCustomizer.renderBoxItemPreview(cardItem, overviewItem);
    if (cardItem.fullSize)
      liElement.classList.add("wide");
  }

  private attachItemHandlers(liElement: HTMLLIElement, listItem: BaseItem) {
    liElement.querySelector(".select-item").addEventListener("click", () => {
      if (!listItem.disabled)
        this.selectItemHandler(listItem)
    }, {passive: true});
    liElement.querySelector(".remove-item").addEventListener("click", () => {
      if (!listItem.disabled)
        this.removeItemHandler(listItem)
    }, {passive: true});
    $(liElement).bind("tap", () => liElement.classList.add("focused"));
    liElement.addEventListener("touchstart",
                               e => {
                                 const children = Array.from(liElement.querySelectorAll("p, p *"));
                                 if (e.target !== liElement && !children.contains(e.target as HTMLElement))
                                   return;
                                 listItem.disabled = true;
                                 sleep(300).then(() => {
                                   listItem.disabled = false
                                 });
                               }, {passive: true}
    );
    liElement.addEventListener("blur", () => liElement.classList.remove("focused"), {passive: true})
  }

  private selectItemHandler(item: BaseItem) {
    if (this._availableCardViewItems.contains(item as CardViewItem))
      this.selectCardViewItem(item as CardViewItem);
    else if (this._availableListViewItems.contains(item as ListViewItem))
      this.selectListViewItem(item as ListViewItem)
  }

  private removeItemHandler(item: BaseItem) {
    if (this._selectedCardViewItems.contains(item as CardViewItem))
      this.removeCardViewItem(item as CardViewItem);
    else if (this._selectedListViewItems.contains(item as ListViewItem))
      this.removeListViewItem(item as ListViewItem)

  }

  private validateListView() {
    if (this._selectedListViewItems.length !== 0)
      return true;
    this._htmlElements.listLayout.selectedList.classList.add("invalid");
    this.showListLayout();
    return false;
  }

  private async saveLayout() {
    if (!this.validateListView())
      return;

    if (this._accountStore.get("shipmentListViewMode") === ShipmentListViewMode.Shipments)
      this._accountStore.update({
                                  listLayoutOverflow: this._htmlElements.listLayout.overflow.checked,
                                  shipmentListLayout: this._selectedListViewItems.map(i => i.code as ListLayoutItem),
                                  shipmentCardLayout: this._selectedCardViewItems.map(i => i.code as CardLayoutItem)
                                })
    else
      this._accountStore.update({
                                  listLayoutOverflow: this._htmlElements.listLayout.overflow.checked,
                                  containerListLayout: this._selectedListViewItems.map(i => i.code as ContainerListLayoutItem),
                                  containerCardLayout: this._selectedCardViewItems.map(i => i.code as ContainerCardLayoutItem)
                                })
    this.layoutChanged = false;
    this._modal.closeModal();
  }

  private showListLayout() {
    this._htmlElements.cardLayout.container.style.display = "none";
    this._htmlElements.listLayout.container.style.display = "";
  }

  private showCardLayout() {
    this._htmlElements.cardLayout.container.style.display = "";
    this._htmlElements.listLayout.container.style.display = "none";
  }

  private resetCardViewToDefaults() {
    const defaults = Object
      .values(this._cardViewItems)
      .filter(i => i.isDefault)
      .map(i => i.code);
    this._htmlElements.cardLayout.searchBox.value = "";
    this.loadCardViewItems(defaults);
  }

  private resetListViewToDefaults() {
    const defaults = Object
      .values(this._listViewItems)
      .filter(i => i.isDefault)
      .map(i => i.code);
    this._htmlElements.listLayout.searchBox.value = "";
    this._htmlElements.listLayout.overflow.checked = true;
    this.loadListViewItems(defaults);
  }

  private runLayoutChangeCheck() {
    const selectedCardItems = this._selectedCardViewItems.map(i => i.code);
    const selectedListItems = this._selectedListViewItems.map(i => i.code);
    const cardLayoutChanged = selectedCardItems.equals(this._initialCardItems);
    const listLayoutChanged = selectedListItems.equals(this._initialListItems);
    this.layoutChanged = cardLayoutChanged || listLayoutChanged;
  }

  private runCardViewSearch() {
    const searchText = this._htmlElements.cardLayout.searchBox.value;
    const searchList = this._availableCardViewItems;
    const availableList = this._htmlElements.cardLayout.availableList
    ViewCustomizer.runSearch(searchText, searchList, availableList);
  }

  private runListViewSearch() {
    const searchText = this._htmlElements.listLayout.searchBox.value;
    const searchList = this._availableListViewItems;
    const availableList = this._htmlElements.listLayout.availableList
    ViewCustomizer.runSearch(searchText, searchList, availableList);
  }

  private removeListViewItem(item: ListViewItem) {
    const availableItems = this._availableListViewItems;
    const selectedItems = this._selectedListViewItems;
    const availableList = this._htmlElements.listLayout.availableList;
    const searchFunction = () => this.runListViewSearch();
    this.removeItem(availableItems, item, selectedItems, availableList, searchFunction);
  }

  private removeCardViewItem(item: CardViewItem) {
    const availableItems = this._availableCardViewItems;
    const selectedItems = this._selectedCardViewItems;
    const availableList = this._htmlElements.cardLayout.availableList;
    const searchFunction = () => this.runCardViewSearch();
    this.removeItem(availableItems, item, selectedItems, availableList, searchFunction);
  }

  private removeItem<T extends BaseItem>(availableItems: T[],
                                         item: T,
                                         selectedItems: T[],
                                         availableList: HTMLUListElement,
                                         searchFunction: () => void) {
    if (availableItems.contains(item))
      return;
    const itemIsAlreadyInList = item.liElement.parentElement === availableList;
    if (!itemIsAlreadyInList) {
      let insertBeforeTarget = availableItems[0];
      while (insertBeforeTarget && insertBeforeTarget.title < item.title) {
        insertBeforeTarget = availableItems[availableItems.indexOf(insertBeforeTarget) + 1];
      }
      if (insertBeforeTarget)
        availableList.insertBefore(item.liElement, insertBeforeTarget.liElement);
      else
        availableList.appendChild(item.liElement);
    }
    availableItems.push(item);
    selectedItems.remove(item);
    searchFunction();
    this.layoutChanged = true;
  }

  private selectListViewItem(item: ListViewItem, index: number = -1) {
    const selectedItems = this._selectedListViewItems;
    const selectedList = this._htmlElements.listLayout.selectedList;
    const itemIsAlreadyInList = item.liElement.parentElement === selectedList;
    const availableItems = this._availableListViewItems;
    const searchFunction = () => this.runListViewSearch();
    this.selectItem(selectedItems, item, index, availableItems, itemIsAlreadyInList, selectedList, searchFunction);
  }

  private selectCardViewItem(item: CardViewItem, index: number = -1) {
    const selectedCardViewItems = this._selectedCardViewItems;
    const selectedList = this._htmlElements.cardLayout.selectedList;
    const itemIsAlreadyInList = item.liElement.parentElement === selectedList;
    const availableItems = this._availableCardViewItems;
    const searchFunction = () => this.runCardViewSearch();
    this.selectItem(selectedCardViewItems, item, index, availableItems, itemIsAlreadyInList, selectedList, searchFunction);
  }


  private selectItem<T extends BaseItem>(selectedCardViewItems: T[],
                                         item: T,
                                         index: number,
                                         availableItems: T[],
                                         itemIsAlreadyInList: boolean,
                                         selectedList: HTMLUListElement,
                                         searchFunction: () => void) {
    if (selectedCardViewItems.contains(item)) {
      if (index >= 0) {
        selectedCardViewItems.remove(item);
        selectedCardViewItems.splice(index, 0, item);
        this.layoutChanged = true;
      }
      return;
    }
    if (index >= 0)
      selectedCardViewItems.splice(index, 0, item)
    else
      selectedCardViewItems.push(item);
    availableItems.remove(item);
    if (!itemIsAlreadyInList)
      selectedList.appendChild(item.liElement);
    item.liElement.style.display = "block";
    searchFunction();
    this.layoutChanged = true;
  }

  private initializeCardViewSorting() {
    const {availableList, selectedList} = this._htmlElements.cardLayout;
    this.createSortables(selectedList,
                         availableList,
                         "card-view",
                         (e, i) => this.selectCardViewItem(e, i),
                         e => this.removeCardViewItem(e),
                         () => this.sortAvailableCardItems(),
                         this._cardViewItems);
  }

  private initializeListViewSorting() {
    const {availableList, selectedList} = this._htmlElements.listLayout;
    this.createSortables(selectedList,
                         availableList,
                         "list-view",
                         (e, i) => this.selectListViewItem(e, i),
                         e => this.removeListViewItem(e),
                         () => this.sortAvailableListItems(),
                         this._listViewItems);
  }

  private sortAvailableCardItems() {
    const items = this._availableCardViewItems.splice(0);
    for (const item of Object.values(this._cardViewItems)) {
      if (!items.contains(item))
        continue;
      this._availableCardViewItems.push(item);
      this._htmlElements.cardLayout.availableList.appendChild(item.liElement);
    }
  }

  private sortAvailableListItems() {
    const items = this._availableListViewItems.splice(0);
    for (const item of Object.values(this._listViewItems)) {
      if (!items.contains(item))
        continue;
      this._availableListViewItems.push(item);
      this._htmlElements.listLayout.availableList.appendChild(item.liElement);
    }
  }

  private createSortables<T extends BaseItem>(selectedList: HTMLUListElement,
                                              availableList: HTMLUListElement,
                                              view: "card-view" | "list-view",
                                              selectFunction: (e: T, index?: number) => void,
                                              removeFunction: (e: T) => void,
                                              sortFunction: () => void,
                                              itemMap: { [index: string]: T }) {
    const onMove = (e: MoveEvent) => {
      if (e.to === selectedList)
        selectFunction(itemMap[e.dragged.getAttribute("data-code")]);
      else if (e.to === availableList)
        removeFunction(itemMap[e.dragged.getAttribute("data-code")]);
      if (e.to === availableList && e.from === availableList)
        e.dragged.classList.add("no-drop");
      else
        e.dragged.classList.remove("no-drop");
    };
    const onEnd = (e: SortableEvent) => {
      e.item.classList.remove("no-drop");
      selectedList.classList.remove("dragging");
      sortFunction();
      if (e.to === selectedList)
        {
          let newIndex = e.newIndex;
          const firstItemInListIsContainerIcon = selectedList.children[0] instanceof HTMLParagraphElement;
          if (firstItemInListIsContainerIcon)
            newIndex--;
          selectFunction(itemMap[e.item.getAttribute("data-code")], newIndex);
        }
    };
    const onStart = () => selectedList.classList.add("dragging");
    Sortable.create(selectedList, {
      group: view,
      draggable: 'li',
      handle: '.drag-handle',
      ghostClass: 'dragging-item',
      delay: 100,
      delayOnTouchOnly: true,
      forceFallback: detect.browser.firefox,
      onStart,
      onEnd,
      onMove
    } as SortableOptions);
    Sortable.create(availableList, {
      group: view,
      draggable: 'li',
      handle: '.drag-handle',
      ghostClass: 'dragging-item',
      sort: false,
      delay: 100,
      delayOnTouchOnly: true,
      forceFallback: detect.browser.firefox,
      onStart,
      onEnd,
      onMove
    } as SortableOptions);
  }

  private _changeLayout(layout: LayoutMode) {
    if (layout === LayoutMode.Line)
      this.showListLayout()
    else
      this.showCardLayout()
  }

  private async _viewModeChanged(mode: ShipmentListViewMode, shipmentOverviewItem: ShipmentOverviewItem, containerOverviewItem: ContainerOverviewItem) {
    for (const key of Object.keys(this._listViewItems)) {
      // @ts-ignore
      delete this._listViewItems[key]
    }
    for (const key of Object.keys(this._cardViewItems)) {
      // @ts-ignore
      delete this._cardViewItems[key]
    }
    let overviewItem: ShipmentOverviewItem | ContainerOverviewItem;
    const boxDummyContainer = this._htmlElements.cardLayout.boxDummyContainer.querySelector(".simplebar-content");
    if (mode === ShipmentListViewMode.Shipments) {
      overviewItem = shipmentOverviewItem;
      this._loadShipmentItems(shipmentOverviewItem);
      await AwaitCondition(() => !!document.getElementById("viewCustomizerShipmentDummy"));
      boxDummyContainer.innerHTML = document.getElementById("viewCustomizerShipmentDummy").outerHTML;
    } else {
      overviewItem = containerOverviewItem
      this._loadContainerItems(containerOverviewItem);
      await AwaitCondition(() => !!document.getElementById("viewCustomizerContainerDummy"));
      boxDummyContainer.innerHTML = document.getElementById("viewCustomizerContainerDummy").outerHTML;
    }
    this.replaceBoxDummy(boxDummyContainer);
    await this.initializeCardViewItems(overviewItem);
    await this.initializeListViewItems(overviewItem);
    this.resetCardViewToInitial();
    this.resetListViewToInitial();
    this.layoutChanged = false;
  }

  private replaceBoxDummy(boxDummyContainer: Element) {
    boxDummyContainer.querySelector("a").removeAttribute("href");
    const cardListContainer = boxDummyContainer.querySelector(".informations");
    const selectedList = this._htmlElements.cardLayout.selectedList;
    cardListContainer.insertAdjacentElement("beforebegin", selectedList);
    const containerIcon = cardListContainer.querySelector("p.container-icon")
    selectedList.innerHTML = "";
    if (containerIcon)
      selectedList.appendChild(containerIcon);
    cardListContainer.remove();
  }

  private _loadShipmentItems(shipmentOverviewItem: ShipmentOverviewItem) {
    for (const key of (Object.keys(ShipmentListFields) as ListLayoutItem[])) {
      const fieldInfo = ShipmentListFields[key];
      this._listViewItems[key] = {
        code: key,
        title: fieldInfo.columnTitle,
        isDefault: fieldInfo.isDefault
      }
    }
    for (const key of (Object.keys(ShipmentBoxFields) as CardLayoutItem[])) {
      const fieldInfo = ShipmentBoxFields[key];
      this._cardViewItems[key] = {
        code: key,
        title: fieldInfo.title(shipmentOverviewItem),
        isDefault: fieldInfo.isDefault,
        fullSize: fieldInfo.class === "wide",
        contentMapper: fieldInfo.getContent
      }
    }
  }

  private _loadContainerItems(containerOverviewItem: ContainerOverviewItem) {
    for (const key of (Object.keys(ContainerListFields) as ContainerListLayoutItem[])) {
      const fieldInfo = ContainerListFields[key];
      this._listViewItems[key] = {
        code: key,
        title: fieldInfo.columnTitle,
        isDefault: fieldInfo.isDefault
      }
    }
    for (const key of (Object.keys(ContainerBoxFields) as ContainerCardLayoutItem[])) {
      const fieldInfo = ContainerBoxFields[key];
      this._cardViewItems[key] = {
        code: key,
        title: fieldInfo.title(containerOverviewItem),
        isDefault: fieldInfo.isDefault,
        fullSize: fieldInfo.class === "wide",
        contentMapper: fieldInfo.getContent
      }
    }
  }
}

export class UnsavedChangesModal extends Modal {
  readonly $modalContent = $(`
<form novalidate>
      <p>
        You have unsaved changes. Are you sure you want to exit without saving?
      </p>
    </form>`);
  readonly $form = this.$modalContent.filter("form")
  readonly $modalHeader = $(document.createElement("h2"))
  readonly $modalHeaderIcon = $(document.createElement("span"))
    .text("Unsaved Changes");
  readonly $abortCancelationButton = $(document.createElement("button"))
    .addClass("button secondary")
    .attr("type", "button")
    .text("KEEP EDITING")
    .css({
           "min-width": 150,
           textAlign: "center"
         })
    .click((e) => {
      this.customCloseAction = () => {
        return true;
      };
      this.destroy();
    });
  readonly $cancelButton = $(document.createElement("button"))
    .addClass("button")
    .text("DISCARD CHANGES")
    .css({
           "min-width": 150,
           textAlign: "center"
         })
  $leaveOrStay: boolean;
  private readonly _validator = new FormValidator(this.$form);

  constructor(action?: () => boolean) {
    super(null, {});
    const settings = {
      contentElement: this.$modalContent,
      buttons: [this.$cancelButton,
                this.$abortCancelationButton],
      headerElement: this.$modalHeader,
      headerIcon: this.$modalHeaderIcon
    };
    Object.assign(this, settings);
    this.initialize();
    this.$modalContainer.setAttribute("style", "z-index:12000");
    this.$modalWindow
        .css({width: 585})
        .attr("id", "unsavedChangesModal");
    this.$cancelButton.click(() => {
      void this.closeModal(action);
    })
  }

  openModal(): void {
    this.$form.show();
    this.$cancelButton
        .removeClass("loading")
        .removeAttr("disabled");
    this.$abortCancelationButton.removeAttr("disabled")
    this.$abortCancelationButton.show();
    this.$cancelButton.show();
    this._validator.resetValidation();
    this.$modalHeader.text("Unsaved Changes")
    super.openModal();
  }
}
