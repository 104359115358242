import {ErrorType} from "@Enums";
import {ViewData} from "./view-data";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class ErrorViewData extends ViewData {
  readonly errorType: ErrorType;

  constructor(errorType: ErrorType) {
    super();
    this.errorType = errorType;
  }

  hasNewInformationComparedTo(compare: this): boolean {
    const errorTypeHasChanged = this.errorType !== compare.errorType;
    return errorTypeHasChanged;
  }

  get pageTitle(): string {
    switch (this.errorType) {
      case "AccessDenied":
        return TitleBuilder.errors.accessDenied;
      case "Offline":
        return TitleBuilder.errors.offline;
      case "NotFound":
        return TitleBuilder.errors.notFound;
      case "UnexpectedError":
        return TitleBuilder.errors.unexpectedError;
    }
  }

  get url(): string {
    switch (this.errorType) {
      case "AccessDenied":
        return UrlBuilder.errors.accessDenied;
      case "Offline":
        return UrlBuilder.errors.offline;
      case "NotFound":
        return UrlBuilder.errors.notFound;
      case "UnexpectedError":
        return UrlBuilder.errors.unexpectedError;
    }
  }

  readonly bodyClasses = ["page-pre-login", "page-login", "error"];
}
