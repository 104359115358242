<select class="select-button"
        data-display-name="Telephone"
        ngSelect
        [settings]="selectSettings"
        [items]="countryItems"
        [ngModel]="_countryCode"
        (selectionChanged)="countrySelected($event.addedItems[0])"
        #select
        rtInputValidator
></select>
<input
  maxlength="45"
  type="text"
  inputmode="numeric"
  [value]="_phoneNumberWithoutAreaCode"
  ngNumericInput
  (input)="phoneNumberInput($event) //noinspection ValidateTypes"
  [settings]="numericControlOptions"
  autocomplete="tel-national"/><span class="input-prependix">{{areaCode}}</span>
