export abstract class ViewData {
  hasNewInformationComparedTo(compare: this) {
    return false;
  }

  isValid() {
    return true;
  }

  abstract readonly url: string;
  abstract readonly bodyClasses: string[];
  abstract readonly pageTitle: string;
}

