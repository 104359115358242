import {ShipmentViewData} from "@services/view-data";
import {normalizeId} from "@utils";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class ContainerDetailViewData extends ShipmentViewData {
    readonly containerId: string | null;
    readonly containerNumbers: string[];


    constructor(containerId: string) {
        super();
        this.containerId = normalizeId(containerId)
    }

    hasNewInformationComparedTo(compare: this): boolean {
        const containerIdChanged = this.containerId !== compare.containerId;
        return containerIdChanged;
    }

    get pageTitle(): string {
        // if (this.hasDefinedTitle())
        //   return TitleBuilder.shipment.shipmentDetail(this.shipmentTitle);
        return TitleBuilder.shipment.list;
    }

    get url(): string {
        return UrlBuilder.shipment.containerDetail(this.containerId!);
    }

    isValid(): boolean {
        return this.containerId !== null;
    }

    readonly bodyClasses = ["page-shipment"];
}
