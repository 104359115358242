import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";
import {ShipmentViewData} from "./shipment-view-data";

export class ShipmentListViewData extends ShipmentViewData {

  readonly redoSearch: boolean;
  readonly query: string;
  readonly autoOenSingleItem: boolean;


  constructor(options:{redoSearch?: boolean, query?:string, autoOpenSingleItem?: boolean} = {}) {
    super();
    this.query = options.query || null;
    this.redoSearch = options.redoSearch || false;
    this.autoOenSingleItem =
      typeof options.autoOpenSingleItem === "boolean"
        ? options.autoOpenSingleItem
        :!!options.query
  }

  get pageTitle(): string {
    return TitleBuilder.shipment.list;
  }

  get url(): string {
    if (this.query !== null)
      return UrlBuilder.shipment.listWithQuery(this.query)
    else
      return UrlBuilder.shipment.list(this.redoSearch);
  }

  readonly bodyClasses = ["page-shipment-list"];
}
