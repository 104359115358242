import {ApplicationRef, Component, ElementRef, QueryList, ViewChild, ViewChildren} from "@angular/core";
import {ViewComponent} from "./view.component";
import {Router} from "@angular/router";
import {List} from "linqts"
import {Modal} from "@widgets/modal";
import * as isotope from "isotope-layout";
import {openAlert} from "@widgets/Alert";
import {PrepareJsonDataToServer, renderAddress, sleep} from "@utils/Utils";
import {PartiesComponent} from "./parties.component";
import {Datetimepicker} from "@widgets/Datetimepicker";
import {SlideInOut} from "@utils/Angular/AnimationTemplates";
import {ConfigService} from "@booking/config.service";
import {ShipmentDetailViewData, ShipmentListViewData, TemplateViewData} from "@services";
import {BookingService} from "@services/booking.service";
import {
  DangerousGoodsMasterData, JobType,
  JsonAddress, JsonContainer,
  JsonPort,
  PortLocation,
  JsonTemplateInformation, TimelineLocation, TimeLineLocationType, TransportMode, TimelineLeg, TimelineLegType
} from "@api";
import {GetView, ViewArrangement} from "@booking/booking-views";
import {ContainerTypes, DocumentTypes, IJsonBooking, IJsonContainer, IJsonPackage} from './BookingModel';
import {takeUntil} from "rxjs/operators";
import {AdditionalInformationComponent} from "@booking/additional-information.component";
import {AccountStore} from "@stores";
import {RoutingService} from "@app/services/routing.service";
import {CountryService} from "@services/country.service";

import detect = require("@utils/detect");

require("isotope-packery");

@Component({
             selector: "submit",
             animations: [SlideInOut()],
             template: `
               <div class="boxes grid grid-padding">
                 <div class="grid-gutter"></div>
                 <rt-accordion class="box" title="SERVICE" (openStateChange)="boxOpenStateChange()">
                   <div class="padding">
                     <div class="timeline timeline-vertical">
                         <rt-vertical-timeline [shipmentLegs]="timelineLegs" [shipment]="null"></rt-vertical-timeline>
                     </div>
                     <table class="table-clean table-responsive">
                       <tbody>
                       <tr>
                         <th>Transport Mode</th>
                         <td>{{booking.transportMode === "SEA" ? "Sea" : "Air"}} <span
                           style="margin-left:15px"
                           class="label">{{booking.shipmentType}}</span></td>
                       </tr>
                       <tr *ngIf="booking.incoTerm">
                         <th>Incoterm</th>
                         <td>{{booking.incoTerm}}</td>
                       </tr>
                       </tbody>
                     </table>
                   </div>
                 </rt-accordion>
                 <rt-accordion class="box accordion-label" title="Parties" (openStateChange)="boxOpenStateChange()">
                   <div class="padding">
                     <div>
                       <p class="text-light">Who are you booking for?</p>
                       <address class="address-field" style="white-space: normal">
                         <p>{{booking.siteName}}</p>
                       </address>
                     </div>
                     <div class="address-container" *ngIf="booking.consignorAddress">
                       <p class="text-light">Shipper</p>
                       <address
                         innerHtml="{{renderAddress(booking.consignorAddress, false)}}"></address>
                     </div>
                     <div class="address-container"
                          *ngIf="!booking.consignorAddress && booking.consigneeAddress">
                     </div>
                     <div class="address-container" *ngIf="booking.consigneeAddress">
                       <p class="text-light">Consignee</p>
                       <address
                         innerHtml="{{renderAddress(booking.consigneeAddress, false)}}"></address>
                     </div>
                     <div class="clearfix"></div>
                     <div class="address-container"
                          *ngIf="hasPickupAddress()">
                       <p class="text-light">Pickup Location</p>
                       <address
                         innerHtml="{{renderAddress(booking.pickupAddress, false)}}"
                         *ngIf="template.pickupAddressType === 'customAddress'"
                       ></address>
                       <address
                         innerHtml="{{renderAddress(booking.consignorAddress, false)}}"
                         *ngIf="template.pickupAddressType === 'sameAddress'"
                       ></address>
                     </div>
                     <div class="address-container"
                          *ngIf="!hasPickupAddress() && hasDeliveryAddress()">
                     </div>
                     <div class="address-container"
                          *ngIf="hasDeliveryAddress()">
                       <p class="text-light">Delivery Location</p>
                       <address
                         innerHtml="{{renderAddress(booking.deliveryAddress, false)}}"
                         *ngIf="template.deliveryAddressType === 'customAddress'"
                       ></address>
                       <address
                         innerHtml="{{renderAddress(booking.consigneeAddress, false)}}"
                         *ngIf="template.deliveryAddressType === 'sameAddress'"
                       ></address>
                     </div>
                     <div class="clearfix"></div>
                     <ng-container
                       *ngIf="mode==='booking' || template.deliverAt || template.pickupAt">
                       <div class="address-container"
                            *ngIf="mode === 'booking' || template.pickupAt">
                         <p class="text-light"
                            *ngIf="template.pickupAddressType !== 'none'">
                           Ready at
                           Pickup
                           Location</p>
                         <p class="text-light"
                            *ngIf="template.pickupAddressType === 'none'">
                           Ready at
                           Port of
                           Loading</p>
                         <span *ngIf="mode === 'booking' && booking.pickupTimeSpecified">
                                                   {{booking.pickupAt | date:dateFormat}}&nbsp;&nbsp;&nbsp;{{booking.pickupAt | date:timeFormat}}
                                               </span>
                         <span *ngIf="mode === 'booking' && !booking.pickupTimeSpecified">
                                                   {{booking.pickupAt | date:dateFormat}}
                                               </span>
                         <span *ngIf="mode === 'template' && template.pickupAt">
                                                       {{relativeDateToString(template.pickupAt)}}
                                               </span>
                       </div>
                       <div class="address-container"
                            *ngIf="mode === 'template' && template.deliverAt && !(template.pickupAt)">
                       </div>
                       <div class="address-container"
                            *ngIf="mode === 'booking' && booking.deliverAt || mode=== 'template' && template.deliverAt">
                         <p class="text-light"
                            *ngIf="template.deliveryAddressType !== 'none'">
                           Requested
                           at
                           Delivery Location</p>
                         <p class="text-light"
                            *ngIf="template.deliveryAddressType === 'none'">
                           Requested
                           at Port
                           of Discharge</p>
                         <span *ngIf="mode === 'booking' && booking.deliveryTimeSpecified">
                                                   {{booking.deliverAt | date:dateFormat}}&nbsp;&nbsp;&nbsp;{{booking.deliverAt | date:timeFormat}}
                                               </span>
                         <span *ngIf="mode === 'booking' && !booking.deliveryTimeSpecified">
                                                   {{booking.deliverAt | date:dateFormat}}
                                               </span>
                         <span *ngIf="mode === 'template' && template.deliverAt">
                                                       {{relativeDateToString(template.deliverAt)}}
                                               </span>
                       </div>
                       <div class="clearfix"></div>
                     </ng-container>
                   </div>
                 </rt-accordion>
                 <rt-accordion class="box" title="GOODS" (openStateChange)="boxOpenStateChange()">
                   <div class="clip" id="accordion-3">
                     <div class="accordion-content"
                          *ngIf="!isFilled(booking.goodsDescription) && !isFilled(booking.marksAndNumbers) && !hasGoodsLines()">
                       <div class="padding">
                         <p class="content-empty">No information provided</p>
                       </div>
                     </div>
                     <div class="accordion-content"
                          *ngIf="isFilled(booking.goodsDescription) || isFilled(booking.marksAndNumbers) || hasGoodsLines()">
                       <div class="packing">
                         <header>
                           <table class="table-clean">
                             <tbody>
                             <tr *ngIf="booking.shipmentType === 'FCL'">
                               <th>Container Count</th>
                               <td>{{getContainers().length}}</td>
                             </tr>
                             <tr *ngIf="booking.shipmentType !== 'FCL'">
                               <th>Pieces</th>
                               <td>{{model.totalPackCount}}</td>
                             </tr>
                             <tr>
                               <th>Weight</th>
                               <td>
                                 {{(booking.shipmentType === "FCL" ? model.totalContainerWeight : model.totalPackWeight) | fixedNumber}}
                                 kg
                               </td>
                             </tr>
                             <tr>
                               <th>Volume</th>
                               <td>
                                 {{(booking.shipmentType === "FCL" ? model.totalContainerVolume : model.totalPackVolume) | fixedNumber}}
                                 m³
                               </td>
                             </tr>
                             <tr>
                               <th>Dangerous Goods</th>
                               <td *ngIf="hasDangerousGoodsLines()">
                                 <p *ngFor="let group of groupedDangerousGoodsItems"
                                    class="dangerous-goods-link popover-trigger"
                                    [rtPopover]="'dangerous-goods'"
                                    [attr.data-dangerous-goods]="jsonStringify(group.items)">UN {{group.key}}</p>
                               </td>
                               <td *ngIf="!hasDangerousGoodsLines()">–</td>
                             </tr>
                             <tr>
                               <th>Goods Description</th>
                               <td>{{isFilled(booking.goodsDescription) ? booking.goodsDescription : "–"}}</td>
                             </tr>
                             <tr>
                               <th>Marks &amp; Numbers</th>
                               <td>{{isFilled(booking.marksAndNumbers) ? booking.marksAndNumbers : "–"}}</td>
                             </tr>
                             </tbody>
                           </table>
                         </header>
                         <ng-container *ngIf="booking.shipmentType !== 'FCL'">
                           <div class="package" style="display: none;"></div>
                           <div class="package" *ngFor="let pack of getPackages()">
                             <span class="piece-count">{{pack.packCount}} {{pack.packCount === 1 ? "Piece" : "Pieces"}}</span>
                             <table class="table-clean table-narrow">
                               <tbody>
                               <tr *ngIf="pack.weight && pack.packCount">
                                 <th>Weight per Piece</th>
                                 <td>{{pack.weight | fixedNumber}} kg</td>
                               </tr>
                               <tr *ngIf="pack.volume > 0">
                                 <th>Volume per Piece</th>
                                 <td>{{pack.volume | fixedNumber}} m³</td>
                               </tr>
                               <tr *ngIf="pack.length && pack.width && pack.height">
                                 <th>Dimensions per Piece</th>
                                 <td>{{pack.length}} x {{pack.width}} x
                                   {{pack.height}} cm
                                 </td>
                               </tr>
                               <tr *ngIf="pack.dangerousGoodsSpecified && pack.dangerousGoods.length">
                                 <th>Dangerous Goods</th>
                                 <td>
                                   <p class="dangerous-goods-link"
                                      *ngFor="let data of pack.groupedDangerousGoodsItems"
                                      [attr.data-dangerous-goods]="jsonStringify(data.items)"
                                      [rtPopover]="'dangerous-goods'">
                                     UN {{data.key}}
                                   </p>
                                 </td>
                               </tr>
                               </tbody>
                             </table>
                           </div>
                         </ng-container>
                         <ng-container *ngIf="booking.shipmentType === 'FCL'">
                           <div class="container" style="display: none;"></div>
                           <div class="container" *ngFor="let container of getContainers()">
                             <header>
                               <p class="container-icon" [ngClass]="container | rtContainerIconClass"></p>
                               <div class="container-info">
                                 <h5>{{container.containerNumber}}</h5>
                                 <small class="text-light">{{container | pureFunction:getContainerDescription}}</small>
                               </div>
                             </header>
                             <table class="table-clean table-narrow">
                               <tbody>
                               <tr *ngIf="container.weight">
                                 <th>Weight</th>
                                 <td>{{container.weight | fixedNumber}} kg</td>
                               </tr>
                               <tr *ngIf="container.volume > 0">
                                 <th>Volume</th>
                                 <td>{{container.volume | fixedNumber}} m³</td>
                               </tr>
                               <tr *ngIf="container.dangerousGoodsSpecified && container.dangerousGoods.length">
                                 <th>Dangerous Goods</th>
                                 <td>
                                   <p class="dangerous-goods-link"
                                      *ngFor="let data of container.groupedDangerousGoodsItems"
                                      [attr.data-dangerous-goods]="jsonStringify(data.items)"
                                      [rtPopover]="'dangerous-goods'">
                                     UN {{data.key}}
                                   </p>
                                 </td>
                               </tr>
                               <tr *ngIf="container.temperatureSpecified">
                                 <th>Temperature</th>
                                 <td>{{container.temperature}} °C</td>
                               </tr>
                               </tbody>
                             </table>
                           </div>
                         </ng-container>
                       </div>
                     </div>
                   </div>
                 </rt-accordion>
                 <rt-accordion class="box" title="DOCUMENTS" (openStateChange)="boxOpenStateChange()" *ngIf="mode==='booking'">
                   <div class="accordion-content">
                     <div class="files" *ngIf="booking.documents.length">
                       <div *ngFor="let file of booking.documents" class="file"
                            style="word-wrap:break-word;">
                         <div class="file-name">{{file.fileName}}
                           <div class="file-size">{{file.fileSize}}</div>
                           <div class="file-type">{{documentTypes[file.documentType]}}</div>
                         </div>
                       </div>
                     </div>
                     <div class="padding" *ngIf="booking.documents.length === 0">
                       <p class="content-empty">No information provided</p>
                     </div>
                   </div>
                 </rt-accordion>
                 <rt-accordion class="box" title="NOTES" (openStateChange)="boxOpenStateChange()">
                   <div class="accordion-content">
                     <div class="padding">
                                      <span style="white-space: pre-wrap;"
                                            *ngIf="isFilled(booking.specialInstructions)">{{booking.specialInstructions}}</span>
                       <p *ngIf="isNullOrWhitespace(booking.specialInstructions)"
                          class="content-empty">No information provided</p>
                     </div>
                   </div>
                 </rt-accordion>
                 <rt-accordion class="box" title="CLIENT REFERENCES" (openStateChange)="boxOpenStateChange()">
                   <div class="padding">
                     <table *ngIf="getReferences().length" class="table-clean table-responsive">
                       <tbody>
                       <tr>
                         <th>{{booking.direction === 'in' ? 'Consignee' : 'Shipper'}}</th>
                         <td>{{getReferences()}}</td>
                       </tr>
                       </tbody>
                     </table>
                     <p *ngIf="getReferences().length === 0" class="content-empty">No information
                       provided</p>
                   </div>
                 </rt-accordion>
               </div>

               <p style="margin-top: 20px" *ngIf="mode==='booking'">
                 <label class="accept-terms checkbox-wrapper multiline">
                   <input type="checkbox"
                          id="terms"
                          name="terms"
                          [(ngModel)]="model.agreedToTerms">
                   <span class="mat-icon-done"></span>
                   <span>I agree to the <a target="_blank"
                                           href="https://www.rohlig.com/terms-and-conditions">terms and conditions</a>, confirm that my shipment has already been quoted and does not contain any {{getForbiddenGoodsList()}}

                           </span>
                 </label>
               </p>

               <div class="buttons phone-auto-width">
                 <button type="button" class="button secondary" [disabled]="transmissionState !== 'dataEntry'"
                         (click)="navigateBack()">Back
                 </button>
                 <button class="button submit-button disable-when-offline"
                         type="button" [disabled]="!model.agreedToTerms"
                         *ngIf="mode==='booking'"
                         (click)="submitBooking()">
                   Submit Booking
                 </button>
                 <button class="button submit-button disable-when-offline"
                         type="button"
                         *ngIf="mode==='template'"
                         [disabled]="transmissionState !== 'dataEntry'"
                         [class.loading]="transmissionState === 'transmittingTemplate'"
                         (click)="saveTemplate()"
                         style="width: 170px;">
                   Save Template
                 </button>
                 <button class="button secondary disable-when-offline"
                         [disabled]="transmissionState !== 'dataEntry'" (click)="cancel()" type="button">Cancel
                 </button>
               </div>

               <div class="hidden">
                 <div #completionModal>
                   <div *ngIf="transmissionState === 'transmittingBooking'">
                     <div class="spinner"></div>
                     <p class="text-center">
                       Your Booking is being submitted, please wait.
                     </p>
                   </div>
                   <div *ngIf="transmissionState === 'success'">
                     <div class="material-icons" style="color: #83a427;">done</div>
                     <p class="text-center">
                       Your Booking <strong>{{bookingNumber}}</strong> has been successfully submitted.<br/>
                       A confirmation has been sent to {{emailRecipient}}.
                     </p>
                   </div>
                   <div *ngIf="transmissionState === 'slowTransmission'">
                     <div class="spinner"></div>
                     <p class="text-center">
                       Submitting your booking takes longer than expected. You can keep using Real Time as
                       usual.<br/>
                       Your Booking will be processed in the background and a confirmation will be sent to
                       {{emailRecipient}} once this has been completed.
                     </p>
                   </div>
                   <div *ngIf="transmissionState === 'failure'">
                     <div class="material-icons" style="color: #C00000;">error_outline</div>
                     <p class="text-center">
                       There has been an error processing your Booking. Please try again in a moment.<br/>
                       If the issue persists, please contact your Röhlig representative.
                     </p>
                   </div>
                   <a
                     #shipmentsButton
                     class="button disable-when-offline"
                     [hidden]="transmissionState !== 'slowTransmission' && transmissionState !== 'success'"
                     [attr.href]="baseApplicationUrl + '/Shipment?search=true'"
                     (click)="$event.preventDefault(); navigateToShipments()">Back to Shipments</a>
                   <button
                     #submitAgainButton
                     class="button disable-when-offline"
                     [hidden]="transmissionState !== 'failure'"
                     type="button"
                     (click)="submitBooking()">Submit Again
                   </button>
                   <button
                     #cancelButton
                     class="button secondary disable-when-offline"
                     [hidden]="transmissionState !== 'failure'"
                     type="button"
                     (click)="cancel()">Cancel
                   </button>
                   <a
                     #gotoBookingButton
                     class="button secondary disable-when-offline"
                     [hidden]="transmissionState !== 'success'"
                     [attr.href]="baseApplicationUrl + '/Shipment/ShipmentDetail?JobID=' + bookingId + '&ObjectType=BKG'"
                     (click)="$event.preventDefault(); navigateToBooking(bookingId)">View
                     Booking</a>
                   <button
                     #saveAsTemplateButton
                     class="button secondary disable-when-offline"
                     [hidden]="transmissionState !== 'slowTransmission' && transmissionState !== 'success'"
                     (click)="convertToTemplate()">Save as Template
                   </button>

                   <h2 #modalTitle>
                     <span *ngIf="transmissionState==='transmittingBooking'">Submitting Booking</span>
                     <span *ngIf="transmissionState==='success'">Booking Submitted</span>
                     <span *ngIf="transmissionState==='failure'">Booking Failed</span>
                     <span *ngIf="transmissionState==='slowTransmission'">Submitting Booking</span>
                   </h2>
                 </div>
               </div>
             `
           })
export class SubmitComponent extends ViewComponent {
  readonly jsonStringify = JSON.stringify;


  readonly name = "SubmitComponent";
  isFilled = String.isFilled;
  isNullOrWhitespace = String.isNullOrWhitespace;

  relativeDateToString = Datetimepicker.RelativeDateToString;
  documentTypes = DocumentTypes;
  containerTypes = ContainerTypes;
  dateFormat = window.dateShortFormat.replace(/m/g, "M");
  timeFormat = window.timeShortFormat.replace(/t+/g, "a");
  isotopeLayout: Isotope;
  transmissionModal: Modal;

  transmissionState: "dataEntry" | "transmittingBooking" | "transmittingTemplate" | "success" | "failure" | "slowTransmission" = "dataEntry";
  bookingNumber: string;
  emailRecipient: string;
  bookingId: string;
  baseApplicationUrl = window.baseApplicationUrl;

  @ViewChildren("cancelButton,submitAgainButton,shipmentsButton,gotoBookingButton,saveAsTemplateButton")
  modalButtons: QueryList<ElementRef>;
  @ViewChild("completionModal")
  modal: ElementRef;
  @ViewChild("modalTitle")
  modalTitle: ElementRef;

  renderAddress = renderAddress;
  template: Partial<JsonTemplateInformation>;
  groupedDangerousGoodsItems: Grouping<DangerousGoodsMasterData, string>[] = [];
  timelineLegs: TimelineLeg[] = [];
  private readonly _accountStore: AccountStore;
  private readonly _bookingService: BookingService;
  private readonly _countryService: CountryService;

  constructor(element: ElementRef,
              app: ApplicationRef,
              router: Router,
              private configService: ConfigService,
              private readonly _routingService: RoutingService,
              accountStore: AccountStore,
              bookingService: BookingService,
              countryService: CountryService) {
    super(element, app, router, bookingService);
    this._bookingService = bookingService;
    this._countryService = countryService;
    this.template = this.model.booking.templateInformation;
    accountStore.observe("mail")
                .pipe(takeUntil(this.destroyed$))
                .subscribe((mail: string | null) => {
                  this.emailRecipient = mail
                });
    this._accountStore = accountStore;
  }

  get booking(): Partial<IJsonBooking> {
    return this.model.booking;
  }

  private static creatTimelineLeg(startLocation: TimelineLocation,
    endLocation: TimelineLocation,
    type: TimelineLegType,
    transportMode: TransportMode): TimelineLeg {
    return {
      startLocation,
      endLocation,
      type,
      transportMode
    } as TimelineLeg
  }

  getContainerDescription(container: IJsonContainer): string {
    let containerType = container.containerType;
    let isNonOperatingReefer = containerType?.contains("RE") && !container.temperatureSpecified;
    if (isNonOperatingReefer) {
      // noinspection UnnecessaryLocalVariableJS
      let nonOperatingReeferType = containerType.replace(/RE(HC)?/, "$1NOR") as keyof typeof ContainerTypes;
      containerType = nonOperatingReeferType;
    }
    return ContainerTypes[containerType];
  }

  hasPickupAddress() {
    return (this.template.pickupAddressType === "customAddress" && this.booking.pickupAddress) ||
           (this.template.pickupAddressType === "sameAddress" && this.booking.consignorAddress);
  }

  hasDeliveryAddress() {
    return (this.template.deliveryAddressType === "customAddress" && this.booking.deliveryAddress) ||
           (this.template.deliveryAddressType === "sameAddress" && this.booking.consigneeAddress);
  }

  getReferences() {
    return this.booking.references
               .filter(r => String.isFilled(r.referenceNumber))
               .map(r => r.referenceNumber)
               .join(", ");
  }

  getPackages() {
    return this.model.packages.filter(c => c !== this.model.dummyPackage);
  }

  getContainers() {
    return this.model.containers.filter(c => c !== this.model.dummyContainer);
  }

  hasGoodsLines() {
    return this.booking.shipmentType === "FCL" ? this.getContainers().length > 0 : this.getPackages().length >
                                                                                   0;
  }

  hasDangerousGoodsLines() {
    return this.booking.shipmentType === "FCL"
           ? !!this.getContainers().firstOrDefault(c => c.dangerousGoodsSpecified)
           : !!this.getPackages().firstOrDefault(c => c.dangerousGoodsSpecified);
  }

  hasTemperatureGoodsLines() {
    return this.booking.shipmentType === "FCL"
           ? !!this.getContainers().firstOrDefault(c => c.temperatureSpecified && c.containerType.contains("RE"))
           : false;
  }

  getForbiddenGoodsList() {
    const hasDangerousGoodsLines = this.hasDangerousGoodsLines();
    const hasTemperatureLines = this.hasTemperatureGoodsLines();
    let goods = "special equipment, high density";
    if (hasDangerousGoodsLines && hasTemperatureLines) {
      goods += " or out of gauge";
    } else {
      goods += ", out of gauge";
      if (hasTemperatureLines) {
        goods += " or hazardous";
      } else if (hasDangerousGoodsLines) {
        goods += " or temperature controlled";
      } else {
        goods += ", hazardous or temperature controlled";
      }
    }
    goods += " goods.";
    return goods;
  }

  replaceHTMLTags(text: string = ""): string {
    return text.replace(/>/g, "&gt;").replace(/</g, "&lt;");
  }

  createBookingSubmitObject(): Partial<IJsonBooking> {
    const booking = $.extend(true, {}, this.model.booking);
    booking.references =
      new List(booking.references).Where(r => !String.isNullOrEmpty(r.referenceNumber)).ToArray();
    //booking.references.forEach(r => r.referenceNumber = this.replaceHTMLTags(r?.referenceNumber).substr(0,50));
    //booking.goodsDescription = this.replaceHTMLTags(booking?.goodsDescription)
    //booking.marksAndNumbers = this.replaceHTMLTags(booking?.marksAndNumbers)
    //booking.specialInstructions = this.replaceHTMLTags(booking?.specialInstructions)
    //booking.templateInformation.description = this.replaceHTMLTags(booking?.templateInformation?.description)
    if (booking.shipmentType === "FCL") {
      booking.containers = this.model.containers
                               .filter(c => this.model.dummyContainer !== c)
                               .map(c => {
                                 c = Object.assign({}, c);
                                 delete c.dangerousGoodsItems;
                                 if (!c.dangerousGoodsSpecified) {
                                   delete c.dangerousGoods;
                                 }
                                 c.temperatureSpecified =
                                   c.temperatureSpecified && c.containerType.contains("RE");
                                 if (!c.temperatureSpecified) {
                                   delete c.temperature;
                                 }
                                 return c;
                               }) as IJsonContainer[];
    } else {
      booking.packages = this.model.packages
                             .filter(p => this.model.dummyPackage !== p)
                             .map(p => {
                               p = Object.assign({}, p);
                               delete p.dangerousGoodsItems;
                               if (!p.dangerousGoodsSpecified) {
                                 delete p.dangerousGoods;
                               }
                               p.packType = booking.transportMode === "AIR" ? "PCE" : "PLT";
                               return p;
                             }) as IJsonPackage[];
    }
    if (this.template.pickupAddressType === "sameAddress") {
      booking.pickupAddress = this.booking.consignorAddress;
      booking.portOfLoading = null;
    } else if (this.template.pickupAddressType === "customAddress") {
      booking.pickupAddress = this.booking.pickupAddress;
      booking.portOfLoading = null;
    } else {
      booking.pickupAddress = null;
    }
    if (this.template.deliveryAddressType === "sameAddress") {
      booking.deliveryAddress = this.booking.consigneeAddress;
      booking.portOfDischarge = null;
    } else if (this.template.deliveryAddressType === "customAddress") {
      booking.deliveryAddress = this.booking.deliveryAddress;
      booking.portOfDischarge = null;
    } else {
      booking.deliveryAddress = null;
    }
    return booking;
  }

  async saveTemplate() {
    //try {
    let viewDef = GetView(AdditionalInformationComponent);
    if (viewDef.viewObject && (viewDef.viewObject as AdditionalInformationComponent).isUploading) {
      openAlert({type: "info", content: "Please wait until all file uploads have been completed."});
      return;
    }
    for (const view of ViewArrangement) {
      if (!((view.validate && view.validate(this.model, this.mode)) ||
            (view.viewObject && await view.viewObject.validate()))) {
        await this.router.navigateByUrl(view.address);
        return;
      }
    }
    this.transmissionState = "transmittingTemplate";
    const template = this.createBookingSubmitObject();
    delete template.deliverAt;
    delete template.pickupAt;
    delete template.pickupTimeSpecified;
    delete template.deliveryTimeSpecified;
    delete template.bookingID;
    delete template.bookingNumber;
    delete template.documents;
    if (template.templateInformation.pickupAddressType !== "none")
      delete template.templateInformation.portOfLoadingIcon;
    if (template.templateInformation.deliveryAddressType !== "none")
      delete template.templateInformation.portOfDischargeIcon;
    template.templateInformation.deliveryAddressType = this.template.deliveryAddressType;
    template.templateInformation.pickupAddressType = this.template.pickupAddressType;
    template.templateInformation.parentPK = template.templateInformation.private ?
                                            template.sitePK :
      this._accountStore.get("currentEntitySet")?.id;
    const isSaved = await $.post({
                                   data: JSON.stringify(PrepareJsonDataToServer(template)),
                                   url: window.baseApplicationUrl + "/WebMethod/SaveBookingTemplate",
                                   contentType: "application/json"
                                 });
    if (isSaved) {
      template.templateInformation.templateID = isSaved;
      const templateExists = this._accountStore.get("bookingTemplates").some(b => b.data.templateInformation.templateID === isSaved);
      this._accountStore.update(({privateBookingPresets, currentEntitySet, availableEntitySets}) => {
        if (template.templateInformation.private) {
          templateExists
          ? privateBookingPresets[privateBookingPresets.findIndex(b => b.templateInformation.templateID === isSaved)] = template
          : privateBookingPresets.push(template);
          return {privateBookingPresets};
        } else {
          const availableSet = availableEntitySets.firstOrDefault(e => e.id === currentEntitySet.id).sharedBookingTemplates;
          const currentSet = currentEntitySet.sharedBookingTemplates;
          if (templateExists) {
            currentSet[currentSet.findIndex(b => b.templateInformation.templateID === isSaved)] = template
            availableSet[availableSet.findIndex(b => b.templateInformation.templateID === isSaved)] = template;
          } else {
            currentEntitySet.sharedBookingTemplates.push(template);
            availableEntitySets.firstOrDefault(e => e.id === currentEntitySet.id).sharedBookingTemplates.push(template);
          }
          return {currentEntitySet, availableEntitySets};
        }

      });

      this.model.enableNavigatingAway = true;
      this.model.isAborted = true;
      this._routingService.navigateTo(new ShipmentListViewData());
    } else {
      this.transmissionState = "dataEntry";
      openAlert({
                  type: "error",
                  content: "An error occured and the booking template could not be saved. Please contact your Röhlig representative if this issues persists."
                })
    }
    // } catch (ex) {
    //     alert(ex);
    // }
  }

  async convertToTemplate() {
    this.model.isAborted = false;
    this.model.enableNavigatingAway = false;
    this.model.booking.templateInformation.templateName = null;
    this.model.booking.templateInformation.templateID = null;
    this.model.booking.templateInformation = {
      private: true,
      pickupAddressType: this.template.pickupAddressType,
      deliveryAddressType: this.template.deliveryAddressType,
      portOfDischargeIcon: this.template.portOfDischargeIcon,
      portOfLoadingIcon: this.template.portOfLoadingIcon
    };
    this.template = this.booking.templateInformation;
    this.transmissionState = "dataEntry";
    this._bookingService.setBookingMode("template")
    this._routingService.navigateTo(new TemplateViewData("Overview"));
    this.transmissionModal.closeModal();
  }

  async submitBooking() {
    let viewDef = GetView(AdditionalInformationComponent);
    if (viewDef.viewObject && (viewDef.viewObject as AdditionalInformationComponent).isUploading) {
      openAlert({type: "info", content: "Please wait until all file uploads have been completed."});
      return;
    }
    for (const view of ViewArrangement.filter(v => v.visible)) {
      if (!((view.validate && view.validate(this.model, this.mode)) ||
            (view.viewObject && await view.viewObject.validate()))) {
        await this.router.navigateByUrl("/" + view.address);
        return;
      }
    }
    this.transmissionModal.openModal();
    this.transmissionState = "transmittingBooking";
    try {
      let bookingData = this.createBookingSubmitObject();
      if (bookingData.containers) {
        bookingData.containers.map(c => {
          delete c.dangerousGoodsSpecified;
          if (!c.temperatureSpecified && c.containerType.contains("RE")) {
            if (c.containerType === "20RE") {
              c.containerType = "20NOR";
            } else if (c.containerType === "40RE") {
              c.containerType = "40NOR";
            } else if (c.containerType === "40REHC") {
              c.containerType = "40HCNOR";
            }
          }
          delete c.temperatureSpecified;
        });
      }
      this.bookingNumber = await $.post({
                                          data: JSON.stringify(PrepareJsonDataToServer(bookingData)),
                                          url: window.baseApplicationUrl + "/WebMethod/AddBooking",
                                          contentType: "application/json"
                                        }).promise();
    } catch (e) {
      this.transmissionState = "failure";
      return;
    }
    this.model.isAborted = true;
    setTimeout(() => {
                 if (this.transmissionState === "transmittingBooking") {
                   this.transmissionState = "slowTransmission";
                   this.model.enableNavigatingAway = true;
                 }
               },
               10000);
    try {
      while (true) {
        await sleep(1000);
        this.bookingId = await $.get(window.baseApplicationUrl +
                                     "/WebMethod/IsBookingBooked?bookingNumber=" +
                                     this.bookingNumber);
        if (this.bookingId) {
          break;
        }
      }
      if (this.transmissionState as any !== "dataEntry") {
        this.transmissionState = "success";
      }
      this.model.enableNavigatingAway = true;
    } catch (e) {
    }
  }

  async entered() {
    await super.entered();
    $(window).trigger("resize");
    if (this.mode === "template") {
      const booking = this.booking;
      const template = booking.templateInformation;
      const partiesView = GetView(PartiesComponent).viewObject as PartiesComponent;
      if (!partiesView || !partiesView.relativeDatepickersInitialized) {
        template.pickupAt = Datetimepicker.ConvertToRelativeValue(booking.pickupAt);
        if (template.pickupAt && !booking.pickupTimeSpecified) {
          template.pickupAt.time = null;
        }
        template.deliverAt = Datetimepicker.ConvertToRelativeValue(booking.deliverAt);
        if (template.deliverAt && !booking.deliveryTimeSpecified) {
          template.deliverAt.time = null;
        }
      }
    }
    if (this.model.packages)
      for (const line of this.model.packages) {
        line.groupedDangerousGoodsItems = this.groupDgData(line);
      }
    if (this.model.containers) {
      let i = 1;
      for (const line of this.model.containers) {
        line.groupedDangerousGoodsItems = this.groupDgData(line);
        if (line !== this.model.dummyContainer)
          line.containerNumber = "Container" + i++;
      }
    }
    this.setDangerousGoods();
    this.creatTimelineLegs();
  }

  onInitialized(): void {
    super.onInitialized();
    const grid = $(".grid", this.element);
    // noinspection JSPotentiallyInvalidConstructorUsage
    const iso = new isotope(grid[0],
                            {
                              percentPosition: false,
                              itemSelector: ".box",
                              layoutMode: "packery",
                              transitionDuration: "0s",
                              packery: {
                                gutter: ".grid-gutter"
                              }
                            });
    this.isotopeLayout = iso;
    setTimeout(() => {
      iso.layout();
      iso.options({transitionDuration: "0.2s"});
    });

    $(window)
      .resize(() => {
        setTimeout(() => {
                     iso.layout();
                   },
                   100);
      });
    this.transmissionModal = new Modal(null,
                                       {
                                         buttons: this.modalButtons.toArray().map(ref => ref.nativeElement),
                                         contentElement: this.modal.nativeElement,
                                         headerElement: this.modalTitle.nativeElement,
                                         closeable: false
                                       });
    (this.modal.nativeElement as HTMLElement).parentElement.parentElement.id = "booking-transmission-modal";
    this.transmissionModal.$modalWindow.width(1000);
  }

  cancel() {
    if (this.transmissionState === "failure")
      this.model.enableNavigatingAway = true;
    super.cancel();
  }

  groupDgData(line: Partial<IJsonPackage> | Partial<IJsonContainer>) {
    if (!line || !line.dangerousGoodsItems)
      return [];
    return line.dangerousGoodsItems
               .map(i => i.data)
               .groupBy(i => i.unno)
               .orderBy(g => g.key);
  }

  setDangerousGoods() {
    const lines: (Partial<IJsonPackage> | Partial<IJsonContainer>)[] =
      this.booking.shipmentType === "FCL" ? this.getContainers() : this.getPackages();
    this.groupedDangerousGoodsItems =
      lines
        .filter(c => c.dangerousGoodsSpecified)
        .map(c => c.dangerousGoodsItems)
        .flat()
        .map(g => g.data)
        .distinct(g => [g.unno, g.subLabel1, g.subLabel2, g.class, g.pg].join("|"))
        .groupBy(g => g.unno)
        .orderBy(g => g.key);
  }

  createTimelineLocation(location: JsonAddress | JsonPort, type: TimeLineLocationType): Partial<TimelineLocation> {
    if (!location)
      return {
        type
      };
    if (this.isJsonPort(location)) {

      const { name, unloco } = location;
      const port: PortLocation = {};
      if (type === TimeLineLocationType.Airport) {
        var nameParts = name.split("(");
        port.name = nameParts[0];
        if (nameParts.length > 1) {
          port.iataCode = nameParts[1].split(")")[0];
        }
      } else if (type === TimeLineLocationType.Harbour) {
        port.unloco = unloco;
        port.name = name.split("(")[0];
      }

      port.country = this._countryService.getByIsoAlpha2(unloco.substr(0, 2));
      return {
        type,
        port
      }
    } else {
      const { name, stateName, city, postCode, address1, address2 } = location;
      const country = this._countryService.getByIsoAlpha2(location.country);
      return {
        type,
        port: { name: city, country },
        location: {
          name,
          city,
          postCode,
          addressLine1: address1,
          addressLine2: address2,
          state: stateName,
          country: { name: location.countryName, isoAlpha2: location.country }
        },
      }
    }
  }

  isJsonPort(port: any): port is JsonPort {
    return port.hasOwnProperty("unloco");
  }

  creatTimelineLegs() {
    this.timelineLegs = [];
    const booking = this.booking;
    let pickupLocation: Partial<TimelineLocation>,
      polLocation: Partial<TimelineLocation>,
      podLocation: Partial<TimelineLocation>,
      deliveryLocation: Partial<TimelineLocation>;
    let mainLegLocationType = booking.transportMode === "AIR" ? TimeLineLocationType.Airport : TimeLineLocationType.Harbour;
    if (booking.templateInformation.pickupAddressType === "none") {
      polLocation = this.createTimelineLocation(booking.portOfLoading, mainLegLocationType)
    } else {
      const address = booking.templateInformation.pickupAddressType === "sameAddress"
        ? booking.consignorAddress
        : booking.pickupAddress;
      pickupLocation = this.createTimelineLocation(address, TimeLineLocationType.Pickup)
      polLocation = this.createTimelineLocation(null, mainLegLocationType);
    }
    if (booking.templateInformation.deliveryAddressType === "none") {
      podLocation = this.createTimelineLocation(booking.portOfDischarge, mainLegLocationType)
    } else {
      const address = booking.templateInformation.deliveryAddressType === "sameAddress"
        ? booking.consigneeAddress
        : booking.deliveryAddress;
      deliveryLocation = this.createTimelineLocation(address, TimeLineLocationType.Delivery)
      podLocation = this.createTimelineLocation(null, mainLegLocationType);
    }
    if (pickupLocation)
      this.timelineLegs.push(SubmitComponent.creatTimelineLeg(pickupLocation,
        polLocation,
        TimelineLegType.Pickup,
        TransportMode.Road));
    this.timelineLegs.push(SubmitComponent.creatTimelineLeg(
      polLocation,
      podLocation,
      booking.transportMode === "AIR" ? TimelineLegType.Air : TimelineLegType.Sea,
      booking.transportMode === "AIR" ? TransportMode.Air : TransportMode.Sea
    ));

    if (deliveryLocation)
      this.timelineLegs.push(SubmitComponent.creatTimelineLeg(podLocation,
        deliveryLocation,
        TimelineLegType.Delivery,
        TransportMode.Road));
  }

  navigateToBooking(bookingId: string) {
    this._routingService.navigateTo(new ShipmentDetailViewData(bookingId, JobType.Booking));
  }

  navigateToShipments() {
    this._routingService.navigateTo(new ShipmentListViewData({redoSearch: true}));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (!this.transmissionModal.isOpen)
      return;
    if (detect.browser.ie) {
      setTimeout(() => {
        this.transmissionModal.closeModal();
      }, 1);
    } else {
      this.transmissionModal.closeModal();
    }
  }

  boxOpenStateChange() {
    this.isotopeLayout.layout();
  }
}
