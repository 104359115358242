import { TitleBuilder } from "@utils/builders/title-builder";
import { UrlBuilder } from "@utils/builders/url-builder";
import { PreLoginViewData } from "@services/view-data";

export class RequestAccessViewData extends PreLoginViewData {

  get pageTitle(): string {
    return TitleBuilder.account.requestAccess;
  }

  get url(): string {
    return UrlBuilder.account.requestAccess;
  }

  readonly bodyClasses = ["page-pre-login", "page-request-account"];
}
