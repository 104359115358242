'use strict';
import settings = require("./settings");

function translate(key: keyof typeof settings | keyof ITranslation, variables:(number | boolean | string | Date)[]) {
    let translation:string;
    const language = settings.LANGUAGE;

    if (settings.hasOwnProperty(key))
        translation = settings[key];
    else
        translation = settings.TRANSLATIONS[language][key];

    return translation.format(...variables);
}

export = translate;
