import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {UserRightsStore} from "@stores";
import {TypedStoreAccessor} from "@stores/store-accessor";
import {EntitySearchUiStore} from "@app/page-components/Entities/entity-list/entity-search-ui-store.service";
import {EntityService} from "@services/entity.service";
import {EntitySearchResult} from "@api";
import {map} from "rxjs/operators";
import {trackById} from "@app/utils/track-by-id";

@Component({
             selector: 'rt-entity-list',
             templateUrl: './entity-list.component.html',
             styleUrls: ['./entity-list.component.scss']
           })
export class EntityListComponent {
  readonly userCanCreateEntitySets$: Observable<boolean>;
  readonly searchStringStoreAccessor: TypedStoreAccessor<string>
  readonly searchResults$: Observable<EntitySearchResult[]>;
  readonly moreResultsAvailable$: Observable<boolean>;
  readonly hasNoSearchResults$: Observable<boolean>;
  readonly trackById = trackById;

  isSearching = false;
  isLoadingMore = false;
  skeletonItemCount = 24;
  private readonly _userRightsStore: UserRightsStore;
  private readonly _entityService: EntityService;
  private readonly _entitySearchUiStore: EntitySearchUiStore;

  constructor(entityService: EntityService, userRightsStore: UserRightsStore, entitySearchUiStore: EntitySearchUiStore) {
    this._entitySearchUiStore = entitySearchUiStore;
    this._entityService = entityService;
    this._userRightsStore = userRightsStore;
    this.searchStringStoreAccessor = entitySearchUiStore.getStoreAccessor("search");
    this.userCanCreateEntitySets$ = userRightsStore.observe("canCreateSites");
    const entityStore = entityService.entityStore;
    this.searchResults$ = entityStore.observe("displayedSearchResults")
    this.moreResultsAvailable$ = entityStore.observe("moreResultsAvailable")
    this.hasNoSearchResults$ = entityStore
      .observe("entityList")
      .pipe(map(e => e?.any() === false))
  }

  loadMore(): void {
    this.isLoadingMore = true;
    this._entityService.loadMore();
    this.isLoadingMore = false;
  }

  async search(): Promise<void> {
    this.isSearching = true;
    const searchString = this._entitySearchUiStore.get("search") || "";
    await this._entityService.startSearch(searchString);
    this.isSearching = false;
  }
}



