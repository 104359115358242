import {Store, StoreState} from "@stores/store";
import {Observable, Subscription} from "rxjs";

// eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/no-explicit-any
export type StoreContainer<T extends StoreState, TP extends StoreState> = { readonly store: Store<T, TP> }

export interface TypedStoreAccessor<T> {
  readonly observe: Observable<T | null>
  readonly value: T | null;

  subscribe(next: Action1<T>): Subscription;

  hasValue(): boolean;

  set(value: T): void;
}

export class StoreAccessor<T extends StoreState, TP extends StoreState, K extends keyof T> implements TypedStoreAccessor<T[K]> {


  readonly key: K;
  private readonly _storeAccessor: () => Store<T, TP>;

  constructor(store: Store<T, TP>, key: K);

  constructor(storeContainer: StoreContainer<T, TP>, key: K);

  constructor(storeAccessor: Func<Store<T, TP>>, key: K);

  constructor(container: Store<T, TP> | StoreContainer<T, TP> | Func<Store<T, TP>>, key: K) {
    this.key = key;
    if (typeof container === "function")
      this._storeAccessor = container;
    else if (container instanceof Store)
      this._storeAccessor = () => container
    else
      this._storeAccessor = () => container.store;
  }

  private _observe: Observable<T[K]>;

  get observe(): Observable<T[K]> {
    if (!this._observe)
      this._observe = this.store.observe(this.key);
    return this._observe
  }

  get value(): T[K] {
    return this.store.get(this.key);
  }

  private get store() {
    return this._storeAccessor();
  }

  subscribe(next: Action1<T[K]>): Subscription {
    return this.observe.subscribe(next);
  }

  set(value: T[K]): void {
    this.store.update(this.key, value);
  }

  hasValue(): boolean {
    return this.store.hasValue(this.key);
  }

}

