import {EventEmitter} from "../utils/EventEmitter";
import {ControlDataEvent, ControlEvent, RenderedEvent} from "../libs/ExtendableEvent";
import * as Utils from "../utils/Utils";
import {UnwrapContainerFunction, UnwrapFunction} from "../utils/Utils";
import {openAlert} from "./Alert";
import {ValidationField} from "./Validate";

require("../utils/JqueryLoader");

type overflowEventData = { oldValue: number, newValue: number, type: "overflow" | "underflow" };

export class NumericInput implements IControl<HTMLInputElement> {
    readonly onRendered = new EventEmitter<RenderedEvent<this, HTMLInputElement>>("rendered",
                                                                                  () => this.eventElements());
    readonly onInit = new EventEmitter<ControlEvent<this, Event>>("init", () => this.eventElements());
    readonly onRendering = new EventEmitter<ControlEvent<this, Event>>("rendering", () => this.eventElements());
    readonly onValueChanged =
        new EventEmitter<ControlDataEvent<this, valueChangedObject<number>, Event>>("value-changed",
                                                                                    this.eventElements);
    readonly onOverflowed =
        new EventEmitter<ControlDataEvent<this, overflowEventData, Event>>("overflowed", () => this.eventElements());
    readonly options: INumericInputOptions;
    readonly $input: JQuery/*<HTMLInputElement>*/;
    readonly input: HTMLInputElement;
    readonly $element: JQuery/*<HTMLInputElement | HTMLDivElement>*/;
    readonly $increaseControl: JQuery/*<HTMLButtonElement | HTMLSpanElement>*/;
    readonly $decreaseControl: JQuery/*<HTMLButtonElement | HTMLSpanElement>*/;
    readonly initialized: boolean = false;
    protected defaultOptions: INumericInputOptions =
        {
            decimalPrecision: 0,
            enableScrolling: true,
            enableGoingAround: true,
            multivals: () => [600, {interval: 200, steps: 10}, {interval: 100, steps: Infinity}],
            step: 1
        }
    protected increaseCallback = this.createChangeCallback(n => n + Utils.UnwrapFunction(this.options.step));
    protected decreaseCallback = this.createChangeCallback(n => n - Utils.UnwrapFunction(this.options.step));
    private readonly validationField: ValidationField;
    constructor(input: HTMLInputElement | JQuery, options?: INumericInputOptions, enableAttributeOverride = false) {
        this.input = input instanceof HTMLInputElement ? input : input[0] as HTMLInputElement;
        this.$input = $(input).attr("inputmode", "numeric");
        this.validationField = ValidationField.getValidationField(this.input);
        this.$input.attr("type", "text");
        if (enableAttributeOverride || this.$input.booleanData("enable-attribute-override")) {
            this.options =
                Utils.ExtendOptions(this.defaultOptions, options, NumericInput.fillOptionsFromAttributes(this.$input));
        } else {
            this.options =
                Utils.ExtendOptions(this.defaultOptions, NumericInput.fillOptionsFromAttributes(this.$input), options);
        }
        if (this.options.decimalPrecision === undefined) {
            this.options.decimalPrecision =
                (Utils.UnwrapFunction(this.options.step || this.options.min || this.options.max) || 0).getPrecision();
        }
        if (this.options.allowSpaces) {
            this.options.increaseControl = null;
            this.options.decreaseControl = null;
            this.options.enableGoingAround = false;
            this.options.enableScrolling = false;
            this.options.spinnerButtons = "none"
        }
        this.$increaseControl = $(Utils.UnwrapContainerFunction(this.options.increaseControl));
        this.$decreaseControl = $(Utils.UnwrapContainerFunction(this.options.decreaseControl));
        let spinnerButtons = Utils.UnwrapFunction(this.options.spinnerButtons);
        if (this.options.prerendered) {
            this.$element = spinnerButtons === "topBottomArrows"
                            ? this.$input.parents(":not(.input-group)").first()
                            : this.$input.parent();
        }
        if (!spinnerButtons && this.options.prerendered) {
            if (this.$element.is(".input-group")) {
                spinnerButtons = "inputGroupButtons";
            } else if (this.$element.is(".number-spinner")) {
                spinnerButtons = "topBottomArrows";
            }
        }
        if (spinnerButtons === "inputGroupButtons") {
            if (!this.options.prerendered) {
                this.$element = $("<div class='input-group'></div>")
                    .append(`<button class="button secondary" tabindex="-1">−</button>`)
                    .insertAfter(this.$input)
                    .append(this.$input)
                    .append(`<button class="button secondary" tabindex="-1">+</button>`);
            }
            this.$increaseControl = this.$element.children(":last");
            this.$decreaseControl = this.$element.children(":first");
        } else if (spinnerButtons === "topBottomArrows") {
            if (!this.options.prerendered) {
                this.$element = $("<div class='number-spinner'></div>")
                    .append(`<span class="mat-icon-expandless"></span>`)
                    .insertAfter(this.$input)
                    .append(this.$input)
                    .append(`<span class="mat-icon-expandmore"></span>`);
            }
            this.$increaseControl =
                $(UnwrapContainerFunction(this.options.increaseControl) || this.$element.children(":first"));
            this.$decreaseControl =
                $(UnwrapContainerFunction(this.options.decreaseControl) || this.$element.children(":last"));
        } else {
            this.$element = this.$input;
        }
        if (typeof this.options.enableScrolling !== "boolean") {
            this.options.enableScrolling = (Utils.UnwrapFunction(this.options.spinnerButtons) || "none") !== "none";
        }
        this.bindHandlers();
        this.value = Utils.ParseNumber(this.$input.val(), this.options.allowGroupSeperator);
        if (String.isNullOrEmpty(this.$input.val())) {
            const originalColor = this.input.style.color;
            this.input.style.color = "transparent !important";
            (async (input: HTMLInputElement) => {
                try {
                    await Utils.AwaitCondition(
                        () => {
                            return String.isFilled(input.value);
                        },
                        10,
                        200);
                } catch (e) {
                    if (e !== "timeout") {
                        throw e;
                    }
                }
                this.value = Utils.ParseNumber(input.value, this.options.allowGroupSeperator);
                this.input.style.color = originalColor;
            })(this.input);
        }
        this.$element.add(this.$input).data("numeric-input", this);
        this.initialized = true;
    }

    private _value: number;

    get value() {
        return this._value;
    }

    set value(value: number) {
        if (!Number.isNumber(value)) {
            value = this.getDefaultValue();
        }
        if (value !== this._value) {
            if (value !== null) {
                const min = Utils.UnwrapFunction(this.options.min);
                const max = Utils.UnwrapFunction(this.options.max);
                if (Utils.UnwrapFunction(this.options.enforceMinMaxBoundaries)) {
                    if (!isNaN(min) && value < min) {
                        value = min;
                    } else if (!isNaN(max) && value > max) {
                        value = max;
                    }
                }
                if (this.$decreaseControl) {
                    if (value <= min && (isNaN(max) || !this.options.enableGoingAround)) {
                        this.$decreaseControl.attr("disabled", "disabled");
                    } else {
                        this.$decreaseControl.removeAttr("disabled");
                    }
                }
                if (this.$increaseControl) {
                    if (value >= max && (isNaN(min) || !this.options.enableGoingAround)) {
                        this.$increaseControl.attr("disabled", "disabled");
                    } else {
                        this.$increaseControl.removeAttr("disabled");
                    }
                }
            }
            const oldValue = this._value;
            this._value = value;
            if (document.activeElement !== this.input) {
                this.formatValue(value);
            }
            if (!this.initialized) {
                return;
            }
            const nativeEvent = new CustomEvent("input", {bubbles: true, cancelable: false});
            const controlDataEvent = new ControlDataEvent(this, {oldValue: oldValue, newValue: value}, false);
            nativeEvent.originalEvent = controlDataEvent;
            this.onValueChanged.fire(controlDataEvent, this.$element.add(this.$input));
            this.input.dispatchEvent(nativeEvent);
        }
    }

    static fillOptionsFromAttributes($input: JQuery): INumericInputOptions {
        const output: INumericInputOptions = {
            spinnerButtons: $input.data("spinner-buttons"),
            enableGoingAround: $input.booleanData("enable-going-around"),
            prerendered: $input.booleanData("prerendered"),
            enableScrolling: $input.booleanData("enable-scrolling"),
            decimalPrecision: $input.hasData("decimal-precision")
                              ? Math.round($input.data("decimal-precision"))
                              : undefined,
            selectTextOnFocus: $input.booleanData("select-text-on-focus"),
            allowGroupSeperator: $input.booleanData("allow-group-seperator"),
            min: $input.hasAttr("min") ? () => parseFloat($input.attr("min")) : undefined,
            max: $input.hasAttr("max") ? () => parseFloat($input.attr("max")) : undefined,
            step: $input.hasAttr("step") ? () => parseFloat($input.attr("step")) : undefined,
            enforceMinMaxBoundaries: $input.booleanData("enforce-min-max-boundaries"),
            enableMaxLengthHandler: false,
            allowSpaces: $input.booleanData("allow-spaces"),
            defaultValue: () => typeof $input.typedData(false,"default-value") === "number"
                          ? $input.typedData(false, "default-value")
                          : undefined
        };

        return output;
    }

    eventElements() {
        return [this.$element, this.$input]
    };

    disable() {
        $([this.$input, this.$decreaseControl, this.$increaseControl]).attr("disabled", "disabled");
    }

    enable() {
        const min = Utils.UnwrapFunction(this.options.min);
        const max = Utils.UnwrapFunction(this.options.max);
        if (this.$decreaseControl) {
            if (this.value <= min && (isNaN(max) || !this.options.enableGoingAround)) {
                this.$decreaseControl.attr("disabled", "disabled");
            } else {
                this.$decreaseControl.removeAttr("disabled");
            }
        }
        if (this.$increaseControl) {
            if (this.value >= max && (isNaN(min) || !this.options.enableGoingAround)) {
                this.$increaseControl.attr("disabled", "disabled");
            } else {
                this.$increaseControl.removeAttr("disabled");
            }
        }
    }

    formatValue(value = this.value) {
        if (this.options.allowSpaces) {
            return;
        }
        const stringValue = this.$input.val();
        if (value === null || isNaN(this.value)) {
            this.$input.val("");
            return;
        }
        const precision = Utils.UnwrapFunction(this.options.decimalPrecision) || 0;
        const roundedValue = Math.round(value, precision);
        if (roundedValue !== value) {
            this.value = roundedValue;
            return;
        }
        if (this.options.formatter) {
            this.$input.val(this.options.formatter(value));
        } else {
            this.$input.val(value.toFixed(precision).toString().replace("-", "−"));
        }
        if (stringValue !== this.$input.val()) {
            const nativeEvent = new CustomEvent("input", {bubbles: true, cancelable: false});
            nativeEvent.originalEvent = new ControlDataEvent(this, {oldValue: value, newValue: value}, false);
            this.input.dispatchEvent(nativeEvent);
        }
    }

    protected createChangeCallback(action: (value: number) => number) {
        return (control?: HTMLElement, cancelAction?: () => void) => {
            let hasOverflow: "overflow" | "underflow";
            if (control && $(control).is(":disabled")) {
                cancelAction();
                return;
            }
            let value = this.value || 0;
            const max = Utils.UnwrapFunction(this.options.max);
            const min = Utils.UnwrapFunction(this.options.min);
            value = action(value);
            if (value > max) {
                if (typeof min === "number" && !isNaN(min) && this.options.enableGoingAround) {
                    value = min;
                    hasOverflow = "overflow";
                } else {
                    value = max;
                }
            } else if (value < min) {
                if (typeof max === "number" && !isNaN(max) && this.options.enableGoingAround) {
                    value = max;
                    hasOverflow = "underflow";
                } else {
                    value = min;
                }
            }
            const oldValue = this.value;
            this.value = value;
            this.formatValue();
            if (hasOverflow) {
                this.onOverflowed.fire(new ControlDataEvent<this, overflowEventData>(this,
                                                                                     {
                                                                                         newValue: value,
                                                                                         oldValue: oldValue,
                                                                                         type: hasOverflow
                                                                                     },
                                                                                     false))
            }
        }
    }

    private getDefaultValue() {
        const value = UnwrapFunction(this.options.defaultValue);
        return typeof value === "number" ? value : null;
    }

    protected bindHandlers() {
        if (this.$increaseControl) {
            this.$increaseControl
                .on("mousedown",
                    (e) => {
                        this.increaseCallback();
                        const cancelAction = Utils.setMultival(() => this.increaseCallback(e.delegateTarget as HTMLElement,
                                                                                           cancelAction),
                                                               this.options.multivals);
                        this.$increaseControl.one("mouseup mouseleave", () => {
                            cancelAction();
                        });
                        if (Utils.UnwrapFunction(this.options.max) <= this.value) {
                            this.value = Utils.UnwrapFunction(this.options.max);
                            cancelAction();
                        }
                    })
                .click((e) => e.preventDefault());
        }

        if (this.$decreaseControl) {
            this.$decreaseControl
                .on("mousedown",
                    (e) => {
                        this.decreaseCallback();
                        const cancelAction = Utils.setMultival(() => this.decreaseCallback(e.delegateTarget as HTMLLIElement,
                                                                                           cancelAction),
                                                               this.options.multivals);
                        if (Utils.UnwrapFunction(this.options.min) >= this.value) {
                            this.value = Utils.UnwrapFunction(this.options.min);
                            cancelAction();
                        }
                        this.$decreaseControl.one("mouseup mouseleave", () => {
                            cancelAction();
                        });
                    })
                .click((e) => e.preventDefault());
        }
        if (this.options.enableScrolling) {
            let miniumOverscrollHook: number = null;
            let maxiumOverscrollHook: number = null;
            this.$element.on("wheel",
                             (e) => {
                                 const wheelEvent = e.originalEvent as WheelEvent;
                                 if (wheelEvent.deltaY === 0) {
                                     return;
                                 }
                                 e.preventDefault();
                                 if (wheelEvent.deltaY > 0) {
                                     maxiumOverscrollHook = null;
                                     if (miniumOverscrollHook !== null) {
                                         return;
                                     }
                                     const previousValue = this.value;
                                     const minValue = Utils.UnwrapFunction(this.options.min);
                                     this.decreaseCallback();
                                     if (!isNaN(minValue) && this.value === minValue && previousValue !== this.value) {
                                         miniumOverscrollHook =
                                             window.setTimeout(() => {miniumOverscrollHook = null}, 500);
                                     }
                                 } else {
                                     miniumOverscrollHook = null;
                                     if (maxiumOverscrollHook !== null) {
                                         return;
                                     }
                                     const previousValue = this.value;
                                     this.increaseCallback();
                                     const maxValue = Utils.UnwrapFunction(this.options.max);
                                     if (!isNaN(maxValue) && this.value === maxValue && previousValue !== this.value) {
                                         maxiumOverscrollHook =
                                             window.setTimeout(() => {maxiumOverscrollHook = null}, 500);
                                     }
                                 }
                             });
        }
        this.$input
            .on("keypress", (e: JQueryKeyEventObject) => {
                if (e.ctrlKey) {
                    return;
                }
                // if ([0, 8, 9, 38, 39, 35, 36].contains(e.which)) {
                if (e.key !== "Spacebar" && e.key.length > 1) {
                    return;
                }
                if (this.options.enableMaxLengthHandler) {
                    const maxLength = this.input.maxLength;
                    if (maxLength) {
                        const $elem = this.validationField.getErrorMessageElement();
                        if (this.$input.val().length + 1 >= maxLength) {

                            if (!$elem.siblings(".length-message").is()) {
                                $elem.after(`<p class="length-message">Maximum number of characters reached.</p>`);
                            }
                            if (this.validationField.isValid !== false) {
                                $elem.hide();
                            }
                            if (this.$input.val().length + 1 > maxLength) {
                                e.preventDefault();
                                return;
                            }
                        } else {
                            $elem.siblings(".length-message").remove();
                            if (this.validationField.isValid !== false) {
                                $elem.hide();
                            }
                        }
                    }
                }
                if ([",", "."].contains(e.key)) {
                    if ((Utils.UnwrapFunction(this.options.decimalPrecision) || 0) !== 0
                        && /[,.]/.test(this.$input.val()) === false) {
                        return;
                        const newValue = Utils.ParseNumber(this.$input.val().replace(/ /g, ""),
                                                           this.options.allowGroupSeperator);
                        this.value = isNaN(newValue) ? null : newValue;
                    }
                }
                if (e.key === "-" && !this.$input.val().contains("-") &&
                    this.input.selectionStart === 0) {
                    const minValue = UnwrapFunction(this.options.min);
                    if (typeof minValue !== "number" || minValue < 0) {
                        return;
                    }
                }
                if (this.options.allowSpaces && (e.key === "Spacebar" || e.key === " ")) {
                    return;
                }
                e.preventDefault();
                if (!/^\d$/.test(e.key)) {
                    return;
                }
                let newStringValue: string;
                let stringValue = this.input.value;
                const selectionStart = this.input.selectionStart;
                const selectionEnd = this.input.selectionEnd;
                if (selectionStart !== selectionEnd) {
                    stringValue = stringValue.substring(0, selectionStart) + stringValue.substring(selectionEnd);
                }
                let key = String.fromCharCode(e.which);
                if (selectionStart === 0) {
                    newStringValue = key + stringValue;
                } else if (selectionStart === this.input.value.length) {
                    newStringValue = stringValue + key;
                } else {
                    newStringValue = stringValue.substring(0, selectionStart)
                                     + key
                                     + stringValue.substring(selectionStart);
                }
                const newValue = Utils.ParseNumber(newStringValue.replace(/ /g, ""), this.options.allowGroupSeperator);
                this.$input.val(newStringValue);
                const newSelection = selectionEnd + 1 - (selectionEnd - selectionStart);
                this.input.setSelectionRange(newSelection, newSelection);
                this.value = isNaN(newValue) ? null : newValue;
            })
            .on("input paste",
                (e) => {
                    if (!(e.originalEvent && e.originalEvent.originalEvent instanceof ControlDataEvent)) {
                        e.stopImmediatePropagation();
                        e.stopPropagation();
                        if (this.options.enableMaxLengthHandler) {
                            const maxLength = this.input.maxLength;
                            if (maxLength) {
                                const $elem = this.validationField.getErrorMessageElement();
                                if (this.$input.val().length + 1 >= maxLength) {
                                    this.$input.val(this.$input.val().substring(0, maxLength));
                                    if (!$elem.siblings(".length-message").is()) {
                                        $elem.after(`<p class="length-message">Maximum number of characters reached.</p>`);
                                    }
                                    e.preventDefault();
                                } else {
                                    $elem.siblings(".length-message").remove();
                                }
                                if (this.validationField.isValid !== false) {
                                    $elem.hide();
                                }
                            }
                        }
                        this.value = Utils.ParseNumber(this.$input.val(), this.options.allowGroupSeperator);
                    }
                })
            .on("focusout", () => this.formatValue())
            .on("focus", () => {
                if (Utils.UnwrapFunction(this.options.selectTextOnFocus)) {
                    this.input.select();
                }
            });
        this.input.addEventListener("paste", (e) => {
            e.preventDefault();
            const pastedText = e.clipboardData.getData("text");
            if (String.isNullOrEmpty(pastedText)) {
                return;
            }
            const stringValue = this.input.value;
            const selectionStart = this.input.selectionStart;
            const selectionEnd = this.input.selectionEnd;
            const newStringValue = stringValue.substring(0, selectionStart) + pastedText.replace(/\s/g, "") +
                                   stringValue.substring(selectionEnd);
            const numberValue = Utils.ParseNumber(newStringValue);
            const regexString = `^[+.]?\\d+(?:\\${window.numberGroupSeparator}\\d+)*(?:\\${window.numberDecimalSeparator}\\d+)?$`;
            if (isNaN(numberValue) || !new RegExp(regexString).test(newStringValue)) {
                openAlert({
                              type: "error",
                              content: `"${pastedText}" can not be converted into a number.`,
                              timeout: 4000,
                              dontDisplayX: true
                          });
            } else {
                this.$input.val(newStringValue);
                this.value = numberValue;
                const newSelection = selectionEnd + pastedText.length - (selectionEnd - selectionStart);
                this.input.setSelectionRange(newSelection, newSelection);
            }
        });
    }

}

export interface INumericInputOptions {
    /**
     * Specify if the control has buttons to change the value
     */
    spinnerButtons?: FunctionOrValue<"none" | "inputGroupButtons" | "topBottomArrows">;
    /**
     * If true users can use the scroll wheel to increase / decrease the value
     * @default true
     */
    enableScrolling?: boolean;
    /** If true and min and max are set the control switches to min value when the number is increased using buttons and is becoming larger thant max, and vice versa
     * default: true
     */
    enableGoingAround?: boolean;
    /**
     * Biggest valid value. If none is provide in the options the max attribute from the control will be used if set
     */
    max?: FunctionOrValue<number>;
    /**
     * Smallest valid value. If none is provide in the options the max attribute from the control will be used if set
     */
    min?: FunctionOrValue<number>;
    /**
     * Amount which is added to / subtracted from the current value when the scroll wheel or buttons are used
     */
    step?: FunctionOrValue<number>;
    /**
     * maximum number of decimal precision. If the user tries to enter more digits the number will be rounded to that precision
     */
    decimalPrecision?: FunctionOrValue<number>,
    /** Function that is fired after the input lost focus or the value was changed using the buttons or the scroll wheel */
    formatter?: (value: number) => string;
    /**
     * If true the text of the input is selected every time the input gains focus
     */
    selectTextOnFocus?: FunctionOrValue<boolean>;
    /**
     * If true the control will not render any additional ui and instead will look for increase / decrease button in the parent element of the input
     */
    prerendered?: boolean;
    /**
     * Control to increase the value. Will only be used if spinnerButtons is null or 'none'
     */
    increaseControl?: ContainerType;
    /**
     * Control to decrease the value. Will only be used if spinnerButtons is null or 'none'
     */
    decreaseControl?: ContainerType;
    /**
     * Multival that is used when the mouse is held down on the increase / decrease buttons
     * @default [600, { interval: 200, steps: 10 }, { interval: 100, steps: Infinity }]
     */
    multivals?: () => (number | MultivalInterval)[];
    /**
     * If true, users can add group separators to the input. If false group separators will be treated as decimal point
     * @default false
     */
    allowGroupSeperator?: boolean;
    /**
     * if true the value will be set to min / max after the input looses focus and the user has entered a number out of these boundaries. This option has no effect on the buttons for which the boundaries will always be applied.
     * @default: false
     */
    enforceMinMaxBoundaries?: FunctionOrValue<boolean>;
    /**
     * If true all spaces in the input value will be ignored when checking if it can be parsed to a number. This disables auto formatting as well as decrease / increase controls and scrolling.
     */
    allowSpaces?: boolean;
    /**
     * If true the max length handler of normal text boxes will be applied to this input (grey text and disabling of typing), otherwise it will be handled by validation.
     * @default false
     */
    enableMaxLengthHandler?: GLboolean
    /**
     * Value that is inserted if the current value in the field is removed.
     */
    defaultValue?: FunctionOrValue<number>;
}

declare global {
    interface JQuery/*<TElement extends Node = HTMLElement>*/ {
        numericInput(options?: INumericInputOptions, enableAttributeOverride?: boolean): JQuery/*<TElement>*/;
    }
}

$.fn.numericInput =
    function <TElement extends Node = HTMLElement>(this: JQuery/*<TElement>*/,
                                                   options?: INumericInputOptions,
                                                   enableAttributeOverride?: boolean) {
        return this.each(function (this: HTMLInputElement) {
            if ($(this).data("numeric-input")) {
                return;
            }
            // ReSharper disable once WrongExpressionStatement
            new NumericInput(this, options, enableAttributeOverride);
        });
    }
