import {ApplicationRef, Component, ElementRef} from "@angular/core";
import {ViewComponent} from "./view.component";
import {Router} from "@angular/router";
import * as Utils from "../utils/Utils";
import {toDictionary, toJsonData} from "@utils/Utils";
import {Dropdown} from "@widgets/Dropdown";
import {Alignment, Position} from "@utils/Enums";
import {ISelectOptions} from "@widgets/Select";
import {INumericInputOptions} from "@widgets/NumericInput";
import {FixIeAnimationIssue, SlideInOut} from "@utils/Angular/AnimationTemplates";
import {PopupSelectList} from "@widgets/PopupSelectList";
import {ISelectListItem} from "@widgets/SelectList";
import {FormValidator} from "@widgets/Validate";
import {LastUsedDangerousGoodsCache} from "@booking/LastUsedDangerousGoodsCache";
import {DangerousGoodsService} from "@booking/DangerousGoodsService";

import {BookingServiceListener} from "@services/booking-service-listener.service";
import {ContainerTypes, IJsonContainer, IJsonPackage} from "@booking/BookingModel";
import {DangerousGoodsMasterData} from "@api";

@Component({
             selector: "goods-containers",
             animations: [SlideInOut()],
             template: `
               <h2>Goods</h2>
               <div class="table-wrap">
                 <table class="table-default table-narrow table-no-background table-no-borders" id="package-list"
                        style="table-layout: fixed;">
                   <colgroup>
                     <col style="width: 220px;"/>
                     <col style="width: 130px;"/>
                     <col style="width: 130px;"/>
                     <col style="width: 38px;"/>
                     <col style="width: 32px;"/>
                     <col style="width: 38px;"/>
                   </colgroup>
                   <thead>
                   <tr>
                     <th style="padding-left: 0;">Container Type</th>
                     <th>Volume</th>
                     <th>Weight</th>
                   </tr>
                   </thead>
                   <tbody *ngFor="let line of model.containers, let index = index">
                   <tr (input)="lineInput(line)">
                     <td style="position: relative; padding-left: 0;">
                       <select
                         [(ngModel)]="line.containerType"
                         [attr.required]="mode === 'booking' ? 'required' : null"
                         data-no-validation-message
                         data-display-name="Container Type"
                         [attr.data-disable-validation]="line === model.dummyContainer && model.containers.length > 1"
                         value="{{line.containerType}}"
                         ngSelect
                         [settings]="selectOptions"
                         [items]="containerTypes | pureFunction:getContainerTypeItems"
                         (change)="lineInput(line)">
                       </select>
                     </td>
                     <td style="position: relative;">
                       <div class="input-group stretch">
                         <input
                           type="number"
                           class="text-right"
                           style="width: 92px; padding-left: 5px;"
                           [(ngModel)]="line.volume"
                           ngNumericInput
                           [settings]="numericInputOptions"
                           [attr.required]="mode === 'booking' ? 'required' : null"
                           min="0.001"
                           data-no-validation-message
                           data-enable-scrolling="false"
                           data-display-name="Volume"
                           data-validate-min-message="Volume must be greater than zero"
                           [attr.data-disable-validation]="line === model.dummyContainer && model.containers.length > 1"/><span
                         class="input-appendix unselectable">m³</span>
                       </div>
                     </td>
                     <td>
                       <div class="input-group stretch">
                         <input
                           type="number"
                           class="text-right"
                           style="width: 94px; padding-left: 5px;"
                           [(ngModel)]="line.weight"
                           ngNumericInput
                           [settings]="numericInputOptions"
                           [attr.required]="mode === 'booking' ? 'required' : null"
                           min="0.001"
                           data-no-validation-message
                           data-enable-scrolling="false"
                           data-display-name="Weight"
                           data-validate-min-message="Weight must be greater than zero"
                           data-enable-going-around="false"
                           [attr.data-disable-validation]="line === model.dummyContainer && model.containers.length > 1"/><span
                         class="input-appendix unselectable">kg</span>
                       </div>
                     </td>
                     <td>
                       <i [hidden]="line === model.dummyContainer"
                          class="material-icons menu-toggle hover-icon">more_vert</i>
                     </td>
                     <td>
                       <i *ngIf="line !== model.dummyContainer"
                          class="material-icons accordion-toggle accordion-dg-goods"
                          (click)="toggleAccordionDgGoods($event.target, index, dgCheckbox)">expand_less</i>
                     </td>
                     <td>
                       <i [hidden]="!checkLineWarning(line)"
                          data-popover-content="Your goods most likely exceed volume or weight limits"
                          class="warning-message material-icons">warning</i>
                     </td>
                   </tr>
                   <tr [hidden]="disableAnimations && line=== model.dummyContainer"
                       [@slideInOut]="line !== model.dummyContainer || disableAnimations ? 'visible' : 'hidden'">
                     <td colspan="6" style="padding: 0;">
                       <div class="accordion headerless accordion-animated"
                            [hidden]="disableAnimations && isAccordionClosed[index]"
                            [@slideInOut] = "isAccordionClosed[index] && !disableAnimations? 'hidden' : 'visible'"
                            id="accordion_fcl_{{index}}">
                         <div class="clip">
                           <div class="dangerous-goods">
                             <label class="checkbox-wrapper">
                               <input #dgCheckbox
                                      type="checkbox"
                                      name="hasDangerousGoods"
                                      [(ngModel)]="line.dangerousGoodsSpecified">
                               <span class="mat-icon-done"></span>
                               <span>Dangerous Goods</span>
                             </label>
                             <div class="form-group"
                                  [hidden]="disableAnimations && !line.dangerousGoodsSpecified"
                                  [@slideInOut]="line.dangerousGoodsSpecified || disableAnimations ? 'visible' : 'hidden'">
                                           <span class="form-label">
                                               <label for="{{'cboDangerousGoods_fcl_' + index}}">Dangerous Goods Substance</label>
                                           </span>
                               <select
                                 id="{{'cboDangerousGoods_fcl_' + index}}"
                                 class="dangerous-goods-select"
                                 multiple
                                 [attr.required]="line.dangerousGoodsSpecified && mode !== 'template' ? 'required' : null"></select>
                               <small class="text-light">Please upload the related Dangerous Goods
                                 Documents
                                 in the next process step.
                               </small>
                               <div class="selected-dangerous-goods">
                                 <div *ngFor="let item of line.dangerousGoodsItems">
                                   <span [innerHTML]="item.html"></span>
                                   <span class="material-icons hover-icon"
                                         (click)="item.remover()">delete</span>
                                 </div>
                               </div>
                             </div>
                             <div [@slideInOut]="disableAnimations || (line.containerType | pureFunction:isReefer) ? 'visible' : 'hidden'"
                                  [hidden]="disableAnimations && !(line.containerType | pureFunction:isReefer)">
                               <div class="form-group">
                                 <label class="checkbox-wrapper">
                                   <input type="checkbox"
                                          name="hasOperatingReefer"
                                          [attr.required]="line.temperatureSpecified && mode !== 'template' ? 'required' : null"
                                          [(ngModel)]="line.temperatureSpecified">
                                   <span class="mat-icon-done"></span>
                                   <span>Operating Reefer</span>
                                 </label>
                               </div>
                               <div [hidden]="disableAnimations && !line.temperatureSpecified"
                                    [@slideInOut]="line.temperatureSpecified || disableAnimations ? 'visible' : 'hidden'">
                                 <div class="form-group">
                                           <span class="form-label">
                                               <label for="{{'txtTemperature_' + index}}">Temperature</label>
                                           </span>
                                   <div class="input-group counter">
                                     <button class="button secondary" tabindex="-1">−</button>
                                     <input
                                       type="number"
                                       tabindex = "-1"
                                       class="count"
                                       min="-30"
                                       max="30"
                                       [(ngModel)]="line.temperature"
                                       [attr.required]="mode === 'booking' && line.temperatureSpecified ? 'required' : null"
                                       data-prerendered
                                       data-enable-scrolling
                                       data-enable-going-around="false"
                                       data-enable-attribute-override
                                       [attr.data-default-value]="mode === 'template' || (!line.temperature && line.temperature !== 0) ? '' : 0"
                                       data-validate-min-message="The minimum temperature is {1} °C."
                                       data-validate-max-message="The maximum temperature is {1} °C."
                                       ngNumericInput
                                       id="{{'txtTemperature_' + index}}"
                                       [attr.data-disable-validation]="line === model.dummyPackage && model.packages.length > 1"/>
                                     <span class="input-appendix">°C</span>
                                     <button class="button secondary" tabindex="-1">+</button>
                                   </div>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </td>
                   </tr>
                   </tbody>
                   <tfoot>
                   <tr>
                     <th style="padding-left: 0;">Total</th>
                     <td></td>
                     <td></td>
                     <td></td>
                     <td></td>
                     <td></td>
                   </tr>
                   <tr>
                     <td style="padding-left: 0;">{{model.containers.length - 1}} Containers</td>
                     <td style="padding-right: 26px; text-align: right;">
                       {{model.totalContainerVolume | fixedNumber:3:0}} m³
                     </td>
                     <td style="padding-right: 26px; text-align: right;">
                       {{model.totalContainerWeight | fixedNumber:3:0}} kg
                     </td>
                     <td></td>
                     <td></td>
                     <td></td>
                   </tr>
                   </tfoot>
                 </table>
               </div>
               <span class="error-message"
                     *ngIf="hasLineValidationErrors && model.containers.length > 1">At least one of the lines is missing a required value.</span>
               <span class="error-message" *ngIf="hasLineValidationErrors && model.containers.length === 1">Goods are required.</span>
             `
           })
export class GoodsContainersComponent extends ViewComponent {
  //language=html
  private static menuTemplate =
    `<a class="duplicate"><i class="material-icons">content_copy</i> Duplicate</a>
    <a class="delete"><i class="material-icons">delete</i> Delete</a>`;
  name: string = "GoodsContainersComponent";
  toDictionary = toDictionary;
  toJsonData = toJsonData;
  containerTypes = ContainerTypes;
  lineCount = 0;
  tableBody: HTMLElement;
  hasLineValidationErrors = false;
  isValidating = false;
  fixIeAnimationIssue = FixIeAnimationIssue;
  isAccordionClosed: { [k: number]: boolean } = {};

  readonly isSubView = true;
  readonly selectOptions: ISelectOptions = {multiple: false, closeOnSelect: true, selectedOnTop: false};
  readonly numericInputOptions: INumericInputOptions = {
    enableScrolling: false,
    min: 0.001,
    decimalPrecision: 3
  };
  private readonly _dgService: DangerousGoodsService;
  private readonly _dgCache: LastUsedDangerousGoodsCache;

  constructor(element: ElementRef,
              router: Router,
              app: ApplicationRef,
              dgService: DangerousGoodsService,
              dgCache: LastUsedDangerousGoodsCache,
              bookingServiceListener: BookingServiceListener) {
    super(element, app, router, bookingServiceListener);
    this._dgService = dgService;
    this._dgCache = dgCache;
  }

  getContainerTypeItems = (containerType: typeof ContainerTypes) => this.toJsonData(containerType).filter(i => !i.value.contains("NOR"));

  async validate() {
    this.isValidating = true;
    this.hasLineValidationErrors = !await super.validate();
    this.isValidating = false;
    return !this.hasLineValidationErrors;
  }

  onInitialized(): void {
    const $tableBody = $("tbody", this.element).parent();
    this.tableBody = $tableBody[0];
    $tableBody.on("field-validated", () => {
      if (this.isValidating) {
        return;
      }
      this.hasLineValidationErrors = $tableBody.has(".error").length > 0;
      this.app.tick();
    });
    this.FormValidator = new FormValidator($(this.element));
    this.attachHandlers();
    super.onInitialized();
    this.calculateVolume();
    this.calculateWeight();
    //window.setTimeout(() => this.app.tick());
  }

  toggleAccordionDgGoods(target: EventTarget, contentId: number, hasDangerousGoodsInput: HTMLInputElement) {
    const toggleBtn = target as HTMLElement;
    $(toggleBtn).toggleClass("closed")
    this.isAccordionClosed[contentId] = $(toggleBtn).hasClass("closed");

    const searchBox = $(hasDangerousGoodsInput).closest(".dangerous-goods").find("input.search-box");
    if (!$(toggleBtn).hasClass("closed") && hasDangerousGoodsInput.checked) {
      $(searchBox).removeAttr("tabindex");
    } else {
      $(searchBox).attr("tabindex", "-1");
    }
  }

  calculateVolume() {
    let totalVolume = 0;
    for (const packLine of this.model.containers) {
      if (packLine === this.model.dummyContainer || !packLine.volume) {
        continue;
      }
      totalVolume += (Utils.ParseNumber(packLine.volume) || 0);
    }
    this.model.totalContainerVolume = totalVolume;
  }

  calculateWeight() {
    let totalWeight = 0;
    for (const packLine of this.model.containers) {
      if (packLine === this.model.dummyContainer) {
        continue;
      }
      totalWeight += (Utils.ParseNumber(packLine.weight) || 0);
    }
    this.model.totalContainerWeight = totalWeight;
  }

  lineInput(line: Partial<IJsonContainer>) {
    let lineIsEmpty = Utils.IsObjectEmpty(line,
                                          this.model.getContainerDummy(),
                                          ["dangerousGoodsItems", "dangerousGoodsSpecified"]);
    if (line === this.model.dummyContainer) {
      if (!lineIsEmpty) {
        this.model.dummyContainer = this.model.getContainerDummy();
        this.model.containers.push(this.model.dummyContainer);
      }
    } else if (lineIsEmpty) {
    }
    this.calculateWeight();
    this.calculateVolume();
  }

  ngAfterViewChecked(): void {
    super.ngAfterViewChecked();
    // if (this.tableBody.children.length !== this.lineCount) {
    this.lineCount = this.tableBody.children.length;
    this.attachHandlers();
    // }
  }

  attachHandlers() {
    $(this.tableBody)
      .children("tbody")
      .each((index, elem) => {
        if (elem.classList.contains("initialized")) {
          return;
        }
        elem.classList.add("initialized");
        const line = this.model.containers[index];
        $(".warning-message", elem).popover();
        $("i.menu-toggle", elem)
          .each((i, menuToggle: HTMLElement) => {
            const $menuToggle = $(menuToggle);
            if ($menuToggle.data("dropdown")) {
              return;
            }
            const menu = $(GoodsContainersComponent.menuTemplate);
            const dropdown = new Dropdown(menu,
                                          $menuToggle,
                                          {
                                            autoPosition: false,
                                            "class": "context-menu",
                                            position: Position.Left,
                                            alignment: Alignment.Start,
                                            openOnFocus: false
                                          });
            menu.filter(".delete")
                .touchClick(() => {
                  dropdown.close();
                  this.model.containers.remove(line);
                  this.calculateVolume();
                  this.calculateWeight();
                  this.app.tick();
                });
            menu.filter(".duplicate")
                .touchClick(() => {
                  dropdown.close();
                  const newLine = $.extend({}, line) as IJsonPackage;
                  newLine.dangerousGoodsItems = [];
                  newLine.dangerousGoods = newLine.dangerousGoods.slice(0);
                  this.model.containers.insert(this.model.containers.indexOf(line) + 1, newLine);
                  this.calculateVolume();
                  this.calculateWeight();
                  this.app.tick();
                });
          });
        const preselectedDangerousGoods = line.dangerousGoods;
        const select = new PopupSelectList<JSONDataContainer<DangerousGoodsMasterData>>($(".dangerous-goods-select", elem)[0] as HTMLSelectElement,
                                                                                        {
                                                                                          selectListClass: "dangerous-goods-select",
                                                                                          class: "dangerous-goods-select",
                                                                                          alignment: Alignment.Stretch,
                                                                                          allowGrowing: false,
                                                                                          showSelectedValuesOnLoad: false,
                                                                                          enableSearchRanking: true,
                                                                                          closeOnSelect: true,
                                                                                          previewItems: items => this._dgCache
                                                                                                                     .get(20)
                                                                                                                     .map(c => items.firstOrDefault(
                                                                                                                       i => i.value === c))
                                                                                                                     .filter(i => i)
          });

        (() => {
          const hasDangerousGoodsInput = $("input[name='hasDangerousGoods']", elem)[0] as HTMLInputElement;
          const allowSearchBoxTabbing = () => {
            const searchBox = $(hasDangerousGoodsInput).closest(".dangerous-goods").find("input.search-box");
            if (hasDangerousGoodsInput.checked) {
              $(searchBox).removeAttr("tabindex");
            } else {
              $(searchBox).attr("tabindex", "-1");
            }
          }
         
          allowSearchBoxTabbing();
          $(hasDangerousGoodsInput).on('change', () => {
            allowSearchBoxTabbing();
          });
        })();
      

        let controlLoaded = false;
        (async () => {
          const items = (await this._dgService.createDangerousGoodsSelectItemsList("SEA"));
          line.dangerousGoodsItems.clear();
          if (preselectedDangerousGoods) {
            for (const good of preselectedDangerousGoods) {
              let item = items.firstOrDefault(i => i.value === good)!;
              item.selected = true;
              line.dangerousGoodsItems.push({
                                              html: item.contentString,
                                              value: item.value,
                                              data: item.ajaxData.data,
                                              remover: () => select.unselectItems(item as ISelectListItem<JSONDataContainer<DangerousGoodsMasterData>>,
                                                                                  true,
                                                                                  true)
                                            })
            }
          }
          select.addItems(...items);
          controlLoaded = true;
        })();
        select.onSelectionChanged.on((e) => {
          if (!controlLoaded) {
            return;
          }
          line.dangerousGoodsItems.pushRange(
            e.addedItems
             .map(i => {
               this._dgCache.add(i.value);
               return {
                 html: i.contentString,
                 value: i.value,
                 data: i.ajaxData.data,
                 remover: () => select.unselectItems(i, true, true)
               }
             }));
          for (const removedItem of e.removedItems) {
            line.dangerousGoodsItems.removeRange(line.dangerousGoodsItems.filter(
              i => i.value === removedItem.value));
          }
          line.dangerousGoods.pushRange(e.addedItems.map(i => i.value));
          line.dangerousGoods.removeRange(e.removedItems.map(i => i.value))
        });
      });
  }

  isReefer(containerType: keyof typeof ContainerTypes) {
    return containerType && containerType.contains("RE");
  }

  checkLineWarning(line: Partial<IJsonContainer>) {
    let containerType = line.containerType;
    if (!containerType) {
      return false;
    }
    if (containerType.startsWith("20")) {
      return line.weight > 28300 || line.volume > 32;
    } else if (containerType.startsWith("40")) {
      if (containerType.contains("HC")) {
        return line.weight > 28560 || line.volume > 74;
      } else {
        return line.weight > 28700 || line.volume > 64;
      }
    }
  }
}
