import {AllHtmlEntities} from "../TypeDefinitions/html-entites";

const entities: AllHtmlEntities = require("html-entities").AllHtmlEntities;

type SearchStringMatchChunk = { text: string; isHtmlTag: boolean };

type SearchStringElement = { normalizedLetter: string; originalLetter: string; subsequentHtml: string; breakSearch?: boolean };

export class SearchString {

    private static readonly szChar = String.fromCharCode(223);
    // noinspection NonAsciiCharacters
    private static readonly specialReplace: { [index: string]: string } = {
        "Æ": "a",
        "Ø": "o",
        "æ": "a",
        "ø": "o",
        "ͣ": "a",
        "ͤ": "e",
        "ͥ": "i",
        "ͦ": "o",
        "ͧ": "u",
        "ͨ": "c",
        "ͩ": "d",
        "ͪ": "h",
        "ͫ": "m",
        "ͬ": "r",
        "ͭ": "t",
        "ͮ": "v",
        "ͯ": "c",
        "Α": "a",
        "Ε": "e",
        "Η": "h",
        "Ι": "i",
        "Ο": "o",
        "Ρ": "p",
        "Υ": "y",
        "ǽ": "a",
        "ˠ": "y",
    };
    private static readonly _NoSearchRegex = /<ns(\d+)><\/ns\d+>/gi;
    private static readonly _HtmlTagRegex = /<\/?[\w\-]+(?:\s+\w+(?:=(?:"[^"]*"|'[^']*'|[^"' ]+))?)*\s*\/?\s*>/g;
    private static readonly _TrailingHtmlTagRegex = /^((?:<\/?[\w\-]+(?:\s+\w+(?:=(?:"[^"]*"|'[^']*'|[^"' ]+))?)*\s*\/?\s*>)+)/g;
    private static readonly _NextCharWithHtmlTagRegex = /(\s|&.+?;|[^&])([\u0300-\u036f]*)((?:<\/?\w+(?:\s+\w+(?:=(?:"[^"]*"|'[^']*'|[^"' ]+))?)*\s*\/?\s*>)*)/g;
    private readonly elements = new Array<SearchStringElement>();
    private trailingHtml: string;
    private inputString: string;
    private quickCheckString: string;
    private _initialized: boolean;

    constructor(inputString: string, initialize = false) {
        if (!inputString) {
            return;
        }
        this.inputString = inputString?.replace(/\s+/g, " ") || "";
        if (initialize)
            this._initialize();
    }

    static replaceSpecialChars(input: string) {
        if (/[^A-Za-z0-9\s()\[\]\-.,;:_^=&%]/.test(input)) {
            for (const prop in SearchString.specialReplace) {
                if (!SearchString.specialReplace.hasOwnProperty(prop)) {
                    continue;
                }
                input = input.replace(new RegExp(prop, "g"), SearchString.specialReplace[prop]);
            }
        }
        return input;
    }

    getMatches = (pattern: string) => {
        if (!this._initialized)
            this._initialize();
        pattern = SearchString.replaceSpecialChars(pattern)
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                              .toLowerCase()
                              .replace(/\s+/g, " ")
                              .trim(" ");
        const output = new Array<SearchStringMatch>();
        if (!this.quickCheckString.contains(pattern.replace(/\s/g, ""))) {
            return output;
        }
        let currentMatch = new SearchStringMatch(true);
        currentMatch.isFirstWord = true;
        currentMatch.isStartOfWord = true;
        let patternPointer = 0;
        let index = this.trailingHtml.length;
        for (let i = 0; i < this.elements.length; i++) {
            let elem = this.elements[i];
            const currentPatternChar = pattern[patternPointer];
            let currentCharIsMatch = elem.normalizedLetter === currentPatternChar;
            if (patternPointer === 0) {
                currentMatch.position = index;
            }
            if (!currentCharIsMatch) {
                //check for ß-stuff

                if (currentPatternChar === SearchString.szChar) {
                    if (elem.normalizedLetter === "s"
                        && (this.elements[i + 1] || ({} as any)).normalizedLetter === "s") {
                        currentMatch.addElement(elem);
                        index += elem.originalLetter.length + elem.subsequentHtml.length;
                        elem = this.elements[++i];
                        currentCharIsMatch = true;
                    }
                } else if (elem.normalizedLetter === SearchString.szChar) {
                    if (currentPatternChar === "s" && pattern[patternPointer + 1] === "s") {
                        patternPointer++;
                        currentCharIsMatch = true;
                    }
                }
            }
            if (currentCharIsMatch || elem.normalizedLetter === currentPatternChar) {
                if (patternPointer === 0) {
                    currentMatch.isFirstWord = i === 0;
                    currentMatch.isStartOfWord = i === 0 || /\s/.test(this.elements[i - 1].normalizedLetter);
                }
                currentMatch.addElement(elem);
                patternPointer++;
                if (patternPointer === pattern.length) {
                    while (currentMatch.chunks[0].text === "") {
                        currentMatch.chunks.shift();
                    }
                    currentMatch.chunks.reverse();
                    output.push(currentMatch);
                    currentMatch = new SearchStringMatch(true);
                    patternPointer = 0;
                }
            }
            if ((!currentCharIsMatch) && patternPointer > 0) {
                i -= patternPointer;
                index -= currentMatch.chunks
                                     .map(c => c.text.length)
                                     .reduce((s1, s2) => s1 + s2, 0);
                index += this.elements[i].originalLetter.length + this.elements[i].subsequentHtml.length;
                currentMatch = new SearchStringMatch(true);
                patternPointer = 0;
                continue;
            }
            index += elem.originalLetter.length + elem.subsequentHtml.length;
            if (elem.breakSearch && patternPointer > 0) {
                currentMatch = new SearchStringMatch(true);
                patternPointer = 0;
            }
        }
        return output;
    };

    toString = () => this.inputString;

    private _initialize() {
        const noSearchParts: string[] = [];
        const div = document.createElement("div");
        let inputString = this.inputString;
        div.innerHTML = inputString;
        this.inputString = div.innerHTML;
        $(".no-search", div).each((i, elem) => {
            noSearchParts.push(elem.outerHTML);
            elem.parentElement.replaceChild(document.createElement("ns" + i), elem);
        });
        inputString = div.innerHTML;
        this.quickCheckString = div.innerHTML
                                   .replace(SearchString._HtmlTagRegex, "")
                                   .replace(/\s/g, "")
                                   .normalize("NFD")
                                   .replace(/[\u0300-\u036f]/g, "")
                                   .toLowerCase();
        const trailingHtmlMatch = SearchString._TrailingHtmlTagRegex.exec(inputString.normalize("NFD"));
        this.trailingHtml = trailingHtmlMatch
                            ? (trailingHtmlMatch[0] || "")
                                .replace(SearchString._NoSearchRegex, (m) => noSearchParts[parseInt(m[1])])
                            : "";
        inputString = inputString.replace(SearchString._TrailingHtmlTagRegex, "");
        const normalizedString = SearchString.replaceSpecialChars(inputString.normalize("NFD")
                                                                             .replace(SearchString._NextCharWithHtmlTagRegex,
                                                                                      "$1"));
        let normalizedStringPosition = 0;
        let match: RegExpExecArray;
        while ((match = SearchString._NextCharWithHtmlTagRegex.exec(inputString))) {
            let element = {
                normalizedLetter: entities.decode(normalizedString.substr(normalizedStringPosition, (match[1]).length))
                                          .toLowerCase(),
                originalLetter: match[1] + match[2],
                breakSearch: SearchString._NoSearchRegex.test(match[2]),
                subsequentHtml: match[3].replace(SearchString._NoSearchRegex, (m, i) => noSearchParts[parseInt(i)])
            } as SearchStringElement;
            this.elements.push(element);
            normalizedStringPosition += match[1].length;
        }
        this._initialized = true;
    }

}

export class SearchStringMatch {
    html = "";
    text = "";
    chunks = new Array<SearchStringMatchChunk>();
    isFirstWord = false;
    isStartOfWord = false;

    constructor(matchString: string, position?: number);
    constructor(addEmptyChunk: boolean);
    constructor();
    constructor(matchString?: string | boolean, public position = -1) {
        if (typeof matchString === "string") {
            this.addEmptyChunk();
            const regex = /(.)((?:<\/?\w+(?:\s+\w+(?:=(?:"[^"]*"|'[^']*'|[^"' ]+))?)*\s*\/?\s*>)*)/g;
            let match: RegExpExecArray;
            while ((match = regex.exec(matchString))) {
                this.chunks[0].text += match[1];
                this.text += match[1];
                this.html += match[1] + match[2];
                if (match[2]) {
                    this.html += match[2];
                    this.chunks.unshift({text: match[2], isHtmlTag: true});
                    this.addEmptyChunk();
                }
            }
            while (this.chunks[0].text === "") {
                this.chunks.shift();
            }
            this.chunks.reverse();
        } else if (matchString) {
            this.addEmptyChunk();
        }
    }

    addElement(elem: SearchStringElement) {
        this.text += elem.originalLetter;
        this.html += elem.originalLetter + elem.subsequentHtml;
        this.chunks[0].text += elem.originalLetter;
        if (elem.subsequentHtml) {
            this.chunks.unshift({text: elem.subsequentHtml, isHtmlTag: true});
            this.addEmptyChunk();
        }
    }

    addEmptyChunk = (position: "start" | "end" = "start") => {
        if (position === "start") {
            this.chunks.unshift({isHtmlTag: false, text: ""});
        } else {
            this.chunks.push({isHtmlTag: false, text: ""});
        }
    };
}
