import {AfterContentInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
             selector: 'a[rtLoadingAnimation],button[rtLoadingAnimation]'
           })
export class LoadingAnimationDirective implements AfterContentInit {
  @Input()
  loadingText: string;
  @Input()
  preSized: boolean;
  private readonly _elementRef: ElementRef;
  private _isLoading: boolean;
  private _isInitialized = false;
  private _originalContent: string;

  constructor(elementRef: ElementRef) {
    this._elementRef = elementRef;
  }

  @Input()
  set rtLoadingAnimation(value: boolean) {
    this._isLoading = value;
    if (!this._isInitialized)
      return;
    if (value)
      this.activateLoading();
    else
      this.deactivateLoading();
  }

  private get _htmlElement() {
    return this._elementRef.nativeElement as HTMLButtonElement | HTMLAnchorElement;
  }

  ngAfterContentInit(): void {
    const isBlockElement = window.getComputedStyle(this._htmlElement).display === "block";
    const elementHasVariableSize = !this.preSized && !isBlockElement;
    if (elementHasVariableSize)
      this.setMinWidthToFitLoadingContent();
    this._isInitialized = true;
  }

  private setMinWidthToFitLoadingContent() {
    const element = this._htmlElement;
    const widthBeforeLoading = element.offsetWidth;
    element.classList.add("no-transition");
    this.activateLoading();
    const widthWhileLoading = element.offsetWidth;
    element.style.minWidth = Math.max(widthBeforeLoading, widthWhileLoading) + "px";
    this.deactivateLoading();
    window.requestAnimationFrame(() =>element.classList.remove("no-transition"))
  }

  private activateLoading() {
    this._htmlElement.classList.add("loading");
    this._htmlElement.setAttribute("disabled", "disabled");
    this._originalContent = this._htmlElement.innerHTML;
    if (this.loadingText)
      this._htmlElement.innerText = this.loadingText;
  }

  private deactivateLoading() {
    this._htmlElement.removeAttribute("disabled");
    this._htmlElement.classList.remove("loading");
    this._htmlElement.innerHTML = this._originalContent;

  }
}
