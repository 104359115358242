/*!
 * Validation logic used from https://github.com/guillaumepotier/Parsley.js/blob/master/src/parsley/validator_registry.js
 *
 * Parsley.js
 * Version 2.8.1 - built Sat, Feb 3rd 2018, 2:27 pm
 * http://parsleyjs.org
 * Guillaume Potier - <guillaume@wisembly.com>
 * Marc-Andre Lafortune - <petroselinum@marc-andre.ca>
 * @license MIT
 */


import translate = require("../utils/translate");
import detect = require("../utils/detect");
import {
    awaitIfPromise,
    GetAsArray,
    getFormFieldDisplayName,
    ParseNumber,
    ParseValue,
    UnwrapContainerFunction,
    UnwrapFunction
} from "../utils/Utils";
import { ControlDataEvent } from "../libs/ExtendableEvent";
import { EventEmitter } from "../utils/EventEmitter";
import { convertPunyCodeToUnicode } from "../utils/punycode";
import { Popup } from "./Popup";

require("../utils/JqueryLoader");

export class FormValidator implements IValidateable {
    readonly onValidated: EventEmitter<ControlDataEvent<this, boolean>> = new EventEmitter<ControlDataEvent<this, boolean>>(
        "form-validated");
    readonly mutationObserverEnabled: boolean;
    readonly $element: JQuery;
    readonly validationFields: ValidationField[] = [];
    private _mutationObserver: MutationObserver;

    constructor($element: JQuery|HTMLElement, autoDiscoverFields = true) {
        this.$element = $($element);
      this.$element
            .attr("novalidate", "novalidate")
            .typedData("form-validator", this)
            .on("presubmit", (e) => {
                let validationHandler = (async () => {
                    if (!(await this.validate())) {
                        e.preventDefault();
                    }
                });
                e.originalEvent.promise = e.promise instanceof Promise
                    ? Promise.all([e.promise, validationHandler()])
                    : validationHandler();
            });
        if (!autoDiscoverFields) {
            this.mutationObserverEnabled = true;
        } else if (MutationObserver) {
            this._mutationObserver = new MutationObserver(mutations => {
                for (const mutation of mutations) {
                    const $addedNodes = $(mutation.addedNodes)
                        .find("input, textarea, select")
                        .add($(mutation.addedNodes).filter("input, textarea, select"));
                    $addedNodes
                        .filter(":not(:button):not([novalidate]):not([type=hidden])")
                        .each((i, e: HTMLFormField) => {
                            const field = ValidationField.getValidationField(e);
                            if (!this.validationFields.contains(field)) {
                                field.formValidator = this;
                                this.validationFields.push(field);
                            }
                        });
                    const $removedNodes = $(mutation.removedNodes)
                        .find("input, textarea, select")
                        .add($(mutation.removedNodes).filter("input, textarea, select"));
                    $removedNodes
                        .filter(":not(:button):not([type=hidden]):not([novalidate])")
                        .each((i, e: HTMLFormField) => {
                            this.validationFields.remove($(e).typedData("validation-field"));
                        })
                }
            });
            this._mutationObserver.observe(this.$element[0], { childList: true, subtree: true });
            this.mutationObserverEnabled = true;
        }
        this.refreshFieldData();
    }

    private _isValid: boolean | null;

    get isValid() {
        return this._isValid;
    }

    private _validated: boolean;

    get validated() {
        return this._validated;
    }

    disableValidation: FunctionOrValue<boolean> = () => this.$element.hasData("disable-validation");

    async validate(focusFailedField = true): Promise<boolean> {
        if (UnwrapFunction(this.disableValidation)) {
            return;
        }
        let fieldFocused = false;
        if (!this.mutationObserverEnabled) {
            this.refreshFieldData();
        }
        const token = "fV_" + new Date().getTime().toString();
        for (const validationField of this.validationFields) {
            if (!await validationField.validate(token) && !fieldFocused) {
                if (UnwrapFunction(validationField.validateData.focusOnError) !== false) {
                    if (focusFailedField) {
                        validationField.$element.hasData("visual-element")
                        ? $(UnwrapContainerFunction(validationField.$element.typedData("visual-element"))).focus()
                        : validationField.$element.focus();
                    }
                    fieldFocused = true;
                }
            }
        }
        for (const customValidator of this.$element.getCustomValidators()) {
            if (!customValidator()) {
                fieldFocused = true;
            }
        }
        this.onValidated.fire(new ControlDataEvent(this, !fieldFocused), this.$element);
        this._isValid = !fieldFocused;
        this._validated = true;
        return !fieldFocused;
    }

    whenValid(): Promise<void> {
        return new Promise(resolve => {
            const handler = (e: ControlDataEvent<this, boolean>) => {
                if (e.data) {
                    resolve();
                } else {
                    this.onValidated.oneTime(handler);
                }
            };
            this.onValidated.oneTime(handler);
        });
    }

    whenValidated() {
        return new Promise<boolean>(resolve => this.onValidated.oneTime((e) => resolve(e.data)))
    }

    resetValidation(): void {
        this.validationFields.forEach(f => f.resetValidation(true));
        this.$element.removeClass("error")
    }

    protected refreshFieldData() {
        this.validationFields
            .filter(f => !document.contains(f.element))
            .map(f => {
                f.formValidator = null;
                this.validationFields.remove(f);
            });
        this.$element.find("input, textarea, select")
            .filter(":not(:button):not([novalidate]):not([type=hidden])")
            .each((i, e: HTMLFormField) => {
                const field = ValidationField.getValidationField(e);
                field.formValidator = this;
                if (!this.validationFields.contains(field)) {
                    this.validationFields.push(field);
                }
            })
    }
}

interface IValidateable {
    readonly isValid: boolean | null;
    readonly validated: boolean;
    readonly onValidated: EventEmitter<ControlDataEvent<this, boolean>>;

    validate(): Promise<boolean>;

    whenValid(): Promise<void>;

    whenValidated(): Promise<boolean>;

    resetValidation(): void;
}

type createDefaultValidatorOptionsParams<T> = {
    validator: keyof typeof ValidationField.validators,
    defaultMessage?: keyof ITranslation | (() => string),
    defaultMessageParams?: FunctionOrValue<(string | number | Date | boolean)[]>,
    isActive?: (this: ValidationField) => boolean,
    constraints?: (this: ValidationField) => T,
    attribute?: string,
    dataAttribute?: string
};

export class ValidationField implements IValidateable {

    static typeTesters = {
        email: /^((([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/,

        // Follow https://www.w3.org/TR/html5/infrastructure.html#floating-point-numbers
        number: /^-?(\d*\.)?\d+(e[-+]?\d+)?$/i,
        range: /^-?(\d*\.)?\d+(e[-+]?\d+)?$/i,

        integer: /^-?\d+$/,

        digits: /^\d+$/,

        alphanum: /^\w+$/i,

        date: {
            test: (value: string) => ValidationField.parseDate(value) !== null
        },

        url: new RegExp(
            "^" +
            // protocol identifier
            "(?:(?:https?|ftp)://)?" + // ** mod: make scheme optional
            // user:pass authentication
            "(?:\\S+(?::\\S*)?@)?" +
            "(?:" +
            // IP address exclusion
            // private & local networks
            // "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +   // ** mod: allow local networks
            // "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +  // ** mod: allow local networks
            // "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +  // ** mod: allow local networks
            // IP address dotted notation octets
            // excludes loopback network 0.0.0.0
            // excludes reserved space >= 224.0.0.0
            // excludes network & broadcast addresses
            // (first & last IP address of each class)
            "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
            "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
            "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
            "|" +
            // host name
            "(?:(?:[a-zA-Z\\u00a1-\\uffff0-9]-*)*[a-zA-Z\\u00a1-\\uffff0-9]+)" +
            // domain name
            "(?:\\.(?:[a-zA-Z\\u00a1-\\uffff0-9]-*)*[a-zA-Z\\u00a1-\\uffff0-9]+)*" +
            // TLD identifier
            "(?:\\.(?:[a-zA-Z\\u00a1-\\uffff]{2,}))" +
            ")" +
            // port number
            "(?::\\d{2,5})?" +
            // resource path
            "(?:/\\S*)?" +
            "$"
        )
    };
    static validators = {
        required: {
            validateMultiple: (v: string[]) => v.length > 0,
            validateNoValue: () => false,
            priority: 512
        } as IValidator,
        type: {
            validateString: function (value, { type, step }) {
                return ValidationField.typeValidator(value, type, { step })
            },
            priority: 256
        } as IValidator<{ type: keyof typeof ValidationField.typeTesters, step?: number }>,
        pattern: {
            validateString: (v: string, c: RegExp) => c.test(v),
            priority: 64
        } as IValidator<RegExp>,
        minLength: {
            validateString: (value: string, constraint: number) => value.length >= constraint,
            priority: 30
        },
        maxLength: {
            validateString: (value: string, constraint: number) => value.length <= constraint,
            priority: 30
        } as IValidator<number>,
        length: {
            validateString: (value, { min, max }) => value.length >= min && value.length <= max,
            priority: 30
        } as IValidator<{ min: number, max: number }>,
        minValues: {
            validateMultiple: (values: string[], constraint: number) => values.length >= constraint,
            priority: 30
        } as IValidator<number>,
        maxValues: {
            validateMultiple: (values: string[], constraint: number) => values.length <= constraint,
            priority: 30
        } as IValidator<number>,
        values: {
            validateMultiple: (values, { min, max }) => values.length >= min && values.length <= max,
            priority: 30
        } as IValidator<{ min: number, max: number }>,
        min: ValidationField.comparisonOperator((value, constraint: number | Date) => value >= constraint),
        max: ValidationField.comparisonOperator((value, requirement: number | Date) => value <= requirement),
        range: ValidationField.comparisonOperator((value,
            { min, max }: { min: number | Date, max: number | Date }) => value >=
            min &&
            value <=
            max),
        equalTo: {
            validateString(value: string, refOrValue: string) {
                const $elem = $(refOrValue);
                return value === ($elem.length ? $elem.val() : refOrValue);
            },
            priority: 256
        } as IValidator<string>,
        url: ValidationField.createTypeValidator("url"),
        date: ValidationField.createTypeValidator("date"),
        alphanum: ValidationField.createTypeValidator("alphanum"),
        digits: ValidationField.createTypeValidator("digits"),
        integer: ValidationField.createTypeValidator("integer"),
        number: ValidationField.createTypeValidator("number"),
        email: ValidationField.createTypeValidator("email"),
        uniqueMail: {
            validateString: async (value: string, constraint: string) => {
                return !(await $.ajax({
                    url: constraint,
                    async: true,
                    data: { email: value }
                }));
            },
            priority: 30
      } as IValidator<string>,
      asciiCharsMail: {
        validateString: (value: string) => {
          let localPart = value.substring(0, value.indexOf('@'));
          let domainPart = value.substring(value.indexOf('@')+1);
          let nonAsciiPattern = new RegExp('[^\x00-\x7F]+');
          return !nonAsciiPattern.test(localPart) &&
            !nonAsciiPattern.test(convertPunyCodeToUnicode(domainPart));
        },
        priority: 30
      } as IValidator
    };
    protected static readonly validationGroups = {} as IIndexable<ValidationField[]>;
    formValidator: FormValidator;
    public readonly element: HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement;
    public readonly $element: JQuery/*<HTMLSelectElement | HTMLInputElement | HTMLAreaElement>*/;
    public readonly validateData: IValidateData = {
        noValidationMessage: () => this.$element.booleanData(false, "no-validation-message"),
        disableValidation: () => this.$element.parentsAndSelf().filter(function (this: HTMLElement) {
            return $(this).booleanData(false, "disable-validation")
        }).length > 0,
        validateDetachedElements: () => this.formValidator && $.contains(this.formValidator.$element[0], this.element),
        errorMessageElement: () => this.$element.typedData("error-message-element"),
        validators: {
            required: this.createDefaultValidatorOptions({
                validator: "required",
                defaultMessage: "validation_required",
                defaultMessageParams: () => [getFormFieldDisplayName(
                    this.$element)],
                attribute: "required",
                constraints: null
            }),
            pattern: this.createDefaultValidatorOptions({
                validator: "pattern",
                attribute: "pattern",
                constraints: () => new RegExp(this.$element.attr("pattern"))
            }),
            minLength: this.createDefaultValidatorOptions({
                validator: "minLength",
                defaultMessage: "validation_minlength",
                defaultMessageParams: () => [this.$element.attr(
                    "minlength")],
                attribute: "minlength"
            }),
            maxLength: this.createDefaultValidatorOptions({
                validator: "maxLength",
                defaultMessage: "validation_maxlength",
                defaultMessageParams: () => [getFormFieldDisplayName(
                    this.$element), this.$element.attr("maxlength")],
                attribute: "maxlength"
            }),
            length: this.createDefaultValidatorOptions({
                validator: "length",
                isActive: () => this.$element.booleanAttr("maxlength") &&
                    this.$element.booleanAttr("minlength"),
                constraints: () => {
                    return {
                        min: parseInt(this.$element.attr("minlength")),
                        max: parseInt(this.$element.attr("maxlength"))
                    }
                }
            }),
            minValues: this.createDefaultValidatorOptions({ validator: "minValues" }),
            maxValues: this.createDefaultValidatorOptions({ validator: "maxValues" }),
            values: this.createDefaultValidatorOptions({ validator: "values" }),
            min: this.createDefaultValidatorOptions({
                validator: "min",
                attribute: "min",
                defaultMessage: "validation_min",
                defaultMessageParams: () => [getFormFieldDisplayName(this.$element),
                ParseValue(this.$element.attr("min")) as (number | Date)]
            }),
            max: this.createDefaultValidatorOptions({
                validator: "max",
                attribute: "max",
                defaultMessageParams: () => [getFormFieldDisplayName(this.$element),
                ParseValue(this.$element.attr("max")) as (number | Date)]
            }),
            equalTo: this.createDefaultValidatorOptions({ validator: "equalTo" }),
            uniqueMail: this.createDefaultValidatorOptions({
                validator: "uniqueMail",
                constraints: () => this.$element.typedData(
                    "validate-unique-mail")
            }),
            asciiCharsMail: this.createDefaultValidatorOptions({
                validator: "asciiCharsMail",
                defaultMessage: () => {
                  return translate("validation_ascii_email", []);
              }
            }),
            type: this.createDefaultValidatorOptions({
                validator: "type",
                isActive: () => this.$element.hasAttr("type") &&
                    ValidationField.typeTesters.hasOwnProperty(this.$element.attr(
                        "type")),
                constraints: () => {
                    return {
                        type: this.$element.attr("type"),
                        step: parseFloat(this.$element.attr("step")),
                        min: parseFloat(this.$element.attr("min")),
                        max: parseFloat(this.$element.attr("max"))
                    }
                },
                defaultMessage: () => {
                    const type = this.$element.attr("type");
                    switch (type) {
                        case "email":
                            return translate("validation_email", []);
                    }
                    return "";
                }
            })
        }
    };
    readonly onValidated = new EventEmitter<ControlDataEvent<this, boolean>>("field-validated");
    protected validationTokens = new Array<string>();
    private currentToken: string;
    private $errorMessageElement: JQuery;
    private lastFailedValidator: ValidatorOptions;

    constructor(element: HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement, options?: IValidateData) {
        this.element = element;
        this.$element = $(element);
        this.$element.typedData("validation-field", this);
        if (this.$element.booleanData("self-validated")) {
            return;
        }
        if (this.getErrorClassElement().hasClass("error"))
            this._isValid = false;
        this.validateData.originVal = this.$element.typedData("original-val");
        $.extend<IValidateData>(this.validateData,
            { liveValidation: this.$element.booleanData(false, "live-validation") },
            options);
        let blurHandlerTimeout: number = null;
        const blurHandler = async () => {
            if (this.$element.hasAttr("autofocus")) {
                this.$element.removeAttr("autofocus");
                return;
            }
            const previousState = this.isValid !== false;
            if (previousState !== await this.validate()) {
                if (blurHandlerTimeout)
                    window.clearTimeout(blurHandlerTimeout)
                blurHandlerTimeout = window.setTimeout(() => Popup.OpenPopups.map(p => p.reposition(true)), 100);
            }
        };
        if (this.element.name) {
            $(document.getElementsByName(this.element.name)).add(this.element).on("blur closed", blurHandler);
        } else {
            this.$element.on("blur closed", blurHandler);
        }
        if (this.$element.is(":radio,:checkbox") || this.validateData.liveValidation) {
            this.enableLiveValidation();
        }
        if (!this.validateData.validationGroup) {
            this.validateData.validationGroup = this.$element.typedData("validation-group");
        }
        if (this.validateData.validationGroup) {
            if (!ValidationField.validationGroups.hasOwnProperty(this.validateData.validationGroup)) {
                ValidationField.validationGroups[this.validateData.validationGroup] = [];
            }
            ValidationField.validationGroups[this.validateData.validationGroup].push(this);
        }
    }

    private _liveValidationActive = false;

    get liveValidationActive() {
        return this._liveValidationActive;
    }

    private _isValid: boolean = null;

    get isValid() {
        return this._isValid;
    };

    private _validated = false;

    get validated() {
        return this._validated;
    }

    static parseDate(string: string) {
        let parsed = string.match(/^(\d{4,})-(\d\d)-(\d\d)$/);
        if (!parsed) {
            return null;
        }
        let [_, year, month, day] = parsed.map(x => parseInt(x, 10));
        let date = new Date(year, month - 1, day);
        if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
            return null;
        }
        return date;
    }

    static typeValidator(value: string, type: keyof typeof ValidationField.typeTesters, { step }: { step?: number }) {
        const tester = this.typeTesters[type];
        if (!tester) {
            throw new Error("validator type `" + type + "` is not supported");
        }
        if (!tester.test(value)) {
            return false;
        }
        if ("number" === type) {
            if (step !== undefined) {
                const numberValue = Number(value);
                const decimals = this.decimalPlaces(step);
                if (this.decimalPlaces(numberValue) > decimals) // Value can't have too many decimals
                {
                    return false;
                }
                // Be careful of rounding errors by using integers.
                if (Math.round(numberValue, step) !== numberValue) {
                    return false;
                }
            }
        }
        return true;
    }

    static getValidationField(element: HTMLFormField): ValidationField {
        const $element = $(element);
        if ($element.hasData("validation-field")) {
            return $element.typedData("validation-field");
        }
        return new ValidationField(element);
    }

    private static createTypeValidator(type: keyof typeof ValidationField.typeTesters): IValidator {
        return {
            validateString(value: string) {
                return ValidationField.typeValidator(value, type, {})
            }, priority: 256
        }
    }

    private static comparisonOperator<T>(operator: (value: number | Date, constraint: T) => boolean): IValidator<T> {
        return {
            validateDate: operator,
            validateNumber: operator,
            priority: 30
        }
    }

    // See http://stackoverflow.com/a/10454560/8279
    private static decimalPlaces(num: number) {
        const match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if (!match) {
            return 0;
        }
        return Math.max(
            0,
            // Number of digits right of decimal point.
            (match[1] ? match[1].length : 0) -
            // Adjust for scientific notation.
            (match[2] ? +match[2] : 0));
    };

    async validate(token?: string): Promise<boolean> {
        if (this.$element.booleanData("self-validated")) {
            this._isValid = true;
            return true;
        }
        if (token) {
            if (this.validationTokens.contains(token)) {
                if (this.currentToken)
                    return this.whenValidated();
                return this.isValid;
            }
            this.validationTokens.push(token);
        } else {
            token = new Date().getTime().toString();
            this.validationTokens.push(token);
        }
        this.currentToken = token;
        if (UnwrapFunction(this.validateData.disableValidation) ||
            (this.formValidator && UnwrapFunction(this.formValidator.disableValidation))) {
            this.resetValidation();
            this.currentToken = null;
            this._isValid = true;
            return true;
        }
        if (this.$element.is(":disabled") ||
            (!this.$element.isAttached() && !UnwrapFunction(this.validateData.validateDetachedElements))) {
            this.currentToken = null;
            this._isValid = true;
            return true;
        }
        const previousResult = this._isValid;
        this._isValid = null;
        const loadingClassTimeout = window.setTimeout(() => this.getErrorClassElement().addClass("loading"), 50);
        let { errorMessage, multipleFail, multiValid, failedValidator } = await this.runValidators();
        window.clearTimeout(loadingClassTimeout);
        if (this.currentToken !== token) {
            if (typeof this._isValid === "boolean") {
                this.getErrorClassElement().removeClass("loading");
                return this.isValid;
            } else {
                return await this.whenValidated();
            }
        }
        this.getErrorClassElement().removeClass("loading");
        this.enableLiveValidation();
        this._validated = true;
        if (errorMessage === undefined) {
            this.lastFailedValidator = null;
            this._isValid = true;
            if (previousResult === true) {
                this.currentToken = null;
                return true;
            }
            this.resetValidation(multiValid);
            this.onValidated.fire(new ControlDataEvent(this, true), this.$element);
            if (this.validateData.validationGroup) {
                return this.validateGroup(token).then((valid) => {
                    this.currentToken = null;
                    return valid;
                });
            }
            if (this.validateData.dependentFields)
                for (let dependentField of this.validateData.dependentFields) {
                    if (!(dependentField instanceof ValidationField))
                        dependentField = ValidationField.getValidationField(dependentField);
                    if (dependentField.isValid !== false)
                        continue;
                    // noinspection JSIgnoredPromiseFromCall
                    dependentField.validate(this.currentToken);
                }
            this.currentToken = null;
            return true;
        }
        if (this.$element[0] === document.activeElement && failedValidator !== this.lastFailedValidator) {
            this.resetValidation(multiValid);
            this.lastFailedValidator = null;
            this._isValid = true;
        } else {
            this._isValid = false;
            this.setInvalid(multipleFail, errorMessage);
            this.lastFailedValidator = failedValidator;
        }
        this.onValidated.fire(new ControlDataEvent(this, false), this.$element);
        if (this.validateData.validationGroup) {
            const validationGroupValid = await this.validateGroup(token);
            if (this.validateData.dependentFields)
                for (let dependentField of this.validateData.dependentFields) {
                    if (!(dependentField instanceof ValidationField))
                        dependentField = ValidationField.getValidationField(dependentField);
                    // noinspection JSIgnoredPromiseFromCall
                    dependentField.validate(token);
                }
            this.currentToken = null;
            return this._isValid && validationGroupValid;
        }
        this.currentToken = null;
        return false;
    }

    public enableLiveValidation() {
        if (!this._liveValidationActive) {
            this._liveValidationActive = true;
            $(document.getElementsByName(this.element.name))
                .add(this.$element)
                .on("change input selection-changed", () => {
                    if (this.$element.is(":checkbox, :radio") ||
                        this.getErrorClassElement().is(".error, .error-group")) {
                        this.validate();
                    }
                });
        }
    }

    whenValid(): Promise<void> {
        return new Promise(resolve => {
            const handler = (e: ControlDataEvent<this, boolean>) => {
                if (e.data) {
                    resolve();
                } else {
                    this.onValidated.oneTime(handler);
                }
            };
            this.onValidated.oneTime(handler);
        });
    }

    whenValidated(): Promise<boolean> {
        return new Promise(resolve => this.onValidated.oneTime((e) => resolve(e.data)))
    }

    resetValidation(removeGroupError = false): void {
        this.currentToken = null;
        this._isValid = true;
        if (this.$errorMessageElement) {
            this.$errorMessageElement.hide();
        }
        if (removeGroupError) {
            $(`[name='${this.$element.attr("name")}']`, this.getFormValidatorElement()).each((i, e) => {
                let validationField = ($(e).typedData("validation-field") as ValidationField);
                if (!validationField) {
                    return;
                }
                validationField.getErrorClassElement().removeClass("error-group")
            });
        }
        this.getErrorClassElement().removeOverwrittenTitle();
        this.getErrorClassElement().removeClass("error loading");
        this.onValidated.fire(new ControlDataEvent(this, null), this.$element);
    }

    public setInvalid(multipleFail: boolean, errorMessage: string) {
        if (multipleFail && this.$element.hasAttr("name")) {
            let sameNameElements = $(`[name='${this.$element.attr("name")}']:not(:disabled)`,
                this.getFormValidatorElement());
            if (sameNameElements.length === 1) {
                this.getErrorClassElement().addClass("error");
            } else {
                sameNameElements.each((i, e) => {
                    let validationField = ($(e).typedData("validation-field") as ValidationField);
                    if (!validationField) {
                        return;
                    }
                    validationField.getErrorClassElement().addClass("error-group")
                });
            }
        } else {
            this.getErrorClassElement().addClass("error");
        }
        if (errorMessage) {
            if (UnwrapFunction(this.validateData.noValidationMessage)) {
                this.getErrorClassElement().overwriteTitle(errorMessage);
            } else {
                this.getErrorMessageElement().text(errorMessage);
            }
        } else {
            this.getErrorMessageElement().hide();
            this.getErrorClassElement().removeOverwrittenTitle();
        }
    }

    public getErrorMessageElement(): JQuery {
        if (this.$errorMessageElement) {
            return this.$errorMessageElement.show();
        }
        if (UnwrapContainerFunction(this.validateData.errorMessageElement)) {
            return this.$errorMessageElement = $(UnwrapContainerFunction(this.validateData.errorMessageElement)).show();
        }
        let $errorMessageParent = this.$element.parent();
        if (this.$element.hasData("visual-element")) {
            $errorMessageParent = $(UnwrapContainerFunction(this.$element.typedData("visual-element"))).parent();
        }
        // this.errorMessageElement = $(document.createElement("span")).addClass("error-message");
        // let errorMessageParent = Utils.UnwrapFunction(this.validateData.errorMessageParent);
        // if (errorMessageParent !== undefined) {
        //     if (errorMessageParent === "grandparent")
        //         this.$element.parent().parent().append(this.errorMessageElement);
        //     else if (errorMessageParent === "parent")
        //         this.$element.parent().append(this.errorMessageElement);
        //     else if (typeof errorMessageParent === "string")
        //         $(errorMessageParent).append(this.errorMessageElement);
        //     else
        //         errorMessageParent.append(this.errorMessageElement);
        // } else {
        if ($errorMessageParent.parents(".form-group:first").length) {
            $errorMessageParent = $errorMessageParent.parents(".form-group:first");
        } else {
            while ($errorMessageParent.is(
                ".popup-select-list, .input-group, .button-toggle, .textarea-wrapper")) {
                $errorMessageParent = $errorMessageParent.parent();
            }
        }
        if ($errorMessageParent.length) {
            this.$errorMessageElement = $("<span class='error-message'></span>");
            $errorMessageParent.append(this.$errorMessageElement);
            return this.$errorMessageElement;
        }
        return null;
    }

    protected getFormValidatorElement() {
        if (this.formValidator) {
            return this.formValidator.$element;
        }
        return undefined;
    }

    protected async validateGroup(token: string) {
        let promises = await Promise.all(ValidationField.validationGroups[this.validateData.validationGroup]
            .filter(v => v != this && v.liveValidationActive && v.isValid === false)
            .map(v => v.validate(token)));
        return promises.concat([this.isValid]).reduce((b1, b2) => b1 && b2);
    }

    private async runValidators() {
        let errorMessage: string;
        let multipleFail: boolean;
        let multiValid = false;
        let failedValidator: ValidatorOptions;
        let values: string[] = GetAsArray(this.$element.filter(":not(:disabled)")
            .filter(":checked, :not([type=checkbox], [type=radio])")
            .val()).filter(v => !!v);
        if ((detect.browser.ie || detect.browser.safari) && this.$element.attr("type") === "date") {
            values = values.filter(v => v.toLowerCase() !== "invalid date");
        }
        if (this.element.hasAttribute("name")) {
            $(`[name='${this.$element.attr("name")}']:not(:disabled)`, this.getFormValidatorElement())
                .filter(":checked, :not([type=checkbox], [type=radio])")
                .each((i, e) => {
                    if (e === this.element) {
                        return;
                    }
                    values.push(...GetAsArray($(e).val()).filter(v => v !== undefined && v !== null))
                });
        }
        const typedValues: {
            numberValue?: number,
            arrayValue?: string[],
            dateValue?: Date
        } = {};
        let activeValidators = this.getActiveValidators();
        if (this.validateData.originVal && values.length === 1 && this.validateData.originVal === values[0]) {
            activeValidators.clear();
        }
        for (const validatorOption of activeValidators) {
            const constraints = validatorOption.constraints ? UnwrapFunction(validatorOption.constraints) : null;
            const validator = validatorOption.validator;
            errorMessage = UnwrapFunction(validatorOption.errorMessage) || "";
            failedValidator = validatorOption;
            if (typeof validator === "function") {
                multipleFail = false;
                if (!await awaitIfPromise(validator(values[0], constraints))) {
                    break;
                }
            } else {
                multipleFail = !!validator.validateMultiple;
                if (values.length) {
                    if (validator.validateDate) {
                        if (typedValues.dateValue === undefined) {
                            typedValues.dateValue = ValidationField.parseDate(values[0]);
                        }
                        if (typedValues.dateValue !== null &&
                            !await awaitIfPromise(validator.validateDate(typedValues.dateValue, constraints))) {
                            break;
                        }
                    }
                    if (validator.validateNumber) {
                        if (typedValues.numberValue === undefined) {
                            typedValues.numberValue = ParseNumber(values[0]);
                        }
                        if (!isNaN(typedValues.numberValue) &&
                            !await awaitIfPromise(validator.validateNumber(typedValues.numberValue, constraints))) {
                            break;
                        }
                    }
                    if (validator.validateString) {
                        if (!await awaitIfPromise(validator.validateString(values[0], constraints))) {
                            break;
                        }
                    }
                    if (validator.validateMultiple) {
                        if (!await awaitIfPromise(validator.validateMultiple(values, constraints))) {
                            break;
                        }
                        multiValid = true;
                    }
                } else if (validator.validateNoValue && !await awaitIfPromise(validator.validateNoValue(constraints))) {
                    break;
                }
            }
            errorMessage = undefined;
            failedValidator = undefined;
            multipleFail = false;
        }
        return { errorMessage, multipleFail, multiValid, failedValidator };
    }

    private getActiveValidators(): ValidatorOptions[] {
        if (this.$element[0] === document.activeElement) {
            if (this.lastFailedValidator && this.lastFailedValidator.isActive) {
                return [this.lastFailedValidator];
            }
            return [];
        } else {
            const output = [];
            for (let prop in this.validateData.validators) {
                if (!this.validateData.validators.hasOwnProperty(prop)) {
                    continue;
                }
                let validator = this.validateData.validators[prop];
                if (!UnwrapFunction(validator.isActive)) {
                    continue;
                }
                if (typeof validator.validator === "function") {
                    output.push({ validator: validator, priority: 0 });
                } else {
                    output.push({ validator: validator, priority: validator.validator.priority || 0 })
                }
            }
            return output
                .sort((a, b) => (a.priority || 0) - (b.priority || 0))
                .map(v => v.validator);
        }
    }

    private getErrorClassElement(): JQuery {
        let $errorElement = this.$element;
        if ($errorElement.hasData("visual-element")) {
            $errorElement = $(UnwrapContainerFunction(this.$element.typedData("visual-element")));
        }
        if ($errorElement.parent().is(".input-group, .button-toggle-large, .button-toggle, .textarea-wrapper")) {
            $errorElement = $errorElement.parent();
        }
        return $errorElement;
    }

    private createDefaultValidatorOptions<T = null>(parameters: createDefaultValidatorOptionsParams<T>): ValidatorOptions<T> {
        let { validator, defaultMessage, defaultMessageParams, isActive, constraints, attribute, dataAttribute } = parameters;
        const validatorAttributeName = attribute || dataAttribute || "data-validate-" + validator.toKebabCase();
        if (!isActive) {
            if (ValidationField.typeTesters.hasOwnProperty(validator)) {
                isActive =
                    () => this.$element.attr("type") === validator ||
                        this.$element.booleanAttr(validatorAttributeName)
            } else {
                isActive = () => this.$element.booleanAttr(validatorAttributeName)
            }
        }
        if (constraints === undefined) {
            constraints = () => ParseValue(this.$element.attr(validatorAttributeName)) as any;
        }
        return {
            constraints: constraints,
            errorMessage:
                () => {
                    let message: string;
                    if (validatorAttributeName.startsWith("data-")) {
                        message = this.$element.attr(`${validatorAttributeName}-message`);
                    } else {
                        message = this.$element.attr(`data-validate-${validatorAttributeName}-message`);
                    }
                    if (!message && constraints === null) {
                        message = (this.$element[0] as HTMLElement).getAttribute(`${validatorAttributeName}`);
                    }
                    if (typeof message === "string" && message && message !== validatorAttributeName) {
                        return message.format.apply(message, UnwrapFunction(defaultMessageParams) || []);
                    }
                    if (typeof defaultMessage === "function") {
                        return defaultMessage.apply(this, UnwrapFunction(defaultMessageParams) || []);
                    }
                    if (!defaultMessage) {
                        return "";
                    }
                    return translate(defaultMessage as string, UnwrapFunction(defaultMessageParams));
                },
            isActive: () => isActive.call(this),
            validator: ValidationField.validators[validator] as IValidator<T>
        }
    }
}

export interface IValidateData {
    validators: IValidators
    originVal?: string;
    noValidationMessage?: FunctionOrValue<boolean>;
    disableValidation?: FunctionOrValue<boolean>;
    liveValidation?: boolean;
    validateDetachedElements?: FunctionOrValue<boolean>;
    validationGroup?: string;
    // errorClassElement: FunctionOrValue<"parent" | "grandparent" | "this" | string | JQuery>;
    errorMessageElement?: ContainerType;
    // errorPlaceMode: FunctionOrValue<"append" | "after">;
    dependentFields?: (HTMLFormField | ValidationField)[];
    focusOnError?: FunctionOrValue<boolean>;
}

interface IValidators extends IIndexable<ValidatorOptions<any>> {
    required?: ValidatorOptions;
    /**
     * Constraints = steps
     */
    number?: ValidatorOptions<number>;
    digits?: ValidatorOptions;
    phone?: ValidatorOptions;
    minLength?: ValidatorOptions<number>;
    maxLength?: ValidatorOptions<number>;
    length?: ValidatorOptions<{ min: number, max: number }>
    email?: ValidatorOptions;
    postcode?: ValidatorOptions;
    equalTo?: ValidatorOptions<string | HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement | JQuery>;
    pattern?: ValidatorOptions<string | RegExp>;
    uniqueMail?: ValidatorOptions<string>;
    asciiCharsMail?: ValidatorOptions<string>;
    range?: ValidatorOptions<{ min: number, max: number }>
    min?: ValidatorOptions<number>;
    max?: ValidatorOptions<number>;
    letters?: ValidatorOptions;
    alphanum?: ValidatorOptions;
    minValues?: ValidatorOptions<number>;
    maxValues?: ValidatorOptions<number>;
    values?: ValidatorOptions<{ min: number, max: number }>;
    type?: ValidatorOptions<{ type: string, step?: number }>;
}

export type ValidatorOptions<T = null> =
    {
        isActive?: FunctionOrValue<boolean>,
        errorMessage?: FunctionOrValue<string>,
        constraints?: FunctionOrValue<T>,
        validator: IValidator<T> | ((value: string, constraints?: T) => boolean | Promise<boolean>),
    };

export interface IValidator<T = null> {
    priority?: number;
    validateString?: (value: string, constraint?: T) => boolean | Promise<boolean>,
    validateNumber?: (value: number, constraint?: T) => boolean | Promise<boolean>,
    validateDate?: (value: Date, constraint?: T) => boolean | Promise<boolean>,
    validateMultiple?: (value: string[], constraint?: T) => boolean | Promise<boolean>,
    validateNoValue?: (constraint?: T) => boolean | Promise<boolean>,
}

declare global {
    interface JQuery {
        validate(): JQuery;

        validate(convertHtmlValidation: boolean): JQuery;

        addCustomValidator(validator: () => boolean): JQuery;

        getCustomValidators(): (() => boolean)[];
    }
}

jQuery.fn.validate = function (this: JQuery) {
    this.each(function (this: HTMLElement) {
        const $elem = $(this);
        if ($elem.hasData("form-validator")) {
            return;
        }
        new FormValidator($elem)
    });

    return this;
};
const customValidators = "custom-validators";
jQuery.fn.addCustomValidator = function (this: JQuery, validator: () => boolean) {
    this.each((index, element) => {
        const $element = $(element);
        if (!($element.data(customValidators) instanceof Array)) {
            $element.data(customValidators, []);
        }
        $element.data(customValidators).push(validator);
    });
    return this;
};

jQuery.fn.getCustomValidators = function (this: JQuery) {
    return this.data(customValidators) || [];
};

