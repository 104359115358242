import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EntitySetPermissions} from "@api";
import {Observable} from "rxjs";
import {UserRightsStore} from "@stores";

@Component({
  selector: 'rt-user-entity-set-permission-edit',
  templateUrl: './user-entity-set-permission-edit.component.html',
  styleUrls: ['./user-entity-set-permission-edit.component.scss'],
  host: {
    class: "entity"
  }
})
export class UserEntitySetPermissionEditComponent {

  @Output()
  readonly deleteRequested= new EventEmitter<void>();

  @Input()
  entitySetPermissions: EntitySetPermissions;
  readonly loggedInAsSuperUser$: Observable<boolean>;

  constructor(userRightsStore: UserRightsStore) {
    this.loggedInAsSuperUser$ = userRightsStore.observe("isSuperUser");
  }

  requestDelete(): void {
    this.deleteRequested.emit();
  }
}
