import {Directive, ElementRef, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {IPopupOptions, Popup} from "@widgets/Popup";
import {Dropdown, IDropdownOptions} from "@widgets/Dropdown";

@Directive({
             selector: '[rtDropdown]',
  exportAs:"dropdown"
           })
export class DropdownDirective implements OnInit, OnDestroy {

  @Input("dropdownTrigger")
  trigger: HTMLElement

  @Input("dropdownOptions")
  settings: IDropdownOptions

  private readonly _elementRef: ElementRef;
  private _dropdown: Dropdown;

  constructor(elementRef: ElementRef) {
    this._elementRef = elementRef;
  }

  open(): void {
    this._dropdown.open();
  }

  close(): void {
    this._dropdown.close();
  }

  ngOnInit(): void {
    this._dropdown = new Dropdown(this._elementRef.nativeElement, this.trigger, this.settings)
  }

  ngOnDestroy(): void {
    this._dropdown.close();
  }

}
