export function createDeepCopy<T>(object: T): T {
    return createDeepCopyInternal(object, new WeakMap());
}


function createDeepCopyInternal<T>(object: T, references: WeakMap<{}, {}>): T {
  if (!object || typeof object !== "object")
    return object;
  if (references.has(object)) {
    return references.get(object) as T;
  }
  let copy: any;
  if (object instanceof Date)
    copy = new Date(object.getTime());
  else if (object instanceof Array)
    copy = object.map(e => createDeepCopyInternal(e, references));
  else {
    copy = Object.create(object as unknown as object);
    for (const key in object) {
      if (!object.hasOwnProperty(key))
        continue;
      copy[key] = createDeepCopyInternal(object[key], references);
    }
  }
  references.set(object, copy);
  return copy;
}
