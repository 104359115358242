import {Injectable} from "@angular/core";
import {EntityStore} from "@stores/entity-store.service";
import {EntityApi, OrganizationApi} from "../api/Apis";
import {RoutingService} from "@app/services/routing.service";
import {AlertService} from "./alert.service";
import {AccountStore, UserRightsStore} from "@stores";
import {debounceTime, filter, skip} from "rxjs/operators";
import {TypeFilter} from "../utils/rxjs-extensions";
import {EntityEditViewData, EntityListViewData} from "@view-data";
import {Entity, EntityOrganization, EntitySaveRequest, UserReference} from "@api";
import {ExternalSearchResult} from "@widgets/SelectList";
import {not} from "@utils/rxjs-extensions/not";

@Injectable({
              providedIn: 'root'
            })
export class EntityService {
  readonly entityStore: EntityStore;
  private readonly _entityApi: EntityApi;
  private readonly _routingService: RoutingService;

  private readonly _alertService: AlertService;

  private readonly _organizationApi: OrganizationApi;

  constructor(entityApi: EntityApi,
              entityStore: EntityStore,
              userRightsStore: UserRightsStore,
              accountStore: AccountStore,
              routingService: RoutingService,
              alertService: AlertService,
              organizationApi: OrganizationApi) {
    this._organizationApi = organizationApi;
    this._alertService = alertService;
    this._routingService = routingService;
    this.entityStore = entityStore;
    this._entityApi = entityApi

    routingService
      .routingStore
      .observe("currentViewData")
      .pipe(filter(TypeFilter(EntityEditViewData)))
      .subscribe((v: EntityEditViewData) => {
        void this._loadEntity(v)
      })

    routingService
      .routingStore
      .observe("currentViewData")
      .pipe(filter(not(TypeFilter(EntityEditViewData))))
      .subscribe(() => {
        this.entityStore.update("currentEntity", null);
      })

    entityStore
      .observe("currentSearch")
      .pipe(
        skip(1),
        filter(() => userRightsStore.get("isAuthenticated")),
        debounceTime(100)
      )
      .subscribe(s => {
        if (routingService.getCurrentViewData() instanceof EntityListViewData)
          void this._search(s);
        else
          this.entityStore.update("entityList", null)
      });
    userRightsStore
      .observe("isAuthenticated")
      .pipe(filter(u => u === false))
      .subscribe(() => this.entityStore.reset());
  }

  static convertOrganizationToJsonData(organizations: EntityOrganization[], selected: boolean): JSONDataContainer<EntityOrganization>[] {
    if (!organizations)
      return [];
    return organizations
      .map(o => {
        return {
          value: o.id,
          data: o,
          selected: selected,
          html:
            `<div class="li-org">
	<div>${o.name || ""}</div>
	<div>
		<span class="org-code">${o.code || ""}</span>
		${o.linkedEntityRefs?.any()
      ? `<span class="mat-icon-link text-hide" title="${o.linkedEntityRefs.map(e => e.name).join("\n")}">Link</span>`
      : ""
            }
		<span class="label" style="position: relative; float: right">${o.system || ""}</span>
	</div>
</div>`
        }
      })
  }




  async searchOrganizations(search: string, entityId: string, entitySetId:string): Promise<ExternalSearchResult<JSONDataContainer<EntityOrganization>>> {
    const organizations = await this._organizationApi.searchOrganizations(search, entityId || null,entitySetId || null).toPromise();
    const results: JSONDataContainer<UserReference>[] = EntityService.convertOrganizationToJsonData(organizations, false);
    return {results}
  }

  get(id: string): Promise<Entity | null> {
    return this._entityApi.entityDetail(id).toPromise();
  }

  async startSearch(search: string): Promise<void> {
    this.entityStore.update(
      "currentSearch", search);
    await this._search(search)
  }

  loadMore(): void {
    this.entityStore.update(s => {
      return {
        searchResultDisplayCount: Math.min(s.searchResultDisplayCount + 24, s.entityList.length)
      }
    });
  }

  async delete(entity: Entity): Promise<void> {
    try {
      await this._entityApi.deleteEntity(entity.id).toPromise()
      this._alertService.openDefaultSuccessAlert(`Entity ${entity.name} was deleted.`);
      this.entityStore.update("entityList", null)
      this._routingService.navigateTo(new EntityListViewData());
    } catch (e) {
      console.error(e)
    }
  }

  async create(entity: EntitySaveRequest): Promise<void> {
    try {
      const savedEntity = await this._entityApi.createEntity(entity).toPromise()
      this._alertService.openDefaultSuccessAlert(`Entity ${entity.name} created.`, false);
      this.entityStore.update({
                                entityList: null,
                                currentEntity: savedEntity,
                              })
      this._routingService.navigateTo(new EntityEditViewData(savedEntity.id));
    } catch (e) {
      console.error(e)
    }

  }

  async update(id:string, entity: EntitySaveRequest): Promise<void> {
    try {
      const savedEntity = await this._entityApi.updateEntity(id, entity).toPromise()
      this._alertService.openDefaultSuccessAlert(`Entity ${entity.name} saved.`);
      this.entityStore.update({
                                entityList: null,
                                currentEntity: savedEntity,
                              })
    } catch (e) {
      console.error(e)
    }
  }

  async loadEntity(id: string): Promise<void> {
    await this._loadEntity(id);
  }

  generateQuickTrackKey(entitySetId: string): Promise<string> {
    return this._entityApi.generateQuickTrackEntityKey(entitySetId).toPromise();
  }

async searchParentEntities(search: string): Promise<ExternalSearchResult> {
    const userReferences = await this._entityApi.searchParentEntities(search).toPromise();
    return {
      results: userReferences.map(e => {
        return {
          label: e.name,
          value: e.id
        }
      })
    }
  }

  async searchParentsForEntity(search: string, entitySetId: string): Promise<ExternalSearchResult> {
    const userReferences = await this._entityApi.searchParentsForEntity(search, entitySetId).toPromise();
    return {
      results: userReferences.map(e => {
        return {
          label: e.name,
          value: e.id
        }
      })
    }
  }

  private async _search(search: string): Promise<void> {
    this.entityStore.update("currentEntity", null);
    const result = await this._entityApi.searchEntities(search).toPromise();
    this.entityStore.update({
                              entityList: result,
                              searchResultDisplayCount: Math.min(result.length, 24)
                            });
  }

  async notifyBookingRecipients(entityid:string): Promise<boolean> {
    return await this._entityApi.notifyBookingRecipients(entityid).toPromise();
  }

  private async _loadEntity(id: string): Promise<void>;

  private async _loadEntity(viewData: EntityEditViewData): Promise<void>;

  private async _loadEntity(viewData: EntityEditViewData | string): Promise<void> {
    if (viewData instanceof EntityEditViewData && viewData.isNewEntity()) {
      this.entityStore.update("currentEntity", {
        name: "",
        globalAccountManagers: []
      });
      return;
    }
    const id = viewData instanceof EntityEditViewData
               ? viewData.entityId
               : viewData;
    const entityPreloaded = this.entityStore.get("currentEntity")?.id === id;
    if (entityPreloaded)
      return;
    this.entityStore.update("currentEntity", null);
    const entity = await this.get(id);
    this.entityStore.update("currentEntity", entity);
  }
}
