// Converts to and from puny-code used in IDN
class PunyCodeEncoder {
  //Default parameters
  private readonly initial_n = 0x80;
  private readonly initial_bias = 72;
  private readonly delimiter = "\x2D";
  private readonly base = 36;
  private readonly damp = 700;
  private readonly tmin = 1;
  private readonly tmax = 26;
  private readonly skew = 38;
  private readonly maxint = 0x7FFFFFFF;

  // decode_digit(cp) returns the numeric value of a basic code 
  // point (for use in representing integers) in the range 0 to
  // base-1, or base if cp is does not represent a value.
  private decode_digit(cp:number) {
    return cp - 48 < 10 ? cp - 22 : cp - 65 < 26 ? cp - 65 : cp - 97 < 26 ? cp - 97 : this.base;
  }

  // encode_digit(d,flag) returns the basic code point whose value
  // (when used for representing integers) is d, which needs to be in
  // the range 0 to base-1. The lowercase form is used unless flag is
  // nonzero, in which case the uppercase form is used. The behavior
  // is undefined if flag is nonzero and digit d has no uppercase form. 
  private encode_digit(d: number, flag: number) {
    return d + 22 + 75 * ((d < 26)? 1 : 0) - ((!flag? 1:0) << 5);
    //  0..25 map to ASCII a..z or A..Z 
    // 26..35 map to ASCII 0..9
  }

  //** Bias adaptation function **
  private adapt(delta: number, numpoints:number, firsttime: boolean) {
    let k = 0;
    delta = firsttime ? Math.floor(delta / this.damp) : (delta >> 1);
    delta += Math.floor(delta / numpoints);

    for (k = 0; delta > (((this.base - this.tmin) * this.tmax) >> 1); k += this.base) {
      delta = Math.floor(delta / (this.base - this.tmin));
    }
    return Math.floor(k + (this.base - this.tmin + 1) * delta / (delta + this.skew));
  }

  // encode_basic(bcp,flag) forces a basic code point to lowercase if flag is zero,
  // uppercase if flag is nonzero, and returns the resulting code point.
  // The code point is unchanged if it is caseless.
  // The behavior is undefined if bcp is not a basic code point.
  private encode_basic(bcp:number, flag: number) {
    bcp -= ((bcp - 97 < 26) ? 1:0) << 5;
    return bcp + (((!flag && (bcp - 65 < 26))? 1:0) << 5);
  }
  
  // convert from javascripts internal character representation to unicode
  private utf16Encoder(input: number[]) {
    let output = [];
    let i = 0;
    let len = input.length;
    let value;
    while (i < len) {
      value = input[i++];
      if ((value & 0xF800) === 0xD800) {
        throw new RangeError("UTF-16(encode): Illegal UTF-16 value");
      }
      if (value > 0xFFFF) {
        value -= 0x10000;
        output.push(String.fromCharCode(((value >>> 10) & 0x3FF) | 0xD800));
        value = 0xDC00 | (value & 0x3FF);
      }
      output.push(String.fromCharCode(value));
    }
    return output.join("");
  }

  decode(input: string, preserveCase: boolean = true):string {
    let output = [];
    let case_flags = [];
    let input_length = input.length;

    let n, out, i, bias, basic, j, ic, oldi, w, k, digit, t, len;

    // Initialize the state: 

    n = this.initial_n;
    i = 0;
    bias = this.initial_bias;

    // Handle the basic code points: Let basic be the number of input code 
    // points before the last delimiter, or 0 if there is none, then
    // copy the first basic code points to the output.

    basic = input.lastIndexOf(this.delimiter);
    if (basic < 0) basic = 0;

    for (j = 0; j < basic; ++j) {
      if (preserveCase) case_flags[output.length] = (input.charCodeAt(j) - 65 < 26);
      if (input.charCodeAt(j) >= 0x80) {
        throw new RangeError("Illegal input >= 0x80");
      }
      output.push(input.charCodeAt(j));
    }

    // Main decoding loop: Start just after the last delimiter if any
    // basic code points were copied; start at the beginning otherwise. 

    for (ic = basic > 0 ? basic + 1 : 0; ic < input_length;) {

      // ic is the index of the next character to be consumed,

      // Decode a generalized variable-length integer into delta,
      // which gets added to i. The overflow checking is easier
      // if we increase i as we go, then subtract off its starting 
      // value at the end to obtain delta.
      for (oldi = i, w = 1, k = this.base; ; k += this.base) {
        if (ic >= input_length) {
          throw RangeError("punycode_bad_input(1)");
        }
        digit = this.decode_digit(input.charCodeAt(ic++));

        if (digit >= this.base) {
          throw RangeError("punycode_bad_input(2)");
        }
        if (digit > Math.floor((this.maxint - i) / w)) {
          throw RangeError("punycode_overflow(1)");
        }
        i += digit * w;
        t = k <= bias ? this.tmin : k >= bias + this.tmax ? this.tmax : k - bias;
        if (digit < t) { break; }
        if (w > Math.floor(this.maxint / (this.base - t))) {
          throw RangeError("punycode_overflow(2)");
        }
        w *= (this.base - t);
      }

      out = output.length + 1;
      bias = this.adapt(i - oldi, out, oldi === 0);

      // i was supposed to wrap around from out to 0,
      // incrementing n each time, so we'll fix that now: 
      if (Math.floor(i / out) > this.maxint - n) {
        throw RangeError("punycode_overflow(3)");
      }
      n += Math.floor(i / out);
      i %= out;

      // Insert n at position i of the output: 
      // Case of last character determines uppercase flag: 
      if (preserveCase) { case_flags.splice(i, 0, input.charCodeAt(ic - 1) - 65 < 26); }

      output.splice(i, 0, n);
      i++;
    }
    if (preserveCase) {
      for (i = 0, len = output.length; i < len; i++) {
        if (case_flags[i]) {
          output[i] = (String.fromCharCode(output[i]).toUpperCase()).charCodeAt(0);
        }
      }
    }
    return this.utf16Encoder(output);
  }
}

export function convertPunyCodeToUnicode(domain: string) {
  let domain_array = domain?.split(".");
  let outputArray = [];
  let punyEncoder = new PunyCodeEncoder();
  for (let i = 0; i < domain_array.length; ++i) {
    let s = domain_array[i];
    outputArray.push(
      s.match(/^xn--/) ? punyEncoder.decode(s.slice(4)) : s
    );
  }
  return outputArray.join(".");
}
