import {ISelectOptions, Select} from "@widgets/Select";
import {AlertType, Alignment} from "@utils/Enums"
import {SelectList} from "@widgets/SelectList";
import {RenderedEvent} from "./libs/ExtendableEvent";
import {closeAlert, openAlert} from "@widgets/Alert";
import {AccountService, OrderService, ShipmentService} from "@services";
import {OrderSearchPreset, ShipmentSearchPreset} from "@api";
import {PrepareJsonDataFromServer} from "@utils/Utils";
import {ShipmentSearchUiStore} from "@app/page-components/shipment/shipment-list/shipment-search-ui-store.service";
import {OrderSearchUiStore} from "@app/page-components/order/order-list/order-search-bar/order-search-ui-store.service";
import {delay} from "rxjs/operators";

export class FilterPreset {
  private static _selectOptions: ISelectOptions =
    {
      placeholderNone: "Presets",
      content: "#filterPresetsControls",
      class: "search-select droplist-presets droplist-button",
      alwaysPlaceholder: true,
      submitOnEnter: false,
      noResultsPlaceholder: "No saved presets",
      multiple: false,
      alignment: Alignment.Stretch,
      closeOnSelect: false,
    } as ISelectOptions;
  private readonly currentAlert: any;
  private readonly $savePresetInputs: JQuery;
  private readonly $savePresetButtons: JQuery;
  private readonly $selectFilterPreset: JQuery;
  private readonly $presetNameInput: JQuery;
  private readonly select: Select<JSONDataContainer<ShipmentSearchPreset | OrderSearchPreset>>;
  private readonly saveMethodUrl: string;
  private readonly loadPresetUrl: string;
  private readonly _type: "order" | "shipment";
  private readonly _accountService = window.ngInjector.get(AccountService)
  private $alert: JQuery/*<HTMLElement>*/;

  constructor(select: JQuery | HTMLElement, type: "order" | "shipment") {
    this.$selectFilterPreset = $(select);
    if (!this.$selectFilterPreset.is())
      return;

    this._type = type;

    //this.$selectFilterPreset.on("change", (e) => this.loadPreset(e));

    if (!this.$selectFilterPreset.hasData("select"))
      this.$selectFilterPreset.selectControl(FilterPreset._selectOptions);
    this.select = this.$selectFilterPreset.typedData("select");
    this.$savePresetInputs =
      this.select.dropdown.popup.$element.find("input.save-filter-preset");
    this.$savePresetButtons =
      this.select.dropdown.popup.$element.find("button.save-filter-preset");
    this.$presetNameInput =
      this.select.dropdown.popup.$element.find(("input.save-filter-preset[type=text]"));
    this.select.dropdown.popup.$element.id("filterPresets");
    window.addEventListener("offline", () => this.select.disable(true));
    window.addEventListener("online", () => this.select.enable());
    this.select.$fakeSelect.disableWhenOffline();
    this.select.onSelectionChanged.on((e) => {
      this.loadPreset(e.addedItems[0]?.ajaxData.data)
    });
    this.select.onItemsRendered.on((e) => this.updatePresetList(e));

    this.select.onClosing.on(e => {
      const sourceEvent = ((e.originalEvent || {}) as FocusEvent);
      const focusTarget = (sourceEvent.relatedTarget || sourceEvent.target) as HTMLElement;
      if (focusTarget && focusTarget.classList.contains("alert") ||
          $(focusTarget).parents(".alert").length)
        e.preventDefault();
    });
    this.select.onClosed.on(e => {
      if (this.$alert)
        closeAlert(this.$alert);
    });
    this.$savePresetButtons.on("click", (e) => this.onSavePreset(e));
    this.$savePresetInputs
        .on("input",
            (e) => {
              const textBox = e.delegateTarget as HTMLInputElement;
              if (textBox.value.length && /[^\s+]/.exec(textBox.value))
                this.$savePresetButtons.removeAttr("disabled");
              else
                this.$savePresetButtons.attr("disabled", "disabled");
              if (textBox.value.length > 50)
                textBox.value = textBox.value.substr(0, 50);
            })
        .trigger("input")
        .on("keydown",
            (e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.stopImmediatePropagation();
                e.stopPropagation();
                $("ul", this.$selectFilterPreset).focus();
                this.$savePresetButtons.click();
              }
            });
    this.select.onClosed.on(() => {
      if (this.currentAlert)
        $("button.close", this.currentAlert).click();

    });
    this._accountService.accountStore
        .observe(this._type === "shipment" ? "shipmentSearchPresets" : "orderSearchPresets")
        .pipe(delay(100))
        .subscribe(d =>
                     this.updateItems(d))
  }

  updatePresetList(e?: RenderedEvent<SelectList, HTMLLIElement>) {
    const items =
      e
      ? e.items
      : this.select.getCurrentItems().map(i => i.$element);
    // if (!e) {
    //     this.select.loadItems();
    //     this.select.renderElement();
    // } else {
    for (let item of items) {
      $(`<span class="material-icons icon-button hover-only" data-no-selector>delete</span>`)
        .click(e => this.deletePreset(e))
        .appendTo(item);
    }
    //}
  }

  onSavePreset(event: JQueryEventObject) {
    event.preventDefault();
    event.stopPropagation();

    const filterPresetName = $(event.delegateTarget).siblings("input.save-filter-preset[type=text]").val();

    if (!filterPresetName)
      return;
    const existingPreset = this.select.getItems().firstOrDefault(i => i.option.text === filterPresetName);
    if (existingPreset) {
      const $alert = openAlert({
                                 type: AlertType.Information,
                                 content: `Existing preset "${filterPresetName}" will be updated. Continue?`,
                                 timeout: false,
                                 buttons: [
                                   $(document.createElement("button"))
                                     .text("Continue")
                                     .addClass("button")
                                     .attr("type", "button")
                                     .click(() => {
                                       closeAlert($alert);
                                       // noinspection JSIgnoredPromiseFromCall
                                       this.savePreset(filterPresetName);
                                     }),
                                   $(document.createElement("button"))
                                     .text("Cancel")
                                     .addClass("button")
                                     .attr("type", "button")
                                     .click(() => {
                                       closeAlert($alert);
                                     })]
                               });
      this.select.onClosed.oneTime(() => closeAlert($alert))
    } else {
      // noinspection JSIgnoredPromiseFromCall
      this.savePreset(filterPresetName);
    }
  }

  async savePreset(filterPresetName: string) {
    this.$savePresetInputs.attr("disabled", "disabled");
    this.$savePresetButtons.attr("disabled", "disabled");
    try {
      if (this._type === "shipment") {
        const search = window.ngInjector.get(ShipmentSearchUiStore).get()
        await this._accountService.createShipmentSearchPreset(filterPresetName, search)
      } else {
        const search = window.ngInjector.get(OrderSearchUiStore).get()
        await this._accountService.createOrderSearchPreset(filterPresetName, search)
      }
    } catch {
      openAlert({
                  type: AlertType.Error,
                  content: "The preset cannot be saved at the moment. Please try again later."
                });
    }
    this.$savePresetInputs.removeAttr("disabled");
    this.$presetNameInput.val("");
    this.select.close();
  };

  loadPreset(preset: ShipmentSearchPreset | OrderSearchPreset) {
    if (!preset) {
      return;
    }
    this.select.close();
    this.select.unselectItems();
    PrepareJsonDataFromServer(preset);
    if (this._type === "shipment") {
      window.ngInjector.get(ShipmentService).startSearch(preset as ShipmentSearchPreset)
    } else {
      window.ngInjector.get(OrderService).startSearch(preset as OrderSearchPreset)
    }
  };

  deletePreset(event: JQueryEventObject) {
    event.preventDefault();
    event.stopPropagation();
    const item = this.select.getItemFromElement($(event.delegateTarget).parent());

    if (this.$alert)
      closeAlert(this.$alert)
    this.$alert = openAlert({
                              type: AlertType.Error,
                              content: `Are you sure you want to delete preset "${item.option.text}"?`,
                              timeout: false,
                              buttons: [
                                $(document.createElement("button"))
                                  .text("Delete")
                                  .addClass("button")
                                  .attr("type", "button")
                                  .click(async () => {
                                    closeAlert(this.$alert);
                                    try {
                                      if (this._type === "shipment")
                                        await this._accountService.deleteShipmentPreset(item.value);
                                      else
                                        await this._accountService.deleteOrderPreset(item.value);
                                    } catch {
                                      openAlert({
                                                  type: AlertType.Error,
                                                  content: `Deleting Preset "${item.option.text}" Failed. Please try again`
                                                });
                                    }
                                  }),
                                $(document.createElement("button"))
                                  .text("Cancel")
                                  .addClass("button")
                                  .attr("type", "button")
                                  .click(() => {
                                    closeAlert(this.$alert);
                                  })]
                            });


  };

  private updateItems(presets: ShipmentSearchPreset[] | OrderSearchPreset[]) {
    const jsonData =
      (presets as ShipmentSearchPreset[])
        .orderBy(p => p.name)
        .map((p): JSONDataContainer<ShipmentSearchPreset | OrderSearchPreset> => {
          return {
            value: p.id,
            label: p.name,
            data: p,
          }
        });
    this.select.clearItems();
    this.select.processJsonData(jsonData);
  }

}
