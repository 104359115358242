import {GetAsArray} from "../utils/Utils";

require("../utils/JqueryLoader");

export type alertOptions = {
  timeout?: number | boolean,
  dontDisplayX?: boolean,
  buttons?: JQuery | JQuery[],
  type: "info" | "success" | "warning" | "error",
  content: string
};

export function openAlert(options: alertOptions) {
  let {timeout, dontDisplayX, buttons, type, content} = options;

  let iconType = "";
  switch (type) {
    case "warning":
      iconType = "priorityhigh";
      break;
    case "error":
      iconType = "highlightoff";
      break;
    case "info":
      iconType = "priorityhigh flip-icon";
      break;
    case "success":
      iconType = "done";
      break;
  }

  const $alert = $(document.createElement("div"))
    .addClass("alert alert-" + type)
    .attr("tabindex", -1)
    .on("click", e => e.stopPropagation());
  if (!dontDisplayX)
    $alert.append($("<button class=\"close mat-icon-close text-hide\">Close</button>"));
  $alert.append($(`<div class="container"><span class="mat-icon-${iconType}">${content}</span></div>`));
  if (!buttons && $alert.find("div.buttons").length) {
    const $buttons = $alert.find("div.buttons");
    buttons = [$buttons.children()];
    $buttons.remove();
  }
  if (buttons) {
    $(document.createElement("div"))
      .addClass("buttons")
      .append(GetAsArray(buttons).reduce((b1, b2) => b1.add(b2)))
      .appendTo($alert.find(".container"));
  }
  $alert.appendTo(document.body);

  setTimeout(() => $alert.addClass("active open"), 100);

  let alertTimeout: number;
  if (timeout !== false) {
    alertTimeout = window.setTimeout(
      () => closeAlert($alert),
      typeof timeout === "number" ? timeout : 5000);
  }

  $(".close", $alert)
    .click(event => {
      event.preventDefault();
      if (alertTimeout)
        window.clearTimeout(alertTimeout);
      closeAlert($alert);
    });
  return $alert;
}

export function closeAlert($alert: JQuery): void;
export function closeAlert(forceAll: boolean): void
export function closeAlert(): void
export function closeAlert($alert?: JQuery | boolean) {
  if (!$alert)
    $alert = $(".alert").has(".close");
  else if ($alert === true)
    $alert = $(".alert");
  $alert.removeClass("active");
  setTimeout(() => ($alert as JQuery).remove(), 250);
}
