import {Directive, ElementRef, OnInit} from '@angular/core';
import {BookingTemplateSelect} from "@widgets/BookingTemplateSelect";

@Directive({
  selector: '[rtBookingTemplateSelect]'
})
export class BookingTemplateSelectDirective implements OnInit{

  private readonly _elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    this._elementRef = elementRef;
  }

  ngOnInit(): void {
    new BookingTemplateSelect(this._elementRef.nativeElement);
  }

}
