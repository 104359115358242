import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {combineLatest, merge, Observable, of, Subject} from "rxjs";
import {EntityRightsTemplate, EntitySetPermissions, JSONData, UserGroup, UserSaveRequest, UserType} from "@api";
import {UserService} from "@services/user.service";
import {AppBaseComponent} from "@app/appBaseComponent";
import {filter, map, takeUntil} from "rxjs/operators";
import {userGroupMapper, userTypeMapper} from "@app/utils/enum-display-name-mapper";
import {TypedStoreAccessors, UserEditStore, UserRightsStore} from "@stores";
import {ISelectOptions} from "@widgets/Select";
import {SlideInOut} from "@utils/Angular/AnimationTemplates";
import {IPopupSelectListOptions} from "@widgets/PopupSelectList";
import {ExternalSearchResult} from "@widgets/SelectList";
import {FormValidatorDirective, NgSelectionChangedEvent} from "@directives";
import {trackById} from "@app/utils/track-by-id";
import {toJsonData} from "@utils/Utils";
import {CountryService} from "@services/country.service";
import {hasValue} from "@utils/rxjs-extensions";

@Component({
             selector: 'rt-user-edit',
             templateUrl: './user-edit.component.html',
             styleUrls: ['./user-edit.component.scss'],
             animations: [SlideInOut()],
             providers: [UserEditStore]
           })
export class UserEditComponent extends AppBaseComponent {

  @ViewChild(FormValidatorDirective)
  rtFormValidator: FormValidatorDirective;

  readonly userType = UserType;
  readonly userGroup = UserGroup;
  readonly selectSettings = {
    groups: {
      allowNoSelection: false
    } as ISelectOptions
  }
  readonly trackById = trackById;

  readonly storeAccessors: TypedStoreAccessors<UserSaveRequest,
    "firstName" |
    "group" |
    "countryCode" |
    "adminCountryCodes" |
    "deactivated" |
    "lastName" |
    "locked" |
    "mailAddress" |
    "phone" |
    "profilePictureBase64">;

  readonly canAssignUserGroup$: Observable<boolean>
  readonly canEditNameAndMail$: Observable<boolean>;
  readonly cannotActivateUser$: Observable<boolean>;
  readonly entitySelectItems$: Observable<JSONDataContainer<EntityRightsTemplate>[]>;
  readonly groupCountrySelectAvailable$: Observable<boolean>;
  readonly groupItems$: Observable<JSONData[]>
  readonly isNewUser$: Observable<boolean>;
  readonly userName$: Observable<string>;
  readonly isRohligUser$: Observable<boolean>;
  readonly originalMailAddress$: Observable<string>;
  readonly selectedEntityIds$: Observable<string[]>;
  readonly userIsLocked$: Observable<boolean>;
  readonly userLockedDate$: Observable<Date>;
  readonly userType$: Observable<UserType>;

  entitySetPermissions: EntitySetPermissions[] = [];
  isSaving = false;
  sendPasswordMail = true;
  sendWelcomeMail = true;
  userLoaded: boolean;

  private readonly _entitySelectItemsSubject = new Subject<EntitySetPermissions[]>()
  private readonly _userService: UserService;
  private readonly _userEditStore: UserEditStore;
  private readonly _countryService: CountryService;

  constructor(userService: UserService,
              userRightsStore: UserRightsStore,
              userEditStore: UserEditStore,
              countryService: CountryService) {
    super();
    this._countryService = countryService;
    this._userEditStore = userEditStore;
    this._userService = userService;
    this.storeAccessors = userEditStore.getStoreAccessors("firstName",
                                                          "group",
                                                          "countryCode",
                                                          "adminCountryCodes",
                                                          "deactivated",
                                                          "lastName",
                                                          "locked",
                                                          "mailAddress",
                                                          "phone",
      "profilePictureBase64");
    const userStore = userService.userStore;
    const currentUser$ = userStore.observe("currentUser")
                                  .pipe(takeUntil(this.destroyed$));
    currentUser$
      .subscribe(u => {
        const isNewUser = !u;
        if (isNewUser) {
          userEditStore.reset();
          this.entitySetPermissions = [];
          return
        }
        this.entitySetPermissions = u.entitySetPermissions?.orderBy(e => e.name) || [];
        userEditStore.update({
                               adminCountryCodes: u.adminCountries?.map(c => c.isoAlpha2) || [],
                               countryCode: u.country?.isoAlpha2 || null,
                               deactivated: u.deactivated || null,
                               firstName: u.firstName || null,
                               group: u.group || null,
                               locked: u.locked || null,
                               mailAddress: u.mailAddress || null,
                               lastName: u.lastName || null,
                               phone: u.phone,
                               profilePictureBase64: u.profilePictureBase64 || null
                             });
        this.userLoaded = true;
      });
    this.userName$ = currentUser$.pipe(filter(hasValue),map(u => `${u.firstName} ${u.lastName}`))
    const entityItemsFromStore$ = currentUser$.pipe(map(u => {
        if (!u?.entitySetPermissions)
          return [];
        return u.entitySetPermissions
                .orderBy(e => e.name)
      }))
    ;
    this.entitySelectItems$ =
      merge(entityItemsFromStore$, this._entitySelectItemsSubject)
        .pipe(map(entitySets => {
          if (!entitySets)
            return [];
          return entitySets.map(e => {
            return {
              value: e.id,
              label: e.name,
              selected: true,
              disabled: !e.canBeManagedByCurrentUser,
              data: this._userService.convertToEntityRightsTemplate(e)
            }
          })
        }));
    this.selectedEntityIds$ =
      this.entitySelectItems$
          .pipe(map(entitySets =>
                      entitySets.map(e => e.value)
          ));
    this.cannotActivateUser$ =
      combineLatest(
        currentUser$,
        userRightsStore.observe("isSuperUser")
      ).pipe(map(([currentUser, isSuperUser]) => {
        if (!currentUser)
          return false;
        const isNewUser = !currentUser.id;
        const canActivateUser = isNewUser || currentUser.type === UserType.Client || isSuperUser;
        return !canActivateUser;
      }));
    this.canEditNameAndMail$ = currentUser$.pipe(map(u => !u || !u.type || u.type === UserType.Client))
    this.userIsLocked$ = currentUser$.pipe(map(u => u && u.locked));
    this.userLockedDate$ = currentUser$.pipe(map(u => u?.lockDate))
    this.isRohligUser$ = currentUser$.pipe(map(u => u?.type === UserType.Rohlig));
    this.groupCountrySelectAvailable$ =
      merge(
        of(false),
        userEditStore.observe("group")
                     .pipe(
                       map(u => u === UserGroup.LocalAdmin),
                     )
      );
    this.userType$ = currentUser$.pipe(map(u => u?.type || UserType.Client));
    this.originalMailAddress$ = currentUser$.pipe(map(u => u?.mailAddress || ""));
    userRightsStore.observe("isSuperUser")
    this.isNewUser$ = currentUser$
      .pipe(map(u => !u || !u.id));
    this.canAssignUserGroup$ =
      combineLatest(
        userRightsStore.observe("userType"),
        userRightsStore.observe("userGroup"),
        currentUser$
      )
        .pipe(map(([userType, userGroup, currentUser]) => {
                if (!currentUser)
                  return false;
                if (userType === UserType.SuperUser)
                  return true;
                if (userGroup === UserGroup.GlobalAdmin)
                  return true;
                if (userGroup === UserGroup.LocalAdmin)
                  return [UserGroup.AccountManager, UserGroup.Standard].contains(currentUser.group)
                return false;
              })
        )
    this.groupItems$ = userRightsStore
      .observe(["userGroup", "isSuperUser"])
      .pipe(map(({isSuperUser, userGroup}) => {
        if (isSuperUser || userGroup === UserGroup.GlobalAdmin)
          return toJsonData(userGroupMapper)
        if (userGroup === UserGroup.LocalAdmin)
          return toJsonData(Object.extract(userGroupMapper, [UserGroup.Standard, UserGroup.AccountManager]))
      }))
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._userEditStore.destroy();
  }

  getUserTypeName = (userType: UserType): string => {
    return userTypeMapper[userType];
  };

  removeEntitySet(entitySetPermissions: EntitySetPermissions): void {
    this.entitySetPermissions.remove(entitySetPermissions);
    this._entitySelectItemsSubject.next(this.entitySetPermissions);
  }

  entitySelectionChanged($event: NgSelectionChangedEvent): void {
    const removedIds = $event.removedItems.map(i => i.value);
    const entitySetPermissions =
      ($event as NgSelectionChangedEvent<JSONDataContainer<EntityRightsTemplate>>)
        .addedItems
        .map(i => this._userService.convertToEntitySetPermissions(i.ajaxData.data));
    entitySetPermissions.pushRange(this.entitySetPermissions.filter(e => !removedIds.contains(e.id)));
    this.entitySetPermissions = entitySetPermissions;
  }

  async save(): Promise<void> {
    this.isSaving = true;
    if (!await this.rtFormValidator.validate()) {
      this.isSaving = false;
      return;
    }
    try {
      const currentUserId = this._userService.getCurrentUserId();
      const isNewUser = !currentUserId;
      const saveRequest: UserSaveRequest = this._userEditStore.get();
      saveRequest.entitySetPermissions = this.entitySetPermissions;
      if (isNewUser)
        await this._userService.create(saveRequest, this.sendWelcomeMail, this.sendPasswordMail);
      else
        await this._userService.update(currentUserId, saveRequest);
    } catch (e) {
      this.isSaving = false;
    }
  }

  getCountryNamesFromCodes = (codes: string[]): string[] => {
    if (!codes)
      return [];
    return codes
      .map(c => this._countryService.getByIsoAlpha2(c)?.common)
      .filter(c => !!c);
  };

  readonly validateEntitySets = (): boolean => this.entitySetPermissions?.any() === true;

  private searchEntities = async (search: string): Promise<ExternalSearchResult> => {
    const entityRightsTemplates = await this._userService.searchEntitySetRightsTemplates(search);

    const jsonData: JSONDataContainer<EntityRightsTemplate>[] = entityRightsTemplates
      .map(e => {
        return {
          data: e,
          disabled: !e.canBeManagedByCurrentUser,
          label: e.name,
          value: e.id
        }
      });
    return {
      results: jsonData
    }
  };

  readonly entitySelectOptions: IPopupSelectListOptions = {
    searchDelay: 1000,
    multiple: true,
    externalSearchHandler: this.searchEntities,
    enableItemUpdates: false,
    closeOnSelect: false,
    disabledItemToolTip: "You do not have the permissions to assign this entity set"
  }
}
