import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {TimelineDateUiData} from "@app/page-components/shipment/shipment-detail/vertical-timeline/vertical-timeline.component";

@Component({
             selector: 'rt-time-line-date',
             templateUrl: './time-line-date.component.html',
             styleUrls: ['./time-line-date.component.scss'],
             host: {
               class: "date-string"
             },
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class TimeLineDateComponent {
  @Input()
  date: TimelineDateUiData


  @HostBinding("class.estimated")
  private get _estimated() {
    return this.date?.estimated;
  }
}
