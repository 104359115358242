import {Component, Input} from '@angular/core';
import {UserRightsStore} from "@stores";
import {Router} from "@angular/router";
import {openAlert} from "@widgets/Alert";
import {AlertService} from "@services/alert.service";
import {OrderReference, ShipmentReference} from "@api";
import {Observable, of} from "rxjs";
import {OrderDetailViewData, OrderService, ShipmentDetailViewData, ShipmentService} from "@services";
import {WebMethodApi} from "../../../api/Apis";

@Component({
             selector: '[rt-reference-link]',
             templateUrl: './reference-link.component.html',
             styleUrls: ['./reference-link.component.scss']
           })
export class ReferenceLinkComponent {
  text: string;
  hasRights$: Observable<boolean> = of(false)
  url: string;

  private readonly _userRightsStore: UserRightsStore;
  private readonly _alertService: AlertService;
  private readonly _webMethodApi: WebMethodApi;
  private readonly _router: Router;

  private readonly _shipmentService: ShipmentService;

  private readonly _orderService: OrderService;

  constructor(userRightsStore: UserRightsStore,
              alertService: AlertService,
              webMethodApi: WebMethodApi,
              router: Router,
              shipmentService: ShipmentService,
              orderService: OrderService) {
    this._orderService = orderService;
    this._shipmentService = shipmentService;
    this._router = router;
    this._webMethodApi = webMethodApi;
    this._alertService = alertService;
    this._userRightsStore = userRightsStore;

  }

  reference: ShipmentReference | OrderReference;

  @Input("rt-reference-link")
  set referenceSetter(value: ShipmentReference | OrderReference) {
    if (!value)
      return;
    this.reference = value;
    if (ReferenceLinkComponent._isShipmentReference(value)) {
      this.hasRights$ = this._userRightsStore.observe("shipments");
      this.url = new ShipmentDetailViewData(value.id, value.type).url;
      this.text = value.houseBill;
    } else {
      this.hasRights$ = this._userRightsStore.observe("orders");
      this.url = new OrderDetailViewData(value.id).url;
      this.text = value.orderNumber;
    }
  }

  private static _isShipmentReference(reference: ShipmentReference | OrderReference): reference is ShipmentReference {
    return reference && "houseBill" in reference;
  }

  async onClick($event: MouseEvent): Promise<void> {
    $event.preventDefault();
    const ref = this.reference;
    const userHasAccess =
      ReferenceLinkComponent._isShipmentReference(ref)
      ? await this._shipmentService.hasUserAccess(ref.id, ref.type)
      : await this._orderService.hasUserAccess(ref.id);
    if (userHasAccess)
      await this._router.navigateByUrl(this.url);
    else
      this._openNoAccessAlert()
  }

  private _openNoAccessAlert() {
    const type =
      ReferenceLinkComponent._isShipmentReference(this.reference)
      ? "shipment"
      : "order";
    openAlert({
                type: "warning",
                content: `You do not have permissions to view this ${type}.`,
                timeout: 3000
              });
  }
}
