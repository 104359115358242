import {Injectable} from "@angular/core";
import {Store, StoreProjectionMapping} from "@app/stores/store";
import {ContainerStoreState} from "./container-store-state";
import {ContainerOverviewItem} from "@app/page-components/shipment/shipment-list/container-overview-item";
import {IsContainerPipe} from "@app/utils/rt-date.pipe";
import {ContainerService} from "@services/container.service";
import {ShipmentOverviewItem} from "@app/page-components/shipment/shipment-list/shipment-overview-item";

export type ContainerStoreProjection = {
  containerTitle: string | null;
  containerOverviewList: (ShipmentOverviewItem|ContainerOverviewItem)[];
  showLoadMoreButton: boolean;
  noResultsFound: boolean;
  viewCustomizerOverviewItem: ContainerOverviewItem;
};

@Injectable({providedIn: "root"})
export class ContainerStore extends Store<ContainerStoreState, ContainerStoreProjection> {

  private static readonly _initialState: Partial<ContainerStoreState> = {
    itemList: null,
    isLoadingMore: false,
    isSearching: false,
    firstContainerInEntitySet: null
  }
  private static readonly _projectionMapping: StoreProjectionMapping<ContainerStoreState, ContainerStoreProjection> = {
    containerTitle: ({currentContainer}) => {
      if (!currentContainer)
        return null;
      else
        return currentContainer.containerNumber || "T.B.D.";
    },
    containerOverviewList: u => {
      return u.itemList?.map(s => ContainerService.isContainer(s) ? new ContainerOverviewItem(s) : new ShipmentOverviewItem(s)) || []
    },
    showLoadMoreButton: u =>
      !u.isSearching &&
      u.itemList &&
      u.itemList.any() &&
      u.moreItemsAvailable,
    noResultsFound: state => !state.isSearching && state.itemList && !state.itemList.any(),
    viewCustomizerOverviewItem: s => {
        let viewCustomizerItem = s.itemList?.firstOrDefault(ContainerService.isContainer) || s.firstContainerInEntitySet;
        if (!viewCustomizerItem)
          return null;
        return new ContainerOverviewItem(viewCustomizerItem);
    }
  }

  constructor() {
    super(ContainerStore._projectionMapping, ContainerStore._initialState);
  }

  getName(): string {
    return "ContainerStore";
  }
}
