import {DateContainer, ShipmentOverviewItem} from "@app/page-components/shipment/shipment-list/shipment-overview-item";
import {OverviewFieldRenderer, OverviewFieldTypes} from "@app/page-components/shipment/shipment-list/shipment-box-fields";
import {CityLocation, DeliveryStatus, DimensionValue, ListLayoutItem, PortLocation, ShipmentEvent, TransportMode} from "@api";
import {FieldRenderer} from "./field-renderer.service";
import {transportModes} from "@app/page-components/shipment/shipment-list/shipment-search-code-mapper";
import {deliveryStatusClassMapper, deliveryStatusDisplayMapper} from "@app/utils/enum-display-name-mapper";

export interface ShipmentListLayoutMapper<T extends OverviewFieldTypes = OverviewFieldTypes> {
  columnTitle: string;
  getValue: Func1<ShipmentOverviewItem, T>;
  getTitle?: Func3<ShipmentOverviewItem, FieldRenderer, T, string>
  getContent: Func3<ShipmentOverviewItem, FieldRenderer, T, string>
  getClass?: Func1<T, string>
  size?: "small" | "extra-small" | "large",
  isDefault?: true
}

export const ShipmentListFields: { readonly [P in ListLayoutItem]: ShipmentListLayoutMapper } = {
  [ListLayoutItem.ArrivalDate]: {
    columnTitle: "Arrival Date",
    getValue: s => s.arrivalDate,
    getContent: (s, r, v: DateContainer) => r.renderDateValue(v),
    getTitle: (s, r, v: DateContainer) => r.renderDateTitle(v),
  },
  [ListLayoutItem.BillOfLading]: {
    columnTitle: "Bill of Lading",
    getValue: s => s.billOfLading,
    getContent: (s, r, v: string) => r.renderString(v),
    size: "small",
    isDefault: true
  },
  [ListLayoutItem.Chargeable]: {
    columnTitle: "Chargeable",
    getValue: s => s.chargeable,
    getContent: (s, r, v: DimensionValue) => r.renderDimensionValue(v),
    size: "extra-small"
  },
  [ListLayoutItem.ClientReferences]: {
    columnTitle: "Client References",
    getValue: s => s.clientReferences,
    getContent: (s, r, v: string[]) => r.renderStringArray(v),
    size: "large"
  },
  [ListLayoutItem.Consignee]: {
    columnTitle: "Consignee",
    getValue: s => s.consigneeName,
    getContent: (s, r, v: string) => r.renderString(v),
    isDefault: true
  },
  [ListLayoutItem.ContainerCount]: {
    columnTitle: "Container Count",
    getValue: s => s.containerCount,
    getContent: (s, r, v: string) => r.renderString(v)
  },
  [ListLayoutItem.ContainerMode]: {
    columnTitle: "Container Mode",
    getValue: s => s.containerMode,
    getContent: (s, r, v: string) => r.renderString(v),
    size: "small",
    isDefault: true
  },
  [ListLayoutItem.ContainerNumber]: {
    columnTitle: "Container Number",
    getValue: s => s.containersNumbers,
    getContent: (s, r, v: string[]) => r.renderStringArray(v)
  },
  [ListLayoutItem.CustomsEntryNumber]: {
    columnTitle: "Customs Entry Number",
    getValue: s => s.customsEntryNumbers,
    getContent: (s, r, v: string[]) => r.renderStringArray(s.customsEntryNumbers)
  },
  [ListLayoutItem.DangerousGoods]: {
    columnTitle: "Dangerous Goods",
    getValue: s => s.dangerousGoods,
    getContent: null,
    getTitle: (s, r) => r.renderStringArray(s.dangerousGoods.map(d => "UN " + d.key))
  },
  [ListLayoutItem.DeliveryLocation]: {
    columnTitle: "Delivery Location",
    getValue: s => s.deliveryLocation,
    getContent: (s, r, v: string) => r.renderString(v),
  },
  [ListLayoutItem.DepartureDate]: {
    columnTitle: "Departure Date",
    getValue: s => s.departureDate,
    getContent: (s, r, v: DateContainer) => r.renderDateValue(v),
    getTitle: (s, r, v: DateContainer) => r.renderDateTitle(v)
  },
  [ListLayoutItem.DeliveryDate]: {
    columnTitle: "Delivery Date",
    getValue: s => s.deliveryDate,
    getContent: (s, r, v: DateContainer) => r.renderDateValue(v),
    getTitle: (s, r, v: DateContainer) => r.renderDateTitle(v)
  },
  [ListLayoutItem.Destination]: {
    columnTitle: "Destination",
    getValue: s => s.destination,
    getContent: (s, r, v: CityLocation) => r.renderCityLocationValue(v),
    getTitle: (s, r, v: CityLocation) => r.renderCityLocationTitle(v),
    isDefault: true
  },
  [ListLayoutItem.GoodsDescription]: {
    columnTitle: "Goods Description",
    getValue: s => s.goodsDescription,
    getContent: (s, r, v: string) => r.renderString(v),
    size: "large"
  },
  [ListLayoutItem.Incoterm]: {
    columnTitle: "Incoterm",
    getValue: s => s.incoTerm,
    getContent: (s, r, v: string) => r.renderString(v),
    size: "extra-small"
  },
  [ListLayoutItem.InvoiceNumber]: {
    columnTitle: "Invoice Number",
    getValue: s => s.invoiceNumbers,
    getContent: (s, r, v: string[]) => r.renderStringArray(v)
  },
  [ListLayoutItem.LastEvent]: {
    columnTitle: "Last Event",
    getValue: s => s.lastEvent,
    getContent: (s, r, v: ShipmentEvent) => r.renderEventValue(v),
    getTitle: (s, r, v: ShipmentEvent) => r.renderEventTitle(v),
    size: "large",
    isDefault: true
  },
  [ListLayoutItem.OrderNumber]: {
    columnTitle: "Order Number",
    getValue: s => s.orderNumbers,
    getContent: (s, r, v: string[]) => r.renderStringArray(v)
  },
  [ListLayoutItem.Origin]: {
    columnTitle: "Origin",
    getValue: s => s.origin,
    getContent: (s, r, v: CityLocation) => r.renderCityLocationValue(v),
    getTitle: (s, r, v: CityLocation) => r.renderCityLocationTitle(v),
    isDefault: true
  },
  [ListLayoutItem.PickupDate]: {
    columnTitle: "Pickup Date",
    getValue: s => s.pickupDate,
    getContent: (s, r, v: DateContainer) => r.renderDateValue(v),
    getTitle: (s, r, v: DateContainer) => r.renderDateTitle(v)
  },
  [ListLayoutItem.PickupLocation]: {
    columnTitle: "Pickup Location",
    getValue: s => s.pickupLocation,
    getContent: (s, r, v: string) => r.renderString(v),
  },
  [ListLayoutItem.Pieces]: {
    columnTitle: "Pieces",
    getValue: s => s.pieces,
    getContent: (s, r, v: string) => r.renderString(v),
    size: "extra-small"
  },
  [ListLayoutItem.PortOfDischarge]: {
    columnTitle: "Port of Discharge",
    getValue: s => s.hidePorts ? "" : s.portOfDischarge,
    getContent: (s, r, v: PortLocation | "") => typeof v === "string"
                                                ? r.renderString(v)
                                                : r.renderPortLocationValue(v, "none"),
    getTitle: (s, r, v: PortLocation | "") => typeof v === "string"
                                              ? r.renderString(v)
                                              : r.renderPortLocationTitle(v, s.portTypes)
  },
  [ListLayoutItem.PortOfLoading]: {
    columnTitle: "Port of Loading",
    getValue: s => s.hidePorts ? "" : s.portOfLoading,
    getContent: (s, r, v: PortLocation | "") => typeof v === "string"
                                                ? r.renderString(v)
                                                : r.renderPortLocationValue(v, "none"),
    getTitle: (s, r, v: PortLocation | "") => typeof v === "string"
                                              ? r.renderString(v)
                                              : r.renderPortLocationTitle(v, s.portTypes)
  },
  [ListLayoutItem.RoehligReferences]: {
    columnTitle: "Röhlig References",
    getValue: s => s.roehligReferences,
    getContent: (s, r, v: string[]) => r.renderStringArray(v)
  },
  [ListLayoutItem.Shipper]: {
    columnTitle: "Shipper",
    getValue: s => s.shipperName,
    getContent: (s, r, v: string) => r.renderString(v),
    isDefault: true
  },
  [ListLayoutItem.Status]: {
    columnTitle: "Status",
    getValue: s => s.status,
    getContent: (s, r, v: DeliveryStatus) => r.renderDeliveryStatusValue(v),
    getTitle: (s, r, v: DeliveryStatus) => r.renderString(deliveryStatusDisplayMapper[v]),
    getClass: (v: DeliveryStatus) => deliveryStatusClassMapper[v],
    size: "extra-small",
    isDefault: true
  },
  [ListLayoutItem.TransportMode]: {
    columnTitle: "Transport Mode",
    getValue: s => s.transportModes,
    getContent: (s, r, v: TransportMode[]) => r.renderString(v.join(" / ")),
    isDefault: true
  },
  [ListLayoutItem.VesselFlightNumber]: {
    columnTitle: "Vessel / Flight Number",
    getValue: s => s.vesselFlightNumbers,
    getContent: (s, r, v: string[]) => r.renderStringArray(v)
  },
  [ListLayoutItem.Volume]: {
    columnTitle: "Volume",
    getValue: s => s.volume,
    getContent: (s, r, v: DimensionValue) => r.renderDimensionValue(v),
    size: "extra-small"
  },
  [ListLayoutItem.Weight]: {
    columnTitle: "Weight",
    getValue: s => s.weight,
    getContent: (s, r, v: DimensionValue) => r.renderDimensionValue(v),
    size: "extra-small"
  },
}
