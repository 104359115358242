<div class="page-wrap">
  <main class="loaded" style="margin-right: 0px;">
    <section class="container">
      <div>
        <img class="rohlig-logo" src="/content/images/Rohlig_Real_Time_blue.svg" width="100%" alt="Logo">
        <div class="wysiwyg">
          <router-outlet></router-outlet>
          <p class="buttons">
            <a class="button" [routerLink]="'/'">Back to Home</a>
          </p>
        </div>
      </div>
    </section>
    <picture class="background-image">
      <source type="image/webp" srcset="/content/images/port.webp 1x, /content/images/port_hi.webp 2x"/>
      <source type="image/jpeg" srcset="/content/images/port.jpg 1x, /content/images/port_hi.jpg 2x"/>
      <img src="/content/images/port.jpg" alt="port-background"/>
      <img src="/content/images/port.jpg" alt="port-background"/>
    </picture>
    <img class="rohlig-logo" src="/content/images/Rohlig_white.svg" width="100%" alt="Logo">
  </main>
</div>
