import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {UserRightsStore} from "@stores";
import {map} from "rxjs/operators";
import {DefaultRedirect} from "@app/guards/default-redirect.guard";
import {UserRightsStoreState} from "@stores/userRightsStoreState";

@Injectable({
              providedIn: 'root'
            })
export class NotAuthenticated extends DefaultRedirect implements CanActivate {

  constructor(userRightsStore: UserRightsStore, router: Router) {
    super(userRightsStore, router);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userRightsStore
               .observe()
               .pipe(map((userRights: Partial<UserRightsStoreState>) => {
                 if (!userRights.isAuthenticated)
                   return true;
                 const returnUrl = route.queryParams["returnUrl"] as string;
                 return super.performRedirectForAuthenticatedUser(userRights, returnUrl);
               }))
  }

}

