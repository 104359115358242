import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {Observable} from "rxjs";
import {MainMasterUiService} from "@app/master-components/main/main-master-ui.service";
import {MainMasterUiStore} from "@app/master-components/main/main-master-ui.store";
import {Snow} from "../../../../libs/snow";

@Component({
             selector: 'rt-top-bar',
             templateUrl: './top-bar.component.html',
             styleUrls: ['./top-bar.component.scss']
           })
export class TopBarComponent implements AfterViewInit, OnDestroy{
  readonly userMenuOpen$: Observable<boolean>;
  readonly navMenuOpen$: Observable<boolean>;

  private readonly _mainMasterUiService: MainMasterUiService;

  private readonly _mainMasterUiStore: MainMasterUiStore;

  readonly letItSnow = new Date().getMonth() === 11;
  @ViewChild("snow")
  snowElement: ElementRef<HTMLDivElement>
  private _snow: Snow;

  constructor(mainMasterUiService: MainMasterUiService, mainMasterUiStore: MainMasterUiStore) {
    this._mainMasterUiStore = mainMasterUiStore;
    this._mainMasterUiService = mainMasterUiService;
    this.userMenuOpen$ = mainMasterUiStore.observe("userMenuOpen");
    this.navMenuOpen$ = mainMasterUiStore.observe("navMenuOpen");
  }

  toggleUserMenu(): void {
    this._mainMasterUiService.toggleUserMenu();
  }

  toggleNavMenu(): void {
    this._mainMasterUiService.toggleNavMenu();
  }

  ngAfterViewInit(): void {
    if (this.letItSnow)
      this._snow = new Snow(this.snowElement.nativeElement, {
        number: 30,
        r: 2.5,
        v: 1
      });
  }

  ngOnDestroy(): void {
    this._snow?.destroy();
  }


}
