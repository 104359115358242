import {sleep} from "./utils/Utils";
import detect = require("@utils/detect");

export class ExportToExcel {

  static downloadMessage = `<strong>Your file has been generated.</strong>
                        <div class='buttons'>
                            <a href='${window.baseApplicationUrl}/webmethod/GetReportBytes?RE_PK={PK}' id='downloadReportButton' class='button'>Download</a>
                        </div>`;
  // $startReportStripe = $('#startReportStripe');
  // $reportCompletedStripe = $('#reportCompletedStripe');
  $button: JQuery;
  private static _isPolling: boolean;

  constructor($button: JQuery) {
    this.$button = $button;
    this.initialize();
  }

  static enableButton(): void {
    $("#b-export-to-excel")
      .removeAttr("disabled")
      .removeClass("disabled")
      .hide()
      .show();
  }

  static async startNewReportPolling() {
    if (this._isPolling)
      return;
    this._isPolling = true;
    let guid: string;
    while (!guid) {
      await sleep(3000);
      guid = await $.get(window.baseApplicationUrl + "/webmethod/GetReportDownloadPK");
    }
    $(".alert.active").remove();
    // noinspection ES6MissingAwait
    this.displayDownloadLink(guid);
    this._isPolling = false;
  }

  static async displayDownloadLink(guid: string) {
    $("#reportCompletedStripe")
      .data("alert", this.downloadMessage.replace("{PK}", guid))
      .click();

    const $downloadReportButton = $("#downloadReportButton");
    if (detect.browser.ie)
      $downloadReportButton.attr("target","_blank");
    $downloadReportButton
      .click(((e) => {
      $(".alert.active").remove();
      this.enableButton();
    }));
  }

  initialize(): void {
    this.$button.on("click", (e) => this.buttonClicked(e));
    // noinspection JSIgnoredPromiseFromCall
    this.checkCanStartExport();
  }

  async checkCanStartExport() {
    if (await $.getJSON(window.baseApplicationUrl + "/webmethod/CanStartNewExcelExport")) {
      ExportToExcel.enableButton();
    } else {
      //$('#startReportStripe').click();
      // noinspection ES6MissingAwait
      ExportToExcel.startNewReportPolling();
    }
  }

  async startExport() {
    this.disableButton();
    let $searchForm = $("form.search-bar, #export-settings");
    if ($searchForm.length) {
      await $.ajax({
                     url: window.baseApplicationUrl + "/webmethod/SaveExcelExport",
                     method: "POST",
                     data: $searchForm.serialize()
                   });
      // noinspection ES6MissingAwait
      ExportToExcel.startNewReportPolling();
    }
  }

  buttonClicked(event: JQueryEventObject) {
    event.preventDefault();
    if (window.isOffline)
      return;
    // Just do this if no report is running

    //$('#startReportStripe').click();
    //_self.startExport();

    if (this.$button.hasClass("disabled")) {
      return;
    }
    if ($("select#export-settings option:selected").length > 0) {

      $("#startReportStripe").click();
      // noinspection JSIgnoredPromiseFromCall
      this.startExport();
    } else {
      //setTimeout(function () {
      //    $('span.small-button.icon-gears label').click();
      //}, 50);
    }

  };

  disableButton(): void {
    this.$button
        .attr("disabled", "")
        .addClass("disabled");
  }
}
