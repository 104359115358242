import {ChangeDetectorRef, Component, HostBinding} from '@angular/core';
import {EntityStore} from "@stores/entity-store.service";
import {AppBaseComponent} from "@app/appBaseComponent";
import {takeUntil} from "rxjs/operators";
import {Entity, UserReference} from "@api";
import {Observable} from "rxjs";
import {UserService} from "@services/user.service";

@Component({
             selector: 'rt-entity-info-sidebar',
             templateUrl: './entity-info-sidebar.component.html',
             styleUrls: ['./entity-info-sidebar.component.scss'],
             host: {
               class: "sidebar-right sidebar-informations"
             }
           })
export class EntityInfoSidebarComponent extends AppBaseComponent {

  @HostBinding("class.open")
  open: boolean;
  readonly entity$: Observable<Entity>;
  localAdmins: UserReference[] = [];
  private readonly _entityStore: EntityStore;

  private readonly _changeDetectorRef: ChangeDetectorRef;

  private readonly _userService: UserService;

  constructor(entityStore: EntityStore, userService: UserService, changeDetectorRef: ChangeDetectorRef) {
    super();
    this._userService = userService;
    this._changeDetectorRef = changeDetectorRef;
    this._entityStore = entityStore;
    entityStore
      .observe("currentEntity")
      .pipe(takeUntil(this.destroyed$))
      .subscribe(entity => this.updateEntity(entity));
    this.entity$ = entityStore.observe("currentEntity");
  }

  private updateEntity(entity: Entity) {
    this.open = !!entity;
    this.localAdmins = [];
    this._changeDetectorRef.markForCheck();
    if (entity?.countries?.any() === true)
      void this._userService
        .getLocalAdminsByCountry(...entity.countries.map(c => c.isoAlpha2))
        .then(admins => {
          const entityChanged = this._entityStore.get("currentEntity")?.id !== entity.id;
          if (entityChanged)
            return
          this.localAdmins = admins;
          this._changeDetectorRef.markForCheck()
        })
  }

  close(): void {
    this._entityStore.update("currentEntity", null)
  }

}
