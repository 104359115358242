import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {AccountStore, ShipmentStore} from "@stores";
import {combineLatest, from, fromEvent, merge, Observable, Subject} from "rxjs";
import {debounceTime, filter, map, take, takeUntil} from "rxjs/operators";
import {ShipmentListLayoutMapper, ShipmentListFields} from "@app/page-components/shipment/shipment-list/shipment-list-fields";
import {ShipmentOverviewItem} from "@app/page-components/shipment/shipment-list/shipment-overview-item";
import {FieldRenderer} from "../field-renderer.service";
import {trackById} from "@app/utils/track-by-id";
import {DangerousGoodsMasterData} from "@api";
import {AppBaseComponent} from "@app/appBaseComponent";
import {converter} from "protobufjs";

@Component({
             selector: 'rt-shipment-list-line-view',
             templateUrl: './shipment-list-line-view.component.html',
             styleUrls: ['./shipment-list-line-view.component.scss'],
             host: {
               class: "shipment-list-scroll-container"
             },
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class ShipmentListLineViewComponent extends AppBaseComponent implements AfterViewInit {

  @Input("skeleton-mode")
  skeletonMode: boolean = false;

  @ViewChild("table")
  table: ElementRef<HTMLElement>;

  @Input("skeleton-item-count")
  skeletonItemCount: number = 24;

  readonly shipments$: Observable<ShipmentOverviewItem[]>;
  readonly enableOverflow$: Observable<boolean>;
  readonly listLayout$: Observable<ShipmentListLayoutMapper[]>;
  readonly renderer: FieldRenderer;
  readonly trackById = trackById;
  readonly dangerousGoodsItem = ShipmentListFields["DangerousGoods"];
  hideCellsClass$: Observable<string>;
  private readonly _accountStore: AccountStore;
  private readonly _shipmentStore: ShipmentStore;
  private readonly _elementRef: ElementRef;
  private readonly _initialized = new Subject<void>();

  constructor(shipmentStore: ShipmentStore, accountStore: AccountStore, renderer: FieldRenderer, elementRef: ElementRef) {
    super();
    this._shipmentStore = shipmentStore;
    this.renderer = renderer;
    this._accountStore = accountStore;
    this._elementRef = elementRef;
    this.shipments$ = shipmentStore.observe("shipmentOverviewList");
    this.enableOverflow$ = accountStore.observe("listLayoutOverflow")
    this.listLayout$ = accountStore
      .observe("shipmentListLayout")
      .pipe(map(l => l.map(item => ShipmentListFields[item])));
    const itemWidths$ =
      combineLatest(
        this.listLayout$,
        merge(from([]),this._shipmentStore.observe("shipmentOverviewList"))
      )
        .pipe(
          takeUntil(this.destroyed$),
          debounceTime(10),
          map(() => {
            const output: number[] = [];
            let table = this.table.nativeElement;
            table.classList.add("force-show-all-columns")
            const columns = table.firstElementChild.children
            for (let i = 0; i < columns.length; i++) {
              let column = columns[i];
              if (column instanceof HTMLDivElement && !column.classList.contains("filler"))
                output.push(column.clientWidth)
            }
            table.classList.remove("force-show-all-columns")
            return output;
          }));
    this.hideCellsClass$ =
      combineLatest(
        merge(
          fromEvent(window, "resize").pipe(map(() => {
          })),
          this._initialized.asObservable()
        )
          .pipe(
            map(() => (elementRef.nativeElement as HTMLElement)?.clientWidth || null),
            filter(v => !!v)
          ),
        this.enableOverflow$,
        itemWidths$
      )
        .pipe(
          takeUntil(this.destroyed$),
          map(([width, overflowEnabled, itemWidths]) => {
            if (overflowEnabled)
              return "";
            let maxDisplayableItems = 0
            for (const item of itemWidths) {
              width -= item
              if (width < 0)
                break;
              maxDisplayableItems++;
            }
            return `show-${maxDisplayableItems}-cells`
          })
        );
  }

  static renderDgList(dgList: Grouping<DangerousGoodsMasterData, string>[]) {
    if (!dgList?.any())
      return "–";
    let output = "";
    for (const dg of dgList) {
      output += `<p class="dangerous-goods-link"
   data-popover-renderer="ng-dangerous-goods"
   data-dangerous-goods='${JSON.stringify(dg.items)}'
>UN ${dg.key}</p>
`
    }
    return output;
  }

  ngAfterViewInit(): void {
    this._initialized.next();
  }

  renderItem = (shipment: ShipmentOverviewItem, layout: ShipmentListLayoutMapper[]): string => {
    let output = "";
    const renderer = this.renderer;
    for (const field of layout) {
      const value = field.getValue(shipment);
      const isDgList = field === this.dangerousGoodsItem;
      const content = isDgList
                      ?
                      ShipmentListLineViewComponent.renderDgList(shipment.dangerousGoods)
                      : field.getContent(shipment, renderer, value)
      let title = content
      if (field.getTitle)
        title = field.getTitle(shipment, renderer, value)
      output += `<div
            title="${title}"
            class="${field.size || ''} ${field.getClass ? field.getClass(field.getValue(shipment)) : ''}">
            ${content}
          </div>`
    }
    return output + "<div class='filler'></div>";
  };
}
