import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {JSONData, PhoneWithCountry} from "@api";
import { CountryService } from "@services/country.service";
import { JSONCallingCode } from "../../../../../RRTClasses/JSON.cs";
//import JSONCallingCode = server.JSONCallingCode;
import {ISelectOptions} from "@widgets/Select";
import {Alignment} from "@utils/Enums";
import {InputValidatorDirective} from "@directives";
import {INumericInputOptions} from "@widgets/NumericInput";
import {ISelectListItem} from "@widgets/SelectList";
import {TypedStoreAccessor} from "@stores/store-accessor";
import {map, takeUntil, takeWhile} from "rxjs/operators";
import {combineLatest} from "rxjs";
import {AppBaseComponent} from "@app/appBaseComponent";

@Component({
             selector: 'rt-phone-number-select',
             templateUrl: './phone-number-select.component.html',
             styleUrls: ['./phone-number-select.component.scss'],
             host: {
               class: "input-group stretch areacode-select"
             }
           })
export class PhoneNumberSelectComponent extends AppBaseComponent implements AfterViewInit  {
  @Output() phoneNumberChange = new EventEmitter<string>();
  @Output() countryChange = new EventEmitter<string>();
  readonly countryItems: JSONData[];
  readonly selectSettings: ISelectOptions<JSONCallingCode> = {
    "class": "search-select droplist-button",
    displayIcon: true,
    search: true,
    closeOnSelect: true,
    allowNoSelection: true,
    autoChangeDirection: false,
    autoPosition: false,
    alignment: Alignment.Start,
    allowGrowing: true,
    selectedOnTop: false,
    visualContainer: "parent",
    placeholderRenderer: (items) => items.length
                                    ? `<span class="${items[0].ajaxData.icon}" title="${items[0].ajaxData.label}"></span>`
                                    : ""
  };
  readonly numericControlOptions: INumericInputOptions = {
    decimalPrecision: 0,
    allowGroupSeperator: false,
    selectTextOnFocus: false,
    enableScrolling: false,
    allowSpaces: true,
    enableMaxLengthHandler: true
  };
  @ViewChild("select", {read: InputValidatorDirective})
  selectValidator: InputValidatorDirective
  areaCode: string | undefined;
  private readonly _countryService: CountryService;
  private _storeAccessor: TypedStoreAccessor<PhoneWithCountry>;

  constructor(countryService: CountryService) {
    super();
    this._countryService = countryService;
    this.countryItems = countryService.getCallingCodes();
      this.phoneNumberChange
      .pipe(
        takeUntil(this.destroyed$),
        map((number): PhoneWithCountry | null => {
          if (!number || !this.countryCode )
            return null;
          return { number, countryCode: this.countryCode }
      }))
      .subscribe(p => {
        this._storeAccessor?.set(p);
      })
  }

  _fallbackCountryCode = "";

  @Input()
  set fallbackCountryCode(value: string) {
    this._fallbackCountryCode = value;
    if (!this._phoneNumberWithoutAreaCode)
      this.countryCode = value;
  }

  @Input()
  set storeAccessor(value: TypedStoreAccessor<PhoneWithCountry>) {
    this._storeAccessor = value;
    value.observe
      .pipe(takeWhile(() => this._storeAccessor === value))
      .subscribe(p => {
        this.phoneNumber = p?.number || null;
        this.countryCode = p?.countryCode;
      })
  }

  _phoneNumberWithoutAreaCode = "";

  get phoneNumberWithoutAreaCode(): string {
    return this._phoneNumberWithoutAreaCode;
  }

  set phoneNumberWithoutAreaCode(value: string) {
    this._phoneNumberWithoutAreaCode = value;
    const phoneNumber = this.areaCode && value.trim() ? this.areaCode + " " + value.trim() : null;
    this._setPhoneNumber(phoneNumber);
  }

  private _setPhoneNumber(phoneNumber: string) {
    if (phoneNumber)
      phoneNumber = phoneNumber.trim();
    this._phoneNumber = phoneNumber;
    this.phoneNumberChange.emit(phoneNumber);
  }

  private _phoneNumber: string;

  get phoneNumber(): string {
    return this._phoneNumber;
  }

  @Input() set phoneNumber(value: string) {
    this._phoneNumber = value;
    this.areaCode = /^\+\d+/.exec(value)?.toString() || "";
    if (this.areaCode) {
      this._countryCode = "";
      this._phoneNumberWithoutAreaCode = value.substring(this.areaCode.length).trim();
    } else {
      this._countryCode = "";
      this._phoneNumberWithoutAreaCode = "";
    }
  }

  _countryCode: string;

  get countryCode(): string {
    return this._countryCode;
  }

  @Input() set countryCode(value: string) {
    this._countryCode = value ?? this._fallbackCountryCode;
    if (this._countryCode) {
      this.areaCode = this._countryService.getCallingCode(this._countryCode) || "";
      if (this.phoneNumberWithoutAreaCode) {
        const phoneNumber = `${this.areaCode} ${this._phoneNumberWithoutAreaCode}`;
        this._setPhoneNumber(phoneNumber)
      }
    } else
      this.areaCode = null;
  }

  ngAfterViewInit(): void {
    const required = this.selectValidator.validateData.validators.required;
    required.isActive = () => {
      return !!this._phoneNumberWithoutAreaCode?.trim();
    }
    required.errorMessage="Please select an area code"
  }

  phoneNumberInput($event: Event): void {
    this.phoneNumberWithoutAreaCode = ($event.target as HTMLInputElement).value;
  }

  countrySelected(item: ISelectListItem): void {
    if (!item)
      this.countryCode = null;
    else {
      this.countryCode = (item.ajaxData as JSONDataContainer<Country>).data.isoAlpha2;
      this.countryChange.emit(this.countryCode);
    }
  }
}
