import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {fromPromise} from "rxjs/internal-compatibility";
import {debounceTime, filter, map, take} from "rxjs/operators";
import {hasValue} from "@utils/rxjs-extensions";

@Injectable()
export class AntiForgeryTokenInterceptor implements HttpInterceptor {

  private _requestCounter = 0;
  private readonly _antiForgeryTokenSubject = new ReplaySubject<string>(1)
  private readonly _antiForgeryToken$ = this._antiForgeryTokenSubject
                                            .pipe(filter(hasValue), take(1));

  private readonly _updateAntiForgerTokenSubject = new Subject();

  constructor() {
    this._updateAntiForgerTokenSubject
        .pipe(
          map(() => {this._requestCounter++}),
          debounceTime(50))
        .subscribe(() => void this.loadNewTokenFromServer())
    this.updateAntiForgeryToken();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return fromPromise(this._injectAntiForgeryToken(request, next))
  }

  async loadNewTokenFromServer(): Promise<void> {
    this._antiForgeryTokenSubject.next(null);
    const requestCountBeforeResponse = this._requestCounter;
    const response = await fetch("/api/account/AntiForgeryToken");
    if (!response.ok) {
      console.error(await response.json());
      return;
    }
    if (this._requestCounter !== requestCountBeforeResponse)
      return;
    this._antiForgeryTokenSubject.next(await response.text())
  }

  updateAntiForgeryToken(): void {
    this._antiForgeryTokenSubject.next(null);
    this._updateAntiForgerTokenSubject.next();
  }

  private async _injectAntiForgeryToken(request: HttpRequest<unknown>, next: HttpHandler): Promise<HttpEvent<unknown>> {
    const token = await this._antiForgeryToken$.toPromise();
    const requestWithToken = request.clone({headers: request.headers.append("X-CSRF-TOKEN", token)});
    return await next.handle(requestWithToken).toPromise();
  }
}

