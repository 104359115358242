import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {Modal} from "@widgets/modal";
import {TypedStoreAccessor} from "@stores/store-accessor";
import {takeUntil, takeWhile} from "rxjs/operators";
import {AppBaseComponent} from "@app/appBaseComponent";

const Cropper = require("cropperjs");

@Component({
             selector: 'rt-avatar-editor',
             templateUrl: './avatar-editor.component.html',
             styleUrls: ['./avatar-editor.component.scss'],
           })
export class AvatarEditorComponent extends AppBaseComponent {

  @Input()
  picture: string
  @Output()
  pictureChange = new EventEmitter<string>()
  @ViewChild("fileInput")
  fileInput: ElementRef<HTMLInputElement>;
  forceHint: boolean;
  private _modal: Modal;
  private _cropper: Cropper;

  constructor() {
    super();
    this._init();
  }

  private _storeAccessor: TypedStoreAccessor<string>;

  @Input()
  set storeAccessor(value: TypedStoreAccessor<string>) {
    this._storeAccessor = value;
    value.observe
         .pipe(
           takeUntil(this.destroyed$),
           takeWhile(() => this._storeAccessor === value)
         )
         .subscribe(p => this.picture = p);
    this.pictureChange
        .pipe(
          takeUntil(this.destroyed$),
          takeWhile(() => this._storeAccessor === value)
        )
        .subscribe(p => value.set(p));
  }

  ngOnDestroy(): void {
    this._modal.destroy();
  }

  @HostListener("dragover", ["$event"])
  onDragOver(e: Event) {
    e.preventDefault();
  }


  openFileDialog() {
    this.fileInput.nativeElement.click();
    this.forceHint = true;
  }

  removePicture(): void {
    this._setPicture(null);
  }

  fileInputChange(): void {
    let inputElement = this.fileInput.nativeElement;
    let file = inputElement.files[0];
    if (file) {
      this._loadPictureIntoCropper(URL.createObjectURL(file))
      inputElement.value = "";
    }
  }

  getImageUrl(data: string) {
    if (!data)
      return "";
    if (/^https?:\/\//.test(data) || data.startsWith("blob:") || data.startsWith("data:"))
      return data;
    return "data:image/png;base64," + data;
  }

  @HostListener("window:mousemove")
  @HostListener("window:touchstart")
  @HostListener("window:focus")
  private _hideHint() {
    if (this._modal.isOpen())
      return;
    this.forceHint = false;
  }

  private _init(): void {

    const cropbox = document.createElement("div");
    cropbox.classList.add("cropbox")

    // Create image container element
    const image = document.createElement("img")
    cropbox.appendChild(image);

    // Initiate cropper with sittings
    this._cropper = new Cropper(image, {
      dragMode: "move",
      movable: false,
      zoomable: false,
      guides: false,
      background: false,
      cropBoxResizable: true,
      aspectRatio: 1,
      ready: () => {
        this._resetCropper()
      }
    });
    this._modal = new Modal(null, {
      contentElement: cropbox,
      header: null,
      buttons: [
        // Create OK button
        $(document.createElement("a"))
          .addClass("button")
          .text("OK")
          .on("click", () => this._acceptCrop()),

        // Create CANCEL button
        $(document.createElement("a"))
          .addClass("button secondary")
          .append("Cancel")
          .on("click", () => this._modal.closeModal())
      ]
    });
  }

  @HostListener("drop", ["$event"])
  private _drop(event: DragEvent): void {
    const files = event.dataTransfer.files;
    if (files) {
      this._loadPictureIntoCropper(window.URL.createObjectURL(files[0]));
    }
    event.preventDefault();
  }

  private _acceptCrop(): void {
    const canvas = this._cropper.getCroppedCanvas();
    if (canvas.width > 200)
      canvas.resizeTo(200, 200);
    this._setPicture(canvas.toDataURL().split(",")[1]);
    this._modal.closeModal();
  }

  private _resetCropper() {
    this._cropper.zoomTo(1);
    let imageData = this._cropper.getImageData();
    // this._cropper.zoomTo( 1);
    // imageData = this._cropper.getImageData();

    const cropBoxWidth = Math.min(imageData.width, imageData.height);
    const containerData = this._cropper.getContainerData();

    this._cropper.setCropBoxData({
                                   left: (containerData.width - cropBoxWidth) / 2,
                                   top: (containerData.height - cropBoxWidth) / 2,
                                   width: cropBoxWidth
                                 });
  }

  private _loadPictureIntoCropper(picture: string) {
    this._cropper.replace(picture);
    this._modal.openModal();
  }

  private _setPicture(picture: string) {
    this.picture = picture;
    this.pictureChange.emit(picture);
  }
}
