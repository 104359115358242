import {Routes} from "@angular/router";
import {BookingModel} from "./BookingModel";
import {Subject} from "rxjs";
import {checkScrollMargin} from "@utils/Utils";
import {BookingViewData, TemplateViewData} from "@services";
import {filter} from "rxjs/operators";
import {GetViewIndex, IViewDef, ViewArrangement} from "@booking/booking-views";
import {BookingServiceListener} from "@services/booking-service-listener.service";


export class ViewManager {
  static mode: "booking" | "template" = "booking";
  private static readonly _activeViewIndexSubject = new Subject<number>();
  static readonly activeViewIndex = ViewManager._activeViewIndexSubject.asObservable()

  static get ActiveView() {
    return ViewArrangement.activeView ?? null;
  }

  static set ActiveView(value) {
    if (value === ViewArrangement.activeView) {
      return;
    }
    ViewArrangement.activeView?.left();
    ViewArrangement.activeView = value;
    value.entered();
    checkScrollMargin();
    this._activeViewIndexSubject.next(GetViewIndex(value));
  }

  static setToBookingMode() {
    if (!ViewArrangement)
      return;
    ViewArrangement[0].visible = false;
    ViewArrangement.last().title = "Submit";
    this.mode = "booking";
  }

  static setToTemplateMode() {
    if (!ViewArrangement)
      return;
    ViewArrangement[0].visible = true;
    ViewArrangement.last().title = "Save";
    ViewManager.mode = "template";
  }
}

export module ViewManager {

  let bookingModel: BookingModel;
  const bookingServiceListener = new BookingServiceListener();
  bookingServiceListener.templateLoaded$
                        .pipe(filter(m => !!m))
                        .subscribe(model => {
                          ViewArrangement.forEach(v => {
                            v.valid = v.validate(model, bookingServiceListener.currentBookingMode);
                            v.isActivated = true;
                          })
                        });
  bookingServiceListener.mode$.subscribe(m => {
    if (m === "template")
      ViewManager.setToTemplateMode();
    else
      ViewManager.setToBookingMode();
  });

  function bookingModelChanged(model: BookingModel) {
    bookingModel = model;
    model.disableNavigationValidation = true;
    resetViews();
    model.viewStates.map((state, i) => {
      let view = ViewArrangement[i];
      if (typeof state.valid === "boolean" || state.isActivated) {
        if (view.viewObject)
          view.viewObject.validate();
        else {
          view.valid = view.validate(model, bookingServiceListener.currentBookingMode);
          view.needsValidation;
        }
      }
      view.isActivated = state.isActivated;
    });
    model.disableNavigationValidation = false;
  }

  bookingServiceListener.bookingModel$
                        .pipe(filter(m => !!m))
                        .subscribe((model:BookingModel) => bookingModelChanged(model));

  function resetViews() {
    const serviceView = ViewArrangement[1];
    ViewArrangement.forEach(v => {
      //v.isActivated = v === serviceView;
      v.isActivated = false;
      v.valid = undefined;
      v.viewObject = null;
      v.needsValidation = false;
    })
  }

  bookingServiceListener.bookingCancelled$.subscribe(() => resetViews());
  ViewManager.activeViewIndex
             .subscribe(i => {
               if (bookingModel)
                 bookingModel.lastViewIndex = i;
             })


  export function GenerateRoutes(views: IViewDef[]) {
    const routes: Routes = [];
    for (const view of views) {
      routes.push({
                    path: view.address.replace(/^\/?booking\//, ""),
                    component: view.type,
                    data: {
                      viewData: () => {
                        const templateInformation = new BookingServiceListener().currentBookingModel?.booking.templateInformation;
                        const currentTemplateId = templateInformation?.templateID;
                        const currentTemplateName = templateInformation?.templateName;
                        const step = view.step;
                        if (step === "Overview")
                          return new TemplateViewData("Overview", currentTemplateId);
                        const isTemplateMode = ViewManager.mode === "template";
                        if (isTemplateMode)
                          return new TemplateViewData(step, currentTemplateId);
                        return new BookingViewData(step);
                      }
                    }
                  });
    }
    routes.push({path: "**", redirectTo: ViewArrangement[0].address});
    return routes;
  }

}
