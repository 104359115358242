import {PrepareJsonDataFromServer} from "@utils/Utils";
import {Injectable} from "@angular/core";

type CacheItem = { code: string, usage: number, lastUsage: Date };

@Injectable({providedIn: "root"})
export class LastUsedDangerousGoodsCache {
    private readonly _cache: CacheItem[] = [];
    private readonly _cacheSize = 100;

    constructor() {
        if (window.localStorage && window.localStorage.getItem) {
            let loadedItems: CacheItem[] = PrepareJsonDataFromServer(JSON.parse(window.localStorage.getItem(
                "dangerousGoodsCache"))) || [];
            if (loadedItems.length > this._cacheSize) {
                loadedItems = loadedItems.orderBy(LastUsedDangerousGoodsCache._calculateScore);
            }
            this._cache.pushRange(loadedItems);
        }
    }

    add(code: string) {
        const cacheItem = this._cache.firstOrDefault(i => i.code === code);
        if (cacheItem) {
            cacheItem.usage++;
            cacheItem.lastUsage = new Date();
        } else {
            if (this._cache.length > this._cacheSize) {
                this._cache.remove(this._cache.orderBy(LastUsedDangerousGoodsCache._calculateScore).last());
            }
            this._cache.push({code: code, lastUsage: new Date(), usage: 1});
        }
        this.saveCache();
    }

    get(amount: number) {
        return this._cache
                   .orderBy(LastUsedDangerousGoodsCache._calculateScore)
                   .slice(0, amount)
                   .map(i => i.code);
    }

    saveCache() {
        if (window.localStorage && window.localStorage.getItem) {
            window.localStorage.setItem("dangerousGoodsCache", JSON.stringify(this._cache));
        }
    }

    private static _calculateScore(item: CacheItem) {
        const timeDifference = (new Date().getTime() - item.lastUsage.getTime()) / 1000 / 3600 / 24;
        return item.usage + (10 / timeDifference);
    }
}
