import {CardLayoutItem, ContainerCardLayoutItem, DangerousGoodsData, DeliveryStatus, DimensionValue, PortLocation, ShipmentEvent, TransportMode} from "@api";
import {DateContainer, ShipmentOverviewItem} from "@app/page-components/shipment/shipment-list/shipment-overview-item";
import {FieldRenderer} from "./field-renderer.service";
import {IsFclOrBuyersConsolPipe} from "@app/utils/rt-date.pipe";

export type OverviewFieldTypes =
  string
  | string[]
  | DateContainer
  | PortLocation
  | DimensionValue
  | number
  | Grouping<DangerousGoodsData, string>[]
  | ShipmentEvent
  | TransportMode
  | DeliveryStatus
  | null;

export type OverviewFieldRenderer = "string" | "stringArray" | "date" | "port" | "city" | "dimension" | "dangerousGoods" | "event";

export interface ShipmentCardLayoutMapper<T extends OverviewFieldTypes = OverviewFieldTypes> {
  class?: "wide";
  title: Func1<ShipmentOverviewItem, string>;
  hidden?: Func1<ShipmentOverviewItem, boolean>,
  getContent: Func2<ShipmentOverviewItem, FieldRenderer, string>,
  isDefault?:true
}

export const ShipmentBoxFields: { readonly [P in CardLayoutItem]: ShipmentCardLayoutMapper } = {
  [CardLayoutItem.ArrivalDate]: {
    title: () => "Arrival Date",
    getContent: (s, r) => r.renderDateValue(s.arrivalDate)
  },
  [CardLayoutItem.Chargeable]: {
    title: () => "Chargeable",
    getContent: (s, r) => r.renderDimensionValue(s.chargeable)
  },
  [CardLayoutItem.ClientReferences]: {
    class: "wide",
    title: () => "Client References",
    getContent: (s, r) => r.renderStringArray(s.clientReferences)
  },
  [CardLayoutItem.Consignee]: {
    title: () => "Consignee",
    getContent: (s, r) => r.renderString(s.consigneeName),
    isDefault: true
  },
  [CardLayoutItem.Container]: {
    title: s => `Container <span class="label white">${(s.containerCount ? s.containerCount : '')}</span>`,
    getContent: (s, r) => s.containerCount
      ? r.renderStringArray(s.containersNumbers, "T.B.D.")
      : r.renderString(""),
    hidden: s => !IsFclOrBuyersConsolPipe.check(s.containerMode),
    class: "wide",
    isDefault: true
  },
  [CardLayoutItem.CustomsEntryNumber]: {
    title: () => "Customs Entry Number",
    getContent: (s, r) => r.renderStringArray(s.customsEntryNumbers)
  },
  [CardLayoutItem.Destination]: {
    title: () => "Destination",
    getContent: (s, r) => r.renderCityLocationValue(s.destination),
    isDefault: true
  },
  [CardLayoutItem.DeliveryDate]: {
    title: () => "Delivery Date",
    getContent: (s, r) => r.renderDateValue(s.deliveryDate)
  },
  [CardLayoutItem.DeliveryLocation]: {
    title: () => "Delivery Location",
    getContent: (s, r) => r.renderString(s.deliveryLocation)
  },
  [CardLayoutItem.DepartureDate]: {
    title: () => "Departure Date",
    getContent: (s, r) => r.renderDateValue(s.departureDate)
  },
  [CardLayoutItem.GoodsDescription]: {
    class: "wide", title: () => "Goods Description",
    getContent: (s, r) => r.renderString(s.goodsDescription)
  },
  [CardLayoutItem.Incoterm]: {
    title: () => "Incoterm",
    getContent: (s, r) => r.renderString(s.incoTerm)
  },
  [CardLayoutItem.InvoiceNumber]: {
    title: () => "Invoice Number",
    getContent: (s, r) => r.renderStringArray(s.invoiceNumbers)
  },
  [CardLayoutItem.OrderNumber]: {
    class: "wide", title: () => "Order Number",
    getContent: (s, r) => r.renderStringArray(s.orderNumbers)
  },
  [CardLayoutItem.Origin]: {
    title: () => "Origin",
    getContent: (s, r) => r.renderCityLocationValue(s.origin),
    isDefault: true
  },
  [CardLayoutItem.PickupDate]: {
    title: () => "Pickup Date",
    getContent: (s, r) => r.renderDateValue(s.pickupDate)
  },
  [CardLayoutItem.PickupLocation]: {
    title: () => "Pickup Location",
    getContent: (s, r) => r.renderString(s.pickupLocation)
  },
  [CardLayoutItem.Pieces]: {
    title: () => "Pieces",
    getContent: (s, r) => r.renderInteger(s.pieces),
    isDefault: true
  },
  [CardLayoutItem.PortOfDischarge]: {
    title: () => "Port of Discharge",
    getContent: (s, r) => r.renderPortLocationValue(s.portOfDischarge, s.portTypes),
    hidden: s => s.hidePorts,
    isDefault: true
  },
  [CardLayoutItem.PortOfLoading]: {
    title: () => "Port of Loading",
    hidden: s => s.hidePorts,
    getContent: (s, r) => r.renderPortLocationValue(s.portOfLoading, s.portTypes),
    isDefault: true
  },
  [CardLayoutItem.RoehligReferences]: {
    title: () => "Röhlig References",
    getContent: (s, r) => r.renderStringArray(s.roehligReferences)
  },
  [CardLayoutItem.Shipper]: {
    title: () => "Shipper",
    getContent: (s, r) => r.renderString(s.shipperName),
    isDefault: true
  },
  [CardLayoutItem.VesselFlightNumber]: {
    title: s => {
      let completeTMode: string[] = [];
      for (let transportMode of s.transportModes) {
        switch (transportMode) {
          case TransportMode.Air:
            completeTMode.push("Flight Number");
            break;
          case TransportMode.Sea:
            completeTMode.push("Vessel");
            break;
          default:
            completeTMode.push("");
            break;
        }
      }
      return completeTMode.join(" / ");

    },
    getContent: (s, r) => r.renderStringArray(s.vesselFlightNumbers)
  },
  [CardLayoutItem.Volume]: {
    title: () => "Volume",
    getContent: (s, r) => r.renderDimensionValue(s.volume)
  },
  [CardLayoutItem.Weight]: {
    title: () => "Weight",
    getContent: (s, r) => r.renderDimensionValue(s.weight),
    isDefault: true
  },
}


export const ShipmentBoxFieldsContainerList: { readonly [P in ContainerCardLayoutItem]?: ShipmentCardLayoutMapper } = {
  [ContainerCardLayoutItem.Shipper]: ShipmentBoxFields.Shipper,
  [ContainerCardLayoutItem.Consignee]: ShipmentBoxFields.Consignee,
  [ContainerCardLayoutItem.Origin]: ShipmentBoxFields.Origin,
  [ContainerCardLayoutItem.Destination]: ShipmentBoxFields.Destination,
  [ContainerCardLayoutItem.Pieces]: ShipmentBoxFields.Pieces,
  [ContainerCardLayoutItem.Weight]: ShipmentBoxFields.Weight,
  [ContainerCardLayoutItem.Volume]: ShipmentBoxFields.Volume,
  [ContainerCardLayoutItem.PortOfDischarge]: ShipmentBoxFields.PortOfDischarge,
  [ContainerCardLayoutItem.PortOfLoading]: ShipmentBoxFields.PortOfLoading,
  [ContainerCardLayoutItem.ArrivalDate]: ShipmentBoxFields.ArrivalDate,
  [ContainerCardLayoutItem.Chargeable]: ShipmentBoxFields.Chargeable,
  [ContainerCardLayoutItem.ClientReferences]: ShipmentBoxFields.ClientReferences,
  [ContainerCardLayoutItem.DeliveryDate]: ShipmentBoxFields.DeliveryDate,
  [ContainerCardLayoutItem.DeliveryLocation]: ShipmentBoxFields.DeliveryLocation,
  [ContainerCardLayoutItem.DepartureDate]: ShipmentBoxFields.DepartureDate,
  [ContainerCardLayoutItem.GoodsDescription]: ShipmentBoxFields.GoodsDescription,
  [ContainerCardLayoutItem.Incoterm]: ShipmentBoxFields.Incoterm,
  [ContainerCardLayoutItem.InvoiceNumber]: ShipmentBoxFields.InvoiceNumber,
  [ContainerCardLayoutItem.OrderNumber]: ShipmentBoxFields.OrderNumber,
  [ContainerCardLayoutItem.PickupDate]: ShipmentBoxFields.PickupDate,
  [ContainerCardLayoutItem.PickupLocation]: ShipmentBoxFields.PickupLocation,
  [ContainerCardLayoutItem.RoehligReferences]: ShipmentBoxFields.RoehligReferences,
  [ContainerCardLayoutItem.VesselFlightNumber]: ShipmentBoxFields.VesselFlightNumber,
  [ContainerCardLayoutItem.CustomsEntryNumber]: ShipmentBoxFields.CustomsEntryNumber,
}
