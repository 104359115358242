import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {FilterPreset} from "../../blueFilterPreset";

@Directive({
  selector: 'select[rtFilterPresets]'
})
export class FilterPresetsDirective implements OnInit{

  private readonly _elementRef: ElementRef;

  @Input("rtFilterPresets")
  type: "order" | "shipment"

  constructor(elementRef:ElementRef) {
    this._elementRef = elementRef;
  }

  ngOnInit(): void {
    new FilterPreset(this._elementRef.nativeElement, this.type);
  }

}
