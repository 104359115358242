import {concat, Observable, Subject, Subscription} from "rxjs";
import {filter, map} from "rxjs/operators";
import {HashMap} from "@utils/HashMap";
import {createDeepCopy} from "@utils/createDeepCopy";
import {StoreAccessor, TypedStoreAccessor} from "@stores/store-accessor";
import {StoreDebugHelper} from "@stores/store-debug-helper";
import {StoreCutoutAccessor} from "@stores/store-cutout-accessor";

// eslint-disable-next-line @typescript-eslint/ban-types
export type StoreState = object;

export interface IStoreUpdate<T extends StoreState = never> {
  keys: (keyof T)[];
  changes: Partial<T>;
  newState: Partial<T>;
}


export type StoreCutoutAccessors<T extends StoreState, K extends keyof T, KS extends keyof T[K] = keyof T[K]> =
  { [P in KS]: TypedStoreAccessor<T[K][KS]> }
export type TypedStoreAccessors <T extends StoreState, K extends keyof T>= {[key in K]: TypedStoreAccessor<T[key]>};
export type StoreProjectionMapping<T, TP> = { [P in keyof TP]: Func1<Partial<T>, TP[P]> };


// eslint-disable-next-line @typescript-eslint/ban-types
export abstract class Store<T extends StoreState, TP extends StoreState = {}> {
  abstract getName(): string;
  protected readonly _initialState: Partial<T>;
  private _currentState: Partial<(T & TP)> = {};
  private readonly _hashMap = new HashMap<(T & TP)>();
  private readonly _updateSubject = new Subject<IStoreUpdate<(T & TP)>>();
  private readonly _projectorMapping: StoreProjectionMapping<T, TP>;

  protected constructor(projector: StoreProjectionMapping<T, TP>, initialState: Partial<T>, loadInitialState = true) {
    const initialStateCopy = createDeepCopy(initialState);
    this._update$.subscribe(u => {
      this._currentState = createDeepCopy(u.newState);
    });
    StoreDebugHelper.registerStore(this);
    this._initialState = initialStateCopy || {};
    this._projectorMapping = projector;
    if (loadInitialState && initialStateCopy)
      this.update(initialStateCopy);
  }

  destroy(): void {
    this._updateSubject.complete();
  }

  update(stateUpdate: Partial<T>, forceUpdate?: boolean): "updated" | "noChanges";
  update<K extends keyof T>(key: K, value: T[K], forceUpdate?: boolean): "updated" | "noChanges";
  update(stateMapper: Func1<Partial<T>, Partial<T>>, forceUpdate?: boolean): "updated" | "noChanges";
  update<K extends keyof T>(stateUpdate: Partial<T> | Func1<Partial<T>, Partial<T>> | K, value?: T[K] | boolean, forceUpdate = false): "updated" | "noChanges" {
    let stateUpdateObject: Partial<T>;
    if (typeof stateUpdate === "function") {
      stateUpdateObject = stateUpdate(createDeepCopy(this._currentState));
      forceUpdate = (value as boolean) || false;
    } else if (typeof stateUpdate === "object") {
      stateUpdateObject = stateUpdate;
      forceUpdate = (value as boolean) || false;
    } else {
      stateUpdateObject = {};
      stateUpdateObject[stateUpdate] = value as T[K];
    }
    const storeUpdate = this._createStoreUpdate(stateUpdateObject);
    if (forceUpdate)
      storeUpdate.keys = storeUpdate.keys
                                    .concat(Object.keys(stateUpdateObject) as (keyof T)[])
                                    .distinct();
    this._updateSubject.next(storeUpdate);
    return this._hasChanges(storeUpdate) ? "updated" : "noChanges";
  }

  replace(newState: Partial<T>): "replaced" | "noChanges" {
    const storeUpdate = this._createStoreUpdate(newState);
    const removedKeys = this._getRemovedKeys(newState);
    storeUpdate.newState = newState as Partial<T & TP>;
    storeUpdate.keys.pushRange(removedKeys);
    removedKeys.forEach(k => {
      this._hashMap.delete(k)
    })
    this._updateSubject.next(storeUpdate);
    return this._hasChanges(storeUpdate) ? "replaced" : "noChanges";
  }

  observe<K extends keyof (T & TP)>(stateKeys: K[]): Observable<Partial<Pick<(T & TP), K>>>;
  observe<K extends keyof (T & TP)>(stateKey: K): Observable<(T & TP)[K]>;
  observe(): Observable<Partial<(T & TP)>>;
  observe<K extends keyof (T & TP)>(stateKeys?: K | K[]): Observable<(T & TP)[K]> | Observable<Pick<(T & TP), K>> | Observable<Partial<(T & TP)>> {
    if (stateKeys === undefined)
      return this._createStoreObservable();
    if (stateKeys instanceof Array)
      return this._createMultiKeyObservable(stateKeys);
    return this._createSingleKeyObservable(stateKeys);
  }

  subscribe<K extends keyof (T & TP)>(stateKeys: K[], next: Action1<Partial<Pick<(T & TP), K>>>): Subscription;
  subscribe<K extends keyof (T & TP)>(stateKey: K, next: Action1<(T & TP)[K]>): Subscription;
  subscribe(next: Action1<Partial<(T & TP)>>): Subscription;
  subscribe<K extends keyof (T & TP)>(stateKeys?: K | K[] | Action1<unknown>, next?: Action1<Partial<Pick<(T & TP), K>>>): Subscription {
    if (typeof stateKeys === "function")
      return this.observe().subscribe(stateKeys);
    else { // noinspection IfStatementWithIdenticalBranchesJS
      if (stateKeys instanceof Array)
        return this.observe(stateKeys).subscribe(next)
      else
        return this.observe(stateKeys).subscribe(next)
    }
  }

  get<K extends keyof (T & TP)>(stateKeys: K[]): Partial<Pick<(T & TP), K>>;
  get<K extends keyof (T & TP)>(stateKey: K): (T & TP)[K];
  get(): Partial<(T & TP)>;

  // get subscribe() {
  //   const observable = this.observe();
  //   return observable.subscribe.bind(observable) as typeof observable.subscribe;
  // }

  get<K extends keyof (T & TP)>(stateKeys?: K | K[]): (T & TP)[K] | Pick<(T & TP), K> | Partial<(T & TP)> {
    if (stateKeys === undefined)
      return createDeepCopy(this._currentState);
    if (stateKeys instanceof Array)
      return createDeepCopy(this._pickKeys(stateKeys, this._currentState));
    return createDeepCopy(this._currentState[stateKeys]);
  }

  getStoreAccessor<K extends keyof T>(key: K): StoreAccessor<T, TP, K> {
    return new StoreAccessor(this, key);
  }

  getStoreAccessors<K extends keyof T>(...keys: K[]): TypedStoreAccessors<T, K> {
    const output = {} as unknown as TypedStoreAccessors<T, K>;
    keys.forEach(k => output[k] = new StoreAccessor(this, k))
    return output;
  }

  getStoreCutoutAccessors<K extends keyof T, KS extends keyof T[K]>(key: K, subKeys: KS[]): StoreCutoutAccessors<T, K, KS> {
    const accessors = {} as StoreCutoutAccessors<T, K, KS>;
    for (const subKey of subKeys) {
      accessors[subKey] = new StoreCutoutAccessor(this, key, subKey);
    }
    return accessors
  }

  negate<K extends KeysOfType<T, boolean>>(...stateKeys: K[]): void {
    const newState: Partial<ExtractKeys<T, boolean>> = {}
    for (const stateKey of stateKeys) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      newState[stateKey] = !this._currentState[stateKey];
    }
    this.update(newState)
  }

  increment<K extends KeysOfType<T, number>>(...stateKeys: K[]): void {
    const newState: Partial<ExtractKeys<T, number>> = {}
    for (const stateKey of stateKeys) {
      const currentValue = this._currentState[stateKey] as unknown as number
      if (typeof currentValue === "number") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        newState[stateKey] = currentValue + 1;
      }
    }
    this.update(newState)
  }

  decrement<K extends KeysOfType<T, number>>(...stateKeys: K[]): void {
    const newState: Partial<ExtractKeys<T, number>> = {}
    for (const stateKey of stateKeys) {
      const currentValue = this._currentState[stateKey] as unknown as number
      if (typeof currentValue === "number") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        newState[stateKey] = currentValue - 1;
      }
    }
    this.update(newState)
  }

  hasValue(key: keyof T): boolean {
    const value = this._currentState[key];
    return value !== undefined && value !== null;
  }

  reset(): void {
    this.replace(this._initialState);
  }

  private readonly _hasChanges: Predicate<IStoreUpdate<(T & TP)>> = u => u.keys.any();

  private readonly _update$ =
    concat(
      new Observable(s => {
        if (this._currentState)
          s.next(this._createUpdateFromCurrentState());
        s.complete();
      }),
      this._updateSubject.asObservable()
    )
      .pipe(filter(this._hasChanges))
  ;

  private _createUpdateFromCurrentState(): IStoreUpdate<T & TP> {
    return {
      newState: createDeepCopy(this._currentState),
      changes: createDeepCopy(this._currentState),
      keys: Object.keys(this._currentState || {}) as (keyof (T & TP))[]
    };
  }

  private _createMultiKeyObservable<K extends keyof (T & TP)>(stateKeys: K[]): Observable<Pick<(T & TP), K>> {
    const keyFilter: Func2<IStoreUpdate<(T & TP)>, number, boolean> = (u, i) => {
      return stateKeys.any(k => u.keys.contains(k))
    };
    const selector: Func1<IStoreUpdate<(T & TP)>, Pick<(T & TP), K>> = u => this._pickKeys(stateKeys, u.newState);
    return this._update$.pipe(
      filter(keyFilter),
      map(selector),
      map(createDeepCopy)
    );
  }

  private _createSingleKeyObservable<K extends keyof (T & TP)>(stateKey: K): Observable<(T & TP)[K]> {
    return this._update$
               .pipe(
                 filter((u, i) => {
                   return u.keys.contains(stateKey);
                 }),
                 map(u => u.newState[stateKey] as (T & TP)[K])
               );
  }

  private _createStoreObservable() {
    return this._update$.pipe(
      map(u => u.newState),
      map(createDeepCopy)
    );
  }

  private _getRemovedKeys(newState: Partial<T>) {
    const keys = Object.keys(this._currentState);
    keys.removeRange(Object.keys(newState));
    return keys as (keyof T)[];
  }

  private _pickKeys<K extends keyof (T & TP)>(stateKeys: K[], state: Partial<(T & TP)>): Pick<(T & TP), K> {
    const extract = {} as Pick<(T & TP), K>;
    for (const key of stateKeys) {
      extract[key] = state[key] as (T & TP)[K];
    }
    return extract;
  }

  private _createStoreUpdate(update: Partial<T>) {
    const storeUpdate: IStoreUpdate<(T & TP)> = {
      keys: [],
      changes: {},
      newState: Object.assign({}, this._currentState)
    };
    this._applyUpdatesToStoreUpdate(update, storeUpdate);
    const projectionUpdate = this._crateProjectionsUpdate(storeUpdate.newState);
    this._applyUpdatesToStoreUpdate(projectionUpdate, storeUpdate);
    return storeUpdate;
  }

  private _applyUpdatesToStoreUpdate(update: Partial<T>, storeUpdate: IStoreUpdate<T & TP>): void;
  private _applyUpdatesToStoreUpdate(update: Partial<TP>, storeUpdate: IStoreUpdate<T & TP>): void;
  private _applyUpdatesToStoreUpdate(update: Partial<T & TP>, storeUpdate: IStoreUpdate<T & TP>): void {
    for (const prop of Object.keys(update) as (keyof (T & TP))[]) {
      const newValue = update[prop];
      const hash = HashMap.createHash(newValue);
      const currentValue = this._currentState[prop];
      const valueUndefinedOrUnchanged = newValue === undefined || currentValue === newValue;
      if (valueUndefinedOrUnchanged)
        continue;
      const ofSameType = newValue?.constructor === currentValue?.constructor;
      if (hash === this._hashMap.get(prop) && ofSameType)
        continue;
      this._hashMap.setHash(prop, hash);
      this._setValueInStoreUpdate(newValue, storeUpdate, prop);
    }
  }

  private _setValueInStoreUpdate(value: Partial<(T & TP)>[keyof (T & TP)], storeUpdate: IStoreUpdate<(T & TP)>, prop: keyof (T & TP)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
    const copy = createDeepCopy(value);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    storeUpdate.changes[prop] = copy;
    storeUpdate.keys.push(prop);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    storeUpdate.newState[prop] = copy;
  }

  private _crateProjectionsUpdate(newState: Partial<T & TP>) {
    const output = {} as TP;
    for (const key of Object.keys(this._projectorMapping) as (keyof TP)[]) {
      output[key] = this._projectorMapping[key](newState);
    }
    return output;
  }
}
