import {PreLoginViewData} from "@services/view-data";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class ForgotPasswordViewData extends PreLoginViewData {
  readonly mailAddress: string | null;

  constructor();
  constructor(mailAddress: string) ;
  constructor(mailAddress?: string) {
    super();
    this.mailAddress = mailAddress || null;
  }

  hasMailAddress(): boolean {
    return this.mailAddress !== null;
  }

  hasNewInformationComparedTo(compare: this): boolean {
    const mailAddressChanged = this.mailAddress !== compare.mailAddress;
    return mailAddressChanged
  }

  get pageTitle(): string {
    return TitleBuilder.account.forgotPassword;
  }

  get url(): string {
    if (this.hasMailAddress())
      return UrlBuilder.account.forgotPasswordWithMailAddress(this.mailAddress!);
    return UrlBuilder.account.forgotPassword;
  }

  readonly bodyClasses = ["page-pre-login", "page-request-account"];
}
