import {Component, ViewChild} from '@angular/core';
import {EntityService} from "@services/entity.service";
import {FormValidatorDirective} from "@directives";
import {TypedStoreAccessors} from "@stores";
import {Entity, EntityDataPeriod, EntityOrganization, EntityReference, EntitySaveRequest, JSONData, LogEntry, UserReference} from "@api";
import {EntityEditUiStore} from "@app/page-components/Entities/entity-edit/entity-edit-ui-store.service";
import {filter, map, takeUntil} from "rxjs/operators";
import {combineLatest, concat, Observable, of, ReplaySubject} from "rxjs";
import {AppBaseComponent} from "@app/appBaseComponent";
import {SelectItemCache} from "@app/utils/select-item-cache";
import {ExternalSearchResult} from "@widgets/SelectList";
import {UserService} from "@services/user.service";
import {toJsonData} from "@utils/Utils";
import {dataPeriodMapper} from "@app/utils/enum-display-name-mapper";
import {Modal} from "@widgets/modal";
import {hasValue} from "@utils/rxjs-extensions";

@Component({
             selector: 'rt-entity-edit',
             templateUrl: './entity-edit.component.html',
             styleUrls: ['./entity-edit.component.scss'],
             providers: [EntityEditUiStore]
           })
export class EntityEditComponent extends AppBaseComponent {

  @ViewChild(FormValidatorDirective)
  formValidator: FormValidatorDirective;
  isDeletingEntity = false;
  isGeneratingQuickTrackKey = false;
  isSaving = false;
  readonly accessors: TypedStoreAccessors<EntitySaveRequest, keyof EntitySaveRequest>;
  readonly currentName$: Observable<string>;
  readonly currentSidebarOrganization$: Observable<EntityOrganization>
  readonly dataPeriodItems = toJsonData(dataPeriodMapper);
  readonly entityLoaded$: Observable<boolean>;
  readonly globalAccountManagerCache: SelectItemCache<UserReference>;
  readonly globalAccountManagerItems$: Observable<JSONDataContainer<UserReference>[]>;
  readonly globalAccountManagers$: Observable<UserReference[]>;
  readonly hasBookingMailAddresses$: Observable<boolean>;
  readonly historyOpen$: Observable<boolean>
  readonly isNewEntity$: Observable<boolean>;
  readonly localAccountManagerCache: SelectItemCache<UserReference>;
  readonly localAccountManagerItems$: Observable<JSONDataContainer<UserReference>[]>;
  readonly logs$: Observable<LogEntry[]>;
  readonly organizationItems$: Observable<JSONDataContainer<EntityOrganization>[]>;
  readonly organizationsCache: SelectItemCache<EntityOrganization>;
  readonly parentEntity$: Observable<EntityReference>;
  readonly parentEntityItems$: Observable<JSONData[]>;
  readonly savedEntityHasBookingAddresses$: Observable<boolean>;

  private readonly _entityService: EntityService;
  private readonly _entityEditUiStore: EntityEditUiStore;
  private readonly _userService: UserService;
  private readonly _deleteModal: Modal = new Modal(null,
                                                   {
                                                     header: "Delete Entity",
                                                     text: "Are you sure you want to delete this Entity?",
                                                     buttons: [
                                                       $(document.createElement("button"))
                                                         .addClass("button")
                                                         .text("Delete")
                                                         .click(() => this._deleteCurrentEntity()),
                                                       $(document.createElement("button"))
                                                         .addClass("button secondary")
                                                         .text("Cancel")
                                                         .click(() => this._deleteModal.closeModal())
                                                     ]
    })
  private readonly _entityKeySubject = new ReplaySubject<string>();
  readonly quickTrackEntityKey$ = this._entityKeySubject.asObservable();

  constructor(entityService: EntityService, entityEditUiStore: EntityEditUiStore, userService: UserService) {
    super();

    this._userService = userService;
    this._entityEditUiStore = entityEditUiStore;
    this._entityService = entityService;
    this.accessors = entityEditUiStore.getStoreAccessors("name",
                                                         "parentEntityId",
                                                         "countryCodes",
                                                         "dataPeriod",
                                                         "globalAccountManagerIds",
                                                         "localAccountManagerIds",
                                                         "organizationIds",
                                                         "exportSeaBookingMailReceivers",
                                                         "exportAirBookingMailReceivers",
                                                         "importSeaBookingMailReceivers",
                                                         "importAirBookingMailReceivers");
    const entityStore = entityService.entityStore;
    this.localAccountManagerCache = new SelectItemCache<UserReference>(
      u => u.id,
      u => u.name,
      this.accessors.localAccountManagerIds)
    this.globalAccountManagerCache = new SelectItemCache<UserReference>(
      u => u.id,
      u => u.name,
      this.accessors.globalAccountManagerIds)
    this.organizationsCache = new SelectItemCache<EntityOrganization>(
      o => o.id,
      o => o.name,
      this.accessors.organizationIds)

    this.parentEntity$ = entityEditUiStore.observe("parentEntity")
    entityEditUiStore
      .observe("parentEntityId")
      .pipe(takeUntil(this.destroyed$))
      .subscribe(e => void this._loadParentEntity(e))
    const currentEntity$ = entityStore.observe("currentEntity");
    this.isNewEntity$ = currentEntity$.pipe(map(e => !e?.id));
    currentEntity$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(e => this.loadEntityIntoUiStore(e, entityEditUiStore));
    this.currentName$ = currentEntity$.pipe(map(e => e?.name || ""));
    this.logs$ = currentEntity$.pipe(map(e => e?.logs || []));
    this.globalAccountManagerItems$ = currentEntity$
      .pipe(map(u => EntityEditComponent._convertUserReferencesToJsonData(u?.globalAccountManagers)))
    this.localAccountManagerItems$ = currentEntity$
      .pipe(map(u => EntityEditComponent._convertUserReferencesToJsonData(u?.localAccountManagers)))
    this.organizationItems$ = currentEntity$
      .pipe(map(u => EntityService.convertOrganizationToJsonData(u?.organizations, true)))

    currentEntity$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(e => {
        this._entityKeySubject.next(e?.quickTrackEntitySetId || null)
      })
    this.parentEntityItems$ = currentEntity$
      .pipe(map(e => EntityEditComponent._getParentEntityItems(e)))
    this.hasBookingMailAddresses$ = this._entityEditUiStore
                                        .observe(["importSeaBookingMailReceivers",
                                                  "importAirBookingMailReceivers",
                                                  "exportAirBookingMailReceivers",
                                                  "exportSeaBookingMailReceivers"])
                                        .pipe(map(s => s.exportAirBookingMailReceivers?.any() ||
                                                       s.exportSeaBookingMailReceivers?.any() ||
                                                       s.importSeaBookingMailReceivers?.any() ||
                                                       s.importSeaBookingMailReceivers?.any()))
    this.entityLoaded$ = currentEntity$.pipe(map(e => !!e));
    userService.userStore.observe("currentUser")
               .pipe(
                 takeUntil(this.destroyed$),
                 filter(hasValue))
               .subscribe(() => {
                 this._entityEditUiStore.update("currentSidebarOrganization", null)
                 this._entityEditUiStore.update("historySidebarOpen", false)
               })
    entityEditUiStore.observe("currentSidebarOrganization")
                     .pipe(
                       takeUntil(this.destroyed$),
                       filter(hasValue))
                     .subscribe(() => {
                       this._userService.userStore.update("currentUser", null);
                       this._entityEditUiStore.update("historySidebarOpen", false)
                     })
    entityEditUiStore.observe("currentSidebarUserId")
                     .pipe(
                       takeUntil(this.destroyed$),
                       filter(hasValue))
                     .subscribe(() => {
                       this._entityEditUiStore.update("currentSidebarOrganization", null)
                       this._entityEditUiStore.update("historySidebarOpen", false)
                     })
    entityEditUiStore.observe("historySidebarOpen")
                     .pipe(
                       takeUntil(this.destroyed$),
                       filter(hasValue))
                     .subscribe(() => {
                       this._userService.userStore.update("currentUser", null);
                       this._entityEditUiStore.update("currentSidebarOrganization", null)
                     });
    this.historyOpen$ = entityEditUiStore.observe("historySidebarOpen");
    this.currentSidebarOrganization$ = entityEditUiStore.observe("currentSidebarOrganization");
    this.globalAccountManagers$ =
      combineLatest(
        concat(of(null as UserReference[]), this.globalAccountManagerCache.selectedItems$),
        concat(of(null as Entity), this._entityEditUiStore.observe("parentEntity"))
      )
        .pipe(map(([entityManagers, parentEntity]) => {
          return parentEntity?.globalAccountManagers || entityManagers
        }))
    this.savedEntityHasBookingAddresses$ = currentEntity$.pipe(map(e => e?.importSeaBookingMailReceivers?.any() === true))

    //entityEditUiStore.observe("name")
    //  .pipe(takeUntil(this.destroyed$),
    //    filter(hasValue))
    //  .subscribe(() => {
    //    this.accessors.name.set(this.accessors.name.value?.replace('&amp;', '&').replace('&lt;', '<').replace('&gt;', '>'));
    //  })
  }

  private static _getParentEntityItems(e: Entity) {
    if (!e.parentEntity)
      return [];
    const parent = e.parentEntity;
    return [
      {
        value: parent.id,
        label: parent.name,
        selected: true
      } as JSONData
    ]
  }

  private static _convertUserReferencesToJsonData(refs: UserReference[] | undefined): JSONDataContainer<UserReference>[] {
    if (!refs)
      return [];
    return refs.map(u => {
      return {
        value: u.id,
        data: u,
        label: u.name,
        selected: true
      }
    })
  }

  private static getQuickTrackHost(): { host: string, hasQtSubDomain: boolean } {
    const host = location.host || location.hostname;
    const parts = host.split(".");
    const hasNoSubDomain = parts.length < 3;
    if (hasNoSubDomain)
      return {host, hasQtSubDomain: false};
    const subdomain = parts[0];
    const hostReplacements: IStringIndexable<string> = {
      "realtime-qa-test": "tracking-qa-test",
      "realtime-qa-live": "tracking-qa-live",
      "realtime": "tracking"
    }
    // eslint-disable-next-line no-prototype-builtins
    return hostReplacements.hasOwnProperty(subdomain)
           ? {host: hostReplacements[subdomain] + ".rohlig.com", hasQtSubDomain: true}
           : {host, hasQtSubDomain: false};
  }

  searchGlobalAccountManagers = async (search: string): Promise<ExternalSearchResult> => {
    const searchResult = await this._userService.searchAccountManagers(search);
    const globalAccountManagerIds = this.accessors.globalAccountManagerIds.value;
    if (globalAccountManagerIds?.any())
      searchResult.results
                  .forEach(r => {
                    r.selected = globalAccountManagerIds.contains(r.value);
                  })
    return searchResult;
  };

  searchLocalAccountManagers = async (search: string): Promise<ExternalSearchResult> => {
    const searchResult = await this._userService.searchAccountManagers(search);
    const localAccountManagerIds = this.accessors.localAccountManagerIds.value;
    if (localAccountManagerIds?.any())
      searchResult.results
                  .forEach(r => {
                    r.selected = localAccountManagerIds.contains(r.value);
                  })
    return searchResult;
  };

  searchOrganizations = async (search: string): Promise<ExternalSearchResult> => {
    const currentEntityId = this._entityService.entityStore.get("currentEntity").id;
    const entitySetId = this._entityEditUiStore.get("parentEntityId");
    const searchResult = await this._entityService.searchOrganizations(search, currentEntityId, entitySetId);
    const organizationIds = this.accessors.organizationIds.value;
    if (organizationIds?.any())
      searchResult.results
                  .forEach(r => {
                    r.selected = organizationIds.contains(r.value);
                  })
    return searchResult
  };

  searchParentEntities = async (search: string): Promise<ExternalSearchResult> => {
    const currentEntityId = this._entityService.entityStore.get("currentEntity").id;
    return await this._entityService.searchParentsForEntity(search, currentEntityId ?? null);
  };

  linkedEntities(entityReferences: EntityReference[]): string {
    return entityReferences
      .map(e => e.name)
      .join("\n");
  }

  getQuickTrackUrl(key: string): string {
    const host = EntityEditComponent.getQuickTrackHost();
    let address = host.host;
    if (!host.hasQtSubDomain)
      address += "/quicktrack";
    return `${location.protocol}//${address}/${key}`
  }

  mapDataPeriod(entityDataPeriod: EntityDataPeriod): string {
    return dataPeriodMapper[entityDataPeriod];
  }

  toggleHistorySidebar(): void {
    this._entityEditUiStore.negate("historySidebarOpen");
  }

  closeHistorySidebar(): void {
    this._entityEditUiStore.update("historySidebarOpen", false);
  }

  openDeleteModal(): void {
    this._deleteModal.openModal();
  }

  ngOnDestroy(): void {
    this._deleteModal.destroy();
    super.ngOnDestroy();
  }

  async generateQuickTrackKey(): Promise<void> {
    this.isGeneratingQuickTrackKey = true;
    try {
      const currentEntityId = this._entityService.entityStore.get("currentEntity").id;
      const entityKey = await this._entityService.generateQuickTrackKey(currentEntityId);
      this._entityKeySubject.next(entityKey);
    } finally {
      this.isGeneratingQuickTrackKey = false;
    }
  }

  async openUserInformationSideBar(id: string): Promise<void> {
    const userStore = this._userService.userStore;
    const userIsAlreadyLoaded = this._entityEditUiStore.get("currentSidebarUserId") === id;
    if (userIsAlreadyLoaded)
      return;
    const user = await this._userService.get(id);
    const otherUserDataLoadedDuringRequest = this._entityEditUiStore.get("currentSidebarUserId") !== id;
    if (otherUserDataLoadedDuringRequest)
      return;
    userStore.update("currentUser", user);
  }

  async notifyBookingRecipients(): Promise<void> {
    const currentEntityId = this._entityService.entityStore.get("currentEntity").id;
    await this._entityService.notifyBookingRecipients(currentEntityId)
  }

  toggleOrganizationInfoSidebar(org: EntityOrganization = null): void {
    this._entityEditUiStore.update(({currentSidebarOrganization}) => {
      const orgIsAlreadyLoaded = currentSidebarOrganization?.id === org?.id;
      return {
        currentSidebarOrganization: orgIsAlreadyLoaded ? null : org
      }
    });
  }

  async save(): Promise<void> {
    if (!await this.formValidator.validate())
      return;
    this.isSaving = true;
    const id = this._entityService.entityStore.get("currentEntity")?.id;
    const isNewEntity = !id;
    if (isNewEntity)
      await this._entityService.create(this.getSaveRequest());
    else {
      await this._entityService.update(id, this.getSaveRequest());
      this.isSaving = false;
    }


  }

  private loadEntityIntoUiStore(e: Entity, entityEditUiStore: EntityEditUiStore) {
    if (!e) {
      entityEditUiStore.reset();
      return
    }
    this.globalAccountManagerCache.updateCache(e.globalAccountManagers);
    this.localAccountManagerCache.updateCache(e.localAccountManagers);
    this.organizationsCache.updateCache(e.organizations);
    entityEditUiStore.replace({
                                globalAccountManagerIds: e.globalAccountManagers?.map(u => u.id),
                                parentEntityId: e.parentEntity?.id,
                                name: e.name,
                                countryCodes: e.countries?.map(c => c.isoAlpha2),
                                dataPeriod: e.dataPeriod || EntityDataPeriod.TwoYears,
                                exportAirBookingMailReceivers: e.exportAirBookingMailReceivers,
                                exportSeaBookingMailReceivers: e.exportSeaBookingMailReceivers,
                                importAirBookingMailReceivers: e.importAirBookingMailReceivers,
                                importSeaBookingMailReceivers: e.importSeaBookingMailReceivers,
                                localAccountManagerIds: e.localAccountManagers?.map(u => u.id),
                                organizationIds: e.organizations?.map(o => o.id)
                              });
  }

  private async _deleteCurrentEntity() {
    this.isDeletingEntity = true;
    try {
      const currentEntity = this._entityService.entityStore.get("currentEntity");
      await this._entityService.delete(currentEntity);
    } catch (e) {
      this.isDeletingEntity = false;
    }
  }

  private getSaveRequest(): EntitySaveRequest {
    return this._entityEditUiStore.get([
                                         "name",
                                         "parentEntityId",
                                         "countryCodes",
                                         "dataPeriod",
                                         "globalAccountManagerIds",
                                         "localAccountManagerIds",
                                         "organizationIds",
                                         "exportSeaBookingMailReceivers",
                                         "exportAirBookingMailReceivers",
                                         "importSeaBookingMailReceivers",
                                         "importAirBookingMailReceivers",
                                       ]);
  }

  private async _loadParentEntity(parentEntityId: string) {
    if (!parentEntityId) {
      this._entityEditUiStore.update("parentEntity", null)
      return;
    }
    const parentEntity = await this._entityService.get(parentEntityId);
    const parentEntityIdChangedDuringRequest = this._entityEditUiStore.get("parentEntityId") !== parentEntityId;
    if (parentEntityIdChangedDuringRequest)
      return
    this._entityEditUiStore.update("parentEntity", parentEntity)
  }
}

