import {arrayify} from "@utils/Utils";

export const TitleBuilder = {
  shipment: {
    list: "Shipments",
    shipmentDetail: (title: string) => title
  },
  order: {
    list: "Orders",
    orderDetail: (title: string) => title
  },
  account: {
    login: "Röhlig Real Time: Track your shipments and manage global freight operations",
    users: "Users",
    myProfile: "Profile",
    viewUser: (username: string) => username,
    editUser: (username: string) => username,
    addUser: "Add User",
    changePassword: "Reset Your Password",
    changePasswordComplete: "Reset Your Password",
    forgotPassword: "Reset Your Password",
    requestAccess: "Request Access"
  },
  booking: "New Booking",
  template: {
    withName: (name:string) => name,
    editTemplateWithoutName: "Edit Booking Template",
    newTemplate: "Add Booking Template"
  },
  entities: {
    list: "Entities",
    addSite: "Add Entity",
    siteDetails: (name: string) => name
  },
  settings: "Settings",
  onlineMonitor: "Admin",
  errors: {
    accessDenied: "Access Denied",
    notFound: "Page Not Found",
    unexpectedError: "Sorry",
    offline: "Offline"
  },
  containerDetail: (containerNumbers: string[] | string) => arrayify(containerNumbers).join("/")
};

