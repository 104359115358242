<div class="page-profile">
  <div class="page-wrap">
    <main>
      <section class="padding" rtFormValidator>

        <div class="search-bar button-container">
          <div class="button-group static">
            <a class="button back-button secondary phone-borderless" routerLink="/Account/Users"><span class="mat-icon-back"></span></a>
          </div>
          <span *ngIf="userData$ | async as userData"
                class="title desktop-visible">
            {{(userData.firstName + " " + userData.lastName) | unescapeHtml}}
            <span class="label" *ngIf="userData.deactivated">Deactivated</span>
            <span class="label" *ngIf="userData.locked">Locked</span>
          </span>
          <div class="button-group" *ngIf="userData$ | async as userData">
            <button class="toggle-button button secondary"
                    (click)="toggleHistory()"
                    [ngClass]="{active: historyOpen}">History
            </button>
            <a class="button navigation-button"
               routerLink="/Account/EditUser"
               [queryParams]="{ID: userData.id}"
            >
              <span class="mat-icon-edit"></span>Edit user
            </a>
          </div>
        </div>
        <ng-container *ngIf="userData$ | async as userData">
          <div id="top">
            <div id="user-details">
              <h2>User Details</h2>
              <figure class="avatar">
                <ng-container *ngIf="userData.profilePictureBase64; else noAvatar">
                  <img src="data:image/jpeg;base64,{{userData.profilePictureBase64}}" alt="User Avatar"/>
                </ng-container>
                <ng-template #noAvatar>
                  <img src="/content/images/avatar.png" alt="User Avatar"/>
                </ng-template>
              </figure>
              <p>
                <label>Name</label>
                <span>{{(userData.firstName + ' ' + userData.lastName) | unescapeHtml}}</span>
              </p>
              <p>
                <label>Email</label>
                <span [outerHTML]="userData.mailAddress.replace('@', '<wbr />@')"></span>
              </p>
              <p>
                <label>Country</label>
                <span [outerHTML]="userData.country | rtCountry"></span>
              </p>
              <p>
                <label>User Type</label>
                {{userData.type | pureFunction:getUserTypeName}}
              </p>
              <ng-container *ngIf="userData | rtIsRohligUser">
                <p>
                  <label>User Group</label>
                  {{userData.group | pureFunction:getUserGroupName}}
                </p>
                <p *ngIf="userData.group == userGroup.LocalAdmin">
                  <label>Administrative Countries</label>
                  <span style="display: block;"
                        *ngFor="let country of userData.adminCountries"
                        [innerHTML]="country | rtCountry"></span>
                </p>
              </ng-container>
              <p *ngIf="userData.phone">
                <label>Telephone</label>
                <span [innerHTML]="userData.phone | rtPhone"></span>
              </p>
            </div>
            <div id="actions" class="wysiwyg">
              <h2>Actions</h2>
              <ng-container *ngIf="!userData.deactivated">
                <p>
                  <button
                    class="button secondary"
                    (click)="sendWelcomeMail(userData)"
                    [disabled]="isSendingWelcomeMail || isDeletingUser">Send Welcome Mail
                  </button>
                </p>
                <p *ngIf="userData.type === userType.Client">
                  <button
                    class="button secondary"
                    (click)="sendPasswordMail(userData)"
                    [disabled]="isSendingPasswordMail || isDeletingUser">Send Password Mail
                  </button>
                </p>
              </ng-container>
              <p *ngIf="userData.type === userType.Client && (loggedInAsSuperUser$ | async)">
                <button
                  class="button secondary"
                  (click)="openDeleteModal(userData)"
                  [disabled]="isDeletingUser">Delete User
                </button>
              </p>
            </div>
          </div>

          <!-- ################# ENTITIES ################# -->
          <div class="tables" *ngIf="userData.entitySetPermissions.any()">
            <h2>Entities</h2>
            <rt-user-entity-permission-view [entitySets]="userData.entitySetPermissions"></rt-user-entity-permission-view>
          </div>
          <rt-history-sidebar rtSticky [historyOpen]="historyOpen" (close)="closeHistory()" [logs]="userData.logs"></rt-history-sidebar>
        </ng-container>
      </section>

    </main>
  </div>
</div>

