<div class="page-user-list">
  <div class="page-wrap">
    <main>
      <section class="padding">
        <rt-user-search-bar></rt-user-search-bar>
        <div class="user-list ajax-target">

          <ng-container *ngIf="isSearching$ | async; else searchResults">
            <table class="table-default table-border table-responsive-full table-separated">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Country</th>
                  <th>Created (UTC)</th>
                  <th>Last Edit (UTC)</th>
                  <th>Entity Sets</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody class="user-list-content">
                <tr *ngFor="let item of [].constructor(skeletonItemCount);">
                  <td><span class="shimmer-content" style="width:50%"></span></td>
                  <td><span class="shimmer-content" style="width:50%"></span></td>
                  <td><span class="shimmer-content" style="width:50%"></span></td>
                  <td><span class="shimmer-content" style="width:50%"></span></td>
                  <td><span class="shimmer-content" style="width:50%"></span></td>
                  <td><span class="shimmer-content" style="width:50%"></span></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </ng-container>

          <ng-template #searchResults>
            <ng-container *ngIf="displayedSearchResults$ | async as searchResults">
              <table *ngIf="searchResults.any()" class="table-default table-border table-responsive-full table-separated">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Country</th>
                    <th>Created (UTC)</th>
                    <th>Last Edit (UTC)</th>
                    <th>Entity Sets</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody class="user-list-content">
                  <!--suppress AngularInvalidExpressionResultType -->
                  <tr *ngFor="let user of searchResults; trackBy: trackById">
                    <td data-header="Name">
                      <a routerLink="/Account/ViewUser"
                         [queryParams]="{ID: user.id}">{{user.firstName + ' ' + user.lastName | unescapeHtml}}</a>
                    </td>
                    <td data-header="Email" [innerHTML]="user.mailAddress | rtWbr"></td>
                    <td data-header="Country" [innerHTML]="user.country | rtCountry">
                    </td>
                    <td data-header="Created (UTC)">{{user.createdAt | rtDateTime}}</td>
                    <td data-header="Last Edit (UTC)">{{user.lastUpdatedAt | rtDateTime}}</td>
                    <td data-header="Entity Sets">
                      {{user.entitySetNames | rtSort | rtStringArray | rtNoPlaceholder | unescapeHtml}}
                    </td>
                    <td class="empty">
                      <span class="label" *ngIf="user.locked">Locked</span>
                      <span class="label" *ngIf="user.deactivated">Deactivated</span>
                      <span class="label" *ngIf="user | pureFunction:showUserGroupLabel">{{user.group | pureFunction:getUserGroupName}}</span>
                    </td>
                    <td class="actions">
                      <a class="mat-icon-visibility text-hide"
                         routerLink="/Account/ViewUser"
                         [queryParams]="{ID: user.id}">"View"</a>
                      <a class="mat-icon-edit text-hide"
                         routerLink="/Account/EditUser"
                         [queryParams]="{ID: user.id}">"Edit"</a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="load-more" *ngIf="moreResultsAvailable$ | async" [ngClass]="{loading: isLoadingMore}">
                <button class="button" (click)="loadMore()" [disabled]="isLoadingMore">
                  Load more
                </button>
              </p>
              <p *ngIf="!searchResults.any()" class="no-results-found">No Users found</p>
            </ng-container>
          </ng-template>

        </div>
      </section>
    </main>
  </div>
</div>
