<google-map *ngIf="apiLoaded$ | async"
            [options]="mapOptions"
            width="100%">


  <ng-container *ngFor="let traveledPath of traveledPaths">
    <map-polyline *ngIf="traveledPath"
                  [options]="traveledPathOptions"
                  [path]="traveledPath">
    </map-polyline>
  </ng-container>

  <ng-container *ngFor="let toGoPath of toGoPaths">
    <map-polyline *ngIf="toGoPath"
                  [options]="toGoPathOptions"
                  [path]="toGoPath">
    </map-polyline>
  </ng-container>

  <map-marker *ngFor="let location of locations"
              [options]="location | pureFunction:getMarkerOptions:getIconForLocation"></map-marker>
  <map-marker *ngFor="let location of lastLocations"
              [options]="location | pureFunction:getMarkerOptions:getIconForEndLocation"></map-marker>
</google-map>
