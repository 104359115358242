import {Injectable} from "@angular/core";
import {Store, StoreProjectionMapping} from "@stores";
import {OrderSearchReferenceType} from "@api";
import {OrderSearchUiStoreState} from "@app/page-components/order/order-list/order-search-bar/order-search-ui-store-state";

interface OrderSearchUiStoreProjection {
  calendarStartDateFocused: boolean;
  calendarEndDateFocused: boolean;
  selectedDates: { start: Date, end: Date };
}

@Injectable({providedIn: "root"})
export class OrderSearchUiStore extends Store<OrderSearchUiStoreState, OrderSearchUiStoreProjection> {

  getName(): string {
    return "OrderSearchUiStore";
  }

  private static _initialState: OrderSearchUiStoreState = {
    filtersOpen: false,
    endDate: null,
    startDate: null,
    status: [],
    transportMode: [],
    referenceType: OrderSearchReferenceType.Any,
    reference: null,
    calendarDateType: "startDate",
    showMobileSearchHint: false
  }

  private static _projections: StoreProjectionMapping<OrderSearchUiStoreState, OrderSearchUiStoreProjection> = {
    calendarEndDateFocused: u => u.calendarDateType === "endDate",
    calendarStartDateFocused: u => u.calendarDateType === "startDate",
    selectedDates: u => {
      if (!u.startDate || !u.endDate)
        return null;
      return {start: u.startDate, end: u.endDate}
    }
  }

  constructor() {
    super(OrderSearchUiStore._projections, OrderSearchUiStore._initialState);
  }


}
