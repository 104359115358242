// @ts-nocheck

import * as Utils from "./utils/Utils";
import {disableElementsWhenOffline, PrepareJsonDataFromServer, PrepareJsonDataToServer, UnwrapFunction} from "./utils/Utils";
import moment = require("moment-mini-ts");
import detect = require("./utils/detect");
import {jsonDateReviver} from "./api/Apis";
import {Key} from "protractor";

const isNodeJsEnvironment = (typeof global) !== "undefined";

Date.prototype.hasTime = function (this: Date) {
  if (typeof this.isTimeSet === "boolean") {
    return this.isTimeSet;
  }
  return !!(this.getHours() || this.getMinutes() || this.getSeconds());
};


Date.prototype.isOnSameDay = function (this: Date, other: Date): boolean {
  return this.getDateComponent().getTime() === other.getDateComponent().getTime()
}

Date.prototype.getDateComponent = function (this: Date): Date {
  const copy = new Date(this.getTime());
  copy.setHours(0, 0, 0, 0);
  return copy;
}

Date.prototype.getDaysTo = function (this: Date, other: Date): number {
  const msDifference = other.getDateComponent().getTime() - this.getDateComponent().getTime();
  return Math.round(msDifference / 1000 / 3600 / 24);
}

Array.prototype.contains = function <T>(this: T[], value: T) {
  return this.indexOf(value) > -1;
};

Array.prototype.remove = function <T>(this: T[], value: T) {
  const index = this.indexOf(value);
  if (index > -1) {
    this.splice(index, 1);
  }
};

Array.prototype.removeRange = function <T>(this: T[], values: T[]) {
  for (let value of values) this.remove(value);
};


Array.prototype.pushRange = function <T>(this: T[], values: T[]) {
  for (let value of values) this.push(value);
  return this.length;
};

Array.prototype.clear = function <T>(this: T[]) {
  this.splice(0);
};

Array.prototype.equals = function <T>(this: T[], other: T[]) {
  if (this === other) {
    return true;
  }
  if (this.length !== other.length) {
    return false;
  }
  for (const elem of this) {
    if (!other.contains(elem)) {
      return false;
    }
  }
  return true;
};

Array.prototype.insert = function <T>(this: T[], index: number, ...elements: T[]) {
  this.splice.apply(this, [index, 0 as any].concat(elements));
};

//const origJoin = Array.prototype.join;
Array.prototype.join = (function (join: (seperator?: any) => string) {
  return function <T>(this: T[],
                      seperator: string,
                      ignoreEmptyElements?: boolean): string {
    return join.call(ignoreEmptyElements
                     ? this.filter(e => !Utils.IsNullOrUndefined(e) && (e as any) !== "")
                     : this, seperator);
  }
})(Array.prototype.join);

Array.prototype.last = function <T>(this: T[]) {
  return this[this.length - 1];
};

Array.prototype.firstOrDefault = function <T>(this: T[], filter: Func1<T, boolean> = () => true) {
  for (const item of this) {
    if (filter(item)) {
      return item;
    }
  }
  return null;
};

Array.prototype.any = function <T>(this: T[], filter: Func1<T, boolean> = () => true) {
  for (const item of this) {
    if (filter(item)) {
      return true;
    }
  }
  return false;
};

Array.prototype.all = function <T>(this: T[], filter: Func1<T, boolean>) {
  for (const item of this) {
    if (!filter(item)) {
      return false;
    }
  }
  return true;
};

Array.prototype.count = function <T>(this: T[], predicate: Func1<T, boolean> = () => true) {
  let count = 0;
  for (const item of this) {
    if (predicate(item))
      count++;
  }
  return count;
};

Array.prototype.isEmpty = function <T>(this: T[]) {
  return this.length === 0;
};

let numberOrderBy = function <T>(arr: T[], assessor: Func1<T, number>) {
  return arr
    .map(i => {
      return {item: i, weight: assessor(i)}
    })
    .sort((a, b) => a.weight - b.weight)
    .map(i => i.item);
};

let stringOrderBy = function <T>(arr: T[], assessor: Func1<T, string>) {
  return arr
    .map(i => {
      return {item: i, weight: assessor(i)}
    })
    .sort((a, b) => a.weight >= b.weight ? 1 : -1)
    .map(i => i.item);
};
Array.prototype.orderBy = function <T>(this: T[], assessor: Func1<T, number> | Func1<T, string>) {
  if (!this.length)
    return [];
  return typeof assessor(this[0]) === "string"
         ? stringOrderBy(this, assessor as Func1<T, string>)
         : numberOrderBy(this, assessor as Func1<T, number>);
};
Array.prototype.orderByMultiple = function <T>(this: T[], ...orderAssesors: (Func1<T, number> | Func1<T, string>)[]) {
  if (!this.length)
    return [];
  orderAssesors.reverse();
  let output = this;
  for (const curr of orderAssesors) {
    output = output
      .orderBy(curr)
      .groupBy<string | number>(curr)
      .map(g => g.items)
      .flat();
  }
  return output;
};

Array.prototype.sum = function <T>(this: T[], assessor: ((item: T) => number)) {
  return this.map(assessor).reduce((n1, n2) => n1 + n2, 0);
};

Array.prototype.groupBy = function <T, TKey>(this: T[], keySelector: (item: T) => TKey) {
  const output = new Array<Grouping<T, TKey>>();
  for (const item of this) {
    const key = keySelector(item);
    const group = typeof key === "number" && isNaN(key)
                  ? output.firstOrDefault(g => isNaN(g.key as any))
                  : output.firstOrDefault(g => g.key === key);
    if (group)
      group.items.push(item);
    else
      output.push({key: key, items: [item]});
  }
  return output;
};

Array.prototype.toDictionary = function <T>(this: T[], keySelector) {
  const dictionary = {} as IIndexable<T>;
  this.forEach(e => {
    dictionary[keySelector(e)] = e;
  })
  return dictionary;
}

Array.prototype.distinct = function <T, TKey = T>(this: T[], keySelector: Func1<T, TKey> = (o: T) => (o as any)) {
  const output: T[] = [];
  const keys: TKey[] = [];
  for (const item of this) {
    const key = keySelector(item);
    if (keys.contains(key))
      continue;
    keys.push(key);
    output.push(item);
  }
  return output;
};

Array.FromString = function <T = any>(arrayString: string) {
  if (!/^\s*\[(\s*("[^"]*"|'[^']*'|[\-+\.0-9]+|\w+)\s*,)*(\s*("[^"]*"|'[^']*'|[\-+\.0-9]+|\w+)\s*)\]\s*$/
    .test(arrayString)) {
    return eval(arrayString);
  }
  return [arrayString];
};

Array.fromObject = function (object: any) {
  const output = [];
  for (const prop in object) {
    if (!object.hasOwnProperty(prop))
      continue;
    output.push(object[prop]);
  }
  return output;
}

Object.extract = function <T extends {}, K extends keyof T>(object: T, keysToExtract: K[]): Pick<T, K> {
  const output = {} as Pick<T, K>;
  keysToExtract.forEach(k => output[k] = object[k]);
  return output;
}

Number.isNumber = function (number) {
  return typeof number === "number" && isFinite(number);
}

Number.prototype.toWord = function (this: number) {
  const words = ["one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten"];

  if (this.valueOf() % 1 === 0 && this.valueOf() > 0 && this.valueOf() <= words.length) {
    return words[this.valueOf() - 1];
  } else {
    return this.toString();
  }
};

Number.prototype.toString = ((toString: () => string) =>
    function (this: number) {
        return toString.call(this, arguments[0]).replace(".", window.numberDecimalSeparator);
    })(Number.prototype.toString);

Number.prototype.toFixed = ((toFixed: (fractionDigits?: number) => string) =>
  function (this: number, fractionDigits?: number) {
    return toFixed.call(this, fractionDigits).replace(".", window.numberDecimalSeparator);
  })(Number.prototype.toFixed);

Number.prototype.toPrecision = ((toPrecision: (precision?: number) => string) =>
  function (this: number, precision?: number) {
    return toPrecision.call(this, precision).replace(".", window.numberDecimalSeparator);
  })(Number.prototype.toPrecision);

Number.prototype.getPrecision = function (this: number) {
  if (isNaN(this)) {
    return NaN;
  }
  let counter = 0;
  let number = this;
  while (!Number.isInteger(number)) {
    if (++counter >= 100) {
      return NaN;
    }
    number *= 10;
  }
  return counter;
};


String.prototype.format = function (this: string, ...args: (string | number | boolean | Date)[]) {
  const stringArgs = args.map(a => {
    if (a instanceof Date)
      return moment(a).format(window.dateShortFormat);
    if (typeof a === "number")
      return a.toString().replace("-", "−");
    return a.toString();
  });
  return this.replace(/{(\d+)}/g,
                      (match: string, number: number) => typeof stringArgs[number] !== "undefined"
                                                         ? stringArgs[number]
                                                         : match);
};

String.prototype.in = function (this: string, array: string[]) {
  if (Array.isArray(array)) {
    return array.contains(this.valueOf());
  } else {
    return false;
  }
};

String.prototype.startsWith = function (this: string, needle: string) {
  return this.indexOf(needle) === 0;
};

String.prototype.contains = function (this: string, needles: string | string[]) {
  if (typeof needles === "string") {
    return this.indexOf(needles) !== -1;
  }
  for (const needle of needles) {
    if (this.indexOf(needle) !== -1) {
      return true;
    }
  }
  return false;
};

String.prototype.padLeft = function (this: string, targetLength: number, fillCharacter = " ") {
  let input = this;
  while (input.length < targetLength) {
    input = fillCharacter + input;
  }
  return input;
};

String.prototype.padRight = function (this: string, targetLength: number, fillCharacter = " ") {
  let input = this;
  while (input.length < targetLength) {
    input = input + fillCharacter;
  }
  return input;
};

String.isNullOrEmpty = input => !(input && input.length > 0);
String.isNullOrWhitespace = input => !(input && /[^\s]/.test(input));
String.isFilled =
  (input, ignoreWhitespace = true) => ignoreWhitespace
                                      ? !String.isNullOrWhitespace(input)
                                      : !String.isNullOrEmpty(input);

String.prototype.trim = ((trim: typeof String.prototype.trim) => function (this: string, pattern?: string) {
  if (pattern) {
    return this.trimLeft(pattern).trimRight(pattern);
  }
  return trim.apply(this);
})(String.prototype.trim);

String.prototype.trimLeft = ((trimLeft: typeof String.prototype.trimLeft) => function (this: string, pattern?: string) {
  if (pattern) {
    let output = this;
    while (output.indexOf(pattern) === 0) {
      output = output.substr(pattern.length);
    }
    return output;
  }
  return trimLeft.apply(this);
})(String.prototype.trimLeft);

String.prototype.trimRight =
  ((trimRight: typeof String.prototype.trimRight) => function (this: string, pattern?: string) {
    if (pattern) {
      let output = this;
      while (output.indexOf(pattern) > -1 && output.lastIndexOf(pattern) === output.length - pattern.length) {
        output = output.substr(0, output.length - pattern.length);
      }
      return output;
    }
    return trimRight.apply(this);
  })(String.prototype.trimRight);

String.prototype.toKebabCase = function (this: string) {
  return this.replace(/-*([A-Z])-*/g, "-$1").toLowerCase().trim("-");
};
String.prototype.toCamelCase = function (this: string) {
  return this.replace(/-([a-z])/g, (substring, args) => substring.toUpperCase()).replace(/-/g, "");
};

if (!isNodeJsEnvironment) {
  HTMLCanvasElement.prototype.resizeTo = function (this: HTMLCanvasElement) {
    const canvasResized = document.createElement("canvas");
    canvasResized.width = 200;
    canvasResized.height = 200;
    canvasResized.getContext("2d").drawImage(this, 0, 0, canvasResized.width, canvasResized.height);

    this.width = 200;
    this.height = 200;
    this.getContext("2d").drawImage(canvasResized, 0, 0);
  };


  File.prototype.isImage = function (this: File) {
    return /^image\/\w+$/.test(this.type);
  };
}

Math.round = ((round: (x: number) => number) => {
  return (x: number, precision = 0) => {
    precision = round(precision);
    if (precision <= 0) {
      return round(x);
    }
    return round(Math.pow(10, precision) * x) / Math.pow(10, precision);
  }
})(Math.round);

Math.diff = (a, b) => Math.abs(a - b);


const consoleBacklog = new Array<Function>();
const isLocalhost = (!window.location.hostname.contains(".rohlig.com") || window.location.hostname.contains("realtime-qa-")) &&
                    !window.location.hostname.contains(".blue-net.lan");
if (!isLocalhost) {
  window.console.log = (function (log: (message?: any, ...params: any[]) => void) {
    return (message: any, ...params: any[]) => {
      if (window.enableDebug) {
        emptyConsoleBacklog();
        log.apply(window, [message].concat(params));
      } else {
        consoleBacklog.push(() => {
          log.apply(window, [message].concat(params))
        });
      }
    };
  })(window.console.log);
  window.console.warn = (function (warn: (message?: any, ...params: any[]) => void) {
    return (message: any, ...params: any[]) => {
      if (window.enableDebug) {
        warn.apply(window, [message].concat(params));
        emptyConsoleBacklog();
      } else {
        consoleBacklog.push(() => {
          warn.apply(window, [message].concat(params))
        });
      }
    };
  })(window.console.warn);
  window.console.error = (function (error: (message?: any, ...params: any[]) => void) {
    return (message: any, ...params: any[]) => {
      if (window.enableDebug) {
        error.apply(window, [message].concat(params));
        emptyConsoleBacklog();
      } else {
        consoleBacklog.push(() => {
          error.apply(window, [message].concat(params))
        });
      }
    };
  })(window.console.error);
  window.console.trace = (function (trace: (message?: any, ...params: any[]) => void) {
    return (message: any, ...params: any[]) => {
      if (window.enableDebug) {
        trace.apply(window, [message].concat(params));
      }
    };
  })(window.console.trace);
}

function emptyConsoleBacklog() {
  while (consoleBacklog.length) {
    consoleBacklog.shift()();
  }
}

window.ifDebug = function (callback: () => void) {
  if (window.enableDebug) {
    callback();
  }
};
console.logReturn = <T>(message: T) => {
  console.log(message);
  return message;
};

console.logSnapshot = function (message: any) {
  console.log(PrepareJsonDataFromServer(JSON.parse(PrepareJsonDataToServer(JSON.stringify(message)))));
}

export enum PositionState {
  InView,
  TopLeft,
  Top,
  TopRight,
  Right,
  BottomRight,
  Bottom,
  BottomLeft,
  Left
}

(($: JQueryStatic) => {

  $.fn.isInViewport = function (this: JQuery) {
    const offsetParent = this.offsetParent()[0] as HTMLElement;
    if (offsetParent) {
      const boundingBox = (this[0] as HTMLElement).getBoundingClientRect();
      const parentBoundingBox = offsetParent.getBoundingClientRect();
      const right = parentBoundingBox.right - (offsetParent.offsetWidth - offsetParent.clientWidth);
      return boundingBox.top < parentBoundingBox.top
             || boundingBox.bottom > parentBoundingBox.bottom
             || boundingBox.left < parentBoundingBox.left
             || boundingBox.right > parentBoundingBox.right;
    }
    const width = this.outerWidth(true);
    const height = this.outerHeight(true);
    const top = this.offset().top - $(document).scrollTop();
    const left = this.offset().left - $(document).scrollLeft();
    const vpHeight = $(window).height();
    const vpWidth = $(window).width();
    return width + left <= vpWidth && height + top <= vpHeight && top >= 0 && left >= 0;
  };
  $.fn.getVisibleSize = function (this: JQuery) {
    let viewportWidth = $(window).width();
    let viewportHeight = $(window).height();

    let boundingBox = (this[0] as HTMLElement).getBoundingClientRect();

    const offsetParent = this.offsetParent()[0] as HTMLElement;
    if (offsetParent && offsetParent !== document.body && window.getComputedStyle(offsetParent).overflow !== "visible") {
      const offsetParentBoundingBox = offsetParent.getBoundingClientRect();

      const scrollbarWidth = detect.browser.ie ? 0 : (offsetParent.offsetWidth - offsetParent.clientWidth);
      const newBoundingBox = {
        top: Math.max(boundingBox.top, offsetParentBoundingBox.top),
        bottom: Math.min(boundingBox.bottom, offsetParentBoundingBox.bottom),
        left: Math.max(boundingBox.left, offsetParentBoundingBox.left),
        right: Math.min(boundingBox.right, offsetParentBoundingBox.right - scrollbarWidth)
      };
      boundingBox = {
        top: newBoundingBox.top,
        bottom: newBoundingBox.bottom,
        height: Math.max(newBoundingBox.bottom - newBoundingBox.top, 0),
        left: newBoundingBox.left,
        width: Math.max(newBoundingBox.right - newBoundingBox.left, 0),
        right: newBoundingBox.right
      };
      viewportHeight = offsetParentBoundingBox.bottom;
      viewportWidth = offsetParentBoundingBox.right;

    }

    const height = boundingBox.height;
    const width = boundingBox.width;

    const top = boundingBox.top;
    const bottom = boundingBox.bottom;
    const left = boundingBox.left;
    const right = boundingBox.right;
    const visibleHeight = Math.max(0,
                                   top > 0 ? Math.min(height, viewportHeight - top) : (bottom < viewportHeight
                                                                                       ? bottom
                                                                                       : viewportHeight));
    const visibleWidth = Math.max(0,
                                  left > 0 ? Math.min(width, viewportWidth - left) : (right < viewportWidth
                                                                                      ? right
                                                                                      : viewportWidth));
    return Math.round(visibleHeight * visibleWidth);
  };

  $.fn.getPositionState = function (this: JQuery) {
    const width = this.outerWidth(true);
    const height = this.outerHeight(true);
    const top = this.offset().top - $(document).scrollTop();
    const left = this.offset().left - $(document).scrollLeft();
    const vpHeight = $(window).height();
    const vpWidth = $(window).width();

    const offToTop = -top > height;
    const offToBottom = top > vpHeight;
    const offToLeft = -left > width;
    const offToRight = left > vpWidth;

    if (offToTop) {
      if (offToLeft) {
        return PositionState.TopLeft;
      }
      if (offToRight) {
        return PositionState.TopRight;
      }
      return PositionState.Top;
    }
    if (offToBottom) {
      if (offToLeft) {
        return PositionState.BottomLeft;
      }
      if (offToRight) {
        return PositionState.BottomRight;
      }
      return PositionState.Bottom;
    }
    if (offToRight) {
      return PositionState.Right;
    }
    if (offToLeft) {
      return PositionState.Left;
    }
    return PositionState.InView;
  };

  $.fn.getRelativePosition = function (this: JQuery) {
    const width = this.outerWidth(true);
    const height = this.outerHeight(true);
    const top = this.offset().top - $(document).scrollTop();
    const left = this.offset().left - $(document).scrollLeft();
    const vpHeight = $(window).height();
    const vpWidth = $(window).width();


    const result = {
      element: PositionState.InView,
      bottomOffset: Math.max(0, top - vpHeight),
      topOffset: Math.max(0, -top - height),
      leftOffset: Math.max(0, -left - width),
      rightOffset: Math.max(0, left - vpWidth),
      lowerLeftCorner: {top: top + height, left: left, state: PositionState.InView},
      upperRightCorner: {top: top, left: left + width, state: PositionState.InView},
      upperLeftCorner: {top: top, left: left, state: PositionState.InView},
      lowerRightCorner: {top: top + height, left: left + width, state: PositionState.InView},
      visibleSize: 0,
      numberOfVisibleCorners: 0,
      numberOfCornersInDirectionOfElement: 0,
      size: height * width,
      offset: 0
    };

    let corners = [
      result.lowerLeftCorner, result.lowerRightCorner, result.upperLeftCorner, result.upperRightCorner
    ];
    for (const corner of corners) {

      const offToTop = corner.top < 0;
      const offToBottom = corner.top > vpHeight;
      const offToLeft = corner.left < 0;
      const offToRight = corner.left > vpWidth;

      if (offToTop) {
        if (offToLeft) {
          corner.state = PositionState.TopLeft;
        } else if (offToRight) {
          corner.state = PositionState.TopRight;
        } else {
          corner.state = PositionState.Top;
        }
      } else if (offToBottom) {
        if (offToLeft) {
          corner.state = PositionState.BottomLeft;
        } else if (offToRight) {
          corner.state = PositionState.BottomRight;
        } else {
          corner.state = PositionState.Bottom;
        }
      } else if (offToRight) {
        corner.state = PositionState.Right;
      } else if (offToLeft) {
        corner.state = PositionState.Left;
      } else {
        result.numberOfVisibleCorners++;
        corner.state = PositionState.InView;
      }

    }

    const hypotenuse = (a: number, b: number) => Math.pow(a * a + b * b, 0.5);

    if (result.lowerLeftCorner.state === PositionState.TopRight) {
      result.element = PositionState.TopRight;
      result.offset = hypotenuse(result.topOffset, result.rightOffset);
    } else if (result.lowerRightCorner.state === PositionState.TopLeft) {
      result.element = PositionState.TopLeft;
      result.offset = hypotenuse(result.topOffset, result.leftOffset);
    } else if (result.upperLeftCorner.state === PositionState.BottomRight) {
      result.element = PositionState.BottomRight;
      result.offset = hypotenuse(result.bottomOffset, result.rightOffset);
    } else if (result.upperRightCorner.state === PositionState.BottomLeft) {
      result.element = PositionState.BottomLeft;
      result.offset = hypotenuse(result.bottomOffset, result.leftOffset);
    } else if (top > vpHeight) {
      result.element = PositionState.Bottom;
      result.offset = result.bottomOffset;
    } else if (-top > height) {
      result.element = PositionState.Top;
      result.offset = result.topOffset;
    } else if (left > vpWidth) {
      result.element = PositionState.Right;
      result.offset = result.rightOffset;
    } else if (-left > width) {
      result.element = PositionState.Left;
      result.offset = result.leftOffset;
    }

    if (result.element === PositionState.InView) {

      //let visibleHeight: number;
      //let visibleWidth: number;

      //if (top + height > vpHeight)
      //    visibleHeight = vpHeight - top;
      //else if (top < 0)
      //    visibleHeight = height + top;
      //else
      //    visibleHeight = height;

      //if (left + width > vpWidth)
      //    visibleWidth = vpWidth - left;
      //else if (left < 0)
      //    visibleWidth = width + left;
      //else
      //    visibleWidth = width;

      //result.visibleSize = Math.max(0, visibleHeight) * Math.max(0, visibleWidth);
      result.visibleSize = this.getVisibleSize();
    }

    for (const corner of corners) {
      if (corner.state === result.element) {
        result.numberOfCornersInDirectionOfElement++;
      }
    }

    return result;
  };

  $.fn.top = function (this: JQuery, value?: string | number): any {
    if (value === undefined) {
      return this.css("top");
    }
    return this.css("top", value);
  };
  $.fn.bottom = function (this: JQuery, value?: string | number): any {
    if (value === undefined) {
      return this.css("bottom");
    }
    return this.css("bottom", value);
  };
  $.fn.left = function (this: JQuery, value?: string | number): any {
    if (value === undefined) {
      return this.css("left");
    }
    return this.css("left", value);
  };
  $.fn.right = function (this: JQuery, value?: string | number): any {
    if (value === undefined) {
      return this.css("right");
    }
    return this.css("right", value);
  };

  //$.fn.positionAndDimensions = function (this: JQuery, realativeTo?: ContainerType | boolean, ignoreElementDimensions?: boolean) {
  //    if (typeof realativeTo === "boolean")
  //        return new PositionAndDimensions(this, realativeTo);
  //    return new PositionAndDimensions(this, realativeTo, ignoreElementDimensions);
  //};

  $.fn.positionAndDimensions = function (this: JQuery, realativeTo?: ContainerType) {
    return new PositionAndDimensions(this, realativeTo);
  };


  $.fn.getJsonFromAttribute = function <T extends object = object>(this: JQuery, attribute: string): T {
    const data = this.attr(attribute) || this.data(attribute);
    if (typeof data === "object") {
      return data as T;
    }
    if (typeof data !== "string" || !data.length) {
      return undefined;
    }
    try {
      return JSON.parse(data, jsonDateReviver);
    } catch (e) {
      //const fixedData = data.replace(/(\{?s*)(["'])?(\w+)\2\s*:\s*((?:(["'])(.*?)\5|(\w+))|\[\s*(?:(["'])(.*?)\8|(\w+)\s*)(,\s*(?:(["'])(.*?)\12|(\w+))\s*)*\])\s*(,?)\}?/g,
      //    (match: string, ...g: string[]) => {
      //        g = [""].concat(g);
      //        var key = g[3];
      //        var value = g[4];
      //        if (g[4])
      //            return `"${g[3]}":"${g[5]}"${g[7]}`;
      //        return `"${g[3]}":${g[6]}${g[7]}`;
      //    });
      let fixedData = data.replace(/'/g, "\"");
      if (fixedData[0] !== "{") fixedData = `{${fixedData}}`;
      return JSON.parse(fixedData);
    }
  };

  $.fn.touchClick = function (this: JQuery, eventHandler: (event: JQueryEventObject) => void) {
    this.on("touchclick", eventHandler);
    this.each((index, elem) => {
      const $elem = $(elem);
      if (!$elem.data("touchlclick-trigger")) {
        let clickDeactivationId: number;
        let ignoreTouchEnd = false;
        const scrollHandler = () => {
          ignoreTouchEnd = true;
          window.removeEventListener("scroll", scrollHandler, true);
        };
        elem.addEventListener("touchstart",
                              e => {
                                (e.target as HTMLElement).classList.add("touch-down");
                                window.addEventListener("scroll", scrollHandler, true);
                              }, {passive: true});

        elem.addEventListener("click",
                              (e: MouseEvent) => {
                                if (e.data && e.data.touchClickHandled) {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  return;
                                }
                                e.data = $.extend(e.data, {touchClickHandled: true});
                                if (!clickDeactivationId) {
                                  const event = new CustomEvent("touchclick", {bubbles: true});
                                  event.originalEvent = e;
                                  e.target.dispatchEvent(event);
                                }
                              });
        elem.addEventListener("touchend",
                              (e: TouchEvent) => {
                                (e.target as HTMLElement).classList.remove("touch-down");
                                if (e.data && e.data.touchClickHandled) {
                                  return;
                                }
                                e.data = $.extend(e.data, {touchClickHandled: true});
                                if (clickDeactivationId) {
                                  window.clearTimeout(clickDeactivationId);
                                }
                                clickDeactivationId = window.setTimeout(() => {
                                  clickDeactivationId = null;
                                }, 1000);
                                window.removeEventListener("scroll", scrollHandler, true);
                                if (ignoreTouchEnd) {
                                  ignoreTouchEnd = false;
                                  return;
                                }
                                if (!(e.currentTarget as HTMLElement).getBoundingClientRect) {
                                  const event = new CustomEvent("touchclick", {bubbles: true});
                                  event.originalEvent = e;
                                  e.target.dispatchEvent(event);
                                  // window.setTimeout(() => {
                                  //     e.target.dispatchEvent(event);
                                  // });
                                } else {
                                  const clientRect = (e.currentTarget as HTMLElement).getBoundingClientRect();
                                  const touch = e.changedTouches[0];
                                  const radiusX = touch.radiusX || 0;
                                  const radiusY = touch.radiusY || 0;
                                  const horizontalMatch = (touch.clientX + radiusX) > clientRect.left
                                                          && (touch.clientX - radiusX) < clientRect.left +
                                                          clientRect.width;
                                  const verticalMatch = (touch.clientY + radiusY) > clientRect.top
                                                        && (touch.clientY - radiusY) < clientRect.top +
                                                        clientRect.height;
                                  if (horizontalMatch && verticalMatch) {
                                    const event = new CustomEvent("touchclick", {bubbles: true});
                                    event.originalEvent = e;
                                    e.target.dispatchEvent(event);
                                    // window.setTimeout(() => {
                                    //     e.target.dispatchEvent(event);
                                    // });
                                  }
                                }

                              }, {passive: true});
        $elem.data("touchlclick-trigger", true);
      }
    });
    return this;
  };

  $.fn.touchSaveOn = function (this: JQuery,
                               mouseEvents: string,
                               toucheEvents: string,
                               eventHandler: (event: JQueryEventObject) => void,
                               checkInterval = 10000): JQuery {
    let touchEnabled = false;
    let timeout: number;
    this.on(toucheEvents,
            (e) => {
              touchEnabled = true;
              if (timeout) window.clearTimeout(timeout);
              timeout = window.setTimeout(() => touchEnabled = false, checkInterval);
              eventHandler(e);
            });
    this.on(mouseEvents,
            (e) => {
              if (!touchEnabled) {
                eventHandler(e);
              }
            });
    return this;
  };

  $.fn.id = function (this: JQuery, generator?: string | ((pregeneratedId: string) => string)): any {
    if (typeof generator === "string") {
      return this.first().attr("id", generator);
    }
    let id = $(this).attr("id");
    if (id && id.length) {
      return id;
    }
    id = `unique-id-${new Date().getTime()}-${Math.floor(Math.random() * 10000)}`;
    if (generator) {
      id = generator(id);
    }
    $(this).attr("id", id);
    return id;
  };

  $.fn.parentsAndSelf = function (this: JQuery, selector?: string) {
    if (selector && selector.length) {
      return $(this.first().filter(selector).toArray().concat(this.parents(selector).toArray()));
    }
    return $(this.toArray().concat(this.parents(selector).toArray()));
  };

  $.fn.parentsUntilInclude = function (this: JQuery, selector?: string | Element | JQuery, filter?: string): JQuery {
    const parents = this.parentsUntil(selector as string, filter);
    return $(parents.toArray().concat(parents.parent().toArray()));
  };

  $.fn.hasChild = function (this: JQuery, selector) {
    return !!this.has(selector).length;
  };

  const originalIs = $.fn.is;
  $.fn.is = function (this: JQuery) {
    if (arguments[0] === undefined) {
      return !!this.length;
    }
    return originalIs.apply(this, arguments);
  };

  $.fn.clickAndRepeat = function (this: JQuery, eventHandler, timeoutBeforeRepeatStart = 800, repeatInterval = 200) {
    this.on("mousedown",
            (e: JQueryMouseEventObject) => {
              if (e.button !== 0) {
                return;
              }
              const element = $(e.currentTarget);
              eventHandler.call(element[0], e);
              let interval: number;
              let timeout = window.setTimeout(() => {
                                                eventHandler.call(element[0], e);
                                                timeout = null;
                                                interval = window.setInterval(() => {
                                                  eventHandler.call(element[0], e);
                                                }, repeatInterval);
                                              },
                                              timeoutBeforeRepeatStart);
              element.on("mouseup.clickAndRepeat mouseleave.clickAndRepeat",
                         () => {
                           if (timeout) {
                             window.clearTimeout(timeout);
                           } else {
                             window.clearInterval(interval);
                           }
                           element.off("mouseup.clickAndRepeat mouseleave.clickAndRepeat");
                         });
            });
    return this;
  };

  $.fn.hasAnyFieldWithValue = function (this: JQuery, recognizeInitValue: boolean = false) {
    const $this = $(this);
    const inputs: Array<HTMLFormField> = $("input, textarea, select", $this) as any;

    for (const input of inputs) {
      if (input.disabled) {
        continue;
      }
      if (input instanceof HTMLTextAreaElement) {
        if (input.value && (!recognizeInitValue || input.value !== $(input).data("init-value"))) {
          return true;
        }
      } else if (input instanceof HTMLSelectElement) {
        if (input.value && (!recognizeInitValue || input.value !== $(input).data("init-value"))) {
          return true;
        }
      } else {
        switch (input.type) {
          case "button":
          case "reset":
          case "submit":
          case "hidden":
            continue;
          case "radio":
          case "checkbox":
            if (input.checked) {
              return true;
            }
            break;
          default:
            if (input.value && (!recognizeInitValue || input.value !== $(input).data("init-value"))) {
              return true;
            }
        }
      }

    }
    return false;
  };

  $.fn.hasValue = function (this: JQuery) {
    const value = this.val();
    if (value instanceof Array || typeof value === "string") {
      return value.length > 0;
    }
    return value !== null && value !== undefined;
  };

  $.fn.data = (function (original: Function) {
    return function <T = any>(this: JQuery, ...args: any[]): any {
      const useCache = args[0] as boolean;
      if (typeof useCache === "boolean") {
        if (!useCache && this.hasAttr("data-" + args[1])) {
          this.removeData(args[1]);
        }
        args.shift();
      }
      return original.apply(this, args);
    };
  })($.fn.data);

  $.fn.booleanAttr = function (this: JQuery, key: string) {
    const attr = this.attr(key);
    if (typeof attr === "undefined") {
      return undefined;
    }
    return attr !== "false";
  };

  $.fn.booleanData = function (this: JQuery) {
    const attr = this.data.apply(this, arguments);
    if (typeof attr === "undefined") {
      return undefined;
    }
    return attr !== false;
  };

  $.fn.hasAttr = function (this: JQuery, key: string) {
    return this.attr(key) !== undefined;
  };

  $.fn.hasData = function (this: JQuery, useCache: any, key?: keyof IJqueryDataVariants): boolean {
    if (key === undefined) {
      key = useCache;
    }
    if (!key) {
      return false;
    }
    if (useCache === false) {
      this.removeData(key);
    }
    const data = this.data();
    return data !== undefined && data.hasOwnProperty(key.toCamelCase());
  };

  $.fn.typedData = $.fn.data;

  $.fn.isAttached = function (this: JQuery) {
    for (let i = 0; i < this.length; i++) {
      if (!document.contains(this[i])) {
        return false;
      }
    }
    return true;
  };

  $.fn.overwriteTitle = function (this: JQuery, title: string | ((index: number, element: Element) => string)) {
    return this.overwriteAttr("title", title);
  };

  $.fn.removeOverwrittenTitle = function (this: JQuery) {
    return this.removeOverwrittenAttr("title");
  };

  function internalOverwrite($elements: JQuery,
                             type: "prop" | "attr",
                             name: string,
                             value: string | ((index: number, element: Element) => string)) {
    if (!name) {
      return;
    }
    const setValue = type === "attr" ? $.fn.attr : $.fn.prop;
    let dataKey = `data-${type}-${name}`.toCamelCase();
    return $elements
      .each((i, elem) => {
        const $elem = $(elem);
        if (!$elem.hasData(dataKey)) {
          const dataObj: IIndexable<any> = {};
          dataObj[dataKey] = $elem.attr(name);
          $elem.data(dataObj);
        }
        setValue.call($elem, name, UnwrapFunction(value, i, elem));
      })
  }

  function internalRemoveOverwrite($elements: JQuery, type: "prop" | "attr", name: string) {
    if (!name) {
      return;
    }
    const setValue = type === "attr" ? $.fn.attr : $.fn.prop;
    const removeValue = type === "attr" ? $.fn.removeAttr : $.fn.removeProp;
    const dataKey = `data-${type}-${name}`;
    return $elements
      .each((i, elem) => {
        const $elem = $(elem);
        let data = $elem.data();
        if (!data.hasOwnProperty(dataKey.toCamelCase())) {
          return;
        } else if (data[dataKey.toCamelCase()] === undefined) {
          removeValue.call($elem, name);
        } else {
          setValue.call($elem, name, data[dataKey.toCamelCase()]);
        }
        $elem.removeData(dataKey)
      })
  }

  $.fn.overwriteAttr =
    function (this: JQuery, name: string, value: string | ((index: number, element: Element) => string)) {
      return internalOverwrite(this, "attr", name, value);
    };

  $.fn.removeOverwrittenAttr = function (this: JQuery, name: string) {
    return internalRemoveOverwrite(this, "attr", name);
  };

  $.fn.overwriteProp =
    function (this: JQuery, name: string, value: any | ((index: number, element: Element) => any)) {
      return internalOverwrite(this, "prop", name, value);
    };

  $.fn.removeOverwrittenProp = function (this: JQuery, name: string) {
    return internalRemoveOverwrite(this, "prop", name);
  };

  $.fn.disableWhenOffline = function (this: JQuery) {
    disableElementsWhenOffline(this);
    return this;
  };
  $.fn.padding = function (this: JQuery) {
    const paddings = this.css("padding").split(" ").map(p => p + "0");
    return {
      top: parseInt(paddings[0]),
      bottom: parseInt(paddings[2] || paddings[0]),
      left: parseInt(paddings[3] || paddings[1] || paddings[0]),
      right: parseInt(paddings[1] || paddings[0])
    }
  }

  function tapHandler(e: Event, tapHandler: () => void) {
    e.preventDefault();
    const $elem = $(this);
    $elem.data(e.type, 1);
    if (e.type === 'touchend' && !$elem.data('touchmove')) {
      tapHandler();
    } else if ($elem.data('touchend')) {
      $elem.removeData('touchstart touchmove touchend');
    }
  }

  $.fn.tap = function (this: JQuery, handler: () => void) {
    return this.bind('touchstart', e => tapHandler(e, handler))
               .bind('touchmove', e => tapHandler(e, handler))
               .bind('touchend', e => tapHandler(e, handler));
  }

})(jQuery);

export class PositionAndDimensions {

  readonly top: number;
  readonly bottom: number;
  readonly left: number;
  readonly right: number;
  readonly height: number;
  readonly width: number;

  constructor(elem: JQuery | HTMLElement, relativeTo?: ContainerType) {
    const $elem = $(elem);
    const docHeight = $(document.body).outerHeight();
    const docWidth = $(document.body).outerWidth();
    this.height = $elem.outerHeight();
    this.width = $elem.outerWidth();
    const offset = $elem.offset();
    this.top = offset.top;
    this.bottom = docHeight - this.top - this.height;
    this.left = offset.left;
    this.right = docWidth - offset.left - this.width;


    if (relativeTo) {
      let $relativeTo = $(Utils.UnwrapContainerFunction(relativeTo));
      relativeTo = $relativeTo[0] as HTMLDivElement;
      if (!["fixed", "absolute", "relative"].contains($relativeTo.css("position"))) {
        $relativeTo = $relativeTo.offsetParent();
      }
      if (!$relativeTo.length || $relativeTo[0] === document.body) {
        return;
      }
      const relativeOffset = $relativeTo.offset();
      this.top -= relativeOffset.top - $relativeTo.scrollTop() + parseInt($relativeTo.css("border-top-width"));
      this.left -=
        relativeOffset.left - $relativeTo.scrollLeft() + parseInt($relativeTo.css("border-left-width"));
      this.bottom -= docHeight - relativeOffset.top - $relativeTo.height();
      const scrollbarWidth = detect.browser.ie ? 0 : relativeTo.offsetWidth - relativeTo.clientWidth;
      this.right -= docWidth - relativeOffset.left - $relativeTo.width() + scrollbarWidth;
      if ($elem.css("position") !== "absolute") {
        // const padding = $relativeTo.padding();
        // this.top += padding.top;
        // this.bottom += padding.bottom;
        // this.left += padding.left;
        // this.right += padding.right;
      }
    } else if ($elem.css("position") !== "absolute") {
      // const padding = $(document.body).padding();
      // this.top += padding.top;
      // this.bottom += padding.bottom;
      // this.left += padding.left;
      // this.right += padding.right;
    }
  }

  //constructor(elem: JQuery | HTMLElement, ignoreElementDimensions?: boolean);
  //constructor(elem: JQuery | HTMLElement, realativeTo?: ContainerType, ignoreElementDimensions?: boolean);
  //constructor(elem: JQuery | HTMLElement, realativeTo?: ContainerType | boolean, ignoreElementDimensions = false) {
  //    if (typeof realativeTo === "boolean") {
  //        ignoreElementDimensions = realativeTo;
  //        realativeTo = document.body;
  //    }
  //    const $elem = $(elem);
  //    this.height = $elem.outerHeight();
  //    this.width = $elem.outerWidth();
  //    const offset = $elem.offset();
  //    let $relativeTo = $(Utils.UnwrapContainerFunction(realativeTo) || document.body);

  //    if (!["fixed", "absolute", "relative"].contains($relativeTo.css("position")))
  //        $relativeTo = $relativeTo.offsetParent();
  //    const originalDisplay = $elem[0].style.display;
  //    if (ignoreElementDimensions)
  //        $elem[0].style.display = "none";
  //    const relativeOffset = $relativeTo.offset();
  //    const relativeToHeight = $relativeTo.css("overflow-y") === "hidden" || $relativeTo[0] === document.body
  //        ? $relativeTo[0].clientHeight
  //        : $relativeTo[0].scrollHeight;
  //    const relativeToWidth = $relativeTo.css("overflow-x") === "hidden" || $relativeTo[0] === document.body
  //        ? $relativeTo[0].clientWidth
  //        : $relativeTo[0].scrollWidth;
  //    const relativeToBorderTopWidth = (parseInt($relativeTo.css("border-top-width")) || 0);
  //    const relativeToBorderLeftWidth = (parseInt($relativeTo.css("border-left-width")) || 0);

  //    this.top = offset.top - relativeOffset.top - relativeToBorderTopWidth + $relativeTo.scrollTop();
  //    this.left = offset.left - relativeOffset.left - relativeToBorderLeftWidth + $relativeTo.scrollLeft();
  //    this.bottom = relativeToHeight - this.top - this.height;
  //    this.right = relativeToWidth - this.left - this.width;
  //    //} else {
  //    //    this.top = offset.top;
  //    //    this.bottom = docHeight - this.top - this.height;
  //    //    this.left = offset.left;
  //    //    this.right = docWidth - offset.left - this.width;
  //    //}
  //    if (ignoreElementDimensions)
  //        $elem[0].style.display = originalDisplay;

  equals = (other: PositionAndDimensions, includeDimensions = true) => {
    if (this === other) {
      return true;
    }
    if (!other) {
      return false;
    }
    return (this.top === other.top
            && this.bottom === other.bottom
            && this.left === other.left
            && this.right === other.right
            && (!includeDimensions || this.height === other.height && this.width === other.width));
  };

  //}
  toString = () => {
    return `${this.top}_${this.bottom}_${this.left}_${this.right}_${this.height}_${this.width}`;
  };
}
