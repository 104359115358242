<div class="documents">
  <ng-container *ngFor="let documentList of documents">
    <a
      *ngFor="let document of documentList"
      [rt-document-link]="document">
    </a>
  </ng-container>
  <ng-container *ngFor="let invoiceGroup of (invoices | pureFunction:filterInvoices)">
    <div class="invoice-splitter" *ngIf="invoiceDisputeSetting$ | async as invoiceDisputeSettings">
      <div></div>
      <div class="invoice-number">Invoice {{invoiceGroup.key}}</div>
      <a *ngIf="hasDisputeInvoiceRights(invoiceDisputeSettings)" style="background-color: white; font-size: 12px; z-index: 1; white-space: nowrap;"
         (click)="openLodgeInvoiceModal(invoiceGroup)">
      Lodge Dispute
      </a>
      <div></div>
    </div>
    <a
      *ngFor="let document of invoiceGroup.items"
      [rt-document-link]="document">
    </a>
  </ng-container>
</div>
