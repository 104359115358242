import {PreLoginViewData} from "@services/view-data";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class ChangePasswordCompleteViewData extends PreLoginViewData {

    constructor() {
        super();
    }

    get pageTitle(): string {
        return TitleBuilder.account.changePasswordComplete;
    }

    get url(): string {
        return UrlBuilder.account.changePasswordComplete;
    }

    readonly bodyClasses = ["page-pre-login", "page-request-account"];
}
