<a
        class="no-focus-highlight"
        routerLink="/Shipment/ContainerDetail" [queryParams]="container | pureFunction:getQueryParams">
    <header>
        <h3>
            Container Number
            <strong>{{container.containerNumber | rtTbd }}</strong>
        </h3>
        <p class="status" [ngClass]="container | rtDeliveryStatusTextColorClass">{{container.status | rtDeliveryStatus}}</p>
    </header>
    <div class="progress">
        <rt-shipment-list-timeline [shipment]="container"></rt-shipment-list-timeline>
        <p *ngIf="container.lastEvent as lastEvent" class="description">
            {{lastEvent.description}}, <span class="text-light">{{lastEvent.actualDate | rtDate}}</span>
        </p>
        <p *ngIf="container.lastEvent | not" class="description">&nbsp;</p>
    </div>
    <div class="informations">
        <p class="container-icon" [ngClass]="container | rtContainerIconClass">
        </p>
       <rt-html-container [html]="container | pureFunction:renderFields:(boxLayout$ | async):renderer"></rt-html-container>
    </div>
    <div class="labels">
        <p class="label" *ngFor="let transportMode of container.transportModes">{{transportMode}}</p>
        <p *ngFor="let containerMode of container.containerModes" class="label">{{containerMode}}</p>
        <p *ngIf="container.isCustomsDeclaration" class="label">Customs Clearance Only</p>
        <p *ngIf="container.hasDangerousGoods" class="label">Dangerous Goods</p>
        <!--          <p *ngIf="container.isLead" class="label">Lead</p>-->
    </div>
</a>
