import {Component, ViewChild} from '@angular/core';
import {ChangePasswordViewData, ForgotPasswordViewData} from "@view-data";
import {filter, takeUntil} from "rxjs/operators";
import {TypeFilter} from "@utils/rxjs-extensions";
import {InputValidatorDirective} from "@directives";
import {AccountService} from "@services";
import {RoutingService} from "@app/services/routing.service";
import {AppBaseComponent} from "@app/appBaseComponent";
import {LoginComponent} from "@app/page-components";

@Component({
             selector: 'rt-forgot-password',
             templateUrl: './forgot-password.component.html',
             styleUrls: ['./forgot-password.component.scss']
           })
export class ForgotPasswordComponent extends AppBaseComponent {
  mailAddress: string;
  @ViewChild(InputValidatorDirective)
  mailAddressValidator: InputValidatorDirective
  isRequestingToken: boolean;
  private readonly _accountService: AccountService;
  private readonly _routingService: RoutingService;
  private _rohligPasswordResetModal = LoginComponent.createRohligResetPasswordModal();

  constructor(routingService: RoutingService, accountService: AccountService) {
    super();
    this._accountService = accountService;
    this._routingService = routingService;
    routingService
      .routingStore
      .observe("currentViewData")
      .pipe(
        filter(TypeFilter(ForgotPasswordViewData)),
        takeUntil(this.destroyed$))
      .subscribe(d => {
        this.mailAddress = d.mailAddress
      })
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._rohligPasswordResetModal.destroy();
  }

  async resetPassword(): Promise<void> {
    if (!await this.mailAddressValidator.validate())
      return;
    const isRohligMailAddress = /@rohlig\.co.*$/i.exec(this.mailAddress);
    if (isRohligMailAddress) {
      this._rohligPasswordResetModal.openModal();
      return;
    }
    this.isRequestingToken = true;
    // noinspection ES6MissingAwait
    await this._accountService.requestPasswordReset(this.mailAddress);
    this.isRequestingToken = false;
    this._routingService.navigateTo(new ChangePasswordViewData())
  }
}
