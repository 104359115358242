import {Injectable} from "@angular/core";
import {Store, StoreProjectionMapping} from "@app/stores/store";
import {UserInformation} from "@api";
import {BookingTemplateSelectItem} from "@widgets/BookingTemplateSelect";

interface AccountStoreProjection {
  bookingTemplates: BookingTemplateSelectItem[];
}

@Injectable({providedIn: "root"})
export class AccountStore extends Store<UserInformation, AccountStoreProjection> {

  private static _initialState: Partial<UserInformation> = {
    shipmentSearchPresets: [],
    shipmentCardLayout: [],
    shipmentListLayout: [],
    availableEntitySets: [],
    orderSearchPresets: [],
    privateBookingPresets: []
  };
  private static _projections: StoreProjectionMapping<UserInformation, AccountStoreProjection> = {
    bookingTemplates: u => {
      const entityTemplates = u.currentEntitySet?.sharedBookingTemplates || [];
      return entityTemplates
        .concat(u.privateBookingPresets || [])
        .orderBy(t => t.templateInformation?.templateName)
        .map(t => {
          return {
            label: t.templateInformation.templateName,
            value: t.templateInformation.templateID,
            shared: !t.templateInformation.private,
            description: t.templateInformation.description,
            data: t
          }
        });
    }
  }
  private _initialized = false;

  constructor() {
    super(AccountStore._projections, AccountStore._initialState, false);
  }

  getName(): string {
    return "AccountStore";
  }

  initialize(userInformation?: UserInformation): void {
    if (this._initialized)
      return;
    if (userInformation)
      this.update(Object.assign({}, this._initialState, userInformation))
    else
      this.reset();
    this._initialized = true;
  }

}


