import {ApplicationRef, Component, ElementRef, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {GoodsContainersComponent} from "./goods-containers.component";
import {GoodsPackagesComponent} from "./goods-packages.component";
import {ViewComponent} from "./view.component";
import {FormValidator, ValidationField} from "@widgets/Validate";
import {IView} from "@utils/Angular/View";
import {BookingServiceListener} from "@services/booking-service-listener.service";
import {GetView} from "@booking/booking-views";
import { unescapeHtml } from "../utils/Utils";

@Component({
             selector: "goods",
             template: `
               <goods-containers [hidden]="model.booking.shipmentType !== 'FCL'"></goods-containers>
               <goods-packages [hidden]="model.booking.shipmentType === 'FCL'"></goods-packages>

               <div class="form-group" #goodsDescription>
                 <label for="GoodsDescription">Goods Description</label>
                 <textarea [attr.required]="mode === 'booking' ? 'required' : null"
                           style="max-width:400px"
                           id="GoodsDescription"
                           [(ngModel)]="model.booking.goodsDescription"></textarea>
               </div>
               <div class="form-group" #marksAndNumbers>
                 <label for="MarksNumbers" [class.optional]="model.booking.shipmentType === 'FCL'">Marks &amp;
                   Numbers</label>
                 <textarea
                   style="max-width:400px"
                   id="MarksNumbers"
                   [attr.required]="model.booking.shipmentType==='FCL' && mode === 'booking'? null:'required'"
                   maxlength="200"
                   [(ngModel)]="model.booking.marksAndNumbers"
                 ></textarea>
               </div>
               <div class="buttons phone-auto-width">
                 <button class="button secondary" [disabled]="!canNavigateBack" (click)="navigateBack()">Back
                 </button>
                 <button class="button" type="button" [disabled]="!canNavigateNext" (click)="navigateNext()">
                   Next
                 </button>
                 <button class="button secondary disable-when-offline" (click)="cancel()" type="button">Cancel
                 </button>
               </div>
             `
           })
export class GoodsComponent extends ViewComponent {

  @ViewChild("goodsDescription") goodsDescription: ElementRef;
  @ViewChild("marksAndNumbers") marksAndNumbers: ElementRef;
  private _goodsDescriptionValid: boolean;
  private _marksAndNumbersValid: boolean;
  @ViewChild(GoodsContainersComponent)
  private containers: GoodsContainersComponent;
  @ViewChild(GoodsPackagesComponent)
  private packages: GoodsPackagesComponent;

  constructor(element: ElementRef, router: Router, app: ApplicationRef, bookingServiceListener: BookingServiceListener) {
    super(element, app, router, bookingServiceListener);
  }
  ngOnInit(): void {
    this.model.booking.marksAndNumbers = unescapeHtml(this.model.booking.marksAndNumbers);
    this.model.booking.goodsDescription = unescapeHtml(this.model.booking.goodsDescription);
  }

  get isValid() {
    return this.getActiveComponent()
             .isValid && this.FormValidator.isValid;
  };

  set isValid(value: boolean) {
    this.getActiveComponent().isValid = value;
  }

  get isActivated() {
    return !!this.getActiveComponent()
  };

  set isActivated(value: boolean) {
    this.getActiveComponent().isActivated = value;
  }

  get canExit() {
    return this.getActiveComponent().canExit
  };

  set canExit(value: boolean) {
    this.getActiveComponent().canExit = value;
  }

  async validate() {
    await this.FormValidator.validate();
    return await this.getActiveComponent().validate() && this.FormValidator.isValid;
  };

  async entered() {
    await super.entered();
    await this.getActiveComponent().entered();
    if (this.model.booking.shipmentType === "FCL") {
      let validationField = $("textarea", this.marksAndNumbers.nativeElement)
        .typedData("validation-field") as ValidationField;
      if (validationField) {
        validationField.resetValidation();
      }
    }
  }

  left(): void {
    super.left();
    this.getActiveComponent().left();
  }

  onInitialized(): void {
    this.FormValidator =
      new FormValidator($([this.goodsDescription.nativeElement, this.marksAndNumbers.nativeElement]));
    this.FormValidator.validationFields[1].validateData.validators.required.isActive =
      () => this.model.booking.shipmentType !== "FCL" && this.bookingServiceListener.currentBookingMode === "booking";
    this.FormValidator.validationFields[0].validateData.validateDetachedElements = true;
    this.FormValidator.validationFields[1].validateData.validateDetachedElements = true;
    this.FormValidator.onValidated.on(e => {
      this.isValid = e.data && this.getActiveComponent().isValid;
      GetView(this).valid = e.data && this.getActiveComponent().isValid;
    });
    super.onInitialized();
  }

  private getActiveComponent(): IView {
    const defaultValue: IView = {
      validate: () => Promise.resolve(false),
      isActivated: false,
      isValid: undefined,
      canExit: true,
      entered: () => {
      },
      left: () => {
      },
      canLeave: () => Promise.resolve(true),
      canNavigateBack: true,
      canNavigateNext: true,
      model: null,
      name: null
    };
    const shipmentType = this.model.booking.shipmentType;
    if (shipmentType === "FCL") {
      return this.containers || defaultValue;
    }
    if (shipmentType === "LCL" || shipmentType === "LSE") {
      return this.packages || defaultValue;
    }
    return defaultValue;
  }

  readonly name: string = "GoodsComponent";
}
