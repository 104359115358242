import {Store} from "@app/stores/store";
import {Injectable} from "@angular/core";
import {MainMasterUiState} from "@app/master-components/main/main-master-ui.state";

@Injectable()
export class MainMasterUiStore extends Store<MainMasterUiState> {
  private static readonly  _initialState: MainMasterUiState =  {
    navMenuOpen: false,
    userMenuOpen: false
  };

  constructor() {
    super({},MainMasterUiStore._initialState);
  }

  getName(): string {
    return "MainMasterUiStore";
  }
}
