import {AfterViewInit, ApplicationRef, Component, ElementRef, ViewChild} from "@angular/core";
import {ViewComponent} from "./view.component";
import {Router} from "@angular/router";
import {AddressSelect, IAddressSelectOptions} from "@widgets/AddressSelect";
import {Select} from "@widgets/Select";
import * as Utils from "../utils/Utils"
import {renderAddress, sleep, UnwrapFunction} from "@utils/Utils"
import {SlideInOut} from "@utils/Angular/AnimationTemplates"
import {AddressEdit} from "@widgets/AddressEdit";
import {DatetimepickerDirective, SelectDirective} from "@directives";
import {Datetimepicker} from "@widgets/Datetimepicker";
import {ISelectListItem, ISelectListOptions} from "@widgets/SelectList";
import {filter, map, takeUntil} from "rxjs/operators";
import {EntitySetInfo, JsonAddress, JsonBooking, JSONData} from "@api";
import {BookingServiceListener} from "@services/booking-service-listener.service";
import {hasValue} from "@utils/rxjs-extensions";
import {BookingModel} from "@booking/BookingModel";
import {AccountStore} from "@app/stores";


@Component(
  {
    selector: "parties",
    animations: [SlideInOut()],
    template: `
      <h2>Parties</h2>
      <div class="form-group big">
        <label for="booking-site-select" class="required">Who are you booking for?</label>
        <select id="booking-site-select"
                data-do-not-init
                [(ngModel)]="model.booking.sitePK"
                style="display: none;"></select>
        <div class="fake-select custom-select" tabindex="0" style="max-width: 380px;">
          <span>{{model.booking.siteName}}</span></div>
      </div>
      <div class="form-section">
        <h3>Shipper</h3>
        <div class="form-group">
          <label for="shipper-address-select">Address</label>
          <select data-do-not-init id="shipper-address-select"
                  [attr.required]="mode === 'booking' ? 'required' : null"
                  style="display:none;"></select>
          <div class="fake-select"><span>{{getAddressLabel(model.booking.consignorAddress)}}</span></div>
        </div>
        <div
          style="min-height: 135px;"
          [innerHTML]="renderAddress(model.booking.consignorAddress)"
          [@slideInOut]="model.booking.consignorAddress ? 'visible' : 'hidden'">
        </div>
      </div>
      <div class="form-section">
        <h3>Consignee</h3>
        <div class="form-group">
          <label for="consignee-address-select">Address</label>
          <select data-do-not-init
                  id="consignee-address-select"
                  [attr.required]="mode === 'booking' ? 'required' : null"
                  style="display:none;"></select>
          <div class="fake-select"><span>{{getAddressLabel(model.booking.consigneeAddress)}}</span></div>
        </div>
        <div
          style="min-height: 135px;"
          [innerHTML]="renderAddress(model.booking.consigneeAddress)"
          [@slideInOut]="model.booking.consigneeAddress ? 'visible' : 'hidden'">
        </div>
      </div>
      <div class="form-section-group">
        <div class="form-section" style="padding-bottom:79px">
          <h3>Pickup Location</h3>
          <div class="form-group">
            <input type="radio" name="pickupAddressType" value="sameAddress"
                   id="pickupAddressType_sameAddress"
                   [(ngModel)]="model.booking.templateInformation.pickupAddressType"/>
            <label for="pickupAddressType_sameAddress">Same as Shipper</label>
            <input type="radio" name="pickupAddressType" value="customAddress"
                   id="pickupAddressType_customAddress"
                   [(ngModel)]="model.booking.templateInformation.pickupAddressType"/>
            <label for="pickupAddressType_customAddress">Different address</label>
            <input type="radio" name="pickupAddressType" value="none" id="pickupAddressType_none"
                   [(ngModel)]="model.booking.templateInformation.pickupAddressType"/>
            <label for="pickupAddressType_none">Not required or I will arrange transport to the Port of
              Loading
              myself</label>
          </div>
          <div class="form-group"
               [@slideInOut]="model.booking.templateInformation.pickupAddressType !== 'customAddress' ? 'hidden' : 'visible'">
            <label for="pickup-address-select">Address</label>
            <select data-do-not-init
                    [attr.data-disable-validation]="model.booking.templateInformation.pickupAddressType !== 'customAddress'"
                    id="pickup-address-select"
                    [attr.required]="mode === 'booking' ? 'required' : null"
                    style="display:none;"></select>
            <div class="fake-select"><span>{{getAddressLabel(model.booking.pickupAddress)}}</span></div>
          </div>
          <div class="form-group"
               [@slideInOut]="model.booking.templateInformation.pickupAddressType !== 'none' ? 'hidden' : 'visible'">
            <label for="port-of-loading-select">Port of Loading</label>
            <select data-do-not-init
                    [attr.data-disable-validation]="model.booking.templateInformation.pickupAddressType !== 'none'"
                    id="port-of-loading-select"
                    ngSelect
                    #polSelect="select"
                    [items]="selectedPortOfLoading"
                    (selectionChanged)="portOfLoadingChanged($event)"
                    [settings]="portSearchOptions"
                    [attr.required]="mode === 'booking' ? 'required' : null"></select>
          </div>
          <div
            style="min-height: 135px;"
            [innerHTML]="renderAddress(model.booking.pickupAddress)"
            [@slideInOut]="model.booking.templateInformation.pickupAddressType === 'customAddress' && model.booking.pickupAddress ? 'visible' : 'hidden'">
          </div>
          <div
            style="min-height: 135px;"
            [innerHTML]="renderAddress(model.booking.consignorAddress)"
            [@slideInOut]="model.booking.templateInformation.pickupAddressType === 'sameAddress' && model.booking.consignorAddress ? 'visible' : 'hidden'">
          </div>
          <div class="form-group bottom-aligned">
            <label *ngIf="model.booking.templateInformation.pickupAddressType === 'none'"
                   for="pickup-datetimepicker">Ready at Port of
              Loading</label>
            <label *ngIf="model.booking.templateInformation.pickupAddressType !== 'none'"
                   for="pickup-datetimepicker">Ready at Pickup
              Location</label>
            <div *ngIf="mode === 'booking'">
              <input type="date"
                     id="pickup-datetimepicker"
                     (onDateChanged)="setPickupDateTime($event)"
                     data-live-validation
                     data-visual-element="next"
                     data-no-validation-message
                     required="required"
                     ngDatetimepicker #pickupDatePicker="datetimepicker"/>
              <p *ngIf="model.booking.templateInformation.pickupAddressType === 'none'"
                 class="error-message hidden">Ready at Port of Loading is
                required</p>
              <p *ngIf="model.booking.templateInformation.pickupAddressType !== 'none'"
                 class="error-message hidden">Ready at Pickup Location is
                required</p>
            </div>
            <div *ngIf="mode === 'template'">
              <input type="date"
                     [attr.data-display-name]="model.booking.templateInformation.pickupAddressType === 'none' ?
                                   'Ready at Port of Loading' : 'Ready at Pickup Location'"
                     id="relative-pickup-datetimepicker"
                     (onDateChanged)="setRelativePickupDateTime()"
                     data-live-validation
                     data-visual-element="next"
                     data-no-validation-message
                     ngDatetimepicker
                     [datePickerMode]="'relative'"
                     [absoluteLabel]="'{0} if booked today'"
                     #relativePickupDatePicker="datetimepicker"/>
              <p *ngIf="model.booking.templateInformation.pickupAddressType === 'none'"
                 class="error-message hidden">Ready at Port of Loading is
                required</p>
              <p *ngIf="model.booking.templateInformation.pickupAddressType !== 'none'"
                 class="error-message hidden">Ready at Pickup Location is
                required</p>
            </div>
          </div>
        </div>
        <div class="form-section" style="padding-bottom:79px">
          <h3>Delivery Location</h3>
          <div class="form-group">
            <input type="radio" name="deliveryAddressType" value="sameAddress"
                   id="deliveryAddressType_sameAddress"
                   [(ngModel)]="model.booking.templateInformation.deliveryAddressType"/>
            <label for="deliveryAddressType_sameAddress">Same as Consignee</label>
            <input type="radio" name="deliveryAddressType" value="customAddress"
                   id="deliveryAddressType_customAddress"
                   [(ngModel)]="model.booking.templateInformation.deliveryAddressType"/>
            <label for="deliveryAddressType_customAddress">Different address</label>
            <input type="radio" name="deliveryAddressType" value="none" id="deliveryAddressType_none"
                   [(ngModel)]="model.booking.templateInformation.deliveryAddressType"/>
            <label for="deliveryAddressType_none">Not required or I will arrange transport from the Port of
              Discharge myself</label>
          </div>
          <div class="form-group"
               [@slideInOut]="model.booking.templateInformation.deliveryAddressType !== 'customAddress' ? 'hidden' : 'visible'">
            <label for="delivery-address-select">Address</label>
            <select data-do-not-init
                    [attr.data-disable-validation]="model.booking.templateInformation.deliveryAddressType !== 'customAddress'"
                    id="delivery-address-select"
                    [attr.required]="mode === 'booking' ? 'required' : null"
                    style="display:none;"></select>
            <div class="fake-select"><span>{{getAddressLabel(model.booking.deliveryAddress)}}</span></div>
          </div>
          <div class="form-group"
               [@slideInOut]="model.booking.templateInformation.deliveryAddressType !== 'none' ? 'hidden' : 'visible'">
            <label for="port-of-discharge-select">Port of Discharge</label>
            <select data-do-not-init
                    [attr.data-disable-validation]="model.booking.templateInformation.deliveryAddressType !== 'none'"
                    id="port-of-discharge-select"
                    ngSelect
                    #podSelect="select"
                    [items]="selectedPortOfDischarge"
                    (selectionChanged)="portOfDischargeChanged($event)"
                    [settings]="portSearchOptions"
                    [attr.required]="mode === 'booking' ? 'required' : null"></select>
          </div>
          <div
            style="min-height: 135px;"
            [innerHTML]="renderAddress(model.booking.deliveryAddress)"
            [@slideInOut]="model.booking.templateInformation.deliveryAddressType === 'customAddress' && model.booking.deliveryAddress ? 'visible' : 'hidden'">
          </div>
          <div
            style="min-height: 135px;"
            [innerHTML]="renderAddress(model.booking.consigneeAddress)"
            [@slideInOut]="model.booking.templateInformation.deliveryAddressType === 'sameAddress' && model.booking.consigneeAddress ? 'visible' : 'hidden'">
          </div>
          <div class="form-group bottom-aligned">
            <label for="delivery-datetimepicker" class="optional">Requested at
              {{model.booking.templateInformation.deliveryAddressType === 'none' ? 'Port of Discharge' : 'Delivery Location'}}</label>
            <div *ngIf="mode === 'booking'">
              <input type="date"
                     id="delivery-datetimepicker"
                     (onDateChanged)="setDeliverDateTime($event)"
                     ngDatetimepicker
                     #deliveryDatePicker="datetimepicker"/>
            </div>
            <div *ngIf="mode === 'template'">
              <input [attr.data-display-name]="model.booking.templateInformation.deliveryAddressType === 'none' ?
                                   'Requested at Port of Discharge' : 'Requested at Delivery Location'"
                     type="date"
                     id="relative-delivery-datetimepicker"
                     (onDateChanged)="setRelativeDeliverDateTime()"
                     ngDatetimepicker
                     [datePickerMode]="'relative'"
                     [absoluteLabel]="'{0} if booked today'"
                     #relativeDeliveryDatePicker="datetimepicker"/>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons phone-auto-width">
        <button class="button secondary" [disabled]="!canNavigateBack" (click)="navigateBack()">Back</button>
        <button class="button" type="button" [disabled]="!canNavigateNext" (click)="navigateNext()">Next
        </button>
        <button class="button secondary disable-when-offline" (click)="cancel()" type="button">Cancel</button>
      </div>
    `
  })
export class PartiesComponent extends ViewComponent implements AfterViewInit {
  readonly name: string = "PartiesComponent";
  @ViewChild("deliveryDatePicker")
  deliveryDatePicker: DatetimepickerDirective;
  @ViewChild("pickupDatePicker")
  pickupDatePicker: DatetimepickerDirective;
  @ViewChild("relativeDeliveryDatePicker")
  relativeDeliveryDatePicker: DatetimepickerDirective;
  @ViewChild("relativePickupDatePicker")
  relativePickupDatePicker: DatetimepickerDirective;
  shipperAddressSelect: AddressSelect;
  consigneeAddressSelect: AddressSelect;
  pickupAddressSelect: AddressSelect;
  deliveryAddressSelect: AddressSelect;
  renderAddress = renderAddress;
  relativeDatepickersInitialized = false;
  absoluteDatepickersInitialized = false;
  portSearchOptions: ISelectListOptions = {
    ajaxResultUrl: window.baseApplicationUrl + "/WebMethod/SearchUNLOCOs",
    maxResults: 25,
    displayIcon: true,
    searchOptions: () => this.model.booking.transportMode === "AIR"
                         ? {searchAirPorts: true, searchOfficialPorts: true}
                         : {searchSeaPorts: true, searchOfficialPorts: true}
  };
  selectedPortOfDischarge: Partial<JSONData>[];
  selectedPortOfLoading: Partial<JSONData>[];
  @ViewChild("podSelect")
  private podSelect: SelectDirective;
  @ViewChild("polSelect")
  private polSelect: SelectDirective;
  private lastTransportMode: "AIR" | "SEA" = null;
  private bookingSiteSelect: Select;
  private bookingSites: JSONData[] = [];
  private readonly _accountStore: AccountStore;

  constructor(element: ElementRef,
              router: Router,
              app: ApplicationRef,
              bookingServiceListener: BookingServiceListener,
              accountStore: AccountStore) {
    super(element, app, router, bookingServiceListener);
    this._accountStore = accountStore;
    bookingServiceListener.bookingBaseData$
                          .pipe(takeUntil(this.destroyed$))
                          .subscribe(data => {
                            AddressEdit.countries = data?.countries ?? [];
                          });
    bookingServiceListener.bookingModel$
                          .pipe(takeUntil(this.destroyed$),
                                filter(hasValue)
                          )
                          .subscribe(m => this._loadSelectedPorts(m))
  }

  getAddressLabel(address: JsonAddress) {
    if (!address)
      return "";
    if (address.displayName)
      return address.displayName;
    if (address.isExternalAddress || !address.city)
      return address.name;
    return address.name + " - " + address.city;
  }

  ngAfterViewInit(): void {
  }

  setPickupDateTime(event: object) {
    const date = event as Date;
    this.model.booking.pickupAt = date;
    if (date) {
      this.deliveryDatePicker.datetimepicker.minDate = date;
    } else {
      this.deliveryDatePicker.datetimepicker.minDate = new Date();
    }
    if (this.deliveryDatePicker.datetimepicker.date === null) {
      if (date) {
        this.deliveryDatePicker.datetimepicker.datepick("showMonth", date);
      } else {
        this.deliveryDatePicker.datetimepicker.datepick("showMonth", new Date());
      }
    }
    this.model.booking.pickupTimeSpecified = this.pickupDatePicker.datetimepicker.isTimeSet;
  }

  setDeliverDateTime(event: object) {
    const date = event as Date;
    this.model.booking.deliverAt = date;
    if (date) {
      this.pickupDatePicker.datetimepicker.maxDate = date;
    } else {
      this.pickupDatePicker.datetimepicker.maxDate = null;
    }
    this.model.booking.deliveryTimeSpecified = this.deliveryDatePicker.datetimepicker.isTimeSet;
  }

  setRelativePickupDateTime() {
    const relativeDate = this.relativePickupDatePicker.datetimepicker.relativeDate;
    this.model.booking.templateInformation.pickupAt = relativeDate;
    if (relativeDate) {
      this.relativeDeliveryDatePicker.datetimepicker.minDate =
        Datetimepicker.ConvertToAbsoluteValue(relativeDate);
    } else {
      this.relativeDeliveryDatePicker.datetimepicker.minDate = null;
    }
    sleep(10).then(() => this.relativeDeliveryDatePicker.datetimepicker.validate());
  }

  setRelativeDeliverDateTime() {
    this.model.booking.templateInformation.deliverAt = this.relativeDeliveryDatePicker.datetimepicker.relativeDate;
  }

  checkAddressChanges() {
    const selects: [AddressSelect, JsonAddress][] = [
      [this.consigneeAddressSelect, this.model.booking.consigneeAddress],
      [this.shipperAddressSelect, this.model.booking.consignorAddress],
      [this.pickupAddressSelect, this.model.booking.pickupAddress],
      [this.deliveryAddressSelect, this.model.booking.deliveryAddress]
    ];
    for (const select of selects) {
      const selectedItem = select[0].getSelectedItems()[0];
      if (select[1]) {
        if (selectedItem) {
          if (selectedItem.ajaxData.value === select[1].code && !selectedItem.disabled) {
            continue;
          }
          select[0].unselectItems(selectedItem, true, true);
        }
        select[0].processJsonData([{
          data: select[1],
          label: select[1].displayName,
          value: select[1].code,
          selected: true
        }]);
        select[0].selectItems(select[0].getCurrentItems(), true, true);
      } else if (selectedItem) {
        select[0].unselectItems(selectedItem, true, true);
      }
    }
  }

  async entered() {
    await super.entered();
    this.checkPorts();
    if (!this.isActivated) {
      return;
    }
    AddressSelect.recentExternalAddressCache.invalidateCache();
    AddressSelect.recentInternalAddressCache.invalidateCache();
    this.shipperAddressSelect.preloadAddresses(true);
    this.consigneeAddressSelect.preloadAddresses(true);
    this.checkAddressChanges();
    this.initializeDatepickers();
  }

  public checkPorts() {
    const transportModeAddedOrChanged = !this.lastTransportMode || this.model.booking.transportMode !== this.lastTransportMode;
    if (transportModeAddedOrChanged) {
      const params = {
        search: "",
        options: JSON.stringify(Object.assign({maxSearchResults: 1, codeSearchOnly: true},
                                              UnwrapFunction(this.portSearchOptions.searchOptions)))
      };
      if (this.podSelect && this.polSelect) {
        let podSelect = this.podSelect.select;
        let polSelect = this.polSelect.select;
        if (podSelect.getSelectedItems().length) {
          params.search = podSelect.getSelectedItems()[0].ajaxData.value;
          $.getJSON(UnwrapFunction(this.portSearchOptions.ajaxResultUrl),
                    params,
                    (data: JSONData[]) => {
                      data.forEach(d => d.selected = true);
                      polSelect.unselectItems(true, true);
                      podSelect.processJsonData(data);
                      podSelect.renderItems(true);
                    });
        }
        if (polSelect.getSelectedItems().length) {
          params.search = polSelect.getSelectedItems()[0].ajaxData.value;
          $.getJSON(UnwrapFunction(this.portSearchOptions.ajaxResultUrl),
                    params,
                    (data: JSONData[]) => {
                      data.forEach(d => d.selected = true);
                      podSelect.unselectItems(true, true);
                      polSelect.processJsonData(data);
                      polSelect.renderItems(true);
                    });
        }
      }
    }
    this.lastTransportMode = this.model.booking.transportMode
  }

  async validate(): Promise<boolean> {
    this.checkAddressChanges();
    return await super.validate();
  }

  async onInitialized() {

    this.initializeDatepickers();

    this.lastTransportMode = this.model.booking.transportMode;

    const addressSelects: {
      controlId: string,
      addressProp: keyof JsonBooking,
      addressSelectProp: keyof PartiesComponent,
      addressType: FunctionOrValue<"all" | "internalEntity">,
      addressSelect?: AddressSelect,
      template?: () => Partial<JsonAddress>,
      addressEditCountryWarning?: () => string;
    }[] = [
      {
        controlId: "shipper-address-select",
        addressProp: "consignorAddress",
        addressSelectProp: "shipperAddressSelect",
        addressType: () => this.model.booking.direction === "out" ? "internalEntity" : "all",
        addressEditCountryWarning: () => this.model.booking.direction === "out"
                                         ? "Addresses in this country can not be used as the Shipper"
                                         : null
      },
      {
        controlId: "consignee-address-select",
        addressProp: "consigneeAddress",
        addressSelectProp: "consigneeAddressSelect",
        addressType: () => this.model.booking.direction === "in" ? "internalEntity" : "all",
        addressEditCountryWarning: () => this.model.booking.direction === "in"
                                         ? "Addresses in this country can not be used as the Consignee"
                                         : null
      },
      {
        controlId: "pickup-address-select",
        addressProp: "pickupAddress",
        addressSelectProp: "pickupAddressSelect",
        addressType: "all",
        template: () => {
          if (this.model.booking.direction === "in") {
            return {
              isInternalAddress: false,
              isExternalAddress: true
            };
          } else {
            return {
              isInternalAddress: true,
              isExternalAddress: false,
              sitePK: this.model.booking.sitePK
            };
          }
        }
      },
      {
        controlId: "delivery-address-select",
        addressProp: "deliveryAddress",
        addressSelectProp: "deliveryAddressSelect",
        addressType: "all",
        template: () => {
          if (this.model.booking.direction === "out") {
            return {
              isInternalAddress: false,
              isExternalAddress: true
            };
          } else {
            return {
              isInternalAddress: true,
              isExternalAddress: false,
              sitePK: this.model.booking.sitePK
            };
          }
        }
      }
    ];

    for (const address of addressSelects) {
      const options: IAddressSelectOptions = {
        closeOnSelect: true,
        addressEditOptions: {
          entities$: this._accountStore
                         .observe("currentEntitySet")
                         .pipe(
                           filter(hasValue),
                           map(data => data.bookingSites)),
          entitySetId: () => this._accountStore.get("currentEntitySet")?.id,
          newAddressTemplate: address.template,
          countryWarning: address.addressEditCountryWarning
        },
        entitySetId: () => this._accountStore.get("currentEntitySet")?.id,
        entityId: () => this.model.booking.sitePK,
        itemToolTip: (item: JSONDataContainer<JsonAddress>) => item.disabled
                                                               ? "Real Time Booking is not available for this country"
                                                               : null
      };
      options.addressType = () => {
        return Utils.UnwrapFunction(address.addressType) === "internalEntity" ? "internal" : null;
      };
      options.addressSearchRange = () => {
        return (Utils.UnwrapFunction(address.addressType) === "internalEntity" ? "entity" : "all");
      };

      let selectElement = this.element.querySelector(`#${address.controlId}`) as HTMLSelectElement;
      const addressSelect =
        new AddressSelect(selectElement, options);
      (this as any)[address.addressSelectProp] = addressSelect;
      addressSelect.onSelectionChanged.on(
        e => {
          if (e.addedItems.length === 0) {
            // @ts-ignore
            this.model.booking[address.addressProp] = null;
          } else {
            // @ts-ignore
            this.model.booking[address.addressProp] = e.addedItems[0].ajaxData.data;
          }
        });
      // @ts-ignore
      if (this.model.booking[address.addressProp]) {
        // @ts-ignore
        const json = this.model.booking[address.addressProp] as JsonAddress;
        addressSelect.processJsonData([{
          value: json.code,
          label: json.displayName || json.isInternalAddress
                 ? [json.name, json.city].join(" - ", true)
                 : json.name,
          data: json,
          selected: true
        }]);
        addressSelect.selectItems(addressSelect.getCurrentItems());
      }
      address.addressSelect = addressSelect;
    }

    this.bookingSiteSelect = new Select(
      this.element.querySelector("#booking-site-select") as HTMLSelectElement,
      {
        search: false,
        closeOnSelect: true,
        allowNoSelection: false,
        toggleControlCss: {
          "max-width": "380px"
        },
        selectedOnTop: false
      });
    this.bookingSiteSelect.onSelectionChanged.on(this.bookingSiteSelectionHandler.bind(this));
    this._accountStore
        .observe("currentEntitySet")
        .pipe(
          filter(hasValue),
          takeUntil(this.destroyed$)
        )
        .subscribe(d => this.loadBookingSites(d));
    super.onInitialized();
    if (!this.FormValidator) {
      throw "no form validator";
    }
  }

  public initializeDatepickers() {
    const booking = this.model.booking;
    if (this.bookingServiceListener.currentBookingMode === "booking" && !this.absoluteDatepickersInitialized) {
      this.absoluteDatepickersInitialized = true;
      if (booking.pickupAt) {
        this.pickupDatePicker.datetimepicker.date = booking.pickupAt;
        if (!booking.pickupTimeSpecified) {
          this.pickupDatePicker.datetimepicker.clearTime();
        }
      }
      if (booking.deliverAt) {
        this.deliveryDatePicker.datetimepicker.date = booking.deliverAt;
        if (!booking.deliveryTimeSpecified) {
          this.deliveryDatePicker.datetimepicker.clearTime();
        }
      }
    }
    if (this.bookingServiceListener.currentBookingMode === "template" && !this.relativeDatepickersInitialized) {
      this.relativeDatepickersInitialized = true;
      if (booking.templateInformation.pickupAt) {
        this.relativePickupDatePicker.datetimepicker.relativeDate =
          booking.templateInformation.pickupAt;
        this.relativeDeliveryDatePicker.datetimepicker.minDate =
          Datetimepicker.ConvertToAbsoluteValue(booking.templateInformation.pickupAt);
      } else {
        this.relativePickupDatePicker.datetimepicker.date = booking.pickupAt;
        if (!booking.pickupTimeSpecified) {
          this.relativePickupDatePicker.datetimepicker.clearTime();
        }
        booking.templateInformation.pickupAt = this.relativePickupDatePicker.datetimepicker.relativeDate;
        this.relativeDeliveryDatePicker.datetimepicker.minDate = booking.pickupAt;
      }
      if (booking.templateInformation.deliverAt) {
        this.relativeDeliveryDatePicker.datetimepicker.relativeDate =
          booking.templateInformation.deliverAt;
      } else {
        this.relativeDeliveryDatePicker.datetimepicker.date = booking.deliverAt;
        if (!booking.deliveryTimeSpecified) {
          this.relativeDeliveryDatePicker.datetimepicker.clearTime();
        }
        booking.templateInformation.deliverAt = this.relativeDeliveryDatePicker.datetimepicker.relativeDate;
      }
      sleep(10).then(() => this.relativeDeliveryDatePicker.datetimepicker.resetValidation());
    }
  }

  bookingSiteSelectionHandler(e: { addedItems: ISelectListItem[] }) {
    if (!e.addedItems[0]) {
      return;
    }
    let newBookingParty = e.addedItems[0].ajaxData;
    if (this.model.booking.direction === "in") {
      if (this.model.booking.consigneeAddress && this.model.booking.consigneeAddress.sitePK !==
          newBookingParty.value) {
        this.consigneeAddressSelect.unselectItems(true, true);
      }
    } else {
      if (this.model.booking.consignorAddress && this.model.booking.consignorAddress.sitePK !==
          newBookingParty.value) {
        this.shipperAddressSelect.unselectItems(true, true);
      }
    }
    this.model.bookingParty = newBookingParty;
    this.model.booking.siteName = newBookingParty.label;
    this.model.booking.sitePK = newBookingParty.value;
    if (this.model.booking.direction === "in") {
      this.consigneeAddressSelect.preloadAddresses();
    } else {
      this.shipperAddressSelect.preloadAddresses();
    }
  }

  portOfLoadingChanged(e: { addedItems: ISelectListItem[] }) {
    console.log("PoL changed");
    if (e.addedItems.length === 0) {
      console.log("PoL removed");
      console.trace();
      this.model.booking.portOfLoading = null;
      this.model.booking.templateInformation.portOfLoadingIcon = null;
    } else {
      console.log("New PoL:");
      console.log(e.addedItems[0].ajaxData);
      let addedItem = e.addedItems[0];
      this.model.booking.portOfLoading = {
        name: addedItem.ajaxData.label,
        unloco: addedItem.value,
        html: addedItem.ajaxData.html
      };
      this.model.booking.templateInformation.portOfLoadingIcon = addedItem.ajaxData.icon;
    }
  }

  portOfDischargeChanged(e: { addedItems: ISelectListItem[] }) {
    console.log("PoD changed");
    if (e.addedItems.length === 0) {
      console.log("PoD removed");
      this.model.booking.portOfDischarge = null;
      this.model.booking.templateInformation.portOfDischargeIcon = null;
    } else {
      console.log("New PoD:");
      console.log(e.addedItems[0].ajaxData);
      let addedItem = e.addedItems[0];
      this.model.booking.portOfDischarge = {
        name: addedItem.ajaxData.label,
        unloco: addedItem.value,
        html: addedItem.ajaxData.html
      };
      this.model.booking.templateInformation.portOfDischargeIcon = addedItem.ajaxData.icon;
    }
  }

  private loadBookingSites(entitySet: EntitySetInfo) {
    const userLoggedOut = !entitySet;
    if (userLoggedOut)
      return;
    const selectedBookingSiteId = this.model.booking.sitePK ?? entitySet.bookingSites[0]?.value;
    const bookingSites = entitySet.bookingSites.slice();
    bookingSites.forEach(s => s.selected = s.value === selectedBookingSiteId);
    this.bookingSiteSelect.clearItems();
    this.bookingSiteSelect.processJsonData(bookingSites);
    if (entitySet.bookingSites.length === 1)
      this.bookingSiteSelect.disable(false);
    else
      this.bookingSiteSelect.enable();
  }

  private _loadSelectedPorts(m: BookingModel) {
    let portOfLoading = this.model.booking.portOfLoading;
    if (portOfLoading)
      this.selectedPortOfLoading = [{
        value: portOfLoading.unloco,
        selected: true,
        label: portOfLoading.name,
        html: portOfLoading.html || portOfLoading.name,
        icon: this.model.booking.templateInformation.portOfLoadingIcon
      }] as Partial<JSONData>[];
    let portOfDischarge = this.model.booking.portOfDischarge;
    if (portOfDischarge)
      this.selectedPortOfDischarge = [{
        value: portOfDischarge.unloco,
        selected: true,
        label: portOfDischarge.name,
        html: portOfDischarge.html || portOfDischarge.name,
        icon: this.model.booking.templateInformation.portOfDischargeIcon
      }] as Partial<JSONData>[]
  }
}
