import {AfterViewChecked, ElementRef, ApplicationRef, AfterViewInit, Directive, OnDestroy} from "@angular/core"
import {Subject} from "rxjs";
import {take} from "rxjs/operators";

@Directive()
export class ContentComponent implements AfterViewInit, AfterViewChecked, OnDestroy{
    private _initialized = false;
    protected element: HTMLElement;
    private readonly _destroyedSubject = new Subject<void>()
    protected readonly destroyed$ = this._destroyedSubject.asObservable().pipe(take(1));

    //@HostBinding('@.disabled')
    //protected animationsDisabled = false;

// ReSharper disable once InconsistentNaming
    constructor(protected readonly __element: ElementRef, protected readonly app: ApplicationRef ) {
        this.element = __element.nativeElement;
    }

    log(param: any) {
        console.log(param);
    }

    ngAfterViewInit(): void {
    }

    ngAfterViewChecked(): void {
        if (this._initialized)
            return;
        this._initialized = true;
        this.onInitialized();
        // window.setTimeout(() => {
            //this.animationsDisabled = false;
            //this.app.tick();
        // });
    }

    onInitialized(): void {
        // if (this.__element.nativeElement)
        //     window.parseContent(this.__element.nativeElement);
    }

  ngOnDestroy(): void {
      this._destroyedSubject.next();
  }
}
