import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ConsolidationShipment, ShipmentListViewMode} from "@api";
import {Router} from "@angular/router";
import {ShipmentDetailViewData} from "@services";
import {AccountStore} from "@stores";

@Component({
             selector: 'rt-consolidation-link',
             templateUrl: './consolidation-link.component.html',
             styleUrls: ['./consolidation-link.component.scss'],
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class ConsolidationLinkComponent implements OnInit {
  urlLink: string = "";
  displayText: string = "";

  @Input("shipment")
  shipment: ConsolidationShipment;
  @Input("is-lead")
  isLead = false;

  private readonly _router: Router;
  private readonly _accountStore: AccountStore;

  constructor(router: Router, accountStore: AccountStore) {
    this._router = router;
    this._accountStore = accountStore;
  }

  ngOnInit() {
    if (this.shipment) {
      this.displayText = this.shipment.houseBill;
      if (this.isLead) {
        this.urlLink = !!this.shipment.houseBill ? `Shipment?q=${this.shipment.houseBill}&autoopensingleitem=false&search=true` : "";
      } else {
        this.urlLink = new ShipmentDetailViewData(this.shipment.id, this.shipment.type).url;
      }
    }
  }

  async onClickLink($event: MouseEvent) {
    $event.preventDefault();
    if (this.isLead) {
      this._accountStore.update("shipmentListViewMode", ShipmentListViewMode.Shipments);
    }
    await this._router.navigateByUrl(this.urlLink,);
  }
}
