import {Component} from '@angular/core';
import {JSONData, ShipmentListViewMode, ShipmentSearchReferenceType} from "@api";
import {StoreAccessor} from "@stores/store-accessor";
import {toJsonData} from "@utils/Utils";
import {ShipmentSearchUiStore} from "@app/page-components/shipment/shipment-list/shipment-search-ui-store.service";
import {AppBaseComponent} from "@app/appBaseComponent";
import {ShipmentService} from "@services";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {ContainerService} from "@services/container.service";
import {AccountStore} from "@stores";
import * as ShipmentSearchCodeMapper from "@app/page-components/shipment/shipment-list/shipment-search-bar/shipment-search-code-mapper";

@Component({
             selector: 'rt-shipment-reference-search-field',
             templateUrl: './shipment-reference-search-field.component.html',
             styleUrls: ['./shipment-reference-search-field.component.scss'],
             host: {
               class: "search-input"
             }
           })
export class ShipmentReferenceSearchFieldComponent extends AppBaseComponent {


  readonly selectedReferenceType = new StoreAccessor(this, "searchReferenceType");
  readonly selectedReference = new StoreAccessor(this, "reference");
  readonly store: ShipmentSearchUiStore;
  readonly showMobileSearchHint$: Observable<boolean>;
  readonly mobileSearchHint$: Observable<string>;
  readonly referenceTypeSearchItems$: Observable<JSONData[]>;
  private readonly _shipmentService: ShipmentService;
  private readonly _accountStore: AccountStore;
  private readonly _containerService: ContainerService;

  constructor(store: ShipmentSearchUiStore,
              shipmentService: ShipmentService,
              containerService: ContainerService,
              accountStore: AccountStore) {
    super()
    this.store = store;
    this._shipmentService = shipmentService;
    this._accountStore = accountStore;
    this._containerService = containerService;
    this.showMobileSearchHint$ = this.store.observe("showMobileSearchHint");
    this.mobileSearchHint$ = this.store
                                 .observe("searchReferenceType")
                                 .pipe(map(this.getMobileSearchHint));
    this.referenceTypeSearchItems$ =
      accountStore
        .observe("shipmentListViewMode")
        .pipe(map(listViewMode =>
                    listViewMode === ShipmentListViewMode.Shipments
                    ? toJsonData(ShipmentSearchCodeMapper.shipmentSearchReferenceTypes)
                    : toJsonData(ShipmentSearchCodeMapper.containerSearchReferenceTypes)))
  }


  getMobileSearchHint = (searchReference: ShipmentSearchReferenceType): string => {
    const referenceDisplayName = ShipmentSearchCodeMapper.shipmentSearchReferenceTypes[searchReference];
    if (referenceDisplayName && searchReference !== ShipmentSearchReferenceType.Any)
      return "Search by " + referenceDisplayName;
    if (this._accountStore.get("shipmentListViewMode") === ShipmentListViewMode.Shipments)
      return "Search Shipments"
    return "Search Containers"
  };

  toggleFiltersOpenState(): void {
    this.store.negate("filtersOpen")
  }

  startSearch(): void {
    const searchObject = this.store.get();
    delete searchObject.filtersOpen;
    if (this._accountStore.get("shipmentListViewMode") === ShipmentListViewMode.Shipments)
      this._shipmentService.startSearch(searchObject)
    else
      this._containerService.startSearch(searchObject)
  }

}
