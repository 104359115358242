import {ApplicationRef, Component, ElementRef, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {ViewComponent} from "./view.component";
import {DropzoneDirective} from "ngx-dropzone-wrapper";
import * as Settings from "../utils/settings";
import {toDictionary, unescapeHtml} from "@utils/Utils";
import {openAlert} from "@widgets/Alert";
import {ISelectOptions, Select} from "@widgets/Select";
import {DocumentTypes, FileUpload, IFile} from "@booking/BookingModel";
import {JSONData, JsonReference} from "@api";
import {BookingServiceListener} from "@services/booking-service-listener.service";
import {GetView, ViewArrangement} from "@booking/booking-views";

const dropzoneConsts = Settings.DROPZONE_CONSTS;


@Component({
               selector: "additonal-information",
               template: `
                 <h2 *ngIf="mode==='booking'">Documents</h2>
                 <div *ngIf="mode==='booking'" class="file-uploader form-group">
                   <div
                     [dropzone]
                     (addedFile)="fileAdded($event)"
                     (uploadProgress)="fileProgress($event)"
                     (success)="fileUploaded($event)"
                     (error)="fileFailed($event)"
                     (queueComplete)="queueCompleted()"
                     (sending)="fileUploadStart($event)"
                     class="drop-zone"
                     [disabled]="isOffline"
                     [attr.disabled]="isOffline? true : null">
                     Drag and drop files here or click to browse
                   </div>
                   <div
                     *ngFor="let file of model.files"
                     [attr.title]="file.error"
                     class="file"
                     [attr.data-size]="file.jsonDocument.fileSize"
                     [ngClass]="{'uploading':file.state !== 'uploaded', 'error': file.state ==='error'} ">
                     {{file.jsonDocument.fileName}}
                     <span class="file-icon"></span>
                     <select
                       data-no-validation-message
                       [attr.required]="mode === 'booking' ? 'required' : null"
                       *ngIf="file.state === 'uploaded'"
                       data-settings='{"placeholderNone":"Select Document Type", "allowNoSelection": true}'
                       [settings]="selectOptions"
                       ngSelect
                       [items] = "documentTypeItems"
                       class="small touch-margin"
                       data-display-name="Document Type"
                       [(ngModel)]="file.jsonDocument.documentType">

                     </select>
                     <div *ngIf="['queued', 'uploading'].contains(file.state)" class="progress"
                          [attr.data-value]="file.progress + '%'">
                       <div class="progress-bar" [style.width]="file.progress + '%'"></div>
                     </div>
                     <span class="remove hover-icon" (click)="removeFile(file);"></span>
                   </div>
                   <p class="error-message" *ngIf="hasFileTypeErrors">Please select a document type for all uploaded
                     files</p>
                   <p class="error-message" *ngIf="hasFileErrors">Please remove all unsupported files.</p>
                 </div>
                 <h2>Notes</h2>
                 <div class="form-group">
                   <label for="notes" class="optional">Special Instructions</label>
                   <textarea style="max-width:400px;"
                             [(ngModel)]="model.booking.specialInstructions"
                             placeholder="List any instructions like specific ports for routing, special equipment, warehouse opening times etc.">
                        </textarea>
                 </div>

                 <h2>Client References</h2>
                 <div class="form-group">
                   <label for="notes"
                          class="optional">{{model.booking.direction === 'in' ? 'Consignee' : 'Shipper'}}</label>
                   <div
                     class="form-group"
                     *ngFor="let reference of model.booking.references"
                     style="max-width: 400px; padding-right: 30px; position: relative;">
                     <input
                       type="text"
                       (blur)="lineBlur()"
                       (input)="lineInput()"
                       [(ngModel)]="reference.referenceNumber"
                       (focusin)="focusedReference = reference"
                       (focusout)="focusedReference = null"
                       maxlength="50"/>
                     <span
                       class="material-icons hover-icon"
                       *ngIf="model.booking.references.length > 1 && reference.referenceNumber"
                       (click)="model.booking.references.remove(reference)"
                       style="position: absolute; top:8px; right: 0;">delete</span>
                   </div>
                 </div>
                 <div class="buttons phone-auto-width">
                   <button class="button secondary" [disabled]="!canNavigateBack" (click)="navigateBack()">Back
                   </button>
                   <button class="button" type="button" [disabled]="!canNavigateNext" (click)="navigateNext()">
                     Next
                   </button>
                   <button class="button secondary disable-when-offline" (click)="cancel()" type="button">Cancel
                   </button>
                 </div>
               `
           })
export class AdditionalInformationComponent extends ViewComponent {
    name: string = "AdditionalInformationComponent";

    isOffline = false;
    toDictionary = toDictionary;
    fileTypeError =
        `This file type is not supported. You can upload the following file types: .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png, .tif, .tiff, .bmp and .msg`;
    documentTypeItems = toDictionary(DocumentTypes)
      .map(t => {
        return { value: t.key, label: t.value } as JSONData
      });
    documentTypes = DocumentTypes;
    hasFileTypeErrors = false;
    hasFileErrors = false;
    focusedReference: JsonReference;
    @ViewChild(DropzoneDirective)
    dropzone: DropzoneDirective;
    private readonly documentTypeSelects = new Array<Select>();
    readonly selectOptions: ISelectOptions = {
        closeOnSelect: true,
        placeholderNone: "<span class=\"text-light\">Select Document Type</span>",
        selectedOnTop: false,
        allowNoSelection: true,
        onInitialized: s => {
            this.documentTypeSelects.push(s);
            if (!this.showDgfType())
                s.getCurrentItems().firstOrDefault(i => i.value === "DGF").$element.hide();
        }
    };

    constructor(element: ElementRef, app: ApplicationRef, router: Router, bookingServiceListener: BookingServiceListener) {
        super(element, app, router, bookingServiceListener);
        while (this.model.booking.references.filter(r => !r.referenceNumber).length < 1) {
            this.model.booking.references.push({} as any);
        }
        window.addEventListener("offline", () => {
            this.isOffline = true;
            app.tick();
        });
        window.addEventListener("online", () => {
            this.isOffline = false;
            app.tick();
        });
  }
  ngOnInit(): void {
    this.model.booking.specialInstructions = unescapeHtml(this.model.booking.specialInstructions);
    this.model.booking.references.forEach(s => s.referenceNumber = unescapeHtml(s.referenceNumber));
  }

    private _isUploading = false;

    get isUploading() {
        return this._isUploading;
    }

    set isUploading(value) {
        this._isUploading = value;
        if (!value && ViewArrangement.activeView !== this) {
            openAlert({type: "info", content: "All files have been uploaded, please specify the file types."});
        }
    }

    async validate() {
        await super.validate();
        this.hasFileTypeErrors = false;
        this.hasFileErrors = false;
        for (const file of this.model.files) {
            if (file.state === "error") {
                this.hasFileErrors = true;
            } else if (!file.jsonDocument.documentType) {
                this.hasFileTypeErrors = true;
            }
        }
        if (this.hasFileTypeErrors || this.hasFileErrors) {
            this.isValid = false;
        }
        GetView(this).valid = this.isValid;
        return this.isValid;
    }


    async entered() {
        await super.entered();
        if (!this.showDgfType())
            this.documentTypeSelects.forEach(
                s => s.getCurrentItems().firstOrDefault(i => i.value === "DGF").$element?.hide());
        else
            this.documentTypeSelects.forEach(
                s => s.getCurrentItems().firstOrDefault(i => i.value === "DGF").$element?.show());
    }

    showDgfType() {
        return !!this.model.files.firstOrDefault(f => f.jsonDocument.documentType === "DGF") ||
               this.model.booking.shipmentType === "FCL" &&
               this.model.containers.firstOrDefault(c => c.dangerousGoodsSpecified) ||
               this.model.booking.shipmentType !== "FCL" &&
               this.model.packages.firstOrDefault(c => c.dangerousGoodsSpecified);
    }

    canLeave() {
        if (this.isUploading) {
            openAlert({type: "info", content: "Please wait until all file uploads have been completed."});
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }

    left(): void {
        if (this.isUploading) {
            openAlert({
                          type: "info",
                          content: "File upload will continue in background. You will have to specify the file types after the upload is completed."
                      });
        }
    }

    fileAdded(file: IFile) {
        if (file.status === "error") {
            return;
        }
        file.id = file.name + Math.random();
        const fileUpload: FileUpload = {
            jsonDocument: {
                fileName: file.name,
                fileSize: this.getFileSize(file.size),
                documentType: null,
                fileBase64: null,
                fileID: file.id.toString()
            },
            progress: 0,
            state: "queued",
            file: file
        };
        this.model.fileMap[file.id] = fileUpload;
        for (const existingFile of this.model.files) {
            if (existingFile.state === "error") {
                continue;
            }
            if (existingFile.jsonDocument.fileName.toLowerCase() === file.name.toLowerCase()) {
                (this.dropzone.dropzone() as Dropzone).removeFile(file);
                fileUpload.state = "error";
                fileUpload.error = "Another document with the same file name is already present.";
            }
        }
        this.model.files.push(fileUpload);
        this.model.booking.documents.push(fileUpload.jsonDocument);
    }

    queueCompleted() {
        this.isUploading = false;
    }

    fileProgress([file, progress]: [IFile, number]) {
        this.isUploading = true;
        this.model.fileMap[file.id].progress = progress;
    }

    fileUploaded([file]: [IFile]) {
        try {
            var uploadResponse = JSON.parse(file.xhr.response);

            if (uploadResponse) {
                if (uploadResponse.success === true) {
                    this.model.fileMap[file.id].state = "uploaded";
                } else {
                    this.model.fileMap[file.id].state = "error";
                    this.model.fileMap[file.id].error = uploadResponse.response;
                }
            }
        } catch (e) {
            this.model.fileMap[file.id].state = "uploaded";
        }

    }

    fileUploadStart([file, xhr, formData]: [IFile, XMLHttpRequest, FormData]) {
        formData.append("FileID", file.id);
    }

    removeFile(file: FileUpload) {
        let dropzone = (this.dropzone.dropzone() as Dropzone);
        if (dropzone.files.contains(file.file)) {
            dropzone.removeFile(file.file);
        } else {
            this.fileRemoved(file.file);
        }
    }

    async fileRemoved(file: IFile) {
        const fileUpload = this.model.fileMap[file.id];
        this.model.files.remove(fileUpload);
        this.model.booking.documents.remove(fileUpload.jsonDocument);
        if (fileUpload.state === "error" && !this.model.files.firstOrDefault(f => f.state === "error")) {
            this.hasFileErrors = false;
        } else if (!fileUpload.jsonDocument.documentType &&
                   !this.model.files.firstOrDefault(f => f.state !== "error" && !f.jsonDocument.documentType)) {
            this.hasFileTypeErrors = false;
        }
        await $.ajax({
                         method: "DELETE",
                         url: window.baseApplicationUrl + "/WebMethod/RemoveFileUploadBooking",
                         data: {id: file.id}
                     });
    }

    fileFailed([file, errorMessage, request]: [IFile, string, XMLHttpRequest | null]) {
        const fileUpload = this.model.fileMap[file.id];
        fileUpload.state = "error";
        if (errorMessage === dropzoneConsts.INVALID_FILE_TYPE) {
            fileUpload.error =
                `This file type is not supported. You can upload the following file types: .bmp, .doc, .docx, .jpeg, .jpg, .msg, .pdf, .png, .tif, .tiff, .xls and .xlsx`;
        } else if (errorMessage === dropzoneConsts.RESPONSE_ERROR) {
            fileUpload.error =
                `File could not be uploaded to the server. Please try again or contact your Röhlig representative if this error perists.`;
        } else if (errorMessage === dropzoneConsts.FILE_TOO_BIG) {
            fileUpload.error = "The maximum supported file size is 5 MB.";
        } else {
            fileUpload.error = errorMessage;
        }
    }

    lineInput() {
        let hasEmptyFields = false;
        for (const ref of this.model.booking.references) {
            if (String.isNullOrEmpty(ref.referenceNumber)) {
                hasEmptyFields = true;
                break;
            }
        }
        if (!hasEmptyFields) {
            this.model.booking.references.push({} as any);
        }
    }

    lineBlur() {
        if (this.model.booking.references.length <= 1) {
            return;
        }
        const focusedReference = this.focusedReference;
        this.focusedReference = null;
        let emptyRefCount = 0;
        let trailingEmptyFields = 0;
        for (const ref of this.model.booking.references) {
            if (String.isNullOrEmpty(ref.referenceNumber)) {
                emptyRefCount++;
                if (ref !== focusedReference) {
                    trailingEmptyFields++;
                } else {
                    trailingEmptyFields = 0;
                }
            } else {
                trailingEmptyFields = 0;
            }
        }
        if (emptyRefCount === trailingEmptyFields) {
            trailingEmptyFields--;
        }
        for (let i = 0; i < trailingEmptyFields; i++) this.model.booking.references.pop();

    }

    getFileSize(size: number): string {
        size = Math.round(size / 1024, 1);
        if (size >= 1024) return Math.round(size / 1024, 1) + " MB";
        return Math.round(size) + " KB";
    }

}
