(function () {
if (window.jQueryLoaded)
    return;

    const $ = window.$ || require("jquery");
  const isNodeJsEnvironment = (typeof global) !== "undefined";
  if (isNodeJsEnvironment) {
    global.$ = $;
    global.jQuery = $;
  }
    Object.defineProperty(window,
                          "$",
                          {
                              get: function () {
                                  return $
                              },
                              set: function (value) {
                                  if (value !== window.$) {
                                      console.warn("JQuery should only be set once");
                                      //console.trace();
                                  }
                              }
                          });
    Object.defineProperty(window,
                          "jQuery",
                          {
                              get: function () {
                                  return $
                              },
                              set: function (value) {
                                  if (value !== window.$) {
                                      console.warn("JQuery should only be set once");
                                      //console.trace();
                                  }
                              }
                          });
    window.jQueryLoaded = true;
})();
