import {ContainerCardLayoutItem, DangerousGoodsData, DeliveryStatus, DimensionValue, PortLocation, ShipmentEvent, TransportMode} from "@api";
import {FieldRenderer} from "./field-renderer.service";
import {DateContainer, ContainerOverviewItem} from "./container-overview-item";

export type OverviewFieldTypes =
  string
  | string[]
  | PortLocation[]
  | DimensionValue[]
  | DateContainer
  | PortLocation
  | DimensionValue
  | number
  | Grouping<DangerousGoodsData, string>[]
  | ShipmentEvent
  | TransportMode
  | DeliveryStatus
  | null;

export interface ContainerCardLayoutMapper<T extends OverviewFieldTypes = OverviewFieldTypes> {
  class?: "wide";
  title: Func1<ContainerOverviewItem, string>;
  hidden?: Func1<ContainerOverviewItem, boolean>,
  getContent: Func2<ContainerOverviewItem, FieldRenderer, string>,
  isDefault?: true
}

export const ContainerBoxFields: { readonly [P in ContainerCardLayoutItem]: ContainerCardLayoutMapper } = {
  [ContainerCardLayoutItem.ArrivalDate]: {
    title: () => "Arrival Date",
    getContent: (s, r) => r.renderDateValue(s.arrivalDate)
  },
  [ContainerCardLayoutItem.BillOfLading]: {
    title: () => "Bill of Lading",
    getContent: (s, r) => r.renderStringArray(s.houseBills),
    isDefault: true
  },
  [ContainerCardLayoutItem.Chargeable]: {
    title: () => "Chargeable",
    getContent: (s, r) => r.renderDimensionValue(s.chargeable)
  },
  [ContainerCardLayoutItem.ClientReferences]: {
    class: "wide",
    title: () => "Client References",
    getContent: (s, r) => r.renderStringArray(s.clientReferences)
  },
  [ContainerCardLayoutItem.Consignee]: {
    title: () => "Consignee",
    getContent: (s, r) => r.renderStringArray(s.consigneeName),
    isDefault: true
  },
  [ContainerCardLayoutItem.ContainerType]: {
    title: () => "Container Type",
    getContent: (s, r) => r.renderString(s.type),
    isDefault: true
  },
  [ContainerCardLayoutItem.CustomsEntryNumber]: {
    title: () => "Customs Entry Number",
    getContent: (s, r) => r.renderStringArray(s.customsEntryNumbers)
  },
  [ContainerCardLayoutItem.DeliveryDate]: {
    title: () => "Delivery Date",
    getContent: (s, r) => r.renderDateValue(s.deliveryDate)
  },
  [ContainerCardLayoutItem.DeliveryLocation]: {
    title: () => "Delivery Location",
    getContent: (s, r) => r.renderAddressLocationName(s.deliveryLocations)
  },
  [ContainerCardLayoutItem.DepartureDate]: {
    title: () => "Departure Date",
    getContent: (s, r) => r.renderDateValue(s.departureDate)
  },
  [ContainerCardLayoutItem.Destination]: {
    title: () => "Destination",
    getContent: (s, r) => r.renderCityLocationValue(s.destinations),
    isDefault: true
  },
  [ContainerCardLayoutItem.GoodsDescription]: {
    class: "wide", title: () => "Goods Description",
    getContent: (s, r) => r.renderStringArray(s.goodsDescriptions)
  },
  [ContainerCardLayoutItem.Incoterm]: {
    title: () => "Incoterm",
    getContent: (s, r) => r.renderStringArray(s.incoTerms)
  },
  [ContainerCardLayoutItem.InvoiceNumber]: {
    title: () => "Invoice Number",
    getContent: (s, r) => r.renderStringArray(s.invoiceNumbers)
  },
  [ContainerCardLayoutItem.Pieces]: {
    title: () => "Pieces",
    getContent: (s, r) => r.renderInteger(s.pieces),
    isDefault: true
  },
  [ContainerCardLayoutItem.PickupLocation]: {
    title: () => "Pickup Location",
    getContent: (s, r) => r.renderAddressLocationName(s.pickupLocations)
  },
  [ContainerCardLayoutItem.PickupDate]: {
    title: () => "Pickup Date",
    getContent: (s, r) => r.renderDateValue(s.pickupDate)
  },
  [ContainerCardLayoutItem.OrderNumber]: {
    class: "wide", title: () => "Order Number",
    getContent: (s, r) => r.renderStringArray(s.orderNumbers)
  },
  [ContainerCardLayoutItem.Origin]: {
    title: () => "Origin",
    getContent: (s, r) => r.renderCityLocationValue(s.origins),
    isDefault: true
  },
  [ContainerCardLayoutItem.PortOfLoading]: {
    title: () => "Port of Loading",
    getContent: (s, r) => r.renderPortLocationValue(s.portsOfLoading, s.portTypes),
    hidden: s => s.hidePorts
  },
  [ContainerCardLayoutItem.PortOfDischarge]: {
    title: () => "Port of Discharge",
    getContent: (s, r) => r.renderPortLocationValue(s.portsOfDischarge, s.portTypes),
    hidden: s => s.hidePorts
  },
  [ContainerCardLayoutItem.RoehligReferences]: {
    title: () => "Röhlig References",
    getContent: (s, r) => r.renderStringArray(s.rohligReferences)
  },
  [ContainerCardLayoutItem.Seal]: {
    title: () => "Seal",
    getContent: (s, r) => r.renderString(s.sealNumber),
    isDefault: true
  },
  [ContainerCardLayoutItem.Shipper]: {
    title: () => "Shipper",
    getContent: (s, r) => r.renderStringArray(s.shipperNames),
    isDefault: true
  },
  [ContainerCardLayoutItem.VesselFlightNumber]: {
    title: s => {
      let completeTMode: string[] = [];
      for (let transportMode of s?.transportModes) {
        switch (transportMode) {
          case TransportMode.Air:
            completeTMode.push("Flight Number");
            break;
          case TransportMode.Sea:
            completeTMode.push("Vessel");
            break;
          default:
            completeTMode.push("");
            break;
        }
      }
      return completeTMode.join(" / ");
    },
    getContent: (s, r) => r.renderStringArray(s.vesselFlightNumbers)
  },
  [ContainerCardLayoutItem.Volume]: {
    title: () => "Volume",
    getContent: (s, r) => r.renderDimensionValue(s.volume)
  },
  [ContainerCardLayoutItem.Weight]: {
    title: () => "Weight",
    getContent: (s, r) => r.renderDimensionValue(s.weight),
    isDefault: true
  },
}
