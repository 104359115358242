import {OverviewFieldTypes} from "@app/page-components/shipment/shipment-list/container-box-fields";
import {CityLocation, DeliveryStatus, DimensionValue, ContainerListLayoutItem, PortLocation, ShipmentEvent, TransportMode, AddressLocation} from "@api";
import {FieldRenderer} from "./field-renderer.service";
import {deliveryStatusClassMapper, deliveryStatusDisplayMapper} from "@app/utils/enum-display-name-mapper";
import {DateContainer, ContainerOverviewItem} from "./container-overview-item";

export interface ContainerListLayoutMapper<T extends OverviewFieldTypes = OverviewFieldTypes> {
  columnTitle: string;
  getValue: Func1<ContainerOverviewItem, T>;
  getTitle?: Func3<ContainerOverviewItem, FieldRenderer, T, string>
  getContent: Func3<ContainerOverviewItem, FieldRenderer, T, string>
  getClass?: Func1<T, string>
  size?: "small" | "extra-small" | "large",
  isDefault?: true
}

export const ContainerListFields: { readonly [P in ContainerListLayoutItem]: ContainerListLayoutMapper } = {
  [ContainerListLayoutItem.ArrivalDate]: {
    columnTitle: "Arrival Date",
    getValue: s => s.arrivalDate,
    getContent: (s, r, v: DateContainer) => r.renderDateValue(v),
    getTitle: (s, r, v: DateContainer) => r.renderDateTitle(v),
  },
  [ContainerListLayoutItem.BillOfLading]: {
    columnTitle: "Bill of Lading",
    getValue: s => s.houseBills,
    getContent: (s, r, v: string[]) => r.renderStringArray(v),
    size: "small",
    isDefault: true
  },
  [ContainerListLayoutItem.Chargeable]: {
    columnTitle: "Chargeable",
    getValue: s => s.chargeable,
    getContent: (s, r, v: DimensionValue[]) => r.renderDimensionValue(v),
    size: "extra-small"
  },
  [ContainerListLayoutItem.ClientReferences]: {
    columnTitle: "Client References",
    getValue: s => s.clientReferences,
    getContent: (s, r, v: string[]) => r.renderStringArray(v),
    size: "large"
  },
  [ContainerListLayoutItem.Consignee]: {
    columnTitle: "Consignee",
    getValue: s => s.consigneeName,
    getContent: (s, r, v: string[]) => r.renderStringArray(v),
    isDefault: true
  },
  [ContainerListLayoutItem.ContainerCount]: {
    columnTitle: "Container Count",
    getValue: s => s.containerCount,
    getContent: (s, r, v: number) => r.renderInteger(v)
  },
  [ContainerListLayoutItem.ContainerMode]: {
    columnTitle: "Container Mode",
    getValue: s => s.containerModes,
    getContent: (s, r, v: string) => r.renderStringArray(s.containerModes),
    size: "small",
    isDefault: true
  },
  [ContainerListLayoutItem.ContainerNumber]: {
    columnTitle: "Container Number / Bill of Lading",
    getValue: s => s.containerNumber,
    getContent: (s, r, v: string) => r.renderString(v),
    isDefault: true
  },
  [ContainerListLayoutItem.ContainerType]: {
    columnTitle: "Container Type",
    getValue: s => s.type,
    getContent: (s, r, v: string) => r.renderString(v),
    size: "small",
    isDefault: true
  },
  [ContainerListLayoutItem.CustomsEntryNumber]: {
    columnTitle: "Customs Entry Number",
    getValue: s => s.customsEntryNumbers,
    getContent: (s, r, v: string[]) => r.renderStringArray(s.customsEntryNumbers)
  },
  [ContainerListLayoutItem.DangerousGoods]: {
    columnTitle: "Dangerous Goods",
    getValue: s => s.dangerousGoods,
    getContent: null,
    getTitle: (s, r) => r.renderStringArray(s.dangerousGoods.map(d => "UN " + d.key))
  },
  [ContainerListLayoutItem.DeliveryDate]: {
    columnTitle: "Delivery Date",
    getValue: s => s.deliveryDate,
    getContent: (s, r, v: DateContainer) => r.renderDateValue(v),
    getTitle: (s, r, v: DateContainer) => r.renderDateTitle(v)
  },
  [ContainerListLayoutItem.DeliveryLocation]: {
    columnTitle: "Delivery Location",
    getValue: s => s.deliveryLocations,
    getContent: (s, r, v: AddressLocation[]) => r.renderAddressLocationName(v),
  },
  [ContainerListLayoutItem.DepartureDate]: {
    columnTitle: "Departure Date",
    getValue: s => s.departureDate,
    getContent: (s, r, v: DateContainer) => r.renderDateValue(v),
    getTitle: (s, r, v: DateContainer) => r.renderDateTitle(v)
  },
  [ContainerListLayoutItem.Destination]: {
    columnTitle: "Destination",
    getValue: s => s.destinations,
    getContent: (s, r, v: CityLocation[]) => r.renderCityLocationValue(v),
    getTitle: (s, r, v: CityLocation[]) => r.renderCityLocationTitle(v),
    isDefault: true
  },
  [ContainerListLayoutItem.GoodsDescription]: {
    columnTitle: "Goods Description",
    getValue: s => s.goodsDescriptions,
    getContent: (s, r, v: string[]) => r.renderStringArray(v),
    size: "large"
  },
  [ContainerListLayoutItem.Incoterm]: {
    columnTitle: "Incoterm",
    getValue: s => s.incoTerms,
    getContent: (s, r, v: string[]) => r.renderStringArray(v),
    size: "extra-small"
  },
  [ContainerListLayoutItem.InvoiceNumber]: {
    columnTitle: "Invoice Number",
    getValue: s => s.invoiceNumbers,
    getContent: (s, r, v: string[]) => r.renderStringArray(v)
  },
  [ContainerListLayoutItem.LastEvent]: {
    columnTitle: "Last Event",
    getValue: s => s.lastEvent,
    getContent: (s, r, v: ShipmentEvent) => r.renderEventValue(v),
    getTitle: (s, r, v: ShipmentEvent) => r.renderEventTitle(v),
    size: "large",
    isDefault: true
  },
  [ContainerListLayoutItem.OrderNumber]: {
    columnTitle: "Order Number",
    getValue: s => s.orderNumbers,
    getContent: (s, r, v: string[]) => r.renderStringArray(v)
  },
  [ContainerListLayoutItem.Origin]: {
    columnTitle: "Origin",
    getValue: s => s.origins,
    getContent: (s, r, v: CityLocation[]) => r.renderCityLocationValue(v),
    getTitle: (s, r, v: CityLocation[]) => r.renderCityLocationTitle(v),
    isDefault: true
  },
  [ContainerListLayoutItem.PickupDate]: {
    columnTitle: "Pickup Date",
    getValue: s => s.pickupDate,
    getContent: (s, r, v: DateContainer) => r.renderDateValue(v),
    getTitle: (s, r, v: DateContainer) => r.renderDateTitle(v)
  },
  [ContainerListLayoutItem.PickupLocation]: {
    columnTitle: "Pickup Location",
    getValue: s => s.pickupLocations,
    getContent: (s, r, v: AddressLocation[]) => r.renderAddressLocationName(v),
  },
  [ContainerListLayoutItem.Pieces]: {
    columnTitle: "Pieces",
    getValue: s => s.pieces,
    getContent: (s, r, v: number) => r.renderInteger(v),
    size: "extra-small"
  },
  [ContainerListLayoutItem.PortOfLoading]: {
    columnTitle: "Port of Loading",
    getValue: s => s.hidePorts ? "" : s.portsOfLoading,
    getContent: (s, r, v: PortLocation[]) => r.renderPortLocationValue(v, s.portTypes),
    getTitle: (s, r, v: PortLocation[]) => r.renderPortLocationTitle(v, s.portTypes)
  },
  [ContainerListLayoutItem.PortOfDischarge]: {
    columnTitle: "Port of Discharge",
    getValue: s => s.hidePorts ? "" : s.portsOfDischarge,
    getContent: (s, r, v: PortLocation[]) => r.renderPortLocationValue(v, s.portTypes),
    getTitle: (s, r, v: PortLocation[]) => r.renderPortLocationTitle(v, s.portTypes)
  },
  [ContainerListLayoutItem.RoehligReferences]: {
    columnTitle: "Röhlig References",
    getValue: s => s.rohligReferences,
    getContent: (s, r, v: string[]) => r.renderStringArray(v)
  },
  [ContainerListLayoutItem.Shipper]: {
    columnTitle: "Shipper",
    getValue: s => s.shipperNames,
    getContent: (s, r, v: string[]) => r.renderStringArray(v),
    isDefault: true
  },
  [ContainerListLayoutItem.Seal]: {
    columnTitle: "Seal",
    getValue: s => s.sealNumber,
    getContent: (s, r, v: string) => r.renderString(v),
    size: "small"
  },
  [ContainerListLayoutItem.Status]: {
    columnTitle: "Status",
    getValue: s => s.status,
    getContent: (s, r, v: DeliveryStatus) => r.renderDeliveryStatusValue(v),
    getTitle: (s, r, v: DeliveryStatus) => r.renderString(deliveryStatusDisplayMapper[v]),
    getClass: (v: DeliveryStatus) => deliveryStatusClassMapper[v],
    size: "extra-small",
    isDefault: true
  },
  [ContainerListLayoutItem.TransportMode]: {
    columnTitle: "Transport Mode",
    getValue: s => s.transportModes,
    getContent: (s, r, v: TransportMode[]) => r.renderString(v.join(" / ")),
    isDefault: true
  },
  [ContainerListLayoutItem.VesselFlightNumber]: {
    columnTitle: "Vessel / Flight Number",
    getValue: s => s.vesselFlightNumbers,
    getContent: (s, r, v: string[]) => r.renderStringArray(v)
  },
  [ContainerListLayoutItem.Volume]: {
    columnTitle: "Volume",
    getValue: s => s.volume,
    getContent: (s, r, v: DimensionValue[]) => r.renderDimensionValue(v),
    size: "extra-small"
  },
  [ContainerListLayoutItem.Weight]: {
    columnTitle: "Weight",
    getValue: s => s.weight,
    getContent: (s, r, v: DimensionValue[]) => r.renderDimensionValue(v),
    size: "extra-small"
  }
}
