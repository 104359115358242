import { createDeepCopy } from '@utils/createDeepCopy';
import {Injectable} from '@angular/core';
import {JsonBooking, JsonContainer, JsonPackage, WebMethodClient} from "@api";
import {interval} from "rxjs";
import {BookingViewData, TemplateViewData, ViewData} from "@view-data";
import {filter, first, map} from "rxjs/operators";
import {hasValue} from "@utils/rxjs-extensions";
import {BookingModel} from "@booking/BookingModel";
import {SessionStorageService} from "@services/local-storage.service";
import {PrepareJsonDataFromServer, ResolveJsonRefs} from "@utils/Utils";
import {Datetimepicker} from "@widgets/Datetimepicker";
import {BookingMode, BookingServiceListener} from "@services/booking-service-listener.service";
import {RoutingService} from "@app/services/routing.service";
import {AccountStore, UserRightsStore} from "@stores";
import {UserRightsStoreState} from "@stores/userRightsStoreState";


@Injectable({
              providedIn: 'root'

            })
export class BookingService extends BookingServiceListener {
  private readonly _webMethodClient: WebMethodClient;
  private readonly _sessionStorageService: SessionStorageService;
  private _enableBookingAutoSave = false;
  private _templateId: string | null = null;
  private readonly _routingService: RoutingService;

  private readonly _accountStore: AccountStore;

  constructor(webMethodClient: WebMethodClient,
              userRightsStore: UserRightsStore,
              routingService: RoutingService,
              localStorageService: SessionStorageService,
              accountStore: AccountStore) {
    super();
    this._accountStore = accountStore;
    this._routingService = routingService;
    this._webMethodClient = webMethodClient;
    this._sessionStorageService = localStorageService;
    routingService
      .routingStore
      .observe("currentViewData")
      .pipe(filter(hasValue))
      .subscribe(d => {
        void this._viewDataReceived(d)
      });
    userRightsStore
      .observe(["isAuthenticated", "bookings"])
      .subscribe((r: Pick<UserRightsStoreState, "isAuthenticated" | "bookings">) => this._entitySetChanged(r))
    interval(200)
      .subscribe(() => {
        if (this._enableBookingAutoSave)
          this.saveBooking();
      });
    this.mode$
        .pipe(filter(() => !!this.currentBookingModel))
        .subscribe(m => {
          this.currentBookingModel.mode = m
        });
    this._removeStoredBookingDataIfCurrentSiteIsNotBooking();
  }

  saveBooking(): void {
    this._sessionStorageService.saveBookingData(this.currentBookingModel);
  }

  setBookingModel(bookingModel: BookingModel): void {
    BookingServiceListener._bookingModelSubject.next(bookingModel);
    this._enableLocalBookingDataAutoStore();
  }

  cancelBooking(): void {
    this._disableLocalBookingDataAutoStore();
    BookingServiceListener._templateLoadedSubject.next(null);
    BookingServiceListener._bookingModelSubject.next(null);
    BookingServiceListener._bookingCanceledSubject.next();
    this._templateId = null;
  }

  initializeBooking(): BookingModel {
    if (this.currentBookingModel)
      this.cancelBooking();
    return this._getOrCreateCurrentModel();
  }

  hasActiveBooking(): boolean {
    const booking = this.currentBookingModel || this._sessionStorageService.loadBookingData();
    if (!booking)
      return false;
    return !booking.enableNavigatingAway
  }

  setBookingMode(mode: BookingMode): void {
    BookingServiceListener._modeSubject.next(mode);
  }

  private _removeStoredBookingDataIfCurrentSiteIsNotBooking() {
    this
      ._routingService
      .routingStore
      .observe("currentViewData")
      .pipe(filter(hasValue), first())
      .subscribe(currentViewData => {
        if (!(currentViewData instanceof TemplateViewData) && !(currentViewData instanceof BookingViewData))
          this._sessionStorageService.removeBookingData();
      });

  }

  private _disableLocalBookingDataAutoStore() {
    this._sessionStorageService.removeBookingData();
    this._enableBookingAutoSave = false;
  }

  private _enableLocalBookingDataAutoStore() {
    this._enableBookingAutoSave = true;
  }

  private _entitySetChanged({isAuthenticated, bookings}: { isAuthenticated: boolean, bookings: boolean }) {
    if (!isAuthenticated) {
      BookingServiceListener._bookingBaseDataSubject.next(null);
      this.cancelBooking();
      return;
    }
    this._webMethodClient
        .getBookingBaseData()
        .pipe(first())
        .subscribe(data => BookingServiceListener._bookingBaseDataSubject.next(data));
    if (bookings && this.hasActiveBooking())
      this.initializeBooking();

  }

  private async _viewDataReceived(data: ViewData) {
    if (!(data instanceof TemplateViewData || data instanceof BookingViewData)) {
      this._disableLocalBookingDataAutoStore();
      this.cancelBooking();
      return;
    }
    BookingServiceListener._modeSubject.next(data instanceof BookingViewData ? "booking" : "template");
    const newTemplateId = data.templateId;
    if (newTemplateId && newTemplateId !== this._templateId) {
      this._templateId = newTemplateId;
      const templateData = this._getTemplateData();
      await this._loadTemplateData(templateData)
      BookingServiceListener._templateLoadedSubject.next(createDeepCopy(this.currentBookingModel));
    } else if (!this.currentBookingModel)
      this.initializeBooking();
  }

  private _getTemplateData() {
    return this._accountStore
               .get("bookingTemplates")
               .firstOrDefault(t => t.value === this._templateId)
      .data;
  }

  private async _loadTemplateData(templateData: JsonBooking) {
    if (!templateData) {
      return;
    }
    const model = this._getOrCreateCurrentModel();
    model.booking = ResolveJsonRefs(PrepareJsonDataFromServer(templateData));
    const booking = model.booking;
    booking.documents = booking.documents || [];
    booking.references = booking.references || [];
    if (booking.containers) {
      model.containers.unshift(...booking.containers);
      model.containers.map(c => {
        c.dangerousGoodsItems = [];
        if (!c.dangerousGoods) {
          c.dangerousGoods = [];
        }
        if (typeof c.dangerousGoodsSpecified !== "boolean") {
          c.dangerousGoodsSpecified = c.dangerousGoods.length > 0;
        }
        if (typeof c.temperatureSpecified !== "boolean") {
          c.temperatureSpecified = typeof c.temperature === "number";
        }
      })
    }
    if (booking.packages) {
      model.packages.unshift(...booking.packages);
      model.packages.map(p => {
        p.dangerousGoodsItems = [];
        if (!p.dangerousGoods) {
          p.dangerousGoods = [];
        }
        if (typeof p.dangerousGoodsSpecified !== "boolean") {
          p.dangerousGoodsSpecified = p.dangerousGoods.length > 0;
        }
        if (p.dimension === "m") {
          p.dimension = "cm";
          p.length *= 100;
          p.width *= 100;
          p.height *= 100;
        }
      })
    }
    delete booking.packages;
    delete booking.containers;
    const isBooking = this.currentBookingMode === "booking";
    if (isBooking) {
      if (booking.templateInformation.pickupAt) {
        booking.pickupAt =
          Datetimepicker.ConvertToAbsoluteValue(booking.templateInformation.pickupAt);
        booking.pickupTimeSpecified = !!booking.templateInformation.pickupAt.time;
        booking.pickupAt.isTimeSet = booking.pickupTimeSpecified;

      }
      if (booking.templateInformation.deliverAt) {
        booking.deliverAt =
          Datetimepicker.ConvertToAbsoluteValue(booking.templateInformation.deliverAt);
        booking.deliveryTimeSpecified = !!booking.templateInformation.deliverAt.time;
        booking.deliverAt.isTimeSet = booking.deliveryTimeSpecified;
      }
    }
    const packages = model.packages
                          .filter(p => p !== model.dummyPackage);
    model.totalPackCount = packages.sum((p: JsonPackage) => p.packCount);
    model.totalPackVolume = packages.sum((p: JsonPackage) => p.volume);
    model.totalPackWeight = packages.sum((p: JsonPackage) => p.weight);
    const containers = model.containers
                            .filter(c => c !== model.dummyContainer);
    model.totalContainerVolume = containers.sum((c: JsonContainer) => c.volume);
    model.totalContainerWeight = containers.sum((c: JsonContainer) => c.weight);
    await model.checkPorts();
  }

  private _getOrCreateCurrentModel() {
    let model = this.currentBookingModel;
    if (!model) {
      const restoredModel = this._sessionStorageService.loadBookingData();
      model = restoredModel ?? new BookingModel(this.currentBookingMode);
      BookingServiceListener._modeSubject.next(model.mode);
    }
    BookingServiceListener._bookingModelSubject.next(model);
    this._enableLocalBookingDataAutoStore();
    return model;
  }
}
