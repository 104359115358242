import {ApplicationRef, Component, ElementRef} from "@angular/core";
import {ContentComponent} from "@utils/Angular/ContentComponent";
import {Router} from "@angular/router";
import {LastUsedDangerousGoodsCache} from "@booking/LastUsedDangerousGoodsCache";
import {DangerousGoodsService} from "@booking/DangerousGoodsService";
import {ShipmentListViewData} from "@services";
import {BookingService} from "@services/booking.service";
import {IViewDef, ViewArrangement} from "@booking/booking-views";
import {filter, takeUntil} from "rxjs/operators";
import {hasValue} from "@utils/rxjs-extensions";

@Component({
             selector: "rt-booking",
             template: `
               <div class="page-wrap">
                 <main>
                   <form novalidate>
                     <div class="search-bar button-container">
                       <div class="button-group static">
                         <a class="button secondary back-button phone-borderless" routerLink="/Shipment"><span class="mat-icon-back"></span></a>
                       </div>
                       <span class="title desktop-visible" *ngIf="isBooking">New Booking</span>
                       <span class="title desktop-visible" *ngIf="isTemplate && isNewTemplate">Add Booking Template</span>
                       <span class="title desktop-visible" *ngIf="isTemplate && !isNewTemplate">Edit Booking Template</span>
                     </div>
                     <section class="padding" [class.all-fields-optional]="isTemplate"
                              [class.template]="isTemplate" style="padding-bottom: 170px">
                       <nav class="navigation-timeline" style="margin-bottom: 40px;">
    <span *ngFor="let view of views"
          [routerLink]="view.isActivated ? '/' + view.address : null"
          routerLinkActive="active"
          [attr.disabled]="view.isActivated ? null : 'disabled'"
          [class.valid]="view.valid"
          class="removeDisabledStyle"
          [attr.tabindex]="view.isActivated ? 0 : -1">
        {{view.title}}
    </span>
                       </nav>
                       <router-outlet></router-outlet>
                     </section>
                   </form>
                 </main>
               </div>
             `,
           })
export class BookingComponent extends ContentComponent {

  isTemplate = false;
  views: IViewDef[] = [];
  shipmentListUrl = new ShipmentListViewData().url;
  isBooking = true;
  isNewTemplate = false;
  private readonly _bookingService: BookingService;

  constructor(element: ElementRef,
              app: ApplicationRef,
              router: Router,
              dgCache: LastUsedDangerousGoodsCache,
              dgService: DangerousGoodsService,
              bookingService: BookingService) {
    super(element, app);
    // this.model = bookingService.initializeBooking();
    this._bookingService = bookingService;
    bookingService.bookingModel$
                  .pipe(
                    takeUntil(this.destroyed$),
                    filter(hasValue))
                  .subscribe(model => {
                    if (model.lastViewIndex >= 0) {
                      router.navigateByUrl(ViewArrangement[model.lastViewIndex].address, {replaceUrl: true});
                    } else {
                      router.navigateByUrl(ViewArrangement.firstOrDefault(v => v.visible).address,
                                           {replaceUrl: true});
                    }
                  });
    bookingService.templateLoaded$
                  .pipe(
                    filter(hasValue),
                    takeUntil(this.destroyed$)
                  )
                  .subscribe(t => {
                    this.isNewTemplate = !t.booking.templateInformation.templateID;
                  });

    bookingService.mode$
                  .pipe(takeUntil(this.destroyed$))
                  .subscribe(mode => {
                    this.isTemplate = mode === "template";
                    this.isNewTemplate = !(bookingService.currentBookingModel?.booking.templateInformation.templateID);
                    this.isBooking = !this.isTemplate;
                    const startIndex = mode === "template" ? 0 : 1;
                    this.views = ViewArrangement.slice(startIndex);
                  });
  }

}
