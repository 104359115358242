import {ExtendableEvent} from "../libs/ExtendableEvent";
import {GetAsArray} from "./Utils";
import "./interfaces"
export class EventEmitter<T extends ExtendableEvent> {

    protected readonly handlers: EventHandler<T>[] = [];

    constructor(protected readonly name: string, protected readonly elementSelector?: (() => JQuery | JQuery[])) {

    }

    on(handler: EventHandler<T>, index: number = this.handlers.length) {
        if (this.handlers.indexOf(handler) !== -1) {
            this.off(handler);
        }
        this.handlers.splice(index, 0, handler);
    }

    /**
     * Binds a handler that will only be fired once. This handler is not detachable using off()
     * @param handler
     * @param index
     */
    oneTime(handler: EventHandler<T>, index: number = this.handlers.length) {
        const wrapper = (e: T) => {
            handler(e);
            this.off(wrapper);
        };
        this.handlers.splice(index, 0, wrapper);
    }

    off(): void;
    off(handler: EventHandler<T>): void;
    off(handler?: EventHandler<T>) {
        if (handler) {
            this.handlers.remove(handler);
        } else {
            this.handlers.clear();
        }
    }

    hasHandlersAttached() {
        return this.handlers.length > 0;
    }

    fire(event: T, elements?: JQuery | JQuery[]) {
        return this.internalFire(event, this.handlers, elements);
    }

    protected internalFire(event: T, handlers: EventHandler<T>[], elements?: JQuery | JQuery[]) {
        event.type = this.name;
        for (let handler of handlers) {
            if (event.isPropagationStopped()) {
                break;
            }
            handler(event);
        }
        if (event.isPropagationStopped()) {
            return;
        }
        const consolidatedElements: JQuery[] = [];
        if (elements) {
            consolidatedElements.pushRange(GetAsArray(elements).filter(e => !!e));
        }
        if (this.elementSelector) {
            consolidatedElements.pushRange(GetAsArray(this.elementSelector()).filter(e => !!e));
        }
        if (consolidatedElements.length) {
            const domEvent = event.toDomEvent(this.name);
            for (let element of consolidatedElements) {
                element.each((i, elem) => {
                    elem.dispatchEvent(domEvent)
                });
            }
        }
        return event;
    }
}

abstract class KeyedEventEmitter<T extends ExtendableEvent, TKey extends string | number> extends EventEmitter<T> {
    protected allHandlers: EventHandler<T>[] = [];

    protected constructor(name: string) {
        super(name);
    }

    on(key: TKey, handler: EventHandler<T>, index: number): void;

    on(key: TKey, handler: EventHandler<T>): void;

    on(handler: EventHandler<T>, index: number): void;

    on(handler: EventHandler<T>): void;

    on(key: TKey | (EventHandler<T>), handler?: (EventHandler<T>) | number, index?: number): void {
        if (index === undefined) {
            index = typeof handler === "number" ? handler : this.handlers.length;
        }
        if (typeof handler !== "function" && typeof key === "function") {
            handler = key;
            key = null;
        }
        key = key as TKey;
        handler = handler as EventHandler<T>;
        index = index as number;
        if (key) {
            super.on(handler, index);
        } else {
            const keyedHandlers = this.getKeyedHandlers(key);
            if (keyedHandlers.indexOf(handler) !== -1) {
                this.off(key, handler);
            }
            keyedHandlers.splice(index, 0, handler);
            this.allHandlers.push(handler);
        }
    }

    /**
     * Binds a handler that will only be fired once. This handler is not detachable using off()
     * @param handler
     * @param index
     */
    oneTime(key: TKey, handler: EventHandler<T>, index: number): void;

    oneTime(key: TKey, handler: EventHandler<T>): void;

    oneTime(handler: EventHandler<T>, index: number): void;

    oneTime(handler: EventHandler<T>): void;

    oneTime(key: TKey | (EventHandler<T>), handler?: (EventHandler<T>) | number, index?: number): void {
        if (index === undefined) {
            index = typeof handler === "number" ? handler : this.handlers.length;
        }
        if (typeof handler !== "function" && typeof key === "function") {
            handler = key;
            key = null;
        }
        key = key as TKey;
        handler = handler as EventHandler<T>;
        index = index as number;
        if (key) {
            super.oneTime(handler, index);
        } else {
            var wrapper = ((key: TKey, handler: EventHandler<T>) => (e: T) => {
                handler(e);
                this.off(key, wrapper);
            })(key, handler);
            this.handlers.splice(index, 0, wrapper);
        }
    }

    off(): void;
    off(handler: EventHandler<T>): void;
    off(key: TKey, handler: EventHandler<T>): void;
    off(key?: TKey | EventHandler<T>, handler?: EventHandler<T>): void {
        if (typeof key === "function") {
            this.handlers.remove(key);
            this.allHandlers.remove(key);
        } else if (key) {
            this.getKeyedHandlers(key).remove(handler);
            this.allHandlers.remove(handler);
        } else {
            this.handlers.clear();
        }
    }

    hasHandlersAttached(key?: TKey) {
        if (key) {
            return this.getKeyedHandlers(key).length > 0;
        }
        return this.handlers.length > 0;
    }

    fire(key: TKey | TKey[], event: T, elements?: JQuery | JQuery[]): T;

    fire(event: T, elements?: JQuery | JQuery[]): T;

    fire(key: TKey | TKey[] | T, event?: T | JQuery | JQuery[], elements?: JQuery | JQuery[]) {
        if (key instanceof ExtendableEvent) {
            if (event) {
                elements = event as JQuery | JQuery[];
            }
            event = key;
            key = null;
        }
        const keys = key instanceof Array ? key : [key as TKey];
        event = event as T;
        elements = elements as JQuery | JQuery[];

        if (key) {
            super.fire(event, elements);
            if (!event.isPropagationStopped()) {
                this.internalFire(event, this.getKeyedHandlers(keys));
            }
        } else {
            this.internalFire(event, this.allHandlers, elements);
        }
        return event;
    }

    protected abstract getKeyedHandlers(key: TKey | TKey[]): EventHandler<T>[];
}

export class StringKeyedEventEmitter<T extends ExtendableEvent> extends KeyedEventEmitter<T, string> {
    protected readonly keyedHandlers = {} as { [index: string]: EventHandler<T>[] };

    constructor(name: string) {
        super(name);
    }

    protected getKeyedHandlers(key: string): EventHandler<T>[] {
        const ret = [];
        for (const key in this.keyedHandlers) {
            if (!this.keyedHandlers.hasOwnProperty(key)) {
                continue;
            }
            for (const handler of this.keyedHandlers[key]) {
                if (ret.indexOf(handler) === -1) {
                    ret.push(handler);
                }
            }
        }
        return ret;
    }
}

export class NumberKeyedEventEmitter<T extends ExtendableEvent> extends KeyedEventEmitter<T, number> {
    protected readonly keyedHandlers = {} as { [index: number]: EventHandler<T>[] };

    constructor(name: string) {
        super(name);
    }

    protected getKeyedHandlers(key: number): EventHandler<T>[] {
        const ret = [];
        for (const key in this.keyedHandlers) {
            if (!this.keyedHandlers.hasOwnProperty(key)) {
                continue;
            }
            for (const handler of this.keyedHandlers[key]) {
                if (ret.indexOf(handler) === -1) {
                    ret.push(handler);
                }
            }
        }
        return ret;
    }
}
