import {AccountStore, Store, UserRightsStore} from "@stores";
import {WebMethodApi} from "../../api/Apis";
import {filter} from "rxjs/operators";
import {hasValue} from "@utils/rxjs-extensions";
import {closeAlert, openAlert} from "@widgets/Alert";
import {ShipmentSearchUiStore} from "@app/page-components/shipment/shipment-list/shipment-search-ui-store.service";
import {OrderSearchUiStore} from "@app/page-components/order/order-list/order-search-bar/order-search-ui-store.service";
import {Injectable} from "@angular/core";
import {runRequestWithoutProgress} from "@app/interceptors";
import {ExcelExportMode, ExcelExportResult, OrderExcelExportMode, ShipmentExcelExportMode, ShipmentListViewMode} from "@api";
import detect = require("@utils/detect");

interface ExportToExcelStoreState {
  canStartNewExport: boolean;
  excelExportResult: ExcelExportResult;
  excelExportMode?: ExcelExportMode;
}

@Injectable({providedIn: "root"})
export class ExportToExcelService extends Store<ExportToExcelStoreState> {
  private static readonly _initialState: ExportToExcelStoreState = {
    canStartNewExport: null,
    excelExportResult: null
  };
  private readonly _accountStore: AccountStore;
  private readonly _webMethodApi: WebMethodApi;
  private readonly _userRightsStore: UserRightsStore;
  private _currentAlert: JQuery/*<HTMLElement>*/;
  private readonly _orderSearchUiStore: OrderSearchUiStore;
  private readonly _shipmentSearchUiStore: ShipmentSearchUiStore;

  constructor(userRightsStore: UserRightsStore,
              accountStore: AccountStore,
              webMethodApi: WebMethodApi,
              shipmentSearchUiStore: ShipmentSearchUiStore,
              orderSearchUiStore: OrderSearchUiStore
  ) {
    super({}, ExportToExcelService._initialState);
    this._shipmentSearchUiStore = shipmentSearchUiStore;
    this._orderSearchUiStore = orderSearchUiStore;
    this._userRightsStore = userRightsStore;
    this._webMethodApi = webMethodApi;
    this._accountStore = accountStore;

    this._hasUnfinishedReport$
        .subscribe(() => {
          this._openProcessingAlert()
          void this._tryGetReportId()
        });
    this._exportToExcelRightAdded$.subscribe(() => void this._checkForPendingReport())
    this._exportToExcelRightRemoved$.subscribe(() => this.reset());
    this._reportAvailableForDownload$.subscribe((result) => {
      this._openDownloadAlert(result)
    });
    this._cannotStartNewReport$.subscribe(() => void this._checkIfNewReportCanBeStarted())
  }

  private get _hasUnfinishedReport$() {
    return this.observe()
               .pipe(filter(
                 ({canStartNewExport, excelExportResult}) =>
                   excelExportResult === null && canStartNewExport === false));
  }

  private get _exportToExcelRightAdded$() {
    return this._userRightsStore
               .observe("exportToExcel")
               .pipe(filter(e => e));
  }

  private get _exportToExcelRightRemoved$() {
    return this._userRightsStore
               .observe("exportToExcel")
               .pipe(filter(e => !e));
  }

  private get _reportAvailableForDownload$() {
    return this.observe("excelExportResult")
               .pipe(filter(hasValue));
  }

  private get _cannotStartNewReport$() {
    return this.observe("canStartNewExport")
               .pipe(filter(
                 c => c === false));
  }

  getName(): string {
    return "ExcelExportService";
  }

  async startNewOrderReport(): Promise<void> {
    const search = this._orderSearchUiStore.get();
    const mode = this._accountStore.get("orderExcelExportMode");
    await this._webMethodApi.startOrderExcelExport(mode, search).toPromise();
    this.update({"canStartNewExport": false, excelExportMode: ExcelExportMode.Order});
  }

  async startNewShipmentReport(): Promise<void> {
    const search = this._shipmentSearchUiStore.get();
    const viewMode = this._accountStore.get("shipmentListViewMode");
    const mode = viewMode === ShipmentListViewMode.Shipments ? ShipmentExcelExportMode.ByShipment : ShipmentExcelExportMode.ByContainer;
    await this._webMethodApi.startShipmentExcelExport(mode, search).toPromise();
    this._accountStore.update({shipmentExcelExportMode: mode})
    this.update({"canStartNewExport": false, excelExportMode: ExcelExportMode.Shipment});
  }

  private async _tryGetReportId() {
    const id = await runRequestWithoutProgress(this._webMethodApi.getReportDownloadPK())
    if (id) {
      this.update("excelExportResult", id);
      return;
    }
    const userHasNoExcelExportRights = this._userRightsStore.get("exportToExcel");
    if (!userHasNoExcelExportRights)
      return;
    setTimeout(() => void this._tryGetReportId(), 3000);
  }

  private async _checkForPendingReport() {
    const canStartNewExport = await runRequestWithoutProgress(this._webMethodApi.canStartNewExcelExport());
    let excelExportResult: ExcelExportResult | null = null
    if (!canStartNewExport)
      excelExportResult = await this._webMethodApi.getReportDownloadPK().toPromise()
    this.update({canStartNewExport, excelExportResult});
  }

  private _openDownloadAlert(result: ExcelExportResult) {
    if (this._currentAlert)
      closeAlert(this._currentAlert);
    const reportTypeDescription = this._getReportTypeDescription(result);

    const buttons = $(
      `<a href='${window.baseApplicationUrl}/webmethod/GetReportBytes?RE_PK=${result.reportId}'
        id='downloadReportButton'
        class='button'
        target="${detect.browser.ie ? "_self" : "_blank"}">Download</a>`)
      .click(() => this._onDownloadCompleted())

    this._currentAlert = openAlert({
                                     buttons,
                                     content: `<strong>Your Excel export based on ${reportTypeDescription} has been generated.</strong>`,
                                     type: "success",
                                     dontDisplayX: true,
                                     timeout: false

                                   });
    this._currentAlert.addClass("secondary")
  }

  private _getReportTypeDescription(result: ExcelExportResult) {
    if (result.reportMode === ExcelExportMode.Shipment) {
      const viewMode = this._accountStore.get("shipmentExcelExportMode");
      return viewMode === ShipmentExcelExportMode.ByShipment ? "Bill of Lading" : "Container";
    } else {
      const viewMode = this._accountStore.get("orderExcelExportMode")
      if (viewMode === OrderExcelExportMode.ByOrder) {
        return "Orders"
      } else if (viewMode === OrderExcelExportMode.ByShipment) {
        return "Shipments";
      } else {
        return "Order lines and Shipments";
      }
    }
  }

  private _openProcessingAlert() {
    if (this._currentAlert)
      closeAlert(this._currentAlert);
    const content = "Your file is being generated, please wait.";
    this._currentAlert = openAlert({
                                     content,
                                     type: "info",
                                     dontDisplayX: true,
                                     timeout: false

                                   });
    this._currentAlert.addClass("secondary")
  }

  private _onDownloadCompleted() {
    if (this._currentAlert)
      closeAlert(this._currentAlert);
    this.update({
                  canStartNewExport: true,
                  excelExportResult: null
                })
  }

  private async _checkIfNewReportCanBeStarted() {
    if (this.get("canStartNewExport") !== false)
      return;
    if (!this._userRightsStore.get("exportToExcel"))
      return;
    const canStartNewExport = await runRequestWithoutProgress(this._webMethodApi.canStartNewExcelExport());
    if (canStartNewExport)
      this._onDownloadCompleted()
    else
      setTimeout(() => void this._checkIfNewReportCanBeStarted(), 3000);
  }
}
