export function TypeFilter<T1 extends object, T2 extends object, T3 extends object, T4 extends object, T5 extends object,
  T6 extends object, T7 extends object, T8 extends object, T9 extends object, T10 extends object>
(t1: Constructor<T1>, t2: Constructor<T2>, t3: Constructor<T3>, t4: Constructor<T4>, t5: Constructor<T5>, t6: Constructor<T6>, t7: Constructor<T7>, t8: Constructor<T8>, t9: Constructor<T9>, t10: Constructor<T10>): (o: object) => o is T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 | T9 | T10 ;
export function TypeFilter<T1 extends object, T2 extends object, T3 extends object, T4 extends object, T5 extends object,
  T6 extends object, T7 extends object, T8 extends object, T9 extends object>
(t1: Constructor<T1>, t2: Constructor<T2>, t3: Constructor<T3>, t4: Constructor<T4>, t5: Constructor<T5>, t6: Constructor<T6>, t7: Constructor<T7>, t8: Constructor<T8>, t9: Constructor<T9>): (o: object) => o is T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 | T9 ;
export function TypeFilter<T1 extends object, T2 extends object, T3 extends object, T4 extends object, T5 extends object,
  T6 extends object, T7 extends object, T8 extends object>
(t1: Constructor<T1>, t2: Constructor<T2>, t3: Constructor<T3>, t4: Constructor<T4>, t5: Constructor<T5>, t6: Constructor<T6>, t7: Constructor<T7>, t8: Constructor<T8>): (o: object) => o is T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 ;
export function TypeFilter<T1 extends object, T2 extends object, T3 extends object, T4 extends object, T5 extends object,
  T6 extends object, T7 extends object>
(t1: Constructor<T1>, t2: Constructor<T2>, t3: Constructor<T3>, t4: Constructor<T4>, t5: Constructor<T5>, t6: Constructor<T6>, t7: Constructor<T7>): (o: object) => o is T1 | T2 | T3 | T4 | T5 | T6 | T7 ;
export function TypeFilter<T1 extends object, T2 extends object, T3 extends object, T4 extends object, T5 extends object,
  T6 extends object>
(t1: Constructor<T1>, t2: Constructor<T2>, t3: Constructor<T3>, t4: Constructor<T4>, t5: Constructor<T5>, t6: Constructor<T6>): (o: object) => o is T1 | T2 | T3 | T4 | T5 | T6 ;
export function TypeFilter<T1 extends object, T2 extends object, T3 extends object, T4 extends object, T5 extends object>
(t1: Constructor<T1>, t2: Constructor<T2>, t3: Constructor<T3>, t4: Constructor<T4>, t5: Constructor<T5>): (o: object) => o is T1 | T2 | T3 | T4 | T5 ;
export function TypeFilter<T1 extends object, T2 extends object, T3 extends object, T4 extends object>
(t1: Constructor<T1>, t2: Constructor<T2>, t3: Constructor<T3>, t4: Constructor<T4>): (o: object) => o is T1 | T2 | T3 | T4 ;
export function TypeFilter<T1 extends object, T2 extends object, T3 extends object>
(t1: Constructor<T1>, t2: Constructor<T2>, t3: Constructor<T3>): (o: object) => o is T1 | T2 | T3 ;
export function TypeFilter<T1 extends object, T2 extends object>(t1: Constructor<T1>, t2: Constructor<T2>): (o: object) => o is T1 | T2 ;
export function TypeFilter<T1 extends object>(t1: Constructor<T1>): (o: object) => o is T1 ;
export function TypeFilter<T1 extends object = object, T2 extends object = object, T3 extends object = object, T4 extends object = object,
  T5 extends object = object, T6 extends object = object, T7 extends object = object, T8 extends object = object,
  T9 extends object = object, T10 extends object = object>
(t1: Constructor<T1>, t2?: Constructor<T2>, t3?: Constructor<T3>, t4?: Constructor<T4>, t5?: Constructor<T5>, t6?: Constructor<T6>, t7?: Constructor<T7>, t8?: Constructor<T8>, t9?: Constructor<T9>, t10?: Constructor<T10>): (e: object) => boolean {
  if (t10 !== undefined)
    return (e): e is T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 | T9 | T10 =>
      e instanceof t1 || e instanceof t2 || e instanceof t3 || e instanceof t4 ||
      e instanceof t5 || e instanceof t6 || e instanceof t7 || e instanceof t8 ||
      e instanceof t9 || e instanceof t10;
  if (t9 !== undefined)
    return (e): e is T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 | T9 =>
      e instanceof t1 || e instanceof t2 || e instanceof t3 || e instanceof t4 ||
      e instanceof t5 || e instanceof t6 || e instanceof t7 || e instanceof t8 ||
      e instanceof t9;
  if (t8 !== undefined)
    return (e): e is T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 =>
      e instanceof t1 || e instanceof t2 || e instanceof t3 || e instanceof t4 ||
      e instanceof t5 || e instanceof t6 || e instanceof t7 || e instanceof t8;
  if (t7 !== undefined)
    return (e): e is T1 | T2 | T3 | T4 | T5 | T6 | T7 =>
      e instanceof t1 || e instanceof t2 || e instanceof t3 || e instanceof t4 ||
      e instanceof t5 || e instanceof t6 || e instanceof t7;
  if (t6 !== undefined)
    return (e): e is T1 | T2 | T3 | T4 | T5 | T6 =>
      e instanceof t1 || e instanceof t2 || e instanceof t3 || e instanceof t4 ||
      e instanceof t5 || e instanceof t6;
  if (t5 !== undefined)
    return (e): e is T1 | T2 | T3 | T4 | T5 =>
      e instanceof t1 || e instanceof t2 || e instanceof t3 || e instanceof t4 ||
      e instanceof t5;
  if (t4 !== undefined)
    return (e): e is T1 | T2 | T3 | T4 =>
      e instanceof t1 || e instanceof t2 || e instanceof t3 || e instanceof t4;
  if (t3 !== undefined)
    return (e): e is T1 | T2 | T3 =>
      e instanceof t1 || e instanceof t2 || e instanceof t3;
  if (t2 !== undefined)
    return (e): e is T1 | T2 =>
      e instanceof t1 || e instanceof t2;
  return (e): e is T1 =>
    e instanceof t1!;
}
