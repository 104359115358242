import {TimelineLegType, TimelineLocation, TimeLineLocationType} from "@api";
import {TimelineLocationTypeCssClassPipe} from "@app/utils/rt-date.pipe";
import {TimelineDateUiData} from "@app/page-components/shipment/shipment-detail/vertical-timeline/vertical-timeline.component";

export function createTimelineUiData(location: TimelineLocation,
                                     legTypeOrIgnoreNoDates: TimelineLegType | boolean,
                                     startOrEnd: "start" | "end"): TimelineDateUiData {
  const legType = typeof legTypeOrIgnoreNoDates === "boolean" ? null : legTypeOrIgnoreNoDates;
  const ignoreNoDates = typeof legTypeOrIgnoreNoDates === "boolean" ? legTypeOrIgnoreNoDates : false;
  if (!location)
    return null;
  const displayDate = location.actualDate || location.estimatedDate;
  if (!displayDate && !ignoreNoDates)
    return null;
  const isEstimated = !location.actualDate && !!location.estimatedDate;
  return {
    actual: location.actualDate,
    estimated: location.estimatedDate,
    scheduled: location.scheduledDate,
    displayDate: displayDate,
    displayDateIsEstimated: isEstimated,
    warningLabelClass: _getWarningLabelCssClass(location.scheduledDate, location.actualDate || location.estimatedDate, legType),
    displayDateLabel: _getDateDisplayLabel(location.type, startOrEnd, isEstimated),
    popupInfoText: _getPopupInfoText(location.scheduledDate, location.actualDate, location.estimatedDate),
    startOrEnd: startOrEnd,
    popoverTitle: _getPopoverTitle(location.type, startOrEnd),
    locationTypeCssClass: TimelineLocationTypeCssClassPipe.transform(location.type),
    locationName: location.port?.name || location.location?.name
  }
}

function _getPopupInfoText(scheduled: Date, actual: Date, estimated: Date) {
  if (!scheduled || !(actual || estimated))
    return "";
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const dateDifference = Math.round(scheduled.getDaysTo(actual || estimated));
  const isEstimated = !actual;
  const onSchedule = dateDifference === 0;
  if (onSchedule)
    return isEstimated
           ? "On schedule"
           : "Completed on schedule";
  const daysLabel = Math.abs(dateDifference) === 1 ? "day" : "days";
  if (estimated)
    return `${Math.abs(dateDifference)} ${daysLabel} ${dateDifference > 0 ? "late" : "ahead"}`;
  return `Completed ${Math.abs(dateDifference)} ${daysLabel} ${dateDifference > 0 ? "behind" : "ahead of"} schedule`;
}

function _getWarningLabelCssClass(scheduled: Date, other: Date, legType: TimelineLegType): null | "short-delay" | "delay" | "ahead" {
  if (!scheduled || !other)
    return null;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const dateDifference = scheduled.getDaysTo(other);
  if (dateDifference === 0)
    return null;
  if (dateDifference < 0)
    return "ahead";
  const legTypeCanHaveShortDelay = legType === TimelineLegType.Rail || legType === TimelineLegType.Sea;
  if (dateDifference <= 2 && legTypeCanHaveShortDelay)
    return "short-delay";
  return "delay";
}

function _getDateDisplayLabel(type: TimeLineLocationType, startOrEnd: "start" | "end", isEstimated: boolean) {
  if (type === TimeLineLocationType.Pickup)
    return isEstimated ? "Estimated Pickup" : "Actual Pickup";
  if (type === TimeLineLocationType.Delivery)
    return isEstimated ? "Estimated Delivery" : "Actual Delivery";
  if (startOrEnd === "end")
    return isEstimated ? "Estimated Time of Arrival" : "Actual Time of Arrival";
  return isEstimated ? "Estimated Time of Departure" : "Actual Time of Departure";
}

export type PopoverTitle = "Pickup" | "Delivery" | "Departure" | "Arrival"

function _getPopoverTitle(type: TimeLineLocationType, startOrEnd: "start" | "end"): PopoverTitle {
  if (type === TimeLineLocationType.Pickup && startOrEnd === "start")
    return "Pickup";
  if (type === TimeLineLocationType.Delivery && startOrEnd === "end")
    return "Delivery";
  return startOrEnd === "start"
         ? "Departure"
         : "Arrival";
}
