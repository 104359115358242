import { Component, OnInit, ViewChild } from '@angular/core';
import { AppBaseComponent } from "@app/appBaseComponent";
import { RoutingService } from "@app/services/routing.service";
import { AccountService } from "@services";
import { CountryService } from '../../../../services/country.service';
import { ISelectOptions } from "@widgets/Select";
import { JSONData } from "@api";
import { SlideInOut } from "@utils/Angular/AnimationTemplates";
import { NgForm } from '@angular/forms';
import { invalid } from 'moment-mini-ts';

@Component({
  selector: 'rt-request-account',
  templateUrl: './request-account.component.html',
  styleUrls: ['./request-account.component.scss'],
  animations: [SlideInOut()]
})

export class RequestAccountComponent extends AppBaseComponent implements OnInit {
  private readonly _accountService: AccountService;
  firstName = "";
  lastName = "";
  companyName = "";
  emailAddress = "";
  RoehligCustomer = "";
  InquiryText = "";
  Summitted = false;
  isDemo = window.isDemo;
  readonly countryItems: JSONData[];
  selectedCountry = "";
  readonly selectSettings = {
    countries: {
      multiple: false,
      placeholderNone: '',
      search: true,
      displayIcon: true,
      class: 'flags'
    } as ISelectOptions,
    default: {
      multiple: true,
      placeholderNone: 'Any',
    } as ISelectOptions,
  }

  constructor(accountService: AccountService, countryService: CountryService) {
    super();
    this._accountService = accountService;
    this.countryItems = countryService.getJsonData();

  }
  ngOnInit(): void {
    //this.RoehligCustomer = "";
  }

  async requestAccount($event?: Event, requestForm?: NgForm): Promise<void>  {
    //$event?.preventDefault();
    let invalidCustomer: boolean = false;
    if (!this.RoehligCustomer) {
      this.RoehligCustomer = "invalid";
      invalidCustomer = true;
    }
    if (!requestForm.valid || invalidCustomer)
      return;
    if (!this.InquiryText)
      this.InquiryText = "–";
    const isCostumer = JSON.parse(this.RoehligCustomer) as boolean;
    await this._accountService.requestAccount(this.emailAddress, this.firstName, this.lastName, this.companyName, this.selectedCountry, isCostumer, this.InquiryText);
    this.Summitted = true;
  }
}
