import {Directive, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {scrollBarOverlay} from "@utils/Utils";
import detect = require("@utils/detect");

import * as SimpleBar from "simplebar";
import SimpleBarClass = require("simplebar");
import {AccountStore, ShipmentStore} from "@stores";
import {ContainerStore} from "@stores/container-store.service";
import {merge} from "rxjs";
import {debounceTime, mapTo, takeUntil} from "rxjs/operators";
import {AppBaseComponent} from "@app/appBaseComponent";

@Directive({
             selector: '[rtScrollBarOverlay]'
           })
export class ScrollBarOverlayDirective  extends AppBaseComponent implements OnInit, OnDestroy  {

  private readonly _elementRef: ElementRef;
  private _scrollbarOverlay: HTMLDivElement;

  constructor(elementRef: ElementRef, accountStore: AccountStore, shipmentStore: ShipmentStore, containerStore: ContainerStore) {
    super();
    this._elementRef = elementRef;
    merge(
      shipmentStore
        .observe("shipmentList")
        .pipe(mapTo(null)),
      containerStore
        .observe("itemList")
        .pipe(mapTo(null)),
      accountStore
        .observe(["listLayoutOverflow", "shipmentListLayout", "currentLayout", "containerListLayout"])
        .pipe(mapTo(null)))
      .pipe(
        debounceTime(50),
        takeUntil(this.destroyed$))
      .subscribe(() => window.dispatchEvent(new CustomEvent("scrollbar-overlay-update")));

  }

  ngOnInit(): void {
    const scrollContainer = this._elementRef.nativeElement as HTMLElement;
    if (detect.browser.safari && !detect.touchDevice) {
      const simpleBar: SimpleBarClass = new SimpleBar(scrollContainer, {autoHide: false});
      scrollBarOverlay(scrollContainer, simpleBar);
    } else
      this._scrollbarOverlay = scrollBarOverlay(scrollContainer);
  }

  ngOnDestroy(): void {
    this._scrollbarOverlay?.remove();
    super.ngOnDestroy();
  }
}
