import {Component, HostBinding, OnInit} from '@angular/core';
import {AccountService} from "@services";
import {EntitySetInfo, UserInformation} from "@api";
import {BookingService} from '@services/booking.service';
import {MainMasterUiService} from "@app/master-components/main/main-master-ui.service";
import appConfig = require("@app-config");

type EntitySets = { name: string, id: string, isSelected: boolean }[];

@Component({
             selector: 'rt-user-menu',
             templateUrl: './user-menu.component.html',
             styleUrls: ['./user-menu.component.scss'],
             // changeDetection: ChangeDetectionStrategy.OnPush,
             host: {class: "sidebar-right"}
           })
export class UserMenuComponent implements OnInit {
  entitySets: EntitySets = [];
  @HostBinding("class.open")
  isOpen: boolean;
  userCanSwitchEntitySets = false;
  rtVersion = appConfig.version;
  entitySetListOpen = false;
  private readonly _accountService: AccountService;
  private readonly _bookingService: BookingService;

  private readonly _mainMasterUiService: MainMasterUiService;

  constructor(accountService: AccountService, bookingService: BookingService, mainMasterUiService: MainMasterUiService) {
    this._mainMasterUiService = mainMasterUiService;
    this._accountService = accountService;
    this._bookingService = bookingService
  }

  ngOnInit(): void {
    this._accountService
        .accountStore
        .observe()
        .subscribe(d => this._reloadEntityData(d));
    this._accountService
        .accountStore
        .observe("currentEntitySet")
        .subscribe(e => this._entitySetChanged(e))
    this._mainMasterUiService
        .store
        .subscribe("userMenuOpen", o => {
          this.isOpen = o
        })
  }

  async changeEntitySet(entitySetId: string): Promise<void> {
    if (await this._canChangeSessionState())
      await this._accountService.changeEntitySet(entitySetId);
  }
  toggleEntitySetList(): void {
    this.entitySetListOpen = !this.entitySetListOpen;
  }

  async logout(): Promise<void> {
    if (await this._canChangeSessionState())
      await this._accountService.logout();
  }

  private _reloadEntityData(userData: UserInformation) {
    const loggedOut = !userData
    if (loggedOut)
      return;
    const currentEntitySetId = userData.currentEntitySet?.id;
    this.entitySets = userData.availableEntitySets.map(set => {
      return {
        id: set.id,
        name: set.name,
        isSelected: set.id === currentEntitySetId
      }
    });
    this.userCanSwitchEntitySets = this.entitySets.length > 1;
    this.closeUserMenu()
  }

  private async _canChangeSessionState() {
    if (this._bookingService.hasActiveBooking()) {
      const bookingCancelled = await this._bookingService.requestCancellation();
      return bookingCancelled
    }
    return true;
  }

  private _entitySetChanged(entitySetInfo: EntitySetInfo | null) {
    this.closeUserMenu()
    if (!entitySetInfo)
      return;
    for (const entitySet of this.entitySets) {
      entitySet.isSelected = entitySet.id === entitySetInfo.id;
    }
  }

  closeUserMenu(): void {
    this._mainMasterUiService.closeUserMenu();
  }
}
