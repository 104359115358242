<div class="event"
     *ngFor="let location of shipment.timelineLegs | pureFunction:getLocations; first as first"
     [ngClass]="{complete: location.progress === 100 || first && location.progress !== null}">
    <span
      class="popover-trigger material-icons icon"
      [ngClass]="location.type | rtTimelineLocationTypeCssClass"
      [rtPopover]="'ng-simple-timeline'"
      data-popover-placement="top"
      [attr.data-settings]="location | pureFunction:createTimelineUiData:true:location.startOrEnd | json"></span>
  <div class="line" *ngIf="!first">
    <div *ngIf="location.progress > 0 && location.progress < 100"
         class="progress-bar"
         [ngStyle]="{'width.%': location.progress}"></div>
    <div class="background"></div>
  </div>
</div>
