import { checkScrollMargin, sleep, trimPaste } from "../utils/Utils";
import { Dropdown } from "./Dropdown";
import * as nprogress from "nprogress";
import { GenerateDefaultOptions } from "../libs/DatepickDefaultOptions";
import { FilterPreset } from "../blueFilterPreset";
import { EqualHeight } from "./Equal_Height";
import { Select } from "./Select";
import detect = require("../utils/detect");
import setRowGroup = EqualHeight.setRowGroup;
import { Viewport } from "../utils/Viewport";
import { closeSidebar } from "./Sidebar";
import {Site} from "../Site";
import { Event } from 'jquery';

require("../utils/JqueryLoader");
require("./../../node_modules/kbw-datepick/dist/js/jquery.plugin.js");
require("./../../node_modules/kbw-datepick/dist/js/jquery.datepick.js");

export function initSearchBar($searchBar: JQuery) {
    const $filterToggle = $searchBar.find(".filters-toggle")
        .on("click",
            function (this: HTMLElement, event) {
                event.preventDefault();
                $(this).toggleClass("active");
                $(".filters")
                    .stop()
                    .slideToggle();
            });

    $searchBar.find("#filter-sort")
        .on("click",
            function (event) {
                const sort = $("#sort").val();
                const html = `<div class="checkboxes">
<p><input id="sort-asc" name="sort-trigger" type="radio" value="asc"" ${sort === "asc" ? " checked" : ""}"><label for="sort-asc">Ascending</label></p>
<p><input id="sort-desc" name="sort-trigger" type="radio" value="desc"${sort === "desc" ? " checked" : ""}"><label for="sort-desc">Descending</label></p>
</div>`;
                $(".fake-dropdown").append(html);
                $("[name=\"sort-trigger\"]").change(function () {
                    $("#sort").val($(this).filter(":checked").val());
                });
            });

    $searchBar.find(".category-select").children(".select-button").on("change", () => {
        setSearchBarPlaceholder($(".select-list").children().find(".selected").text());
    });

  Viewport.onChange.connect(() => setSearchBarPlaceholder($(".custom-select.select-button").children().html()));

  //$searchBar.find(".input-search").children("input")
  //  .bind("paste", (event: Event) => {
  //    trimPaste(<ClipboardEvent>event.originalEvent, $searchBar.find(".input-search").children("input")[0] as HTMLInputElement);
  //  })

    function setSearchBarPlaceholder(searchType: string) {
        if ($("body").hasClass("page-shipment-list") || $("body").hasClass("page-order-list")) {
            const $searchInput = $searchBar.find(".input-search").children("input");
            const isShipment = $("body").hasClass("page-shipment-list");

            if (Viewport.is('320')) {
                if (searchType === "Any")
                    $searchInput.attr("placeholder", `Search ${isShipment === true ? "Shipments" : "Orders"}`);
                else
                    $searchInput.attr("placeholder", "Search by " + searchType);
            }
            else {
                $searchInput.attr("placeholder", `Search ${isShipment === true ? "Shipments" : "Orders"}`);
            }
        }
    }

    $searchBar.find("button.submit-form")
        .click(() => $filterToggle.filter(".active").click());
    $searchBar.on("presubmit",
        async (event) => {
            event.preventDefault();
            nprogress.start();
            closeSidebar();
            // noinspection JSJQueryEfficiency
            $(".shipment-box.added").removeClass("added");
            const request = $.post($searchBar.attr("action"), $searchBar.serialize());
            const data = await request.promise();
            $(".shipment-list")
                .toggleClass("no-results", !parseInt(request.getResponseHeader("Result-Count")));
            $(".header")
                .toggle(!!parseInt(request.getResponseHeader("Result-Count")));
            const contentType = request.getResponseHeader("content-type") || "";
            if (contentType.indexOf("javascript") > -1) {
                eval(data);
            } else if (contentType.indexOf("html") > -1) {
                const $container = $("." + $searchBar.attr("data-target"));
                $(".load-more").remove();
                $container.html(data);
                Site.parseContent($container);
                if (!detect.browser.safari) {
                    setRowGroup($container[0]);
                    await sleep(0);
                }
                window.dispatchEvent(new CustomEvent("scrollbar-overlay-update"));
                checkScrollMargin();
                nprogress.done();
            }
        });

    $(".reset-form").click(function (event) {
        event.preventDefault();
        if (window.isOffline)
            return;
        $searchBar.find(".datepicker-popup button.datepicker-reset").click();
        $searchBar.find("input, select")
            .each(function () {
                if (this instanceof HTMLSelectElement) {
                    if (this.multiple)
                        this.value = null;
                    else
                        this.value = (this.options[0] || {}).value;
                    $(this).change();
                } else {
                    switch (this.type) {
                        case "checkbox":
                        case "radio":
                            this.checked = false;
                            break;
                        case "submit":
                        case "reset":
                        case "button":
                        case "hidden":
                            break;
                        default:
                            this.value = null;
                    }
                }
            });
        $searchBar.submit();
    });

    $(".datepicker").each(function () {
        const $wrapper = $(this).parents(".datepicker-wrap");
        const $datepicker = $wrapper.find(".datepicker-calendar");
        const $input = $wrapper.find(".output").hide();
        let $placeholder: JQuery;
        const $inputStart = $wrapper.find(".date-start");
        const $inputEnd = $wrapper.find(".date-end");
        const $popup = $wrapper.find(".datepicker-popup").css("display", "block");
        const $dummies = $wrapper.find(".date-range");
        const $dateTypeLabel = $wrapper.prev().find("span.date-type");
        let lastSelectedStartDate: Date;
        let lastSelectedEndDate: Date;
        const dateTypeSelect = $popup.find("select").typedData("select") as Select;

        function getCurrentMonth() {
            return $datepicker.datepick("retrieveDate",
                $datepicker.find(".datepick-month a:not(.other-month):first")[0]);
        }

        $inputStart.on("focus",
            function () {
                $inputStart.addClass("focus");
                $inputEnd.removeClass("focus");
                const currentMonth = getCurrentMonth();
                $datepicker.datepick("option",
                    {
                        minDate: null,
                        maxDate: lastSelectedEndDate
                    });
                if (lastSelectedStartDate)
                    $datepicker.datepick("showMonth", lastSelectedStartDate);
                else if (lastSelectedEndDate || lastSelectedEndDate > currentMonth)
                    $datepicker.datepick("showMonth", currentMonth);

            });

        $inputEnd.on("focus",
            function () {
                $inputStart.removeClass("focus");
                $inputEnd.addClass("focus");
                const currentMonth = getCurrentMonth();
                $datepicker.datepick("option",
                    {
                        minDate: lastSelectedStartDate,
                        maxDate: null
                    });
                if (lastSelectedEndDate)
                    $datepicker.datepick("showMonth", lastSelectedEndDate);
                else if (lastSelectedStartDate || lastSelectedStartDate < currentMonth)
                    $datepicker.datepick("showMonth", currentMonth);
            });

        dateTypeSelect.onSelectionChanged.on(function (e) {
            if ($inputStart.val() || $inputEnd.val()) {
                $dateTypeLabel.html(" (" + e.addedItems[0].contentString + ")");
            }
        });

        $wrapper.parents(".datepicker-wrap").prev("label").click(function (event) {
            event.preventDefault();
        });

        $datepicker.datepick($.extend(GenerateDefaultOptions(),
            {
                onSelect: customRange,
                onShow: $.datepick.monthNavigation,
                rangeSelect: true
            }));

        const $fakeSelect = $("<div class=\"fake-select custom-select\" tabindex=\"0\"></div>")
            .insertBefore($input)
            .append($placeholder =
                $(document.createElement("span")).text(($input[0] as HTMLInputElement).placeholder));

        const dateDropdown = new Dropdown($popup,
            $fakeSelect,
            {
                hidingMode: "appendToToggle",
                allowGrowing: function () {
                    return window.innerWidth >= 720
                },
                class: "select"
            });
        dateDropdown.onOpened.on(function () {
            $inputStart.removeClass("focus");
            $inputStart
                .addClass("focus")
                .focus();
        });

        $popup.find(".datepicker-done").click(function (event) {
            event.preventDefault();
            if ($inputStart.val() || $inputEnd.val()) {
                $placeholder.text(($inputStart.val() + " – " + $inputEnd.val()));
                $input.val($placeholder.text());
                // adding date-type to "Date" label for control
                $dateTypeLabel.html(" (" + dateTypeSelect.getSelectedItems()[0].contentString + ")");
            } else {
                $placeholder.text(($input[0] as HTMLInputElement).placeholder);
                $input.val(null);
                $dateTypeLabel.html("");
            }
            dateDropdown.close();
        });

        $popup.find(".datepicker-reset").click(function (event) {
            event.preventDefault();
            // removing date-type to "Date" label for control
            $dateTypeLabel.html("");
            lastSelectedEndDate = null;
            lastSelectedStartDate = null;
            $datepicker.datepick("clear");
            $placeholder.text(($input[0] as HTMLInputElement).placeholder);
            $dateTypeLabel.text("");
            $input.val(null);
            $wrapper.find(".placeholder[for=\"input-date-range\"]").show();
            $dummies.val("").prev(".placeholder").show();
            $inputStart
                .focus()
                .addClass("focus");
            if (!detect.touch)
                $inputStart.focus();
        });

        function customRange(dateValue: Date[]) {
            if (dateValue.length === 2 && !$datepicker.data("datepick").pickingRange) {
                $inputStart.val($.datepick.formatDate(window.dateShortFormat, dateValue[0]));
                $inputEnd.val($.datepick.formatDate(window.dateShortFormat, dateValue[1]));
                lastSelectedStartDate = dateValue[0];
                lastSelectedEndDate = dateValue[1];
                $datepicker.datepick("setDate", lastSelectedStartDate, lastSelectedEndDate);
            } else {
                if ($inputEnd.is(".focus")) {
                    $inputEnd.val($.datepick.formatDate(window.dateShortFormat, dateValue[0]));
                    lastSelectedEndDate = dateValue[0];
                    if (lastSelectedStartDate && lastSelectedStartDate > lastSelectedEndDate) {
                        lastSelectedStartDate = null;
                        $inputStart.val("");
                    }
                    $datepicker.datepick("setDate",
                        lastSelectedStartDate || lastSelectedEndDate,
                        lastSelectedEndDate);
                } else {
                    $inputStart.val($.datepick.formatDate(window.dateShortFormat, dateValue[0]));
                    lastSelectedStartDate = dateValue[0];
                    $datepicker.datepick("setDate", lastSelectedStartDate, lastSelectedEndDate);
                }
                $inputEnd.focus();
            }
            if ($inputStart.val() || $inputEnd.val()) {
                $placeholder.text(($inputStart.val() + " – " + $inputEnd.val()));
                $input.val($placeholder.text());
                // adding date-type to "Date" label for control
                $dateTypeLabel.html(" (" + dateTypeSelect.getSelectedItems()[0].contentString + ")");
                if (lastSelectedEndDate)
                    $datepicker.datepick("showMonth", lastSelectedEndDate);
            } else {
                $placeholder.text(($input[0] as HTMLInputElement).placeholder);
                $input.val(null);
                $dateTypeLabel.html("");
            }
        }
    });

    $(".datepicker-done").click();
}
