import {Popup} from "./Popup";
import {Alignment, PopupPosition} from "@utils/Enums";
import {DangerousGoodsData, DangerousGoodsMasterData, ShipmentContainer} from "@api";
//import JsonGoodsBoxContainer = server.JsonGoodsBoxContainer;
import { JsonGoodsBoxContainer } from "../../../RRTClasses/JSON.cs";
import {TimelineDateUiData} from "@app/page-components/shipment/shipment-detail/vertical-timeline/vertical-timeline.component";
import {RtDatePipe} from "@app/utils/rt-date.pipe";
import {FieldRenderer} from "@app/page-components/shipment/shipment-list/field-renderer.service";

require("../utils/JqueryLoader");

export class Popover {

  static readonly popovers = new Array<Popover>();
  static readonly openPopovers = new Array<Popover>();
  static renderers = {
    timeline: function (this: HTMLElement) {
      const settings = $(this).data("settings");
      let htmlString = `<div class='timeline-popover'><h4>${settings.heading}</h4>`;
      if (settings.locationName)
        htmlString +=
          `<span class='material-icon ${settings.locationIconClass}'>${settings.locationName}</span>`;
      if (!settings.estimatedDate)
        htmlString += `<span class='date'><span class='text-light'>Actual</span>${settings.actualDate}</span>`;
      else
        htmlString +=
          `<span class='date'><span class='text-light material-icon mat-icon-schedule'>Estimated</span>${settings.estimatedDate}</span>`;
      htmlString +=
        `<span class='date'><span class='text-light material-icon mat-icon-event'>Scheduled</span>${settings.scheduledDate
        }</span><span class='info-text ${settings.onfoTextClass}'>${settings.infoText}</span></div>`;
      return htmlString;
    },
    "ng-timeline": function (this: HTMLElement) {
      const rtDatePipe = window.ngInjector.get(RtDatePipe);
      const formatDate: Func1<Date,string> = d => rtDatePipe.transform(d);
      const settings = $(this).getJsonFromAttribute("settings") as TimelineDateUiData;
      let htmlString = `<div class='timeline-popover'><h4>${settings.popoverTitle}</h4>`;
      if (settings.locationName)
        htmlString += `<span class='material-icon ${settings.locationTypeCssClass}'>${settings.locationName}</span>`;
      if (settings.actual)
        htmlString += `<span class='date'><span class='text-light'>Actual</span>${formatDate(settings.actual)}</span>`;
      else
        htmlString +=
          `<span class='date'><span class='text-light material-icon mat-icon-schedule'>Estimated</span>${formatDate(settings.estimated)}</span>`;
      htmlString +=
        `<span class='date'><span class='text-light material-icon mat-icon-event'>Scheduled</span>${formatDate(settings.scheduled)
        }</span><span class='info-text ${settings.warningLabelClass}'>${settings.popupInfoText}</span></div>`;
      return htmlString;
    },
    "simple-timeline": function (this: HTMLElement) {
      const settings = $(this).data("settings");
      let htmlString = `<div class='timeline-popover'><h4>${settings.heading}</h4>`;
      if (settings.locationName)
        htmlString +=
          `<span class='material-icon ${settings.locationIconClass}'>${settings.locationName}</span>`;
      if (settings.actualDate)
        htmlString += `<span class='date'>${settings.actualDate}</span>`;
      else if (settings.estimatedDate)
        htmlString +=
          `<span class='date'><span class='text-light material-icon mat-icon-schedule'>${settings.estimatedDate}</span></span>`;
      return htmlString;
    },
    "ng-simple-timeline": function (this: HTMLElement) {
      const rtDatePipe = window.ngInjector.get(RtDatePipe);
      const formatDate: Func1<Date,string> = d => rtDatePipe.transform(d);
      const settings =
        $(this).getJsonFromAttribute<TimelineDateUiData>("settings");
      let htmlString = `<div class='timeline-popover'><h4>${settings.popoverTitle}</h4>`;
      if (settings.locationName)
        htmlString +=
          `<span class='material-icon ${settings.locationTypeCssClass}'>${settings.locationName}</span>`;
      if (settings.actual)
        htmlString += `<span class='date'>${formatDate(settings.actual)}</span>`;
      else if (settings.estimated)
        htmlString +=
          `<span class='date'><span class='text-light material-icon mat-icon-schedule'>${formatDate(settings.estimated)}</span></span>`;
      return htmlString;
    },
    containerdates: function (this: HTMLElement) {
      const settings = $(this).data("settings");
      let htmlstring = `<h4>${settings.type}</h4>`;

      htmlstring += "<table class=\"popover-containerdatetable\">";
      $.each(settings.containers,
             (index, container) => {

               let date = "-";
               let dateType = "";

               if (settings.type === "Pickup") {
                 if (container.actPick) {
                   date = container.actPick;
                   dateType = "act";
                 } else if (container.estPick) {
                   date = container.estPick;
                   dateType = "est";
                 }
               } else if (settings.type === "Delivery") {
                 if (container.actDelv) {
                   date = container.actDelv;
                   dateType = "act";
                 } else if (container.estDelv) {
                   date = container.estDelv;
                   dateType = "est";
                 }
               }


               htmlstring +=
                 `<tr><td class="${dateType}">${date}</td><td>${container.containerNumber}</td></tr>`;
             });
      htmlstring += "</table>";
      return htmlstring;
    },
    "dangerous-goods": function (this: HTMLElement) {
      let data = $(this).getJsonFromAttribute<DangerousGoodsMasterData | DangerousGoodsMasterData[]>("data-dangerous-goods");
      if (!(data instanceof Array))
        data = [data];
      let html = "";
      for (const item of data.orderByMultiple(g => g.subLabel1, g => g.subLabel2, g => g.pg)) {
        //language=html
        html += `<p class="dangerous-goods-popover-content">UN ${item.unno}<br/>${item.psn}`;
        const values = new Array<string>();
        if (item.class)
          values.push("Class " + item.class);
        if (item.subLabel1)
          values.push(item.subLabel1);
        if (item.subLabel2)
          values.push(item.subLabel2);
        if (item.pg)
          values.push("Packing Group " + item.pg);
        if (values.length)
          html += "<br /><span class='text-light'>" + values.join(", ") + "</span>";
        html += "</p>";
      }
      return html;
    },
    "ng-dangerous-goods": function (this: HTMLElement) {
      let data = $(this).getJsonFromAttribute<DangerousGoodsData[]>("data-dangerous-goods");
      if (!(data instanceof Array))
        data = [data];
      let html = "";
      for (const item of data.orderByMultiple(g => g.subLabel1, g => g.subLabel2, g => g.packingGroup)) {
        //language=html
        html += `<p class="dangerous-goods-popover-content">UN ${item.unNumber}<br/>${item.properShippingName}`;
        const values = new Array<string>();
        if (item.class)
          values.push("Class " + item.class);
        if (item.subLabel1)
          values.push(item.subLabel1);
        if (item.subLabel2)
          values.push(item.subLabel2);
        if (item.packingGroup)
          values.push("Packing Group " + item.packingGroup);
        if (values.length)
          html += "<br /><span class='text-light'>" + values.join(", ") + "</span>";
        html += "</p>";
      }
      return html;
    },
    "container-info": function (this: HTMLElement) {
      let data = $(this).getJsonFromAttribute<JsonGoodsBoxContainer>("data-container-info");
      let events = "";
      for (const routing of data.routings)
        //language=html
      {
        const eventClass =
          routing.isCompleted
          ? routing == data.routings.last() || data.routings[data.routings.indexOf(routing) + 1].isCompleted
            ? "complete"
            : "progress"
          : "";
        events += `
                    <div class="event ${eventClass}">
                        <div class="line-container">
                            <div class="line">
                                <div class="background"></div>
                            </div>
                        </div>
                        <div class="right-side">
                            <div class="port">
                                <span class="material-icons icon mat-icon-${routing.portTypeCssClass}"></span>
                                ${routing.port} (${routing.portCode})
                                <div class="spriteCountryBorder ${routing.countryCode}" title="${routing.country}"></div>
                            </div>
                        </div>
                    </div>
                `;
      }
      let tableRows = "";
      if (data.type)
        tableRows +=
          `<tr>
                        <th>Container Type</th>
                        <td>${data.type || "-"}</td>
                    </tr>`
      tableRows +=
        `<tr>
                        <th>Seal</th>
                        <td>${data.seal || "-"}</td>
                    </tr>`
      if (typeof data.temperature === "number")
        tableRows +=
          `<tr>
                        <th>Temperature</th>
                        <td>${data.temperature} °${data.temperatureUnit || ""}</td>
                    </tr>`
      //language=html
      return `
        <div class="container-details-popver-content">
          <h4>${data.containerNumber || ""}</h4>
          <div class="timeline-vertical compact">
            ${events}
          </div>
          <table class="table-clean">
            ${tableRows}
          </table>
        </div>
      `;
    },
    "ng-container-info": function (this: HTMLElement) {
      let data = $(this).getJsonFromAttribute<ShipmentContainer>("data-container-info");
      const fieldRenderer = window.ngInjector.get(FieldRenderer);
      let events = "";
      for (let i = 0; i < data.routing.length; i++) {
        const routing = data.routing[i];
        const nextRoutingCompleted = !data.routing[i + 1] || data.routing[i + 1].isCompleted;
        const eventClass =
          routing.isCompleted
          ? nextRoutingCompleted
            ? "complete"
            : "progress"
          : "";
        let port = fieldRenderer.renderPortLocationValue(routing, "UNLOCO","last");
        // if (routing.name) {
        //   port = routing.name;
        //   if (routing.unloco)
        //     port += ` (${routing.unloco})`;
        //   if (port.cou)
        //                         <div class="spriteCountryBorder ${routing.country.isoAlpha2.toLowerCase()}" title="${routing.country.name}"></div>`;
        // }
        events += `
                    <div class="event ${eventClass}">
                        <div class="line-container">
                            <div class="line">
                                <div class="background"></div>
                            </div>
                        </div>
                        <div class="right-side">
                            <div class="port">
                                <span class="material-icons icon mat-icon-directionsboat"></span>
                                ${port}
                            </div>
                        </div>
                    </div>
                `;
      }
      let tableRows = "";
      if (data.type)
        tableRows +=
          `<tr>
                        <th>Container Type</th>
                        <td>${data.type || "–"}</td>
                    </tr>`
      tableRows +=
        `<tr>
                        <th>Seal</th>
                        <td>${data.sealNumber || "–"}</td>
                    </tr>`
      if (data.temperature)
        tableRows +=
          `<tr>
                        <th>Temperature</th>
                        <td>${data.temperature.value} °${data.temperature.dimension}</td>
                    </tr>`
      //language=html
      return `
        <div class="container-details-popver-content">
          <h4>${data.containerNumber || ""}</h4>
          <div class="timeline-vertical compact">
            ${events}
          </div>
          <table class="table-clean">
            ${tableRows}
          </table>
        </div>
      `;
    }
  };
  popup: Popup;
  $trigger: JQuery/*<HTMLElement>*/;

  private readonly _renderer: keyof typeof Popover.renderers;

  constructor(element: HTMLElement, renderer: keyof typeof Popover.renderers = null) {
    this._renderer = renderer;

    this.$trigger = $(element);

    if (!this.$trigger.hasAttr("tabindex"))
      this.$trigger.attr("tabindex", -1);
    this.$trigger
        .click((e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!this.popup.isOpen()) {
            this.open();
            this.popup.$element.addClass("focused");
          } else if (!this.popup.$element.is(".focused"))
            this.popup.$element.addClass("focused");
          else
            this.close();
        })
        .mouseenter(function () {
          $(this).data("hovered", true);
        })
        .mouseleave(function () {
          $(this).data("hovered", false);
        })
        .hoverIntent({
                       over: () => {
                         this.open();
                       },
                       out: () => {
                         if (this.popup.isOpen() && !this.popup.$element.is(".focused") &&
                             !this.popup.$element.is(":hover")) {
                           this.close();
                         }
                       },
                       timeout: 300
                     })
        .data("popover-control", this)
        .removeAttr("title");
    Popover.popovers.push(this);
    this.popup = new Popup(document.createElement("div"), {
      autoPosition: true,
      class: "popover",
      hidingMode: "detach",
      visualContainer: this.$trigger,
      fixAlignment: true,
      horizontalAlignment: Alignment.Center,
      verticalAlignment: Alignment.Center,
      allowGrowing: true,
      autoChangeDirection: true,
      position: this.$trigger.hasData("popover-placement") && false
                ? ("outside-" + this.$trigger.typedData("popover-placement")).toCamelCase() as PopupPosition
                : PopupPosition.OutsideTop
    });
    this.popup.$element
        .attr("tabindex", "0")
        .data("popover-control", this)
        .focusin(() => {
          this.popup.$element.addClass("focused");
        })
        .click(e => e.stopPropagation())
        .hoverIntent({
                       over() {
                       },
                       out: () => {
                         if (this.popup.isOpen() && !this.popup.$element.is(".focused") &&
                             !this.$trigger.is(":hover"))
                           this.close();
                       },
                       timeout: 300
                     });
    this.popup.onRepositioned.on(() => {
      this.popup.$element
          .removeClass("top right bottom left")
          .addClass(this.popup.currentPosition
                        .toString()
                        .replace(/.+([A-Z].+)/, "$1")
                        .toLowerCase());
    })
  }

  close() {
    this.popup.close();
    this.popup.$element.removeClass("focused");
    Popover.openPopovers.remove(this);
  }

  open() {
    if (this.popup.isOpen()) {
      return;
    }
    Popover.openPopovers.forEach(p => p.close());
    let content: string = null;
    const $trigger = this.$trigger;
    if ($trigger.data("popover-target")) {
      content = $($trigger.data("popover-target")).html();
    } else if ($trigger.data("popover-content")) {
      content = $trigger.data("popover-content");
    } else {
      const renderer = this._renderer || $trigger.data("popover-renderer") as keyof typeof Popover.renderers;
      if (window.hasOwnProperty(renderer) && typeof window[renderer as string] === "function") {
        content = window[renderer].call($trigger);
      } else if (Popover.renderers.hasOwnProperty(renderer)) {
        content = Popover.renderers[renderer].call($trigger);
      }
    }
    if (!content)
      return;
    this.popup.$element.html(content);
    this.popup.open();
    Popover.openPopovers.push(this);
  }
}

declare global {
  interface JQuery {
    popover(): JQuery;
  }

  interface Window extends IIndexable<any> {

  }
}

jQuery.fn.popover = function (this: JQuery) {
  return this.each((index, elem: HTMLElement) => {
    const $elem = $(elem);
    if ($elem.data("popover-control"))
      return;
    // ReSharper disable once WrongExpressionStatement
    new Popover(elem);
  });
};
$(window).on("resize-end scroll-end", () => {
  Popover.openPopovers.forEach(p => {
    if (!p.$trigger.is(":visible")) {
      p.close();
    }
  });
});

$(document).click(() => {
  Popover.openPopovers.forEach(p => p.close());
});
