import {ProfileUpdateData} from "@api";
import {Store} from "@stores";
import {Injectable} from "@angular/core";

@Injectable({providedIn: "any"})
export class MyProfileEditUiStore extends Store<ProfileUpdateData> {
  constructor() {
    super({}, {});
  }

  getName(): string {
    return "MyProfileEditUiStore";
  }
}
