<ng-container *ngFor="let leg of shipmentLegs; last as  lastLeg">
  <div class="event"
       [ngClass]="
       {
       progress: leg.progress !== null  && leg.progress >= 0 && leg.progress < 100,
       complete: leg.progress === 100
       }"
  >
    <div class="left-side">
      <ng-container *ngIf="leg.type !== legTypes.Delivery">
        <ng-container *ngIf="leg.startLocation | pureFunction:createTimelineUiData:leg.type:'start' as startDate">
          <ng-container *ngIf="startDate.scheduled; else noDatePopover">
        <span class="date popover-trigger departure"
              [title]="startDate.displayDateLabel"
              [rtPopover]="'ng-timeline'"
              data-popover-placement="top"
              [attr.data-settings]="startDate | json">
          <rt-time-line-date [date]="startDate"></rt-time-line-date>
        </span>
          </ng-container>
          <ng-template #noDatePopover>
        <span class="date departure"
              [title]="startDate.displayDateLabel">
          <rt-time-line-date [date]="startDate"></rt-time-line-date>
        </span>
          </ng-template>
        </ng-container>
      </ng-container>
      <span class="transport">
      {{leg.vessel}}
        <span class="material-icons" [ngClass]="leg | rtTimelineLegTransportModeCssClass"></span>
  </span>
      <ng-container *ngIf="leg.type !== legTypes.Pickup">
        <ng-container *ngIf="leg.endLocation | pureFunction:createTimelineUiData:leg.type:'end' as endDate">
          <ng-container *ngIf="endDate.scheduled; else noDatePopover">
        <span class="date popover-trigger arrival"
              [title]="endDate.displayDateLabel"
              [rtPopover]="'ng-timeline'"
              data-popover-placement="top"
              [attr.data-settings]="endDate | json">
          <rt-time-line-date [date]="endDate"></rt-time-line-date>
        </span>
          </ng-container>
          <ng-template #noDatePopover>
        <span class="date arrival"
              [title]="endDate.displayDateLabel">
          <rt-time-line-date class="arrival" [date]="endDate"></rt-time-line-date>
        </span>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>

    <div class="line-container">
      <div class="line">
        <div *ngIf="leg.progress > 0" class="progress-bar" [ngStyle]="{'height.%': leg.progress}"></div>
        <div class="background"></div>
      </div>
    </div>
    <div class="right-side">
      <rt-vertical-timeline-port [location]="leg.startLocation"></rt-vertical-timeline-port>
      <div class="customs-state" *ngIf="leg.startLocation.customsState as customsState">
        Customs clearance:
        <span [ngClass]="customsState | rtCustomsStateCssClass">{{customsState | rtCustomsStateLabel}}</span>
      </div>
      <address *ngIf="leg.startLocation.location as addressLocation">
        <p [innerHTML]="addressLocation | rtAddress"></p>
        <ng-container *ngIf="leg.type === legTypes.Pickup && (shipment | rtIsFclOrBuyersConsol) && (shipment | pureFunction:getContainers)?.length > 1">
          <span
            *ngIf="shipment |pureFunction:getContainers | pureFunction:getContainerPickupDates as pickupDates"
            class="popover-trigger string-dotted no-focus-outline"
            [rtPopover]="'containerdates'"
            data-popover-placement="top"
            [attr.data-settings]="pickupDates | json">
            {{pickupDates.completedCount}}/{{pickupDates.count}}  containers picked up
          </span>
        </ng-container>
      </address>
    </div>


  </div>
  <div *ngIf="lastLeg" class="event">
    <div class="left-side"></div>
    <div class="right-side">
      <rt-vertical-timeline-port [location]="leg.endLocation"></rt-vertical-timeline-port>
      <div class="customs-state" *ngIf="leg.endLocation.customsState as customsState">
        Customs clearance:
        <span [ngClass]="customsState | rtCustomsStateCssClass">{{customsState | rtCustomsStateLabel}}</span>
      </div>
      <address *ngIf="leg.endLocation.location as addressLocation">
        <p [innerHTML]="addressLocation | rtAddress"></p>
        <ng-container *ngIf="leg.type === legTypes.Delivery && (shipment | rtIsFclOrBuyersConsol) && (shipment |pureFunction:getContainers)?.length > 1">
          <span
            *ngIf="shipment | pureFunction:getContainers | pureFunction:getContainerDeliveryDates as deliveryDates"
            class="popover-trigger string-dotted no-focus-outline"
            [rtPopover]="'containerdates'"
            data-popover-placement="top"
            [attr.data-settings]="deliveryDates | json">
            {{deliveryDates.completedCount}}/{{deliveryDates.count}} containers delivered
        </span>
        </ng-container>
      </address>
    </div>
  </div>
</ng-container>
