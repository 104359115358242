<div class="grid">
  <p class="category-select">
    <select class="select-button"
            ngSelect
            [storeAccessor]="selectedReferenceType"
            [items]="referenceTypeSearchItems$ | async"
            [settings]='{"allowNoSelection":false, "class": "search-select droplist-button width-182px-important", "noScroll":true}'
            data-display-name="Search By">
    </select>
  </p>
  <button class="button mat-icon-search text-hide" (click)="startSearch()">Search</button>
  <p class="input-search">
    <input name="PrimarySearchObject.PrimarySearchNumber"
           type="search"
           [placeholder]="(showMobileSearchHint$ | async)
                 ? (mobileSearchHint$ | async)
                 : 'Search Shipments'"
           (keydown.enter)="startSearch()"
           [ngModel]="selectedReference.observe | async"
           (ngModelChange)="selectedReference.set($event)"/>
  </p>
</div>
