import {BookingModel} from "@booking/BookingModel";
import {Datetimepicker} from "@widgets/Datetimepicker";
import {IView} from "@utils/Angular/View";
import {TemplateSettingsComponent} from "@booking/template-settings.component";
import {ServiceComponent} from "@booking/service.component";
import {PartiesComponent} from "@booking/parties.component";
import {GoodsComponent} from "@booking/goods.component";
import {AdditionalInformationComponent} from "@booking/additional-information.component";
import {SubmitComponent} from "@booking/submit.component";
import {ViewComponent} from "@booking/view.component";
import {BookingMode} from "@services/booking-service-listener.service";
import {TemplateStep} from "@Enums";


const overviewValidator = (model: BookingModel, mode: BookingMode) => {
  return !!(mode === "booking" || model.booking.templateInformation.templateName);
};
const serviceValidator = (model: BookingModel, mode: BookingMode) => {
  const booking = model.booking;
  return !!(booking.direction && booking.transportMode &&
            (booking.transportMode === "AIR" || booking.shipmentType) &&
            (booking.incoTerm || mode === "template"));
}
const partiesValidator = (model: BookingModel, mode: BookingMode) => {
  if (mode === "template") {
    const template = model.booking.templateInformation;
    return !template.pickupAt || !template.deliverAt ||
           Datetimepicker.ConvertToAbsoluteValue(template.pickupAt as any) <
           Datetimepicker.ConvertToAbsoluteValue(template.deliverAt as any);
  } else {
    const booking = model.booking;
    if (!booking.consigneeAddress || !booking.consignorAddress) {
      return false;
    }
    if (booking.templateInformation.pickupAddressType === "customAddress" && !booking.pickupAddress) {
      return false;
    }
    if (booking.templateInformation.deliveryAddressType === "customAddress" && !booking.deliveryAddress) {
      return false;
    }
    if (booking.templateInformation.pickupAddressType === "none" && !booking.portOfLoading)
      return false;
    if (booking.templateInformation.deliveryAddressType === "none" && !booking.portOfDischarge)
      return false;
    if (!booking.pickupAt || (booking.deliverAt && booking.deliverAt < booking.pickupAt)) {
      return false;
    }
  }
  return true;
};
const goodsValidator =
  (model: BookingModel, mode: BookingMode) => {
    if (mode === "template") {
      return true;
    }
    if (model.booking.shipmentType === "FCL") {
      if (!model.booking.goodsDescription) {
        return false;
      }
      const containers = model.containers.filter(c => c !== model.dummyContainer);
      if (!containers.length) {
        return false;
      }
      for (const container of containers) {
        if (!container.containerType || !container.weight || !container.volume) {
          return false;
        }
        if (container.temperatureSpecified && !Number.isNumber(container.temperature)) {
          return false;
        }
        if (container.dangerousGoodsSpecified && !container.dangerousGoods.length) {
          return false;
        }
      }
    } else {
      if (!model.booking.goodsDescription || !model.booking.marksAndNumbers) {
        return false;
      }
      const packages = model.packages.filter(c => c !== model.dummyPackage);
      if (!packages.length) {
        return false;
      }
      for (const item of packages) {
        if (!item.weight || !item.length || !item.width || !item.height || !item.packCount) {
          return false;
        }
        if (item.dangerousGoodsSpecified && !item.dangerousGoods.length) {
          return false;
        }
      }
    }
    return true;
  };
const submitValidator = (model: BookingModel, mode: BookingMode) => {
  return mode === "template" || model.agreedToTerms;
};

const additionalInformationValidator = (model: BookingModel) => {
  return model.files.filter(f => f.state !== "uploaded" || !f.jsonDocument.documentType).length === 0;
};

export const ViewArrangement: IViewDef[] & {activeView?: IView}= [
  {
    address: "booking/overview",
    type: TemplateSettingsComponent,
    title: "Overview",
    visible: false,
    validate: overviewValidator,
    step: "Overview"
  } as IViewDef,
  {
    address: "booking/service",
    type: ServiceComponent,
    title: "Service",
    visible: true,
    validate: serviceValidator,
    step: "Service"
  },
  {
    address: "booking/parties",
    type: PartiesComponent,
    title: "Parties",
    visible: true,
    validate: partiesValidator,
    step: "Parties"
  },
  {
    address: "booking/goods",
    type: GoodsComponent,
    title: "Goods",
    visible: true,
    validate: goodsValidator,
    step: "Goods"
  },
  {
    address: "booking/additional-info",
    type: AdditionalInformationComponent,
    title: "Additional Info",
    visible: true,
    validate: additionalInformationValidator,
    step: "AdditionalInformation"
  },
  {
    address: "booking/submit",
    type: SubmitComponent,
    title: "Submit",
    visible: true,
    validate: submitValidator,
    step: "Submit"
  }
];
Object.defineProperty(ViewArrangement, "activeView", {
  get: function(this:{_activeView: IView | null}): IView | null {
    return this._activeView ?? null;
  },
  set: function(this: {_activeView: IView | null}, value: IView) {
    if (this._activeView === value)
      return;
    this._activeView?.left();
    this._activeView = value;
    value.entered();
  }
})

export interface IViewDef {
  needsValidation?: boolean;
  address: string;
  type: Constructor<any>;
  title: string;
  viewObject?: IView;
  visible: boolean;
  valid?: boolean;
  validate: (model: BookingModel, mode: BookingMode) => boolean;
  isActivated?: boolean;
  step: TemplateStep;
}

export type ViewSearch = string | Constructor<ViewComponent> | IView;


export function GetActiveViewIndex() {
  if (!ViewArrangement.activeView) {
    return -1;
  }
  return GetViewIndex(ViewArrangement.activeView);
}

export function GetViewIndex(addressOrType: ViewSearch): number {
  let matcher: (view: IViewDef) => boolean;
  if (typeof addressOrType === "string") {
    addressOrType = normalizeAddress(addressOrType);
    matcher = v => v.address === addressOrType || v.address.contains("/" + addressOrType);
  } else if (typeof addressOrType === "function") {
    matcher = v => v.type === addressOrType;
  } else {
    matcher = v => addressOrType instanceof v.type;
  }
  for (let i = 0; i < ViewArrangement.length; i++) {
    if (matcher(ViewArrangement[i])) {
      return i;
    }
  }
  return -1;
}

export function SetViewObject(viewObject: IView): void {
  for (const view of ViewArrangement) {
    if (viewObject instanceof view.type) {
      view.viewObject = viewObject;
      return;
    }
  }
}

export function GetNextView(addressOrType: ViewSearch) {
  let index = GetViewIndex(addressOrType);
  if (index === -1) {
    throw "Unable to determine view";
  }
  do {
    index++;
  }
  while (ViewArrangement[index] && ViewArrangement[index].visible === false);
  return ViewArrangement[index];
}

export function GetPreviousView(addressOrType: ViewSearch) {
  let index = GetViewIndex(addressOrType);
  if (index === -1) {
    throw "Unable to determine view";
  }
  do {
    index--;
  }
  while (ViewArrangement[index] && ViewArrangement[index].visible === false);
  return ViewArrangement[index];
}

export function GetView(addressOrType: ViewSearch) {
  return ViewArrangement[GetViewIndex(addressOrType)];
}

function normalizeAddress(address: string = "") {
  address = address.toLowerCase();
  if (address.startsWith("/")) {
    address = address.substr(1);
  }
  return address;
}

export function GetViewObject(addressOrType: ViewSearch): IView {
  const view = GetView(addressOrType);
  if (!view) {
    return null;
  }
  return view.viewObject;
}
