import {ContainerModeSearch, DateSearchType, DeliveryStatus, ShipmentSearchDateType, ShipmentSearchReferenceType, TransportMode} from "@api";

export const referenceTypes: { [P in ShipmentSearchReferenceType]: string } = {
  [ShipmentSearchReferenceType.Any]: "Any",
  [ShipmentSearchReferenceType.BillOfLading]: "Bill of Lading",
  [ShipmentSearchReferenceType.ClientReference]: "Client Reference",
  [ShipmentSearchReferenceType.RohligReference]: "Röhlig Reference",
  [ShipmentSearchReferenceType.OrderNumber]: "Order Number",
  [ShipmentSearchReferenceType.ContainerNumber]: "Container Number",
  [ShipmentSearchReferenceType.ShipperPickupName]: "Shipper / Pickup Name",
  [ShipmentSearchReferenceType.ConsigneeDeliveryName]: "Consignee / Delivery Name",
  [ShipmentSearchReferenceType.InvoiceNumber]: "Invoice Number",
  [ShipmentSearchReferenceType.CustomsEntryNumber]: "Customs Entry Number"
}

export const dateTypes: { [P in ShipmentSearchDateType]: string } = {
  [ShipmentSearchDateType.BookingCreated]: "Booking Submitted",
  [ShipmentSearchDateType.BookingAccepted]: "Booking Accepted",
  [ShipmentSearchDateType.BookingConfirmed]: "Booking Confirmed",
  [ShipmentSearchDateType.EstimatedPickup]: "Estimated Pickup",
  [ShipmentSearchDateType.ActualPickup]: "Actual Pickup",
  [ShipmentSearchDateType.ETD]: "ETD Port of Loading",
  [ShipmentSearchDateType.ATD]: "ATD Port of Loading",
  [ShipmentSearchDateType.ETA]: "ETA Port of Discharge",
  [ShipmentSearchDateType.ATA]: "ATA Port of Discharge",
  [ShipmentSearchDateType.EstimatedDelivery]: "Estimated Delivery",
  [ShipmentSearchDateType.ActualDelivery]: "Actual Delivery"
}


export const deliveryStatuses: { [P in DeliveryStatus]: string } = {
  [DeliveryStatus.Booked]: "Booked",
  [DeliveryStatus.InTransit]: "In Transit",
  [DeliveryStatus.Delivered]: "Delivered",
  [DeliveryStatus.Canceled]: "Canceled"
}

export const transportModes: { [P in TransportMode]: string } = {
  [TransportMode.Sea]: "Sea",
  [TransportMode.Air]: "Air",
  [TransportMode.Road]: "Road",
  [TransportMode.Rail]: "Rail",
  [TransportMode.Courier]: "Courier"

}

export const containerModeSearches: { [P in ContainerModeSearch]: string } = {
  [ContainerModeSearch.FCL]: "FCL/ULD/FTL",
  [ContainerModeSearch.LCL]: "LCL/LSE/LTL",
  [ContainerModeSearch.BuyersConsol]: "Buyer's Consol",
  [ContainerModeSearch.Bulk]: "Bulk",
  [ContainerModeSearch.BreakBulk]: "Break Bulk",
  [ContainerModeSearch.RoRo]: "Roll-on/Roll-off",
  [ContainerModeSearch.Liquid]: "Liquid"
}
