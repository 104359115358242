import { Component } from '@angular/core';
import {StoreDebugHelper} from "@stores/store-debug-helper";

@Component({
  selector: 'rt-error-master',
  templateUrl: './error-master.component.html',
  styleUrls: ['./error-master.component.scss']
})
export class ErrorMasterComponent {

  constructor() {
    if (!window.isProduction)
      StoreDebugHelper.download();
  }

}
