import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {AccountStore, OrderStore, StoreCutoutAccessors, UserRightsStore} from "@stores";
import {Order, OrderLine, OrderSplit, OrderStatus, UserInformation} from "@api";
import {Observable} from "rxjs";
import * as Isotope from "isotope-layout";
import {RtDatePipe} from "@app/utils/rt-date.pipe";
import {OrderService} from "@services";
import {checkScrollMargin, sleep} from "@utils/Utils";
import {delay, takeUntil} from "rxjs/operators";
import {AppBaseComponent} from "@app/appBaseComponent";

@Component({
             selector: 'rt-order-details',
             templateUrl: './order-details.component.html',
             styleUrls: ['./order-details.component.scss']
           })
export class OrderDetailsComponent extends AppBaseComponent {

  readonly boxStateAccessors: StoreCutoutAccessors<UserInformation, "orderBoxOpenStates">;
  readonly hasDocumentRights$: Observable<boolean>;
  readonly hasShipmentRights$: Observable<boolean>;
  readonly hasProductRights$: Observable<boolean>;
  readonly order$: Observable<Order>;
  readonly orderStatuses = OrderStatus;
  private readonly _orderStore: OrderStore;
  private readonly _accountStore: AccountStore;
  private readonly _userRightsStore: UserRightsStore;
  private readonly _elementRef: ElementRef;
  private _iso: Isotope;
  private readonly _datePipe: RtDatePipe;

  constructor(accountStore: AccountStore,
              userRightsStore: UserRightsStore,
              orderStore: OrderStore,
              orderService: OrderService,
              elementRef: ElementRef,
              datePipe: RtDatePipe) {
    super();
    this._datePipe = datePipe;
    this._elementRef = elementRef;
    this._userRightsStore = userRightsStore;
    this._orderStore = orderStore;
    this._accountStore = accountStore;
    this.boxStateAccessors = accountStore.getStoreCutoutAccessors("orderBoxOpenStates", [
      "orderInformation",
      "documents",
      "notes",
      "parties",
      "products",
      "splits"
    ]);

    this.hasDocumentRights$ = userRightsStore.observe("documents");
    this.hasShipmentRights$ = userRightsStore.observe("shipments")
    this.order$ = this._orderStore.observe("currentOrder");
    this.hasProductRights$ = userRightsStore.observe("products");
  }

  @ViewChild("grid")
  set gridRef(value: ElementRef) {
    this._setupIsotype(value?.nativeElement)
  }

  getSplits(order: Order): OrderSplit[] {
    const splits = order.splits.slice();
    splits.unshift({
                     commercialInvoiceNumbers: order.commercialInvoiceNumbers,
                     status: order.status,
                     shipment: order.shipments[0],
                     splitNumber: order.splitNumber as unknown as number
                   });
    return splits;
  }

  getHeader = (group: Grouping<OrderLine, number>): Partial<OrderLine> => {
    if (group.items.length === 1)
      return null;
    const firstLine = group.items[0];
    return {
      quantityDimension: firstLine.quantityDimension,
      productCode: firstLine.productCode,
      quantity: group.items.sum(l => l.quantity),
      lineNumber: firstLine.lineNumber,
      lineStatus: this._mergeOrderStatuses(group.items.map(l => l.lineStatus))
    }
  };

  containsMultiline(groups: Grouping<OrderLine, number>[]): boolean {
    return groups.any(g => g.items.length > 1)
  }

  isMultiline(group: Grouping<OrderLine, number>): boolean {
    return group.items.length > 1;
  }

  groupLines = (order: Order): Grouping<OrderLine, number>[] => {
    const orders = [order].concat(order.splits);

    const lines = orders
      .map(order => order.lines.map(line => {
        return {line, order}
      }))
      .flat()
      .groupBy(l => l.line.lineNumber)
      .orderBy(l => l.key)
      .map(l => this._copyOrderLineGroup(l, orders.length > 1));
    return lines;
  };

  hasNoParties(order: Order): boolean {
    return !order.buyer && !order.supplier
  }

  getCommercialInvoices(order: Order): string[] {
    const invoices: string[] = [];
    invoices.pushRange(order.commercialInvoiceNumbers);
    for (const split of order.splits) {
      invoices.pushRange(split.commercialInvoiceNumbers)
    }
    return invoices.distinct();
  }

  _mergeOrderStatuses(statuses: OrderStatus[]): OrderStatus {
    if (statuses.length <= 1) return statuses[0];

    if (statuses.all(s => s === OrderStatus.Delivered)) return OrderStatus.Delivered;
    if (statuses.all(s => s === OrderStatus.InTransit)) return OrderStatus.InTransit;
    if (statuses.all(s => s === OrderStatus.Booked)) return OrderStatus.Booked;
    if (statuses.all(s => s === OrderStatus.Placed)) return OrderStatus.Placed;
    if (statuses.any(s => s === OrderStatus.Delivered)) return OrderStatus.PartiallyDelivered;
    if (statuses.any(s => s === OrderStatus.InTransit)) return OrderStatus.PartiallyInTransit;
    if (statuses.any(s => s === OrderStatus.Booked)) return OrderStatus.PartiallyBooked;
    return null;
  }

  hasNoOrderLines(order: Order): boolean {
    if (order.lines.any())
      return false;
    for (const split of order.splits) {
      if (split.lines.any())
        return false;
    }
    return true;
  }

  hasNoDocuments(order: Order): boolean {
    return !order.documents?.any();
  }

  @HostListener("window:resize")
  async _updateLayout(): Promise<void> {
    if (!this._iso)
      return;
    await sleep(1);
    this._iso.layout();
    checkScrollMargin();
  }

  ngOnDestroy(): void {
    this._iso?.destroy()
  }

  private _setupIsotype(grid: HTMLElement) {
    this._iso?.destroy();
    if (!grid)
      return;
    this._iso = new Isotope(grid,
                            {
                              percentPosition: false,
                              itemSelector: ".box",
                              layoutMode: "packery",
                              transitionDuration: "0.2s",
                              packery: {
                                gutter: ".grid-gutter"
                              }
                            });
    this._accountStore
        .observe("orderBoxOpenStates")
        .pipe(
          takeUntil(this.destroyed$),
          delay(1)
        )
        .subscribe(() => void this._updateLayout());
  }

  private _copyOrderLineGroup(group: Grouping<{ order: Order, line: OrderLine }, number>, multipleOrders: boolean): Grouping<OrderLine, number> {
    const isMultiLine = group.items.length > 1;
    return {
      key: group.key,
      items: group.items.map(l => this._mergeOrderLine(l.line, l.order, multipleOrders, isMultiLine))
    }

  }

  private _mergeOrderLine(line: OrderLine, order: Order, multipleOrders: boolean, isMultiLine: boolean): OrderLine {
    const copy = Object.assign({}, line);
    if (isMultiLine)
      copy.lineStatus = order.status;
    else if (multipleOrders)
      copy.lineStatus = order.status || line.lineStatus;
    else if (!copy.lineStatus)
      copy.lineStatus = order.status;
    return copy;
  }
}
