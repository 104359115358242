import {Store, StoreProjectionMapping, StoreState} from "@stores/store";

// eslint-disable-next-line @typescript-eslint/ban-types
export class TestStore<T extends StoreState, TP extends StoreState = {}> extends Store<T, TP> {
  getName(): string {
    return "TestStore";
  }


  constructor(projector: StoreProjectionMapping<T, TP>, initialState: Partial<T>) {
    super(projector, initialState);
  }
}
