import {Component, Input, ViewEncapsulation} from '@angular/core';
import {EntitySearchResult} from "@api";
import {SlideInOut} from "@utils/Angular/AnimationTemplates";
import {EntityService} from "@services/entity.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {EntityStore} from "@stores/entity-store.service";

@Component({
             selector: 'rt-entity-set',
             templateUrl: './entity-set.component.html',
             styleUrls: ['./entity-set.component.scss'],
             animations: [SlideInOut()],
             encapsulation: ViewEncapsulation.None
           })
export class EntitySetComponent {

  @Input()
  entity: EntitySearchResult;
  isOpen = true;
  private readonly _entityStore: EntityStore;

  private readonly _entityService: EntityService;

  readonly currentEntityId$: Observable<string>;

  constructor(entityStore: EntityStore, entityService: EntityService) {
    this._entityService = entityService;
    this._entityStore = entityStore;
    this.currentEntityId$ =entityStore.observe("currentEntity")
                                         .pipe(map(e => e?.id))
  }

  readonly unwrapEntities = (entity: EntitySearchResult, indention = 20): { indention: number, entity: EntitySearchResult }[] => {
    const output: { indention: number, entity: EntitySearchResult }[] = [];
    output.push({indention, entity});
    indention += 25;
    output.pushRange(
      entity.childEntities
            .map(e => this.unwrapEntities(e, indention))
            .flat());
    return output;
  };

  openEntitySideBar(entity: EntitySearchResult): void {
    if (this._entityStore.get("currentEntity")?.id === entity.id)
      this._entityStore.update("currentEntity", null)
    else
      void this._entityService.loadEntity(entity.id);
  }

  toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }
}
