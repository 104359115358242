<div class="avatar-upload" (click)="openFileDialog(); $event.stopImmediatePropagation()">
  <div
    class="hint"
    [ngClass]="{invisible: !!picture}"
    [ngStyle]="{opacity: forceHint ? 0.8 : null}">
    <div><p>Drop your picture here or click to upload</p></div>
  </div>
  <img
    [src]="picture | pureFunction:getImageUrl | trustUrl"
    *ngIf="picture"
    class="avatar-picture"
    alt="User avatar">
  <input type="file"
         accept="image/*"
         #fileInput
         (change)="fileInputChange()">
  <a
    class="avatar-remove"
    (click)="removePicture(); $event.stopPropagation()"
    *ngIf="picture">Remove</a>
</div>

