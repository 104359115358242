import {Component} from '@angular/core';
import {MainMasterUiStore} from "./main-master-ui.store";
import {MainMasterUiService} from "./main-master-ui.service";

@Component({
             selector: 'rt-main-master',
             templateUrl: './main-master.component.html',
             styleUrls: ['./main-master.component.scss'],
             providers: [
               MainMasterUiStore,
               MainMasterUiService
             ]
           })
export class MainMasterComponent {
}
