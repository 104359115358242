import {Component} from '@angular/core';
import {StoreAccessor} from "@stores/store-accessor";
import {Observable} from "rxjs";
import {JSONData} from "@api";
import {toJsonData, trimPaste} from "@utils/Utils";
import {ISelectOptions} from "@widgets/Select";
import {AccountStore, UserRightsStore} from "@stores";
import {CountryService} from "@services/country.service";
import {userActivationStatusMapper, userGroupMapper, userGroupSearchMapper} from "@app/utils/enum-display-name-mapper";
import {UserSearchUiStore} from "@app/page-components/account/user-list/user-search-bar/user-search-ui-store.service";
import {UserService} from "@services/user.service";
import {ISelectListItem} from "@widgets/SelectList";
import {SlideInOut} from "@utils/Angular/AnimationTemplates";
import {take} from "rxjs/operators";

@Component({
             selector: 'rt-user-search-bar',
             templateUrl: './user-search-bar.component.html',
             styleUrls: ['./user-search-bar.component.scss'],
             animations: [SlideInOut()],
             host: {
               class: "search-bar"
             }
           })
export class UserSearchBarComponent {

  readonly store: UserSearchUiStore;
  readonly filtersOpen = new StoreAccessor(this, "filtersOpen");
  readonly activationStatus = new StoreAccessor(this, "activationStatus");
  readonly userGroups = new StoreAccessor(this, "userGroups");
  readonly entityIds = new StoreAccessor(this, "entityIds");
  readonly countryCodes = new StoreAccessor(this, "countryCodes");
  readonly reference = new StoreAccessor(this, "reference");
  readonly canCreateUsers$: Observable<boolean>;
  readonly userGroupSearchItems = toJsonData(userGroupSearchMapper);
  readonly activationStatusSearchItems = toJsonData(userActivationStatusMapper);
  readonly countryItems: JSONData[]
  readonly filterCount$: Observable<number>;
  readonly selectSettings = {
    entitySets: {
      class: "multiple",
      search: true,
      placeholderNone: "Any",
      showSelectedValuesOnLoad: true,
      ajaxResultUrl: "/WebMethod/SearchSites",
      multiple: true
    } as ISelectOptions,
    countries: {
      multiple: true,
      placeholderNone: 'Any',
      search: true,
      displayIcon: true,
      class: 'flags'
    } as ISelectOptions,
    default: {
      multiple: true,
      placeholderNone: 'Any',
    } as ISelectOptions,
    single: {
      placeholderNone: 'Any'
    } as ISelectOptions,
  }
  readonly entitySetItemsCache$: Observable<JSONData[]>;
  private readonly _userService: UserService;
  private readonly _accountStore: AccountStore;
  private readonly _countryService: CountryService;

  constructor(userSearchUiStore: UserSearchUiStore,
              userService: UserService,
              accountStore: AccountStore,
              countryService: CountryService,
              userRightsStore: UserRightsStore) {
    this._countryService = countryService;
    this._accountStore = accountStore;
    this._userService = userService;
    this.store = userSearchUiStore;
    this.countryItems = countryService.getJsonData();
    this.filterCount$ = this._userService.userStore.observe("filterCount");
    this.entitySetItemsCache$ = userSearchUiStore.observe("entitySetItemsCache").pipe(take(1));
    this.canCreateUsers$ = userRightsStore.observe("canCreateUsers")
  }

  toggleFiltersOpenState(): void {
    this.store.negate("filtersOpen")
  }

  clearFilters(): void {
    this.store.update({
                        filtersOpen: false,
                        reference: "",
                        entityIds: [],
                        activationStatus: null,
                        userGroups: [],
                        countryCodes: []
                      });
    this.startSearch();
  }

  startSearch(): void {
    const searchObject = this.store.get();
    delete searchObject.filtersOpen;
    this._userService.startSearch(searchObject)
  }

  startSearchAndCloseFilters(): void {
    this.startSearch();
    this.store.update({filtersOpen: false});
  }

  onReferencePaste($event: ClipboardEvent): void {
    trimPaste($event, $event.target as HTMLInputElement);
  }

  addEntitySetToCache(selection: ISelectListItem[]) {
    this.store.update("entitySetItemsCache", selection.map(i => i.ajaxData));
  }
}
