import {EventEmitter} from "../utils/EventEmitter";
import {ControlEvent, RenderedEvent} from "../libs/ExtendableEvent";
import {getRegExpMatchCount} from "../utils/Utils";
import {ValidationField} from "./Validate";

require("../utils/JqueryLoader");

export class ChangePasswordForm implements IControl<JQuery> {
  protected static readonly defaultOptions: IChangePasswordOptions = {
    passwordRequirements: {
      length: 8,
      numbers: 1,
      letters: 1
    },
    enableShowPasswords: true,
    oldPasswordCheck: window.baseApplicationUrl + "/WebMethod/CheckPassword",
    requestIdCheck: window.baseApplicationUrl + "/WebMethod/CheckPasswordRequestId"
  };
  readonly onInit = new EventEmitter<ControlEvent<this>>("init", () => this.eventElements());
  readonly onRendered = new EventEmitter<RenderedEvent<this, JQuery>>("rendered", () => this.eventElements());
  readonly onRendering = new EventEmitter<ControlEvent<this>>("rendering", () => this.eventElements());
  readonly options: IChangePasswordOptions;
  readonly $oldPassword: JQuery/*<HTMLInputElement>*/;
  readonly $requestId: JQuery/*<HTMLInputElement>*/;
  readonly oldPasswordValidationField!: ValidationField;
  readonly newPasswordValidationField: ValidationField;
  readonly confirmNewPasswordValidationField: ValidationField;
  readonly requestIdValidationField!: ValidationField;
  readonly $newPassword: JQuery/*<HTMLInputElement>*/;
  readonly $confirmNewPassword: JQuery/*<HTMLInputElement>*/;
  readonly $passwordQualityChecklist: JQuery/*<HTMLUListElement>*/;
  private $showPasswords: JQuery/*<HTMLInputElement>*/;

  private readonly checkedPasswords = {} as IIndexable<boolean>;
  private readonly checkedRequestIds = {} as IIndexable<boolean>;

  constructor(public readonly $element: JQuery, options?: IChangePasswordOptions) {
    this.options = $.extend({}, ChangePasswordForm.defaultOptions, options);
    if (!this.options.authenticationMode)
      this.options.authenticationMode = this.$element.typedData("authentication-mode") || "oldPassword";
    if (!this.options.header === undefined)
      this.options.header = this.$element.typedData("header");
    this.onRendering.fire(new ControlEvent(this));
    const $content = $(`<header>
                                        <h2>${this.options.header}</h2>
                                    </header>
                                    <form autocomplete="off">
                                      <div class="form-group">
                                          <label for="request-id">Reset Code</label>
                                          <input
                                              autocomplete="off"
                                              type="text"
                                              id="request-id"
                                              name="request-id"
                                              pattern = "^[{(]?[0-9A-Fa-f]{8}[-]?([0-9A-Fa-f]{4}[-]?){3}[0-9A-Fa-f]{12}[)}]?$"
                                              data-validate-pattern-message="The reset code was incorrect."
                                              required />
                                      </div>
                                      <div class="form-group">
                                          <label for="old-password">Old Password</label>
                                          <input type="password" id="old-password" name="old-password" autocomplete=off"/>
                                      </div>
                                      <div class="form-group">
                                          <label for="new-password">New Password</label>
                                          <input type="password" id="new-password" name="new-password" autocomplete="new-password" />
                                      </div>
                                      <div class="form-group" style="display:none;">
                                          <ul id="password-quality-checklist"></ul>
                                      </div>
                                      <div class="form-group">
                                          <label for="confirm-new-password">Repeat New Password</label>
                                          <input
                                              type="password"
                                              id="confirm-new-password"
                                              autocomplete="new-password"
                                              name="confirm-password"
                                              data-validation-group="change-password-form"
                                              data-validate-equal-to="#new-password"
                                              data-validate-equal-to-message="The passwords do not match."
                                              data-display-name="Repetition of new password"/>
                                      </div>
                                      <div class="form-group">
                                          <label for="show-passwords">
                                              <input type="checkbox" id="show-passwords" />
                                              <span class="mat-icon-done"></span>Show passwords
                                          </label>
                                      </div>
                                    </form>`);
    this.$requestId = $content.find("#request-id");
    this.$oldPassword = $content.find("#old-password");
    this.$newPassword = $content.find("#new-password");
    this.$confirmNewPassword = $content.find("#confirm-new-password");
    this.$passwordQualityChecklist = $content.find("#password-quality-checklist");
    this.$showPasswords = $content.find("#show-passwords");
    if (!this.options.header)
      $content.children().first().remove();
    if (!this.options.enableShowPasswords)
      this.$showPasswords.parent().remove();
    else {
      this.$showPasswords.on("change", () => {
        this.getFormFields()
            .prop("type", this.$showPasswords.is(":checked") ? "text" : "password");
      });
    }

    this.$element
        .typedData("change-password-form", this)
        .empty()
        .append($content);
    this.$element
        .parents("form:first")
        .on("realsubmit", () => this.getFormFields()
                                    .attr("type", "text"));

    this.newPasswordValidationField = ValidationField.getValidationField(this.$newPassword[0] as HTMLInputElement);
    this.newPasswordValidationField.validateData.validators["password-quality"] =
      {
        isActive: () => this.hasAnyValue(),
        validator: {
          validateString: () => this.checkPasswordRequirements()
        },
        errorMessage: "The password does not meet the password requirements."
      };
    this.newPasswordValidationField.validateData.validators.required!.isActive =
      () => this.hasAnyValue() || this.options.authenticationMode === "requestId";

    this.confirmNewPasswordValidationField =
      ValidationField.getValidationField(this.$confirmNewPassword[0] as HTMLInputElement);
    this.confirmNewPasswordValidationField.validateData.validators.required!.isActive =
      () => this.hasAnyValue() || this.options.authenticationMode === "requestId";
    this.confirmNewPasswordValidationField.validateData.validators.equalTo!.isActive =
      () => this.hasAnyValue() || this.options.authenticationMode === "requestId";

    if (this.options.authenticationMode === "oldPassword") {
      this.$requestId.parent().remove();

      this.oldPasswordValidationField =
        ValidationField.getValidationField(this.$oldPassword[0] as HTMLInputElement);
      this.oldPasswordValidationField.validateData.validators["password-check"] =
        {
          isActive: true,
          validator: {
            validateString: async (password: string) => {
              if (!this.checkedPasswords.hasOwnProperty(password))
                this.checkedPasswords[password] =
                  await $.getJSON(`${this.options.oldPasswordCheck}?password=${password}`);
              return this.checkedPasswords[password];
            }
          },
          errorMessage: "The password was incorrect."
        };
      this.oldPasswordValidationField.validateData.validators.required!.isActive = () => this.hasAnyValue();
    } else {
      this.$oldPassword.parent().remove();
      this.$requestId.val(this.options.requestId || this.$element.typedData("request-id"));
      this.requestIdValidationField = ValidationField.getValidationField(this.$requestId[0] as HTMLInputElement);
      this.requestIdValidationField.validateData.validators["request-id-check"] =
        {
          isActive: true,
          validator: {
            validateString: async (requestId: string) => {
              if (!this.checkedRequestIds.hasOwnProperty(requestId))
                this.checkedRequestIds[requestId] =
                  await $.getJSON(`${this.options.requestIdCheck}?requestId=${requestId}`);
              return this.checkedRequestIds[requestId];
            }
          },
          errorMessage: () => this.$requestId.typedData("validate-pattern-message")
        };
    }

    this.$newPassword
        .on("input", () => {
          this.checkPasswordRequirements();
          if (this.confirmNewPasswordValidationField.isValid === false)
            this.confirmNewPasswordValidationField.validate();
        })
        .one("focus", () => this.$passwordQualityChecklist.parent().show())
        .on("blur", () => {
          this.$passwordQualityChecklist.toggleClass("validated",
                                                     !!this.$newPassword.val() ||
                                                     this.$newPassword.is(".error"));
        });


    this.checkPasswordRequirements();


  }

  eventElements() {
    return [this.$element];
  }

  hasAnyValue() {
    let result = !!(this.$oldPassword.val() || this.$newPassword.val() || this.$confirmNewPassword.val());
    if (!result && this.options.authenticationMode !== "requestId") {
      if (this.oldPasswordValidationField)
        this.oldPasswordValidationField.resetValidation();
      this.newPasswordValidationField.resetValidation();
      this.confirmNewPasswordValidationField.resetValidation();
      this.$passwordQualityChecklist.removeClass("validated");
    }
    return result;
  }

  checkPasswordRequirements(): boolean {
    const password = this.$newPassword.val() as string;
    const $passwordChecklist = $(document.createElement("ul"));
    const requirements = this.options.passwordRequirements;
    if (requirements.length)
      $passwordChecklist.append(this.createChecklistItem(requirements.length,
                                                         "{0} character[s] minimum",
                                                         /./g));
    if (requirements.numbers)
      $passwordChecklist.append(this.createChecklistItem(requirements.numbers,
                                                         "contains {0} number[s]",
                                                         /\d/g));
    if (requirements.letters)
      $passwordChecklist.append(this.createChecklistItem(requirements.letters,
                                                         "contains {0} letter[s]",
                                                         /[a-z]/ig));
    if (requirements.upperCaseCharacters)
      $passwordChecklist.append(this.createChecklistItem(requirements.upperCaseCharacters,
                                                         "contains {0} upper case letter[s]",
                                                         /[A-Z]/g));
    if (requirements.specialCharacters)
      $passwordChecklist.append(this.createChecklistItem(requirements.specialCharacters,
                                                         "contains {0} special character[s]",
                                                         /\W/g));
    if (requirements.lowerCaseCharacters)
      $passwordChecklist.append(this.createChecklistItem(requirements.lowerCaseCharacters,
                                                         "contains {0} lower case letter[s]",
                                                         /[a-z]/g));
    const $item = $(`<li>cannot contain spaces</li>`).appendTo($passwordChecklist);
    if (!/\s/.test(password))
      $item.addClass("valid");
    this.$passwordQualityChecklist.html($passwordChecklist.html());
    return $passwordChecklist.children(":not(.valid)").length === 0;
  }

  reset() {
    this.confirmNewPasswordValidationField.resetValidation()
    this.newPasswordValidationField.resetValidation()
    this.oldPasswordValidationField?.resetValidation()
    this.requestIdValidationField?.resetValidation()
    this.$confirmNewPassword.val("")
    this.$newPassword.val("")
    this.$requestId?.val("")
    this.$oldPassword?.val("")
    this.$passwordQualityChecklist
        .html("")
        .removeClass("validated");
    Object.keys(this.checkedPasswords).forEach(k => delete this.checkedPasswords[k])
    Object.keys(this.checkedRequestIds).forEach(k => delete this.checkedPasswords[k])
  }

  protected createChecklistItem(requirement: number, text: string, regExp: RegExp) {
    text = text.replace(/\[([^\]])\]/g, requirement > 1 ? "$1" : "");
    const $item = $(`<li>${text.format(requirement.toString())}</li>`);
    if (getRegExpMatchCount(regExp, this.$newPassword.val()) >= requirement)
      $item.addClass("valid");
    return $item;
  }

  protected getFormFields() {
    return $([this.$oldPassword[0], this.$newPassword[0], this.$confirmNewPassword[0]]);
  }
}

interface IChangePasswordOptions {
  authenticationMode?: "oldPassword" | "requestId";
  enableShowPasswords?: boolean;
  passwordRequirements?: {
    numbers?: number;
    letters?: number;
    upperCaseCharacters?: number;
    specialCharacters?: number;
    length?: number;
    lowerCaseCharacters?: number;
  },
  oldPasswordCheck?: string | ((oldPassword: string) => Promise<boolean>);
  requestIdCheck?: string | ((oldPassword: string) => Promise<boolean>);
  header?: string;
  requestId?: string;
}

declare global {
  interface JQuery {
    changePasswordForm(options?: IChangePasswordOptions): JQuery;
  }
}

jQuery.fn.changePasswordForm = function (this: JQuery, options?: IChangePasswordOptions) {
  return this.each((i, elem) => {
    const $elem = $(elem);
    if ($elem.hasData("change-password-form"))
      return;
    new ChangePasswordForm($elem, options);
  });
};

