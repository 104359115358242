import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponseBase} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import * as nprogress from "nprogress";
import {catchError, map} from "rxjs/operators";

@Injectable()
export class ShowLoadingInterceptor implements HttpInterceptor {
  private static semaphore = 0;
  private static _hideLoadingForNextRequest = false;

  constructor() {
    nprogress.configure({showSpinner: false});
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (ShowLoadingInterceptor._hideLoadingForNextRequest) {
      ShowLoadingInterceptor._hideLoadingForNextRequest = false;
      return next.handle(request);
    }
    this.incrementSemaphore();
    return next.handle(request)
               .pipe(
                 map(r => {
                   if (r instanceof HttpResponseBase) {
                     this.decrementSemaphore();
                   }
                   return r;
                 }),
                 catchError((err: HttpErrorResponse) => {
                   this.decrementSemaphore()
                   return throwError(err);
                 }));
  }

  private decrementSemaphore() {
    ShowLoadingInterceptor.semaphore--
    if (ShowLoadingInterceptor.semaphore === 0) {
      nprogress.done();
    }
  }

  private incrementSemaphore() {
    if (ShowLoadingInterceptor.semaphore === 0) {
      nprogress.start();
      nprogress.set(0.2);
    }
    ShowLoadingInterceptor.semaphore++;
  }

  static async runRequestWithoutProgress<T>(request:Observable<T>): Promise<T> {
    this._hideLoadingForNextRequest = true;
    return request.toPromise();
  }
}

export function runRequestWithoutProgress<T>(request:Observable<T>): Promise<T> {
  return ShowLoadingInterceptor.runRequestWithoutProgress(request);
}
