<img class="rohlig-logo" src="/content/images/Rohlig_blue.svg" width="100%" alt="Logo">
<figure class="realtime-logo logologin">
  <a [routerLink]="''">
    <img src="/content/images/Real Time_blue.svg" width="100%" alt="Logo">
  </a>
</figure>

<h1>Reset Your Password</h1>
<ul class="steps forgot-password">
  <li>
    <p>
      <small>Step 1:</small>
      Enter email
    </p>
  </li>
  <li class="end">
    <p>
      <small>Step 2:</small>
      Change password
    </p>
  </li>
</ul>
<div class="complete">
  <p>Your password has been changed.</p>
  <p class="text-center">
    <a routerLink="/" class="button">Back to Home</a>
  </p>
</div>
