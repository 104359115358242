import {Component, Directive, ElementRef, Input, OnInit} from '@angular/core';
import {TypedStoreAccessor} from "@stores/store-accessor";
import {GenerateDefaultOptions} from "../../../libs/DatepickDefaultOptions";
import {concat} from "rxjs";
import {debounceTime} from "rxjs/operators";

export type CalendarDateType = "startDate" | "endDate"

@Directive({
             selector: '[rt-calendar]',
             host: {
               class: "datepicker-calendar"
             }
           })
export class CalendarDirective implements OnInit {

  @Input()
  startDateStoreAccessor: TypedStoreAccessor<Date>;

  @Input()
  endDateStoreAccessor: TypedStoreAccessor<Date>;

  @Input()
  currentDateTypeStoreAccessor: TypedStoreAccessor<CalendarDateType>


  private _$datepicker: JQuery;

  private readonly _elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    this._elementRef = elementRef;
  }

  ngOnInit(): void {
    this._$datepicker = $(this._elementRef.nativeElement);
    this._$datepicker.datepick($.extend(GenerateDefaultOptions(),
                                        {
                                          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                          onSelect: this._dateSelected.bind(this),
                                          onShow: $.datepick.monthNavigation,
                                          rangeSelect: true
                                        }));
    this.currentDateTypeStoreAccessor.subscribe(d => this._dateTypeChanged(d));
    concat(
      this.endDateStoreAccessor.observe,
      this.startDateStoreAccessor.observe
    )
      .pipe(debounceTime(100))
      .subscribe(() => this._updateDates())
  }

  private _dateSelected(dateValue: Date[]) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (dateValue.length === 2 && !this._$datepicker.data("datepick").pickingRange) {
      this.startDateStoreAccessor.set(dateValue[0]);
      this.endDateStoreAccessor.set(dateValue[1]);
      this._$datepicker.datepick("setDate", dateValue[0], dateValue[1]);
    } else {
      if (this.currentDateTypeStoreAccessor.value === "endDate") {
        this.endDateStoreAccessor.set(dateValue[0])
        const startDate = this.startDateStoreAccessor.value;
        const endDate = dateValue[0];
        if (startDate && startDate > endDate) {
          this.startDateStoreAccessor.set(null);
        }
        this._$datepicker.datepick("setDate",
                                   startDate || endDate,
                                   endDate);
      } else {
        const endDate = this.endDateStoreAccessor.value;
        const startDate = dateValue[0];
        this.startDateStoreAccessor.set(startDate);
        this._$datepicker.datepick("setDate", startDate, endDate);
      }
      this.currentDateTypeStoreAccessor.set("endDate")
      this._changeViewToEndDate();
    }
  }

  private _getCurrentMonth() {
    return this._$datepicker.datepick("retrieveDate",
                                      this._$datepicker.find(".datepick-month a:not(.other-month):first")[0]);
  }

  private _dateTypeChanged(type: CalendarDateType) {
    if (type === "endDate")
      this._changeViewToEndDate()
    else
      this._changeViewToStartDate()
  }

  private _changeViewToStartDate() {
    const selectedEndDate = this.endDateStoreAccessor.value;
    this._$datepicker.datepick("option",
                               {
                                 minDate: null,
                                 maxDate: selectedEndDate
                               });
    const selectedStartDate = this.startDateStoreAccessor.value;
    const currentMonth = this._getCurrentMonth();
    if (selectedStartDate)
      this._$datepicker.datepick("showMonth", selectedStartDate);
    else if (selectedEndDate || selectedEndDate > currentMonth)
      this._$datepicker.datepick("showMonth", currentMonth);
  }

  private _changeViewToEndDate() {
    const selectedStartDate = this.startDateStoreAccessor.value;
    this._$datepicker.datepick("option",
                               {
                                 minDate: selectedStartDate,
                                 maxDate: null
                               });
    const selectedEndDate = this.endDateStoreAccessor.value;
    const currentMonth = this._getCurrentMonth();
    if (selectedEndDate)
      this._$datepicker.datepick("showMonth", selectedEndDate);
    else if (selectedStartDate || selectedStartDate > currentMonth)
      this._$datepicker.datepick("showMonth", currentMonth);
  }

  private _updateDates() {
    const startDate = this.startDateStoreAccessor.value;
    const endDate = this.endDateStoreAccessor.value;
    this._$datepicker.datepick("setDate",
                               startDate || endDate,
                               endDate);
  }
}
