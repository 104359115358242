import {Inject, Injectable} from '@angular/core';
import {ViewData} from "@view-data";
import {DOCUMENT} from "@angular/common";
import {RoutingStore} from "@stores";

@Injectable({
              providedIn: 'root'
            })
export class BodyClassService {
  private readonly _document: Document;
  private _currentBodyClasses: string[] = [];

  constructor(@Inject(DOCUMENT) document: Document, routingService: RoutingStore) {
    this._document = document;
    routingService.subscribe("currentViewData", d => this.setBodyClasses(d));
  }

  private setBodyClasses(viewData: ViewData) {
    const newBodyClasses = viewData.bodyClasses;
    const currentBodyClasses = this._currentBodyClasses;
    for (const bodyClass of currentBodyClasses) {
      if (newBodyClasses.indexOf(bodyClass) === -1)
        this._document.body.classList.remove(bodyClass);
    }
    for (const bodyClass of newBodyClasses) {
      this._document.body.classList.add(bodyClass);
    }
    this._currentBodyClasses = newBodyClasses;
  }
}

