import {Component, Input} from '@angular/core';
import {Observable} from "rxjs";
import {TypedStoreAccessor} from "@stores/store-accessor";
import {AppBaseComponent} from "@app/appBaseComponent";
import {filter, map, takeUntil, takeWhile} from "rxjs/operators";
import {HashMap} from "@utils/HashMap";

@Component({
             selector: 'rt-booking-mail-receiver-list',
             templateUrl: './booking-mail-receiver-list.component.html',
             styleUrls: ['./booking-mail-receiver-list.component.scss'],
             host: {
               class: "column col"
             }
           })
export class BookingMailReceiverListComponent extends AppBaseComponent {
  private static controlIndex = -1;
  @Input()
  hasBookingMailAddresses$: Observable<boolean>
  @Input()
  heading: string;
  mailAddresses: { address: string }[];
  focusedItem: { address: string };
  readonly controlIndex = BookingMailReceiverListComponent.controlIndex++;

  private _storeAccessor: TypedStoreAccessor<string[]>;

  get storeAccessor(): TypedStoreAccessor<string[]> {
    return this._storeAccessor;
  }

  @Input()
  set storeAccessor(value: TypedStoreAccessor<string[]>) {
    this._storeAccessor = value;
    if (value)
      value
        .observe
        .pipe(
          takeUntil(this.destroyed$),
          takeWhile(() => this._storeAccessor === value),
          map(addresses => {
            if (!addresses)
              return [];
            return addresses.map(address => {
              return {address: address.replace("@rohlig.com", "")}
            })
          }),
          map(a => a.concat([{address:""}])),
          filter(addresses => !HashMap.hashesEqual(addresses, this.mailAddresses))
        )
        .subscribe((addresses: {address:string}[]) => {
          this.mailAddresses = addresses;
          this.lineInput();
          this.lineInput();
        })
  }

  lineInput(): void {
    let hasEmptyFields = false;
    for (const ref of this.mailAddresses) {
      if (String.isNullOrEmpty(ref.address)) {
        hasEmptyFields = true;
        break;
      }
    }
    if (!hasEmptyFields) {
      this.mailAddresses.push({address:""});
    }
  }

  lineBlur(): void {
    if (this.mailAddresses.length <= 1) {
      return;
    }
    const focusedReference = this.focusedItem;
    this.focusedItem = null;
    let emptyRefCount = 0;
    let trailingEmptyFields = 0;
    for (const ref of this.mailAddresses) {
      if (String.isNullOrEmpty(ref.address)) {
        emptyRefCount++;
        if (ref !== focusedReference) {
          trailingEmptyFields++;
        } else {
          trailingEmptyFields = 0;
        }
      } else {
        trailingEmptyFields = 0;
      }
    }
    if (emptyRefCount === trailingEmptyFields) {
      trailingEmptyFields--;
    }
    for (let i = 0; i < trailingEmptyFields; i++) {
      this.mailAddresses.pop();
    }
    this._storeAccessor.set(this.mailAddresses.filter(a => !!(a?.address)).map(a => a.address + "@rohlig.com"))
  }

  removeItem(mailAddress: { address: string }): void {
    this.mailAddresses.remove(mailAddress);
    this._storeAccessor.set(this.mailAddresses.filter(a => !!(a?.address)).map(a => a.address + "@rohlig.com"))
  }
}
