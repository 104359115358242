require("../utils/JqueryLoader");

export module EqualHeight {
    let groups: { [id: string]: boolean } = { };

    export function add(newElement: HTMLElement) {
        // groups[$(newElement).data('equal-height-group')] = true;
    }

    export function updateAll() {
        // $.each(groups, function (i) {
        //     setTimeout(() => updateGroup(i));
        // });
    }

    export function updateGroup(groupName: string) {
        // if (document.body.classList.contains("open-touch-dropdown"))
        //     return;
        // let height = 0;
        //
        // const $elements = $('[data-equal-height-group="' + groupName + '"]')
        //     .css('height', '')
        //     .each(function (this: HTMLElement) {
        //         height = Math.max(height, $(this).outerHeight());
        //     });
        //
        // $elements.css('height', height);
    }

    export function handler() {
        // add(this);
    }



   export function setRowGroup(container: HTMLElement | JQuery = document.body) {
        // let group = 0;
        // $(".shipment-box", container).each(function (index) {
        //     if (Viewport.width() >= 1900) {
        //         if (index % 4 === 0)
        //             group++;
        //     } else if (Viewport.width() >= 1600) {
        //         if (index % 3 === 0)
        //             group++;
        //     } else if (Viewport.width() >= 720) {
        //         if (index % 2 === 0)
        //             group++;
        //     } else {
        //         group++;
        //     }
        //     jQuery(this).attr("data-equal-height-group", "group-" + group);
        // });
    };

}
