import {List} from "linqts";
import {PopupSelectList} from "./PopupSelectList";
import {ISelectListItem, SelectionChangedEvent} from "./SelectList";
import {ControlDataEvent} from "../libs/ExtendableEvent";
import {Entity} from "../blueEntity";
import {closeSidebar} from "./Sidebar";
import {checkScrollMargin} from "@utils/Utils";
import {JSONData, JSONOrganization, JSONUser} from "@api";

require("../utils/JqueryLoader");

export class eXselect implements IEXselectSettings {
    static settings: IEXselectSettings = {
        canDelete: true,
        unselectable: true,
        dataInfo: null,
        dataLink: null,
        delay: 1000,
        tbodyCheck: 0,
        htmlResult: false
    };
    static texts: IEXselectTexts = {
        headers: ["Name"]
    };

    static ajaxProcessor = (data: JSONUser[] | JSONOrganization[]): JSONData[] => {
        for (let item of data) item.label = item.name;
        return data;
    };

    indicator: JQuery;
    dropdown: JQuery;
    tbody: JQuery;
    request: any;
    canDelete: boolean;
    unselectable: boolean;
    dataInfo: string;
    dataLink: string;
    selection = new List<string>();
    delay: number;
    tbodyCheck: number;
    htmlResult: boolean;
    dataChain: string;
    settings: IEXselectSettings;
    texts: IEXselectTexts;
    $element: JQuery;
    protected readonly select: PopupSelectList<JSONUser | JSONOrganization>;

    constructor(public $selectElement: JQuery, public parent: Entity) {
        // Read and initialization of member variables
        $.extend(true, this, eXselect.settings, this.$selectElement.getJsonFromAttribute("data-settings"));
        this.texts = $.extend(true, {}, eXselect.texts, this.$selectElement.getJsonFromAttribute("data-texts"));

        this.build();

        this.select = new PopupSelectList<JSONUser | JSONOrganization>($selectElement[0] as HTMLSelectElement,
                                                                       {
                                                                           ajaxResultUrl: this.$selectElement.data(
                                                                               "href"),
                                                                           searchDelay: this.delay,
                                                                           ajaxDataPreprocessor: eXselect.ajaxProcessor,
                                                                           multiple: true
                                                                       });
        this.select.onSelectionChanged.on(this.selectionChangedHandler);
        for (const item of this.select.getSelectedItems()) this.itemAdd(item);

        if ($(this.$selectElement).data("href-params")) this.select.onAjaxRequest.on(this.hrefParamsHandler);
    }

    hrefParamsHandler = (e: ControlDataEvent<PopupSelectList, object>) => {
        const hrefParams = this.$selectElement.getJsonFromAttribute("data-href-params") as any;
        $.each(hrefParams,
               (key, value) => {
                   if (value.startsWith("#")) {
                       const val = ($(value)[0] as HTMLInputElement).value;
                       if (val && val.length) {
                           hrefParams[key] = val;
                       } else
                           hrefParams[key] = null;
                   }
               });
        $.extend(e.data, hrefParams);
    };

    selectionChangedHandler =
        (e: SelectionChangedEvent<ISelectListItem<JSONUser | JSONOrganization>, PopupSelectList>) => {
            for (const item of e.addedItems.filter(i => !e.removedItems.contains(i)))
                this.itemAdd(item);
            for (const item of e.removedItems.filter(i => !e.addedItems.contains(i)))
                this.itemRemove(item);
            checkScrollMargin();
        };

    itemAdd = (item: ISelectListItem<JSONUser | JSONOrganization>) => {
        var data = item.ajaxData;
        if (this.selection.Contains(data.value))
            return;
        let row: JQuery;
        row = $(`<tr></tr>`).appendTo(this.tbody);
        item.$option.data("exselect-row", row);
        row.data("select-item", item);
        var deactivated = (data as JSONUser).isActive === false;
        for (let i = 0; i < this.texts.headers.length; i++) {
            const header = this.texts.headers[i];
            const content = (data as any)[header.toLowerCase()] || "";
            const td = $(`<td data-header="${header}">${content}</td>`).appendTo(row);
            if (this.dataLink && i === 0)
                td.html(`<a href="${this.dataLink}?ID=${data.value}">${content}</a>`);
            if (deactivated && header === "Name")
                td.append(" <span class=\"label\">Deactivated</span>");
        }

        // Chain icon
        if (this.dataChain != null) {
            const link = $("<td class=\"link\" data-header=\"\"></td>").appendTo(row);
            if ((data as JSONOrganization).linked) {
                link.html(
                    `<span class="text-hide mat-icon-link" title="${(data as JSONOrganization)
                        .linked}">Linked</span>`);
            }
        }

        // Info action
        if (this.dataInfo != null) {
            const $info =
                $(`<td class="actions" data-header=""><a class="mat-icon-info text-hide info-button toggle-button sidebar-informations-open" data-id="${data.value
                      }" data-href="${
                      this.dataInfo}">Info</a></td>`)
                    .appendTo(row);
            $info.children()
                 .disableWhenOffline();
        }

        // Delete action
        const action = $("<td class=\"actions\" data-header=\"\"></td>").appendTo(row);
        if (this.canDelete && this.select.isEnabled()) {
            $("<a class=\"mat-icon-close text-hide\">Delete</a>")
                .click((e) => {
                    let $elem = $(e.delegateTarget);
                    if ($elem.parent().prev().children().is(".active"))
                        closeSidebar();
                    let selectListItem = $elem.parent().parent().data("select-item") as ISelectListItem;
                    selectListItem.option.selected = false;
                    this.select.syncItems();
                })
                .appendTo(action);
        } else
            action.html("<span>&nbsp;</span>");
        this.selection.Add(data.value);
    };

    itemRemove = (item: ISelectListItem<JSONUser | JSONOrganization>) => {
        (item.$option.data("exselect-row") as JQuery).detach();
        this.selection.Remove(item.ajaxData.value);
    };

    build() {

        this.$element = this.$selectElement.wrap(document.createElement("div")).parent().addClass("eXselect");
        this.$selectElement.typedData("visual-element", this.$selectElement.next());

        // Results table
        const resultsTable = $("<table class=\"table-default table-border table-responsive\"></table>")
            .appendTo(this.$element);
        const header = $(document.createElement("thead")).appendTo(resultsTable);
        const headerRow = $(document.createElement("tr")).appendTo(header);
        $.each(this.texts.headers,
               (key, value) => {
                   headerRow.append(`<th>${value}</th>`);
               });
        // Actions columns
        if (this.dataChain != null)
            headerRow.append("<th class=\"link\">&nbsp;</th>");
        if (this.dataInfo != null)
            headerRow.append("<th class=\"actions\">&nbsp;</th>");
        headerRow.append("<th class=\"actions\">&nbsp;</th>");

        this.tbody = $(`<tbody id="${this.$selectElement.data("id")}"></tbody>`).appendTo(resultsTable);
    }

}

export interface IEXselectSettings {
    canDelete: boolean;
    unselectable: boolean;
    dataInfo: string;
    dataLink: string;
    delay: number;
    tbodyCheck: number;
    htmlResult: boolean;
}

export interface IEXselectTexts {
    headers: string[];
}
