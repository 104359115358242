import {Injectable} from "@angular/core";
import {Store, StoreProjectionMapping} from "@app/stores/store";
import {User, UserActivationStatus, UserSearch, UserSearchResult} from "@api";

interface UserStoreProjections {
  userTitle: string;
  filterCount: number;
  displayedSearchResults: UserSearchResult[] | null;
  moreResultsAvailable: boolean;
}


interface UserStoreState {
  currentUser: User;
  userList: UserSearchResult[];
  currentSearch: Partial<UserSearch>;
  searchResultDisplayCount: number;
  isSearching: boolean;
}


@Injectable({providedIn: "root"})
export class UserStore extends Store<UserStoreState, UserStoreProjections> {
  private static readonly _initialState: Partial<UserStoreState> = {
    currentSearch: {activationStatus: UserActivationStatus.Activated},
    isSearching: false,
  }
  private static readonly _projectionMapping: StoreProjectionMapping<UserStoreState, UserStoreProjections> =
    {
      userTitle: ({currentUser}) => {
        if (!currentUser)
          return null;
        const isNewUser = !currentUser.id;
        if (isNewUser)
          return "Add User"
        if (!currentUser.firstName || !currentUser.lastName)
          return "";
        return `${currentUser.firstName} ${currentUser.lastName}`;
      },
      filterCount: (state) => {
        if (!state.currentSearch)
          return 0;
        const {countryCodes, activationStatus, entityIds, userGroups} = state.currentSearch
        let count = 0;
        if (activationStatus)
          count++;
        count +=
          [countryCodes, entityIds, userGroups]
            .filter(a => a && a.any())
            .length;
        return count;
      },
      displayedSearchResults: u => {
        if (!u.userList)
          return null;
        return u.userList.slice(0, u.searchResultDisplayCount);
      },
      moreResultsAvailable: s => {
        return s.userList && s.userList.length > s.searchResultDisplayCount;
      }
    };

  constructor() {
    super(UserStore._projectionMapping, UserStore._initialState);
  }

  getName(): string {
    return "UserStore";
  }
}


