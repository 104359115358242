import {OrderSearchReferenceType, OrderSearchStatus} from "@api";

export const referenceTypes: { [P in OrderSearchReferenceType]: string } = {
  [OrderSearchReferenceType.Any]: "Any",
  [OrderSearchReferenceType.OrderNumber]: "Order Number",
  [OrderSearchReferenceType.CommercialInvoice]: "Commercial Invoice",
  [OrderSearchReferenceType.ProductCode]: "Product Code",
  [OrderSearchReferenceType.BillOfLading]: "Bill of Lading",
  [OrderSearchReferenceType.RohligReference]: "Röhlig Reference",
  [OrderSearchReferenceType.ContainerNumber]: "Container Number",
  [OrderSearchReferenceType.SupplierName]: "Supplier Name",
  [OrderSearchReferenceType.BuyerName]: "Buyer Name",
}


export const orderSearchStatuses: { [P in OrderSearchStatus]: string } = {
  [OrderSearchStatus.Placed]: "Placed",
  [OrderSearchStatus.Booked]: "Booked",
  [OrderSearchStatus.InTransit]: "In Transit",
  [OrderSearchStatus.Delivered]: "Delivered",
  [OrderSearchStatus.Canceled]: "Canceled",
}

