<img class="rohlig-logo" src="/content/images/Rohlig_blue.svg" width="100%" alt="Logo">
<figure class="realtime-logo logologin">
  <a [routerLink]="''">
    <img src="/content/images/Real Time_blue.svg" alt="Logo">
  </a>
</figure>

<form #requestForm="ngForm" *ngIf="!Summitted" rtFormValidator>
  <div class="requestTitle">
    <a class="button back-button text-center" routerLink="/Account/Login"><span class="mat-icon-back"></span></a>
    <div>
      <h1 class="grid-padding" style="margin:0">Request Access</h1>
    </div>
  </div>
  <div class="request-form-container">
    <label for="FirstName">First Name</label>
    <input id="FirstName" name="FirstName" type="text" [(ngModel)]="firstName" required />
  </div>
  <div class="request-form-container">
    <label for="LastName">Last Name</label>
    <input id="LastName" name="LastName" type="text" [(ngModel)]="lastName" required />
  </div>
  <div class="request-form-container">
    <label for="EmailAddress">Email</label>
    <input id="EmailAddress" name="EmailAddress" type="email" [(ngModel)]="emailAddress" required email data-validate-ascii-chars-mail/>
  </div>
  <div class="request-form-container">
    <label for="CompanyName">Company Name</label>
    <input id="CompanyName" name="CompanyName" type="text" [(ngModel)]="companyName" required />
  </div>
  <div class="request-form-container">
    <label for="selectedCountry">Country</label>
    <select id="selectedCountry"
            name="selectedCountry"
            ngSelect
            [(ngModel)]="selectedCountry"
            [settings]="selectSettings.countries"
            [items]="countryItems"
            required>
    </select>
  </div>
  <div class="request-form-container radio-buttons">
    <input type="radio" id="Customer" name="RoehligCustomer" [(ngModel)]="RoehligCustomer" value="true" radio/>
    <label class="text-left" for="Customer" style="width:192px; margin-right:40px"> I'm a customer of R&ouml;hlig </label>
    <input type="radio" id="NotCustomer" name="RoehligCustomer" [(ngModel)]="RoehligCustomer" value="false" radio/>
    <label class="text-left" for="NotCustomer"> Not a customer yet </label>
    <span class="error-message" *ngIf="RoehligCustomer === 'invalid'">Please select if you are already a customer or not yet.</span>
  </div>
  <p class="costumer-info" *ngIf="RoehligCustomer === 'true'" style="margin-bottom: 15px;">Receive a user account for Real Time.</p>
  <p class="costumer-info" *ngIf="RoehligCustomer === 'false'">Get contacted by one of our sales representatives.</p>
  <label class="optional" [@slideInOut]="RoehligCustomer === 'false' ? 'visible' : 'hidden'" for="InquiryText">Comment</label>
  <textarea class="costumer-info ng-trigger"
            name="InquiryText"
            id="InquiryText"
            [@slideInOut]="RoehligCustomer === 'false' ? 'visible' : 'hidden'"
            placeholder="Do you have additional comments?"
            optional
            [(ngModel)]="InquiryText"></textarea>

  <div>
    <!--<button class="button submit-button" (click)="requestAccount($event)" [disabled]="!requestForm.form.valid">Submit Request</button>-->
    <button class="button submit-button" (click)="requestAccount($event, requestForm)">Submit Request</button>
  </div>
</form>

<div class="request-form-container" *ngIf="Summitted">
  <div class="thank-message">
    <span class="material-icons">
      <img class="green-check-mark" src="/content/images/green_check_mark@2x.png" alt="Logo">
    </span>
    <div>
      <h1 class="text-left">Thank you for your request</h1>
    </div>
  </div>
  <p class="costumer-info thank-you text-left" *ngIf="RoehligCustomer === 'true'">Our team will review your request and provide you with <b>login credentials</b> as soon as possible. In the meantime, why don't you check out the Real Time Demo?</p>
  <p class="costumer-info thank-you text-left" *ngIf="RoehligCustomer === 'false'">A sales representative will be in touch with you soon. In the meantime, why don't you check out the Real Time Demo?</p>
  <a class="button submit-button" href="https://realtime-demo.rohlig.com/">Real Time Demo</a>
  <a class="button submit-button secondary" routerLink="/Account/Login">Close</a>
</div>
