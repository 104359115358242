import {Injectable} from "@angular/core";
import {Store} from "../../../stores";

@Injectable({providedIn: "root"})
export class EntitySearchUiStore extends Store<{ search: string }> {

    constructor() {
        super({}, {search: ""});
    }

    getName(): string {
        return "EntitySearchUiStore";
    }
}
