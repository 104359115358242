import {AdministrationViewData} from "./administration-view-data";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class OnlineMonitorViewData extends AdministrationViewData {

  get pageTitle(): string {
    return TitleBuilder.onlineMonitor;
  }

  get url(): string {
    return UrlBuilder.onlineMonitor;
  }

  get bodyClasses(): string[] {
    return ["page-onlinemonitor"];
  }

}
