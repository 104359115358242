<header class="page-header" #snow>
  <div class="mobile-menu">
    <div class="mat-icon-menu text-hide"
         [ngClass]="{'sidebar-open': userMenuOpen$ | async}"
         (click)="toggleNavMenu()">
      Menu
    </div>
  </div>

  <figure class="logo">
    <a [routerLink]="''">
      <img src="/content/images/Real_Time_white.svg" alt="Rohlig Real Time Logo"/>
    </a>
  </figure>

  <rt-user-box [ngClass]="{'open': userMenuOpen$ | async}"
               (click)="toggleUserMenu()"></rt-user-box>
  <div></div>
</header>
