<ng-container *ngIf="entitySetPermissions">
  <header>
    <h3>{{entitySetPermissions.name | unescapeHtml}}</h3>
    <p *ngIf="entitySetPermissions.canBeManagedByCurrentUser" class="close">
      <a class="button secondary mat-icon-close text-hide delete-item" (click)="requestDelete()">Delete</a>
    </p>
  </header>
  <div class="content">
    <div class="options">
      <label>
        <input type="checkbox"
               [(ngModel)]="entitySetPermissions.exportToExcel"
               [disabled]="!entitySetPermissions.canBeManagedByCurrentUser"/>
        <span class="mat-icon-done"></span>
        Export to Excel
      </label>
      <label>
        <input type="checkbox"
               [(ngModel)]="entitySetPermissions.documents"
               [disabled]="!entitySetPermissions.canBeManagedByCurrentUser"/>
        <span class="mat-icon-done"></span>
        Documents
      </label>
      <label>
        <input type="checkbox"
               [(ngModel)]="entitySetPermissions.products"
               [disabled]="!entitySetPermissions.canBeManagedByCurrentUser"/>
        <span class="mat-icon-done"></span>
        Products
      </label>
      <label>
        <input type="checkbox"
               [(ngModel)]="entitySetPermissions.exceptions"
               [disabled]="!entitySetPermissions.canBeManagedByCurrentUser"/>
        <span class="mat-icon-done"></span>
        Exceptions
      </label>
      <label *ngIf="loggedInAsSuperUser$ | async">
        <input type="checkbox"
               [(ngModel)]="entitySetPermissions.messaging"
               [disabled]="!entitySetPermissions.canBeManagedByCurrentUser"/>
        <span class="mat-icon-done"></span>
        Messaging
      </label>
    </div>
    <rt-user-entity-permission-edit [entityPermissions]="entitySetPermissions"
                                    [hasDocumentsPermissions]="entitySetPermissions.documents"></rt-user-entity-permission-edit>
  </div>
</ng-container>
