
<ng-container *ngIf="shipment">
  <a *ngIf="!!urlLink; else noLink "
     [href]="urlLink"
     (click)="onClickLink($event)"
     [innerHTML]="displayText | rtTbd"></a>

  <ng-template #noLink>
    <span [innerHTML]="displayText | rtTbd"></span>
  </ng-template>
</ng-container>

