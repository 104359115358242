<div class="page-entity">
  <div class="page-wrap">
    <main>
      <section class="padding" *ngIf="entityLoaded$ | async">
        <div class="search-bar button-container no-wrap">
          <div class="button-group static">
            <a routerLink="/SiteManagement"  class="button back-button secondary"><span class="mat-icon-back"></span></a>
          </div>
          <ng-container *ngIf="entityLoaded$ | async">
            <span class="title desktop-visible" *ngIf="isNewEntity$ | async; else existingEntityBreadcrumb">Add Entity</span>
            <ng-template #existingEntityBreadcrumb>
              <span class="title desktop-visible">{{currentName$ | async | unescapeHtml}}</span>
            </ng-template>
          </ng-container>
          <p class="button-group" *ngIf="isNewEntity$ | async | not">
            <button class="toggle-button button secondary"
                    (click)="toggleHistorySidebar()"
                    [ngClass]="{active: historyOpen$ | async}">History
            </button>
          </p>
        </div>
        <form rtFormValidator class="entity-details validate processing-submits">
          <div class="tables">
            <div class="column">
              <div class="entity-header">
                <header>
                  <h2>Entity Details</h2>
                </header>
                <div>
                  <label for="name">Name</label>
                  <input type="text"
                         ngDefaultControl
                         [storeAccessor]="accessors.name"
                         required
                         maxlength="50"
                         id="name"/>
                </div>
                <div class="parent-select">
                  <label class="optional" for="select-entity">Parent Entity</label>
                  <select id="select-entity"
                          ngSelect
                          [settings]="{placeholderNone: '-'}"
                          [items]="parentEntityItems$ | async"
                          [storeAccessor]="accessors.parentEntityId"
                          [searchFunction]="searchParentEntities">
                  </select>
                </div>
                <div class="country-select">
                  <label class="optional" for="select-country">Countries</label>
                  <select id="select-country"
                          multiple="multiple"
                          rtCountrySelect
                          [placeholderNone]="''"
                          [storeAccessor]="accessors.countryCodes">
                  </select>
                </div>
                <div *ngIf="parentEntity$ | async | not">
                  <label for="select-data-period">Data Period</label>
                  <select
                    id="select-data-period"
                    required
                    ngSelect
                    [disabled]="accessors.parentEntityId.observe | async | not | not"
                    [storeAccessor]="accessors.dataPeriod"
                    [items]="dataPeriodItems">
                  </select>
                </div>

                <div *ngIf="parentEntity$ | async as parentEntity">
                  <label>Data Period</label>
                  <input class="disabled" disabled type="text" [value]="parentEntity.dataPeriod | pureFunction:mapDataPeriod"/>
                </div>
              </div>
            </div>
            <div class="column wysiwyg actions">
              <ng-container *ngIf="isNewEntity$ | async | not">
                <header>
                  <h2>Actions</h2>
                </header>
                <p>
                  <a class="button secondary" (click)="openDeleteModal()">Delete Entity</a>
                </p>
                <p>
                  <!--<ng-container *ngIf="parentEntity$ | async | not">
        <button class="button secondary disabled"
                style="cursor: not-allowed;"
        [rtPopover] = "null"
        data-popover-target="#qttInformation">Generate Quick Track & Trace Link</button>
        <div style="display: none;" id="qttInformation">
  <div style="width: 400px; padding: 12px; max-width: 90vw; max-height:50vh; overflow-y: auto;">
            <p>This function is no longer available in the newest version of Quick Track & Trace. From now on all users should access the site via the default link <a target="_blank" href="https://tracking.rohlig.com">tracking.rohlig.com</a>.</p>
            <p style="margin-top:12px;">Customers that already have a custom link can keep using it and will be automatically forwarded to the new version.</p>
  </div>
        </div>
      </ng-container>-->
                  <ng-container *ngIf="quickTrackEntityKey$ | async as key; else generateKey">
                    <label>Quick Track & Trace Link</label>
                    <a class="tracklink" target="_blank" [href]="key | pureFunction:getQuickTrackUrl | trustUrl">
                      {{key | pureFunction:getQuickTrackUrl}}
                    </a>
                  </ng-container>
                  <ng-template #generateKey>
                    <ng-container *ngIf="parentEntity$ | async | not">
                      <button class="button secondary genqkey" (click)="generateQuickTrackKey()">Generate Quick Track & Trace Link</button>
                    </ng-container>
                  </ng-template>
                </p>
              </ng-container>
            </div>
          </div>

          <div class="tables">
            <div class="column">
              <header>
                <h2>Global Account Managers</h2>
              </header>
              <div class="data-view">
                <label for="select-GAM">User</label>
                <div class="eXselect">
                  <select
                    id="select-GAM"
                    multiple="multiple"
                    data-disabled-item-tooltip="User must be Account Manager or Real Time Admin"
                    data-visual-element="next"
                    required="Please select at least one Global Account Manager."
                    [disabled]="accessors.parentEntityId.observe | async | not | not"
                    rtInputValidator
                    rtPopupSelect
                    [searchFunction]="searchGlobalAccountManagers"
                    [items]="globalAccountManagerItems$ | async"
                    [storeAccessor]="accessors.globalAccountManagerIds"
                    (selectionChanged)="globalAccountManagerCache.updateCacheFromSelect($event.addedItems)"
                    [attr.data-settings]='{"delay": 300, "required": true, "tbodyCheck": 250} | json'>
                  </select>
                  <table class="table-default table-border table-responsive" *ngIf="globalAccountManagers$ | async | any as gams">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th class="actions">&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody id="GAM">
                    <tr *ngFor="let user of gams">
                      <td data-header="Name"><a routerLink="/Account/ViewUser" [queryParams]="{ID: user.id}">
                        {{user.name}}
                        <span class="label" *ngIf="!user.isActive">Deactivated</span></a>
                      </td>
                      <td data-header="Email">{{user.mailAddress}}</td>
                      <td class="actions" data-header="">
                        <a class="mat-icon-close text-hide"
                           [ngStyle]="{visibility: (parentEntity$ | async) ? 'hidden' : null}"
                           (click)="globalAccountManagerCache.removeItem(user.id)">Delete</a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="column">
              <header>
                <h2>Local Account Managers</h2>
              </header>
              <div class="data-view">
                <label class="optional" for="select-LAM">User</label>
                <div class="eXselect">
                  <select class="eXselect"
                          id="select-LAM"
                          data-disabled-item-tooltip="User must be Account Manager or Real Time Admin"
                          multiple="multiple"
                          rtPopupSelect
                          [items]="localAccountManagerItems$ | async"
                          [searchFunction]="searchLocalAccountManagers"
                          [storeAccessor]="accessors.localAccountManagerIds"
                          (selectionChanged)="localAccountManagerCache.updateCacheFromSelect($event.addedItems)"
                          [attr.data-settings]='{"delay": 300} | json'>
                  </select>
                  <table class="table-default table-border table-responsive" *ngIf="localAccountManagerCache.selectedItems$ | async | any as lams">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th class="actions">&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody id="LAM">
                    <tr *ngFor="let user of lams">
                      <td data-header="Name"><a routerLink="/Account/ViewUser" [queryParams]="{ID: user.id}">
                        {{user.name}}
                        <span class="label" *ngIf="!user.isActive">Deactivated</span></a>
                      </td>
                      <td data-header="Email">{{user.mailAddress}}</td>
                      <td class="actions" data-header="">
                        <a class="mat-icon-close text-hide" (click)="localAccountManagerCache.removeItem(user.id)">Delete</a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="tables">
            <div class="column" style="margin-top: 20px;">
              <header>
                <h2>Organizations</h2>
              </header>
              <div class="data-view">
                <label class="optional" for="select-organizations">Organization</label>
                <div class="eXselect"><select class="eXselect"
                                              id="select-organizations"
                                              multiple="multiple"
                                              rtPopupSelect
                                              [items]="organizationItems$ | async"
                                              [searchFunction]="searchOrganizations"
                                              [storeAccessor]="accessors.organizationIds"
                                              (selectionChanged)="organizationsCache.updateCacheFromSelect($event.addedItems)"
                                              [attr.data-settings]='{"delay": 300} | json'>
                </select>
                  <table class="table-default table-border table-responsive"
                         *ngIf="organizationsCache.selectedItems$ | async | any as orgs">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Code</th>
                      <th>System</th>
                      <th class="link">&nbsp;</th>
                      <th class="actions">&nbsp;</th>
                      <th class="actions">&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody id="organization">
                    <tr *ngFor="let org of orgs">
                      <td data-header="Name">{{org.name}}</td>
                      <td data-header="Code">{{org.code}}</td>
                      <td data-header="System">{{org.system}}</td>
                      <td class="link" data-header="">
                        <span
                          class="text-hide mat-icon-link"
                          title="{{refs | pureFunction:linkedEntities}}"
                          *ngIf="org.linkedEntityRefs | any as refs">Linked</span>
                      </td>
                      <td class="actions" data-header="">
                        <a class="mat-icon-info text-hide info-button"
                           [ngClass]="{active: (currentSidebarOrganization$ | async)?.id === org.id}"
                           (click)="toggleOrganizationInfoSidebar(org)">Info</a>
                      </td>
                      <td class="actions" data-header="">
                        <a class="mat-icon-close text-hide" (click)="organizationsCache.removeItem(org.id)">Delete</a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


          <div class="tables">
            <div class="column" style="margin-top: 20px;">
              <header>
                <h2>Booking Recipients</h2>
              </header>
            </div>
            <div class="tables">
              <div class="column" style="margin-top: 20px;">
                <button class="button secondary disable-when-offline"
                        [disabled]="savedEntityHasBookingAddresses$ | async | not"
                        (click)="notifyBookingRecipients()"
                        type="button">Send Information Email
                </button>
              </div>
            </div>
            <div class="tables">
              <rt-booking-mail-receiver-list
                [storeAccessor]="accessors.exportSeaBookingMailReceivers"
                heading="Export Sea"
                [hasBookingMailAddresses$]="hasBookingMailAddresses$"></rt-booking-mail-receiver-list>
              <rt-booking-mail-receiver-list
                [storeAccessor]="accessors.exportAirBookingMailReceivers"
                heading="Export Air"
                [hasBookingMailAddresses$]="hasBookingMailAddresses$"></rt-booking-mail-receiver-list>
            </div>
          </div>

          <div class="tables">
            <rt-booking-mail-receiver-list
              [storeAccessor]="accessors.importSeaBookingMailReceivers"
              heading="Import Sea"
              [hasBookingMailAddresses$]="hasBookingMailAddresses$"></rt-booking-mail-receiver-list>
            <rt-booking-mail-receiver-list
              [storeAccessor]="accessors.importAirBookingMailReceivers"
              heading="Import Air"
              [hasBookingMailAddresses$]="hasBookingMailAddresses$"></rt-booking-mail-receiver-list>
          </div>
          <p class="text-light double-column" style="max-width: 900px; margin-top: 20px; margin-bottom: 30px;">Notifications about new Bookings will be sent to
            above email addresses for processing. At least one email address needs to be provided for each of the four scenarios before assigning Booking
            permissions to users.</p>


          <div class="tables">
            <div class="column" style="margin-top: 20px; padding-bottom: 20px;">
              <p class="buttons phone-auto-width">
                <button class="button"
                        [rtLoadingAnimation]="isSaving"
                        loadingText="saving"
                        (click)="save()">Save
                </button>
                <a class="button secondary" routerLink="/SiteManagement">Cancel</a>
              </p>
            </div>
          </div>
        </form>

        <div class="sidebar-informations sidebar-right" [ngClass]="{open: currentSidebarOrganization$ | async}" rtSticky>
          <div class="overflow"></div>
          <div class="sidebar">
            <button class="button secondary mat-icon-close text-hide" (click)="toggleOrganizationInfoSidebar()">Close</button>
            <div class="content">
              <header>
                <h2>Organization Details</h2>
                <p class="close">
                  <button class="button secondary mat-icon-close text-hide" (click)="toggleOrganizationInfoSidebar()">Close</button>
                </p>
              </header>
              <div class="data-view" *ngIf="currentSidebarOrganization$ | async as org">
                <p>
                  <label>Organization Name</label>
                  {{org.name}}
                </p>
                <p>
                  <label>Organization Code</label>
                  {{org.code}}
                </p>
                <p>
                  <label>System Name</label>
                  <span class="label">{{org.system}}</span>
                </p>
                <!--<p style="padding-bottom: 50px;">-->
                <p>
                  <label>Addresses</label>
                  <ng-container *ngFor="let address of org.addresses">
                    <span class="text-light label" style="font-size: 8pt;">{{address.addressCode}}</span><br/>
                    <span [outerHTML]="address | rtAddress"></span>
                    <br/>
                  </ng-container>
                </p>
              </div>

            </div>
          </div>
        </div>


        <rt-history-sidebar [historyOpen]="historyOpen$ | async" [logs]="logs$ | async" (close)="closeHistorySidebar()" rtSticky></rt-history-sidebar>
      </section>
    </main>
  </div>
</div>
