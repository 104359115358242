import {ApplicationRef, Component, ElementRef} from "@angular/core";
import {Router} from "@angular/router";
import {ViewComponent} from "./view.component";
import {SlideInOut} from "@utils/Angular/AnimationTemplates";
import {Title} from "@angular/platform-browser";
import {BookingServiceListener} from "@services/booking-service-listener.service";
import {filter, skip, takeUntil} from "rxjs/operators";
import { hasValue } from "@utils/rxjs-extensions";
import {unescapeHtml} from "../utils/Utils";

@Component({
               selector: "service",
               animations: [
                   SlideInOut()
               ],
               template: `
                 <h2>Overview</h2>
                 <div class="form-group">
                        <span class="form-label">
                        <label for="template-name" class="required">Template Name</label>
                        </span>
                   <input id="template-name" type="text" required
                    [(ngModel)]="model.booking.templateInformation.templateName"
                     />
                 </div>

                 <div class="form-group">
                        <span class="form-label">
                        <label class="optional">Description</label>
                        </span>
                   <textarea style="max-width:400px" type="text" [(ngModel)]="model.booking.templateInformation.description"></textarea>
                 </div>
                 <div class="form-group">
                        <span class="form-label">
                            <label class="required">Visibility</label>
                        </span>
                   <input
                     type="radio"
                     name="visibility"
                     id="visibility-private"
                     [(ngModel)]="model.booking.templateInformation.private"
                     [value]="true"
                     required/>
                   <label for="visibility-private">Private (just me)</label>
                   <input
                     type="radio"
                     name="visibility"
                     id="visibility-shared"
                     [(ngModel)]="model.booking.templateInformation.private"
                     [value]="false"
                   />
                   <label for="visibility-shared">Shared (all people in my company)</label>
                 </div>
                 <div class="form-group" *ngIf="model.booking.templateInformation.createDateString">
                        <span class="form-label">
                            <span class="text-light" style="font-size:12px;">Last edited on {{model.booking.templateInformation.createDateString}}
                              by {{model.booking.templateInformation.createUser}}</span>
                        </span>
                 </div>
                 <div class="buttons phone-auto-width">
                   <button class="button secondary" [disabled]="!canNavigateBack" (click)="navigateBack()">Back
                   </button>
                   <button class="button" type="button" [disabled]="!canNavigateNext" (click)="navigateNext()">
                     Next
                   </button>
                   <button class="button secondary disable-when-offline" (click)="cancel()" type="button">Cancel
                   </button>
                 </div>
               `
           })
export class TemplateSettingsComponent extends ViewComponent {

  readonly name="TemplateSettingsComponent";

    constructor(element: ElementRef,
                app: ApplicationRef,
                router: Router,
                private titleService: Title,
                bookingServiceListener: BookingServiceListener) {
      super(element, app, router, bookingServiceListener);

      this.bookingServiceListener.bookingModel$
          .pipe(
            filter(hasValue),
            skip(1),
            filter(m => this.mode === "template"),
            takeUntil(this.destroyed$)
          )
        .subscribe(() => this.entered());
     
  }
  ngOnInit(): void {
    this.model.booking.templateInformation.templateName = unescapeHtml(this.model.booking.templateInformation.templateName);
    this.model.booking.templateInformation.description = unescapeHtml(this.model.booking.templateInformation.description);
  }

    left() {
        super.left();
        // this.titleService.setTitle(this.model.booking.templateInformation.templateName + " · Röhlig Real Time")
    }
}
