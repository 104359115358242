import {Injectable} from '@angular/core';
import {ErrorViewData} from "@view-data";
import {alertOptions, closeAlert, openAlert} from "@widgets/Alert";
import {filter} from "rxjs/operators";
import {TypeFilter} from "@utils/rxjs-extensions";
import {AccountStore, RoutingStore} from "@stores";

@Injectable({
              providedIn: 'root'
            })
export class AlertService {

  private _alert: JQuery;
  private _closeAlertOnNavigation: boolean;

  constructor(routingStore: RoutingStore, accountStore: AccountStore) {
    routingStore
      .observe("currentViewData")
      .pipe(filter(() => this._closeAlertOnNavigation))
      .subscribe(() => AlertService._closeCurrentAlert());
    routingStore
      .observe("currentViewData")
      .pipe(filter(TypeFilter(ErrorViewData)))
      .subscribe(() => closeAlert(true));
    accountStore
      .observe("name")
      .subscribe(() => closeAlert(true));
  }

  openAlert(options: alertOptions, closeOnNavigation = false): void {
    AlertService._closeCurrentAlert();
    this._alert = openAlert(options);
    this._closeAlertOnNavigation = closeOnNavigation;
  }


  openDefaultSuccessAlert(content: string, closeOnNavigation = true): void {
    this.openAlert({
                     type: "success",
                     content,
                     dontDisplayX: false,
                     timeout: 5000
                   }, closeOnNavigation);
  }

  private static _closeCurrentAlert() {
    closeAlert();
  }
}
