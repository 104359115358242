import { ValidationField } from "./Validate";
import { EventEmitter } from "../utils/EventEmitter";
import { ControlDataEvent, ControlEvent, RenderedEvent } from "../libs/ExtendableEvent"
import { SearchString, SearchStringMatch } from "../libs/SearchString";
import * as Utils from "../utils/Utils"
import { ExtendOptions, GetDatasetEntryName, GetRecursionEnd, IsPointInElement, UnwrapFunction } from "../utils/Utils"

require("../utils/JqueryLoader");

import { JSONData } from "@api";

export type ExternalSearchResult<T extends JSONData = JSONData> = {results: T[], moreResultsAvailable?: boolean};

export interface ISelectListOptions<T extends JSONData = JSONData> {
  /**
   * allow deselect of all values
   * @default true
   */
  allowNoSelection?: boolean;
  /**
   * allow selection of multiple items
   * @default false / is also set to true if the base select control allows multiple
   */
  multiple?: boolean;
  sort?: (items: ISelectListItem<T>[]) => void;
  /**
   * maximum number of selected values
   */
  maxSelectedValues?: number;
  tooMuchValuesSelectedMessage?: string;
  /**
   * minimum number of selected values
   */
  minSelectedValues?: number;
  tooFewValuesSelectedMessage?: string;
  /**
   * display search field
   * @default false
   */
  search?: boolean;
  ajaxResultUrl?: FunctionOrValue<string>;
  externalSearchHandler?: Func1<string, Promise<ExternalSearchResult>>;
  /**
   * show selected values on top
   * @default true
   */
  selectedOnTop?: FunctionOrValue<boolean>;
  /**
   * show selected values without search
   */
  showSelectedValuesOnLoad?: boolean;
  filterFunction?: (filter: string, item: ISelectListItem<T>) => SearchStringMatch[];
  /**
   * maximum number of results to be displayed
   */
  maxResults?: number;
  /**
   * minimum number of characters to perform search
   * @default 1
   */
  minSearchChars?: number;
  searchDelay?: number;
  /**
   * If true search results will be shown in the following order: Pattern is at the beginning of the item, pattern is at a beginning of a word of an item, other result.
   * This is ignored for AJAX results
   * @default false
   */
  enableSearchRanking?: FunctionOrValue<boolean>;
  ajaxDataPreprocessor?: (data: any) => T[];
  displayIcon?: boolean;
  /**
   * message to indicate no search results
   * @default "No results found"
   */
  noResultsPlaceholder?: string,
  /**
   * message asking for entering search string
   * @default 'Please enter {0} or more characters'
   */
  searchStringToShortPlaceholder?: string;
  /**
   * message asking for entering search string when there are already items displayed and no search string is entered yet.
   * @default 'Search for more results'
   */
  searchForMoreResultsPlaceholder?: string;
  /**
   * If the select contains more than this amount of items, the search will be delayed, for better performance
   * @default 10
   */
  minItemsForSearchDelay?: number;
  /**
   * Css classes added to each item
   */
  itemClass?: string;
  /**
   * Css styles added to each item
   */
  itemsCss?: ICssStyles | string;
  /**
   * Css classes added to the list
   */
  selectListClass?: string;
  /**
   * Css styles added to the list
   */
  selectListCss?: ICssStyles;
  /**
   * Placeholder of the search textbox
   * @default 'Search'
   */
  searchPlaceholder?: string;
  /**
   * If true, pressing enter selectd / deselect the current item
   * @default true
   */
  enterToSelect?: boolean;
  /**
   * message to indicate more results than the maximum number displayed
   * @default "Refine search to show additional results"
   */
  additionalResultsPlaceholder?: string;
  /**
   * If true all options will be shown and no scroll bar will be used to limit the height of the list
   * @default false
   */
  noScroll?: boolean;
  /**
   * Class for item text wrapper
   */
  itemTextWrapperClass?: string;
  /**
   * Tooltip that is displayed when an item is hovered by mouse
   */
  itemToolTip?: string | ((item: T) => string);
  /**
   * Tooltip that is displayed when an item is hovered by mouse while disabled
   */
  disabledItemToolTip?: string | ((item: T) => string);
  /**
   * If true, existing items will be updated with new data when they are re-loaded from the server (e.g. when a search is performed)
   * @default true
   */
  enableItemUpdates?: FunctionOrValue<boolean>;
  /**
   * This option mimics the ajax search rendering behaviour. Only the selected items will be rendered and the ones returned by the {@link previewItems} function.
   * @default: true when more than 500 items are loaded
   */
  showOnlyPreviewItemsWithoutSearch?: FunctionOrValue<boolean>;
  /**
   * Limits the number of search results. This does not affect AJAX results.
   * @default: 100
   */
  searchResultLimit?: number;
  /**
   * Items to show when no search string has been entered. Only used, when {@link showOnlyPreviewItemsWithoutSearch} is true. Selected Items will be shown above the results of this function
   * @param items
   */
  previewItems?: (items: ISelectListItem<T>[]) => ISelectListItem<T>[];
  onInitialized?: (select: SelectList<T>) => void;
  /**
   * Options that are send to the server when performing an ajax search
   */
  searchOptions?: FunctionOrValue<object>;

  configureSelectedOption?: Func1<ISelectListItem[], void>;
}

export class SelectList<T extends JSONData = JSONData> {

  static readonly selectListItemName = "select-list-item-index";
  static readonly selectListItemNameCamelCase = GetDatasetEntryName(SelectList.selectListItemName);
  readonly $element: JQuery;
  readonly $selectElement: JQuery;
  readonly onAjaxRequest =
    new EventEmitter<ControlDataEvent<this, { [index: string]: boolean | string | number, options: string, search: string }>>(
      "ajax-request",
      () => this.eventElements());
  readonly onItemHoverIn =
    new EventEmitter<ControlDataEvent<this, ISelectListItem<T>>>("item-hover-in", this.eventElements);
  readonly onItemHoverOut =
    new EventEmitter<ControlDataEvent<this, ISelectListItem<T>>>("item-hover-out", this.eventElements);
  readonly onSelectionChanging: EventEmitter<SelectionChangedEvent<ISelectListItem<T>, this>> =
    new EventEmitter<SelectionChangedEvent<ISelectListItem<T>, this>>("selection-changing",
      () => this.eventElements());
  readonly onSelectionChanged =
    new EventEmitter<SelectionChangedEvent<ISelectListItem<T>, this>>("selection-changed",
      () => this.eventElements());
  readonly onItemsRendered = new EventEmitter<RenderedEvent<this, HTMLLIElement>>("rendered",
    () => this.eventElements());
  readonly onInit = new EventEmitter<ControlEvent<this>>("select-list-init", () => this.eventElements());
  readonly onRendering = new EventEmitter<ControlEvent<this>>("rendering", () => this.eventElements());
  readonly onRendered = new EventEmitter<RenderedEvent<this, JQuery>>("rendered", () => this.eventElements());
  protected defaultOptions: ISelectListOptions = $.extend({
    selectedOnTop: () => this.options.search,
    disabledItemToolTip: () => this.$selectElement.typedData(
      "disabled-item-tooltip")
  }, SelectList.defaultOptions);
  protected loadingCount = 0;
  protected readonly currentItems = new Array<ISelectListItem<T>>();
  protected readonly items = new Array<ISelectListItem<T>>();
  protected $list: JQuery;
  protected $filterTextbox: JQuery;
  protected readonly selectedItems = new Array<ISelectListItem<T>>();
  protected searchTimeout: number;
  protected currentAjaxRequest: JQueryXHR;
  protected highlightedItem: ISelectListItem<T>;
  protected readonly selectionHandler: (e: Event | ISelectListItem<T>) => boolean;
  protected readonly $dummyItem: JQuery;
  protected additionalResultsAvailable = false;
  protected $validationElement: JQuery;
  protected itemsRendered = false;
  private currentExternalSearchFilterRequest: Promise<unknown>;

  constructor(public readonly selectElement: HTMLSelectElement, public readonly options?: ISelectListOptions) {
    this.$selectElement = $(selectElement).data("select", this);
    if (this.$selectElement.data("select-list")) {
      throw "Select-List was already initialized";
    }
    this.options = ExtendOptions(this.defaultOptions, options) as ISelectListOptions;
    if (this.options.showOnlyPreviewItemsWithoutSearch === undefined) {
      this.options.showOnlyPreviewItemsWithoutSearch = () => this.currentItems.length > 1000;
    }
    this.$validationElement = $(document.createElement("span")).addClass("error-message");
    this.$selectElement
      .hide()
      .change((e) => {
        if (!e.originalEvent || e.originalEvent.data !== "no-sync") this.syncItems()
      })
      .on("select-update", () => {
        this.syncItems()
      })
      .on("select-disable", (e) => {
        this.disable(e.data);
      })
      .on("select-enable", () => {
        this.enable()
      })
      .data("select-list", this);
    this.$element = $("<div class=\"select-list\"></div>")
      .css(this.options.selectListCss)
      .addClass(this.options.selectListClass || "")
      .insertAfter(this.$selectElement)
      .data("select-list", this);
    this.$selectElement.typedData("visual-element", this.$element);
    if (typeof this.options.itemsCss === "object") {
      this.options.itemsCss = $("<span>").css(this.options.itemsCss as ICssStyles).attr("style");
    }
    this.$dummyItem = $(document.createElement("li"))
      .addClass(`dummy ${this.options.itemClass}`)
      .attr("style", this.options.itemsCss as string);
    this.onSelectionChanged.on(() => {
      const event = new CustomEvent("change", { bubbles: true, cancelable: false });
      event.data = "no-sync";
      this.$selectElement[0].dispatchEvent(event);
    });
    if (this.options.multiple ||
      (this.$selectElement.attr("multiple") === "multiple" && this.options.multiple !== false)) {
      this.selectionHandler = this.multiSelectHandler;
      if (this.options.multiple) {
        this.$selectElement.attr("multiple", "multiple");
      } else {
        this.options.multiple = true;
      }
    } else {
      this.selectionHandler = this.singleSelectHandler;
      if (this.options.allowNoSelection) {
        this.$selectElement.attr("multiple", "multiple");
      } else {
        this.options.minSelectedValues = 1;
        this.options.maxSelectedValues = 1;
      }
    }
    this.loadItems();
    let angularValue = this.$selectElement.attr("ng-reflect-model");
    if (!this.$selectElement.hasValue() && angularValue) {
      for (const item of this.currentItems) {
        if (item.value === angularValue) {
          item.selected = true;
          break;
        }
      }
    }
    this.renderElement();
    this.onInit.fire(new ControlEvent(this), [this.$element, this.$selectElement]);
    if (this.options.minSelectedValues || this.options.maxSelectedValues) {
      this.activateValidation();
    }
    if (selectElement.disabled) {
      this.disable();
    }
    if (options.onInitialized)
      options.onInitialized(this);
  }

  protected static defaultFilter =
    (filter: string, item: ISelectListItem<any>): SearchStringMatch[] => {
      return item.contentSearchString.getMatches(filter);
    };

  static defaultOptions: ISelectListOptions = {
    allowNoSelection: true,
    search: false,
    filterFunction: SelectList.defaultFilter,
    minSearchChars: 1,
    searchDelay: 100,
    minItemsForSearchDelay: 10,
    searchPlaceholder: "Search",
    noResultsPlaceholder: "No results found",
    searchStringToShortPlaceholder: "Please enter {0} or more characters",
    additionalResultsPlaceholder: "Refine search for more results",
    ajaxDataPreprocessor: Utils.PrepareJsonDataFromServer,
    itemsCss: {},
    selectListCss: {},
    enterToSelect: true,
    showSelectedValuesOnLoad: true,
    tooFewValuesSelectedMessage: "Please select at least {0} items",
    tooMuchValuesSelectedMessage: "Please select at most {0} items",
    noScroll: false,
    enableItemUpdates: true,
    searchResultLimit: 100,
    searchForMoreResultsPlaceholder: "Search for more results"
  };

  getSelectedItems() {
    return this.selectedItems.slice();
  }

  getHighlightedItem() {
    return this.highlightedItem;
  }

  getCurrentItems() {
    return this.currentItems.slice();
  }

  getItems(): ISelectListItem<T>[] {
    return this.items.slice();
  }

  loadItems(justSelectedItems: boolean = false) {
    this.currentItems.clear();
    if (this.$list) {
      this.$list.empty();
    }
    for (let i = 0; i < this.selectElement.options.length; i++) {
      const option = this.selectElement.options[i];
      const $option = $(option);
      if ($option.data("role") === "no-selection-option") {
        continue;
      }
      const existingItem = this.items[$option.data(SelectList.selectListItemName)];
      if (justSelectedItems && !option.selected) {
        continue;
      }
      if (existingItem) {
        this.currentItems.push(existingItem);
      } else {
        this.currentItems.push(this.createItem(option));
      }
    }
  }

  syncItems() {
    const addedItems = new Array<ISelectListItem<T>>();
    const removedItems = new Array<ISelectListItem<T>>();
    for (let item of this.items) {
      const option = item.$option[0] as HTMLOptionElement;
      item.disabled = option.disabled;
      const isSelected = (option).selected;
      if (isSelected !== item.selected) {
        if (isSelected) {
          addedItems.push(item);
        } else {
          removedItems.push(item);
        }
      }
    }
    for (let i = 0; i < this.selectElement.options.length; i++) {
      const option = this.selectElement.options[i];
      const $option = $(option);
      if ($option.data("role") === "no-selection-option") {
        continue;
      }
      if ($option.hasData(SelectList.selectListItemName)) {
        continue;
      }
      const item = this.createItem(option);
      if (item.selected) {
        addedItems.push(item);
      }
    }
    if (!addedItems.length && !removedItems.length) {
      return;
    }
    if (this.onSelectionChanging.fire(new SelectionChangedEvent(this, addedItems, removedItems)).defaultPrevented) {
      this.unselectItems(addedItems);
      this.selectItems(removedItems);
    } else {
      this.unselectItems(removedItems, true);
      this.selectItems(addedItems, true);
      this.renderItems();
      this.onSelectionChanged.fire(new SelectionChangedEvent(this, addedItems, removedItems, false));
    }
  }

  clearFilters() {
    if (this.$filterTextbox) {
      this.$filterTextbox.val("");
    }
    this.applyFilter();
  }

  public toggleItem(item: ISelectListItem<T>) {
    if (item.disabled) {
      return null;
    }
    const removedItems = new Array<ISelectListItem<T>>();
    const addedItems = new Array<ISelectListItem<T>>();
    if (item.selected) {
      if (!this.options.minSelectedValues || this.options.minSelectedValues < this.selectedItems.length) {
        removedItems.push(item);
      }
    } else {
      if (!this.options.maxSelectedValues || this.options.maxSelectedValues > this.selectedItems.length) {
        addedItems.push(item);
      }
    }
    if (addedItems.length === 0 && removedItems.length === 0) {
      return false;
    }
    if (!this.onSelectionChanging.fire(new SelectionChangedEvent(this,
      addedItems,
      removedItems)).defaultPrevented) {
      const itemsUnselected = this.unselectItems(removedItems);
      const itemsSelected = this.selectItems(addedItems);
      if (itemsUnselected || itemsSelected) {
        this.onSelectionChanged.fire(new SelectionChangedEvent(this, addedItems, removedItems, false));
        return true;
      }
    }
    return false;
  }

  public processJsonData(data: Partial<T>[]) {
    const jsonData = this.options.ajaxDataPreprocessor(data) as T[];
    this.currentItems.clear();
    const newlySelectedItems: ISelectListItem<T>[] = [];
    for (let jsonDatum of jsonData) {
       jsonDatum.label = jsonDatum.label?.replace(/</g, "&lt;").replace(/>/g, "&gt;");
      const existingOption = jsonDatum.value && jsonDatum.value.length ?
        $(`option[value='${jsonDatum.value}']`, this.$selectElement)
        : $(`option[value='${jsonDatum.label}']`,
          this.$selectElement);
      if (existingOption.length) {
        const existingItem: ISelectListItem<T> = this.items[existingOption.data(SelectList.selectListItemName)];
        this.updateItem(jsonDatum.value, jsonDatum);
        if (existingItem) {
          this.currentItems.push(existingItem);
        }
      } else {
        const newlySelectedItem = jsonDatum.selected && !this.selectedItems.any(i => i.value === jsonDatum.value);
        const item = this.updateItem(jsonDatum.value, jsonDatum, true);
        this.currentItems.push(item);
        if (newlySelectedItem)
          newlySelectedItems.push(item);
      }
    }
    if (newlySelectedItems.length) {
      this.onSelectionChanged.fire(new SelectionChangedEvent(this, newlySelectedItems, [], false));
    }
    this.itemsRendered = false;
    this.renderItems();
  }

  public addItems(...items: ISelectListItem<T>[]): void;
  public addItems(...items: Partial<ISelectListItem<T>>[]): void;
  public addItems(...items: ISelectListItem<T>[]) {
    this.items.pushRange(items);
    const selectedItems: ISelectListItem<T>[] = [];
    items.map(i => {
      i.$option = $(i.option);
      if (i.selected) {
        this.selectElement.appendChild(i.option);
        selectedItems.push(i);
        i.option.selected = true;
      }
    });
    if (selectedItems.length) {
      this.selectedItems.push(...selectedItems);
      this.onSelectionChanged.fire(new SelectionChangedEvent(this, selectedItems, [], false));
    }
    this.currentItems.pushRange(items);
  }

  getVisibleElementCount() {
    let count = 0;
    const parentRect = this.$list[0].getBoundingClientRect();
    const dropdownItems = this.$list.children(":visible");
    for (let i = 0; i < dropdownItems.length; i++) {
      const elemRect = dropdownItems[i].getBoundingClientRect();
      if (parentRect.top <= elemRect.top && parentRect.bottom >= elemRect.bottom) {
        count++;
      } else if (count > 0) {
        break;
      }
    }
    return count;
  }

  isEnabled() {
    return this.$element.attr("disabled") !== "disabled";
  }

  enable() {
    this.$element.removeAttr("disabled");
    this.$selectElement.removeAttr("disabled");
  }

  disable(preventFormSubmit = false) {
    this.$element.attr("disabled", "disabled");
    this.$selectElement.attr("disabled", preventFormSubmit ? "" : null);
  }

  eventElements() {
    return [this.$element, this.$selectElement];
  }

  getItemFromElement(element: HTMLElement | JQuery) {
    if (element instanceof HTMLElement) {
      return this.items[parseInt($(element).data(SelectList.selectListItemNameCamelCase))];
    }
    return this.items[parseInt(element.data(SelectList.selectListItemNameCamelCase))];
  }

  clearCurrentItems() {
    this.currentItems.clear();
    this.renderItems();
  }

  clearItems() {
    this.items.clear();
    this.unselectItems(true, true);
    this.selectElement.innerHTML = "";
    this.clearCurrentItems();
  }

  /**
   * Updates an existing item with new data
   * @param value The original value of the data
   * @param data THe new data
   * @param createNewItem If true a new item will be created if no existing one is found, else null will be returned
   */
  updateItem(value: string, data: T, createNewItem?: boolean): ISelectListItem<T>;
  updateItem(item: ISelectListItem<T>, data: T, createNewItem?: boolean): ISelectListItem<T>;
  updateItem(value: string | ISelectListItem<T>, data: T, createNewItem = false): ISelectListItem<T> {
    if (typeof value === "string") {
      for (const item of this.items) {
        if (item.value === value) {
          return this.updateItem(item, data, createNewItem);
        }
      }
    } else {
      if (!Utils.UnwrapFunction(this.options.enableItemUpdates)) {
        return value;
      }
      const item = value;
      if (item.value !== data.value) {
        for (const item of this.items) {
          if (item.value === data.value) {
            throw `Item with value '${item.value}' already exists`;
          }
        }
      }

      item.ajaxData = data;
      if (item.contentString !== (data.html || data.label || "")) {
        item.contentString = data.html || data.label || "";
        item.contentSearchString = new SearchString(data.html || data.label || "");
      }
      if (item.value !== data.value) {
        item.normalizedValue = data.value
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
        if (item.option)
          item.option.value = item.value;
      }
      if (typeof data.disabled === "boolean") {
        item.disabled = data.disabled;
        item.option.disabled = data.disabled;
      }
      if (item.selected && data.selected !== false) {
        this.onSelectionChanged.fire(new SelectionChangedEvent(this, [item], [item], false));
      } else if (item.selected && data.selected === false) {
        this.unselectItems([item], false, true);
      } else if (!item.selected && data.selected) {
        this.selectItems([item], false, true);
      }
      if (item.$element) {
        this.onItemsRendered.fire(new RenderedEvent(this, [this.renderItem(item)]));
      }
      return item;
    }
    if (!createNewItem) {
      return null;
    }
    const option = $(document.createElement("option"))
      .attr({
        value: data.value,
        "data-icon": data.icon,
        "data-html": data.html,
        "data-ajax-data": JSON.stringify(data),
        disabled: data.disabled ? "disabled" : undefined
      })
      .text(data.label)
      .prop("selected", data.selected || false)[0] as HTMLOptionElement;
    if (data.selected)
      this.selectElement.appendChild(option);
    return this.createItem(option);
  }

  removeItem(item: ISelectListItem<T>): void;
  removeItem(item: Partial<ISelectListItem<T>>): void;
  removeItem(item: ISelectListItem<T>) {
    if (!item) {
      return;
    }
    if (!this.items.contains(item)) {
      if (!item.value) {
        return;
      }
      item = this.items.firstOrDefault(i => i.value === item.value);
      if (!item) {
        return;
      }
    }
    if (item.selected) {
      this.unselectItems(item, true);
      this.onSelectionChanged.fire(new SelectionChangedEvent(this, [], [item], false));
    }
    this.items.remove(item);
    this.currentItems.remove(item);
    item.option.remove();
    item.$element.remove();
    this.items.forEach((item, index) => {
      item.$option.typedData(SelectList.selectListItemName, index);
      item.$element.typedData(SelectList.selectListItemName, index);
    })
  }

  disableItem(value: string, unselectItem?: boolean): void;
  disableItem(item: ISelectListItem<T>, unselectItem?: boolean): void;
  disableItem(item: string | ISelectListItem<T>, unselectItem = false): void {
    if (typeof item === "string")
      return this.disableItem(this.items.firstOrDefault(i => i.value === item), unselectItem);
    if (!item || item.disabled)
      return;
    item.disabled = true;
    item.ajaxData.disabled = true;
    item.option.disabled = true;
    if (item.$element) {
      item.$element.addClass("disabled");
      let disabledTitle = UnwrapFunction(this.options.disabledItemToolTip, item.ajaxData);
      if (disabledTitle)
        item.$element.attr("title", disabledTitle);
    }
    if (item.selected && unselectItem)
      this.unselectItems(item, true, true);
  }

  enableItem(value: string): void;
  enableItem(item: ISelectListItem<T>): void;
  enableItem(item: string | ISelectListItem<T>): void {
    if (typeof item === "string")
      return this.enableItem(this.items.firstOrDefault(i => i.value === item));
    if (!item || !item.disabled)
      return;
    item.disabled = false;
    item.ajaxData.disabled = false;
    item.option.disabled = false;
    if (item.$element) {
      item.$element.removeClass("disabled");
      item.$element.attr("title", UnwrapFunction(this.options.itemToolTip, item.ajaxData) || "");
    }
  }

  renderElement() {
    if (this.onRendering.fire(new ControlEvent(this)).defaultPrevented) {
      return false;
    }
    if (this.highlightedItem)
      this.highlightItem();
    if (this.hasExternalSearch()) {
      if (this.options.showSelectedValuesOnLoad) {
        this.loadItems(true);
      } else {
        this.currentItems.clear();
      }
      this.currentItems.pushRange(this.items.filter(i => i.selected && !this.currentItems.contains(i)));
    }
    if (this.$element.has(this.selectElement).length) {
      this.$element.empty().append(this.$selectElement);
    } else {
      this.$element.empty();
    }
    if (this.options.search || this.hasExternalSearch()) {
      this.$filterTextbox = $(document.createElement("input"))
        .attr({
          type: "text",
          placeholder: this.options.searchPlaceholder,
          tabindex: -1
        })
        .addClass("small search-box")
        .on("input", () => {
          if (this.$filterTextbox[0] === document.activeElement)
            this.searchBoxInputHandler()
        })
        //.on("paste", (event: Event) => {
        //  Utils.trimPaste(<ClipboardEvent>event.originalEvent, this.$filterTextbox[0] as HTMLInputElement);
        //})
        .on("keydown", (e) => {
          const key = e.key as key;
          if (key === "End" || key === "Home") {
            return;
          }
          this.controlKeyHandler(e);
        })
        .appendTo(this.$element);
      if (this.hasExternalSearch()) {
        this.$filterTextbox.disableWhenOffline();
      }
    }
    this.$list = $(document.createElement("ul"))
      .attr({ tabindex: -1 })
      .appendTo(this.$element)
      //.touchSaveOn("click", "touchend", this.selectionHandler);
      .touchClick((e) => {
        const originalEvent = GetRecursionEnd(e.originalEvent, e => e.originalEvent) as TouchEvent;
        let $target = $(originalEvent.target);
        let $currentTarget = $target;
        while ($currentTarget.length && !$currentTarget.is("li")) {
          $currentTarget = $currentTarget.parent();
        }
        if ($target.is(".dummy") || $currentTarget.length === 0)
          return;

        if ($target.is("span.select-option-configure") && !!this.options.configureSelectedOption) {
          this.options.configureSelectedOption(this.selectedItems);
          return;
        }

        if (originalEvent.type === "touchend") {

          const touch = originalEvent.changedTouches[0];
          if (!IsPointInElement($currentTarget,
            touch.clientX,
            touch.clientY,
            touch.radiusX,
            touch.radiusY)) {
            return;
          }
        }
        if ($target.add($target.parentsUntilInclude(this.$element[0])).is("[data-no-selector]")) {
          return;
        }
        this.selectionHandler(e.originalEvent);
      })
      .on("mouseover",
        (e) => {
          if (e.target instanceof HTMLLIElement) {
            this.highlightItem(e.target as HTMLLIElement);
          } else {
            const $target = $(e.target).parents("li:first");
            if ($target.length) {
              this.highlightItem($target[0]);
            }
          }
        })
      .on("mouseout",
        (e) => {
          if ((e.target instanceof HTMLLIElement || $(e.target).parents("li:first").length)
            && !(e.relatedTarget instanceof HTMLLIElement)) {
            this.highlightItem(undefined);
          }
        });
    if (this.options.noScroll) {
      this.$list.addClass("no-scroll");
    }
    this.renderItems();
    if (this.hasExternalSearch() && this.options.showSelectedValuesOnLoad) {
      this.renderDummy(true);
    }
    this.$list.on("keydown", (e) => {
      this.controlKeyHandler(e)
    });
    this.onRendered.fire(new RenderedEvent<this, JQuery>(this, this.$element));
    return true;
  };

  public unselectItems(force?: boolean, fireEvent?: boolean): boolean;

  public unselectItems(items: ISelectListItem<T>[], force?: boolean, fireEvent?: boolean): boolean;
  public unselectItems(item: ISelectListItem<T>, force?: boolean, fireEvent?: boolean): boolean;

  public unselectItems(items: ISelectListItem<T> | ISelectListItem<T>[] | boolean,
    force = false,
    fireEvent = false): boolean {
    let selectionChanged = false;
    if (typeof items === "boolean") {
      if (typeof force === "boolean") {
        fireEvent = force;
      }
      force = items;
      items = this.getSelectedItems();
    } else if (!items) {
      items = this.getSelectedItems();
    }
    items = items instanceof Array ? items : [items as ISelectListItem<T>];
    for (let item of items) {
      if (item.disabled && !force) {
        continue;
      }
      if (this.selectedItems.indexOf(item) === -1) {
        continue;
      }
      selectionChanged = true;
      item.selected = false;
      item.option.selected = false;
      if (item.$element) {
        item.$element.removeClass("selected");
      }
      this.selectedItems.remove(item);
    }
    if (selectionChanged && fireEvent) {
      this.onSelectionChanged.fire(new SelectionChangedEvent(this, [], items, false));
    }
    return selectionChanged;
  };

  public selectItems(items: ISelectListItem<T>[], force?: boolean, fireEvent?: boolean): boolean;
  public selectItems(item: ISelectListItem<T>, force?: boolean, fireEvent?: boolean): boolean;
  public selectItems(items: ISelectListItem<T> | ISelectListItem<T>[], force = false, fireEvent = false) {
    items = items instanceof Array ? items : [items];
    let selectionChanged = false;
    for (let item of items) {
      if (item.disabled && !force) {
        continue;
      }
      if (!item.option.parentElement) {
        this.selectElement.options.add(item.option);
      }
      if (this.selectedItems.indexOf(item) !== -1) {
        continue;
      }
      selectionChanged = true;
      item.selected = true;
      item.option.selected = true;
      if (item.$element) {
        item.$element.addClass("selected");
      }
      this.selectedItems.push(item);
    }
    if (selectionChanged && fireEvent) {
      this.onSelectionChanged.fire(new SelectionChangedEvent(this, items, [], false));
    }
    return selectionChanged;
  };

  trySelectValue(value: string): boolean {
    const item = this.items.firstOrDefault(i => i.value === value);
    if (!item)
      return false;
    this.unselectItems(true, true);
    this.selectItems(item, true, true);
    return true;
  }

  focus() {
    if (this.options.search || this.hasExternalSearch()) {
      this.$filterTextbox.focus();
    } else {
      this.$list.focus();
    }
  };

  protected activateValidation() {
    let validators = ValidationField.getValidationField(this.selectElement).validateData.validators;
    validators.minValues.constraints = this.options.minSelectedValues;
    validators.minValues.errorMessage =
      () => this.options.tooFewValuesSelectedMessage.format(this.options.minSelectedValues.toString());
    validators.maxValues.constraints = this.options.maxSelectedValues;
    validators.maxValues.errorMessage =
      () => this.options.tooMuchValuesSelectedMessage.format(this.options.maxSelectedValues.toString());
  }

  protected createItem(option: HTMLOptionElement): ISelectListItem<T> {
    const $option = $(option);
    if ($option.hasData(SelectList.selectListItemName)) {
      return this.items[$option.data(SelectList.selectListItemName)];
    }
    let contentString = option.dataset.html || option.innerText;
    
    const item: ISelectListItem<T> = {
      contentString: contentString,
      value: option.value,
      selected: option.selected,
      contentSearchString: new SearchString(contentString),
      normalizedValue: option.value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase(),
      $option: $option,
      option: option,
      disabled: option.disabled
    };
    item.ajaxData = item.$option.getJsonFromAttribute("ajax-data");
    if (item.selected) {
      this.selectedItems.push(item);
    }
    item.$option.data(SelectList.selectListItemName, this.items.length);
    this.items.push(item);
    return item;
  }

  protected showLoading() {
    if (this.loadingCount++ === 0) {
      this.$element.addClass("loading");
    }
  }

  protected hideLoading() {
    if (--this.loadingCount <= 0) {
      this.$element.removeClass("loading");
      this.loadingCount = 0;
    }
  }

  protected getSortedItems() {
    if (UnwrapFunction(this.options.selectedOnTop)) {
      const selectedItems = new Array<ISelectListItem<T>>();
      const unselectedItems = new Array<ISelectListItem<T>>();
      for (let item of this.currentItems) {
        if (item.selected) {
          selectedItems.push(item);
        } else {
          unselectedItems.push(item);
        }
      }
      if (typeof this.options.sort === "function") {
        this.options.sort(selectedItems);
        this.options.sort(unselectedItems);
      }
      return selectedItems.concat(unselectedItems);
    }
    if (typeof this.options.sort === "function") {
      const items = this.currentItems.slice();
      this.options.sort(items);
      return items;
    }
    return this.currentItems.slice();
  }

  protected getFirstSelectableItem(item: ISelectListItem<T>, direction: "up" | "down") {
    const items = this.currentItems;
    if (direction === "up") {
      for (let i = items.indexOf(item); i < items.length; i++) {
        if (this.isSelectable(items[i])) {
          return items[i];
        }
      }
    } else {
      for (let j = Math.min(items.length - 1, items.indexOf(item)); j >= 0; j--) {
        if (this.isSelectable(items[j])) {
          return items[j];
        }
      }
    }
    return null;
  }

  protected isSelectable(item: ISelectListItem<T> | number | HTMLLIElement): boolean {
    if (typeof item === "number") {
      return this.isSelectable(this.currentItems[item]);
    }
    if (item instanceof HTMLLIElement) {
      return this.isSelectable(this.getItemFromElement(item));
    }
    return item.$element && item.$element.isAttached() && !item.disabled && item.$option.is(":not([disabled])");
  }

  protected multiSelectHandler(e: Event | ISelectListItem<T>) {
    let item: ISelectListItem<T>;
    if ((e as Event).target) {
      let $target = $((e as Event).target);
      if ($target.is(".no-selector") || !$target.parents().is(this.$list)) {
        return null;
      }
      while (!$target.parent().is(this.$list)) {
        $target = $target.parent();
        if ($target.is(".no-selector")) {
          return null;
        }
      }
      item = this.getItemFromElement($target);
    } else {
      item = e as ISelectListItem<T>;
    }
    return this.toggleItem(item);
  }

  protected getAjaxResults() {
    const params = {
      search: this.filter(),
      options: JSON.stringify(Object.assign({ maxSearchResults: this.options.maxResults || 10 },
        UnwrapFunction(this.options.searchOptions) || {}))
    };
    this.onAjaxRequest.fire(new ControlDataEvent(this, params, false));
    this.currentAjaxRequest = $.getJSON(UnwrapFunction(this.options.ajaxResultUrl),
      params,
      (data, d, a) => {
        if (a.getResponseHeader("additional-results-available") === "true") {
          this.additionalResultsAvailable = true;
        }
        this.processJsonData(data);
        this.applyFilter();
        this.hideLoading();
      });
    this.currentAjaxRequest
      .fail(() => {
        this.hideLoading()
      })
      .always(() => {
        this.currentAjaxRequest = null;
      });
  }

  protected async getExternalSearchResults() {
    try {
      const search = this.filter();

      const searchRequest = this.options.externalSearchHandler(search);
      this.currentExternalSearchFilterRequest = searchRequest;
      const result = await searchRequest;

      const searchChangedDuringRequest = searchRequest !== this.currentExternalSearchFilterRequest;
      if (searchChangedDuringRequest)
        return;

      this.additionalResultsAvailable = !!result.moreResultsAvailable;
      this.processJsonData(result.results as T[]);
      this.applyFilter();
    } finally {
      this.hideLoading();
    }
  }

  protected filter(): string {
    if (!this.$filterTextbox) {
      return "";
    }
    return this.$filterTextbox.val() || "";
  };

  protected searchBoxInputHandler(applyDelay = true) {
    if (this.searchTimeout) {
      window.clearTimeout(this.searchTimeout);
      this.hideLoading();
      if (this.currentAjaxRequest) {
        console.error("timout and request");
      }
    }
    if (this.currentAjaxRequest) {
      this.currentAjaxRequest.abort();
    }
    this.highlightItem();
    this.additionalResultsAvailable = false;
    const searchDelay =
      applyDelay
        && (this.hasExternalSearch()
          || this.currentItems.length >= (this.options.minItemsForSearchDelay || 0))
        ? this.options.searchDelay
        : 0;
    if (this.filter().length < this.options.minSearchChars) {
      this.searchTimeout = window.setTimeout(() => {
        this.searchTimeout = null;
        this.applyFilter();
      }, searchDelay);
      return false;
    }
    this.showLoading();
    if (UnwrapFunction(this.options.ajaxResultUrl)) {
      this.searchTimeout = window.setTimeout(() => {
        this.searchTimeout = null;
        this.getAjaxResults();
      }, searchDelay);
    } else if (this.options.externalSearchHandler) {
      this.searchTimeout = window.setTimeout(() => {
        this.searchTimeout = null;
        void this.getExternalSearchResults();
      }, searchDelay);
    } else {
      this.searchTimeout = window.setTimeout(() => {
        this.searchTimeout = null;
        this.applyFilter();
      }, searchDelay);
    }
    return true;
  };

  protected renderDummy(text: string): void;
  protected renderDummy(forceSearchHint: boolean): void;
  protected renderDummy(): void;
  protected renderDummy(param?: string | boolean): void {
    this.$dummyItem.detach();
    let dummyText: string;
    const hasResults = (!this.$list.is(":visible") && this.currentItems.length) ||
      this.$list.children().is(":visible");
    if (typeof param === "string") {
      dummyText = param;
    } else if (this.$filterTextbox) {
      const hasFilter = this.filter().length >= (this.options.minSearchChars || 0);
      if (hasFilter) {
        if (!hasResults) {
          dummyText = this.options.noResultsPlaceholder.format(this.filter());
        } else if (this.additionalResultsAvailable) {
          dummyText = this.options.additionalResultsPlaceholder;
        }
      } else {
        if (!hasResults) {
          dummyText =
            this.options.searchStringToShortPlaceholder.format(this.options.minSearchChars.toWord());
        } else if (param === true ||
                   (this.hasExternalSearch() && !hasFilter) ||
                   UnwrapFunction(this.options.showOnlyPreviewItemsWithoutSearch) &&
                   this.currentItems.length !== this.selectedItems.length) {
          dummyText = this.filter().length === 0
            ? this.options.searchForMoreResultsPlaceholder
            : this.options.searchStringToShortPlaceholder.format(this.options.minSearchChars.toWord());
        }
      }
    } else if (!hasResults) {
      dummyText = this.options.noResultsPlaceholder;
    }
    if (dummyText) {
      this.$dummyItem.appendTo(this.$list).html(dummyText);
    }
  };

  protected hasExternalSearch(): boolean {
    return !!UnwrapFunction(this.options.ajaxResultUrl) || !!this.options.externalSearchHandler;
  }

  protected applyFilter() {
    if (this.filter().length < this.options.minSearchChars) {
      if (this.hasExternalSearch()) {
        this.currentItems.clear();
        this.$list.empty();
        if (this.filter() === "" && UnwrapFunction(this.options.showSelectedValuesOnLoad)) {
          this.selectedItems
            //.reverse()
            .forEach(i => {
              this.currentItems.push(i);
              this.$list.append(i.$element);
            });
        }
      } else {
        this.renderItems();
        for (let item of this.currentItems.filter(i => i.contentElement)) {
          item.contentElement.innerHTML = item.contentString || "applyFilter";
          item.hasSearchResultMarking = false;
        }
      }
    } else {
      this.$list.empty();
      let resultCount = 0;
      let searchResults: { item: ISelectListItem<T>, results: SearchStringMatch[] }[];
      if (this.hasExternalSearch()) {
        resultCount = -Infinity;
        searchResults = this.currentItems.map(i => {
          return { item: i, results: this.options.filterFunction(this.filter(), i) }
        })
      } else if (!UnwrapFunction(this.options.enableSearchRanking)) {
        searchResults = new Array<{ item: ISelectListItem<T>, results: SearchStringMatch[] }>();
        for (const item of this.currentItems) {
          const results = this.options.filterFunction(this.filter(), item);
          if (!results.length)
            continue;
          if (++resultCount > (this.options.searchResultLimit || Infinity)) {
            this.additionalResultsAvailable = true;
            break;
          }
          searchResults.push({ item, results });
        }
      } else {
        const firstWordResults = new Array<{ item: ISelectListItem<T>, results: SearchStringMatch[] }>();
        const firstLetterResults = new Array<{ item: ISelectListItem<T>, results: SearchStringMatch[] }>();
        const otherResults = new Array<{ item: ISelectListItem<T>, results: SearchStringMatch[] }>();
        for (const item of this.currentItems) {
          const results = this.options.filterFunction(this.filter(), item);
          if (!results.length)
            continue;
          if (++resultCount > (this.options.searchResultLimit || Infinity)) {
            this.additionalResultsAvailable = true;
            break;
          }
          if (results.firstOrDefault(r => r.isFirstWord))
            firstWordResults.push({ item, results });
          else if (results.firstOrDefault(r => r.isStartOfWord))
            firstLetterResults.push({ item, results });
          else
            otherResults.push({ item, results });
        }
        searchResults = firstWordResults
          .concat(firstLetterResults)
          .concat(otherResults);
      }
      const renderedElements = new Array<HTMLLIElement>();
      for (let searchResult of searchResults) {
        if (searchResult.item.$element) {
          searchResult.item.$element.appendTo(this.$list);
        } else {
          renderedElements.push(this.renderItem(searchResult.item));
        }
        let offset = 0;
        let html = searchResult.item.contentSearchString.toString();
        searchResult.item.hasSearchResultMarking = !!searchResult.results.length;
        for (let result of searchResult.results) {
          if (result.position !== -1) {
            let replacement = "";
            for (let chunk of result.chunks) {
              if (chunk.isHtmlTag) {
                replacement += chunk.text;
              } else {
                replacement += `<mark>${chunk.text}</mark>`;
              }
            }
            const start = result.position + offset;

            html = html.substr(0, start)
              + replacement
              + html.substr(start + result.html.length);
            offset += replacement.length - result.html.length;
          }
        }
        searchResult.item.contentElement.innerHTML = html;
      }
      if (renderedElements.length)
        this.onItemsRendered.fire(new RenderedEvent(this, renderedElements));
    }
    this.renderDummy();
    this.hideLoading();
  }

  protected renderItems() {
    if (!this.$list) {
      return;
    }
    this.$list.empty();
    let items = this.getSortedItems();
    let showOnlyPreviewItemsWithoutSearch = UnwrapFunction(this.options.showOnlyPreviewItemsWithoutSearch);
    if (showOnlyPreviewItemsWithoutSearch) {
      if (this.options.showSelectedValuesOnLoad) {
        items = items.filter(i => i.selected);
      } else {
        items.clear();
      }
      if (this.options.previewItems) {
        const previewItems = this.options.previewItems(this.currentItems) as ISelectListItem<T>[];
        items.pushRange(previewItems.filter(i => !i.selected).reverse());
      }
    }
    const renderedItems: HTMLLIElement[] = [];
    for (let item of items) {
      if (item.$element) {
        item.$element.appendTo(this.$list);
        if (item.hasSearchResultMarking) {
          item.contentElement.innerHTML = item.contentString;
          item.hasSearchResultMarking = false;
        }
        item.$element.toggleClass("selected", !!item.selected);
        item.$element.toggleClass("disabled", !!item.disabled);
      } else {
        renderedItems.push(this.renderItem(item));
      }
    }
    if (renderedItems.length) {
      this.onItemsRendered.fire(new RenderedEvent(this, renderedItems));
    }
    this.renderDummy();
    this.itemsRendered = true;
  };

  protected renderItem(item: ISelectListItem<T>) {
    const $previousItem = item.$element
    let classes = (item.$option.attr("class") || "") + " " + (this.options.itemClass || "");
    if (this.options.displayIcon) {
      let icon = item.$option.attr("data-icon") || item.ajaxData.icon;
      classes += (` icon ${icon}`);
    }
    if (item.disabled) {
      classes += " disabled";
    }
    if (item.selected) {
      classes += " selected";
    }

    const liElement = document.createElement("li");
    if (item.disabled) {
      liElement.title = UnwrapFunction(this.options.disabledItemToolTip, item.ajaxData) || "";
    }
    if (!liElement.title) {
      liElement.title = UnwrapFunction(this.options.itemToolTip, item.ajaxData) || "";
    }
    liElement.tabIndex = -1;
    liElement.className = classes;
    liElement.setAttribute(`data-${SelectList.selectListItemName}`, this.items.indexOf(item).toString());
    liElement.innerHTML = `<span class="item-text ${this.options.itemTextWrapperClass || ""}">${item.contentString}</span>`
    if (this.options.configureSelectedOption) {
      liElement.innerHTML += `<span class="select-option-configure mat-icon-settings"></span>`;
    }

    item.contentElement = liElement.children[0] as HTMLElement;
    item.$element = $(liElement);
    if ($previousItem && this.$list.children().is($previousItem))
      $previousItem.replaceWith(liElement);
    else
      this.$list.append(liElement);
    return liElement;
  }

  protected highlightItem(index?: number | HTMLElement) {
    if (index === undefined) {
      if (this.highlightedItem) {
        this.$list.children().removeClass("highlight");
        this.onItemHoverOut.fire(new ControlDataEvent(this, this.highlightedItem, false));
        this.highlightedItem = null;
      }
    } else if (index instanceof HTMLElement) {
      const item = this.getItemFromElement(index);
      const itemIndex = this.items.indexOf(item);
      if (item === this.highlightedItem) {
        return;
      }
      if (this.highlightedItem) {
        this.highlightedItem.$element.removeClass("highlight");
        this.onItemHoverOut.fire(new ControlDataEvent(this, this.highlightedItem, false));
      }
      this.highlightedItem = item;
      if (itemIndex !== -1) {
        item.$element.addClass("highlight").scrollintoview({ duration: 0 });
        this.onItemHoverIn.fire(new ControlDataEvent(this, this.highlightedItem, false));
      }

    } else {
      index = Math.max(-1, Math.min(this.currentItems.length - 1, index));
      if (index === -1) {
        if (this.$filterTextbox) {
          this.$filterTextbox.focus();
        }
        if (this.highlightedItem) {
          this.highlightedItem.$element.removeClass("highlight");
          this.highlightedItem = null;
        }
      } else {
        const item = this.getItemFromElement(this.$list.children().eq(index));

        if (item === this.highlightedItem) {
          return;
        }
        const direction = this.items.indexOf(item) > this.items.indexOf(this.highlightedItem) ? "up" : "down";
        if (this.highlightedItem) {
          this.highlightedItem.$element.removeClass("highlight");
        }
        this.highlightedItem = this.getFirstSelectableItem(item, direction)
          || this.getFirstSelectableItem(item, direction === "up" ? "down" : "up");
        this.highlightedItem.$element.addClass("highlight").scrollintoview({ duration: 0 });
      }
    }
  };

  protected singleSelectHandler(e: Event | ISelectListItem<T>): boolean {
    let item: ISelectListItem<T>;
    if ((e as Event).target) {
      let $target = $((e as Event).target);
      if ($target.is(".no-selector") || !$target.parents().is(this.$list)) {
        return false;
      }
      while (!$target.parent().is(this.$list)) {
        $target = $target.parent();
        if ($target.is(".no-selector")) {
          return false;
        }
      }
      item = this.getItemFromElement($target);
      if (!item) {
        return false;
      }
    } else {
      item = e as ISelectListItem<T>;
    }
    if (item.disabled) {
      return null;
    }
    let removedItems = new Array<ISelectListItem<T>>();
    const addedItems = new Array<ISelectListItem<T>>();
    if (item.selected) {
      if (!this.options.minSelectedValues) {
        removedItems.push(item);
      }
    } else {
      removedItems = this.selectedItems.slice(0);
      addedItems.push(item);
    }
    if (removedItems.length === 0 && addedItems.length === 0) {
      return false;
    }
    if (!this.onSelectionChanging.fire(new SelectionChangedEvent(this,
      addedItems,
      removedItems)).defaultPrevented) {
      const selectedItems = this.selectItems(addedItems);
      const unselectedItems = this.unselectItems(removedItems);
      if (selectedItems || unselectedItems) {
        this.onSelectionChanged.fire(new SelectionChangedEvent(this, addedItems, removedItems, false));
        return true;
      }
    }
    return false;
  };

  /**
   * Method to add extra key handlers.
   * @param {key} key
   * @returns {boolean} If true is returned no further key handling is applied and the original key event is stopped
   */
  protected controlKeyHandlerExtender(key: key): boolean {
    return false;
  }

  protected controlKeyHandler(e: JQueryKeyEventObject) {
    let keyHandled = true;
    if (!this.controlKeyHandlerExtender(e.key)) {
      // noinspection FallThroughInSwitchStatementJS
      switch (e.key as key) {
        case "ArrowDown":
        case "Down":
          this.highlightItem(this.currentItems.indexOf(this.highlightedItem) + 1);
          break;
        case "ArrowUp":
        case "Up":
          this.highlightItem(this.currentItems.indexOf(this.highlightedItem) - 1);
          break;
        case "PageUp":
          this.highlightItem(Math.max(this.currentItems.indexOf(this.highlightedItem) -
            this.getVisibleElementCount(), 0));
          break;
        case "PageDown":
          this.highlightItem(this.currentItems.indexOf(this.highlightedItem) + this.getVisibleElementCount());
          break;
        case "Home":
          this.highlightItem(0);
          break;
        case "End":
          this.highlightItem(Number.POSITIVE_INFINITY);
          break;
        // @ts-ignore
        case "Enter":
          if (!this.options.enterToSelect) {
            keyHandled = false;
            break;
          }
        case " ":
        case "Spacebar":
          if (e.target && $(e.target).is("input,button,select")) {
            keyHandled = false;
          } else if (this.highlightedItem) {
            //item.$element.click();
            this.selectionHandler(this.highlightedItem);
          }
          break;
        default:
          keyHandled = false;
      }
    }
    if (keyHandled) {
      if (this.highlightedItem && e.target && $(e.target).is("input,button,select")) {
        this.$list.focus();
      }
      e.preventDefault();
      e.stopPropagation();
      return false;
    } else if (this.$list.is(":focus") && this.$filterTextbox) {
      this.$filterTextbox /*.val(this.$filterTextbox.val() + e.key)*/.focus();
    }
    return true;
  };
}

export class SelectionChangedEvent<TI, TC> extends ControlEvent<TC> {

  constructor(control: TC,
    public readonly addedItems: TI[],
    public readonly removedItems: TI[],
    cancelable?: boolean) {
    super(control, cancelable);
  }
}


export interface ISelectListItem<T extends JSONData = JSONData> {
  value: string;
  normalizedValue: string;
  contentString: string;
  contentSearchString: SearchString;
  selected: boolean;
  $option: JQuery;
  option: HTMLOptionElement;
  $element?: JQuery;
  contentElement?: HTMLElement;
  ajaxData?: T;
  disabled?: boolean;
  hasSearchResultMarking?: boolean;
}

declare global {
  interface JQuery {
    selectList(options?: ISelectListOptions): JQuery;

    selectList(loadDataSettings: true): JQuery;
  }
}

jQuery.fn.selectList = function (this: JQuery, options?: ISelectListOptions | true) {
  return this.each((index, elem) => {
    const $elem = $(elem);
    if ($elem.data("select-list")) {
      return;
    }
    if (options === true) {
      options = $elem.getJsonFromAttribute<ISelectListOptions>("data-select-list-settings")
        || $elem.getJsonFromAttribute<ISelectListOptions>("data-settings");
    }
    // ReSharper disable once WrongExpressionStatement
    new SelectList(elem as HTMLSelectElement, options);
  });
};
