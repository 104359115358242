import {UserViewData} from "./user-view-data";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class UserListViewData extends UserViewData {

  get pageTitle(): string {
    return TitleBuilder.account.users;
  }

  get url(): string {
    return UrlBuilder.account.users;
  }

  get bodyClasses(): string[] {
    return ["page-user-list"];
  }

}
