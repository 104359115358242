import {StringKeyedEventEmitter} from "./EventEmitter";
import {DataEvent} from "../libs/ExtendableEvent";
import {createDeepCopy} from "@utils/createDeepCopy";

export class Cache<T> {
  /**
   * This event is fired when any item is updated in the cache (or removed) and contains a list all updated values (null for removed elements or not updated values)
   */
  onInvalidated = new StringKeyedEventEmitter<DataEvent<{ [index: string]: T }>>("invalidated");

  protected readonly cache = {} as { [index: string]: ICacheItem<T> };
  protected readonly cacheTime: number;
  protected readonly cacheTimers = {} as { [index: string]: number };

  constructor(cacheTime: Date) {
    this.cacheTime = (cacheTime.getTime() - new Date(0, 0, 0, 0, 0, 0).getTime());
  }

  async getCachedItem(key: string): Promise<T> {
    const cacheItem = this.cache[key];
    if (cacheItem) {
      if (new Date().getTime() - cacheItem.cacheDate.getTime() <= this.cacheTime) {
        return createDeepCopy(cacheItem.data);
      } else if (cacheItem.promise)
        return await cacheItem.promise.then(createDeepCopy);
    }
    return null;
  }

  setCachedItem(key: string, data: T) {
    const cacheItem = this.cache[key];
    if (cacheItem) {
      const eventData = {} as { [index: string]: T };
      eventData[key] = data;
      this.onInvalidated.fire(key, new DataEvent(eventData, false));
    }
    if (this.cacheTimers[key])
      window.clearTimeout(this.cacheTimers[key]);
    this.cache[key] = {data: data, key: key, cacheDate: new Date()};
    //this.cacheTimers[key] = window.setTimeout(() => this.invalidateCache(key));
  }

  getCacheItemState(key: string): "not-cached" | "loading" | "outdated" | "cached" {
    const cacheItem = this.cache[key];
    if (cacheItem) {
      if (new Date().getTime() - cacheItem.cacheDate.getTime() <= this.cacheTime)
        return "cached";
      else if (cacheItem.promise)
        return "loading";
      else
        return "outdated";

    }
    return "not-cached";
  }

  invalidateCache(key?: string) {
    if (key) {
      const eventData = {} as { [index: string]: T };
      eventData[key] = null;
      if (this.getCacheItemState(key) !== "cached")
        return;
      this.cache[key].cacheDate = new Date(0);
      this.onInvalidated.fire(key, new DataEvent(eventData, false));
    } else {
      const eventData = {} as { [index: string]: T };
      const keys = new Array<string>();
      for (let key in this.cache) {
        if (!this.cache.hasOwnProperty(key))
          continue;
        if (this.getCacheItemState(key) !== "cached")
          continue;
        // this.cache[key].cacheDate = new Date(0, 0, 0);
        delete this.cache[key];
        eventData[key] = null;
        keys.push(key);
      }
      this.onInvalidated.fire(keys, new DataEvent(eventData, false));
    }
  }

  async reloadCacheItem(key: string, promise: Promise<T>) {
    const cacheItem: ICacheItem<T> = this.cache[key] || (this.cache[key] = {cacheDate: new Date(0, 0, 0), key: key});
    cacheItem.promise = promise;
    let data = await promise;
    this.setCachedItem(key, data);
    return createDeepCopy(data);
  }
}

interface ICacheItem<T> {
  cacheDate: Date;
  data?: T;
  key: string;
  promise?: Promise<T>;
}
