import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CommercialInvoice, InvoiceDisputeData, InvoiceDisputeSetting, ShipmentDocument} from "@api";
import { Modal } from '../../../../../widgets/modal';
import { AccountStore, UserRightsStore, ShipmentStore} from "@stores";
import { AccountService } from "@services";
import { SettingsService } from "@services/settings.service";
import { AlertService } from "@services/alert.service";
import { Observable } from 'rxjs';

//type InvoiceDisputeData = {mailAddress: string, intMailAddress: string, name: string, company: string, invoiceNumber: string,
  //rohligRef: string, amount: string, charge: string, reason: string, currency: string, phone: string }

@Component({
  selector: 'rt-shipment-documents',
  templateUrl: './shipment-documents.component.html',
  styleUrls: ['./shipment-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShipmentDocumentsComponent {
  private readonly _accountService: AccountService;
  private readonly _accountStore: AccountStore;
  private readonly _shipmentStore: ShipmentStore;
  private readonly _userRightsStore: UserRightsStore;
  private readonly _alertService: AlertService;
  chargeInDispute: string = "";
  amount: string = "";
  reason: string = "";
  private invoiceNumber: string = "";
  private invoiceCurrency: string = "";
  private readonly rohligRef: string;
  private readonly mail: string;
  private readonly phone: string;
  private readonly name: string;
  private readonly company: string;
  private readonly companyId: string;
   readonly country: string;
   invoiceDisputeSetting$: Observable<InvoiceDisputeSetting[]>;

  constructor(accountStore: AccountStore, accountService: AccountService, userRightsStore: UserRightsStore, shipmentStore: ShipmentStore, settingsService: SettingsService, alertService: AlertService) {
    this._userRightsStore = userRightsStore;
    this._accountStore = accountStore;
    this._shipmentStore = shipmentStore;
    this._accountService = accountService;
    this._alertService = alertService;
    this.invoiceDisputeSetting$ = settingsService.getInvoiceSetting();
    this.rohligRef = this._shipmentStore.get("currentShipment").houseBill;
    this.mail = this._accountStore.get("mail");
    this.phone = this._accountStore.get("phone");
    this.name = this._accountStore.get("name");
    this.company = this._accountStore.get("currentEntitySet").name;
    this.companyId = this._accountStore.get("currentEntitySet").id;
    this.country = this._accountStore.get("country")?.isoAlpha2 || "";
  }
  @Input()
  documents: ShipmentDocument[][];

  @Input()
  invoices: CommercialInvoice[];
  hasDisputeInvoiceRights(invoiceSettings: InvoiceDisputeSetting[]): boolean {
    return invoiceSettings.some(i => i.country == this.country) && this._userRightsStore.get("invoices");
  }

  filterInvoices(documents: CommercialInvoice[]): Grouping<CommercialInvoice, string>[] {
    if (!documents)
      return null;
    return documents
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
      .groupBy<CommercialInvoice, string>((i: CommercialInvoice) => i.invoiceNumber)
  }
  openLodgeInvoiceModal(invoiceKey: Grouping<CommercialInvoice, string>): void {
    this.invoiceNumber = invoiceKey.key;
    this.invoiceCurrency = this.invoices.find(i => i.invoiceNumber == this.invoiceNumber).invoiceCurrency;

    let modal: Modal;
    // eslint-disable-next-line prefer-const
    modal = new Modal(null, {
      header: "Lodge Dispute",
      html: `<form style="min-width:350px">
            <table style="text-align: right;margin-bottom: 10px;">
              <tr style="height: 30px;">
                <td><label style="margin-bottom: 0;">Invoice Number:</label></td>
                <td style="width: 100px;"><b>${this.invoiceNumber}</b></td>
              </tr>
              <tr style="height: 30px;">
                <td><label style="margin-bottom: 0;">Airway Bill:</label></td>
                <td><b>${this.rohligRef}</b></td>
              </tr>
            </table>
              <div >
                <label for="ChargeInDispute">Charge in Dispute</label>
                <input id="ChargeInDispute" name="ChargeInDispute" type="text" required />
              </div>
              <div>
                <label for="AmountInDispute">Amount in Dispute</label>
                <div style="display: flex;align-items: center;flex-wrap: wrap;">
                  <input id="AmountInDispute" name="AmountInDispute" type="number" required />
                  <p style="position: relative; left: 83%;bottom: 30px;">${this.invoiceCurrency}</p>
                </div>
               </div>
              <div class="request-form-container">
                <label for="Reason">Reason</label>
                <textarea id="Reason" name="Reason" type="text" required
                  placeholder="Please provide some context on the reason for your dispute"></textarea>
              </div>
            </form>`,
      buttons: [
        $(document.createElement("button"))
          .text("Submit")
          .addClass("button")
          .click(() => {
            if (this.checkInput()) {
              this.submitInvoiceDispute();
              modal.closeAndDeleteModal();
            }
          }),
        $(document.createElement("button"))
          .text("Cancel")
          .addClass("button secondary")
          .click(() => {
            modal.closeAndDeleteModal()
          })
      ]
    });
    modal.openModal();
  }

  private checkInput(): boolean {
    const amount = String.isFilled((document.getElementById("AmountInDispute") as HTMLInputElement).value);
    const charge = String.isFilled((document.getElementById("ChargeInDispute") as HTMLInputElement).value);
    const reason = String.isFilled((document.getElementById("Reason") as HTMLInputElement).value );
    return amount && charge && reason;
  }

  async submitInvoiceDispute() {
    let invoiceDisputeData: InvoiceDisputeData = {
      mailAddress: this.mail
      , intMailAddress: (await this.invoiceDisputeSetting$.toPromise()).find(i => i.country === this.country).mailAddress
      , name: this.name
      , company: this.company
      , companyId: this.companyId
      , invoiceNumber: this.invoiceNumber
      , rohligRef: this.rohligRef
      , amount: (document.getElementById("AmountInDispute") as HTMLInputElement).value
      , charge: (document.getElementById("ChargeInDispute") as HTMLInputElement).value
      , reason: (document.getElementById("Reason") as HTMLInputElement).value
      , currency: this.invoiceCurrency
      , phone: this.phone
    }
    await this._accountService.lodgeDispute(invoiceDisputeData);
    const successAlert = `Your dispute has been successfully submitted. A confirmation has been sent to: ${this.mail}`
    this._alertService.openDefaultSuccessAlert(successAlert, false);
  }
}
