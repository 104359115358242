<p class="mat-icon-person text-hide">User menu open</p>
<figure class="avatar">
  <ng-container *ngIf="profilePictureBase64$ | async as picture">
    <img src="data:image/jpeg;base64,{{picture}}" style="width: 48px; height: 48px;" alt="User Avatar"/>
  </ng-container>
  <ng-container *ngIf="!(profilePictureBase64$ | async)">
    <img src="/content/images/avatar.png" style="width: 48px; height: 48px;" alt="User Avatar"/>
  </ng-container>
</figure>
<div>
  <p class="name">{{userName$ | async | unescapeHtml}}</p>
  <p *ngIf="currentSiteName$ | async as siteName" class="company" id="company-name">{{siteName | unescapeHtml}}</p>
</div>

