// @ts-nocheck

//import MapOptions = google.maps.MapOptions;

//TODO: should be imported from server namespace, does not work with the compiler

import {MapGeoDataItem} from "@api";

interface Window {
    bluemapcallback: Function;
    bluemapsurl: string;
}

window.bluemapcallback = async function () {
    if (!window.bluemapsurl)
        return;
    const data: MapGeoDataItem[] = await $.getJSON(window.bluemapsurl);
    if (data.length === 0)
        return;

    const flightPlanCoordinates = data.map((value, index) => {
        return {
            position: new google.maps.LatLng(value.Latitude, value.Longitude),
            traveled: value.Completed,
            index: index
        }
    });

    const styles: google.maps.MapTypeStyle[] = [
        {
            featureType: "water",
            stylers: [{color: "#ecf1f2"}, {visibility: "on"}]
        },
        {
            featureType: "landscape",
            stylers: [{color: "#f8f8f8"}]
        },
        {
            featureType: "road",
            stylers: [{saturation: -100}, {lightness: 45}]
        },
        {
            featureType: "road.highway",
            stylers: [{visibility: "simplified"}]
        }, {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [{visibility: "off"}]
        },
        {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [{color: "#999999"}]
        },
        {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [{color: "#999999"}]
        },
        {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [{visibility: "off"}]
        },
        {
            featureType: "transit",
            stylers: [{visibility: "off"}]
        },
        {
            featureType: "poi",
            stylers: [{visibility: "off"}]
        }];
    const styledMap = new google.maps.StyledMapType(styles, {name: "TnT"});
    const mapOptions: MapOptions = {
        zoom: 3,
        center: new google.maps.LatLng(0, -180),
        mapTypeId: google.maps.MapTypeId.TERRAIN,
        mapTypeControl: false,
        streetViewControl: false,
        gestureHandling: "cooperative"
    };
    const map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);
    map.mapTypes.set("map_style", styledMap);
    map.setMapTypeId("map_style");

    const mapImagesPath = `${window.baseApplicationUrl.trim("/")}/Content/images/gmap/`;
    const markerIcons = {
        default: mapImagesPath + "map_marker.png",
        grey: mapImagesPath + "map_marker_grey.png",
        complete: mapImagesPath + "map_marker_end_complete.png",
        end: mapImagesPath + "map_marker_end.png"
    };

    const bounds = new google.maps.LatLngBounds();
    for (const marker of flightPlanCoordinates) {
        bounds.extend(marker.position);

        if (marker.index === 0 || marker.index === flightPlanCoordinates.length - 1) {
            continue;
        }
        new google.maps.Marker({
                                   position: marker.position,
                                   icon: {
                                       url: marker.traveled ? markerIcons.default : markerIcons.grey,
                                       size: new google.maps.Size(30, 30),
                                       origin: new google.maps.Point(0, 0),
                                       anchor: new google.maps.Point(14, 14)
                                   },
                                   map: map
                               });
    }
    map.fitBounds(bounds);

    const traveledPath = flightPlanCoordinates
        .filter(c => c.traveled)
        .map(c => c.position);
    const toGoPath = flightPlanCoordinates
        .filter(c => !c.traveled)
        .map(c => c.position);
    if (traveledPath.length > 0)
        toGoPath.unshift(traveledPath.last());
    else
        traveledPath[0] = toGoPath[0];

    //Traveled path
    new google.maps.Polyline({
                                 path: traveledPath,
                                 geodesic: false,
                                 strokeColor: "#83a427",
                                 strokeOpacity: 1.0,
                                 strokeWeight: 2,
                                 map: map
                             });

    //To go path
    new google.maps.Polyline({
                                 path: toGoPath,
                                 geodesic: false,
                                 strokeOpacity: 0,
                                 icons: [{
                                     icon: {
                                         path: "M 0,-1,0,1",
                                         strokeOpacity: 1,
                                         strokeColor: "#757575",
                                         scale: 2
                                     },
                                     offset: "0",
                                     repeat: "10px"
                                 }],
                                 map: map
                             });

    //Start
    new google.maps.Marker({
                               position: traveledPath[0],
                               map: map,
                               icon: {
                                   url: flightPlanCoordinates[0].traveled ? markerIcons.default : markerIcons.grey,
                                   size: new google.maps.Size(30, 30),
                                   origin: new google.maps.Point(0, 0),
                                   anchor: new google.maps.Point(15, 15)
                               }
                           });
    //End
    new google.maps.Marker({
                               position: toGoPath.last(),
                               map: map,
                               icon: {
                                   url: flightPlanCoordinates.last().traveled ? markerIcons.complete : markerIcons.end,
                                   size: new google.maps.Size(38, 46),
                                   origin: new google.maps.Point(0, 0),
                                   anchor: new google.maps.Point(14, 29)
                               }
                           });

};
if (window.mapLoaded)
    window.bluemapcallback();
