import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";

@Injectable({
                providedIn: 'root'
            })
export class BodyScrollService {
    private readonly _document: Document;
    private _semaphore = 0;

    constructor(@Inject(DOCUMENT) document: Document) {
        this._document = document;
    }

    disableScrolling() {
        if (this._semaphore === 0)
            this._document.documentElement.classList.add("no-scroll");
        this._semaphore++;
    }

    enableScrolling() {
        this._semaphore = Math.max(0, this._semaphore - 1);
        if (this._semaphore === 0)
            this._document.documentElement.classList.remove("no-scroll");
    }
}
