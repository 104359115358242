<div class="shipment-box-container" *ngIf="listLayout$ | async; let listLayout">
  <div class="list-view-spacer"></div>
  <div class="ajax-target" [ngClass]="hideCellsClass$ | async" #table>
    <div class="shipment-box-line header">
      <div class="" *ngFor="let item of listLayout" [ngClass]="item.size">{{item.columnTitle}}</div>
      <div class="filler"></div>
    </div>
    <ng-container *ngIf="!skeletonMode; else skeletonView">
      <a *ngFor="let shipment of (shipments$ | async); trackBy: trackById"
         class="shipment-box-line no-focus-highlight"
         routerLink="/Shipment/ShipmentDetail"
         [queryParams]="{JobID:shipment.id, ObjectType:shipment.jobType | rtShipmentTypeUrlParam}"
         rt-Initialize-Child-Popovers
         [innerHTML]="shipment | pureFunction:renderItem:listLayout | safeHtml">
      </a>
    </ng-container>
    <ng-template #skeletonView>
      <div *ngFor="let item of [].constructor(skeletonItemCount);"
           class="shipment-box-line">
        <div *ngFor="let item of listLayout" [ngClass]="item.size || ''" class="skeletonWidth">
          <span class="shimmer-content" style="width:50%"></span>
        </div>
        <div class="filler"></div>
      </div>
    </ng-template>
  </div>
</div>
