import {Directive, ElementRef} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {FormValidator} from "@widgets/Validate";

@Directive({
               selector: '[rtFormValidator]'
           })
export class FormValidatorDirective {
    private readonly _formValidator: FormValidator;
    private readonly validatedSubject = new ReplaySubject<boolean>(1)

    constructor(elementRef: ElementRef) {
        this._formValidator = new FormValidator(elementRef.nativeElement);
        this._formValidator.onValidated.on(e => this.validatedSubject.next(e.data));
    }

    get validated$() {
        return this.validatedSubject.asObservable();
    }

    get isValid() {
        return this._formValidator.isValid;
    }

    async validate(): Promise<boolean> {
        return this._formValidator.validate();
    }

    reset() {
        return this._formValidator.resetValidation();
    }

}
