import {Injectable} from '@angular/core';
import {Container, EntityInfo, EntitySetInfo, IContainerViewItem, Shipment, ShipmentListViewMode, ShipmentSearch, ShipmentSearchReferenceType} from "@api";
import {ContainerDetailViewData, ShipmentDetailViewData, ShipmentListViewData} from "@view-data";
import {AccountStore, RoutingStore, UserRightsStore} from "@stores";
import {debounceTime, distinctUntilKeyChanged, filter, skip} from "rxjs/operators";
import {hasValue, TypeFilter} from "@utils/rxjs-extensions";
import {RoutingService} from "@app/services/routing.service";
import {ShipmentSearchUiStore} from "@app/page-components/shipment/shipment-list/shipment-search-ui-store.service";
import {ContainerApi, ShipmentApi} from "../api/Apis";
import {ContainerStoreState} from "@stores/container-store-state";
import {ContainerStore} from "@stores/container-store.service";
import {ShipmentService} from "@services/shipment.service";
import {combineLatest} from "rxjs";

@Injectable({
              providedIn: 'root'
            })
export class ContainerService {
  readonly containerStore: ContainerStore;
  private readonly _containerApi: ContainerApi;
  private readonly _routingStore: RoutingStore;

  private readonly _routingService: RoutingService;
  private readonly _shipmentApi: ShipmentApi;
  private readonly _shipmentService: ShipmentService;

  constructor(containerApi: ContainerApi,
              shipmentApi: ShipmentApi,
              containerStore: ContainerStore,
              routingService: RoutingService,
              userRightsStore: UserRightsStore,
              shipmentSearchUiStore: ShipmentSearchUiStore,
              shipmentService: ShipmentService,
              accountStore: AccountStore) {
    const routingStore = routingService.routingStore;
    this._routingService = routingService;
    this._routingStore = routingStore;
    this.containerStore = containerStore;
    this._containerApi = containerApi;
    this._shipmentApi = shipmentApi;
    this._shipmentService = shipmentService;
    // accountStore
    //   .subscribe("currentContainerSearch",
    //              s => {
    //                if (s) {
    //                  shipmentSearchUiStore.reset();
    //                  shipmentSearchUiStore.update(s);
    //                }
    //                else
    //                  shipmentSearchUiStore.reset();
    //                containerStore.update("currentSearch", s || {});
    //              });
    accountStore
      .observe("currentEntitySet")
      .pipe(
        filter(hasValue),
        distinctUntilKeyChanged("id")
      )
      .subscribe(s => void this._entitySetChanged(s))
    routingStore
      .observe("currentViewData")
      .pipe(filter(TypeFilter(ContainerDetailViewData)))
      .subscribe((v: ContainerDetailViewData) => {
        void this._loadContainer(v)
      })
    combineLatest(
      routingStore
        .observe("currentViewData")
        .pipe(filter(TypeFilter(ShipmentListViewData))),
      accountStore.observe("shipmentListViewMode")
    )
      .pipe(filter(([_, viewMode]) => viewMode === ShipmentListViewMode.Containers))
      .subscribe(([viewData, _]) => {
        void this._loadItemList(viewData.redoSearch, viewData.query)
      })

    shipmentService.shipmentStore
      .observe("currentSearch")
      .pipe(
        skip(1),
        filter(() => userRightsStore.get("isAuthenticated")),
        debounceTime(100)
      )
      .subscribe(s => {
        shipmentSearchUiStore.update(s);
        if (routingStore.get("currentViewData") instanceof ShipmentListViewData && accountStore.get("shipmentListViewMode") === ShipmentListViewMode.Containers)
          void this._search(s)
        else
          this.containerStore.update("itemList", null)
      });
    userRightsStore
      .observe("isAuthenticated")
      .pipe(filter(u => u === false))
      .subscribe(() => this.containerStore.reset());
}


  async loadMore(): Promise<void> {
    try {
      this.containerStore.update({isLoadingMore: true});
      const result = (await this._containerApi.loadMoreContainers().toPromise());
      this.containerStore.update((state: Partial<ContainerStoreState>) => {
        return {
          itemList: (state.itemList || []).concat(result.items),
          moreItemsAvailable: result.moreItemsAvailable
        }
      })
    } finally {
      this.containerStore.update({isLoadingMore: false});
    }
  }

  get(containerId: string): Promise<Container | null> {
    return this._containerApi.containerDetail(containerId).toPromise();
  }

  startSearch(searchObject: Partial<ShipmentSearch>, force = true): void {
    this._shipmentService.shipmentStore.update("currentSearch", searchObject, force);
  }

  rerunSearch(): void {
    void this._search(this._shipmentService.shipmentStore.get("currentSearch"));
  }

  async reloadCurrentContainer(): Promise<void> {
    const container = this.containerStore.get("currentContainer");
    if (!container)
      return;
    const update =
      await this.get(container.containerId);
    this.containerStore.update(u => {
      const containerList = u.itemList;
      if (containerList) {
        const index = containerList.findIndex(s => "containerId" in s && (s as Container).containerId === update.containerId);
        if (index >= 0)
          containerList[index] = update;
      }
      return {currentContainer: update, itemList: containerList};
    })
  }

  static isContainer(item: IContainerViewItem): item is Container {
    return "containerId" in item;
  }
  private async _search(search: ShipmentSearch): Promise<Container[] | null> {
    const {
      bookedByRohlig,
      bookedByUserIds,
      containerMode,
      dateType,
      destinationCountryCodes,
      endDate,
      originCountryCodes,
      portsOfDischargeUnLocodes,
      portsOfLoadingUnLocodes,
      reference,
      searchReferenceType,
      startDate,
      status,
      transportMode
    } = search;
    try {
      this.containerStore.update({isSearching: true});
      const result = await this._containerApi
                               .searchContainers(reference,
                                                 searchReferenceType,
                                                 transportMode,
                                                 containerMode,
                                                 status,
                                                 originCountryCodes,
                                                 destinationCountryCodes,
                                                 portsOfLoadingUnLocodes,
                                                 portsOfDischargeUnLocodes,
                                                 startDate,
                                                 endDate,
                                                 dateType,
                                                 bookedByUserIds,
                                                 bookedByRohlig)
                               .toPromise();
      this.containerStore.update({
                                   itemList: result.items,
                                   moreItemsAvailable: result.moreItemsAvailable
                                 });
      if (result.items.length === 1 && (this._routingService.getCurrentViewData() as ShipmentListViewData).autoOenSingleItem) {
        const container: Container | Shipment = result.items[0];
        if (ContainerService.isContainer(container))
          this._routingService.navigateTo(new ContainerDetailViewData(container.containerId));
        else
          this._routingService.navigateTo(new ShipmentDetailViewData(container.id, container.jobType));
      }
      return result.items;
    } finally {
      this.containerStore.update(u => {
        return {
          isSearching: false
        }
      });
    }
  }

  private async _loadContainer(viewData: ContainerDetailViewData) {
    const containerFromStore = this._getContainerFromStore(viewData);
    if (containerFromStore)
      this._updateCurrentContainerInStore(containerFromStore);
    else {
      this.containerStore.update("currentContainer", null);
      const container =
        await this.get(viewData.containerId);
      this._updateCurrentContainerInStore(container);
    }
  }

  private _updateCurrentContainerInStore(containerFromStore: Container): void {
    this.containerStore.update({currentContainer: containerFromStore});
  }

  private _getContainerFromStore(viewData: ContainerDetailViewData): Container {
    const currentContainers = this.containerStore.get("itemList") ?? [];
    return currentContainers
      .firstOrDefault((s: Container) => ContainerService.isContainer(s) && s.containerId === viewData.containerId);
  }

  private _loadItemList(force: boolean, query: string) {
    if (!force && (this.containerStore.hasValue("itemList"))) {
      this._routingService.navigateTo(new ShipmentListViewData(), true)
      return;
    }
    const search =
      query
      ? {searchReferenceType: ShipmentSearchReferenceType.Any, reference: query}
      : this._shipmentService.shipmentStore.get("currentSearch");
    this.startSearch(search)
  }

  private async _entitySetChanged(entitySet: EntitySetInfo) {
    if (!this._hasContainerRights(entitySet)) {
      this.containerStore.reset();
      return;
    }
    const currentViewData = this._routingStore.get("currentViewData");
    if (currentViewData instanceof ShipmentListViewData) {
      this.containerStore.update("itemList", null)
      await this._search(this._shipmentService.shipmentStore.get("currentSearch"));
    } else {
      this.containerStore.update("itemList", null)
      let currentContainer: Container | null = null;
      if (currentViewData instanceof ContainerDetailViewData) {
        const containerId = currentViewData.containerId;
        const userCanStillViewContainer = await this._containerApi.hasUserAccessForEntitySet(containerId, entitySet.id).toPromise();
        if (userCanStillViewContainer)
          currentContainer = await this._containerApi.containerDetail(containerId).toPromise();
        else
          this._routingService.navigateTo(new ShipmentListViewData())
      }
      this.containerStore.update({currentContainer})
    }

    this.containerStore.update({firstContainerInEntitySet:null})
    const firstContainerInEntitySet = await this._containerApi.getFirstContainerResult().toPromise()
    this.containerStore.update({firstContainerInEntitySet})
  }

  private _hasContainerRights(entity: EntityInfo): boolean {
    return entity.hasShipmentRights ||
           entity.subSites && entity.subSites.any(s => this._hasContainerRights(s));
  }
}

