import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
        name: 'neq',
        pure: true
      })
export class EqualsNotPipe implements PipeTransform {

  transform(value: any, compareTo: any): boolean {
    return value !== compareTo;
  }
}

@Pipe({
        name: 'not',
        pure: true
      })
export class NotPipe implements PipeTransform {

  transform(value: unknown): boolean {
    if (value instanceof Array)
      return !value.any()
    return !value
  }
}
