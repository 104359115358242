import {Injectable} from "@angular/core";
import {MainMasterUiStore} from "@app/master-components/main/main-master-ui.store";
import {Viewport} from "@utils/Viewport";
import {RoutingStore} from "@stores";
import {MainMasterUiState} from "@app/master-components/main/main-master-ui.state";
import {BodyScrollService} from "@services/body-scroll.service";

@Injectable()
export class MainMasterUiService {

  readonly store: MainMasterUiStore;


  private readonly _bodyScrollService: BodyScrollService;

  constructor(store: MainMasterUiStore, routingStore: RoutingStore, bodyScrollService: BodyScrollService) {
    this._bodyScrollService = bodyScrollService;
    this.store = store;
    Viewport.onChange.connect(() => this.closeMenus());
    Viewport.enable()
    routingStore.subscribe("currentViewData", () => this.closeMenus());
    store.subscribe("userMenuOpen", o => this._menuOpenStateChanged(o));
    store.subscribe("navMenuOpen", o => this._menuOpenStateChanged(o));
  }

  toggleNavMenu(): void {
    this.store.update(state => {
      return {
        navMenuOpen: !state.navMenuOpen,
        userMenuOpen: false
      }
    });
  }

  toggleUserMenu(): void {
    this.store.update(state => {
      return {
        userMenuOpen: !state.userMenuOpen,
        navMenuOpen: false
      }
    });
  }

  closeMenus(): void {
    this.store.update({
                        navMenuOpen: false,
                        userMenuOpen: false
                      })
  }

  closeNavMenu(): void {
    this.store.update({navMenuOpen: false});
  }

  closeUserMenu(): void {
    this.store.update({userMenuOpen: false});
  }

  private _menuOpenStateChanged(menuOpen:boolean) {
    if (menuOpen)
      this._bodyScrollService.disableScrolling();
    else
      this._bodyScrollService.enableScrolling();
  }
}
