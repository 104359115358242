<div class="overflow"></div>
<div class="sidebar">
  <button class="button secondary mat-icon-close text-hide" (click)="close.emit()">Close</button>
  <div class="content">

    <header>
      <h2>History</h2>
      <p class="close">
        <button class="button secondary mat-icon-close text-hide" (click)="close.emit()">Close</button>
      </p>
    </header>
    <div class="data-view">
      <p *ngFor="let log of logs">
        <label *ngIf="log.date">{{log.date | rtDateTime}}</label>
        {{log.content | unescapeHtml}}
      </p>
    </div>

  </div>
</div>
