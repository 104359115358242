import {
  CityLocation, Container, ContainerGoods,
  DangerousGoodsData,
  DeliveryStatus,
  DimensionValue,
  JobType,
  PortLocation,
  Shipment,
  ShipmentContainer,
  ShipmentContainerGroup,
  ShipmentEvent,
  ShipmentGoods,
  TimelineLeg,
  TimelineLegType,
  TimelineLocation,
  TransportMode
} from "@api";
import {ShipmentDetailViewData} from "@services";
import {GetDangerousGoodsPipe, IsContainerGroup} from "@app/utils/rt-date.pipe";

export interface DateContainer {
  actual: Date | null;
  estimated: Date | null;
}

export class ShipmentOverviewItem {
  readonly arrivalDate: DateContainer | null;
  readonly billOfLading: string;
  readonly chargeable: DimensionValue | null;
  readonly clientReferences: string[];
  readonly consigneeName: string | null;
  readonly containerCount: number;
  readonly containerMode: string;
  readonly containersNumbers: string[];
  readonly dangerousGoods: Grouping<DangerousGoodsData, string>[];
  readonly deliveryDate: DateContainer | null;
  readonly deliveryLocation: string | null;
  readonly departureDate: DateContainer | null;
  readonly destination: CityLocation | null;
  readonly goodsDescription: string | null;
  readonly hidePorts: boolean;
  readonly id: string;
  readonly incoTerm: string | null;
  readonly invoiceNumbers: string[];
  readonly isAir: boolean;
  readonly isCustomsDeclaration: boolean;
  readonly isLead: boolean;
  readonly isUninitializedBooking: boolean;
  readonly jobType: JobType;
  readonly lastEvent: ShipmentEvent | null;
  readonly orderNumbers: string[];
  readonly origin: CityLocation | null;
  readonly pickupDate: DateContainer | null;
  readonly pickupLocation: string | null;
  readonly pieces: number;
  readonly portOfDischarge: PortLocation;
  readonly portOfLoading: PortLocation;
  readonly portTypes: "IATA" | "UNLOCO";
  readonly roehligReferences: string[];
  readonly shipperName: string | null;
  readonly status: DeliveryStatus;
  readonly timelineLegs: TimelineLeg[];
  readonly transportModes: TransportMode[];
  readonly url: string;
  readonly vesselFlightNumbers: string[];
  readonly volume: DimensionValue | null;
  readonly weight: DimensionValue | null;
  readonly customsEntryNumbers: string[];


  constructor(shipment: Shipment) {
    const pickupTimelineLegLocation = ShipmentOverviewItem._getPickupTimelineLegLocation(shipment);
    const deliveryTimelineLocation = ShipmentOverviewItem._getDeliveryTimelineLegLocation(shipment);
    this.arrivalDate = this._getArrivalDate(shipment);
    this.billOfLading = shipment.houseBill;
    this.chargeable = shipment.goods?.chargeable;
    this.clientReferences = ShipmentOverviewItem._getClientReferences(shipment);
    this.consigneeName = shipment.consigneeLocation?.name;
    this.containerCount = shipment.goods?.containers.length;
    this.containerMode = shipment.containerMode;
    this.containersNumbers = ShipmentOverviewItem._getContainerNumbers(shipment.goods.containers);
    this.dangerousGoods = ShipmentOverviewItem._getDangerousGoodsList(shipment.goods)
    this.deliveryDate = ShipmentOverviewItem._getDateFromTimeLineLocation(deliveryTimelineLocation);
    this.deliveryLocation = shipment.deliveryLocation?.name || null;
    this.departureDate = this._getDepartureDate(shipment);
    this.destination = shipment.destination;
    this.goodsDescription = shipment.goods?.goodsDescription;
    this.hidePorts = shipment.transportModes?.contains(TransportMode.Courier) || shipment.transportModes?.contains(TransportMode.Road);
    this.id = shipment.id;
    this.incoTerm = shipment.incoterm;
    this.invoiceNumbers = shipment.invoiceDocuments
                                  .map(i => i.invoiceNumber)
                                  .distinct();
    this.isAir = shipment.transportModes?.firstOrDefault() === TransportMode.Air;
    this.isCustomsDeclaration = shipment.jobType === JobType.CustomsDeclaration
    this.isLead = shipment.isLead;
    this.isUninitializedBooking = shipment.jobType !== JobType.Booking && this.containersNumbers[0] !== "T.B.D."
    this.jobType = shipment.jobType;
    this.lastEvent = shipment.events.filter(e => !!e.actualDate).last();
    this.orderNumbers = ShipmentOverviewItem._getOrderNumbers(shipment);
    this.origin = shipment.origin;
    this.pickupDate = ShipmentOverviewItem._getDateFromTimeLineLocation(pickupTimelineLegLocation);
    this.pickupLocation = shipment.pickupLocation?.name || null;
    this.pieces = shipment.goods.packageCount || 0;
    this.portOfDischarge = shipment.portOfDischarge;
    this.portOfLoading = shipment.portOfLoading;
    this.portTypes = shipment.transportModes?.contains(TransportMode.Air) ? "IATA" : "UNLOCO";
    this.roehligReferences = shipment.rohligReferences;
    this.shipperName = shipment.shipperLocation?.name;
    this.status = shipment.status;
    this.timelineLegs = shipment.timelineLegs;
    this.transportModes = shipment.transportModes;
    this.url = new ShipmentDetailViewData(shipment.id, shipment.jobType).url;
    this.vesselFlightNumbers = this.getVesselFlightNumbers(shipment.timelineLegs);
    this.volume = shipment.goods.volume;
    this.weight = shipment.goods.grossWeight;
    this.customsEntryNumbers = shipment.customsEntryNumbers;
  }

  private static _getDeliveryTimelineLegLocation(shipment: Shipment) {
    const lastTimelineLeg = shipment.timelineLegs.last();
    return lastTimelineLeg?.type === TimelineLegType.Delivery
           ? lastTimelineLeg.endLocation
           : null;
  }

  private static _getPickupTimelineLegLocation(shipment: Shipment) {
    const lastTimelineLeg = shipment.timelineLegs.firstOrDefault();
    return lastTimelineLeg?.type === TimelineLegType.Pickup
           ? lastTimelineLeg.startLocation
           : null;
  }

  private static _getDateFromTimeLineLocation(location: TimelineLocation | undefined): DateContainer | null {
    return {
      actual: location?.actualDate || null,
      estimated: location?.estimatedDate || null,
    }
  }

  private static _getDangerousGoodsList(goods: ShipmentGoods): Grouping<DangerousGoodsData, string>[] {
    return new GetDangerousGoodsPipe().transform({goods})
  }

  private static _getClientReferences({consigneeReferences, deliveryReferences, notifyReferences, pickupReferences, shipperReferences}: Shipment): string[] {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
    return shipperReferences.concat(consigneeReferences, notifyReferences, pickupReferences, deliveryReferences)
  }

  private static _getContainerNumbers(containers: (ShipmentContainer | ShipmentContainerGroup)[]) {
    const containerNumbers: string[] = [];
    for (const container of containers) {
      if (IsContainerGroup(container)) {
        const joinedContainerNumbers = container.containers
                                                .map(c => c.containerNumber)
                                                .distinct()
                                                .join("/");
        containerNumbers.push(joinedContainerNumbers);
      } else {
        containerNumbers.push(container.containerNumber)
      }
    }
    return containerNumbers
      .filter(n => !!n)
      .distinct();
  }

  // noinspection ParameterNamingConventionJS
  private static isSamePort(port1: PortLocation, port2: PortLocation) {
    if (!port1 || !port2)
      return false;
    if (port1.unloco && port1.unloco === port2.unloco)
      return true;
    if (port1.iataCode && port1.iataCode === port2.iataCode)
      return true;
    return port1.name && port1.name.toLowerCase() === port2.name?.toLowerCase()
  }

  private getVesselFlightNumbers(timelineLegs: TimelineLeg[]) {
    return timelineLegs
      .map(l => {
        if (l.type === TimelineLegType.Sea)
          return l.vessel;
        if (l.type === TimelineLegType.Air)
          return l.flightNumber;
        return null;
      })
      .filter(n => !!n)
      .distinct();
  }

  private _getArrivalDate(shipment: Shipment) {
    const endLocation =
      shipment.timelineLegs
              .firstOrDefault(l => ShipmentOverviewItem.isSamePort(shipment.portOfDischarge, l.endLocation.port))?.endLocation;
    return ShipmentOverviewItem._getDateFromTimeLineLocation(endLocation);
  }

  private _getDepartureDate(shipment: Shipment) {
    const startLocation =
      shipment.timelineLegs
              .firstOrDefault(l => ShipmentOverviewItem.isSamePort(shipment.portOfLoading, l.startLocation.port))?.startLocation;
    return ShipmentOverviewItem._getDateFromTimeLineLocation(startLocation);
  }

  private static _getOrderNumbers(shipment: Shipment) {
    return shipment.orderReferences
                   .concat(shipment.orders.map(o => o.orderNumber))
                   .distinct();
  }
}
