import {Injectable} from "@angular/core";
import {Store, StoreProjectionMapping} from "@stores/store";
import {UserActivationStatus} from "@api";
import {UserSearchUiStoreState} from "./userSearchUiStoreState";

export interface UserSearchUiProjections {
}

@Injectable({providedIn: "root"})
export class UserSearchUiStore extends Store<UserSearchUiStoreState, UserSearchUiProjections> {

  private static readonly _initialState: Partial<UserSearchUiStoreState> = {
    userGroups: [],
    countryCodes: [],
    entityIds: [],
    activationStatus: UserActivationStatus.Activated,
    filtersOpen: false
  }
  private static readonly _projectionMapping: StoreProjectionMapping<UserSearchUiStoreState, UserSearchUiProjections> =
    {
    };

  constructor() {
    super(UserSearchUiStore._projectionMapping, UserSearchUiStore._initialState);
  }

  getName(): string {
    return "UserSearchUiStore";
  }
}
