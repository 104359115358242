import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Container, Shipment, ShipmentPackage} from "@api";
import {ContainerService} from "@services/container.service";

@Component({
             selector: 'rt-shipment-package',
             templateUrl: './shipment-package.component.html',
             styleUrls: ['./shipment-package.component.scss'],
             host: {
               class: "package"
             },
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class ShipmentPackageComponent {

  @Input()
  package: ShipmentPackage;
  @Input()
  shipment: Shipment | Container;
  differsFromParentDescription = (description: string) => {
    if (ContainerService.isContainer(this.shipment))
      return this.shipment.goods.all(g => g.goodsDescription !== description);
    else
      return this.shipment.goods.goodsDescription !== description
  };

  differsFromParentMarksAndNumbers = (description: string) => {
    if (ContainerService.isContainer(this.shipment))
      return this.shipment.goods.all(g => g.marksAndNumbers !== description);
    else
      return this.shipment.goods.marksAndNumbers !== description
  };

}
