import {Routes} from '@angular/router';
import {ErrorViewData} from "@view-data";
import {AccessDeniedComponent, NotFoundComponent, OfflineComponent, UnexpectedErrorComponent} from "@page-components";
import {ErrorMasterComponent} from "@master-components";

export const ErrorRoutes: Routes = [
  {
    path: "Error",
    component: ErrorMasterComponent,
    children: [
      {
        path: "AccessDenied",
        component: AccessDeniedComponent,
        data: {viewData: new ErrorViewData("AccessDenied")}
      },
      {
        path: "NotFound",
        component: NotFoundComponent,
        data: {viewData: new ErrorViewData("NotFound")}
      },
      {
        path: "UnexpectedError",
        component: UnexpectedErrorComponent,
        data: {viewData: new ErrorViewData("UnexpectedError")}
      },
      {
        path: "Offline",
        component: OfflineComponent,
        data: {viewData: new ErrorViewData("Offline")}
      }
    ]
  }
];
