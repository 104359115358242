import {Injectable} from "@angular/core";
import {Store, StoreProjectionMapping} from "@app/stores/store";
import {BookingStoreState} from "@app/stores/bookingStoreState";
import {distinctUntilChanged, map} from "rxjs/operators";

type BookingStoreProjections = { templateName: string | null, isNewTemplate: boolean };

@Injectable({providedIn: "root"})
export class BookingStore extends Store<BookingStoreState, BookingStoreProjections> {

  getName(): string {
    return "BookingStore";
  }

  private static _projectionMapping: StoreProjectionMapping<BookingStoreState, BookingStoreProjections> =
    {
      isNewTemplate: ({booking}) => !!booking?.templateInformation?.templateID,
      templateName: ({booking}) => booking?.templateInformation?.templateName
    };

    constructor() {
      super(BookingStore._projectionMapping, {});
    }
}
