<div class="sidebar-overlay" (click)="closeUserMenu()"></div>
<div class="sidebar">
  <div class="sidebar-user">
    <p class="close">
      <button class="button mat-icon-close text-hide" (click)="closeUserMenu()">Close</button>
    </p>
    <rt-user-box></rt-user-box>
    <ul>
      <ng-container *ngIf="userCanSwitchEntitySets">
        <li class="submenu" [ngClass]="{open: entitySetListOpen}">
          <a style="cursor: pointer" (click)="toggleEntitySetList()">Entity Set</a>
          <ul>
            <li *ngFor="let entitySet of entitySets">
              <ng-container *ngIf="entitySet.isSelected; else switchEntitySetLink">
                <span class="active-site">
                  {{entitySet.name | unescapeHtml}}
                  <span class="mat-icon-done"></span>
                </span>
              </ng-container>
              <ng-template #switchEntitySetLink>
                <a (click)="changeEntitySet(entitySet.id)">{{entitySet.name | unescapeHtml}}</a>
              </ng-template>
            </li>
          </ul>
        </li>
      </ng-container>
      <li routerLinkActive="active">
        <a [routerLink]="'account/myProfile'">Profile</a></li>
      <li>
        <a (click)="logout()">Logout</a>
      </li>
    </ul>

  </div>
  <a
    id="disclaimer"
    href="https://rohlig.com/disclaimer"
    target="_blank">
    Disclaimer
  </a>
  <div id="version">{{rtVersion}}</div>
</div>
