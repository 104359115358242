import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[rtOrderLineCollapser]'
})
export class OderLineCollapserDirective {

  @HostBinding("class.collapsed")
   collapsed: boolean;

  @Output("rtOrderLineCollapser")
  toggled = new  EventEmitter<void>()

  @HostListener("click")
  toggle(): void {
    this.collapsed = !this.collapsed;
    this.toggled.emit()
  }

}
