import {Component, OnDestroy} from '@angular/core';
import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import {ConnectionHub} from "../../../../api/connection-hub";

@Component({
             selector: 'rt-online-monitor',
             templateUrl: './online-monitor.component.html',
             styleUrls: ['./online-monitor.component.scss']
           })
export class OnlineMonitorComponent implements OnDestroy {
  connectedUsers: { mailAddress: string, connectionId: string }[] = [];
  private readonly _hubConnection: HubConnection;

  constructor() {
    this._hubConnection = new HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl("/hubs")
      .build();
    this._hubConnection.start()
        .then(() => this.setupHubCallbacksAndLoadUsers());
  }

  clientDisconnected(_: any, connectionId: string) {
    const usersToRemove = this.connectedUsers.filter(u => u.connectionId === connectionId);
    this.connectedUsers.removeRange(usersToRemove)
  }

  clientConnected(mailAddress: string, connectionId: string) {
    return this.connectedUsers.push({mailAddress, connectionId});
  }

  ngOnDestroy(): void {
    // noinspection JSIgnoredPromiseFromCall
    this._hubConnection.stop();
  }

  private setupHubCallbacksAndLoadUsers() {
    const connectionHub = new ConnectionHub(this._hubConnection);
    connectionHub.getConnectedUsers().then(console.log);
    connectionHub.getConnectedUsers()
                 .then(users => {
                  return users.map(u => {
                     return {mailAddress: u.email, connectionId: u.id}
                   });
                 })
                 .then(u => {
                         this.connectedUsers.push(...u);
                       }
                 );
    connectionHub.registerCallbacks(
      {
        clientConnected: this.clientConnected,
        clientDisconnected: this.clientDisconnected,
        entitySetChanged: () => {
        }
      })
  }
}
