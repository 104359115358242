import {EntityViewData} from "./entity-view-data";
import {normalizeId} from "@utils/normalize-Id";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class EntityEditViewData extends EntityViewData {
    readonly entityId: string | null;

    constructor();
    constructor(entityId: string);
    constructor(entityId: string | null = null) {
        super();
        this.entityId = normalizeId(entityId);
    }

    isNewEntity(): boolean {
        return this.entityId === null;
    }

    hasNewInformationComparedTo(compare: this): boolean {
      const idChanged = this.entityId !== compare.entityId;
      return idChanged;
    }

  get pageTitle(): string {
    if (this.isNewEntity())
      return TitleBuilder.entities.addSite;
    else {
      // if (this.hasDefinedEntityName())
      //   return TitleBuilder.entities.siteDetails(this.entityName!);
      return TitleBuilder.entities.list;
    }
  }

  get url(): string {
    if (this.isNewEntity())
      return UrlBuilder.entities.addSite;
    else
      return UrlBuilder.entities.siteDetails(this.entityId);
  }

  readonly bodyClasses = ["page-entity"];

}
