import {Inject, Injectable, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {formatDate} from "@angular/common";
import {
  AddressLocation,
  Container,
  ContainerStatus,
  Country,
  CustomsState,
  DangerousGoodsData,
  DeliveryStatus,
  DimensionValue,
  JobType,
  Note,
  Order,
  OrderStatus,
  PhoneWithCountry,
  Shipment,
  ShipmentContainer,
  ShipmentContainerGroup, ShipmentGoods,
  ShipmentPackage,
  TimelineLeg,
  TimelineLegType,
  TimeLineLocationType,
  TransportMode,
  User,
  UserGroup,
  UserType
} from "@api";
import {orderStatusClassMapper, orderStatusDisplayMapper, userGroupMapper} from "@app/utils/enum-display-name-mapper";
import {DomSanitizer, SafeHtml, SafeUrl} from "@angular/platform-browser";
import {FieldRenderer} from "@app/page-components/shipment/shipment-list/field-renderer.service";
import {ContainerService} from "@services/container.service";
import {ShipmentOverviewItem} from "@app/page-components/shipment/shipment-list/shipment-overview-item";
import {ContainerOverviewItem} from "@app/page-components/shipment/shipment-list/container-overview-item";


@Pipe({
        name: 'any',
        pure: true
      })
@Injectable({providedIn: "root"})
export class RtAnyPipe implements PipeTransform {

  transform<T>(value: T[]): T[] | null {
    return value && value.any() ? value : null;
  }

}

@Pipe({
        name: 'rtDate',
        pure: true
      })
@Injectable({providedIn: "root"})
export class RtDatePipe implements PipeTransform {

  private readonly _localeId: string;

  constructor(@Inject(LOCALE_ID) localeId: string) {
    this._localeId = localeId;
  }

  transform(value: Date): string {
    if (!value)
      return "";
    return formatDate(value, window.dateShortFormat.replace(/m/g, "M"), this._localeId);
  }

}

@Pipe({
        name: 'rtDateTime',
        pure: true
      })
@Injectable({providedIn: "root"})
export class RtDateTimePipe implements PipeTransform {

  private readonly _localeId: string;

  constructor(@Inject(LOCALE_ID) localeId: string) {
    this._localeId = localeId;
  }

  transform(value: Date): string {
    if (!value)
      return "";
    const dateFormat = window.dateShortFormat.replace(/m/g, "M");
    const timeFormat = window.timeShortFormat.replace("tt", "a");
    return formatDate(value, `${dateFormat} ${timeFormat}`, this._localeId);
  }

}

@Pipe({
  name: 'unescapeHtml',
  pure: true
})
@Injectable({ providedIn: "root" })
export class UnescapeHtmlPipe implements PipeTransform {
  transform(value: string | string[]): string | string[] {
    if(typeof value === "string")
      return value === null ? null : value.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">");
    if (value as string[])
      value.forEach(s => s = s === null ? null : s.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">"));
    return value;
  }
}

@Pipe({
        name: 'safeHtml',
        pure: true
      })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }

  transform(value: string): SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Pipe({
        name: 'trustUrl',
        pure: true
      })
export class TrustUrl implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }

  transform(value: string): SafeUrl {
    return this.sanitized.bypassSecurityTrustUrl(value);
  }
}

@Pipe({
        name: 'rtIsAirShipment',
        pure: true
      })
@Injectable({providedIn: "root"})
export class IsAirShipmentPipe implements PipeTransform {

  transform(value: Shipment | Container, includePartlyAir = false): boolean {
    if (includePartlyAir)
      return value.transportModes.contains(TransportMode.Air);
    return value.transportModes[0] == TransportMode.Air;
  }

}

@Pipe({
        name: 'rtUserGroup',
        pure: true
      })
@Injectable({providedIn: "root"})
export class UserGroupPipe implements PipeTransform {

  transform(value: UserGroup): string {
    if (!value)
      return "";
    return userGroupMapper[value];
  }

}

@Pipe({
        name: 'rtIsClient',
        pure: true
      })
@Injectable({providedIn: "root"})
export class IsClientPipe implements PipeTransform {

  transform(value: User): boolean {
    return value?.type === UserType.Client;
  }

}

@Pipe({
        name: 'rtIsRohligUser',
        pure: true
      })
@Injectable({providedIn: "root"})
export class IsRohligUserPipe implements PipeTransform {

  transform(value: User): boolean {
    return value?.type === UserType.Rohlig;
  }

}


@Pipe({
        name: 'orderBy',
        pure: true
      })
@Injectable({providedIn: "root"})
export class OrderByPipe implements PipeTransform {

  transform<T, TKey extends KeysOfType<T, string | number>>(value: T[], orderByKey: TKey): T[] {
    if (!value)
      return null;
    return value.orderBy(v => v[orderByKey] as unknown as number);
  }

}


@Pipe({
        name: 'rtSort',
        pure: true
      })
@Injectable({providedIn: "root"})
export class rtSortPipe implements PipeTransform {

  transform(value: string[]): string[] {
    if (!value)
      return null;
    return value.orderBy(v => v);
  }

}


@Pipe({
        name: 'last',
        pure: true
      })
export class LastPipe implements PipeTransform {

  transform<T>(value: T[]): T {
    return value?.last();
  }

}


@Pipe({
        name: 'last',
        pure: true
      })
export class FirstPipe implements PipeTransform {

  transform<T>(value: T[]): T {
    return value?.last();
  }

}

@Pipe({
        name: 'rtTimelineLegTransportModeCssClass',
        pure: true
      })
@Injectable({providedIn: "root"})
export class TimelineLegTransportModeCssClassPipe implements PipeTransform {

  transform({transportMode}: TimelineLeg): string {
    const prefix = "mat-icon-";
    switch (transportMode) {
      case TransportMode.Sea:
        return prefix + "directionsboat"
      case TransportMode.Air:
        return prefix + "localairport"
      case TransportMode.Rail:
        return prefix + "train"
      case TransportMode.Road:
      case TransportMode.Courier:
        return prefix + "localshipping"

    }
  }
}

@Pipe({
        name: 'rtTimelineLegTypeCssClass',
        pure: true
      })
@Injectable({providedIn: "root"})
export class TimelineLegTypeCssClassPipe implements PipeTransform {

  static transform(type: TimelineLegType): string {
    const prefix = "mat-icon-";
    switch (type) {
      case TimelineLegType.Pickup:
      case TimelineLegType.Delivery:
        return prefix + "pindrop";
      case TimelineLegType.Air:
        return prefix + "localairport";
      case TimelineLegType.Sea:
        return prefix + "directionsboat";
      case TimelineLegType.Rail:
        return prefix + "train";
      case TimelineLegType.Road:
        return prefix + "place";
      default:
        return "";
    }
  }

  transform(type: TimelineLegType): string {
    return TimelineLegTypeCssClassPipe.transform(type);
  }

}


@Pipe({
        name: 'rtTimelineLocationTypeCssClass',
        pure: true
      })
@Injectable({providedIn: "root"})
export class TimelineLocationTypeCssClassPipe implements PipeTransform {

  static transform(type: TimeLineLocationType): string {
    const prefix = "mat-icon-";
    switch (type) {
      case TimeLineLocationType.Pickup:
      case TimeLineLocationType.Delivery:
        return prefix + "pindrop";
      case TimeLineLocationType.Airport:
        return prefix + "localairport";
      case TimeLineLocationType.Harbour:
        return prefix + "directionsboat";
      case TimeLineLocationType.TrainStation:
        return prefix + "train";
      case TimeLineLocationType.Truck:
        return prefix + "place";
      default:
        return "";
    }
  }

  transform(type: TimeLineLocationType): string {
    return TimelineLocationTypeCssClassPipe.transform(type)
  }

}

@Pipe({
        name: 'rtCustomsStateCssClass',
        pure: true
      })
@Injectable({providedIn: "root"})
export class CustomsStateCssClassPipe implements PipeTransform {

  transform(value: CustomsState): string {
    const prefix = "mat-icon-";
    switch (value) {
      case CustomsState.NotSubmitted:
        return "";
      case CustomsState.Submitted:
        return " text-orange";
      case CustomsState.Held:
        return " text-red";
      case CustomsState.Cleared:
        return " text-green";
      default:
        return "";
    }
  }

}


@Pipe({
        name: 'rtCustomsStateLabel',
        pure: true
      })
@Injectable({providedIn: "root"})
export class CustomsStateLabelPipe implements PipeTransform {

  transform(value: CustomsState): string {
    switch (value) {
      case CustomsState.NotSubmitted:
        return "not submitted";
      case CustomsState.Submitted:
        return "submitted";
      case CustomsState.Held:
        return "held";
      case CustomsState.Cleared:
        return "cleared";
      default:
        return "";
    }
  }

}

@Pipe({
        name: 'rtDeliveryStatusTextColorClass',
        pure: true
      })
@Injectable({providedIn: "root"})
export class DeliveryStatusTextColorClassPipe implements PipeTransform {

  transform(value: Shipment | Container): string {
    const deliveryStatus = value.status || DeliveryStatus.Delivered
    switch (deliveryStatus) {
      case DeliveryStatus.Booked:
        return "text-light";
      case DeliveryStatus.InTransit:
        return "text-orange";
      case DeliveryStatus.Delivered:
        return "text-green";
      case DeliveryStatus.Canceled:
        return "text-red";
    }
  }

}


@Pipe({
        name: 'rtOrderStatusTextColorClass',
        pure: true
      })
@Injectable({providedIn: "root"})
export class OrderStatusTextColorClassPipe implements PipeTransform {

  transform(value: Order | OrderStatus): string {
    const status = (typeof value === "string" ? value : value.status) || OrderStatus.Delivered;
    return orderStatusClassMapper[status];
  }
}

@Pipe({
        name: 'rtOrderStatusName',
        pure: true
      })
@Injectable({providedIn: "root"})
export class OrderStatusNamePipe implements PipeTransform {

  transform(value: Order | OrderStatus): string {
    const status = typeof value === "string" ? value : value.status
    if (!status)
      return "";
    return orderStatusDisplayMapper[status];
  }
}

@Pipe({
        name: 'rtGetDangerousGoods',
        pure: true
      })
@Injectable({providedIn: "root"})
export class GetDangerousGoodsPipe implements PipeTransform {

  transform(shipment: Shipment | DangerousGoodsData[]): Grouping<DangerousGoodsData, string>[] {
    const dangerousGoods = shipment instanceof Array
                           ? shipment
                           : this._getDangerousGoodsFromShipment(shipment);
    return dangerousGoods
      .distinct(JSON.stringify)
      .groupBy(d => d.unNumber);
  }

  private _getDangerousGoodsFromShipment(shipment: Shipment): DangerousGoodsData[] {
    const dangerousGoods = [] as DangerousGoodsData[];
    const containers = shipment.goods.containers
                               .map(function (c): ShipmentContainer[] {
                                 return IsContainerGroup(c) ? c.containers : [c];
                               })
                               .flat()
    containers.forEach(c => dangerousGoods.pushRange(c.dangerousGoods));
    shipment.goods.packages.forEach(p => dangerousGoods.pushRange(p.dangerousGoods));
    return dangerousGoods;
  }
}

@Pipe({
        name: 'rtIsFclOrBuyersConsol',
        pure: true
      })
@Injectable({providedIn: "root"})
export class IsFclOrBuyersConsolPipe implements PipeTransform {

  static check(containerMode: string): boolean {
    return containerMode === 'FCL' || containerMode === 'Buyer\'s Consol'
  }

  transform(shipment: Shipment): boolean {
    const { containerMode } = shipment || {};
    return IsFclOrBuyersConsolPipe.check(containerMode);
  }
}

@Pipe({
        name: 'rtReeferTemperature',
        pure: true
      })
@Injectable({providedIn: "root"})
export class ReeferTemperaturePipe implements PipeTransform {

  transform(value: ShipmentContainer | ShipmentContainerGroup): DimensionValue | null {
    if (IsContainerGroup(value))
      return value.commonTemperature;
    return value.temperature
  }

}

@Pipe({
        name: 'rtPacksWithInformation',
        pure: true
      })
export class PacksWithInformationPipe implements PipeTransform {

  transform(value: ShipmentPackage[], shipment: Shipment | Container): ShipmentPackage[] | null {
    const packsWithInformation =
      value.filter(p => {
        return p.packCount > 1 ||
               p.packCount && HasInformationOtherThanPackCountPipe.calculate(p, shipment);
      });
    if (packsWithInformation.length)
      return packsWithInformation;
    return null;
  }

}

@Pipe({
        name: 'rtNoteTypeName',
        pure: true
      })
export class NoteTypeNamePipe implements PipeTransform {

  private readonly _noteTypeMapping = {
    "AdditionalSecurityInformation": "Additional Security Information",
    "AutoRatingAuditLog": "Auto Rating Audit Log",
    "AWBRatelineOvertypedNotes": "AWBRateline Overtyped Notes",
    "BookingNotes": "Booking Notes",
    "CarrierBookingRequest": "Carrier Booking Request",
    "CertificateOfOriginNote": "Certificate of Origin Note",
    "ChargeLineInformation": "Charge Line Information",
    "ClientVisibleJobNotes": "Client Visible Job Notes",
    "CustomsInstructionNotes": "Customs Instruction Notes",
    "DangerousGoodsAdditionalHandlingInformation": "Dangerous Goods Additional Handling Information",
    "DeliveryInstructionsNote": "Delivery Instructions Note",
    "DeliveryOrderReceiptNotes": "Delivery Order Receipt Notes",
    "DetailedGoodsDescription": "Detailed Goods Description",
    "ExportReceivalAdviceRemarks": "Export Receival Advice Remarks",
    "ExtraOrderDetails": "Extra Order Details",
    "FaxEmailTransmissionLog": "Fax Email Transmission Log",
    "ForwardingInstructionNotes": "Forwarding Instruction Notes",
    "HandlingInstructions": "Handling Instructions",
    "ImportCustomsHandlingNotes": "Import Customs Handling Notes",
    "InternalWorkNotes": "Internal Work Notes",
    "LoadListInstructions": "Load List Instructions",
    "ManifestGoodsDescription": "Manifest Goods Description",
    "MarksAndNumbers": "Marks and Numbers",
    "OrderManagementNote": "Order Management Note",
    "OrderManagementUpdate": "Order Management Update",
    "OrderNotes": "Order Notes",
    "OutturnNotes": "Outturn Notes",
    "PaymentHandlingInstructions": "Payment Handling Instructions",
    "PickingInstructions": "Picking Instructions",
    "PickupInstructionsNote": "Pickup Instructions Note",
    "PrealertArrivalNoticeRemarks": "Prealert Arrival Notice Remarks",
    "PrintedMawbUnallocationInfo": "Printed Mawb Unallocation Info",
    "ReceiveConfirmationInstructions": "Receive Confirmation Instructions",
    "ShippedUnitsInformation": "Shipped Units Information",
    "SpecialInstructions": "Special Instructions",
    "SupplierAddress": "Supplier Address",
    "TranshipmentNotes": "Transhipment Notes",
    "UnmatchedOrgDetails": "Unmatched Org Details"
  }

  transform(note: Note): string | null {
    if (note.type in this._noteTypeMapping)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    { // @ts-ignore
      return this._noteTypeMapping[note.type] as string;
    }
    return note.type;
  }

}

@Pipe({
        name: 'rtNoteText',
        pure: true
      })
export class NoteTextPipe implements PipeTransform {


  transform(note: Note): string | null {
    if (!note.title)
      return null;
    const textWithLinks = note.title
                              .replace(/\b(https?:\/\/)?(www\.\S+)/g, (match, protocol,  address) => {
                                if (!protocol)
                                  protocol = "http://"
                                return `<a href="${protocol+address}" target="_blank">${match}</a>`;
                              });
    const textWithBrs = textWithLinks.replace(/\n/g, "<br />")
    return textWithBrs
  }

}

@Pipe({
        name: 'rtWbr',
        pure: true
      })
export class WbrPipe implements PipeTransform {


  transform(value: string): string {
    return value && value.replace("/", "/<wbr />");
  }

}

@Pipe({
        name: 'rtHasInformationOtherThanPackCount',
        pure: true
      })
export class HasInformationOtherThanPackCountPipe implements PipeTransform {

  static calculate(value: ShipmentPackage, shipment: Shipment | Container) {
    return !!value.weight ||
           !!value.volume ||
           HasInformationOtherThanPackCountVolumeWeightPipe.calculate(value, shipment)
  }

  transform(value: ShipmentPackage | ShipmentPackage[], shipment: Shipment | Container): boolean {
    if (!(value instanceof Array))
      value = [value]
    return value.any(p => HasInformationOtherThanPackCountPipe.calculate(p, shipment))
  }
}

@Pipe({
        name: 'rtHasInformationOtherThanPackCountVolumeWeight',
        pure: true
      })
export class HasInformationOtherThanPackCountVolumeWeightPipe implements PipeTransform {

  static calculate(value: ShipmentPackage, shipment: Shipment | Container) {
    const goods: ShipmentGoods[] = shipment.goods instanceof Array ? shipment.goods : [shipment.goods];
    return this.hasDifferentValue(value.goodsDescription, goods.map(g => g.goodsDescription)) ||
           this.hasDifferentValue(value.marksAndNumbers, goods.map(g => g.marksAndNumbers)) ||
           value.dangerousGoods.length > 0 ||
           !!value.dimensionsPerPiece;
  }

  private static hasDifferentValue(value: string, compare: string[]) {
    return value && /\S/.test(value) && compare.contains(value)
  }

  transform(value: ShipmentPackage, shipment: Shipment| Container): boolean {
    return HasInformationOtherThanPackCountVolumeWeightPipe.calculate(value, shipment)
  }
}

@Pipe({
        name: 'rtPackCount',
        pure: true
      })
@Injectable({providedIn: "root"})
export class PackCountPipe implements PipeTransform {

  transform(value: ShipmentPackage[]): number | null {
    if (value.any(p => typeof p.packCount !== "number"))
      return null;
    return value.sum(p => p.packCount)
  }

}

@Pipe({
        name: 'rtPackWeight',
        pure: true
      })
@Injectable({providedIn: "root"})
export class PackWeightPipe implements PipeTransform {

  transform(value: ShipmentPackage[]): DimensionValue | null {
    if (value.any(p => !p.weight))
      return null;
    const weightSum = value.sum(p => p.weight.value);
    return {value: weightSum, dimension: value[0].weight.dimension};
  }

}


@Pipe({
        name: 'rtPackVolume',
        pure: true
      })
@Injectable({providedIn: "root"})
export class PackVolumePipe implements PipeTransform {

  transform(value: ShipmentPackage[]): DimensionValue | null {
    if (value.any(p => !p.volume))
      return null;
    const volumeSum = value.sum(p => p.volume.value);
    return {value: volumeSum, dimension: value[0].volume.dimension};
  }

}

@Pipe({
        name: 'rtIsContainer',
        pure: true
      })
@Injectable({providedIn: "root"})
export class IsContainerPipe implements PipeTransform {

  transform(shipment: Shipment | Container): shipment is Container {
    return ContainerService.isContainer(shipment);
  }

}

@Pipe({
        name: 'rtIsContainerOverviewItem',
        pure: true
      })
@Injectable({providedIn: "root"})
export class IsContainerOverviewItemPipe implements PipeTransform {

  transform(shipment: ShipmentOverviewItem | ContainerOverviewItem): shipment is ContainerOverviewItem {
    return shipment instanceof ContainerOverviewItem;
  }

}

@Pipe({
        name: 'rtIsShipmentOverviewItem',
        pure: true
      })
@Injectable({providedIn: "root"})
export class IsShipmentOverviewItemPipe implements PipeTransform {

  transform(shipment: ShipmentOverviewItem | ContainerOverviewItem): shipment is ShipmentOverviewItem {
    return shipment instanceof ShipmentOverviewItem;
  }

}

@Pipe({
        name: 'rtIsFcl',
        pure: true
      })
@Injectable({providedIn: "root"})
export class IsFclPipe implements PipeTransform {

  transform(shipment: Shipment | Container): boolean {
    if (ContainerService.isContainer(shipment))
      return shipment.containerModes.contains("FCL")
    return shipment.containerMode === 'FCL'
  }

}

@Pipe({
        name: 'rtIsOperatingReefer',
        pure: true
      })
@Injectable({providedIn: "root"})
export class IsOperatingReeferPipe implements PipeTransform {

  transform(value: ShipmentContainer | ShipmentContainerGroup ): boolean {
    if (IsContainerGroup(value))
      return value.commonIsOperatingReefer;
    return value.operatingReefer
  }

}

export function IsContainerGroup(value: ShipmentContainer | ShipmentContainerGroup): value is ShipmentContainerGroup {
  return "containers" in value;
}

@Pipe({
        name: 'rtHasNoClientReferences',
        pure: true
      })
@Injectable({providedIn: "root"})
export class RtHasNoClientReferencesPipe implements PipeTransform {

  transform({shipperReferences, deliveryReferences, pickupReferences, notifyReferences, consigneeReferences}: Shipment | Container): boolean {
    const hasClientReferecnes =
      [shipperReferences,
       deliveryReferences,
       pickupReferences,
       notifyReferences,
       consigneeReferences].any(a => a.any());
    return !hasClientReferecnes;
  }

}

@Pipe({
        name: 'rtAddress',
        pure: true
      })
@Injectable({providedIn: "root"})
export class RtAddress implements PipeTransform {

  transform(location: AddressLocation | AddressLocation[], multipleAddressPlaceholder = FieldRenderer.multipleLocations): string {
    if (location instanceof  Array) {
      if (location.length > 1)
        return multipleAddressPlaceholder;
      location = location[0];
    }

    if (!location)
      return "";
    const {
      addressLine1,
      addressLine2,
      city,
      country,
      name,
      postCode,
      state
    } = location
    return [
      name,
      addressLine1,
      addressLine2,
      city && postCode ? `${postCode} ${city}` : null,
      state?.toUpperCase(),
      country?.name?.toUpperCase()]
      .filter(l => !!l)
      .join("<br />");
  }
}

@Pipe({
        name: 'rtCountry',
        pure: true
      })
@Injectable({providedIn: "root"})
export class RtCountry implements PipeTransform {

  private readonly _fieldRenderer: FieldRenderer;

  constructor(fieldRenderer: FieldRenderer) {
    this._fieldRenderer = fieldRenderer;
  }

  transform(country: Country | (Country[])): string {
    if (country instanceof Array) {
      return country
        .map(c => this._fieldRenderer.renderCountry(c))
        .join();
    } else {
      return this._fieldRenderer.renderCountry(country)
    }
  }
}

@Pipe({
        name: 'rtCountryFlag',
        pure: true
      })
@Injectable({providedIn: "root"})
export class RtCountryFlag implements PipeTransform {

  private readonly _fieldRenderer: FieldRenderer;

  constructor(fieldRenderer: FieldRenderer) {
    this._fieldRenderer = fieldRenderer;
  }

  transform(country: Country | (Country[])): string {
    if (country instanceof Array) {
      return country
        .map(c => this._fieldRenderer.renderCountryFlag(c))
        .join();
    } else {
      return this._fieldRenderer.renderCountryFlag(country)
    }
  }
}

@Pipe({
        name: 'rtPhone',
        pure: true
      })
@Injectable({providedIn: "root"})
export class RtPhone implements PipeTransform {

  private readonly _fieldRenderer: FieldRenderer;

  constructor(fieldRenderer: FieldRenderer) {
    this._fieldRenderer = fieldRenderer;
  }

  transform(phone: PhoneWithCountry): string {
    return this._fieldRenderer.renderPhone(phone)
  }
}

@Pipe({
        name: 'rtAsContainerGroup',
        pure: true
      })
@Injectable({providedIn: "root"})
export class AsContainerGroupPipe implements PipeTransform {

  transform(value: ShipmentContainerGroup | ShipmentContainer): ShipmentContainerGroup {
    return "containers" in value ? value : null;

  }
}

@Pipe({
        name: 'rtAsContainer',
        pure: true
      })
@Injectable({providedIn: "root"})
export class AsContainerPipe implements PipeTransform {

  transform(value: ShipmentContainerGroup | ShipmentContainer): ShipmentContainer {
    return "containers" in value ? null : value;

  }
}

@Pipe({
        name: 'rtContainerStatusClass',
        pure: true
      })
@Injectable({providedIn: "root"})
export class ContainerStatusClassPipe implements PipeTransform {

  transform(value: ContainerStatus): string {
    switch (value) {
      case ContainerStatus.WaitingForPickup:
        return "text-light";
      case ContainerStatus.InTransit:
      case ContainerStatus.PartiallyInTransit:
        return "text-orange";
      case ContainerStatus.Delivered:
      case ContainerStatus.PartiallyDelivered:
        return "text-green";
    }

  }
}

@Pipe({
        name: 'rtContainerStatusName',
        pure: true
      })
@Injectable({providedIn: "root"})
export class ContainerStatusNamePipe implements PipeTransform {

  transform(value: ContainerStatus): string {
    switch (value) {
      case ContainerStatus.WaitingForPickup:
        return "Booked";
      case ContainerStatus.InTransit:
        return "In Transit";
      case ContainerStatus.Delivered:
        return "Delivered";
      case ContainerStatus.PartiallyDelivered:
        return "Partially Delivered"
      case ContainerStatus.PartiallyInTransit:
        return "Partially In Transit"

    }
  }
}
