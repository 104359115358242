import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fixedNumber',
  pure: true
})
export class FixedNumberPipe implements PipeTransform {

  transform(value: number|null|undefined, decimalPlaces = 3, defaultValue?: number): string {
    if (typeof value !== "number") {
      value = defaultValue;
    }
    if (typeof value !== "number")
      return "";
    return value.toFixed(decimalPlaces);
  }

}


