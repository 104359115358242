export enum Position {
    Top = "top",
    Left = "left",
    Bottom = "bottom",
    Right = "right"
}

export namespace Position {

    export function GetCycle(position: Position) {
        switch (position) {
            case Position.Top:
                return [Position.Top, Position.Bottom, Position.Left, Position.Right];
            case Position.Left:
                return [Position.Left, Position.Right, Position.Top, Position.Bottom];
            case Position.Bottom:
                return [Position.Bottom, Position.Top, Position.Left, Position.Right];
            case Position.Right:
                return [Position.Right, Position.Left, Position.Top, Position.Bottom];
            default:
                throw ("Invalid position");
        }
    }

    export function GetCycleWithDefaultPosition(position: Position) {
        return GetCycle(position).concat([position]);
    }
}

export enum Alignment {
    Start = "start",
    End = "end",
    Center = "center",
    Stretch = "stretch"
}

export enum AlertType {
    Error = "error",
    Information = "info",
    Warning = "warning",
    Success = "success"
};

export enum SelectOverflowMode {
    ItemCount = "itemCount",
    CropValues = "cropValues"
}

export enum SelectItemCountMode {
    Always = "always",
    Never = "never",
    Overflow = "overflow"
}

export enum PopupPosition {
    OutsideTop = "outsideTop",
    OutsideLeft = "outsideLeft",
    OutsideBottom = "outsideBottom",
    OutsideRight = "outsideRight",
    InsideTop = "insideTop",
    InsideLeft = "insideLeft",
    InsideBottom = "insideBottom",
    InsideRight = "insideRight"
}

export namespace PopupPosition {

    var insidePositions = [
        PopupPosition.InsideTop, PopupPosition.InsideLeft, PopupPosition.InsideBottom, PopupPosition.InsideRight
    ];

    var topPositions = [PopupPosition.InsideTop, PopupPosition.OutsideTop];
    var leftPositions = [PopupPosition.InsideLeft, PopupPosition.OutsideLeft];
    var bottomPositions = [PopupPosition.InsideBottom, PopupPosition.OutsideBottom];
    var rightPositions = [PopupPosition.InsideRight, PopupPosition.OutsideRight];

    export function FromPosition(position: Position): PopupPosition {
        switch (position) {
            case Position.Bottom:
                return PopupPosition.OutsideBottom;
            case Position.Left:
                return PopupPosition.OutsideLeft;
            case Position.Right:
                return PopupPosition.OutsideRight;
            case Position.Top:
                return PopupPosition.OutsideTop;
        }
        throw "Unknown position: " + position;
    }

    export function IsOutside(position: PopupPosition) {
        return !insidePositions.contains(position);
    }

    export function IsInside(position: PopupPosition) {
        return insidePositions.contains(position);
    }

    export function IsTop(position: PopupPosition) {
        return topPositions.contains(position);
    }

    export function IsLeft(position: PopupPosition) {
        return leftPositions.contains(position);
    }

    export function IsBottom(position: PopupPosition) {
        return bottomPositions.contains(position);
    }

    export function IsRight(position: PopupPosition) {
        return rightPositions.contains(position);
    }

    export function IsTopOrBottom(position: PopupPosition) {
        return IsTop(position) || IsBottom(position);
    }

    export function IsLeftOrRight(position: PopupPosition) {
        return IsLeft(position) || IsRight(position);
    }

    export function GetCycle(position: PopupPosition) {
        switch (position) {
            case PopupPosition.OutsideTop:
                return [
                    PopupPosition.OutsideTop, PopupPosition.OutsideBottom, PopupPosition.OutsideLeft,
                    PopupPosition.OutsideRight
                ];
            case PopupPosition.OutsideLeft:
                return [
                    PopupPosition.OutsideLeft, PopupPosition.OutsideRight, PopupPosition.OutsideTop,
                    PopupPosition.OutsideBottom
                ];
            case PopupPosition.OutsideBottom:
                return [
                    PopupPosition.OutsideBottom, PopupPosition.OutsideTop, PopupPosition.OutsideLeft,
                    PopupPosition.OutsideRight
                ];
            case PopupPosition.OutsideRight:
                return [
                    PopupPosition.OutsideRight, PopupPosition.OutsideLeft, PopupPosition.OutsideTop,
                    PopupPosition.OutsideBottom
                ];
            case PopupPosition.InsideTop:
                return [
                    PopupPosition.InsideTop, PopupPosition.InsideBottom, PopupPosition.InsideLeft,
                    PopupPosition.InsideRight
                ];
            case PopupPosition.InsideLeft:
                return [
                    PopupPosition.InsideLeft, PopupPosition.InsideRight, PopupPosition.InsideTop,
                    PopupPosition.InsideBottom
                ];
            case PopupPosition.InsideBottom:
                return [
                    PopupPosition.InsideBottom, PopupPosition.InsideTop, PopupPosition.InsideLeft,
                    PopupPosition.InsideRight
                ];
            case PopupPosition.InsideRight:
                return [
                    PopupPosition.InsideRight, PopupPosition.InsideLeft, PopupPosition.InsideTop,
                    PopupPosition.InsideBottom
                ];
            default:
                throw ("Invalid position");
        }
    }

    export function GetCycleWithDefaultPosition(position: PopupPosition) {
        const cycle = GetCycle(position);
        cycle.push(position);
        return cycle;
    }
}