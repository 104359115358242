import {Injectable} from '@angular/core';
import {JSONData, Country as ApiCountry} from "@api";


@Injectable({
              providedIn: 'root'
            })
export class CountryService {

  private readonly _countries: Country[];

  constructor() {
    this._countries = window.countries;
  }

  get countries(): Country[] {
    return this._countries.slice();
  }

  getName(isoAlpha2: string): string | null {
    if (!isoAlpha2)
      return null;
    isoAlpha2 = isoAlpha2.toUpperCase();
    return this._countries.firstOrDefault(c => c.isoAlpha2 === isoAlpha2)?.common || null;
  }

  getCallingCode(isoAlpha2: string): string | null {
    if (!isoAlpha2)
      return null;
    isoAlpha2 = isoAlpha2.toUpperCase();
    const callingCode = this._countries.firstOrDefault(c => c.isoAlpha2 === isoAlpha2)?.callingCode;
    return callingCode ? "+" + callingCode : null;
  }

  getCode(pk: string): string | null {
    if (!pk)
      return null;
    pk = pk.toLowerCase();
    return this._countries.firstOrDefault(c => c.pk === pk)?.common || null;
  }

  getByCallingCode(code: string): Country[] {
    if (!code)
      return null;
    if (code[0] === "+")
      code = code.substring(1);
    return this._countries.filter(c => c.callingCode === code);
  }

  getJsonData(): JSONDataContainer<Country>[] {
     return this._countries
       .map(c => {
         return {
           value: c.isoAlpha2,
           label: c.common,
           icon: c.isoAlpha2.toLowerCase() + " spriteCountryBorder",
           data: c
         }
       });
  }

  getByIsoAlpha2(isoAlpha2: string): null | Country {
      if (!isoAlpha2)
        return null;
      isoAlpha2 = isoAlpha2.toUpperCase();
      return this._countries.firstOrDefault(c => c.isoAlpha2 === isoAlpha2);
  }

  convertToApiCountry(country: Country):ApiCountry {
    if (!country)
      return null;
    return {
      isoAlpha2: country.isoAlpha2,
      name: country.common
    }
  }

  getCallingCodes(): JSONDataContainer<Country>[]{
    return this._countries
      .map(c => { return  {
        html : `${c.common}<span class="text-light"> +${c.callingCode || ""}</span>`,
        label : c.common,
        value : c.isoAlpha2,
        icon : `spriteCountryBorder ${c.isoAlpha2.toLowerCase()}`,
        data: c
      }})
  }

}
