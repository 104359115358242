import * as Utils from "../utils/Utils"
import {disableScrolling, enableScrolling} from "../utils/Utils"
import {EventEmitter} from "../utils/EventEmitter";
import {ControlEvent} from "../libs/ExtendableEvent";

require("../utils/JqueryLoader");
import {HistoryManager} from "../utils/HistoryManager"
import {FormSubmitService} from "@services/form-submit.service";

export class Modal implements IModalSettings {
  private static settings: IModalSettings = {
    type: "ajax",
    header: "Header",
    showOnClick: true,
    closeable: true
  };
  $modalWindow: JQuery;
  $header: JQuery;
  type: "form" | "ajax";
  header: string;
  text: string;
  html: string;
  showOnClick: boolean;
  buttons: (JQuery | HTMLElement)[] | { [index: string]: JQuery | HTMLElement | "close" | "action" | null; };
  contentElement: ContainerType;
  headerElement: ContainerType;
  closeable: boolean;
  $modalContainer: HTMLDivElement;
  readonly onClosed = new EventEmitter<ControlEvent<this>>("closed");
  customCloseAction: () => boolean;
  private readonly $element: JQuery;

  constructor(element?: HTMLElement, settings?: IModalSettings, runIntializer = true, customCloseAction: () => boolean = () => {
    return true
  }) {
    if (element) {
      this.$element = $(element);
      $(element).data("modal", this);
      if (this.$element.data("settings") && !settings) {
        settings = this.$element.getJsonFromAttribute("data-settings");
      }
    }
    $.extend(true, this, Modal.settings, settings);
    if (runIntializer)
      this.initialize();
    //needed for Angular
    HistoryManager;
    this.customCloseAction = customCloseAction;
  }

  destroy() {
    this.$modalContainer.remove();
    this.$modalWindow.parent().remove();
  }

  initialize() {
    // Create modal box
    this.$modalContainer = document.createElement("div");
    this.$modalContainer.classList.add("modal-container");
    this.$modalContainer.classList.add("hidden");
    this.$modalContainer.addEventListener("click", (e) => {
      if (this.customCloseAction())
        this.closeModal();
    });

    const modalWindow = document.createElement("div");
    modalWindow.classList.add("modal-window");

    this.$modalWindow = $(modalWindow);


    this.$modalContainer.appendChild(modalWindow);
    this.$modalWindow.wrap(`<div class="modal-scroll-container"></div>`);

    // Header
    const mHeader = document.createElement("div");
    mHeader.classList.add("modal-header");
    mHeader.addEventListener("click", (event) => {
      event.stopPropagation();
      return false;
    });
    if (this.headerElement) {
      $(Utils.UnwrapContainerFunction(this.headerElement)).appendTo(mHeader);
      modalWindow.appendChild(mHeader);
    } else if (this.header) {
      const headerText = document.createElement("h2");
      headerText.appendChild(document.createTextNode(this.header));
      mHeader.appendChild(headerText);
      modalWindow.appendChild(mHeader);
    }
    this.$header = $(mHeader).children();

    // Body
    const mBody = document.createElement("div");
    mBody.classList.add("modal-body");
    mBody.addEventListener("click", (event) => {
      event.stopPropagation();
      return false;
    });
    const bodyText = document.createElement("span");
    if (this.contentElement) {
      $(Utils.UnwrapContainerFunction(this.contentElement)).appendTo(mBody);
    } else {
      if (this.text) {
        bodyText.innerText = this.text;
      } else if (this.html) {
        bodyText.innerHTML = this.html;
      }
      mBody.appendChild(bodyText);
    }
    modalWindow.appendChild(mBody);

    // Footer
    const mFooter = document.createElement("div");
    mFooter.addEventListener("click", (event) => {
      event.stopPropagation();
      return false;
    });
    mFooter.classList.add("modal-footer");
    mFooter.classList.add("buttons");
    if (this.buttons instanceof Array) {
      for (const elem of this.buttons) {
        if (elem instanceof HTMLElement) {
          mFooter.appendChild(elem);
        } else {
          elem.appendTo(mFooter);
        }
      }
    } else if (this.buttons) {
      for (let key in this.buttons) {
        if (!this.buttons.hasOwnProperty(key)) {
          continue;
        }
        const value = this.buttons[key];
        if (typeof value === "string") {
          const button = document.createElement("a");
          button.classList.add("button");
          button.appendChild(document.createTextNode(key));

          switch (value) {
            case "action":
              button.addEventListener("click", this.closeModal.bind(this, () => this.doAction()));
              break;

            case "close":
              button.addEventListener("click", this.closeModal.bind(this));
              button.classList.add("secondary");
              break;

            default:
              button.classList.add("secondary");
          }

          mFooter.appendChild(button);
        } else if (value instanceof HTMLElement) {
          mFooter.appendChild(value);
        } else {
          value.appendTo(mFooter);
        }
      }
    }
    modalWindow.appendChild(mFooter);

    // Add modal window to document at the beginning
    $(document.body).prepend(this.$modalContainer);

    //window.addEventListener('resize', this.setPosition.bind(this));
    if (this.showOnClick && this.$element) {
      this.$element.on("click", this.openModal.bind(this));
    }
  }

  isOpen() {
    return this.$modalContainer.classList.contains("show");
  }

  openModal() {
    if (this.isOpen()) {
      return;
    }
    disableScrolling();
    this.$modalContainer.classList.remove("hidden");
    this.$modalContainer.classList.add("show");
    $("<div class=\"modal-overlay\"></div>").prependTo(this.$modalContainer);
    if (this.closeable)
      window.historyManager.pushState({
                                        onSkip: (dir) => {
                                          if (dir === "backwards" && this.isOpen()) {
                                            this.closeModal();
                                            window.historyManager.deleteForwardHistory();
                                          }
                                        },
                                        onLeave: (dir) => {
                                          if (dir === "backwards" && this.isOpen()) {
                                            this.closeModal();
                                            window.historyManager.deleteForwardHistory();
                                          }
                                        },
                                        url: location.href
                                      });
  }

  closeModal(action?: (modal?: Modal) => void) {
    enableScrolling();
    this.$modalContainer.classList.remove("show");
    Utils.sleep(300).then(() => {
      this.$modalContainer.classList.add("hidden");
      for (let i: number = 0; i < this.$modalContainer.children.length; i++) {
        if (this.$modalContainer.children.item(i).classList.contains("modal-overlay"))
          this.$modalContainer.children.item(i).remove();
      }
      this.onClosed.fire(new ControlEvent(this));
    });
    // Do action if needed
    if (typeof action === "function")
      action(this);

  }

  closeAndDeleteModal(action?: (modal?: Modal) => void) {
    this.onClosed.oneTime(() => this.destroy());
    this.closeModal(action);
  }

  doAction() {
    if (this.type === "form") {
      const form = document.createElement("form");
      form.method = "POST";
      form.action = this.$element.data("href");

      const input = document.createElement("input");
      input.type = "text";
      const param = this.$element.data("value").split("=");
      input.name = param[0];
      input.value = param[1];
      form.appendChild(input);

      $(form).append("<input type=\"submit\" value=\"do\"/>");
      form.style.display = "none";
      document.body.appendChild(form);

      FormSubmitService.replaySubject.next({
                                             formData: new FormData(form),
                                             targetUrl: form.action
                                           })
    } else {
      const param = this.$element.data("value").split("=");

      $.ajax({
               type: "POST",
               url: this.$element.data("href"),
               data: param[1],
               async: false
             });
    }
  }

}

interface IModalSettings {
  type?: "form" | "ajax";
  header?: string;
  text?: string;
  html?: string;
  buttons?: (JQuery | HTMLElement)[] | { [index: string]: JQuery | HTMLElement | string };
  showOnClick?: boolean;
  contentElement?: ContainerType;
  headerElement?: ContainerType;
  closeable?: boolean;
}


declare global {
  interface JQuery {
    modal(options?: IModalSettings): JQuery;

    modal(loadDataSettings: true): JQuery;
  }
}

jQuery.fn.modal = function (this: JQuery, options?: IModalSettings | true) {
  return this.each((index, elem) => {
    if (options === true) {
      const $this = $(this);
      options = $this.getJsonFromAttribute<IModalSettings>("data-modal-settings")
                || $this.getJsonFromAttribute<IModalSettings>("data-settings");
    }
    // ReSharper disable once WrongExpressionStatement
    new Modal(elem as HTMLElement, options);
  });
}
