import {Injectable} from "@angular/core";
import {UserRightsStore} from "@app/stores/user-rights-store.service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()
export class NavMenuQuery {
    constructor(userRightsStore: UserRightsStore) {
        this.forwarding$ =
            userRightsStore
                .observe(["shipments", "orders"])
                .pipe(map(r => !!r.shipments || !!r.orders));
        this.shipments$ = userRightsStore.observe("shipments");
        this.orders$ = userRightsStore.observe("orders");

        this.userManagement$ =
            userRightsStore
                .observe(["userManagement", "entityManagement"])
                .pipe(map(r => !!r.entityManagement || !!r.userManagement));
        this.users$ = userRightsStore.observe("userManagement");
        this.entities$ = userRightsStore.observe("entityManagement");

        this.administration$ = userRightsStore.observe("administration");
    }

    readonly forwarding$: Observable<boolean>;
    readonly shipments$: Observable<boolean>;
    readonly orders$: Observable<boolean>;
    readonly userManagement$: Observable<boolean>;
    readonly users$: Observable<boolean>;
    readonly entities$: Observable<boolean>;
    readonly administration$: Observable<boolean>;

}
