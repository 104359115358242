// @ts-nocheck
import {HubConnection, HubConnectionBuilder, HubConnectionState} from "@microsoft/signalr";
import {closeAlert, openAlert} from "../widgets/Alert";
import {disableElementsWhenOffline} from './Utils';
import * as detect from "./detect";

require("./JqueryLoader");

require("signalr");

export module ConnectionChecker {
  let $alert: JQuery;
  let hubConnection: HubConnection;
  let stopCalled = false;

  window.isOffline = false;


  setupSignalR();

  disableElementsWhenOffline(() => $(":submit, a[href], .disable-when-offline"));
  $(document.body).on("click", "a[href]", e => {
    if (window.isOffline) {
      e.preventDefault();
      if (!(e.target as HTMLElement).classList.contains("button"))
        highlightAlert();
    }
  });

  $(window)
    .on("online", () => {
      window.isOffline = false;
      closeAlert($alert);
      $alert = null;
    })
    .on("offline", () => {
      if (!$alert) {
        window.isOffline = true;
        $alert = openAlert({
                             type: "warning",
                             content: "Cannot connect to server. Please check your internet connection and try again.",
                             timeout: false,
                             dontDisplayX: true
                           });
      }
    });

  let offlineHighlightTimeout: number;

  function highlightAlert() {
    $alert
      .addClass("alert-error")
      .removeClass("alert-warning");
    window.clearTimeout(offlineHighlightTimeout);
    offlineHighlightTimeout = window.setTimeout(() => {
      $alert
        .addClass("alert-warning")
        .removeClass("alert-error");
    }, 3000)
  }

  if (detect.os.ios) {
    $(window).on('blur', function () {
      stopConnection();
    });
  }

  $(window).on('unload', function () {
    stopConnection();
  });

  $(window).on('beforeunload', function () {
    stopConnection();
  });

  function stopConnection() {
    stopCalled = true;
    // noinspection JSIgnoredPromiseFromCall
    hubConnection.stop();
  }

  function fireOfflineEvent() {
    window.dispatchEvent(new CustomEvent("offline", {cancelable: false}));
  }

  function fireOnlineEvent() {
    window.dispatchEvent(new CustomEvent("online", {cancelable: false}));
  }

  async function startConnection() {
    if (stopCalled)
      return;
    try {
      await hubConnection.start();
      fireOnlineEvent();
    } catch {
      if (stopCalled)
        return;
      fireOfflineEvent();
      window.setTimeout(() => startConnection(), 5000)
    }
  }

  function setupSignalR() {
    hubConnection = new HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl("/hubs")
      .build();
    // noinspection JSIgnoredPromiseFromCall
    startConnection();
    hubConnection.onclose(() => {
      if (stopCalled)
        return;
      fireOfflineEvent();
    });
    hubConnection.onreconnected(fireOnlineEvent);
  }

  window.addEventListener("focus", () => {
    const needsReconnecting = hubConnection.state === HubConnectionState.Disconnected;
    if (needsReconnecting) {
      // noinspection JSIgnoredPromiseFromCall
      startConnection();
    }
  })
}
