import {FormValidator, Modal, openAlert} from "../../../../widgets";
import {ShipmentListViewData, ShipmentService} from "../../../../services";
import {RoutingService} from "@app/services/routing.service";

export class BookingCancelModal extends Modal {
  readonly $modalContent = $(`
<span id="successMessage">Booking <strong  class="bookingNumber"></strong> has been canceled.</span>
<form novalidate>
      <p>
        Are you sure you want to cancel Booking <strong class="bookingNumber"></strong>? This cannot be undone.
      </p>
      <div class="form-group">
        <label for="txtCancelReason">Reason for Cancellation</label>
        <textarea id="txtCancelReason" required style="height: auto; background-color: #fff;"></textarea>
      </div>
    </form>`);
  readonly $successMessage = this.$modalContent.filter("#successMessage")
  readonly $form = this.$modalContent.filter("form")
  readonly $bookingNumber = this.$modalContent.find("#bookingNumber");
  readonly $modalHeader = $(document.createElement("h2"))
    .text("Cancel Booking");
  readonly $abortCancelationButton = $(document.createElement("button"))
    .addClass("button secondary")
    .attr("type", "button")
    .text("Keep Booking")
    .click((e) => {
      this.closeModal();
    });
  readonly $backToShipments = $(document.createElement("button"))
    .addClass("button")
    .text("Back to Shipments")
    .attr("type", "button")
    .click((e) => {
      this.closeModal();
      window.ngInjector.get(RoutingService).navigateTo(new ShipmentListViewData());
    })
    .hide()
    .disableWhenOffline();
  readonly $viewBooking = $(document.createElement("button"))
    .addClass("button secondary")
    .text("View Booking")
    .attr("type", "button")
    .click(() => {
      this.closeModal();
    })
    .hide()
    .disableWhenOffline();
  readonly $cancelButton = $(document.createElement("button"))
    .addClass("button")
    .text("Cancel Booking")
    .css({
           "min-width": 180,
           textAlign: "center"
         })
    .click(() => {
      void this._cancelBooking()
    })
    .disableWhenOffline();
  private readonly _validator = new FormValidator(this.$form);

  constructor() {
    super(null, {});
    const settings = {
      contentElement: this.$modalContent,
      buttons: [this.$cancelButton,
                this.$abortCancelationButton,
                this.$backToShipments,
                this.$viewBooking],
      headerElement: this.$modalHeader
    };
    Object.assign(this, settings);
    this.initialize();
    this.$modalWindow
        .css({width: 585})
        .attr("id", "cancelBookingModal")
  }

  private _reasonText: string;

  get reasonText(): string {
    return this._reasonText;
  }

  set reasonText(value: string) {
    this._reasonText = value;
    this.$reasonText.val(value)
  }

  readonly $reasonText = this.$modalContent
                             .find("#txtCancelReason")
                             .on("input", () => {
                               this._reasonText = this.$reasonText.val().trim()
                             })

  private _bookingNumber: string

  get bookingNumber(): string {
    return this._bookingNumber;
  }

  set bookingNumber(value: string) {
    this._bookingNumber = value;
    this.$bookingNumber.text(value)
  }

  closeModal(action?: (modal?: Modal) => void): void {
    super.closeModal(action);
    this.reasonText = "";
  }

  openModal(): void {
    this.$form.show();
    this.$successMessage.hide()
    this.$cancelButton
        .removeClass("loading")
        .removeAttr("disabled");
    this.$abortCancelationButton.removeAttr("disabled")
    this.$reasonText.removeAttr("disabled");
    this.$abortCancelationButton.show();
    this.$cancelButton.show();
    this.$viewBooking.hide();
    this.$backToShipments.hide();
    this._validator.resetValidation();
    this.$modalHeader.text("Cancel Booking")
    super.openModal();
  }

  private async _cancelBooking() {
    if (!await this._validator.validate()) {
      return;
    }
    this.$cancelButton
        .addClass("loading")
        .attr("disabled", "disabled");
    $([this.$abortCancelationButton, this.$reasonText])
      .attr("disabled", "disabled");
    try {

      const result: boolean = await $.post({
                                             url: "/WebMethod/CancelBooking",
                                             data: {
                                               reason: this.reasonText,
                                               bookingNumber: this.bookingNumber
                                             }
                                           }) as boolean;
      if (!result) {
        // noinspection ExceptionCaughtLocallyJS
        throw "";
      }
      this.$abortCancelationButton.hide();
      this.$cancelButton.hide();
      this.$viewBooking.show();
      this.$backToShipments.show();
      this.$form.hide();
      this.$successMessage.show();
      this.$header.text("Booking Canceled");
      await window.ngInjector.get(ShipmentService).reloadCurrentShipment()
    } catch (e) {
      openAlert({
                  type: "error",
                  content: "The booking could not be canceled. Please contact your Röhlig representative.",
                  timeout: 5000,
                  dontDisplayX: false
                }
      );
    }
  }
}
