import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const requestWithoutCache =
            request.clone({
                              headers: request.headers
                                              .set("Cache-Control", "noc-cache")
                                              .set("Pragma", "no-cache")
                          });
        return next.handle(requestWithoutCache);
    }

}
