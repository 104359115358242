import {Injectable} from "@angular/core";
import {Store} from "@app/stores/store";
import {UserRightsStoreState} from "@app/stores/userRightsStoreState";

@Injectable({providedIn: "root"})
export class UserRightsStore extends Store<UserRightsStoreState> {

  private static readonly _initialState: UserRightsStoreState = {
    administration: false,
    bookings: false,
    canChangeUserGroups: false,
    canCreateSites: false,
    canCreateUsers: false,
    canHaveBookingRights: false,
    documents: false,
    entityManagement: false,
    exceptions: false,
    exportToExcel: false,
    invoices: false,
    isAuthenticated: false,
    isSuperUser: false,
    messaging: false,
    orders: false,
    products: false,
    shipments: false,
    userManagement: false,
    userGroup: null,
    userType: null
  };

  constructor() {
    super({}, UserRightsStore._initialState, false);
  }

  getName(): string {
    return "UserRightsStore";
  }
}
