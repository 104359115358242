<div class="overflow"></div>
<div class="sidebar">
  <button class="button secondary mat-icon-close text-hide" (click)="close()">Close</button>
  <div class="content" *ngIf="entity$ | async as entity">

    <header>
      <h2>Entity Details</h2>
    </header>
    <div class="data-view">
      <p>
        <label>Entity Name</label>
        {{entity.name | unescapeHtml}}
      </p>
      <p>
        <label>Countries</label>
        <span style="display: block;" *ngFor="let country of entity.countries" [innerHTML]="country | rtCountry | safeHtml"></span>
      </p>
      <p>
        <label>Global Account Manager</label>
        <ng-container *ngFor="let manager of entity.globalAccountManagers">
          {{manager.name | unescapeHtml}}
          <span class="label" *ngIf="!manager.isActive">Deactivated</span>
          <br />
        </ng-container>
      </p>
      <p>
        <label>Local Account Manager</label>
        <ng-container *ngFor="let manager of entity.localAccountManagers">
          {{manager.name | unescapeHtml}}
          <span class="label" *ngIf="!manager.isActive">Deactivated</span>
          <br />
        </ng-container>
      </p>
      <p>
        <label>Local Real Time Admin</label>
        <ng-container *ngFor="let admin of localAdmins">
          {{admin.name | unescapeHtml}}
          <span class="label" *ngIf="!admin.isActive">Deactivated</span>
          <br />
        </ng-container>
      </p>
      <p>
        <label>Entity Set Name</label>
        {{entity.entitySet?.name || "" | unescapeHtml}}
      </p>
      <p>
        <label>Organizations</label>
        <ng-container *ngFor="let org of entity.organizations">
          {{org.name | unescapeHtml}}<br />
          <label style="font-size: 9pt; display: inline-block;">{{org.code}}</label>
          <span class="label" style="float: right;"> {{org.system | unescapeHtml}}</span><br />
          <br />
        </ng-container>
      </p>
    </div>
  </div>
</div>
