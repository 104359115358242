import {ContentComponent} from "@utils/Angular/ContentComponent";
import {ApplicationRef, Directive, ElementRef} from "@angular/core";
import {Router} from "@angular/router";
import {BookingModel} from "./BookingModel";
import {FormValidator} from "@widgets/Validate";
import {IView} from "@utils/Angular/View";
import {filter, takeUntil} from "rxjs/operators";
import {hasValue} from "@utils/rxjs-extensions";
import {GetActiveViewIndex, GetNextView, GetPreviousView, GetView, IViewDef, SetViewObject, ViewArrangement} from "@booking/booking-views";
import {BookingMode, BookingServiceListener} from "@services/booking-service-listener.service";
import { ShipmentListViewData } from '@services';
import detect = require("@utils/detect");

@Directive()
export abstract class ViewComponent extends ContentComponent implements IView {
  nextClicked = false;
  FormValidator: FormValidator;
  viewDef: IViewDef;
  mode: BookingMode;
  disableAnimations = detect.browser.edge || detect.browser.ie || detect.browser.safari;
  readonly isSubView: boolean = false;
  public model: BookingModel
  protected readonly bookingServiceListener: BookingServiceListener;

  protected constructor(element: ElementRef,
                        app: ApplicationRef,
                        protected readonly router: Router,
                        bookingServiceListener: BookingServiceListener
  ) {
    super(element, app);
    this.bookingServiceListener = bookingServiceListener;
    this.model = new BookingModel();
    bookingServiceListener.bookingModel$
                          .pipe(
                            filter(hasValue),
                            takeUntil(this.destroyed$)
                            )
                          .subscribe(b => this.model = b);
    SetViewObject(this);
    this.mode = bookingServiceListener.currentBookingMode;
    bookingServiceListener
      .mode$
      .subscribe(m => this.mode = m)
    this.viewDef = GetView(this);

    try {
      this._canNavigateNext = !!GetNextView(this);
    } catch (e) {
      this._canNavigateNext = false;
    }
    try {
      this._canNavigateBack = !!GetPreviousView(this);
    } catch (e) {
      this._canNavigateBack = false;
    }
  }

  abstract readonly name: string;

  private _isValid: boolean;

  get isValid(): boolean {
    return this._isValid;
  }

  set isValid(value: boolean) {
    this._isValid = value;
  }

  private _isActivated: boolean;

  get isActivated(): boolean {
    return this._isActivated;
  }

  set isActivated(value: boolean) {
    this._isActivated = value;
  }

  private _canExit: boolean = true;

  get canExit(): boolean {
    return this._canExit;
  }

  set canExit(value: boolean) {
    this._canExit = value;
  }

  private _canNavigateNext: boolean;

  get canNavigateNext(): boolean {
    return this._canNavigateNext;
  }

  set canNavigateNext(value: boolean) {
    this._canNavigateNext = value;
  }

  private _canNavigateBack: boolean;

  get canNavigateBack(): boolean {
    return this._canNavigateBack;
  }

  set canNavigateBack(value: boolean) {
    this._canNavigateBack = value;
  }

  validate() {
    return this.FormValidator.validate();
  }

  async entered() {
    //this.animationsDisabled = false;
    this.model.lastViewIndex = GetActiveViewIndex();
    this._isActivated = true;
    if (this.viewDef) {
      this.viewDef.isActivated = true;
    }
  }

  left() {
    $(this.element)
      .find(".fake-select.dropdown-open")
      .each(function (this: HTMLElement) {
        $(this).data("dropdown").close();
      });
    if (this.nextClicked) {
      this.validate();
    } else if (this.viewDef) {
      this.viewDef.valid = this.viewDef.validate(this.model, this.mode);
    }
    //this.animationsDisabled = true;
  }

  canLeave() {
    return Promise.resolve(true);
  }

  ngAfterViewChecked(): void {
    super.ngAfterViewChecked();
    if (!this.isSubView) {
      ViewArrangement.activeView = this;
    }
  }

  onInitialized(): void {
    super.onInitialized();
    if (!this.FormValidator) {
      this.FormValidator = new FormValidator($(this.element));
    }
    if (!this.FormValidator.onValidated.hasHandlersAttached()) {
      this.FormValidator.onValidated.on(e => {
        this._isValid = e.data;
        if (this.viewDef) {
          this.viewDef.valid = e.data
        }
      });
    }
    if (document.body.scrollTo) {
      document.body.scrollTo({behavior: "smooth", top: 0, left: 0});
    } else {
      document.body.scrollTop = 0;
    }
    $(this.element).find(".disable-when-offline").disableWhenOffline();
    if (this.viewDef && this.viewDef.needsValidation) {
      this.validate();
      this.viewDef.needsValidation = true;
    }
  }

  navigateNext() {
    this.nextClicked = true;
    const view = GetNextView(this);
    if (view) {
      this.router.navigateByUrl(view.address);
    }
  }

  navigateBack() {
    const view = GetPreviousView(this);
    if (view) {
      this.router.navigateByUrl(view.address);
    }
  }

  cancel() {
    this.bookingServiceListener.requestCancellation(new ShipmentListViewData().url);
  }

}
