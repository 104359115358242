import {ChangeDetectionStrategy, Component, ElementRef, HostListener, OnDestroy, ViewChild} from '@angular/core';
import {AccountStore, ShipmentStore, StoreCutoutAccessors, UserRightsStore} from "@stores";
import {
  DangerousGoodsData,
  DeliveryStatus,
  JobType,
  Shipment,
  ShipmentContainer,
  ShipmentContainerGroup,
  TransportMode,
  UserInformation
} from "@api";
import {Observable} from "rxjs";
import {delay, map, mergeMap, takeUntil} from "rxjs/operators";
import {ShipmentService} from "@services";
import {checkScrollMargin, sleep} from "@utils/Utils";
import * as Isotope from "isotope-layout";
import {AppBaseComponent} from "@app/appBaseComponent";
import {IsContainerGroup, RtDatePipe} from "@app/utils/rt-date.pipe";
import {BookingCancelModal} from "@app/page-components/shipment/shipment-detail/bookingCancelModal";
import {ShipmentTypeUrlParamPipe} from "@app/value-pipes/string-value.pipe";

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-var-requires
require("isotope-packery");

@Component({
             selector: 'rt-shipment-detail',
             templateUrl: './shipment-detail.component.html',
             styleUrls: ['./shipment-detail.component.scss'],
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class ShipmentDetailComponent extends AppBaseComponent implements OnDestroy {

  private static readonly _cancelModal = new BookingCancelModal()
  readonly boxStateAccessors: StoreCutoutAccessors<UserInformation, "shipmentBoxOpenStates">;
  readonly hasDocumentRights$: Observable<boolean>;
  readonly hasInvoiceDocumentRights$: Observable<boolean>;
  readonly shipment$: Observable<Shipment>;
  readonly JobType = JobType;
  readonly hasOrderRights$: Observable<boolean>;
  readonly deliveryStatuses = DeliveryStatus;
  private readonly _shipmentService: ShipmentService;
  private readonly _accountStore: AccountStore;
  private readonly _shipmentStore: ShipmentStore;
  private readonly _userRightsStore: UserRightsStore;
  private hasBookingRights$: Observable<boolean>;
  private readonly _elementRef: ElementRef;
  private _iso: Isotope;
  private readonly _datePipe: RtDatePipe;

  constructor(accountStore: AccountStore,
              userRightsStore: UserRightsStore,
              shipmentStore: ShipmentStore,
              shipmentService: ShipmentService,
              elementRef: ElementRef,
              datePipe: RtDatePipe) {
    super();
    this._datePipe = datePipe;
    this._elementRef = elementRef;
    this._userRightsStore = userRightsStore;
    this._shipmentStore = shipmentStore;
    this._accountStore = accountStore;
    this._shipmentService = shipmentService;
    this.boxStateAccessors = accountStore.getStoreCutoutAccessors("shipmentBoxOpenStates", [
      "map",
      "shipmentInformation",
      "documents",
      "orders",
      "events",
      "goods",
      "notes",
      "clientReferences",
      "parties",
      "timeline",
    ]);

    this.hasDocumentRights$ = userRightsStore.observe("documents");
    this.hasInvoiceDocumentRights$ = userRightsStore.observe("invoices");
    this.hasBookingRights$ = userRightsStore.observe("bookings");
    this.hasOrderRights$ = userRightsStore.observe("orders")

    this.shipment$ = this._shipmentStore.observe("currentShipment");
  }

  @ViewChild("grid")
  set gridRef(value: ElementRef) {
    this._setupIsotype(value?.nativeElement)
  }

  hasNoGoodsInformation({goods}: Shipment): boolean {
    for (const good of Object.values(goods)) {
      if (good instanceof Array) {
        if (good.any())
          return false;
      } else if (good)
        return false;
    }
    return true;
  }

  getShipmentIfCanCancelBooking = (shipment: Shipment): Shipment => {
    if (!shipment)
      return null;
    if (!shipment.isBooking)
      return null;
    if (shipment.status === DeliveryStatus.Canceled)
      return null;
    const bookingEntityIds = this._accountStore.get("currentEntitySet").bookingSites.map(s => s.value);
    if (shipment.bookingEntityIds.any(i => bookingEntityIds.contains(i)))
      return shipment;
    return null;
  };

  removeContainerGroupData(shipmentContainer: ShipmentContainer, shipmentContainerGroup: ShipmentContainerGroup): ShipmentContainer {
    const output = Object.assign({}, shipmentContainer);
    if (shipmentContainerGroup.commonTemperature)
      delete output.temperature;
    if (shipmentContainerGroup.commonIsOperatingReefer)
      delete output.operatingReefer;
    if (shipmentContainerGroup.commonType)
      delete output.type
    return output;
  }

  getContainerDangerousGoods(containerBase: ShipmentContainer | ShipmentContainerGroup): Grouping<DangerousGoodsData, string>[] {
    if (IsContainerGroup(containerBase)) {
      return containerBase
        .containers
        .map(c => c.dangerousGoods)
        .flat()
        .distinct(dg => JSON.stringify(dg))
        .groupBy(dg => dg.unNumber)
    } else
      return containerBase.dangerousGoods.groupBy(dg => dg.unNumber)
  }
  readonly getQueryParams = (item: ShipmentContainer): { ContainerID?: string } => {
    return {
      ContainerID: item.containerID
    }
  }
  toOrderNumberList(orderNumbers: string[]): string {
    return orderNumbers.join("<br />");
  }

  hasNoDocuments(shipment: Shipment): boolean {
    return !shipment.documents?.any() &&
           !shipment.customsDeclarationDocuments?.any() &&
           !shipment.invoiceDocuments?.any();
  }

  hasNoParties(shipment: Shipment): boolean {
    return !shipment.shipperLocation &&
           !shipment.consigneeLocation &&
           !shipment.pickupLocation &&
           !shipment.deliveryLocation;
  }

  shouldShowPorts(shipment: Shipment): boolean {
    return !shipment.transportModes.contains(TransportMode.Courier) &&
           !shipment.transportModes.contains(TransportMode.Road)
  }

  shouldShowCustomsEntry(shipment: Shipment): boolean {
    return shipment.customsEntryNumbers.length > 0;
  }

  @HostListener("window:resize")
  @HostListener("window:layout")
  async _updateLayout(): Promise<void> {
    if (!this._iso)
      return;
    await sleep(1);
    this._iso.layout();
    checkScrollMargin();
  }

  ngOnDestroy(): void {
    this._iso?.destroy()
  }

  openCancelModal(shipment: Shipment): void {
    const bookingNumber = shipment.rohligReferences.firstOrDefault(n => /^RT20\d{6}-\d+$/.test(n));
    const cancelModal = ShipmentDetailComponent._cancelModal;
    cancelModal.bookingNumber = bookingNumber;
    cancelModal.bookingNumber = bookingNumber;
    cancelModal.openModal();
  }

  private _setupIsotype(grid: HTMLElement) {
    this._iso?.destroy();
    if (!grid)
      return;
    this._iso = new Isotope(grid,
                            {
                              percentPosition: false,
                              itemSelector: ".box",
                              layoutMode: "packery",
                              transitionDuration: "0.2s",
                              packery: {
                                gutter: ".grid-gutter"
                              }
                            });
    this._accountStore
        .observe("shipmentBoxOpenStates")
        .pipe(
          takeUntil(this.destroyed$),
          delay(1)
        )
        .subscribe(() => void this._updateLayout());
  }
}

