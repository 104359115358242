import {Routes} from '@angular/router';
import {ChangePasswordCompleteViewData, ChangePasswordViewData, ErrorViewData, ForgotPasswordViewData, LoginViewData, RequestAccessViewData} from "@view-data";
import {DefaultRedirect, NotAuthenticated} from "@guards";
import { ChangePasswordComponent, ForgotPasswordComponent, LoginComponent, UnexpectedErrorComponent, RequestAccountComponent} from "@page-components";
import {LoginMasterComponent} from "@master-components";
import {  } from '../page-components/account/request-account/request-account.component';
import {ChangePasswordCompleteComponent} from "@app/page-components/account/change-password-complete/change-password-complete.component";

export const LoginRoutes: Routes = [
  {
    path:"Account",
    component: LoginMasterComponent,
    canActivate: [NotAuthenticated],
    children: [
      {
        path: "Login",
        component: LoginComponent,
        data: {viewData: new LoginViewData()},
        canActivate: [NotAuthenticated]
      },
      {
        path: "ForgotPassword",
        component: ForgotPasswordComponent,
        data: {viewData: new ForgotPasswordViewData()},
        canActivate: [NotAuthenticated]
      },
      {
        path: "ChangePassword",
        component: ChangePasswordComponent,
        data: {viewData: new ChangePasswordViewData()},
        canActivate: [NotAuthenticated]
      },
      {
        path: "ChangePasswordComplete",
        component: ChangePasswordCompleteComponent,
        data: {viewData: new ChangePasswordCompleteViewData()},
        canActivate: [NotAuthenticated]
      },
      {
        path: "RequestAccess",
        component: RequestAccountComponent,
        data: { viewData: new RequestAccessViewData()},
        canActivate: [NotAuthenticated]
      },
      {
        path: "",
        pathMatch: "full",
        canActivate: [DefaultRedirect],
        component: UnexpectedErrorComponent,
        data: {viewData: new ErrorViewData("UnexpectedError")}
      }
    ]
  }
];
