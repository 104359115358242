import {FormSubmitService} from "@services/form-submit.service";

const formSubmitHandler = async function (e: JQueryEventObject) {
  e.stopPropagation();
  e.stopImmediatePropagation();
  e.preventDefault();
  const presubmitEvent =
    new CustomEvent("presubmit", {cancelable: true, bubbles: true});
  const form = e.target as HTMLFormElement;
  form.dispatchEvent(presubmitEvent);
  if (presubmitEvent.promise instanceof Promise)
    await presubmitEvent.promise;
  if (window.isOffline)
    return;
  if (!presubmitEvent.defaultPrevented) {
    const realsubmitEvent = new CustomEvent("realsubmit", {cancelable: true, bubbles: true});
    form.dispatchEvent(realsubmitEvent);
    if (!realsubmitEvent.defaultPrevented) {
      FormSubmitService.replaySubject.next({
                                             targetUrl: form.action,
                                             formData: new FormData(form)
                                           })
    }
  }
};
document.body.addEventListener("submit", formSubmitHandler);
$(document.body).on("submit", formSubmitHandler);
$("form")
  .each(function (this:HTMLFormElement) {
    this.addEventListener("submit", formSubmitHandler);
    $(this).on("submit", formSubmitHandler);
  });
