import {Component, Input, Output, EventEmitter, HostBinding} from '@angular/core';
import {LogEntry} from "@api";

@Component({
             selector: 'rt-history-sidebar',
             templateUrl: './history-sidebar.component.html',
             styleUrls: ['./history-sidebar.component.scss'],
  host: {
               class: "sidebar-informations sidebar-right"
  }
           })
export class HistorySidebarComponent {

  @Output()
  close = new EventEmitter<void>()

  @HostBinding("class.open")
  @Input()
  historyOpen: boolean;

  @Input() logs: LogEntry[];

}
