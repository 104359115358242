import * as Detect from "../utils/detect";
import {Viewport} from "../utils/Viewport";
import * as nprogress from "nprogress";
import {disableScrolling, enableScrolling, scrollBarSize, sleep} from "../utils/Utils";

let $openedSidebar: JQuery;
const userBox = document.getElementsByClassName("user-box")[0] as HTMLElement;

const resizeHandler = () => {
    const scrollElement = document.body.parentElement;
    const scrollBarWidth = scrollElement.scrollHeight > scrollElement.clientHeight ? scrollBarSize + "px" : null;
  const main = document.getElementsByTagName("main")[0] as HTMLElement;
  main.style.paddingRight = scrollBarWidth;
  const header = document.getElementsByTagName("header")[0] as HTMLElement;
  header.style.paddingRight = scrollBarWidth;
  const userSideBar = document.querySelector("#sidebar-userpanel > .sidebar") as HTMLElement;
  if (userSideBar && userSideBar.parentElement.classList.contains("open")) {
        userSideBar.style.width = Math.max(257, userBox.clientWidth) + "px";
    }
};
let resizeHandlerSet = false;
const scrollHandler = function () {
    if (!$openedSidebar)
        return;
  const pageHeader = $(".page-header")[0];
  const breadcrumb = $(".breadcrumb")[0];
    const barsHeight = pageHeader.offsetHeight + breadcrumb.offsetHeight;
    const scrollTop = $(window).scrollTop();
    if (scrollTop < barsHeight)
        $openedSidebar.css("top", (barsHeight - scrollTop) + "px");
    else
        $openedSidebar.css("top", 0);
};
let scrollHandlerSet = false;

$(document.body).on("click", ".close-sidebar, .close, .sidebar-right .mat-icon-close", closeSidebar);

$(".sidebar-open").click(function (this: HTMLElement) {
    const $this = $(this);
    if (!$this.hasData("sidebar-target"))
        return;
    const $sidebar = $(document.getElementById($this.typedData("sidebar-target")));
    if ($sidebar.is(".open")) {
        closeSidebar();
        this.classList.remove("open");
    } else {
        openSidebar($sidebar);
        this.classList.add("open");
    }
});

$(".sidebar, .sidebar-right, .sidebar-menu").children(".overlay").mousedown((e) => {
    closeSidebar();
});

function addHandlers(enableScrollHandler: boolean) {
    if (!resizeHandlerSet) {
        window.addEventListener("resize", resizeHandler);
        resizeHandler();
    }
    if (scrollHandlerSet !== enableScrollHandler) {
        if (scrollHandlerSet) {
            window.removeEventListener("resize", scrollHandler);
            window.removeEventListener("scroll", scrollHandler);
        }
        else {
            window.addEventListener("resize", scrollHandler);
            window.addEventListener("scroll", scrollHandler);
            scrollHandler();
        }
    }
    resizeHandlerSet = true;
    scrollHandlerSet = enableScrollHandler;
}

function removeHandlers() {
    if (scrollHandlerSet) {
        window.removeEventListener("resize", scrollHandler);
        window.removeEventListener("scroll", scrollHandler);
    }
    if (resizeHandlerSet)
        window.removeEventListener("resize", resizeHandler);
    resizeHandlerSet = false;
    scrollHandlerSet = false;
}

export function openSidebar($sidebar: JQuery) {
    if ($openedSidebar) {
        if ($openedSidebar[0] === $sidebar[0])
            return;
        $(".sidebar-open.open").removeClass("open");
        $(".sidebar-informations-open.active").removeClass("active");
        $openedSidebar.addClass("closing");
        const $currentSideBar = $openedSidebar;
        $openedSidebar = null;
        window.setTimeout(() => $currentSideBar.removeClass("closing open"), 250);
        // noinspection JSIgnoredPromiseFromCall
        // closeSidebar();
    }
    if ($sidebar.children(".overlay").is()) {
        disableScrolling();
        $(document.body).scrollTop(0);
    }
    $sidebar.addClass("open");
    $sidebar.removeClass("closing");
    $openedSidebar = $sidebar;
    addHandlers($sidebar.hasClass("sidebar-informations"));
}

export async function closeSidebar() {
    if (!$openedSidebar)
        return;
    $(".sidebar-open.open").removeClass("open");
    $(".sidebar-informations-open.active").removeClass("active");
    $openedSidebar.addClass("closing");
    removeHandlers();
    if (Detect.browser.edge) {
        const $fix = $(".page-shipment .shipment-list li span").last();
        if (!(!$fix.is() || Viewport.width() < 720)) {
            $fix.stop().animate({"left": "49.8%"},
                                500,
                                function () {
                                    $fix.stop().animate({"left": "50%"}, 500);
                                });
        }
    }
    const $currentSideBar = $openedSidebar;
    await sleep(250);
    if (!$currentSideBar.hasClass("closing"))
        return;
    enableScrolling();
    $currentSideBar.removeClass("closing open");
    if ($openedSidebar && $currentSideBar[0] === $openedSidebar[0])
        $openedSidebar = null;
}

$(".sidebar-right .submenu").click(function (this: HTMLElement) {
    $(this)
        .toggleClass("open")
        .children("ul")
        .stop()
        .slideToggle();
});

$(document.body).on("click", ".sidebar-informations-open", async (event) => {
    event.preventDefault();
    const $element = $(event.target);
    const $sidebar = $(".sidebar-informations");
    const $sidebarContent = $sidebar.find(".content").first();

    if ($element.hasClass("toggle-button") && $element.hasClass("active")) {
        closeSidebar();
        return false;
    }

    let sidebarClosed: Promise<void>;
    if ($openedSidebar && $openedSidebar[0] === $sidebar[0])
        sidebarClosed = closeSidebar();

    if ($element.attr("data-href")) {
        nprogress.start();
        const response = await $.get($element.attr("data-href"), { id: $element.attr("data-id") });
        await sidebarClosed;
        $sidebarContent.html(response);
        nprogress.done();
    } else if ($element.attr("data-sidebar")) {
        await sidebarClosed;
        $sidebarContent.html("");
        const target = $element.data("sidebar");
        $("[data-sidebar-info=\"" + target + "\"]")
            .clone()
            .appendTo($sidebarContent);
    }

    if ($element.hasClass("toggle-button"))
        $element.addClass("active");
    openSidebar($sidebar);
});
