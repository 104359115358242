import * as Utils from "../utils/Utils";
import {EventEmitter} from "../utils/EventEmitter";
import {ControlDataEvent} from "../libs/ExtendableEvent";
import {NumericInput} from "./NumericInput";
require("../utils/JqueryLoader");

export class Timepicker {

    //language=html
    private static timepickerTemplate = `
<div class="timepicker">
    <div class="timepicker-group number-spinner">
        <span class="increase mat-icon-expandless"></span>
        <input type="text" class="small" placeholder="H" required data-no-validation-message data-disable-validation/>
        <span class="decrease mat-icon-expandmore"></span>
    </div>
    <div class="timepicker-group seperator">
        :
    </div>
    <div class="timepicker-group number-spinner">
        <span class="increase mat-icon-expandless"></span>
        <input type="text" class="small" max="59" min="0" min-length="2" placeholder="MM" required data-no-validation-message data-disable-validation />
        <span class="decrease mat-icon-expandmore"></span>
    </div>
    <div class="timepicker-group timePeriod number-spinner">
        <span class="increase mat-icon-expandless"></span>
        <div class="no-ui" style="cursor: pointer;">AM</div>
        <span class="decrease mat-icon-expandmore"></span>
    </div>
</div>
`;

    private static defaultOptions: ITimepickerOptions = {};
    readonly onTimeChanged = new EventEmitter<ControlDataEvent<this, Date>>("timechanged");
    private readonly $container: JQuery;
    private readonly $element: JQuery;
    private readonly txtHours: HTMLInputElement;
    private readonly txtMinutes: HTMLInputElement;
    private readonly txtPeriod: HTMLDivElement;
    private readonly options: ITimepickerOptions;
    private readonly hoursNumericInput: NumericInput;
    private readonly minutesNumericInput: NumericInput;

    private _previousMinutesValue: string;

    get previousMinutesValue() {
        return this._previousMinutesValue || "";
    }

    set previousMinutesValue(value) {
        this._previousMinutesValue = value;
    }

    private _previousHoursValue: string;

    get previousHoursValue() {
        return this._previousHoursValue || "";
    }

    set previousHoursValue(value) {
        this._previousHoursValue = value;
    }

    get time() {
        let hours = this.hoursNumericInput.value;
        const minutes = this.minutesNumericInput.value;
        if (hours === null && minutes === null)
            return null;
        if (this.options.format === "12h") {
            hours = hours % 12;
            if (this.txtPeriod.innerText === "PM") {
                hours = (hours + 12) % 24;
            }
        }
        return new Date(0, 0, 0, hours, minutes, 0, 0);
    }

    set time(date: Date) {
        if (!date || !date.hasTime())
        {
            this.clear();
            return;
        }
        this.minutesNumericInput.value = date.getMinutes();
        let hours = date.getHours();
        if (this.options.format === "12h") {
            this.txtPeriod.innerText = hours > 11 ? "PM" : "AM";
            hours = hours % 12;
            if (hours === 0)
                hours = 12;
        }
        this.hoursNumericInput.value = hours;
    }

    constructor(container: ContainerType, options: ITimepickerOptions = {}) {
        this.$container = $(Utils.UnwrapContainerFunction(container))
            .data("timepicker", this)
            .html(Timepicker.timepickerTemplate);
        this.$element = this.$container.children();
        this.options = Utils.ExtendOptions(Timepicker.defaultOptions, options);
        this.txtHours = this.$element.find("input:first")[0] as HTMLInputElement;
        this.txtMinutes = this.$element.find("input")[1] as HTMLInputElement;
        if (String.isNullOrEmpty(this.options.format)) {
            this.options.format = window.timeShortFormat.contains("t") ? "12h" : "24h";
        }
        if (this.options.format === "12h") {
            this.txtPeriod = this.$element.find("div.no-ui")[0] as HTMLDivElement;
            this.txtHours.max = "12";
            this.txtHours.min = "1";
        } else {
            this.$element.children().eq(3).remove();
            this.txtHours.max = "23";
            this.txtHours.min = "0";
        }
        if (this.options.required) {
            this.$element.find("input:first").prop("required", true);
        }
        this.$element.children()
            .eq(3)
            .find("span.increase, span.decrease")
            .mousedown((e) => {
                const callback = () => {
                    if (this.txtPeriod.innerText === "AM") {
                        this.txtPeriod.innerText = "PM";
                    } else {
                        this.txtPeriod.innerText = "AM";
                    }
                    this.triggerChangeEvent();
                };
                callback();
                const cancelAction = Utils.setMultival(callback, [{interval: 1000, steps: Infinity}]);
                $(e.delegateTarget).one("mouseup", () => {
                    cancelAction();
                });
            });
        let blockTimeTypeChange = false;
        this.$element.children()
            .eq(3)
            .on("wheel",
                (e) => {
                    const wheelEvent = e.originalEvent as WheelEvent;
                    if (wheelEvent.deltaY === 0) {
                        return;
                    }
                    e.preventDefault();
                    if (blockTimeTypeChange) {
                        return;
                    }
                    if (this.txtPeriod.innerText === "AM") {
                        this.txtPeriod.innerText = "PM";
                    } else {
                        this.txtPeriod.innerText = "AM";
                    }
                    blockTimeTypeChange = true;
                    window.setTimeout(() => blockTimeTypeChange = false, 100);
                    this.triggerChangeEvent();

                    this.triggerChangeEvent();
                });
        $(this.txtHours)
            .add(this.txtMinutes)
            .on("input", () => {
                this.triggerChangeEvent();
            });
        if (this.txtPeriod) {
            $(this.txtPeriod)
                .click(() => {
                    this.txtPeriod.innerText = this.txtPeriod.innerText === "AM" ? "PM" : "AM";
                    this.triggerChangeEvent();
                });
        }
        this.hoursNumericInput = new NumericInput(this.txtHours, {
            prerendered: true,
            selectTextOnFocus: true,
            enforceMinMaxBoundaries: true,
            spinnerButtons: "topBottomArrows"
        });
        if (this.options.format === "12h") {
            this.hoursNumericInput.onValueChanged.on((e) => {
                if (e.data.oldValue === 11 && e.data.newValue === 12)
                    this.txtPeriod.innerText = this.txtPeriod.innerText === "AM" ? "PM" : "AM";
                else if (e.data.oldValue === 12 && e.data.newValue === 11)
                    this.txtPeriod.innerText = this.txtPeriod.innerText === "AM" ? "PM" : "AM";
                this.triggerChangeEvent();
            });
        }
        this.minutesNumericInput = new NumericInput(this.txtMinutes, {
            prerendered: true,
            selectTextOnFocus: true,
            spinnerButtons: "topBottomArrows",
            enforceMinMaxBoundaries: true,
            formatter: v => v.toString().padLeft(2, "0")
        });
        this.txtMinutes.addEventListener("focus",
                                         () => {
                                             if (this.txtMinutes.value && this.txtMinutes.value.length === 2 &&
                                                 this.txtMinutes.value[0] === "0") {
                                                 this.txtMinutes.value = this.txtMinutes.value[1];
                                             }
                                             this.txtMinutes.select();
                                         });
        this.$element.add(this.$container).data("timepicker", this);
    }

    hasValue() {
        return this.hoursNumericInput.value !== null || this.minutesNumericInput.value !== null;
    }

    clear() {
        this.txtHours.value = null;
        this.txtMinutes.value = null;
        this.hoursNumericInput.value = null;
        this.minutesNumericInput.value = null;
        if (this.txtPeriod) {
            this.txtPeriod.innerText = "AM";
        }
    }

    private triggerChangeEvent() {
        if (this.txtMinutes.value || this.txtHours.value) {
            if (!this.txtMinutes.value && document.activeElement !== this.txtMinutes) {
                if (document.activeElement !== this.txtHours || (this.txtHours.value || "").length >=
                    this.previousHoursValue.length && this.txtHours.value !== this.previousHoursValue) {
                    this.minutesNumericInput.value = 0;
                }
            }
            if (!this.txtHours.value && document.activeElement !== this.txtHours) {
                if (document.activeElement !== this.txtMinutes || (this.txtMinutes.value || "").length >=
                    this.previousMinutesValue.length && this.txtMinutes.value !== this.previousMinutesValue) {
                    this.hoursNumericInput.value = this.options.format === "12h" ? 12 : 0;
                }
            }
            $([this.txtMinutes, this.txtHours]).removeAttr("data-disable-validation");
        } else {
            $([this.txtMinutes, this.txtHours]).attr("data-disable-validation", "");
        }
        this.onTimeChanged.fire(new ControlDataEvent(this, this.time, false));
        this.previousHoursValue = this.txtHours.value;
        this.previousMinutesValue = this.txtMinutes.value;
    }

}

export interface ITimepickerOptions {
    format?: "12h" | "24h";
    prefilledTime?: Date;
    required?: boolean;
}

$.fn.timepicker = function (this: JQuery, options) {
    this.each((i, elem: HTMLElement) => {
        var $elem = $(elem);
        if ($elem.data("timepicker")) {
            return;
        }
        // ReSharper disable once WrongExpressionStatement
        new Timepicker($elem, options);
    });
    return this;
};

declare global {
    interface JQuery {
        timepicker(options?: ITimepickerOptions): JQuery;
    }
}