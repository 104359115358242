export class ExtendableEvent<T extends Event = Event, TData  = any> implements Event<TData> {
    public cancelable = true;
    public readonly originalEvent: T;

    private propagationStopped = false;

    constructor(sourceEvent?: T);
    constructor(cancelable: boolean, sourceEvent?: T);
    constructor(cancelable: boolean | T, sourceEvent?: T) {
        this.cancelable = ExtendableEvent.getCancelable(cancelable);
        this.originalEvent = ExtendableEvent.getSourceEvent(cancelable, sourceEvent);
    }

    public toDomEvent(type?: string) {
        return new CustomEvent(type || this.type, {bubbles: this.bubbles, cancelable: this.cancelable});
    }

    protected static getCancelable<T extends Event>(cancelable: boolean | T): boolean {
        if (typeof cancelable === "boolean")
            return cancelable;
        return true;
    }

    protected static getSourceEvent<T extends Event>(cancelable: boolean | T, sourceEvent: T): T {
        if (cancelable && typeof cancelable !== "boolean")
            return cancelable;
        return sourceEvent;
    }

    initEvent(eventTypeArg: string, canBubbleArg: boolean, cancelableArg: boolean): void {
        throw new Error("Not implemented");
    }

    preventDefault(): void {
        if (!this.cancelable)
            throw "Event is not cancelable";
        this.defaultPrevented = true;
    }

    stopImmediatePropagation(): void {
        this.propagationStopped = true;
    }

    stopPropagation(): void {
        this.propagationStopped = true;
    }

    isPropagationStopped() {
        return this.propagationStopped;
    }

    deepPath(): EventTarget[] { throw new Error("Not implemented"); }


    bubbles: boolean = true;
    cancelBubble: boolean = false;
    currentTarget: EventTarget;
    defaultPrevented: boolean = false;
    eventPhase: number;
    isTrusted: boolean;
    returnValue: boolean;
    srcElement: Element;
    target: EventTarget;
    timeStamp = new Date().getTime();
    type: string = "custom";
    scoped: boolean;
    AT_TARGET: number;
    BUBBLING_PHASE: number;
    CAPTURING_PHASE: number;
    readonly NONE: number;
    data: TData;
    readonly composed: boolean;

  composedPath(): EventTarget[] {
    return [];
  }
}

export class ControlEvent<TC, TE extends Event = Event> extends ExtendableEvent<TE> {

    constructor(control: TC, sourceEvent?: TE);
    constructor(control: TC, cancelable?: boolean, sourceEvent?: TE);
    constructor(public readonly control: TC, cancelable?: boolean | TE, sourceEvent?: TE) {
        super(ExtendableEvent.getCancelable(cancelable), ExtendableEvent.getSourceEvent(cancelable, sourceEvent));
    }


    toDomEvent(type?: string): CustomEvent<any> {
        return $.extend(super.toDomEvent(type), { control: this.control});
    }
}

export class DataEvent<TD, TE extends Event = Event> extends ExtendableEvent<TE> {

    constructor(data: TD, sourceEvent?: TE);
    constructor(data: TD, cancelable?: boolean, sourceEvent?: TE);
    constructor(public readonly data: TD, cancelable?: boolean | TE, sourceEvent?: TE) {
        super(ExtendableEvent.getCancelable(cancelable), ExtendableEvent.getSourceEvent(cancelable, sourceEvent));
    }

    toDomEvent(type?: string): CustomEvent<any> {
        return $.extend(super.toDomEvent(type), { data: this.data});
    }

}

export class ControlDataEvent<TC, TD, TE extends Event = Event> extends ControlEvent<TC, TE> {

    constructor(control: TC, data: TD, sourceEvent?: TE);
    constructor(control: TC, data: TD, cancelable?: boolean, sourceEvent?: TE);
    constructor(control: TC, public readonly data: TD, cancelable?: boolean | TE, sourceEvent?: TE) {
        super(control, ExtendableEvent.getCancelable(cancelable), ExtendableEvent.getSourceEvent(cancelable, sourceEvent));
    }

    toDomEvent(type?: string): CustomEvent<any> {
        return $.extend(super.toDomEvent(type), { control: this.control, data: this.data});
    }
}

export class RenderedEvent<T, TE extends HTMLElement | JQuery> extends ControlEvent<T> {
    readonly items: TE[];

    constructor(control: T, items: TE[] | TE) {
        super(control, false);
        if (items instanceof Array)
            this.items = items;
        else
            this.items = [items];
    }

    toDomEvent(type?: string): CustomEvent<any> {
        return $.extend(super.toDomEvent(type), { control: this.control, items: this.items });
    }

}
