import {animate, AnimationEvent, state, style, transition, trigger} from '@angular/animations'
import detect = require("@utils/detect");


function buildSlideInOutTrigger() {
  return trigger('slideInOut',
                 [
                   transition('hidden => visible',
                              [
                                style({
                                        opacity: 0,
                                        height: 0,
                                        "margin-top": 0,
                                        "margin-bottom": 0,
                                        "overflow": "hidden",
                                      }),
                                animate('300ms ease-out',
                                        style({
                                                opacity: 1,
                                                height: "*",
                                                "margin-top": "*",
                                                "margin-bottom": "*",
                                                "min-height": "*"
                                              })),
                                style({
                                        "overflow": "*"
                                      })
                              ]),
                   transition('visible => hidden',
                              [
                                style({
                                        "overflow": "hidden"
                                      }),
                                animate('300ms ease-out',
                                        style({
                                                opacity: 0,
                                                height: 0,
                                                "margin-top": 0,
                                                "margin-bottom": 0,
                                                "min-height": 0
                                              }))
                              ]),
                   //state('visible',
                   //    style({
                   //        opacity: 1,
                   //        height: "*",
                   //        "margin-top": "*",
                   //        "margin-bottom": "*",
                   //    })),
                   state('hidden',
                         style({
                                 opacity: 0,
                                 height: 0,
                                 "min-height": 0,
                                 "margin-top": 0,
                                 "margin-bottom": 0,
                                 "overflow": "hidden",
                               }))
                 ]);
}

function buildSlideInOutTriggerForIe() {
  return trigger('slideInOut',
                 [
                   state('visible',
                         style({
                                 opacity: 1,
                                 height: "*",
                                 "margin-top": "*",
                                 "margin-bottom": "*",
                               })),
                   state('hidden',
                         style({
                                 display: "none"
                               }))
                 ]);
}

export function SlideInOut() {
  if (detect.browser.ie)
    return buildSlideInOutTriggerForIe();
  return buildSlideInOutTrigger();
}

export function FixIeAnimationIssue(e: AnimationEvent) {
  return;
  // if (!detect.browser.ie && !detect.browser.edge) {
  //     return;
  // }
  // if (e.toState === "hidden")
  //     return;
  // const $elem = $(".ng-animating:not(.ie-fix-attached)");
  // $elem.addClass("ie-fix-attached");
  // window.setTimeout(() => {
  //     $elem.css({
  //                   animation: "",
  //                   animationPlayState: ""
  //               })
  //         .removeClass("ie-fix-attached")
  //         .removeClass("ng-animating");
  // }, e.totalTime + 100)
}
