import { SelectDirective } from '@directives';
import {ChangeDetectionStrategy, Component, HostBinding, ViewChild} from '@angular/core';
import {combineLatest, merge, Observable} from "rxjs";
import {ShipmentService} from "@services";
import {AccountStore, ShipmentStoreState, UserRightsStore} from "@stores";
import {JSONData, LayoutMode, ShipmentExcelExportMode, ShipmentListViewMode, ShipmentSearchPreset} from "@api";
import {debounceTime, map, takeUntil} from "rxjs/operators";
import {AppBaseComponent} from "@app/appBaseComponent";
import {ExportToExcelService} from "@app/services/export-to-excel.service";
import {StoreAccessor, TypedStoreAccessor} from "@stores/store-accessor";
import {ISelectOptions} from "@widgets/Select";
import {checkScrollMargin} from "@utils/Utils";
import {ShipmentSearchUiStore} from "@app/page-components/shipment/shipment-list/shipment-search-ui-store.service";
import {ContainerService} from "@services/container.service";
import {ContainerStoreState} from "@stores/container-store-state";
import {ContainerStoreProjection} from "@stores/container-store.service";
import {ShipmentStoreProjection} from "@stores/shipment-store.service";
import { ViewCustomizer } from "@widgets/ViewCustomizer";
import { WebMethodClient } from "@api";
import { Alignment } from '@utils/Enums';
import {ShipmentOverviewItem} from "@app/page-components/shipment/shipment-list/shipment-overview-item";
import {ContainerOverviewItem} from "@app/page-components/shipment/shipment-list/container-overview-item";
import {FieldRenderer} from "@app/page-components/shipment/shipment-list/field-renderer.service";
type MergedStoreStateType=(ContainerStoreState & ContainerStoreProjection) | (ShipmentStoreState & ShipmentStoreProjection);

@Component({
             selector: 'rt-shipment-list',
             templateUrl: './shipment-list.component.html',
             styleUrls: ['./shipment-list.component.scss'],
             changeDetection: ChangeDetectionStrategy.OnPush,
           })
export class ShipmentListComponent extends AppBaseComponent {

  @HostBinding("class.scrolling")
  overflowMode = false;
  readonly canStartNewExcelReport$: Observable<boolean>
  readonly filterCount$: Observable<number>;
  readonly hasBookingRights$: Observable<boolean>;
  readonly hasExportToExcelRights$: Observable<boolean>
  readonly hasShipments$: Observable<boolean>;
  readonly isBoxMode$: Observable<boolean>;
  readonly isLineMode$: Observable<boolean>;
  readonly isLineScrollMode$: Observable<boolean>;
  readonly isLoadingMore$: Observable<boolean>;
  readonly isSearching$: Observable<boolean>;
  readonly listViewMode$: Observable<ShipmentListViewMode>;
  readonly moreItemsAvailable$: Observable<boolean>;
  readonly noResultsFound$: Observable<boolean>;
  readonly searchPresets$: Observable<ShipmentSearchPreset[]>
  readonly filtersOpen = new StoreAccessor(this, "filtersOpen")
  readonly exportToExcelModeAccessor: TypedStoreAccessor<ShipmentExcelExportMode>;
  readonly excelExportSettings: ISelectOptions = {
    allowNoSelection: false,
    class: "double-button-droplist droplist-button",
    toggleControl: "parent",
    visualContainer: "button-group"
  };

  @ViewChild("viewSelect")
  viewSelect: SelectDirective;

  readonly excelExportItems: JSONData[] = [
    {
      value: ShipmentExcelExportMode.ByShipment,
      label: "By Shipment"
    },
    {
      value: ShipmentExcelExportMode.ByContainer,
      label: "By Container"
    }
  ];
  readonly shipmentListViewMode = ShipmentListViewMode;
  readonly store: ShipmentSearchUiStore;
  readonly viewModeSelectItems: JSONData[] = [
    {
      label: "By Bill of Lading",
      value: ShipmentListViewMode.Shipments,
      icon: "select-option-icon mat-icon-document"
    },
    {
      label: "By Container",
      value: ShipmentListViewMode.Containers,
      icon: "select-option-icon mat-icon-container"
    }
  ]

  readonly layoutModeSelectItems: JSONData[] = [
    {
      label: "Card View",
      value: LayoutMode.Box,
      icon: "select-option-icon mat-icon-card"
    },
    {
      label: "List View",
      value: LayoutMode.Line,
      icon: "select-option-icon mat-icon-list",
    }
  ]

  readonly viewModeSelectSettings: Partial<ISelectOptions> = {
    displayIcon: true,
    class: "double-button-droplist droplist-button",
    allowNoSelection: false,
    alignment: Alignment.Stretch,
    allowGrowing: true,
    placeholderRenderer: (items) => items.length
                                    ? `<span class="${items[0].ajaxData.icon}" title="${items[0].ajaxData.label}"></span>`
                                    : ""
  }

  readonly layoutModeSelectSettings: Partial<ISelectOptions> = {
    displayIcon: true,
    class: "double-button-droplist droplist-button",
    allowNoSelection: false,
    alignment: Alignment.Stretch,
    allowGrowing: true,
    placeholderRenderer: (items) => items.length
                                    ? `<span class="${items[0].ajaxData.icon}" title="${items[0].ajaxData.label}"></span>`
                                    : "",
    configureSelectedOption: (items) => { ShipmentListComponent._viewCustomizer.open() }
  }

  readonly viewModeAccessor: TypedStoreAccessor<ShipmentListViewMode>
  readonly currentLayoutAccessor: TypedStoreAccessor<LayoutMode>
  private readonly _shipmentService: ShipmentService;
  private readonly _accountStore: AccountStore;
  private readonly _exportToExcelService: ExportToExcelService;
  private readonly _containerService: ContainerService;
  static  _viewCustomizer: ViewCustomizer;
  readonly viewCustomizerShipment$: Observable<ShipmentOverviewItem>;
  readonly viewCustomizerContainer$: Observable<ContainerOverviewItem>;

  constructor(shipmentService: ShipmentService,
              containerService: ContainerService,
              accountStore: AccountStore,
              exportToExcelService: ExportToExcelService,
              userRightsStore: UserRightsStore,
              store: ShipmentSearchUiStore,
              webMethodClient: WebMethodClient,
              fieldRenderer: FieldRenderer) {
    super();
    const containerStore = containerService.containerStore;
    this.store = store;
    this._exportToExcelService = exportToExcelService;
    this._accountStore = accountStore;
    this._shipmentService = shipmentService;
    this._containerService = containerService;
    const shipmentStore = shipmentService.shipmentStore;
    this.hasBookingRights$ = userRightsStore.observe("bookings");
    this.isLoadingMore$ = this._viewDependentOObservable("isLoadingMore");
    this.isSearching$ = this._viewDependentOObservable("isSearching");
    this.moreItemsAvailable$ =this._viewDependentOObservable("showLoadMoreButton");
    this.viewModeAccessor = accountStore.getStoreAccessor("shipmentListViewMode");
    this.currentLayoutAccessor = accountStore.getStoreAccessor("currentLayout");
    this.viewCustomizerShipment$ = shipmentStore.observe("viewCustomizerOverviewItem");
    this.viewCustomizerContainer$ = containerStore.observe("viewCustomizerOverviewItem");
    if (!ShipmentListComponent._viewCustomizer)
      ShipmentListComponent._viewCustomizer = new ViewCustomizer(accountStore, fieldRenderer, shipmentStore, containerStore);
      //
      // accountStore.observe("shipmentListViewMode").subscribe(m => {
      //   if(this.viewSelect){
      //       this.viewSelect.select.getItems().forEach(e => {e.$element.remove(), e.$element = null });
      //       this.viewSelect.select.renderItems(true);
      //   }
      //   if(m === ShipmentListViewMode.Shipments) {
      //     if(this.viewSelect){
      //       this.viewSelect.select.options.configureSelectedOption = (items) => {this._viewCustomizer.open(); this.viewSelect.select.close() };
      //
      //     }
      //     this.layoutModeSelectSettings.configureSelectedOption = (items) => {this._viewCustomizer.open(); this.viewSelect.select.close() };
      //   }
      //   else {
      //     this.layoutModeSelectSettings.configureSelectedOption = null;
      //       if(this.viewSelect)
      //       this.viewSelect.select.options.configureSelectedOption = null;
      //   }
      // })

    this.hasShipments$ =
      combineLatest(
        shipmentStore.observe("shipmentOverviewList"),
        containerStore.observe("itemList"),
        accountStore.observe("shipmentListViewMode")
      )
        .pipe(
          map(([shipmentOverviewList, itemList, shipmentListViewMode]) =>
                shipmentListViewMode === ShipmentListViewMode.Shipments
                ? shipmentOverviewList
                : itemList),
          map(items => items && items.any())
        );
    this.isLineMode$ = accountStore.observe("currentLayout")
                                   .pipe(map(l => l === LayoutMode.Line));
    this.isBoxMode$ = accountStore.observe("currentLayout")
                                  .pipe(map(l => l === LayoutMode.Box));
    this.noResultsFound$ = this._viewDependentOObservable("noResultsFound")
    accountStore.observe("listLayoutOverflow")
                .pipe(takeUntil(this.destroyed$))
                .subscribe(o => {
                  this.overflowMode = o
                });
    this.isLineScrollMode$ = accountStore.observe("listLayoutOverflow");
    this.canStartNewExcelReport$ = exportToExcelService.observe("canStartNewExport");
    this.exportToExcelModeAccessor = accountStore.getStoreAccessor("shipmentExcelExportMode")
    this.hasExportToExcelRights$ = userRightsStore.observe("exportToExcel")
    this.searchPresets$ = accountStore.observe("shipmentSearchPresets");
    this.filterCount$ = this._shipmentService.shipmentStore.observe("filterCount");
    this.filterCount$.subscribe(console.log);
    this.listViewMode$ = accountStore.observe("shipmentListViewMode");

    merge(this.isLineMode$, this.isSearching$)
      .pipe(debounceTime(300))
      .subscribe(() => checkScrollMargin())
  }

  startNewExcelExport(): void {
    void this._exportToExcelService.startNewShipmentReport();
  }

  loadMore(): void {
    if (this._accountStore.get("shipmentListViewMode") === ShipmentListViewMode.Shipments)
      void this._shipmentService.loadMore();
    else
      void this._containerService.loadMore();
  }

  toggleFiltersOpenState(): void {
    this.store.negate("filtersOpen")
  }

  startSearch(): void {
    const searchObject = this.store.get();
    delete searchObject.filtersOpen;
    if (this._accountStore.get("shipmentListViewMode") === ShipmentListViewMode.Shipments)
      this._shipmentService.startSearch(searchObject)
    else
      this._containerService.startSearch(searchObject)
  }

  private _viewDependentOObservable<T>(shipmentObservable: Observable<T>, containerObservable: Observable<T>): Observable<T>;
  private _viewDependentOObservable<T extends keyof MergedStoreStateType>(key: T): Observable<MergedStoreStateType[T]>;
  private _viewDependentOObservable<T>(shipmentObservable: Observable<T>|keyof (ShipmentStoreState | ContainerStoreState), containerObservable?: Observable<T>): Observable<T> {
    if (typeof shipmentObservable === "string") {
      return combineLatest(
        this._accountStore.observe("shipmentListViewMode"),
        this._shipmentService.shipmentStore.observe(shipmentObservable),
        this._containerService.containerStore.observe(shipmentObservable)
      )
        .pipe(map(([viewMode, shipmentValue, containerValue]) =>
                    viewMode === ShipmentListViewMode.Shipments
                    ? shipmentValue
                    : containerValue)) as any;
    }
    return combineLatest(
      this._accountStore.observe("shipmentListViewMode"),
      shipmentObservable,
      containerObservable
    )
      .pipe(map(([viewMode, shipmentValue, containerValue]) =>
                  viewMode === ShipmentListViewMode.Shipments
                  ? shipmentValue
                  : containerValue));
  }
}
