import {createDeepCopy} from '@utils/createDeepCopy';
import {NgModule} from "@angular/core";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {BookingComponent} from "./booking.component";
import {Router} from "@angular/router";

import {ServiceComponent} from "./service.component";
import {PartiesComponent} from "./parties.component";
import {GoodsContainersComponent} from "./goods-containers.component";
import {GoodsPackagesComponent} from "./goods-packages.component";
import {AdditionalInformationComponent} from "./additional-information.component";
import {SubmitComponent} from "./submit.component";
import {GoodsComponent} from "./goods.component";
import {HashMap} from "@utils/HashMap";

import {CustomNumberValueAccessor, DatetimepickerDirective, NumericInputDirective} from "@directives";
import {TimelineLinkComponent} from "./timelineLink.Component";
import {DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule} from "ngx-dropzone-wrapper";
import * as Settings from "../utils/settings";

import {Modal} from "@widgets/modal";
import {TemplateSettingsComponent} from "./template-settings.component";
import {LastUsedDangerousGoodsCache} from "@booking/LastUsedDangerousGoodsCache";
import {PortComponent} from "../QuickTrack/src/components/port/port.component";
import {RtCommonModule} from "@app/rt-common.module";
import {DangerousGoodsService} from "@booking/DangerousGoodsService";
import {Subject} from "rxjs";
import {BookingService} from "@services/booking.service";
import {BookingModel} from "@booking/BookingModel";

"use strict";


const DropzoneConfig: DropzoneConfigInterface = {
  url: window.baseApplicationUrl + "/WebMethod/FileUploadBooking",
  parallelUploads: 1,
  uploadMultiple: false,
  clickable: true,
  acceptedFiles: ".bmp,.doc,.docx,.jpeg,.jpg,.msg,.pdf,.png,.tif,.tiff,.xls,.xlsx",
  autoProcessQueue: true,
  autoQueue: true,
  method: "POST",
  //language=html
  previewTemplate: "<input type=\"hidden\"/>",
  dictInvalidFileType: Settings.DROPZONE_CONSTS.INVALID_FILE_TYPE,
  dictResponseError: Settings.DROPZONE_CONSTS.RESPONSE_ERROR,
  dictFileTooBig: Settings.DROPZONE_CONSTS.FILE_TOO_BIG,
  maxFilesize: 5,


  //for testing:
  // chunkSize: 1,
  // forceChunking: true,
  // parallelChunkUploads: false
};

@NgModule({
            imports: [
              // BrowserModule,
              BrowserAnimationsModule,
              // FormsModule,
              // // RouterModule.forRoot(
              // //     ,
              // //     {
              // //         enableTracing: window.enableDebug || false,
              // //         errorHandler() {
              // //             console.log.apply(window, arguments)
              // //         },
              // //         preloadingStrategy: PreloadAllModules
              // //     }
              // // ),
              DropzoneModule,
              RtCommonModule
            ],
            declarations: [
              BookingComponent,
              TemplateSettingsComponent,
              ServiceComponent,
              AdditionalInformationComponent,
              GoodsContainersComponent,
              GoodsPackagesComponent,
              PartiesComponent,
              SubmitComponent,
              NumericInputDirective,
              DatetimepickerDirective,
              TimelineLinkComponent,
              GoodsComponent,
              CustomNumberValueAccessor,
              PortComponent,
            ],
            entryComponents: [
              BookingComponent
            ],
            bootstrap: [BookingComponent],
            exports: [
              NumericInputDirective
            ],
            providers: [
              {
                provide: DROPZONE_CONFIG,
                useValue: DropzoneConfig
              }
            ]
          })
export class BookingModule {

  // lastSessionViewIndex = parseInt(sessionStorage.getItem("booking_step")) || 0;

  private _navigationRequestedSubject = new Subject<string>();
  private readonly _bookingService: BookingService;

  constructor(
    router: Router,
    dgCache: LastUsedDangerousGoodsCache,
    dgService: DangerousGoodsService,
    bookingService: BookingService) {
    this._bookingService = bookingService;
    bookingService.cancellationRequested$
                  .subscribe(r => this._confirmNavigationOrShowCancelModal(r.requestedUrl, r.resolver))
    this._navigationRequestedSubject.subscribe(url => router.navigateByUrl(url));
    window.addEventListener("beforeunload", e => this._checkBeforeUnload(e));
  }

  private _checkBeforeUnload(e: BeforeUnloadEvent) {
    const model = this._bookingService.currentBookingModel;
    if (this._bookingHasUnsavedChanges()) {
      const warning = "If you navigate away from the booking process or reload this page, your progress will be lost and you have to enter all data again.";
      e.returnValue = warning;
      return warning;
    }
  }

  private _bookingHasUnsavedChanges() {
    const currentModel = createDeepCopy(this._bookingService.currentBookingModel);
    const currentTemplate = createDeepCopy(this._bookingService.currentTemplate);

    let bookingIsActive = currentModel && !currentModel.enableNavigatingAway;
    if (!bookingIsActive)
      return false;

    if (!currentTemplate)
      return !currentModel.isEmpty();

    this._removeFieldsNotNeededForCompare(currentModel);
    this._removeFieldsNotNeededForCompare(currentTemplate);

    return !HashMap.hashesEqual(currentModel, currentTemplate)
  }

  // noinspection JSMethodCanBeStatic
  private _removeFieldsNotNeededForCompare(model: BookingModel) {

    model.packages.forEach(pack => {
      delete pack.volume;
      delete pack.groupedDangerousGoodsItems;
      delete pack.dangerousGoodsItems;
    });

    model.containers.forEach(container => {
      delete container.groupedDangerousGoodsItems;
      delete container.dangerousGoodsItems;
    });

    delete model.booking.consigneeAddress?.createDate;
    delete model.booking.consignorAddress?.createDate;
    delete model.booking.deliveryAddress?.createDate;
    delete model.booking.pickupAddress?.createDate;
    delete model.bookingParty;
    delete model.lastViewIndex;
    delete model.totalContainerWeight;
    delete model.totalPackVolume;
    delete model.totalPackWeight;
    model.booking.references = model.booking.references.filter(r => !!r.referenceNumber);
  }

  private _confirmNavigationOrShowCancelModal(targetUrl: string, resolver: PromiseResolver<boolean>) {

    if (!this._bookingHasUnsavedChanges()) {
      this._navigateAway(targetUrl, resolver);
      return;
    }
    let modalTitle = "Cancel Booking";
    let modalContent = "Are you sure you want to cancel? All information will be lost.";
    const model = this._bookingService.currentBookingModel;
    const mode = this._bookingService.currentBookingMode;
    if (mode === "template") {
      modalTitle = model.booking.templateInformation.templateID
                   ? "Discard Booking Template Changes"
                   : "Discard Booking Template";
      modalContent = model.booking.templateInformation.templateID
                     ? "Are you sure you want to discard all changes to this Booking Template?"
                     : "Are you sure you want to discard this Booking Template?";
    }
    const modal = new Modal(null, {
      buttons: [
        $(document.createElement("button"))
          .text(mode === "booking" ? "Cancel Booking" : "Discard")
          .addClass("button")
          .attr("type", "button")
          .click(() => {
            this._navigateAway(targetUrl, resolver);
            modal.closeModal();
          }),
        $(document.createElement("button"))
          .text(mode === "booking" ? "Keep Booking" : "Keep editing")
          .addClass("button secondary")
          .attr("type", "button")
          .click(() => {
            modal.closeModal();
            resolver(false);
          })
      ],
      header: modalTitle,
      html: modalContent
    });
    modal.openModal();
  }

  private _navigateAway(targetUrl: string, resolver: PromiseResolver<boolean>) {
    const model = this._bookingService.currentBookingModel;
    model.enableNavigatingAway = true;
    model.isAborted = true;
    if (targetUrl)
      this._navigationRequestedSubject.next(targetUrl);
    this._bookingService.cancelBooking();
    resolver(true);
  }

}
