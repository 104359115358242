import * as Utils from "../utils/Utils";
import {EventEmitter} from "@utils/EventEmitter";
import {ControlEvent, RenderedEvent} from "../libs/ExtendableEvent";
import {Select} from "./Select";
import {Alignment} from "@utils/Enums";
import {ValidationField} from "./Validate";
import {NumericInput} from "./NumericInput";
//import JsonCallingCode = server.JSONCallingCode;
import { JSONCallingCode as JsonCallingCode } from "../../../RRTClasses/JSON.cs";

require("../utils/JqueryLoader");


export class AreaCodeSelect implements IControl<JQuery> {
    //language=html
    static template = `
<div class="input-group stretch areacode-select">
<select class="select-button" data-display-name="Telephone" required="Please select an area code"></select>
<input maxlength="45" type="text"/><span class="input-prependix"></span>
</div>`;

    private static countries: JQueryPromise<JsonCallingCode[]>;
    readonly $element: JQuery;
    readonly select: Select<JsonCallingCode>;
    readonly $callingCode: JQuery;
    readonly $input: JQuery;
    readonly $preLoadedValues: JQuery;
    readonly onRendered = new EventEmitter<RenderedEvent<this, JQuery>>("rendered", () => this.eventElements());
    readonly onRendering = new EventEmitter<ControlEvent<this>>("rendering", () => this.eventElements());
    readonly onInit = new EventEmitter<ControlEvent<this>>("areacode-select-init", () => this.eventElements());
    private defaultAreaCode: string;
    private readonly $container: JQuery;
    private readonly areaCodeHiddenField: HTMLInputElement;
    private readonly numberHiddenField: HTMLInputElement;

    constructor(container: ContainerType) {
        if (!AreaCodeSelect.countries) {
            AreaCodeSelect.countries = $.getJSON(window.baseApplicationUrl +
                                                 "/WebMethod/GetCountryCallingCodes") as JQueryPromise<JsonCallingCode[]>;
        }

        this.$element = $(AreaCodeSelect.template)
            .data("area-code-select", this);

        this.$input = this.$element.find("input");
        new NumericInput(this.$input, {
            decimalPrecision: 0,
            allowGroupSeperator: false,
            selectTextOnFocus: false,
            enableScrolling: false,
            allowSpaces: true,
            enableMaxLengthHandler: true
        });
        this.$input.on("input", () => {
            this.numberHiddenField.value = this.getPhoneNumber();
        });

        this.$callingCode = this.$element.find(".input-prependix");

        this.select = new Select<JsonCallingCode>(this.$element.find("select")[0] as HTMLSelectElement, {
            "class": "search-select droplist-button",
            displayIcon: true,
            search: true,
            closeOnSelect: true,
            allowNoSelection: true,
            autoChangeDirection: false,
            autoPosition: false,
            alignment: Alignment.Start,
            allowGrowing: true,
            selectedOnTop: false,
            placeholderRenderer: (items) => items.length
                                            ? `<span class="${items[0].ajaxData.icon}" title="${items[0].ajaxData.label}"></span>`
                                            : ""
        });

        this.select.$selectElement.typedData({
                                                 "visual-element": this.$element,
                                                 "display-name": "Phone area code"
                                             });
        this.$input
            .typedData({
                           "visual-element": this.$element,
                           "display-name": "Phone number"
                       });

        this.$container = $(Utils.UnwrapContainerFunction(container))
            .append(this.$element)
            .data("area-code-select", this);
        this.$preLoadedValues = this.$element.parent().children("input");
        this.areaCodeHiddenField = this.$preLoadedValues.filter("[data-role=areaCodeGuid]")[0] as HTMLInputElement;
        this.numberHiddenField = this.$preLoadedValues.filter("[data-role=phoneNumber]")[0] as HTMLInputElement;
        const selectValidationField = ValidationField.getValidationField(this.select.selectElement);
        selectValidationField.validateData.validators.required.isActive = () => !!this.$input.val();
        this.$input
            .on("input", function (this: HTMLInputElement) {
                if (!this.value)
                    selectValidationField.resetValidation();
            })
            .val(/^(\+\d+\s*)?(.*)/.exec(this.numberHiddenField.value || "+1 ")[2]);
        this.defaultAreaCode = this.areaCodeHiddenField.value;

        this.$element.parents("form").on("realsubmit", () => {
            this.numberHiddenField.value = this.getPhoneNumber();
        });


        // noinspection JSIgnoredPromiseFromCall
        this.loadCountries();

        this.select.onSelectionChanged.on((e) => {
            if (e.addedItems.length) {
                this.$callingCode.html(e.addedItems[0].ajaxData.callingCode);
                this.numberHiddenField.value = this.getPhoneNumber();
                this.areaCodeHiddenField.value = e.addedItems[0].value;
            } else {
                this.$callingCode.html("");
                this.numberHiddenField.value = null;
                this.areaCodeHiddenField.value = null;
            }
        });


    }

    eventElements() {
        return [this.$element, this.$container, this.$container.children("input")]
    }

    async loadCountries() {
        this.select.processJsonData(await AreaCodeSelect.countries);
        this.selectCountry(this.defaultAreaCode);
    }

    selectCountry(countryCode: string) {
        for (const item of this.select.getItems()) {
            if (item.value === countryCode) {
                this.select.unselectItems(false, false);
                this.select.selectItems(item, true, true);
                break;
            }
        }

    }

    getPhoneNumber(): string {

        const phoneNumber = this.$input.val();

        if (phoneNumber) {
            return (this.$callingCode.html() + " " + phoneNumber.replace(/\s+/g, " ").trim()).substr(0, 50);
        }
        return null;
    }

    setPhoneNumber(phoneNumber: string) {
        this.$input.val(phoneNumber);
    }

}

declare global {
    interface JQuery {
        areaCodeSelect(): JQuery;
    }
}

jQuery.fn.areaCodeSelect = function (this: JQuery) {
    return this.each((index, elem) => {
        if ($(elem).data("areaCodeSelect"))
            return;
        // ReSharper disable once WrongExpressionStatement
        new AreaCodeSelect(elem as HTMLElement);
    });
};
