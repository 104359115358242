export class HashMap<T extends object> {

  private readonly _map: Partial<Record<keyof T,string>> = {};

  static createHash(object: unknown): string {
    if (object instanceof Date)
      return object.getTime().toString();
    if(object === undefined)
      return "undefined";
    if (typeof object === "object")
      return JSON.stringify(object);
    return object.toString();
  }

  static hashesEqual(o1:unknown, o2:unknown):boolean {
    return this.createHash(o1) === this.createHash(o2);
  }

  set<K extends keyof T>(key: K, value: T[K]) {
    this._map[key] = HashMap.createHash(value);
  }

  setHash<K extends keyof T>(key: K, hash: string) {
    this._map[key] = hash;
  }

  get(key: keyof T) {
    return this._map[key] ?? null;
  }

  delete(key: keyof T) {
    delete this._map[key];
  }

  has(key: keyof T) {
    return this._map.hasOwnProperty(key);
  }
}
