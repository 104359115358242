<div *ngIf="boxLayout$ | async; let boxLayout">
  <div class="ajax-target">
    <ng-container *ngIf="!skeletonMode; else skeletonView">
      <ng-container *ngFor="let shipment of (shipments$ | async); trackBy: trackById">
        <rt-shipment-list-box [shipment]="shipment"></rt-shipment-list-box>
      </ng-container>
    </ng-container>
    <ng-template #skeletonView>
      <div *ngFor="let item of [].constructor(skeletonItemCount);" class="shipment-box">
        <header class="skeleton">
          <p><span class="shimmer-content" style="width:35%"></span></p>
          <p><span class="shimmer-content" style="width:55%"></span></p>
        </header>
        <div class="progress skeleton">
          <p><span class="shimmer-content" style="width:100%"></span></p>
          <p><span class="shimmer-content" style="width:35%"></span></p>
        </div>
        <div class="informations"
             [ngClass]="{empty: !boxLayout.length}">
          <ng-container *ngFor="let item of boxLayout;">
            <p class="{item.class}">
              <span class="shimmer-content" style="width:50%"></span>
              <span class="shimmer-content" style="width:70%"></span>
            </p>
          </ng-container>
        </div>
        <div class="labels">
          <span class="label shimmer-content" style="width:20%"></span>
        </div>
      </div>
    </ng-template>
  </div>
</div>

