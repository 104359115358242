import * as Dropzone from "dropzone";
import {DangerousGoodsMasterData, JsonAddress, JsonBooking, JsonContainer, JSONData, JsonDocument, JsonPackage, JsonTemplateInformation} from "@api";
import {BookingMode} from "@services/booking-service-listener.service";

export class BookingModel {
  static constructCount = 0;

  booking: Partial<IJsonBooking> = {
    references: [],
    documents: [],
    templateInformation: {
      private: true
    }
  };
  mode: BookingMode;
  disableNavigationValidation: boolean;
  isAborted: boolean;
  files: FileUpload[] = [];
  fileMap = {} as { [index: string]: FileUpload };
  containers: Array<Partial<IJsonContainer>> = [this.getContainerDummy()];
  dummyContainer = this.containers.last();
  packages: Array<Partial<IJsonPackage>> = [this.getPackageDummy()];
  dummyPackage = this.packages.last();
  agreedToTerms = false;
  bookingParty: JSONData;
  totalContainerVolume = 0;
  totalContainerWeight = 0;
  totalPackVolume = 0;
  totalPackWeight = 0;
  totalPackCount = 0;
  enableNavigatingAway = false;
  viewStates: { isActivated: boolean, valid: boolean }[] = [];
  lastViewIndex: number;

  constructor(mode?: BookingMode) {
    this.mode = mode;
    if (false) {
      this.booking = {
        pickupAddress: {
          "displayName": "adsdfwe",
          "code": "TESTLTDABCDEBY",
          "name": "Test Ltd. abc",
          "address1": "Test Address",
          "address2": "asdf",
          "city": "Checkcity",
          "country": "DE",
          "countryName": "Germany",
          "state": "BY",
          "stateName": "Bayern",
          "postCode": "12345",
          "createUser": "Wanja Donix",
          "isExternalAddress": true,
          "isInternalAddress": false,
          "sitePK": "1903dfdf-28fa-49e1-a11f-6416db008ace",
          "primarySitePK": null,
          "createDateString": "07.06.2018"
        },

        deliveryAddress: {
          "displayName": "adsdfwe",
          "code": "TESTLTDABCDEBY",
          "name": "Test Ltd. abc",
          "address1": "Test Address",
          "address2": "asdf",
          "city": "Checkcity",
          "country": "DE",
          "countryName": "Germany",
          "state": "BY",
          "stateName": "Bayern",
          "postCode": "12345",
          "createUser": "Wanja Donix",
          "isExternalAddress": true,
          "isInternalAddress": false,
          "sitePK": "1903dfdf-28fa-49e1-a11f-6416db008ace",
          "primarySitePK": null,
          "createDateString": "07.06.2018"
        },

        consigneeAddress: {
          "displayName": null,
          "code": "TESTLTDDEBY26",
          "name": "Test Ltd.",
          "address1": "Test Address",
          "address2": null,
          "city": "Checkcity",
          "country": "DE",
          "countryName": "Germany",
          "state": "BY",
          "stateName": "Bayern",
          "postCode": "12345",
          "createUser": "Daniel Kuon (daniel.kuon@rohlig.com)",
          "isExternalAddress": false,
          "isInternalAddress": true,
          "sitePK": "84f26ac4-1e17-4e78-ab79-d32b08535365",
          "primarySitePK": null,
          "createDateString": "24.05.2018"
        },

        consignorAddress: {
          "displayName": "adsdfwe",
          "code": "TESTLTDABCDEBY",
          "name": "Test Ltd. abc",
          "address1": "Test Address",
          "address2": "asdf",
          "city": "Checkcity",
          "country": "DE",
          "countryName": "Germany",
          "state": "BY",
          "stateName": "Bayern",
          "postCode": "12345",
          "createUser": "Wanja Donix",
          "isExternalAddress": true,
          "isInternalAddress": false,
          "sitePK": "1903dfdf-28fa-49e1-a11f-6416db008ace",
          "primarySitePK": null,
          "createDateString": "07.06.2018"
        },
        deliverAt: new Date(),
        pickupAt: new Date(),
        direction: "in",
        documents: [
          //this.getDummyDocument("Invoice.pdf", "CIV"),
          //this.getDummyDocument("Packlist.txt", "PKL")
        ],
        goodsDescription: "Description of goods",
        marksAndNumbers: "Marks, numbers",
        references: [
          {ReferenceNumber: "Ref 1"},
          {ReferenceNumber: "Ref 2"}
        ] as any,
        shipmentType: "FCL",
        siteName: "Test Client",
        specialInstructions: `Special Instructions
Even more special`,
        transportMode: "SEA",
        incoTerm: "DAP",
        templateInformation: {
          private: true
        }
      };
      this.containers = [
        {
          containerType: "20GP",
          volume: 18.9,
          weight: 1890
        },
        {
          containerType: "40GP",
          volume: 28.9,
          weight: 3890
        },
        {
          containerType: "20GP",
          volume: 19.9,
          weight: 890
        },
        this.getContainerDummy()
      ];
      this.dummyContainer = this.containers.last();
      this.packages = [
        {
          weight: 10.8,
          volume: 20,
          height: 1,
          width: 10,
          length: 2,
          packCount: 10
        },
        {
          weight: 14.8,
          volume: 100.1,
          height: 2.6,
          width: 11,
          length: 3.5,
          packCount: 10
        },
        this.getPackageDummy()
      ];
      this.dummyPackage = this.packages.last();
      this.totalPackCount = 0;
      if (this.booking.shipmentType === "FCL") {
        for (const container of this.containers) {
          if (container === this.dummyContainer) {
            continue;
          }
          this.totalContainerVolume += container.volume;
          this.totalPackCount += 1;
          this.totalContainerWeight += container.weight;
        }
      } else {
        for (const pack of this.packages) {
          if (pack === this.dummyPackage) {
            continue;
          }
          this.totalPackVolume += pack.volume;
          this.totalPackWeight += pack.weight;
          this.totalPackCount += pack.packCount;
        }
      }
    }
  }

  isEmpty() {
    const isTemplate = !!this.booking.bookingID || this.booking.templateInformation.templateID;
    return (!this.booking.shipmentType &&
                                              !this.booking.transportMode &&
                                              !this.booking.incoTerm &&
                                              !this.booking.direction &&
                                              (!this.booking.templateInformation.templateName || isTemplate))
  }

  getContainerDummy(): Partial<IJsonContainer> {
    return {
      containerType: null,
      dangerousGoodsItems: [],
      dangerousGoods: [],
      temperature: 0
    } as Partial<IJsonContainer>;
  }

  getPackageDummy(): Partial<IJsonPackage> {
    return {
      packCount: 1,
      dangerousGoodsItems: [],
      dangerousGoods: []
    } as Partial<IJsonPackage>;
  }

  getDummyAddress(name: string): JsonAddress {
    return {
      address1: "Testweg 14",
      city: "Checkstadt",
      code: "UNKNOWN",
      country: "de",
      countryName: "Germany",
      displayName: name,
      name: name,
      postCode: "12345",
      state: "BY",
      stateName: "Bavaria"
    } as JsonAddress;
  }

  getDummyDocument(name: string,
                   type: keyof typeof DocumentTypes): Partial<IJsonDocument> {
    return {
      documentType: type,
      fileName: name,
      fileSize: Math.round(Math.random() * 1000, 1) + " MB"
    } as Partial<IJsonDocument>;
  }

  async checkPorts() {
    const params = {
      search: "",
      options: JSON.stringify(Object.assign({maxSearchResults: 1, codeSearchOnly: true},
                                            this.booking.transportMode === "AIR"
                                            ? {searchAirPorts: true, searchOfficialPorts: true}
                                            : {searchSeaPorts: true, searchOfficialPorts: true}))
    };
    if (this.booking.portOfLoading) {
      console.log("Loading PoL: " + this.booking.portOfLoading.unloco);
      params.search = this.booking.portOfLoading.unloco;
      this.booking.portOfLoading = null;
      const port = (await $.getJSON(window.baseApplicationUrl + "/WebMethod/SearchUNLOCOs",
                                    params))[0] as JSONData;
      if (!port)
        console.log("No PoL returned");
      if (port) {
        this.booking.portOfLoading = {
          unloco: port.value,
          name: port.label,
          html: port.html
        };
        console.log("Returned PoL:")
        console.log(this.booking.portOfLoading);
      }
    }
    if (this.booking.portOfDischarge) {
      console.log("Loading PoD: " + this.booking.portOfDischarge.unloco);
      params.search = this.booking.portOfDischarge.unloco;
      this.booking.portOfDischarge = null;
      const port = (await $.getJSON(window.baseApplicationUrl + "/WebMethod/SearchUNLOCOs",
                                    params))[0] as JSONData;
      if (!port)
        console.log("No PoD returned");
      if (port) {
        this.booking.portOfDischarge = {
          unloco: port.value,
          name: port.label,
          html: port.html
        }
        console.log("Returned PoD:")
        console.log(this.booking.portOfDischarge);
      }
    }
  }
}

export const DocumentTypes = {
  PKL: "Packing List",
  //MFD: "Export Declaration",
  CIV: "Commercial Invoice",
  DGF: "Dangerous Goods",
  MSC: "Other"
};

export const IncoTerms = {
  EXW: "EXW – Ex Works",
  FCA: "FCA – Free Carrier",
  FAS: "FAS – Free Alongside Ship",
  FOB: "FOB – Free On Board",
  CPT: "CPT – Carriage Paid To",
  CFR: "CFR – Cost And Freight",
  CIF: "CIF – Cost, Insurance & Freight",
  CIP: "CIP – Carriage And Insurance Paid To",
  DPU: "DPU – Delivered at Place Unloaded",
  DAP: "DAP – Delivered At Place",
  DDP: "DDP – Delivered Duty Paid"
};

export const ContainerTypes = {
  "40HC": "40ft High Cube",
  "40GP": "40ft General Purpose",
  "20GP": "20ft General Purpose",
  "40REHC": "40ft High Cube Reefer",
  "40RE": "40ft Reefer",
  "20RE": "20ft Reefer",
  "40OT": "40ft Open Top",
  "40NOR": "40ft Non Operating Reefer",
  "20NOR": "20ft Non Operating Reefer",
  "40HCNOR": "40ft High Cube Non Operating Reefer"
};


export interface IJsonBooking extends Partial<JsonBooking> {
  direction?: "in" | "out";
  shipmentType?: "LCL" | "FCL" | "LSE";
  transportMode?: "AIR" | "SEA";
  incoTerm?: keyof typeof IncoTerms;
  containers: IJsonContainer[];
  packages: IJsonPackage[];
  documents: IJsonDocument[];
  pickupTimeSpecified?: boolean;
  deliveryTimeSpecified?: boolean;
  templateInformation: Partial<JsonTemplateInformation>;
}

export interface IJsonContainer extends JsonContainer {
  containerType: keyof typeof ContainerTypes;
  dangerousGoodsItems?: IDangerousGoodsItem[];
  groupedDangerousGoodsItems?: Grouping<DangerousGoodsMasterData, string>[];
}

export interface IJsonPackage extends JsonPackage {
  dangerousGoodsItems?: IDangerousGoodsItem[];
  groupedDangerousGoodsItems?: Grouping<DangerousGoodsMasterData, string>[];
  packType?: "PLT" | "PCE";
}

export interface IDangerousGoodsItem {
  html: string;
  value: string;
  remover: () => void;
  data?: DangerousGoodsMasterData
}

export interface IJsonDocument extends JsonDocument {
  documentType: keyof typeof DocumentTypes;
  link?: string;
}

export type FileUpload = {
  progress: number,
  state: "queued" | "uploading" | "uploaded" | "error",
  jsonDocument: IJsonDocument,
  file: IFile,
  error?: string
};

export interface IFile extends Dropzone.DropzoneFile {
  id: string;
}
