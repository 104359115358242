import {Pipe, PipeTransform} from '@angular/core';
import {
  AddressLocation,
  CityLocation,
  Container,
  DangerousGoodsData,
  DeliveryStatus,
  DimensionValue,
  JobType,
  JsonContainer,
  PortLocation,
  Shipment,
  ShipmentContainer,
  ShipmentContainerGroup
} from "@api";
import {FieldRenderer} from "../page-components/shipment/shipment-list/field-renderer.service";

@Pipe({
        name: 'rtString',
        pure: true
      })
export class StringPipe implements PipeTransform {

  private readonly _fieldRenderer: FieldRenderer;

  constructor(fieldRenderer: FieldRenderer) {
    this._fieldRenderer = fieldRenderer;
  }

  transform(value: string): string {
    return this._fieldRenderer.renderString(value)
  }
}

@Pipe({
        name: 'rtInteger',
        pure: true
      })
export class IntegerPipe implements PipeTransform {

  private readonly _fieldRenderer: FieldRenderer;

  constructor(fieldRenderer: FieldRenderer) {
    this._fieldRenderer = fieldRenderer;
  }

  transform(value: number): string {
    return this._fieldRenderer.renderInteger(value);
  }
}

@Pipe({
        name: 'rtDecimal',
        pure: true
      })
export class DecimalPipe implements PipeTransform {

  private readonly _fieldRenderer: FieldRenderer;

  constructor(fieldRenderer: FieldRenderer) {
    this._fieldRenderer = fieldRenderer;
  }

  transform(value: number | DimensionValue, precision = 3): string {
    return this._fieldRenderer.renderDecimal(value, precision)
  }
}

@Pipe({
        name: 'rtTbd',
        pure: true
      })
export class TbdPipe implements PipeTransform {

  transform(value: string): string {
    return value && value !== "–" ? value : "T.B.D.";
  }

}

@Pipe({
        name: 'rtNoPlaceholder',
        pure: true
      })
export class NoPlaceholderPipe implements PipeTransform {

  transform(value: string): string {
    return (!value || value === "–" || value === "T.B.D.") ? "" : value;
  }

}

@Pipe({
        name: 'rtStringArray',
        pure: true
      })
export class StringArrayPipe implements PipeTransform {

  private readonly _fieldRenderer: FieldRenderer;

  constructor(fieldRenderer: FieldRenderer) {
    this._fieldRenderer = fieldRenderer;
  }

  transform(value: string[]): string {
    return this._fieldRenderer.renderStringArray(value)
  }
}

@Pipe({
        name: 'rtPortTitle',
        pure: true
      })
export class PortTitlePipe implements PipeTransform {

  private readonly _fieldRenderer: FieldRenderer;

  constructor(fieldRenderer: FieldRenderer) {
    this._fieldRenderer = fieldRenderer;
  }

  transform(value: PortLocation, type: "IATA" | "UNLOCO" | "both" | "none"): string {
    return this._fieldRenderer.renderPortLocationTitle(value, type)
  }

}

@Pipe({
        name: 'rtContainerIconClass',
        pure: true
      })
export class ContainerIconClassPipe implements PipeTransform {

  private static isJsonContainer(value: Container | ShipmentContainer | ShipmentContainerGroup | JsonContainer): value is JsonContainer {
    return value.hasOwnProperty("containerType");
  }

  private static isContainerGroup(value: Container | ShipmentContainer | ShipmentContainerGroup | JsonContainer): value is  ShipmentContainerGroup {
    return value.hasOwnProperty("commonType");
  }

  transform(value: Container | ShipmentContainer | ShipmentContainerGroup | JsonContainer): string {
    let classPrefix = "container-icon-";

    if (new Date().getMonth() === 11)
      classPrefix = "christmas " + classPrefix;

    if (ContainerIconClassPipe.isContainerGroup(value))
      return classPrefix + this.mapDescriptionToClassAppendix(value.commonType)
    else if (ContainerIconClassPipe.isJsonContainer(value))
      return classPrefix + value.containerType;
    else
      return classPrefix + this.mapDescriptionToClassAppendix(value.type);
  }

  // noinspection JSMethodCanBeStatic
  private mapDescriptionToClassAppendix(type: string) {
    switch (type || "") {
      case "20ft General Purpose":
      case "20ft Hard Top":
        return "20GP";
      case "40ft General Purpose":
        return "40GP";
      case "40ft Open Top":
        return "40OT";
      case "40ft High Cube":
        return "40HC";
      case "20ft Reefer":
      case "20ft Non Operating Reefer":
        return "20RE";
      case "40ft Reefer":
      case "40ft Non Operating Reefer":
        return "40RE";
      case "40ft High Cube Reefer":
      case "40ft High Cube Non Operating Reefer":
        return "40REHC";
      case "20ft Tank (Hazardous)":
      case "20ft Tank (Non Hazardous)":
        return "20T";
      default:
        return "other";
    }
  }

}

@Pipe({
        name: 'rtPortValue',
        pure: true
      })
export class PortValuePipe implements PipeTransform {

  private readonly _fieldRenderer: FieldRenderer;

  constructor(fieldRenderer: FieldRenderer) {
    this._fieldRenderer = fieldRenderer;
  }

  transform(value: PortLocation | PortLocation[],
            type: Shipment | Container | "IATA" | "UNLOCO" | "both" | "none",
            countryPlacing: 'first' | 'last' = 'first'): string {
    if (value instanceof Array) {
      if (value.length > 1)
        return FieldRenderer.multipleLocations;
      else
        value = value[0];
    }
    return this._fieldRenderer.renderPortLocationValue(value, type, countryPlacing)
  }
}

@Pipe({
        name: 'rtCityValue',
        pure: true
      })
export class CityValuePipe implements PipeTransform {

  private readonly _fieldRenderer: FieldRenderer;

  constructor(fieldRenderer: FieldRenderer) {
    this._fieldRenderer = fieldRenderer;
  }

  transform(value: CityLocation | CityLocation[]): string {
    return this._fieldRenderer.renderCityLocationValue(value)
  }
}

@Pipe({
        name: 'rtCityTitle',
        pure: true
      })
export class TbdCityTitlePipe implements PipeTransform {

  private readonly _fieldRenderer: FieldRenderer;

  constructor(fieldRenderer: FieldRenderer) {
    this._fieldRenderer = fieldRenderer;
  }

  transform(value: CityLocation | CityLocation[]): string {
    if (value instanceof Array) {
      if (value.length > 1)
        return "";
      else
        value = value[0];
    }
    return this._fieldRenderer.renderCityLocationTitle(value)
  }
}

@Pipe({
        name: 'rtAddressName',
        pure: true
      })
export class AddressNamePipe implements PipeTransform {

  transform(value: AddressLocation | AddressLocation[], multipleLocationsPlaceholder = FieldRenderer.multipleLocations): string {
    if (value instanceof Array) {
      if (value.length > 1)
        return multipleLocationsPlaceholder;
      else
        value = value[0];
    }
    return value?.name;
  }
}

@Pipe({
        name: 'rtDimension',
        pure: true
      })
export class DimensionPipe implements PipeTransform {

  private readonly _fieldRenderer: FieldRenderer;

  constructor(fieldRenderer: FieldRenderer) {
    this._fieldRenderer = fieldRenderer;
  }

  transform(value: DimensionValue | DimensionValue[]): string {
    return this._fieldRenderer.renderDimensionValue(value)
  }
}

@Pipe({
        name: 'rtMergeDimensions',
        pure: true
      })
export class MergeDimensionPipe implements PipeTransform {

  transform(value: DimensionValue[][] | DimensionValue[]): DimensionValue[] {
    return value
      .flat(1)
      .filter(v => !!v)
      .groupBy(v => v.dimension)
      .map(g => {
        return {
          dimension: g.key,
          value: g.items.sum(v => v.value)
        }
      });
  }
}

@Pipe({
        name: 'rtSum',
        pure: true
      })
export class SumPipe implements PipeTransform {

  transform(value: number[]): number {
    return value.sum(v => v)
  }
}

@Pipe({
        name: 'rtMerge',
        pure: true
      })
export class MergePipe implements PipeTransform {

  transform<T, TKey extends keyof T>(value: T[], key: TKey): T[TKey][] {
    return value.map(v => (v[key]));
  }
}

@Pipe({
        name: 'rtFlatten',
        pure: true
      })
export class FlattenPipe implements PipeTransform {

  transform<T>(value: T[][]): T[] {
    return value.flat();
  }
}

@Pipe({
        name: 'rtDistinct',
        pure: true
      })
export class DistinctPipe implements PipeTransform {

  transform<T>(value: T[]): T[] {
    return value.distinct();
  }
}

@Pipe({
        name: 'rtDangerousGoodsTitle',
        pure: true
      })
export class DangerousGoodsTitlePipe implements PipeTransform {

  private readonly _fieldRenderer: FieldRenderer;

  constructor(fieldRenderer: FieldRenderer) {
    this._fieldRenderer = fieldRenderer;
  }

  transform(value: Grouping<DangerousGoodsData, string>[]): string {
    return this._fieldRenderer.renderDangerousGoodsTitle(value)
  }
}

@Pipe({
        name: 'rtDeliveryStatus',
        pure: true
      })
export class DeliveryStatusPipe implements PipeTransform {

  private readonly _fieldRenderer: FieldRenderer;

  constructor(fieldRenderer: FieldRenderer) {
    this._fieldRenderer = fieldRenderer;
  }

  transform(value: DeliveryStatus): string {
    return this._fieldRenderer.renderDeliveryStatusName(value)
  }
}


@Pipe({
        name: 'rtShipmentTypeUrlParam',
        pure: true
      })
export class ShipmentTypeUrlParamPipe implements PipeTransform {

  static transform(value: JobType): string {
    switch (value) {
      case JobType.Shipment:
        return "SHI"
      case JobType.Booking:
        return "BKG"
      case JobType.CustomsDeclaration:
        return "CD"
      default:
        return "SHI"
    }
  }

  transform(value: JobType): string {
    return ShipmentTypeUrlParamPipe.transform(value)
  }

}
