import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {combineLatest, from, fromEvent, merge, Observable, Subject} from "rxjs";
import {ContainerListLayoutItem} from "@api";
import {debounceTime, filter, map, takeUntil} from "rxjs/operators";
import {ContainerListFields, ContainerListLayoutMapper} from "@app/page-components/shipment/shipment-list/container-list-fields";
import {AppBaseComponent} from "@app/appBaseComponent";
import {ShipmentOverviewItem} from "@app/page-components/shipment/shipment-list/shipment-overview-item";
import {ShipmentListFields, ShipmentListLayoutMapper} from "@app/page-components/shipment/shipment-list/shipment-list-fields";
import {FieldRenderer} from "@app/page-components/shipment/shipment-list/field-renderer.service";
import {AccountStore} from "@stores";
import {ShipmentListLineViewComponent} from "@app/page-components/shipment/shipment-list/shipment-list-list-view/shipment-list-line-view.component";
import {ContainerOverviewItem} from "@app/page-components/shipment/shipment-list/container-overview-item";
import {ShipmentTypeUrlParamPipe} from "@app/value-pipes/string-value.pipe";
import {ContainerStore} from "@stores/container-store.service";

@Component({
             selector: 'rt-container-list-view',
             templateUrl: './container-list-view.component.html',
             styleUrls: ['./container-list-view.component.scss'],
             host: {
                class: "shipment-list-scroll-container"
             },
           })
export class ContainerListViewComponent extends AppBaseComponent implements AfterViewInit {

  @Input("skeleton-mode")
  skeletonMode: boolean = false;

  @ViewChild("table")
  table: ElementRef<HTMLElement>;

  @Input("skeleton-item-count")
  skeletonItemCount: number = 24;

  readonly items$: Observable<(ShipmentOverviewItem | ContainerOverviewItem)[]>;
  readonly enableOverflow$: Observable<boolean>;
  readonly listLayout$: Observable<ContainerListLayoutItem[]>;
  readonly renderer: FieldRenderer;
  readonly dangerousGoodsItem = ShipmentListFields["DangerousGoods"];
  hideCellsClass$: Observable<string>;
  private readonly _accountStore: AccountStore;
  private readonly _containerStore: ContainerStore;
  private readonly _elementRef: ElementRef;
  private readonly _initialized = new Subject<void>();

  constructor(containerStore: ContainerStore, accountStore: AccountStore, renderer: FieldRenderer, elementRef: ElementRef) {
    super();
    this._containerStore = containerStore;
    this.renderer = renderer;
    this._accountStore = accountStore;
    this._elementRef = elementRef;
    this.items$ = containerStore.observe("containerOverviewList");
    this.enableOverflow$ = accountStore.observe("listLayoutOverflow")
    this.listLayout$ = accountStore
      .observe("containerListLayout")
    const itemWidths$ =
      combineLatest(
        this.listLayout$,
        merge(from([]), containerStore.observe("containerOverviewList"))
      )
        .pipe(
          takeUntil(this.destroyed$),
          debounceTime(10),
          map(() => {
            const output: number[] = [];
            let table = this.table.nativeElement;
            table.classList.add("force-show-all-columns")
            const columns = table.firstElementChild.children
            for (let i = 0; i < columns.length; i++) {
              let column = columns[i];
              if (column instanceof HTMLDivElement && !column.classList.contains("filler"))
                output.push(column.clientWidth)
            }
            table.classList.remove("force-show-all-columns")
            return output;
          }));
    this.hideCellsClass$ =
      combineLatest(
        merge(
          fromEvent(window, "resize").pipe(map(() => {
          })),
          this._initialized.asObservable()
        )
          .pipe(
            map(() => (elementRef.nativeElement as HTMLElement)?.clientWidth || null),
            filter(v => !!v)
          ),
        this.enableOverflow$,
        itemWidths$
      )
        .pipe(
          takeUntil(this.destroyed$),
          map(([width, overflowEnabled, itemWidths]) => {
            if (overflowEnabled)
              return "";
            let maxDisplayableItems = 0
            for (const item of itemWidths) {
              width -= item
              if (width < 0)
                break;
              maxDisplayableItems++;
            }
            return `show-${maxDisplayableItems}-cells`
          })
        );
  }

  trackById = (_:number, item: ContainerOverviewItem|ShipmentOverviewItem) => {
    if (item instanceof ContainerOverviewItem)
      return item.containerId;
    return item.id;
  };

  ngAfterViewInit(): void {
    this._initialized.next();
  }

  renderItem = (shipment: ShipmentOverviewItem | ContainerOverviewItem, layout: ContainerListLayoutItem[]): string => {
    let output = "";
    const renderer = this.renderer;
    for (const field of layout) {
      let mapper: ShipmentListLayoutMapper | ContainerListLayoutMapper =
        shipment instanceof ShipmentOverviewItem
        ? (ShipmentListFields as any)[field]
        : ContainerListFields[field]

      if (shipment instanceof ShipmentOverviewItem && field === ContainerListLayoutItem.ContainerNumber)
        mapper = ShipmentListFields.BillOfLading

      if (!mapper) {
        output += `<div>${renderer.renderString(null)}</div>`
        continue;
      }

      const value = mapper.getValue(shipment as any);
      const isDgList = field === ContainerListLayoutItem.DangerousGoods;
      const content = isDgList
                      ?
                      ShipmentListLineViewComponent.renderDgList(shipment.dangerousGoods)
                      : mapper.getContent(shipment as any, renderer, value)
      let title = content
      if (mapper.getTitle)
        title = mapper.getTitle(shipment as any, renderer, value)
      output += `<div
            title="${title}"
            class="${mapper.size || ''} ${mapper.getClass ? mapper.getClass(value as any) : ''}">
            ${content}
          </div>`
    }
    return output + "<div class='filler'></div>";
  };

  readonly getQueryParams = (item: ContainerOverviewItem | ShipmentOverviewItem): { ContainerID: string } | { JobID: string, ObjectType: string } => {
    if (item instanceof ContainerOverviewItem) {
      return {
        ContainerID: item.containerId,
      }
    }
    return {
      JobID: item.id,
      ObjectType: ShipmentTypeUrlParamPipe.transform(item.jobType)
    }
  };

  getSize(item: ContainerListLayoutItem) {
    return ContainerListFields[item].size;
  }

  getColumnTitle(item: ContainerListLayoutItem) {
    return ContainerListFields[item].columnTitle.replace("/","/<br />");
  }

}
