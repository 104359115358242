import {Injectable} from "@angular/core";
import {NavigationEnd, Router, RoutesRecognized} from "@angular/router";
import {
  AdministrationViewData,
  BookingViewData,
  EntityListViewData,
  EntityViewData,
  ErrorViewData,
  LoginViewData, NoUserRightsViewData,
  OnlineMonitorViewData,
  OrderListViewData,
  PreLoginViewData,
  ProfileViewData,
  ShipmentListViewData,
  ShipmentViewData,
  TemplateViewData,
  UserListViewData,
  UserViewData,
  ViewData
} from "@services";
import {OrderViewData} from "@services/view-data/data-objects/order-view-data";
import {TypeFilter} from "@utils/rxjs-extensions";
import {filter} from "rxjs/operators";
import {parseRouteSnapshotToViewData} from "@app/utils/url-parser";
import {UserRightsStoreState} from "@app/stores/userRightsStoreState";
import {UserRightsStore} from "@app/stores/user-rights-store.service";
import {RoutingStore} from "@app/stores/routing-store.service";
import {Subject} from "rxjs";

@Injectable({providedIn: "root"})
export class RoutingService {

  private readonly _router: Router;
  private readonly _userRightsStore: UserRightsStore;
  private readonly _reloadRequestedSubject= new Subject<ViewData>();
  readonly routingStore: RoutingStore;
  readonly reloadRequested$ = this._reloadRequestedSubject.asObservable();

  constructor(routingStore: RoutingStore, userRightsStore: UserRightsStore, router: Router) {
    this.routingStore = routingStore;
    this._userRightsStore = userRightsStore;
    this._router = router;
    this._setupSubscriptions();
  }

  private _setupSubscriptions() {
    this._userRightsStore.subscribe(u => this._userRightsChanged(u));
    this._router.events
        .pipe(filter(TypeFilter(RoutesRecognized)))
        .subscribe(this._updateLastRecognizedRoute);
    this._router.events
        .pipe(filter(TypeFilter(NavigationEnd)))
        .subscribe(this._loadLastRecognizedRouteViewData)
  }

  private readonly _loadLastRecognizedRouteViewData = () => {
    const lastRecognizedRouteViewData = this.routingStore.get("lastRecognizedRouteViewData");
    this.routingStore.update({currentViewData: lastRecognizedRouteViewData})
  };

  private readonly _updateLastRecognizedRoute = (e: RoutesRecognized) => {
    const viewData = parseRouteSnapshotToViewData(e.state.root);
    this.routingStore.update({lastRecognizedRouteViewData: viewData});
  };

  private static _canUserAccessSite(viewData: ViewData, userRights: Partial<UserRightsStoreState>): boolean {
    if (viewData instanceof ErrorViewData)
      return true;
    if (!userRights.isAuthenticated)
      return viewData instanceof PreLoginViewData;
    if (viewData instanceof AdministrationViewData)
      return userRights.administration;
    if (viewData instanceof EntityViewData)
      return userRights.entityManagement;
    if (viewData instanceof UserViewData)
      return userRights.userManagement;
    if (viewData instanceof ProfileViewData)
      return true;
    if (viewData instanceof ShipmentViewData)
      return userRights.shipments;
    if (viewData instanceof OrderViewData)
      return userRights.orders;
    if (viewData instanceof BookingViewData || viewData instanceof TemplateViewData)
      return userRights.bookings;
    return false;
  }

  private readonly _userRightsChanged = (userRights: Partial<UserRightsStoreState>) => {
    const currentViewData = this.routingStore.get().currentViewData;
    const applicationIsBooting = !currentViewData;
    if (applicationIsBooting)
      return;
    if (RoutingService._canUserAccessSite(currentViewData, userRights))
      return;
    this.navigateTo(RoutingService._getFirstSiteUserCanAccess(userRights));
  };

  private static _getFirstSiteUserCanAccess(userRights: Partial<UserRightsStoreState>) {
    if (userRights.shipments)
      return new ShipmentListViewData()
    if (userRights.orders)
      return new OrderListViewData()
    if (userRights.userManagement)
      return new UserListViewData()
    if (userRights.entityManagement)
      return new EntityListViewData()
    if (userRights.administration)
      return new OnlineMonitorViewData()
    if (userRights.isAuthenticated)
      return new NoUserRightsViewData()
    return new LoginViewData()
  }

  navigateTo(viewData: ViewData, replaceUrl = false): void {
    void this._router.navigateByUrl(viewData.url, {replaceUrl});
  }


  getCurrentViewData(): ViewData {
    return this.routingStore.get("currentViewData");
  }

  requestReload(viewData: ViewData): void {
    this._reloadRequestedSubject.next(viewData);
  }
}
