import {Directive, OnDestroy} from "@angular/core";
import {Subject} from "rxjs";

@Directive()
export abstract class AppBaseComponent implements OnDestroy{

  private readonly _destroyed = new Subject<void>();
  protected readonly destroyed$ = this._destroyed.asObservable();

  ngOnDestroy(): void {
    this._destroyed.next();
  }

}
