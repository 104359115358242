import {ApplicationRef, Directive, ElementRef, EventEmitter, forwardRef, Injectable, Input, OnInit, Output, Provider} from "@angular/core";
import {ContentComponent} from "@utils/Angular/ContentComponent";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ISelectOptions, Select} from "@widgets/Select";
import {JSONData} from "@api";
import {arrayify} from "@utils/Utils";
import {ExternalSearchResult, ISelectListItem, SelectionChangedEvent} from "@widgets/SelectList";
import {NgSelectionChangedEvent, SelectDirective} from "./select.directive";
import {IPopupSelectListOptions, PopupSelectList} from "@widgets/PopupSelectList";


const POPUP_SELECT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PopupSelectDirective),
  multi: true
};

@Directive({
             selector: 'select[rtPopupSelect]',
             providers: [POPUP_SELECT_VALUE_ACCESSOR],
             exportAs: "select",
             host: {
               "(change)": "onChange()"
             }
           })
@Injectable()
export class PopupSelectDirective extends ContentComponent implements ControlValueAccessor, OnInit {
  private _disabled: boolean;
  @Input()
  set disabled(value:boolean) {
    this._disabled = value;
    if (value)
    this.select?.disable(true);
    else
      this.select?.enable()
  }
  @Input()
  settings: Partial<IPopupSelectListOptions>;
  @Input()
  searchFunction: Func1<string, Promise<ExternalSearchResult>>;
  select: PopupSelectList;
  @Output()
  selectionChanged = new EventEmitter<NgSelectionChangedEvent>();
  @Output()
  closed = new EventEmitter<NgSelectionChangedEvent>();
  @Output()
  initialized = new EventEmitter<PopupSelectList>();
  private _isWritingValue = false;
  private _onChange?: () => void;
  private _onTouched?: Action1<string[] | string>;

  constructor(element: ElementRef, app: ApplicationRef) {
    super(element, app);
    this.element.style.display = "none";
  }

  private _items: Partial<JSONData>[];

  @Input()
  set items(value: Partial<JSONData>[]) {
    this._items = value;
    if (this.select)
      this.select.processJsonData(value);
  }

  writeValue(obj: string | string[]): void {
    if (!this.select)
      return;
    this._isWritingValue = true;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const valuesToSelect = arrayify(obj);
    if (this._valueHasNotChanged(valuesToSelect)) {
      this._isWritingValue = false;
      return;
    }
    const currentSelectedItems = this.select.getSelectedItems();
    const removedItems = currentSelectedItems.filter(i => !valuesToSelect.contains(i.value));
    const selectedExistingItems = this.select.getItems().filter(i => valuesToSelect.contains(i.value));
    const newItemValues  =valuesToSelect.filter(v => !selectedExistingItems.any(i => i.value === v));
    this.select.processJsonData(newItemValues.map(i => {
      return {value: i, label: i}
    }));
    const newItems = this.select.getItems().filter(i => newItemValues.contains(i.value))
    this.select.unselectItems(removedItems, true, true);
    this.select.selectItems(selectedExistingItems.concat(newItems), true, true);
    this._isWritingValue = false;
  }

  registerOnChange(fn: Action1<string[] | string>): void {
    this._onChange = () => {
      if (this._isWritingValue)
        return;
      if (this.select.options.multiple)
        fn(this.select.getSelectedItems().map(i => i.value));
      else if (this.select.getSelectedItems().length)
        fn(this.select.getSelectedItems()[0].value);
      else
        fn(null);
    };
  }

  registerOnTouched(fn: Action1<string[] | string>): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled)
      this.select.disable(true);
    else
      this.select.enable();
  }

  ngOnDestroy(): void {
    this.select?.close();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    const $select = $(this.element);
    if ($select.data("select"))
      this.select = $select.data("select");
    else {
      const options = this.settings || $select.getJsonFromAttribute<IPopupSelectListOptions>("data-settings") || {};
      if (this.searchFunction)
        options.externalSearchHandler = this.searchFunction;
      this.select =
        new PopupSelectList($select[0] as HTMLSelectElement, Object.assign({}, options, {onInitialized: null}));
    }
    if (this._items)
      this.select.processJsonData(this._items);
    if (this._disabled)
      this.select.disable();
    this.select.onSelectionChanged.on(e => {
      this._selectionChanged(e)
    });
  }

  onInitialized(): void {
    if (this.settings?.onInitialized)
      this.settings.onInitialized(this.select);
    this.initialized.emit(this.select);
  }

  onChange(): void {
    if (this._onChange)
      this._onChange();
  }

  private _selectionChanged(e: SelectionChangedEvent<ISelectListItem, PopupSelectList>) {
    if (this._isWritingValue)
      return
    const ngEvent: NgSelectionChangedEvent = {
      addedItems: e.addedItems,
      removedItems: e.removedItems,
      selection: this.select.getSelectedItems()
    };
    this.selectionChanged.emit(ngEvent);
  }

  private _valueHasNotChanged(newSelectedValues: string[]) {
    const currentSelectedValues = this.select.getSelectedItems()
    for (const currentSelectedValue of currentSelectedValues) {
      if (!newSelectedValues.contains(currentSelectedValue.value))
        return false;
    }
    return currentSelectedValues.length === newSelectedValues.filter(v => !!v).distinct().length;
  }
}
