import {OrderViewData} from "./order-view-data";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class OrderListViewData extends OrderViewData {
  readonly redoSearch: boolean;
  readonly query: string;

  constructor(options: { redoSearch?: boolean, query?: string} = {}) {
    super();
    this.query = options.query || null;
    this.redoSearch = options.redoSearch || false;
  }
  get pageTitle(): string {
    return TitleBuilder.order.list;
  }

  get url(): string {

    if (this.query !== null)
      return UrlBuilder.order.listWithQuery(this.query)
    else
      return UrlBuilder.order.list;
  }
 

  get bodyClasses(): string[] {
    return ["page-order-list"];
  }

}
