import {ApplicationRef, Component, ElementRef, ViewChild} from "@angular/core";
import {IncoTerms} from "./BookingModel"
import {SelectDirective} from "@directives";
import {Router} from "@angular/router";
import {ViewComponent} from "./view.component";
import {FixIeAnimationIssue, SlideInOut} from "@utils/Angular/AnimationTemplates";
import {PartiesComponent} from "./parties.component";
import {toDictionary} from "@utils/Utils";
import {JSONData} from "@api";
import {BookingServiceListener} from "@services/booking-service-listener.service";
import {GetViewObject} from "@booking/booking-views";
import {filter, skip, takeUntil} from 'rxjs/operators';
import {hasValue} from "@utils/rxjs-extensions";
import {AccountStore} from "@app/stores";
import {DangerousGoodsService} from "@booking/DangerousGoodsService";

@Component({
             selector: "service",
             animations: [
               SlideInOut()
             ],
             template: `
               <h2>Service</h2>
<div class="form-group">
<span class="form-label">
<label class="required">Shipment Direction</label>
</span>
    <div class="button-toggle-large button-toggle" style="display: flex; width: 100%; max-width: 440px;">
        <input
                type="radio"
                value="out" name="direction"
                [(ngModel)]="model.booking.direction"
                id="os"
                (ngModelChange)="directionChange()"
                required
                data-display-name="Shipment Direction"/>
        <label for="os" data-description="I am the Shipper" class="required">Outbound<span
                class="show-phone-landscape"> Shipment</span></label>
        <input type="radio" value="in" name="direction" [(ngModel)]="model.booking.direction" id="is"
               (ngModelChange)="directionChange()"/>
        <label for="is" data-description="I am the Consignee" class="required">Inbound<span
                class="show-phone-landscape"> Shipment</span></label>
    </div>
</div>

<div class="form-group">
<span class="form-label">
<label class="required">Transport Mode</label>
</span>
    <div class="button-toggle-large button-toggle" style="display: flex; width: 100%; max-width: 440px;">
        <input
                type="radio"
                value="SEA"
                name="mode"
                id="sea"
                [(ngModel)]="model.booking.transportMode"
                (ngModelChange)="transportModeChanged()"
                (change)="model.booking.shipmentType = null"
                required
                data-display-name="Transport Mode"/>
        <label for="sea" class="required">Sea Freight</label>
        <input
                type="radio"
                value="AIR"
                [(ngModel)]="model.booking.transportMode"
                (ngModelChange)="transportModeChanged()"
                (change)="model.booking.shipmentType = 'LSE'"
                name="mode"
                id="air"/>
        <label for="air" class="required">Air Freight</label>
    </div>
</div>
<div class="form-group" [@slideInOut]="model.booking.transportMode==='SEA' ? 'visible' : 'hidden'">
<span class="form-label">
<label class="required">Container Mode</label>
</span>
    <div class="button-toggle-large button-toggle" style="display: flex; width: 100%; max-width: 440px;">
        <input
                type="radio"
                value="LCL"
                name="type"
                id="lcl"
                [(ngModel)]="model.booking.shipmentType"
                [attr.disabled]="model.booking.transportMode==='SEA'? null : true"
                required
                data-display-name="Container Mode"/>
        <label for="lcl" data-description="Loose Goods" class="required">LCL</label>
        <input type="radio" value="FCL" name="type" id="fcl"
               [attr.disabled]="model.booking.transportMode==='SEA'? null : true"
               [(ngModel)]="model.booking.shipmentType"/>
        <label for="fcl" data-description="Full Container" class="required">FCL</label>
    </div>
</div>
<div class="form-group">
<span class="form-label">
<label for="incoTerm">Incoterm</label>
</span>
    <select
            value="{{model.booking.incoTerm || ''}}" name="incoTerm"
            [(ngModel)]="model.booking.incoTerm"
            [settings]='{"closeOnSelect": true, "allowNoSelection": true, "selectedOnTop": false}'
            ngSelect
            [items]="incoTermSelectItems"
            id="incoTerm"
            (ngModelChange)="incoTermChanged()"
            [attr.required]="mode === 'booking' ? 'required' : null">
        <!--                     <option *ngFor="let term of incoTermsDictionary" [value]="term.key">{{term.value}}</option>-->
    </select>
</div>
<div class="buttons phone-auto-width">
    <button class="button secondary" [disabled]="!canNavigateBack" (click)="navigateBack()">Back
    </button>
    <button class="button" type="button" [disabled]="!canNavigateNext" (click)="navigateNext()">
        Next
    </button>
    <button class="button secondary disable-when-offline" (click)="cancel()" type="button">Cancel
    </button>
</div>
             `
           })
export class ServiceComponent extends ViewComponent {

  readonly name = "ServiceComponent";
  fixIeAnimationIssue = FixIeAnimationIssue;
  incoTerms = IncoTerms;
  incoTermsDictionary = toDictionary(IncoTerms);
  incoTermSelectItems = toDictionary(IncoTerms)
    .map(t => {
      return {value: t.key, label: t.value} as JSONData
    });
  @ViewChild(SelectDirective)
  private incoTermSelect: SelectDirective;
  private readonly _accountStore: AccountStore;

  private readonly _dangerousGoodsService: DangerousGoodsService;

  constructor(element: ElementRef,
              app: ApplicationRef,
              router: Router,
              bookingServiceListener: BookingServiceListener,
              accountStore: AccountStore,
              dangerousGoodsService: DangerousGoodsService) {
    super(element, app, router, bookingServiceListener);
    this._dangerousGoodsService = dangerousGoodsService;
    this._accountStore = accountStore;
    this.bookingServiceListener.bookingModel$
        .pipe(
          filter(hasValue),
          skip(1),
          filter(m => this.mode === "booking"),
          takeUntil(this.destroyed$)
        )
        .subscribe(() => this.entered());
  }

  // static Validate(model: BookingModel): boolean {
  //   const booking = model.booking;
  //   if (this === "booking") {
  //     if (!booking.incoTerm)
  //       return false;
  //   }
  //   return !!(booking.direction && booking.transportMode &&
  //             (booking.transportMode !== "SEA" || booking.jobType));
  // }

  async ngAfterViewInit(): Promise<void> {
    super.ngAfterViewInit();
  }

  incoTermChanged() {
    this.incoTermSelect.select.syncItems();
  }

  transportModeChanged() {
    if (this.model.booking.transportMode === "SEA") {
      if (this.model.booking.shipmentType === "LSE") {
        this.model.booking.shipmentType = null;
      }
    } else {
      this.model.booking.shipmentType = "LSE";
    }
    let viewObject = GetViewObject(PartiesComponent) as PartiesComponent;
    if (viewObject) {
      viewObject.checkPorts();
    } else {
      void this.model.checkPorts();
    }
    this.model.packages.forEach(p => p.dangerousGoods?.clear())
  }

  directionChange() {
    const booking = this.model.booking;
    const bookingCountries =
      this._accountStore
          .get("currentEntitySet")
          .bookingSites
          .filter(c => !c.showWarningOnSelect)
          .map(c => c.value);
    if (booking.direction === "in") {
      if (booking.consigneeAddress) {
        if (!bookingCountries.contains(booking.consigneeAddress.country) ||
            booking.sitePK && booking.consigneeAddress.sitePK !== booking.sitePK) {
          booking.consigneeAddress = null;
        }
      }
    } else {
      if (booking.consignorAddress) {
        if (!bookingCountries.contains(booking.consignorAddress.country) ||
            booking.sitePK && booking.consignorAddress.sitePK !== booking.sitePK) {
          booking.consignorAddress = null;
        }
      }
    }
  }

  async validate() {
    const isValid = await super.validate();
    if (isValid) {
      if (!this.model.booking.templateInformation.pickupAddressType) {
        if (["FCA", "FAS", "FOB"].contains(this.model.booking.incoTerm)) {
          this.model.booking.templateInformation.pickupAddressType = "none";
        } else {
          this.model.booking.templateInformation.pickupAddressType = "sameAddress";
        }
      }
      if (!this.model.booking.templateInformation.deliveryAddressType) {
        if (["CFR", "CIF"].contains(this.model.booking.incoTerm)) {
          this.model.booking.templateInformation.deliveryAddressType = "none";
        } else {
          this.model.booking.templateInformation.deliveryAddressType = "sameAddress";
        }
      }
    }
    return isValid;
  }
}
