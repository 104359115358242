import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Directive, Inject, Input, OnDestroy, OnInit, Optional} from "@angular/core";
import {TypedStoreAccessor} from "@stores/store-accessor";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Directive({
             selector: "[storeAccessor]",
             // providers: [
             //   {
             //     provide: NG_VALUE_ACCESSOR,
             //     deps: [[new Optional(), ElementRef], [new Optional(), Renderer2], [new Optional(), COMPOSITION_BUFFER_MODE]],
             //     useFactory: (elementRef: ElementRef, renderer2: Renderer2, compositionMode: boolean): ControlValueAccessor => {
             //       if (!elementRef || !renderer2)
             //         return null;
             //       const element = elementRef.nativeElement as HTMLElement;
             //       if (element instanceof HTMLInputElement) {
             //         if (element.type === "checkbox")
             //           return new CheckboxControlValueAccessor(renderer2, elementRef);
             //         // if(element.type ==="radio")
             //         //   return new RadioControlValueAccessor(renderer2, elementRef);
             //       }
             //       return new DefaultValueAccessor(renderer2, elementRef, compositionMode);
             //     },
             //     multi: true
             //   }
             // ]
           })
export class StoreAccessorDirective<T> implements OnDestroy, OnInit {
  @Input()
  storeAccessor: TypedStoreAccessor<T>
  private _destroyed = new Subject<void>();
  private _destroyed$ = this._destroyed.asObservable();
  private readonly _valueAccessor: ControlValueAccessor;

  constructor(@Optional() @Inject(NG_VALUE_ACCESSOR) valueAccessors: ControlValueAccessor[]) {
    this._valueAccessor = valueAccessors ? valueAccessors[0] : null;
  }

  ngOnDestroy(): void {
    this._destroyed.next();
  }

  ngOnInit(): void {
    const storeAccessor = this.storeAccessor;
    storeAccessor.observe
                 .pipe(takeUntil(this._destroyed$))
      .subscribe(v => {
        if (typeof v === "string") {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          (v as String) = v.toString().replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">");
        }
                   // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                   this._valueAccessor?.writeValue(v)
                 });
    this._valueAccessor?.registerOnChange((v: T) => {
      storeAccessor.set(v)
    });
  }
}

