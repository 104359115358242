import {Directive, ElementRef, forwardRef, Renderer2} from "@angular/core";
import {CheckboxControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";



@Directive({
               selector:'input[storeAccessor][type=checkbox]',
               host: {'(change)': 'onChange($event.target.checked)', '(blur)': 'onTouched()'},
               providers: [{
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: forwardRef(() => RtCheckboxControlValueAccessor),
                 multi: true,
               }]
           })
export class RtCheckboxControlValueAccessor extends CheckboxControlValueAccessor {
    constructor(_renderer: Renderer2, _elementRef: ElementRef) {
        super(_renderer, _elementRef)
    }
}
