import {EventEmitter} from "@utils/EventEmitter";
import {ControlDataEvent, ControlEvent, RenderedEvent} from "../libs/ExtendableEvent";
import {Alignment, PopupPosition} from "@utils/Enums";
import * as Utils from "../utils/Utils";
import {checkScrollMargin, ExtendOptions, UnwrapContainerFunction, UnwrapFunction} from "@utils/Utils";
import {PositionAndDimensions, PositionState} from "../bluePrototypes";

require("../utils/JqueryLoader");

export interface IPopupOptions {
    position?: FunctionOrValue<PopupPosition>;
    /**
     * If true the popup will be moved if it isn't fully in the viewport
     * @default false
     */
    autoPosition?: boolean;
    horizontalAlignment?: FunctionOrValue<Alignment>;
    verticalAlignment?: FunctionOrValue<Alignment>;
    /**
     * If true the popup can grow to the size of its contents
     * @default true
     */
    allowGrowing?: FunctionOrValue<boolean>;
    /**
     * If true and autoPosition is also true, the vertical and horizontal alignments will be swapped when the popup changes position from left/right to top/bottom
     */
    autoSwapAlignment?: boolean;
    /**
     * Container element where the popup DOM will be inserted.
     * @default body element.
     */
    container?: ContainerType;
    /**
     * Element to which the popup will be visually attached
     */
    visualContainer?: ContainerType;
    css?: ICssStyles;
    class?: string;
    /**
     * Indicates if the direction of the should be change during auto positioning, or if the position should just flip between left/right and top/bottom
     * @default:false
     */
    autoChangeDirection?: boolean;
    /**
     * Determines if the the popup should just be hidden or completly detached from the dom, when not visible. If 'hide' is selected the popup will be
     * @default: detach
     */
    hidingMode?: "hide" | "detach";
    /**
     * if true the popup will be shrinked so that is is completly inside the body element / all parent elements with overflow: hidden
     * @default true
     */
    shrinkToVisible?: FunctionOrValue<boolean>;
    /**
     * If true, alignment will not be changed to make the popup more visible
     * @default false
     */
    fixAlignment?: FunctionOrValue<boolean>;
    disablePositioning?: FunctionOrValue<boolean>;
}

export class Popup implements IControl<JQuery> {
    static readonly OpenPopups = new Array<Popup>();
    private static defaultOptions: IPopupOptions = {
        position: PopupPosition.OutsideBottom,
        horizontalAlignment: Alignment.Stretch,
        verticalAlignment: Alignment.Start,
        autoPosition: true,
        autoChangeDirection: false,
        hidingMode: "detach",
        shrinkToVisible: true
    };
    private static defaultContainer = document.body;
    private static readonly sidebarExtender: HTMLElement = document.getElementById("sidebar-extender");
    readonly $element: JQuery;
    readonly onOpening = new EventEmitter<ControlEvent<this>>("opening", () => this.eventElements());
    readonly onClosing = new EventEmitter<ControlEvent<this>>("closing", () => this.eventElements());
    readonly onOpened = new EventEmitter<ControlEvent<this>>("opened", () => this.eventElements());
    readonly onClosed = new EventEmitter<ControlEvent<this>>("closed", () => this.eventElements());
    readonly onRendered = new EventEmitter<RenderedEvent<this, JQuery>>("rendered", () => this.eventElements());
    readonly onRendering = new EventEmitter<ControlEvent<this>>("rendering", () => this.eventElements());
    readonly onAlignmentSwapping = new EventEmitter<ControlEvent<this>>("alignment-swapping",
                                                                        () => this.eventElements());
    readonly onRepositioned = new EventEmitter<ControlDataEvent<this, PopupPosition>>("repositioned",
                                                                                      this.eventElements);
    readonly onInit = new EventEmitter<ControlEvent<this>>("popup-init", () => this.eventElements());
    readonly options: IPopupOptions;
    currentPosition: PopupPosition;
    private _isOpen = false;
    private visibleSize: number;
    private currentAlignment: Alignment;
    private visualContainerPosition: PositionAndDimensions;
    private readonly cachedPositions = {};
    private lastPositioningTime = 0;
    private realHeight: string;
    private realWidth: string;

    constructor(private readonly content: ContainerType, options?: IPopupOptions) {
        this.options = ExtendOptions(Popup.defaultOptions, options);
        this.$element = $(`<div class="popup unpositioned"></div>`)
            .addClass(this.options.class)
            .css(this.options.css || {})
            .data("popup", this);
        if (this.options.hidingMode === "hide") {
            this.$element
                .hide()
                .appendTo(this.getContainer());
        }
        if (typeof this.content !== "function") {
            $(this.content)
                .appendTo(this.$element)
                .data("popup", this);
        }
        if (!this.options.visualContainer)
            this.options.visualContainer = this.options.container;
        this.onInit.fire(new ControlEvent(this), [this.$element]);
    }

    private _isRepositioning = false;

    private get isRepositioning() {
        return this._isRepositioning;
    }

    private set isRepositioning(value) {
        if (value === this._isRepositioning)
            return;
        this._isRepositioning = value;
        if (true || !Utils.UnwrapFunction(this.options.shrinkToVisible) || $(document.body).is(".ie, .edge, .ios"))
            return;
        if (value === false) {
            if (this.realHeight === null) {
                this.realHeight = this.$element[0].style.height;
                this.realWidth = this.$element[0].style.width;
                this.cutInvisibleBits();
            }
        } else {
            this.$element[0].style.height = this.realHeight;
            this.$element[0].style.width = this.realWidth;
            this.realHeight = null;
            this.realHeight = null;
            this.$element.removeClass("shrinked-to-visible");
        }
    }

    protected static calculateSidebarExtenderSize() {
        if (!this.sidebarExtender)
            return;
        const extenderHeight =
            this.OpenPopups
                .map(p =>
                         p.$element.position().top + p.$element.height() - document.body.clientHeight + 4)
                .sort()[0] || 0;
        this.sidebarExtender.style.height = Math.max(0, extenderHeight) + "px";
    }

    isOpen = () => this._isOpen;

    open = () => {
        if (!this.isOpen)
            return;
        if (this.onOpening.fire(new ControlEvent(this)).defaultPrevented)
            return;
        this.$element.removeClass("closed");
        if (this.options.hidingMode === "hide")
            this.$element.show();
        else
            this.$element.appendTo(this.getContainer());
        this._isOpen = true;
        this.render();
        $(window).on("resize-end scroll-end scrolling resizing", this.resizeHandler);
        this.onOpened.fire(new ControlEvent(this, false));
        checkScrollMargin();
        Popup.OpenPopups.push(this);
        Popup.calculateSidebarExtenderSize();
    };

    close = () => {
        if (!this._isOpen)
            return;
        if (this.onClosing.fire(new ControlEvent(this)).defaultPrevented)
            return;
        if (!this._isOpen)
            return;
        this._isOpen = false;
        $(window).off("resize-end scroll-end resizing scrolling", this.resizeHandler);
        if (this.options.hidingMode === "detach") {
            if (jQuery.contains(document.documentElement, this.$element[0]))
                this.$element.detach();
        } else
            this.$element.hide();
        this.$element.addClass("closed");
        this.currentPosition = null;
        this.onClosed.fire(new ControlEvent(this, false));
        checkScrollMargin();
        Popup.OpenPopups.remove(this);
        Popup.calculateSidebarExtenderSize();
    };

    toggle = () => {
        if (this._isOpen)
            this.close();
        else
            this.open();
    };

    render = () => {
        if (!this._isOpen)
            return;
        if (this.onRendering.fire(new ControlEvent(this)).defaultPrevented)
            return;
        if (typeof this.content === "function") {
            this.$element.empty();
            this.$element.append($(this.content()));
        }
        const previousPosition = this.currentPosition;
        this.reposition();
        this.onRendered.fire(new RenderedEvent(this, this.$element));
        if (this.currentPosition !== previousPosition)
            this.onRepositioned.fire(new ControlDataEvent(this, this.currentPosition));
    };

    resizeHandler = (e: JQueryEventObject, step: number, elapsedMilliseconds: number) => {
        if (this.isRepositioning)
            return;
        if (e.type === "scrolling" || e.type === "resizing") {
            if (elapsedMilliseconds % this.lastPositioningTime > 0)
                return;
        }
        const previousPosition = this.currentPosition;
        this.reposition();
        if (this.currentPosition !== previousPosition) {
            this.onRepositioned.fire(new ControlDataEvent(this, this.currentPosition));
            checkScrollMargin();
        }
    };

    resize = (position?: PopupPosition) => {
        if (position === undefined)
            position = this.currentPosition;
        if (position === undefined)
            return;
        const visualContainer = $(UnwrapContainerFunction(this.options.visualContainer))[0] as HTMLElement ||
                                Popup.defaultContainer;
        if (!visualContainer)
            throw "Visual container is undefined";
        const dimensions = visualContainer.getBoundingClientRect();
        if (UnwrapFunction(this.options.horizontalAlignment) === Alignment.Stretch) {
            if (UnwrapFunction(this.options.allowGrowing)) {
                this.$element.css({
                                      "min-width": dimensions.width,
                                      width: "auto"
                                  });
            } else {
                this.$element.css({
                                      "min-width": null,
                                      width: dimensions.width
                                  });
            }
        } else {
            if (this.options.css && this.options.css.width)
                this.$element.outerWidth(this.options.css.width);
            else
                this.$element.width("auto");
        }
        if (UnwrapFunction(this.options.verticalAlignment) === Alignment.Stretch) {
            if (UnwrapFunction(this.options.allowGrowing)) {
                this.$element.css({
                                      "min-height": dimensions.height,
                                      height: "auto"
                                  });
            } else {
                this.$element.css({
                                      "min-height": null,
                                      height: dimensions.height
                                  });
            }
        } else {
            if (this.options.css && this.options.css.height)
                this.$element.outerHeight(this.options.css.height);
            else
                this.$element.height("auto");
        }
        checkScrollMargin();
    };

    reposition = (force = false) => {
        if (!this._isOpen)
            return;
        if (UnwrapFunction(this.options.disablePositioning)) {
            this.$element.css({
                                  width: "",
                                  height: "",
                                  top: "",
                                  left: "",
                                  right: "",
                                  bottom: ""
                              });
            this.currentPosition = null;
            return;
        }
        const timeStart = performance.now();
        const visualContainer = $(UnwrapContainerFunction(this.options.visualContainer) || Popup.defaultContainer);
        if (!visualContainer.length)
            throw ("No visual container available for popup");
        let position = UnwrapFunction(this.options.position);
        const vcPos = visualContainer.positionAndDimensions(this.getContainer());
        if (!force
            && position === this.currentPosition
            && this.$element.isInViewport()
            && vcPos.equals(this.visualContainerPosition, false)) {
            if (vcPos.width !== this.visualContainerPosition.width
                || vcPos.height !== this.visualContainerPosition.height) {
                this.resize(position);
                this.visualContainerPosition = vcPos;
            }
            return;
        }
        this.isRepositioning = true;
        this.visualContainerPosition = vcPos;
        let horizontalAlignment = UnwrapFunction(this.options.horizontalAlignment);
        let verticalAlignment = UnwrapFunction(this.options.verticalAlignment);
        let cycle = UnwrapFunction(this.options.autoPosition)
                    ? PopupPosition.GetCycle(position)
                    : [position];
        if (!UnwrapFunction(this.options.autoChangeDirection))
            cycle = cycle.slice(0, 2);
        const mostVisible = {
            size: 0,
            position: null as PopupPosition,
            alignment: null as Alignment
        };
        const nearest = {
            cornersInDirection: 0,
            offset: null as number,
            position: null as PopupPosition,
            alignment: null as Alignment
        };
        for (let i = 0; i <= cycle.length; i++) {
            let currentPosition = cycle[i];

            //set position to the best one once all positions have been tried and the popup is not fully visible in either of them
            if (currentPosition === undefined) {
                if (mostVisible.size > 0) {
                    currentPosition = mostVisible.position;
                    verticalAlignment = horizontalAlignment = mostVisible.alignment;
                } else {
                    currentPosition = nearest.position;
                    verticalAlignment = horizontalAlignment = nearest.alignment;
                }
            }

            //Swap horizontal and vertical alignment if necessary
            if (i > 1
                && this.options.autoSwapAlignment
                && PopupPosition.IsTopOrBottom(currentPosition) !== PopupPosition.IsTopOrBottom(cycle[i - 1])) {
                if (!this.onAlignmentSwapping.fire(new ControlEvent(this)).defaultPrevented) {
                    let temp = horizontalAlignment;
                    horizontalAlignment = verticalAlignment;
                    verticalAlignment = temp;
                }
            }

            const currentAlignment = PopupPosition.IsTopOrBottom(currentPosition)
                                     ? horizontalAlignment
                                     : verticalAlignment;
            this.resize(currentPosition);
            this.$element.css({right: "", left: "", top: "", bottom: ""});
            this.setPosition(currentPosition, horizontalAlignment, verticalAlignment, vcPos);
            this.currentPosition = currentPosition;
            this.currentAlignment = currentAlignment;
            if (i === cycle.length)
                break;
            this.cutInvisibleBits();
            let relativePosition = this.$element.getRelativePosition();
            if (relativePosition.visibleSize === relativePosition.size)
                break;


            this.updateBestPosition(relativePosition,
                                    currentPosition,
                                    currentAlignment,
                                    mostVisible,
                                    nearest
            );

            if (Utils.UnwrapFunction(this.options.fixAlignment) ||
                !this.setAlignment(currentPosition, horizontalAlignment, verticalAlignment, vcPos, true))
                continue;
            relativePosition = this.$element.getRelativePosition();
            this.currentAlignment = currentAlignment;
            if (relativePosition.visibleSize === relativePosition.size)
                break;

            this.updateBestPosition(relativePosition,
                                    currentPosition,
                                    this.invertAlignment(currentAlignment),
                                    mostVisible,
                                    nearest
            );
        }
        this.lastPositioningTime = Math.ceil((performance.now() - timeStart) / 100) * 100;
        this.isRepositioning = false;
        Popup.calculateSidebarExtenderSize()
    };

    eventElements() {
        return [this.$element];
    };

    getContainer() {
        const container = $(Utils.UnwrapContainerFunction(this.options.container));
        return container.length ? container : Popup.defaultContainer;
    }

    cutInvisibleBits() {
        return;
        if (this.realHeight !== null) {
            this.$element[0].style.height = this.realHeight;
            this.$element[0].style.width = this.realWidth;
        }

        this.$element.css({maxHeight: null, maxWidth: null});

        let maxHeight = this.$element.height();
        let maxWidth = this.$element.width();

        let minHeight = 0;
        let minWidth = 0;

        const height = this.$element.height();
        const width = this.$element.width();

        this.$element.parents(":not(html)")
            .each((i, elem: HTMLElement) => {
                const $elem = $(elem);
                if (elem === document.documentElement)
                    return;
                const overflowY = $elem.css("overflow-y");
                const overflowX = $elem.css("overflow-x");
                if (overflowY === "hidden"
                    || overflowX === "hidden"
                    || elem === document.body) {
                    const positionToParent = this.$element.positionAndDimensions(elem);
                    if (overflowY === "hidden" || elem === document.body) {
                        if (String.isNullOrEmpty(this.$element[0].style.bottom) && positionToParent.bottom < 0)
                            maxHeight = Math.min(maxHeight, height + positionToParent.bottom);
                        else if (String.isNullOrEmpty(this.$element[0].style.top) && positionToParent.top < 0)
                            maxHeight = Math.min(maxHeight, height + positionToParent.top);
                    }

                    if (overflowX === "hidden" || elem === document.body) {
                        if (String.isNullOrEmpty(this.$element[0].style.left) && positionToParent.left < 0)
                            maxWidth = Math.min(maxWidth, width + positionToParent.left);
                        else if (String.isNullOrEmpty(this.$element[0].style.right) && positionToParent.right < 0)
                            maxWidth = Math.min(maxWidth, width + positionToParent.right);
                    }
                    //console.log({ elem, positionToParent, maxHeight, maxWidth });
                } else if ((overflowY === "scroll" || overflowY === "auto") && minHeight === 0
                           || (overflowX === "scroll" || overflowX === "auto") && minWidth === 0) {
                    //const positionToParent = this.$element.positionAndDimensions(elem, true);
                    const positionToParent = this.$element.positionAndDimensions(elem);
                    if ((overflowY === "scroll" || overflowY === "auto") && minHeight === 0) {
                        if (String.isNullOrEmpty(this.$element[0].style.bottom))
                            minHeight = height + positionToParent.bottom;
                        else if (String.isNullOrEmpty(this.$element[0].style.top))
                            minHeight = height + positionToParent.top;
                    }
                    if ((overflowX === "scroll" || overflowX === "auto") && minWidth === 0) {
                        if (String.isNullOrEmpty(this.$element[0].style.left))
                            minWidth = width + positionToParent.left;
                        else if (String.isNullOrEmpty(this.$element[0].style.right))
                            minWidth = width + positionToParent.right;
                    }
                }
            });

        //const parents = this.$element.parents();
        //const overflowYParent = parents.filter((i, elem) => $(elem).css("overflow-y") === "hidden").first()[0]
        //    || document.body;
        //const overflowXParent = parents.filter((i, elem) => $(elem).css("overflow-x") === "hidden").first()[0]
        //    || document.body;
        //const overflowYParentPosition: PositionAndDimensions = this.$element.positionAndDimensions(overflowYParent);
        //const overflowXParentPosition: PositionAndDimensions =
        //    overflowXParent === overflowYParent
        //        ? overflowYParentPosition
        //        : this.$element.positionAndDimensions(overflowXParent);
        //const position = {
        //    top: overflowYParentPosition.top - 5,
        //    bottom: overflowYParentPosition.bottom - 5,
        //    left: overflowXParentPosition.left - 5,
        //    right: overflowXParentPosition.right - 5,
        //}
        //if (String.isNullOrEmpty(this.$element[0].style.bottom) && position.bottom < 0)
        //    this.$element
        //        .addClass("shrinked-to-visible")
        //        .height(this.$element.height() + position.bottom);
        //else if (String.isNullOrEmpty(this.$element[0].style.top) && position.top < 0)
        //    this.$element
        //        .addClass("shrinked-to-visible")
        //        .height(this.$element.height() + position.top);

        //if (String.isNullOrEmpty(this.$element[0].style.left) && position.left < 0)
        //    this.$element
        //        .addClass("shrinked-to-visible")
        //        .width(this.$element.width() + position.left);
        //else if (String.isNullOrEmpty(this.$element[0].style.right) && position.right < 0)
        //    this.$element
        //        .addClass("shrinked-to-visible")
        //        .width(this.$element.width() + position.right);

        const newWidth = Math.max(minWidth, maxWidth);
        if (newWidth < width) {
            this.$element
                .addClass("shrinked-to-visible")
                .css("max-width", newWidth - 5);
            if (String.isNullOrEmpty(this.$element[0].style.right))
                this.$element[0].style.right = (parseInt(this.$element[0].style.right) + width - newWidth - 5) + "px";
        }
        const newHeight = Math.max(maxHeight, minHeight);
        if (newHeight < height) {
            this.$element
                .addClass("shrinked-to-visible")
                .css("max-height", newHeight - 5);
            if (String.isNullOrEmpty(this.$element[0].style.bottom))
                this.$element[0].style.bottom =
                    (parseInt(this.$element[0].style.bottom) + height - newHeight - 5) + "px";
        }
    }

    protected setPosition(currentPosition: PopupPosition,
                          horizontalAlignment: Alignment,
                          verticalAlignment: Alignment,
                          vcPos: PositionAndDimensions) {
        this.$element[0].classList.remove("unpositioned");
        const elementBoundings = this.$element[0].getBoundingClientRect();

        if (PopupPosition.IsInside(currentPosition)
            && horizontalAlignment === Alignment.Center
            && verticalAlignment === Alignment.Center) {
            this.$element.top(vcPos.top + (vcPos.height - elementBoundings.height) / 2);
            this.$element.left(vcPos.left + (vcPos.width - elementBoundings.width) / 2);
            return;
        }

        if (PopupPosition.IsInside(currentPosition)
            && horizontalAlignment === Alignment.Stretch
            && verticalAlignment === Alignment.Stretch) {
            this.$element.top(vcPos.top);
            this.$element.left(vcPos.left);
            return;
        }

        //set position of element
        if (PopupPosition.IsTopOrBottom(currentPosition)) {
            if (horizontalAlignment === Alignment.End)
                this.$element.right(vcPos.right);
            else if (horizontalAlignment === Alignment.Center)
                this.$element.left(vcPos.left + (vcPos.width - elementBoundings.width) / 2);
            else
                this.$element.left(vcPos.left);
        } else {
            if (verticalAlignment === Alignment.End)
                this.$element.bottom(vcPos.bottom);
            else if (verticalAlignment === Alignment.Center)
                this.$element.top(vcPos.top + (vcPos.height - elementBoundings.height) / 2);
            else
                this.$element.top(vcPos.top);
        }

        const vC = $(this.getContainer())[0] as HTMLElement;
        switch (currentPosition) {
            case PopupPosition.OutsideTop:
                let scrollTop = vC.scrollTop;
                //fix for Edge
                if (vC === document.body)
                    scrollTop = 0;
                this.$element.bottom(vcPos.bottom + vcPos.height - scrollTop);
                break;
            case PopupPosition.OutsideLeft:
                this.$element.right(vcPos.right + vcPos.width);
                break;
            case PopupPosition.OutsideBottom:
                this.$element.top(vcPos.top + vcPos.height);
                break;
            case PopupPosition.OutsideRight:
                this.$element.left(vcPos.left + vcPos.width - vC.scrollLeft);
                break;
            case PopupPosition.InsideTop:
                this.$element.top(vcPos.top);
                break;
            case PopupPosition.InsideLeft:
                this.$element.left(vcPos.left);
                break;
            case PopupPosition.InsideBottom:
                this.$element.bottom(vcPos.bottom);
                break;
            case PopupPosition.InsideRight:
                this.$element.right(vcPos.right);
                break;
        }

        this.$element.toggleClass("popup-top", PopupPosition.IsTop(currentPosition));
        this.$element.toggleClass("popup-bottom", PopupPosition.IsBottom(currentPosition));
        this.$element.toggleClass("popup-left", PopupPosition.IsLeft(currentPosition));
        this.$element.toggleClass("popup-right", PopupPosition.IsRight(currentPosition));
        this.$element.toggleClass("popup-inside", PopupPosition.IsInside(currentPosition));
        this.$element.toggleClass("popup-outside", PopupPosition.IsOutside(currentPosition));
    }

    protected setAlignment(currentPosition: PopupPosition,
                           horizontalAlignment: Alignment,
                           verticalAlignment: Alignment,
                           vcPos: PositionAndDimensions,
                           invert = false) {
        if (invert) {
            horizontalAlignment = this.invertAlignment(horizontalAlignment);
            verticalAlignment = this.invertAlignment(verticalAlignment);
        }
        if (PopupPosition.IsTopOrBottom(currentPosition)) {
            if (horizontalAlignment === Alignment.End) {
                this.$element.right(vcPos.right);
                this.$element.left("");
            } else if (horizontalAlignment === Alignment.Start) {
                this.$element.left(vcPos.left);
                this.$element.right("");
            } else
                return false;
        } else {
            if (verticalAlignment === Alignment.End) {
                this.$element.bottom(vcPos.bottom);
                this.$element.top("");
            } else if (verticalAlignment === Alignment.Start) {
                this.$element.top(vcPos.top);
                this.$element.bottom("");
            } else
                return false;
        }
        return true;
    }

    protected invertAlignment(alignment: Alignment) {
        if (alignment === Alignment.End)
            return Alignment.Start;
        if (alignment === Alignment.Start)
            return Alignment.End;
        if (alignment === Alignment.Stretch)
            return Alignment.End;
        return alignment;
    }

    protected updateBestPosition(relativePosition: RelativePosition,
                                 currentPosition: PopupPosition,
                                 currentAlignment: Alignment,
                                 mostVisible: { size: number, position: PopupPosition, alignment: Alignment },
                                 nearest: {
                                     cornersInDirection: number,
                                     offset: number,
                                     position: PopupPosition,
                                     alignment: Alignment;
                                 }): void {
        if (relativePosition.element === PositionState.InView) {
            if (relativePosition.visibleSize > mostVisible.size) {
                mostVisible.size = relativePosition.visibleSize;
                mostVisible.position = currentPosition;
                mostVisible.alignment = currentAlignment;
            }
        } else {
            if (relativePosition.numberOfCornersInDirectionOfElement > nearest.cornersInDirection
                || (relativePosition.numberOfCornersInDirectionOfElement === nearest.cornersInDirection
                    && relativePosition.offset < nearest.offset)) {
                nearest.position = currentPosition;
                nearest.alignment = currentAlignment;
                nearest.cornersInDirection = relativePosition.numberOfCornersInDirectionOfElement;
                nearest.offset = relativePosition.offset;
            }
        }
    }

}

declare global {
    interface JQuery {
        popup(options?: IPopupOptions): JQuery;

        popup(loadDataSettings: true): JQuery;
    }
}

jQuery.fn.popup = function (this: JQuery, options?: IPopupOptions | true) {
    return this.each((index, elem) => {
        const $elem = $(elem);
        if ($elem.data("popup"))
            return;
        if (options === true) {
            options = $elem.getJsonFromAttribute<IPopupOptions>("data-popup-settings")
                      || $elem.getJsonFromAttribute<IPopupOptions>("data-settings");
        }
        // ReSharper disable once WrongExpressionStatement
        new Popup(elem as HTMLElement, options);
    });
};
