<!--<div class="box accordion element-item" [ngClass]="{closed: openStateStoreAccessor.observe | async | not}">-->
<header>
  <button class="block accordion-toggle button"
          type="button"
          [ngClass]="{closed: openStateStoreAccessor?.observe | async | not}"
          (click)="toggle()">
    <span>{{title}}</span>
  </button>
</header>
<div class="clip">
  <div class="accordion-content" [ngClass]="contentClasses">
    <ng-content *ngIf="!isempty"></ng-content>
    <p *ngIf="isempty" class="content-empty">No information available</p>
  </div>
</div>
<!--</div>-->
