/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import {ViewData} from "./view-data";
import {BookingStep} from "@Enums";
import {normalizeId} from "@utils";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class BookingViewData extends ViewData {
  readonly templateId: string | null;
  readonly step: BookingStep;

  constructor(step: BookingStep);
  constructor(step: BookingStep, templateId: string);
  constructor(step: BookingStep, templateId: string | null = null) {
    super();
    this.templateId = normalizeId(templateId);
    this.step = step;
  }

  isTemplate() {
    return this.templateId != null;
  }

  hasNewInformationComparedTo(compare: this): boolean {
    const stepChanged = this.step !== compare.step;
    const templateIdAddedOrChanged = this.templateId !== compare.templateId;
    return stepChanged || templateIdAddedOrChanged;
  }

  get pageTitle(): string {
    return TitleBuilder.booking;
  }

  get url(): string {
    if (this.isTemplate())
      return UrlBuilder.booking.bokkingWithTemplate(this.templateId!)
    switch (this.step) {
      case "Overview":
        return UrlBuilder.booking.overview;
      case "Service":
        return UrlBuilder.booking.service;
      case "Parties":
        return UrlBuilder.booking.parties;
      case "Goods":
        return UrlBuilder.booking.goods;
      case "AdditionalInformation":
        return UrlBuilder.booking.additionalInfo;
      case "Submit":
        return UrlBuilder.booking.submit;

    }
  }

  readonly bodyClasses = ["page-shipment-booking", "page-booking"];

}
