import {ApplicationRef, Directive, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Provider} from "@angular/core";
import {ISelectOptions, Select} from "@widgets/Select";
import {CountryService} from "@services/country.service";
import {NgSelectionChangedEvent} from "./select.directive";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {arrayify} from "@utils/Utils";
import {ISelectListItem, SelectionChangedEvent} from "@widgets/SelectList";
import {ContentComponent} from "@utils/Angular/ContentComponent";

const NUMBER_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CountrySelectDirective),
  multi: true
};

@Directive({
             selector: '[rtCountrySelect]',
             providers: [NUMBER_VALUE_ACCESSOR],
             exportAs: "select",
             host: {
               "(change)": "onChange()"
             }
           })
export class CountrySelectDirective extends ContentComponent implements ControlValueAccessor, OnInit {

  select: Select;
  @Output()
  selectionChanged = new EventEmitter<NgSelectionChangedEvent>();
  @Output()
  closed = new EventEmitter<NgSelectionChangedEvent>();
  @Output()
  initialized = new EventEmitter<Select>();

  private readonly settings: Partial<ISelectOptions> = {
    multiple: true,
    placeholderNone: 'Any',
    search: true,
    displayIcon: true,
    class: 'flags'
  }
  private _isWritingValue = false;
  private _onChange?: () => void;
  private _onTouched?: Action1<string[] | string>;

  private readonly _countryService: CountryService;

  constructor(element: ElementRef, app: ApplicationRef, countryService: CountryService) {
    super(element, app);
    this._countryService = countryService;
    if (!this.element.nextElementSibling || !this.element.nextElementSibling.classList.contains("fake-select")) {
      $(`<div class="fake-select custom-select" tabindex="0"></div>`)
        .insertAfter(this.element);
    }
    this.element.style.display = "none";
  }

  private _disabled: boolean;

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    this.setDisabledState(value);
  }

  @Input()
  set placeholderNone(value: string) {
    this.settings.placeholderNone = value;
  }

  @Input()
  set single(value: boolean) {
    this.settings.multiple = !value;
  }

  // private _items: Partial<JSONData>[];

  writeValue(obj: string | string[]): void {
    if (!this.select)
      return;
    this._isWritingValue = true;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const selectedValues = arrayify(obj);
    if (this._valueHasNotChanged(selectedValues)) {
      this._isWritingValue = false;
      return;
    }
    this.select.unselectItems(true, true);
    for (const item of this.select.getItems()) {
      if (selectedValues.contains(item.value)) {
        this.select.selectItems(item, true, true);
        selectedValues.remove(item.value);
      }
    }
    if (selectedValues.any())
      this.select.processJsonData(selectedValues.map(i => {
        return {value: i, label: i, selected: true}
      }))
    this._isWritingValue = false;
  }

  registerOnChange(fn: Action1<string[] | string>): void {
    this._onChange = () => {
      if (this._isWritingValue)
        return;
      if (this.select.options.multiple)
        fn(this.select.getSelectedItems().map(i => i.value));
      else if (this.select.getSelectedItems().length)
        fn(this.select.getSelectedItems()[0].value);
      else
        fn(null);
    };
  }

  registerOnTouched(fn: Action1<string[] | string>): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (!this.select)
      return
    if (isDisabled)
      this.select.disable(true);
    else
      this.select.enable();
  }

  ngOnDestroy(): void {
    this.select?.destroyUi();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    const $select = $(this.element);
    this.select = new Select($select[0] as HTMLSelectElement, this.settings);
    this.select.processJsonData(this._countryService.getJsonData());
    this.select.onSelectionChanged.on(e => {
      this._selectionChanged(e)
    });
    if (this._disabled)
      this.select.disable(true);
  }

  onInitialized(): void {
    if (!this.select.$fakeSelect[0].contains(this.select.selectElement))
      this.select.$fakeSelect.insertAfter(this.select.selectElement);
    if (this.settings?.onInitialized)
      this.settings.onInitialized(this.select);
    this.initialized.emit(this.select);
  }

  onChange(): void {
    if (this._onChange)
      this._onChange();
  }

  private _selectionChanged(e: SelectionChangedEvent<ISelectListItem, Select>) {
    const ngEvent: NgSelectionChangedEvent = {
      addedItems: e.addedItems,
      removedItems: e.removedItems,
      selection: this.select.getSelectedItems()
    };
    this.selectionChanged.emit(ngEvent);
  }

  private _valueHasNotChanged(newSelectedValues: string[]) {
    const currentSelectedValues = this.select.getSelectedItems()
    for (const currentSelectedValue of currentSelectedValues) {
      if (!newSelectedValues.contains(currentSelectedValue.value))
        return false;
    }
    return currentSelectedValues.length === newSelectedValues.filter(v => !!v).distinct().length;
  }
}
