import {Injectable} from '@angular/core';
import {EntityInfo, EntitySetInfo, JobType, Shipment, ShipmentListViewMode, ShipmentSearch, ShipmentSearchReferenceType} from "@api";
import {ShipmentDetailViewData, ShipmentListViewData} from "@view-data";
import {AccountStore, RoutingStore, ShipmentStore, ShipmentStoreState, UserRightsStore} from "@stores";
import {debounceTime, distinctUntilKeyChanged, filter, skip} from "rxjs/operators";
import {hasValue, TypeFilter} from "@utils/rxjs-extensions";
import {ShipmentApi} from "../api/Apis";
import {ShipmentSearchUiStore} from "@app/page-components/shipment/shipment-list/shipment-search-ui-store.service";
import {RoutingService} from "@app/services/routing.service";
import {ShipmentTypeUrlParamPipe} from "@app/value-pipes/string-value.pipe";
import {combineLatest} from "rxjs";

@Injectable({
              providedIn: 'root'
            })
export class ShipmentService {
  readonly shipmentStore: ShipmentStore;
  private readonly _shipmentApi: ShipmentApi;
  private readonly _routingStore: RoutingStore;

  private readonly _routingService: RoutingService;

  constructor(shipmentApi: ShipmentApi,
              shipmentStore: ShipmentStore,
              routingService: RoutingService,
              userRightsStore: UserRightsStore,
              shipmentSearchUiStore: ShipmentSearchUiStore,
              accountStore: AccountStore) {
    const routingStore = routingService.routingStore;
    this._routingService = routingService;
    this._routingStore = routingStore;
    this.shipmentStore = shipmentStore;
    this._shipmentApi = shipmentApi;
    accountStore
      .subscribe("currentShipmentSearch",
                 search => {
                   shipmentSearchUiStore.reset();
                   if (search)
                     shipmentSearchUiStore.update(search);
                   shipmentStore.update("currentSearch", search || {});
                 });
    accountStore
      .observe("currentEntitySet")
      .pipe(
        filter(hasValue),
        distinctUntilKeyChanged("id")
      )
      .subscribe(s => void this._entitySetChanged(s))
    routingStore
      .observe("currentViewData")
      .pipe(filter(TypeFilter(ShipmentDetailViewData)))
      .subscribe((v: ShipmentDetailViewData) => {
        void this._loadShipment(v)
      })
    combineLatest(
      routingStore
        .observe("currentViewData")
        .pipe(filter(TypeFilter(ShipmentListViewData))),
      accountStore.observe("shipmentListViewMode")
    )
      .pipe(filter(([_, viewMode]) => viewMode === ShipmentListViewMode.Shipments))
      .subscribe(([viewData, _]) => {
        void this._loadShipmentList(viewData.redoSearch, viewData.query)
      })

    shipmentStore
      .observe("currentSearch")
      .pipe(
        skip(1),
        filter(() => userRightsStore.get("isAuthenticated")),
        debounceTime(100)
      )
      .subscribe(s => {
        shipmentSearchUiStore.update(s);
        if (routingStore.get("currentViewData") instanceof ShipmentListViewData && accountStore.get("shipmentListViewMode") === ShipmentListViewMode.Shipments)
          void this._search(s)
        else
          this.shipmentStore.update("shipmentList", null)
      });
    userRightsStore
      .observe("isAuthenticated")
      .pipe(filter(u => u === false))
      .subscribe(() => this.shipmentStore.reset());
    accountStore
      .observe("shipmentListViewMode")
      .pipe(filter(m => m === ShipmentListViewMode.Shipments))
      .subscribe(() => {
        shipmentSearchUiStore.reset();
        shipmentSearchUiStore.update(shipmentStore.get("currentSearch"));
      });
  }

  hasUserAccess(id: string, type: JobType, entitySetId: string): Promise<boolean>;
  hasUserAccess(id: string, type: JobType): Promise<boolean>;
  async hasUserAccess(id: string, type: JobType, entitySetId?: string): Promise<boolean> {
    const currentShipmentList = this.shipmentStore.get("shipmentList");
    if (currentShipmentList?.any(s => s.id === id && s.jobType === type))
      return true;
    if (entitySetId)
      return await this._shipmentApi
                       .hasUserAccessForEntitySet(id, ShipmentTypeUrlParamPipe.transform(type), entitySetId)
                       .toPromise()
    else
      return await this._shipmentApi
                       .hasUserAccess(id, ShipmentTypeUrlParamPipe.transform(type))
                       .toPromise();
  }

  async loadMore(): Promise<void> {
    try {
      this.shipmentStore.update({isLoadingMore: true});
      const result = (await this._shipmentApi.loadMoreShipments().toPromise());
      this.shipmentStore.update((state: Partial<ShipmentStoreState>) => {
        return {
          shipmentList: (state.shipmentList || []).concat(result.shipments),
          moreShipmentsAvailable: result.moreShipmentsAvailable
        }
      })
    } finally {
      this.shipmentStore.update({isLoadingMore: false});
    }
  }

  get(shipmentId: string, jobType: JobType): Promise<Shipment | null> {
    return this._shipmentApi.shipmentDetail(shipmentId, jobType, true).toPromise();
  }

  startSearch(searchObject: Partial<ShipmentSearch>, force = true): void {
    this.shipmentStore.update("currentSearch", searchObject, force);
  }

  rerunSearch(): void {
    void this._search(this.shipmentStore.get("currentSearch"));
  }

  async reloadCurrentShipment(): Promise<void> {
    const shipment = this.shipmentStore.get("currentShipment");
    if (!shipment)
      return;
    const update =
      await this.get(shipment.id, shipment.jobType);
    this.shipmentStore.update(u => {
      const shipmentList = u.shipmentList;
      if (shipmentList) {
        const index = shipmentList.findIndex(s => s.id === update.id);
        if (index >= 0)
          shipmentList[index] = update;
      }
      return {currentShipment: update, shipmentList};
    })
  }

  private async _search(search: ShipmentSearch): Promise<Shipment[] | null> {
    const {
      bookedByRohlig,
      bookedByUserIds,
      containerMode,
      dateType,
      destinationCountryCodes,
      endDate,
      originCountryCodes,
      portsOfDischargeUnLocodes,
      portsOfLoadingUnLocodes,
      reference,
      searchReferenceType,
      startDate,
      status,
      transportMode
    } = search;
    try {
      this.shipmentStore.update({isSearching: true});
      const result = await this._shipmentApi
                               .searchShipments(reference,
                                                searchReferenceType,
                                                transportMode,
                                                containerMode,
                                                status,
                                                originCountryCodes,
                                                destinationCountryCodes,
                                                portsOfLoadingUnLocodes,
                                                portsOfDischargeUnLocodes,
                                                startDate,
                                                endDate,
                                                dateType,
                                                bookedByUserIds,
                                                bookedByRohlig)
                               .toPromise();
      this.shipmentStore.update({
                                  shipmentList: result.shipments,
                                  moreShipmentsAvailable: result.moreShipmentsAvailable
                                });
      if (result.shipments.length === 1 && (this._routingService.getCurrentViewData() as ShipmentListViewData).autoOenSingleItem) {
        const shipment = result.shipments[0];
        this._routingService.navigateTo(new ShipmentDetailViewData(shipment.id, shipment.jobType));
      }
      return result.shipments;
    } finally {
      this.shipmentStore.update(u => {
        return {
          isSearching: false
        }
      });
    }
  }

  private async _loadShipment(viewData: ShipmentDetailViewData) {
    const shipmentFromStore = this._getShipmentFromStore(viewData);
    if (shipmentFromStore)
      this._updateCurrentShipmentInStore(shipmentFromStore);
    else {
      this.shipmentStore.update("currentShipment", null);
      const shipment =
        await this.get(viewData.shipmentId, viewData.jobType);
      this._updateCurrentShipmentInStore(shipment);
    }
  }


  private _updateCurrentShipmentInStore(shipmentFromStore: Shipment): void {
    this.shipmentStore.update({currentShipment: shipmentFromStore});
  }

  private _getShipmentFromStore(viewData: ShipmentDetailViewData): Shipment {
    const currentShipments = this.shipmentStore.get("shipmentList") ?? [];
    return currentShipments
      .firstOrDefault((s: Shipment) => s.id === viewData.shipmentId && s.jobType === viewData.jobType);
  }

  private _loadShipmentList(force: boolean, query: string) {
    if (!force && (this.shipmentStore.hasValue("shipmentList"))) {
      this._routingService.navigateTo(new ShipmentListViewData(), true)
      return;
    }
    const search =
      query
      ? {searchReferenceType: ShipmentSearchReferenceType.Any, reference: query}
      : this.shipmentStore.get("currentSearch");
    this.startSearch(search)
  }

  private async _entitySetChanged(entitySet: EntitySetInfo) {
    if (!this._hasShipmentRights(entitySet)) {
      this.shipmentStore.reset();
      return;
    }
    const currentViewData = this._routingStore.get("currentViewData");
    if (currentViewData instanceof ShipmentListViewData) {
      this.shipmentStore.update("shipmentList", [])
      await this._search(this.shipmentStore.get("currentSearch"));
    } else {
      this.shipmentStore.update("shipmentList", null)
      let currentShipment: Shipment | null = null;
      if (currentViewData instanceof ShipmentDetailViewData) {
        const id = currentViewData.shipmentId;
        const jobType = currentViewData.jobType;
        const userCanStillViewShipment = await this.hasUserAccess(id, jobType, entitySet.id);
        if (userCanStillViewShipment)
          currentShipment = await this._shipmentApi.shipmentDetail(id, jobType, true).toPromise();
        else
          this._routingService.navigateTo(new ShipmentListViewData())
      }
      this.shipmentStore.update({currentShipment})
    }
    this.shipmentStore.update({firstShipmentInEntitySet:null})
    const firstShipmentInEntitySet = await this._shipmentApi.getFirstShipmentResult().toPromise()
    this.shipmentStore.update({firstShipmentInEntitySet})
  }

  private _hasShipmentRights(entity: EntityInfo): boolean {
    return entity.hasShipmentRights ||
           entity.subSites && entity.subSites.any(s => this._hasShipmentRights(s));
  }

  private _getFilledSearchParams() {
    const filledParameters: (keyof ShipmentSearch)[] = [];
    const search = this.shipmentStore.get("currentSearch");
    const arrays: (KeysOfType<ShipmentSearch, Array<unknown>>)[] = [
      "bookedByUserIds",
      "containerMode",
      "destinationCountryCodes",
      "originCountryCodes",
      "portsOfDischargeUnLocodes",
      "portsOfLoadingUnLocodes",
      "status",
      "transportMode"];
    filledParameters.pushRange(arrays.filter(a => search[a]?.any() === true));
    const values: (keyof ShipmentSearch)[] = [
      "bookedByRohlig",
      "endDate",
      "reference",
      "startDate"
    ]
    filledParameters.pushRange(values.filter(v => !!search[v]));
    return filledParameters;
  }
}

