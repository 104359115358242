<div class="page-shipment-list">
  <div class="page-wrap">
    <main>
      <section class="padding">
        <div style="display: none;">
          <rt-shipment-list-box
            *ngIf="viewCustomizerShipment$ | async as shipment" [shipment]="shipment"
            id="viewCustomizerShipmentDummy"></rt-shipment-list-box>
          <rt-container-box
            *ngIf="viewCustomizerContainer$ | async as container" [container]="container"
            id="viewCustomizerContainerDummy"></rt-container-box>
        </div>
        <div class="button-container search-bar">
          <span class="title">Shipments</span>
          <rt-shipment-reference-search-field></rt-shipment-reference-search-field>
          <div class="button-group grow space-below">
            <div class="button secondary fake-select filters-toggle"
                 [ngClass]="{'active': filtersOpen.observe | async}"
                 (click)="toggleFiltersOpenState()"
                 [attr.data-filters-count]="(filterCount$ | async ) || null ">
              <span>Filters</span>
              <span *ngIf="filterCount$ | async as filterCount" class="filter-count" [attr.data-filters-count]="filterCount"></span>
            </div>
            <select id="select-filter-preset"
                    data-filter-preset-type="shipment"
                    class="button secondary select-button"
                    [rtFilterPresets]="'shipment'">
            </select>
          </div>

          <div class="button-group" >
            <select class="button secondary select-button"
                    ngSelect
                    [settings]="viewModeSelectSettings"
                    [storeAccessor]="viewModeAccessor"
                    [items]="viewModeSelectItems">
            </select>

            <select class="button secondary select-button"
                    ngSelect
                    [storeAccessor]="currentLayoutAccessor"
                    [settings]="layoutModeSelectSettings"
                    [items]="layoutModeSelectItems"
                    #viewSelect="select">
            </select>
          </div>

          <div class="button-group phone-block" style="flex-grow: 1; justify-content: flex-end;">
            <span class="double-button" *ngIf="hasExportToExcelRights$ | async">
              <button id="b-export-to-excel"
                      class="button configure-button secondary"
                      [disabled]="canStartNewExcelReport$ | async | not"
                      (click)="startNewExcelExport()">
                Export to Excel
              </button>
            </span>

            <span class="double-button" *ngIf="hasBookingRights$ | async">
              <a routerLink="/booking" class="primary button" id="booking-button"><span class="mat-icon-add"></span>New Booking</a>
              <span class="button disable-when-offline dropdown-toggle" id="booking-template-toggle" tabindex="0">
                <select id="booking-template-select"
                        data-display-name="Templates"
                        multiple="multiple"
                        style="display: none;"
                        rtBookingTemplateSelect>
                </select>
              </span>
            </span>
          </div>
        </div>
        <div class="shipment-list"
             [ngClass]="{
             'shipment-line-view': isLineMode$ | async,
             'shipment-box-view': isBoxMode$ | async
             }">

          <rt-shipment-search-bar></rt-shipment-search-bar>
          <ng-container *ngIf="(hasShipments$ | async) || (isSearching$ | async)">
            <ng-container *ngIf="(listViewMode$ | async) === shipmentListViewMode.Shipments">
              <rt-shipment-list-line-view *ngIf="isLineMode$ | async" [skeleton-mode]="isSearching$ | async"
                                          [ngClass]="{scrolling: isLineScrollMode$ | async}" rtScrollBarOverlay></rt-shipment-list-line-view>
              <rt-shipment-list-box-view *ngIf="isBoxMode$ | async" [skeleton-mode]="isSearching$ | async">
              </rt-shipment-list-box-view>
            </ng-container>
            <ng-container *ngIf="(listViewMode$ | async) === shipmentListViewMode.Containers">
              <rt-container-list-view *ngIf="isLineMode$ | async" [skeleton-mode]="isSearching$ | async"
                                      [ngClass]="{scrolling: isLineScrollMode$ | async}" rtScrollBarOverlay></rt-container-list-view>
              <rt-container-box-view *ngIf="isBoxMode$ | async">
              </rt-container-box-view>
            </ng-container>
          </ng-container>

          <p *ngIf="noResultsFound$ | async" class="no-results-found">No shipments found</p>

          <p class="load-more" *ngIf="moreItemsAvailable$ | async" [ngClass]="{loading: isLoadingMore$ | async}">
            <button class="button"
                    (click)="loadMore()"
                    *ngIf="moreItemsAvailable$ | async"
                    [disabled]="isLoadingMore$ | async">Load more
            </button>
          </p>
        </div>
      </section>
    </main>
  </div>
</div>
<div style="display: none;">
  <fieldset id="filterPresetsControls">
    <legend>Save Preset</legend>
    <label for="droplist-filterpreset-name">Preset Name</label>
    <p class="input-group input-search">
      <input id="droplist-filterpreset-name" class="save-filter-preset" type="text">
      <button class="button mat-icon-save text-hide save-filter-preset"></button>
    </p>
  </fieldset>
</div>
