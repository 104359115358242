import {Component, HostListener, OnDestroy} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AccountStore} from "@app/stores/account-store.service";
import {StoreDebugHelper} from "@stores/store-debug-helper";

@Component({
  selector: 'rt-user-box',
  templateUrl: './user-box.component.html',
  styleUrls: ['./user-box.component.scss']
})
export class UserBoxComponent implements OnDestroy{

  readonly currentSiteName$: Observable<string>;
  readonly userName$: Observable<string>;
  readonly profilePictureBase64$: Observable<string>;
  private readonly _accountStore: AccountStore;
  private _debugCounterId: number;

  constructor(accountStore: AccountStore) {
    this._accountStore = accountStore;

    this.currentSiteName$ = accountStore
      .observe("currentEntitySet")
      .pipe(map(s => s?.name || ""));
    this.userName$ = accountStore.observe("name");
    this.profilePictureBase64$ = accountStore.observe("profilePictureBase64");
  }

  @HostListener("mousedown")
  @HostListener("touchstart")
  private _startDebugTimer() {
    this._debugCounterId = window.setTimeout(() => StoreDebugHelper.download(), 5000);
  }

  ngOnDestroy(): void {
    this._stopDebugTimer()
  }


  @HostListener("mouseup")
  @HostListener("mouseleave")
  @HostListener("touchend")
  @HostListener("touchleave")
  private _stopDebugTimer() {
    window.clearTimeout(this._debugCounterId)
  }
}
