<!--suppress TypeScriptValidateTypes -->
<rt-accordion *ngFor="let entitySet of (entitySetPermissions | orderBy:'name')"
              [animated]="true"
              [title]="entitySet.name">
  <dl class="padding">
    <dt>
      Export to Excel
      <span class="{{entitySet.exportToExcel | pureFunction:getRightClass}}">&nbsp;</span>
    </dt>
    <dt>
      Documents
      <span class="{{entitySet.documents | pureFunction:getRightClass}}">&nbsp;</span>
    </dt>
    <dt>
      Products
      <span class="{{entitySet.products | pureFunction:getRightClass}}">&nbsp;</span>
    </dt>
    <dt>
      Exceptions
      <span class="{{entitySet.exceptions | pureFunction:getRightClass}}">&nbsp;</span>
    </dt>
    <dt *ngIf="loggedInAsSuperUser$ | async">
      Messaging
      <span class="{{entitySet.messaging | pureFunction:getRightClass}}">&nbsp;</span>
    </dt>
  </dl>
  <table class="table-default table-border table-separated desktop">
    <thead>
    <tr>
      <th class="center">&nbsp;</th>
      <th class="center">Shipments</th>
      <th class="center">Orders</th>
      <th class="center">Invoice Documents</th>
      <th class="center">Booking</th>
      <th class="wide">&nbsp;</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let entity of entitySet | pureFunction:getEntitiesWithPadding">
      <td [ngStyle]="{'padding-left.px': entity.padding}">{{entity.name | unescapeHtml}}</td>
      <td class="center"><span class="{{entity.shipments | pureFunction:getRightClass}}">&nbsp;</span></td>
      <td class="center"><span class="{{entity.orders | pureFunction:getRightClass}}">&nbsp;</span></td>
      <td class="center"><span class="{{entity.invoiceDocuments | pureFunction:getRightClass}}">&nbsp;</span></td>
      <td class="center"><span class="{{entity.bookings | pureFunction:getRightClass}}">&nbsp;</span></td>
      <td></td>
    </tr>
    </tbody>
  </table>
  <table class="table-default table-border table-separated mobile">
    <thead>
    <tr>
      <th>Shipment</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let entity of entitySet | pureFunction:getRightOfEntities:'shipments'">
      <td [ngStyle]="{'padding-left.px': entity.padding}">{{entity.entityName | unescapeHtml}}</td>
      <td class="center"><span class="{{entity.hasRight | pureFunction:getRightClass}}">&nbsp;</span></td>
    </tr>
    </tbody>
  </table>
  <table class="table-default table-border table-separated mobile">
    <thead>
    <tr>
      <th>Order</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let entity of entitySet | pureFunction:getRightOfEntities:'orders'">
      <td [ngStyle]="{'padding-left.px': entity.padding}">{{entity.entityName | unescapeHtml}}</td>
      <td class="center"><span class="{{entity.hasRight | pureFunction:getRightClass}}">&nbsp;</span></td>
    </tr>
    </tbody>
  </table>
  <table class="table-default table-border table-separated mobile">
    <thead>
    <tr>
      <th>Invoice Documents</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let entity of entitySet | pureFunction:getRightOfEntities:'invoiceDocuments'">
      <td [ngStyle]="{'padding-left.px': entity.padding}">{{entity.entityName | unescapeHtml}}</td>
      <td class="center"><span class="{{entity.hasRight | pureFunction:getRightClass}}">&nbsp;</span></td>
    </tr>
    </tbody>
  </table>
  <table class="table-default table-border table-separated mobile">
    <thead>
    <tr>
      <th>Booking</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let entity of entitySet | pureFunction:getRightOfEntities:'bookings'">
      <td [ngStyle]="{'padding-left.px': entity.padding}">{{entity.entityName | unescapeHtml}}</td>
      <td class="center"><span class="{{entity.hasRight | pureFunction:getRightClass}}">&nbsp;</span></td>
    </tr>
    </tbody>
  </table>
</rt-accordion>
