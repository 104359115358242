import { Injectable } from '@angular/core';
import {JSONData, WebMethodClient} from "@api";
import {WebMethodApi} from "../../api/Apis";

@Injectable({
  providedIn: 'root'
})
export class UnlocodeSearchService {

  private readonly _cache: IStringIndexable<JSONData> = {};

  private readonly _webMethodApi: WebMethodApi;

  constructor(webMethodApi: WebMethodApi) {
    this._webMethodApi = webMethodApi;
  }

  async getUnlocodeItems(unlocodes: string[]) {
    const nonCachedUnlocodes = unlocodes.filter(c => !this._cache[c]);
    if (nonCachedUnlocodes.any())
      await this._loadUnlocodesFromServer(nonCachedUnlocodes);
    return unlocodes
      .map(c => this._cache[c])
      .filter(c => !!c)
  }

  private async _loadUnlocodesFromServer(unlocodes: string[]) {
    const result: JSONData[] =
      await this._webMethodApi.getPortsByUnlocodes(unlocodes).toPromise();
    for (const jsonDatum of result) {
      this._cache[jsonDatum.value] = jsonDatum;
    }
  }
}
