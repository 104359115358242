import {Injectable} from "@angular/core";
import {
    HttpEvent,
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpResponseBase,
    HttpSentEvent,
    HttpUserEvent
} from "@angular/common/http";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class ForwardingInterceptor implements HttpInterceptor {
    private readonly _router: Router;

    constructor(router: Router) {
        this._router = router;
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next
            .handle(request)
            .pipe(map(r => this._handleReponse(r)));
    }

    private _handleReponse(response: HttpSentEvent | HttpHeaderResponse | HttpResponse<any> | HttpProgressEvent | HttpUserEvent<any>) {
        if (response instanceof HttpResponseBase && response.headers.has("location"))
            this._router.navigateByUrl(response.headers.get("location"));
        return response;
    }
}
