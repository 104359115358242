<div class="page-add-user" rtFormValidator>
  <div class="page-wrap">
    <main>
      <section class="padding">
        <div class="search-bar button-container">
            <div class="button-group static">
              <a class="button back-button secondary phone-borderless" routerLink="/Account/Users"><span class="mat-icon-back"></span></a>
            </div>
            <ng-container *ngIf="userLoaded">
              <span class="title desktop-visible" *ngIf="isNewUser$ | async">Add User</span>
              <span class="title desktop-visible" *ngIf="isNewUser$ | async | not">{{userName$ | async | unescapeHtml}}</span>
            </ng-container>
        </div>
        <div class="user-permissions" *ngIf="userLoaded">
          <div class="user-details wysiwyg">
            <h2>User Details</h2>
            <p>
                <span class="form-label">
                    <label for="user-type">User Type</label>
                </span>
              <input type="text" id="user-type" disabled [value]="userType$ | async | pureFunction:getUserTypeName"/>
            </p>
            <p class="select-user-group" *ngIf="isRohligUser$ | async">
            <span class="form-label">
                <label for="user-group">User Group</label>
            </span>
              <select
                id="user-group"
                ngSelect
                required
                *ngIf="canAssignUserGroup$ | async; else cannotAssignGroup"
                [storeAccessor]="storeAccessors.group"
                [settings]="selectSettings.groups"
                [items]="groupItems$ | async">
              </select>

              <ng-template #cannotAssignGroup>
                <input [value]="storeAccessors.group.observe | async | rtUserGroup" disabled/>
              </ng-template>
            </p>
            <p class="select-admin-country" [@slideInOut]="(groupCountrySelectAvailable$ | async) ? 'visible' : 'hidden'">
            <span class="form-label">
              <label for="group-country">Admin Country</label>
            </span>
              <select
                id="group-country"
                multiple="multiple"
                required
                data-display-name="Country"
                rtCountrySelect
                [disabled]="groupCountrySelectAvailable$ | async | not"
                [attr.disabled]="(groupCountrySelectAvailable$ | async) ? null : 'disabled'"
                *ngIf="canAssignUserGroup$ | async; else cannotAssignGroupCounty"
                [storeAccessor]="storeAccessors.adminCountryCodes"
                placeholderNone="">
              </select>
              <ng-template #cannotAssignGroupCounty>
                <input [value]="storeAccessors.adminCountryCodes.observe | async | pureFunction:getCountryNamesFromCodes | rtStringArray" disabled/>
              </ng-template>
            </p>
            <p>
        <span class="form-label">
            <label for="first-name">First Name</label>
        </span>
              <input type="text"
                     id="first-name"
                     required
                     maxlength="100"
                     ngDefaultControl
                     [storeAccessor]="storeAccessors.firstName"
                     [disabled]="canEditNameAndMail$ | async | not"
              />
            </p>
            <p>
        <span class="form-label">
            <label for="last-name">Last Name</label>
        </span>
              <input type="text"
                     id="last-name"
                     maxlength="100"
                     required
                     ngDefaultControl
                     [storeAccessor]="storeAccessors.lastName"
                     [disabled]="canEditNameAndMail$ | async | not"/>
            </p>
            <p>
        <span class="form-label">
            <label for="country">Country</label>
        </span>
              <select id="country"
                      rtCountrySelect
                      required
                      placeholderNone=""
                      [single]="true"
                      [storeAccessor]="storeAccessors.countryCode">
              </select>
            </p>
            <p>
            <span class="form-label">
                    <label for="email">Email</label>
                </span>
              <input
                type="email"
                id="email"
                [storeAccessor]="storeAccessors.mailAddress"
                required
                ngDefaultControl
                data-validate-ascii-chars-mail
                data-validate-unique-mail-message="The user already exists."
                [attr.data-original-val]="originalMailAddress$ | async |unescapeHtml"
                data-validate-unique-mail="/WebMethod/IsUserExists"
                [disabled]="canEditNameAndMail$ | async | not"/>
            </p>
            <p>
            <span class="form-label">
              <label for="telephone" class="optional"> Telephone </label>
              </span>
              <rt-phone-number-select
                id="telephone"
                [storeAccessor]="storeAccessors.phone"
                [fallbackCountryCode]="storeAccessors.countryCode.observe | async"
              ></rt-phone-number-select>
            </p>
            <p>
              <span class="form-label">
              <label>
              <input type="checkbox"
                     id="inactive"
                     name="user-inactive"
                     [storeAccessor]="storeAccessors.deactivated"
                     [disabled]="cannotActivateUser$ | async"/>
            <span class="mat-icon-done"> </span>
              Deactivated
              </label>
              </span>
              <span class="form-label" *ngIf="userIsLocked$ | async">
              <label>
              <input type="checkbox"
                     id="locked"
                     name="user-locked"
                     [storeAccessor]="storeAccessors.locked"/>
            <span class="mat-icon-done"> </span>
              Locked <ng-container *ngIf="userLockedDate$ | async as lockDate">({{lockDate | rtDateTime}} UTC)</ng-container>
              </label>
            </span>

            </p>
            <h2 class="optional"> Profile Picture </h2>
            <rt-avatar-editor [storeAccessor]="storeAccessors.profilePictureBase64"></rt-avatar-editor>
            <ng-container *ngIf="isNewUser$ | async">
              <h2> Actions </h2>
              <p>
                        <span class="form-label">
                          <label>
                          <input type="checkbox" [(ngModel)]="sendWelcomeMail">
                        <span class="mat-icon-done"> </span>
                          Send Welcome Mail
                        </label>
                        </span>
              </p>
              <p>
                        <span class="form-label">
                          <label>
                          <input type="checkbox" [(ngModel)]="sendPasswordMail">
                        <span class="mat-icon-done"> </span>
                          Send Password Mail
                        </label>
                        </span>
              </p>
            </ng-container>

            <div class="buttons-wrapper">
              <p class="buttons" rtSticky [static]="true">
                <button class="button" [rtLoadingAnimation]="isSaving" loadingText="saving" (click)="save()">Save</button>
                <a class="button secondary" href="javascript: history.back();">Cancel</a>
              </p>
            </div>
          </div>
          <p></p>
          <div class="entities wysiwyg">
            <h2> Entities </h2>
            <p></p>
            <div class="custom-select select-item-container">
              <label for="entity-select" style="line-height: 1.75em;"> Entity Set </label>
              <select
                id="entity-select"
                rtPopupSelect
                rtInputValidator
                customErrorMessage="Entity Set is required."
                [customValidator]="validateEntitySets"
                [settings]="entitySelectOptions"
                (selectionChanged)="entitySelectionChanged($event)"
                [items]="entitySelectItems$ | async"
                [ngModel]="selectedEntityIds$ | async"
              ></select>
            </div>
            <small class="text-light">Search by Entity Name, Organization Code or Address Code </small>
            <p></p>
            <div id="entity-list" class="entities-list">
              <rt-user-entity-set-permission-edit
                *ngFor="let entitySetPermissions of entitySetPermissions; trackBy: trackById"
                (deleteRequested)="removeEntitySet(entitySetPermissions)"
                [entitySetPermissions]="entitySetPermissions"></rt-user-entity-set-permission-edit>
            </div>
          </div>
        </div>
        <rt-entity-info-sidebar rtSticky></rt-entity-info-sidebar>
      </section>
    </main>
  </div>
</div>
