<div class="shipment-box-container" *ngIf="listLayout$ | async; let listLayout">
  <div class="list-view-spacer"></div>
  <div class="ajax-target" [ngClass]="hideCellsClass$ | async" #table>
    <div class="shipment-box-line header">
      <div
        *ngFor="let item of listLayout"
        [ngClass]="item | pureFunction:getSize"
        [innerHtml]="item | pureFunction:getColumnTitle"></div>
      <div class="filler"></div>
    </div>
    <ng-container *ngIf="!skeletonMode; else skeletonView">
      <a *ngFor="let item of (items$ | async); trackBy: trackById"
         class="shipment-box-line no-focus-highlight"
         [routerLink]="(item | rtIsContainerOverviewItem) ? '/Shipment/ContainerDetail' : '/Shipment/ShipmentDetail'"
         [queryParams]="item | pureFunction:getQueryParams"
         rt-Initialize-Child-Popovers
         [innerHTML]="item | pureFunction:renderItem:listLayout | safeHtml">
      </a>
    </ng-container>
    <ng-template #skeletonView>
      <div *ngFor="let item of [].constructor(skeletonItemCount);"
           class="shipment-box-line">
        <div *ngFor="let item of listLayout" [ngClass]="(item | pureFunction:getSize) || ''" class="skeletonWidth">
          <span class="shimmer-content" style="width:50%"></span>
        </div>
        <div class="filler"></div>
      </div>
    </ng-template>
  </div>
</div>
