import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Observable} from "rxjs";
import {ShipmentCardLayoutMapper, ShipmentBoxFields} from "@app/page-components/shipment/shipment-list/shipment-box-fields";
import {ShipmentOverviewItem} from "@app/page-components/shipment/shipment-list/shipment-overview-item";
import {trackById} from "@app/utils/track-by-id";
import {FieldRenderer} from "../field-renderer.service";
import {AccountStore, ShipmentStore} from "@stores";
import {map} from "rxjs/operators";

@Component({
             selector: 'rt-shipment-list-box-view',
             templateUrl: './shipment-list-box-view.component.html',
             styleUrls: ['./shipment-list-box-view.component.scss'],
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class ShipmentListBoxViewComponent {
  @Input("skeleton-mode")
  skeletonMode: boolean = false;

  @Input("skeleton-item-count")
  skeletonItemCount: number = 24;

  readonly shipments$: Observable<ShipmentOverviewItem[]>;
  readonly boxLayout$: Observable<ShipmentCardLayoutMapper[]>;
  readonly renderer: FieldRenderer;
  readonly trackById = trackById;
  private readonly _accountStore: AccountStore;
  private readonly _shipmentStore: ShipmentStore;

  constructor(shipmentStore: ShipmentStore, accountStore: AccountStore, renderer: FieldRenderer) {
    this._shipmentStore = shipmentStore;
    this.renderer = renderer;
    this._accountStore = accountStore;
    this.shipments$ = shipmentStore.observe("shipmentOverviewList");
    this.boxLayout$ = accountStore
      .observe("shipmentCardLayout")
      .pipe(map(l => l.map(item => ShipmentBoxFields[item])));
  }
}
