import {Component, ViewChild} from '@angular/core';
import {ChangePasswordCompleteViewData, ChangePasswordViewData, LoginViewData} from "@view-data";
import {AccountService} from "@services";
import {filter, takeUntil} from "rxjs/operators";
import {TypeFilter} from "@utils/rxjs-extensions";
import {ChangePasswordFormDirective} from "@directives";
import {RoutingStore} from "@stores";
import {AppBaseComponent} from "../../../appBaseComponent";
import {RoutingService} from "@app/services/routing.service";

@Component({
             selector: 'rt-change-password',
             templateUrl: './change-password.component.html',
             styleUrls: ['./change-password.component.scss']
           })
export class ChangePasswordComponent extends AppBaseComponent {
  @ViewChild(ChangePasswordFormDirective)
  changePasswordFormDirective: ChangePasswordFormDirective;
  isChangingPassword: boolean;
  showUnknownErrorField = false;
  requestId: string;
  private readonly _accountService: AccountService;

  private readonly _routingService: RoutingService;

  constructor(routingStore: RoutingStore, accountService: AccountService, routingService: RoutingService) {
    super();
    this._routingService = routingService;
    this._accountService = accountService;
    routingStore
      .observe("currentViewData")
      .pipe(
        takeUntil(this.destroyed$),
        filter(TypeFilter(ChangePasswordViewData))
      )
      .subscribe(d => {
        this.requestId = d.requestId;
      });
  }

  async resetPassword(): Promise<void> {
    if (!await this.changePasswordFormDirective.validate())
      return;
    this.isChangingPassword = true;
    await this._accountService.resetPassword(this.changePasswordFormDirective.requestId, this.changePasswordFormDirective.newPassword)
    this._routingService.navigateTo(new ChangePasswordCompleteViewData())
  }

  passwordResetFailed(): void {
    this.isChangingPassword = false;
    this.showUnknownErrorField = true;
  }
}
