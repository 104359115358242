import { CommonModule } from "@angular/common";
import { Injector, ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';

import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule, JsonpInterceptor } from "@angular/common/http";
import {
  AccessDeniedComponent,
  ChangePasswordComponent,
  ForgotPasswordComponent,
  LoginComponent,
  NotFoundComponent,
  OfflineComponent,
  RequestAccountComponent,
  SettingsComponent,
  UnexpectedErrorComponent,
} from '@app/page-components';
import { environment } from "../environments/environment";
import {
  ChatBubbleComponent,
  ErrorMasterComponent,
  LoginMasterComponent,
  MainMasterComponent,
  NavMenuComponent,
  TopBarComponent,
  UserBoxComponent,
  UserMenuComponent
} from "@app/master-components";
import {ChangePasswordFormDirective, FormValidatorDirective, InputValidatorDirective, LoadingAnimationDirective} from "@directives";
import {Site} from "../Site";
import {BodyClassService} from "@services/body-class.service";
import {TitleSetterService} from "@services/title-setter.service";
import {AntiForgeryTokenInterceptor, EntityIDInterceptor, ForwardingInterceptor, NoCacheInterceptor, ShowLoadingInterceptor, WebServiceErrorInterceptor} from "@app/interceptors";
import {API_BASE_URL} from "@api";
import {RtCommonModule} from "@app/rt-common.module";
import {FormSubmitService} from "@services/form-submit.service";
import {ChatService} from "@services/chat.service";
import {ActivationService} from "@booking/ActivationService";
import {OnlineMonitorComponent} from './page-components/administration/online-monitor/online-monitor.component';
import {NoUserRightsComponent} from './page-components/no-user-rights/no-user-rights.component';
import {ShipmentListComponent} from './page-components/shipment/shipment-list/shipment-list.component';
import {OverviewFieldComponent} from './page-components/shipment/shipment-list/overview-field/overview-field.component';
import {DangerousGoodsListComponent} from '@components/dangerous-goods-list/dangerous-goods-list.component';
import {StoreConnectorService} from "@stores";
import {RoutingService} from "@app/services/routing.service";
import {StoreAccessorDirective} from "@app/utils/store-accessor.directive";
import {StoreDebugHelper} from "@stores/store-debug-helper";
import {DropdownDirective} from './utils/dropdown.directive';
import {CalendarDirective} from '@components/calendar/calendar.directive';
import {ShipmentListBoxViewComponent} from './page-components/shipment/shipment-list/shipment-list-box-view/shipment-list-box-view.component';
import {ShipmentListLineViewComponent} from './page-components/shipment/shipment-list/shipment-list-list-view/shipment-list-line-view.component';
import {AddressNamePipe, DangerousGoodsTitlePipe} from './value-pipes/string-value.pipe';
import {ScrollBarOverlayDirective} from './utils/scroll-bar-overlay.directive';
import {ViewCustomizerDirective} from './legacyControlDirectives/view-customizer.directive';
import {CollapsibleBoxComponent} from '@components/collapsible-box/collapsible-box.component';
import {ShipmentDetailComponent} from './page-components/shipment/shipment-detail/shipment-detail.component';
import {ShipmentPackageComponent} from './page-components/shipment/shipment-detail/shipment-package/shipment-package.component';
import {ShipmentConsolidationComponent} from './page-components/shipment/shipment-detail/shipment-consolidation/shipment-consolidation.component';
import {ConsolidationLinkComponent} from './page-components/shipment/shipment-detail/shipment-consolidation/consolidation-item-link/consolidation-link.component';
import {BookingModule} from "@booking/app.module";
import {ShipmentGoodsComponent} from './page-components/shipment/shipment-detail/shipment-goods/shipment-goods.component';
import {ShipmentInformationComponent} from './page-components/shipment/shipment-detail/shipment-information/shipment-information.component';
import {ShipmentDocumentsComponent} from './page-components/shipment/shipment-detail/shipment-documents/shipment-documents.component';
import {DocumentLinkComponent} from './page-components/shipment/shipment-detail/shipment-documents/document-link/document-link.component';
import {ShipmentListTimelineComponent} from './page-components/shipment/shipment-list/shipment-list-timeline/shipment-list-timeline.component';
import {ShipmentMapComponent} from './page-components/shipment/shipment-detail/shipment-map/shipment-map.component';
import {OrderListComponent} from './page-components/order/order-list/order-list.component';
import {OrderSearchBarComponent} from './page-components/order/order-list/order-search-bar/order-search-bar.component';
import {OrderDetailsComponent} from './page-components/order/order-details/order-details.component';
import {OderLineCollapserDirective} from './page-components/order/order-details/oder-line-collapser.directive';
import {FilterPresetsDirective} from './utils/filter-presets.directive';
import {ReferenceLinkComponent} from '@components/reference-link/reference-link.component';
import {BookingTemplateSelectDirective} from './page-components/shipment/shipment-list/booking-template-select.directive';
import {GoogleMapsModule} from "@angular/google-maps";
import {PhoneNumberSelectComponent} from '@components/phone-number-select/phone-number-select.component';
import {MyProfileComponent} from "@app/page-components/my-profile/my-profile.component";
import {UserEntityPermissionViewComponent} from "@components/user-entity-permission-view/user-entity-permission-view.component";
import {AvatarEditorComponent} from '@components/avatar-editor/avatar-editor.component';
import {UserListComponent} from './page-components/account/user-list/user-list.component';
import {UserSearchBarComponent} from './page-components/account/user-list/user-search-bar/user-search-bar.component';
import {UserDetailComponent} from './page-components/account/user-detail/user-detail.component';
import {StickyDirective} from '@components/sticky.directive';
import {UserEditComponent} from './page-components/account/user-edit/user-edit.component';
import {UserEntityPermissionEditComponent} from "@app/page-components/account/user-edit/user-entity-permission-edit/user-entity-permission-edit.component";
import {UserEntitySetPermissionEditComponent} from "@app/page-components/account/user-edit/user-entity-set-permission-edit/user-entity-set-permission-edit.component";
import {SkeletonBoxComponent} from '@components/skeleton-box/skeleton-box.component';
import {EntityInfoSidebarComponent} from '@components/entity-info-sidebar/entity-info-sidebar.component';
import {EntityListComponent} from './page-components/Entities/entity-list/entity-list.component';
import {EntityEditComponent} from './page-components/Entities/entity-edit/entity-edit.component';
import {EntitySetComponent} from './page-components/Entities/entity-list/entity-set/entity-set.component';
import {BookingMailReceiverListComponent} from './page-components/Entities/entity-edit/booking-mail-receiver-list/booking-mail-receiver-list.component';
import {HistorySidebarComponent} from "@components/history-sidebar/history-sidebar.component";
import { ChangePasswordCompleteComponent } from './page-components/account/change-password-complete/change-password-complete.component';
import { HtmlContainerComponent } from '@components/html-container/html-container.component';
import {ShipmentSearchFiltersComponent} from "@app/page-components/shipment/shipment-list/shipment-search-filters/shipment-search-filters.component";
import { ShipmentReferenceSearchFieldComponent } from './page-components/shipment/shipment-list/shipment-reference-search-field/shipment-reference-search-field.component';
import { ContainerListViewComponent } from './page-components/shipment/shipment-list/container-list-view/container-list-view.component';
import { ContainerBoxViewComponent } from './page-components/shipment/shipment-list/container-box-view/container-box-view.component';
import { ContainerDetailComponent } from './page-components/shipment/container-details/container-detail.component';
import { ShipmentListBoxComponent } from './page-components/shipment/shipment-list/shipment-list-box-view/shipment-list-box/shipment-list-box.component';
import { ContainerBoxComponent } from './page-components/shipment/shipment-list/container-box-view/container-box/container-box.component';

const antiForgeryTokenInterceptor = new AntiForgeryTokenInterceptor();

const providers: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ShowLoadingInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useValue: antiForgeryTokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: EntityIDInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: WebServiceErrorInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ForwardingInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JsonpInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: NoCacheInterceptor,
    multi: true
  },
  {
    provide: Site
  },
  {
    provide: API_BASE_URL,
    useValue: ""
  },
  // {
  //   provide: RouteReuseStrategy,
  //   useClass: RouteReuseCacheStrategy
  // },
  {
    provide: AntiForgeryTokenInterceptor,
    useValue: antiForgeryTokenInterceptor,
  },
  // RouteReuseCacheStrategy,
  ActivationService
];

const declarations: Array<Type<unknown> | unknown[]> = [
  AppComponent,
  [//Master Commponents
    MainMasterComponent,
    LoginMasterComponent,
    NavMenuComponent,
    UserBoxComponent,
    TopBarComponent,
    UserMenuComponent,
    ErrorMasterComponent,
    ChatBubbleComponent
  ],
  [//Login Commponents
    ChangePasswordComponent,
    ForgotPasswordComponent,
    RequestAccountComponent,
    LoginComponent
  ],
  [ //Error Commponents
    NotFoundComponent,
    AccessDeniedComponent,
    OfflineComponent,
    UnexpectedErrorComponent
  ],
  [
    InputValidatorDirective,
    ChangePasswordFormDirective,
    LoadingAnimationDirective
  ],
  [
    ShipmentListComponent,
    SettingsComponent,
  ]
];

// eslint-disable-next-line @typescript-eslint/ban-types
const imports: Array<Type<unknown> | ModuleWithProviders<{}> | unknown[]> = [
  CommonModule,
  HttpClientModule,
  RtCommonModule,
  BookingModule,
  HttpClientJsonpModule
];

@NgModule({
              declarations: [
                  declarations,
                  FormValidatorDirective,
                  StoreAccessorDirective,
                  OnlineMonitorComponent,
                  NoUserRightsComponent,
                  ShipmentListComponent,
                  OverviewFieldComponent,
                  DangerousGoodsListComponent,
                  ShipmentSearchFiltersComponent,
                  DropdownDirective,
                  CalendarDirective,
                  ShipmentListBoxViewComponent,
                  ShipmentListLineViewComponent,
                  ShipmentConsolidationComponent,
                  ConsolidationLinkComponent,
                  DangerousGoodsTitlePipe,
                  ScrollBarOverlayDirective,
                  ViewCustomizerDirective,
                  CollapsibleBoxComponent,
                  ShipmentDetailComponent,
                  ShipmentPackageComponent,
                  ShipmentGoodsComponent,
                  ShipmentInformationComponent,
                  ShipmentDocumentsComponent,
                  DocumentLinkComponent,
                  ShipmentListTimelineComponent,
                  ShipmentMapComponent,
                  OrderListComponent,
                  OrderSearchBarComponent,
                  OrderDetailsComponent,
                  OderLineCollapserDirective,
                  FilterPresetsDirective,
                  ReferenceLinkComponent,
                  BookingTemplateSelectDirective,
                  MyProfileComponent,
                  PhoneNumberSelectComponent,
                  UserEntityPermissionViewComponent,
                  AvatarEditorComponent,
                  UserListComponent,
                  UserSearchBarComponent,
                  UserDetailComponent,
                  StickyDirective,
                  UserEditComponent,
                  UserEntityPermissionEditComponent,
                  UserEntitySetPermissionEditComponent,
                  SkeletonBoxComponent,
                  EntityInfoSidebarComponent,
                  EntityListComponent,
                  EntityEditComponent,
                  EntitySetComponent,
                  BookingMailReceiverListComponent,
                  HistorySidebarComponent,
                  ChangePasswordCompleteComponent,
                  HtmlContainerComponent,
                  ShipmentReferenceSearchFieldComponent,
                  ContainerListViewComponent,
                  ContainerBoxViewComponent,
                  AddressNamePipe,
                  ContainerDetailComponent,
                  ShipmentListBoxComponent,
                  ContainerBoxComponent
              ],
            imports: [
              imports,
              GoogleMapsModule
            ],
            providers: providers,
            bootstrap: [AppComponent]
          })
export class AppModule {
  constructor(routingService: RoutingService,
    bodyClassService: BodyClassService,
    titleSetterService: TitleSetterService,
    formSubmitService: FormSubmitService,
    chatService: ChatService,
    storeConnectorService: StoreConnectorService,
    injector: Injector) {
    console.log(StoreDebugHelper)
    window.ngInjector = injector;
    if (navigator.serviceWorker) {
      navigator.serviceWorker.register("/service-worker.js");
    }
    if (!environment.production) {
      console.log({ providers, declarations, imports })
    }
  }
}
