export class Tabs {

    readonly $tabList: JQuery;
    readonly $tabHeaders: JQuery;
    readonly $tabContents: JQuery;
    readonly $element: JQuery;

    constructor($element: JQuery) {

        if ($element.data("tabs"))
            throw "Tabs already initialized...";

        const self = this;
        this.$element = $element.data("tabs", this);
        this.$tabList = $element.find("li");
        this.$tabHeaders = $element.find("dt");
        this.$tabContents = $element.find("dd");

        this.goToTab(0);

        $element.find("ul button, dt button")
                .on("click", function (this: HTMLElement, event) {
                    event.preventDefault();
                    self.goToHandler($(this));
                });
    }

    goToTab(id: number) {
        this.$tabList.removeClass("active");
        this.$tabHeaders.removeClass("active");

        if (typeof id === "number") {
            this.$tabList.eq(id).addClass("active");
            this.$tabHeaders.eq(id).addClass("active");
        } else {
            this.$element
                .find(`[data-tab="${id}"]`)
                .parent()
                .addClass("active");
        }
    }

    goToHandler(current: JQuery) {
        if (typeof current.data("tab") !== "undefined") {
            this.goToTab(current.data("tab"));
        } else {
            this.goToTab(current.parent().index());
        }
    }
}

declare global {
    interface JQuery {
        data(key: "tabs"): Tabs;
        data(key: "tabs", value: Tabs): JQuery;
        tabs(): JQuery;
    }
}

jQuery.fn.tabs = function (this: JQuery) {
    return this.each((index, elem) => {
        const $elem = $(elem);
        if ($elem.data("tabs"))
            return;
        new Tabs($elem);
    });
};