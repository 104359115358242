<span class="material-icons icon" [ngClass]="location.type | rtTimelineLocationTypeCssClass"></span>
<ng-container *ngIf="location.type === locationTypes.Airport; else notAirLeg">
  <span [outerHTML]="location.port | rtPortValue:'IATA':'last'"></span>
</ng-container>
<ng-template #notAirLeg>
  <ng-container *ngIf="location.type === locationTypes.Pickup || location.type === locationTypes.Delivery; else unlocoPort">
    <span [outerHTML]="location.port | rtPortValue:'none':'last'"></span>
  </ng-container>
  <ng-template #unlocoPort>
    <span [outerHTML]="location.port | rtPortValue:'UNLOCO':'last'"></span>
  </ng-template>
</ng-template>
