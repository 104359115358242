import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {
  Container,
  ContainerGoods,
  IContainerViewItem,
  Shipment,
  ShipmentContainer,
  ShipmentContainerBase,
  TimelineLeg,
  TimelineLegType,
  TimelineLocation,
  TimeLineLocationType,
  TransportMode
} from "@api";
import {UserRightsStore} from "@stores";
import {Observable} from "rxjs";
import {IsContainerGroup, RtDatePipe} from "@app/utils/rt-date.pipe";
import {createTimelineUiData, PopoverTitle} from "@app/page-components/shipment/shipment-timeline-popover-converter";
import {ContainerService} from "@services/container.service";

@Component({
             selector: 'rt-vertical-timeline',
             templateUrl: './vertical-timeline.component.html',
             styleUrls: ['./vertical-timeline.component.scss'],
             host: {
               class: "timeline timeline-vertical"
             },
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class VerticalTimelineComponent {

  @Input()
  shipment: IContainerViewItem;

  @Input()
  set shipmentLegs(value: TimelineLeg[]) {
    this._shipmentLegs = value;
  }

  get shipmentLegs() {
    return this._shipmentLegs ?? this.shipment.timelineLegs;
  }


  readonly userHasCanViewExceptions$: Observable<boolean>;
  readonly transportModes = TransportMode;
  readonly legTypes = TimelineLegType;
  readonly createTimelineUiData = createTimelineUiData;
  private _shipmentLegs: TimelineLeg[];
  private _datePipe: RtDatePipe;

  constructor(userRightsStore: UserRightsStore, datePipe: RtDatePipe) {
    this._datePipe = datePipe;
    this.userHasCanViewExceptions$ = userRightsStore.observe("exceptions");
  }

  private static _unwrapContainerBases(containerBases: ShipmentContainerBase[]) {
    const containers: ShipmentContainer[] = [];
    for (const containerBase of containerBases) {
      if (IsContainerGroup(containerBase))
        containers.pushRange(containerBase.containers);
      else
        containers.push(containerBase)
    }
    return containers;
  }

  getContainerPickupDates = (containerBases: ShipmentContainerBase[]): ContainerDates => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const dates = this._getContainerDates(containerBases, c => c.actualPickup, c => c.estimatedPickup);
    const completedCount = dates.count(c => !!c.actPick);
    if (!completedCount)
      return null;
    return {
      type: "Pickup",
      containers: dates,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      completedCount: completedCount,
      count: dates.length
    }
  };

  getContainerDeliveryDates = (containerBases: ShipmentContainerBase[]): ContainerDates => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const dates = this._getContainerDates(containerBases, c => c.actualDelivery, c => c.estimatedDelivery);
    const completedCount = dates.count(c => !!c.actDelv);
    if (!completedCount)
      return null;
    return {
      type: "Delivery",
      containers: dates,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      completedCount: completedCount,
      count: dates.length
    }
  };

  getContainers(iContainerViewItem: Container | Shipment): ShipmentContainerBase[] {
    return ContainerService.isContainer(iContainerViewItem)
           ? []
           : iContainerViewItem.goods.containers;
  }

  private _getContainerDates(containerBases: ShipmentContainerBase[]
    , actual: Func1<ShipmentContainer, Date>
    , estimated: Func1<ShipmentContainer, Date>) {
    const containers = VerticalTimelineComponent._unwrapContainerBases(containerBases);
    const dates: ContainerDate[] = [];
    containers
      .filter(actual)
      .orderBy((c: ShipmentContainer) => actual(c).getTime())
      .forEach(c => {
        containers.remove(c);
        dates.push(this._convertContainerToDateObject(c))
      });
    containers
      .filter(estimated)
      .orderBy((c: ShipmentContainer) => estimated(c).getTime())
      .forEach(c => {
        containers.remove(c);
        dates.push(this._convertContainerToDateObject(c))
      });
    containers
      .forEach(c => {
        containers.remove(c);
        dates.push(this._convertContainerToDateObject(c))
      });
    return dates;
  }

  private _convertContainerToDateObject(container: ShipmentContainer): ContainerDate {
    return {
      actDelv: this._datePipe.transform(container.actualDelivery),
      estDelv: this._datePipe.transform(container.estimatedDelivery),
      actPick: this._datePipe.transform(container.actualPickup),
      estPick: this._datePipe.transform(container.estimatedPickup),
      containerNumber: container.containerNumber
    };
  }

}

export interface TimelineDateUiData {
  scheduled?: Date;
  estimated?: Date;
  actual?: Date;
  displayDate: Date;
  displayDateIsEstimated: boolean;
  warningLabelClass: null | "short-delay" | "delay" | "ahead";
  displayDateLabel: string;
  popupInfoText: string;
  startOrEnd: "start" | "end";
  popoverTitle: PopoverTitle;
  locationTypeCssClass: string;
  locationName: string;
}

export interface ContainerDates {
  type: "Pickup" | "Delivery";
  containers: ContainerDate[]
  completedCount: number;
  count: number;
}

export interface ContainerDate {
  containerNumber: string;
  estPick: string;
  actPick: string;
  estDelv: string;
  actDelv: string;
}
