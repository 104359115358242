<div class="page-wrap">
  <main>
    <section class="padding">
      <ng-container *ngIf="shipment$ | async as shipment; else skeletonView">
        <div class="summary">
          <div class="static">
            <a routerLink="/Shipment" class="button back-button secondary"><span class="mat-icon-back"></span></a>
          </div>
          <div>
            <header>
              <p>
                <ng-container *ngIf="shipment | rtIsAirShipment; else billOdLading">Airway Bill</ng-container>
                <ng-template #billOdLading>Bill of Lading</ng-template>
              </p>
            </header>
            <footer>
              <p>{{shipment.houseBill}}</p>
            </footer>
          </div>
          <div>
            <header>
              <p>Transport Mode</p>
            </header>
            <footer>
              <p *ngIf="shipment.transportModes">{{shipment.transportModes.join(" / ")}}</p>
              <p>
                <span *ngIf="shipment.containerMode" class="label grey">{{shipment.containerMode}}</span>{{' '}}
                <span *ngIf="shipment.jobType === JobType.CustomsDeclaration" class="label grey">Customs Clearance Only</span>{{' '}}
                <span *ngIf="shipment.serviceLevel && (shipment | rtIsAirShipment)"
                      class="label service-level"
                      [ngClass]="[shipment.serviceLevel.toLowerCase()]">{{shipment.serviceLevel}}</span>
              </p>
            </footer>
          </div>
          <div>
            <header>
              <p>Incoterm</p>
            </header>
            <footer>
              <p>{{shipment.incoterm | rtString}}</p>
            </footer>
          </div>
          <div>
            <header>
              <p>Status</p>
            </header>
            <footer>
              <p [class]="shipment | rtDeliveryStatusTextColorClass">{{shipment.status | rtDeliveryStatus}}</p>
            </footer>

          </div>
          <div class="shrink">
            <header>
              <p>Last Event</p>
            </header>
            <footer style="flex-direction: column;">
              <ng-container *ngIf="shipment.events.last() as lastEvent; else noEvent">
                <p>{{lastEvent.description}}</p>
                <p>
                  <small class="text-light">{{lastEvent.actualDate | rtDate}}</small>
                </p>
              </ng-container>
              <ng-template #noEvent>
                {{'' | rtString }}
              </ng-template>
            </footer>
          </div>
          <div class="static"
               *ngIf="shipment$ | async | pureFunction:getShipmentIfCanCancelBooking as shipment">
            <button class="button secondary"
                    *ngIf="shipment.status !== deliveryStatuses.Canceled"
                    (click)="openCancelModal(shipment)"
            >Cancel Booking
            </button>
            <button
              class="button secondary"
              disabled
              title="Please contact your Röhlig representative."
              *ngIf="shipment.status === deliveryStatuses.Canceled"
            >Cancel Booking
            </button>
          </div>
        </div>
        <div #grid class="boxes grid grid-padding">
          <div class="grid-gutter" #gridGutter></div>

          <!-- ================ TIME LINE ================ -->
          <rt-collapsible-box [openStateStoreAccessor]="boxStateAccessors.timeline"
                              [title]="'Timeline'"
                              [isempty]="shipment.timelineLegs.length === 0">
            <div class="padding">
              <rt-vertical-timeline [shipment]="shipment"></rt-vertical-timeline>
            </div>
          </rt-collapsible-box>


          <!-- =========== SHIPMENT INFORMATION ========== -->
          <rt-collapsible-box [isempty]="false"
                              [openStateStoreAccessor]="boxStateAccessors.shipmentInformation"
                              [title]="'Shipment Information'">

            <div class="padding">
              <table class="table-clean">
                <tbody>
                  <tr>
                    <th>
                      <ng-container *ngIf="shipment | rtIsAirShipment">Master Airway Bill</ng-container>
                      <ng-container *ngIf="shipment | rtIsAirShipment | not">Master Bill of Lading</ng-container>
                    </th>
                    <td>
                      {{shipment.masterBill | rtTbd}}
                    </td>
                  </tr>
                  <tr>
                    <th>Röhlig References</th>
                    <td>
                      {{shipment.rohligReferences | rtStringArray | rtTbd}}
                    </td>
                  </tr>
                  <tr *ngIf="shipment | pureFunction:shouldShowCustomsEntry">
                    <th>Customs Entry Number</th>
                    <td>
                      {{shipment.customsEntryNumbers | rtStringArray}}
                    </td>
                  </tr>
                  <tr>
                    <th>Origin</th>
                    <td class="shipment-information-location"
                        [innerHTML]="shipment.origin | rtCityValue | rtTbd">
                    </td>
                  </tr>
                  <tr>
                    <th>Destination</th>
                    <td class="shipment-information-location"
                        [innerHTML]="shipment.destination | rtCityValue | rtTbd">
                    </td>
                  </tr>
                  <ng-container *ngIf="shipment | pureFunction:shouldShowPorts">
                    <tr>
                      <th>Port of Loading</th>
                      <td [innerHTML]="shipment.portOfLoading | rtPortValue:shipment">
                      </td>
                    </tr>
                    <tr>
                      <th>Port of Discharge</th>
                      <td [innerHTML]="shipment.portOfDischarge | rtPortValue:shipment">
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <rt-shipment-consolidation [shipment]="shipment"></rt-shipment-consolidation>
            </div>
          </rt-collapsible-box>

          <!-- ================ PARTIES ================ -->
          <rt-collapsible-box [title]="'Parties'"
                              [openStateStoreAccessor]="boxStateAccessors.parties"
                              [isempty]="shipment | pureFunction:hasNoParties">
            <div class="padding">
              <div class="shipment-information">
                <address>
                  <ng-container *ngIf="shipment.shipperLocation">
                    <p class="text-light">Shipper</p>
                    <rt-html-container [html]="shipment.shipperLocation | rtAddress"></rt-html-container>
                  </ng-container>
                </address>
                <address>
                  <ng-container *ngIf="shipment.consigneeLocation">
                    <p class="text-light">Consignee</p>
                    <rt-html-container [html]="shipment.consigneeLocation | rtAddress"></rt-html-container>
                  </ng-container>
                </address>
                <address>
                  <ng-container *ngIf="shipment.pickupLocation">
                    <p class="text-light">Pickup Location</p>
                    <rt-html-container [html]="shipment.pickupLocation | rtAddress"></rt-html-container>
                  </ng-container>
                </address>
                <address>
                  <ng-container *ngIf="shipment.deliveryLocation">
                    <p class="text-light">Delivery Location</p>
                    <rt-html-container [html]="shipment.deliveryLocation | rtAddress"></rt-html-container>
                  </ng-container>
                </address>
              </div>
            </div>
          </rt-collapsible-box>

          <!-- ================ Client References ================ -->
          <rt-collapsible-box [openStateStoreAccessor]="boxStateAccessors.clientReferences"
                              title="Client References"
                              [isempty]="shipment | rtHasNoClientReferences">
            <div class="padding">
              <table class="table-clean">
                <tbody>
                <tr *ngIf="shipment.shipperReferences.any()">
                  <th>Shipper</th>
                  <td>
                    <p>{{shipment.shipperReferences | rtStringArray | unescapeHtml}}</p>
                  </td>
                </tr>
                <tr *ngIf="shipment.consigneeReferences.any()">
                  <th>Consignee</th>
                  <td>
                    <p>{{shipment.consigneeReferences | rtStringArray | unescapeHtml}}</p>
                  </td>
                </tr>
                <tr *ngIf="shipment.pickupReferences.any()">
                  <th>Pickup From Party Reference</th>
                  <td>
                    <p>{{shipment.pickupReferences | rtStringArray | unescapeHtml}}</p>
                  </td>
                </tr>
                <tr *ngIf="shipment.deliveryReferences.any()">
                  <th>Deliver To Party Reference</th>
                  <td>
                    <p>{{shipment.deliveryReferences | rtStringArray | unescapeHtml}}</p>
                  </td>
                </tr>
                <tr *ngIf="shipment.notifyReferences.any()">
                  <th>Notify Party</th>
                  <td>
                    <p>{{shipment.notifyReferences | rtStringArray | unescapeHtml}}</p>
                  </td>
                </tr>

                </tbody>
              </table>
            </div>
          </rt-collapsible-box>

          <!-- ================ EVENTS ================ -->
          <rt-collapsible-box [openStateStoreAccessor]="boxStateAccessors.events"
                              [title]="'Events'"
                              [isempty]="shipment.events.length === 0">
            <div class="padding">
              <table class="table-clean events">
                <tbody>
                <tr *ngFor="let event of shipment.events">
                  <th>{{event.actualDate | rtDate}}</th>
                  <td>
                    <p>{{event.description}}</p>
                    <small *ngIf="event.address" [innerHTML]="event.address  | rtAddress">
                    </small>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </rt-collapsible-box>

          <!-- ================ PACKING ================ -->
          <rt-collapsible-box [isempty]="shipment | pureFunction:hasNoGoodsInformation"
                              [title]="'Goods'"
                              [openStateStoreAccessor]="boxStateAccessors.goods"
                              contentClasses="packing">
            <header>
              <table class="table-clean">
                <tbody>
                <tr *ngIf="shipment.containerMode === 'FCL'">
                  <th>Container Count</th>
                  <td>{{shipment.goods.containers.length | rtInteger}}</td>
                </tr>
                <tr>
                  <th>Pieces</th>
                  <td>{{shipment.goods.packageCount | rtInteger}}</td>
                </tr>
                <tr>
                  <th>Weight</th>
                  <td>{{shipment.goods.grossWeight | rtDimension}}</td>
                </tr>
                <tr>
                  <th>Volume</th>
                  <td>{{shipment.goods.volume | rtDimension}}</td>
                </tr>
                <tr *ngIf="shipment | rtIsFclOrBuyersConsol | not ">
                  <th>Chargeable</th>
                  <td>{{shipment.goods.chargeable | rtDimension}}</td>
                </tr>
                <tr>
                  <th>Dangerous Goods</th>
                  <td [rt-dangerous-goods-list]="shipment | rtGetDangerousGoods">
                  </td>
                </tr>
                <tr>
                  <th>Goods Description</th>
                  <td>{{shipment.goods.goodsDescription | rtString | unescapeHtml}}</td>
                </tr>
                <tr>
                  <th>Marks &amp; Numbers</th>
                  <td>{{shipment.goods.marksAndNumbers | rtString | unescapeHtml}}</td>
                </tr>
                </tbody>
              </table>
            </header>
            <ng-container *ngIf="shipment | rtIsFclOrBuyersConsol">
              <!--            For correct order of background colors-->
              <div class="container" style="display: none;"></div>
              <div class="container" *ngFor="let containerBase of shipment.goods.containers">
                <header *ngIf="containerBase  | rtAsContainer as container">
                  <div class="container-icon" [ngClass]="container | rtContainerIconClass"></div>
                  <div class="container-info">
                    <h5><a routerLink="/Shipment/ContainerDetail" [queryParams]="container | pureFunction:getQueryParams">{{container.containerNumber| rtString }}</a></h5>
                    <small class="text-light">{{container.type}}</small>
                  </div>
                  <table class="table-clean table-narrow">
                    <tbody>
                    <tr>
                      <th>Status</th>
                      <td>
                        <div class="status"
                             [ngClass]="[container.status | rtContainerStatusClass]">
                          {{container.status |rtContainerStatusName | rtString}}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Seal</th>
                      <td>{{container.sealNumber | rtString}}</td>
                    </tr>
                    </tbody>
                  </table>
                </header>
                <header *ngIf="containerBase | rtAsContainerGroup;let group">
                  <div class="container-icon" [ngClass]="group | rtContainerIconClass"></div>
                  <div class="container-info">
                    <span *ngFor="let container of group.containers"
                          [rtPopover]="'ng-container-info'"
                          class="popover-trigger"
                          [attr.data-container-info]="container | pureFunction:removeContainerGroupData:group | json">
                      {{container.containerNumber}}
                    </span>
                    <div class="flex-filler"></div>
                    <small class="text-light">{{group.commonType | rtString}}</small>
                  </div>
                  <table class="table-clean table-narrow">
                    <tbody>
                    <tr>
                      <th>Status</th>
                      <td>
                        <div class="status"
                             [ngClass]="group.commonContainerStatus | rtContainerStatusClass">
                          {{group.commonContainerStatus | rtContainerStatusName | rtString}}
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </header>
                <table class="table-clean table-narrow" *ngIf="(shipment | rtIsFcl) || (containerBase | rtIsOperatingReefer)">
                  <tbody>
                  <ng-container *ngIf="shipment | rtIsFcl">
                    <tr *ngIf="containerBase.packages | rtPackCount as packsCount">
                      <th>Pieces</th>
                      <td>{{packsCount | rtInteger}}</td>
                    </tr>
                    <tr *ngIf="containerBase.packages | rtPackWeight as packsWeight">
                      <th>Weight</th>
                      <td>{{packsWeight | rtDimension}}</td>
                    </tr>
                    <tr *ngIf="containerBase.packages | rtPackVolume as packsVolume">
                      <th>Volume</th>
                      <td>{{packsVolume | rtDimension}}</td>
                    </tr>
                    <ng-container *ngIf="containerBase | pureFunction:getContainerDangerousGoods as dangerousGoods">
                      <tr *ngIf="dangerousGoods.length">
                        <th>Dangerous Goods</th>
                        <td [rt-dangerous-goods-list]="dangerousGoods">
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                  <tr *ngIf="containerBase | rtIsOperatingReefer">
                    <th>Temperature</th>
                    <td>{{containerBase | rtReeferTemperature | rtDimension}}</td>
                  </tr>
                  </tbody>
                </table>
                <ng-container *ngIf="containerBase.packages | rtPacksWithInformation:shipment as packs">
                  <ng-container *ngIf="packs | rtHasInformationOtherThanPackCount:shipment">
                    <ng-container *ngIf="packs.length > 1">
                      <rt-shipment-package *ngFor="let pack of packs"
                                           [package]="pack"
                                           [shipment]="shipment"></rt-shipment-package>
                    </ng-container>
                    <ng-container *ngIf="packs.length === 1">
                      <rt-shipment-package *ngIf="packs[0].packCount > 1 || (packs[0] | rtHasInformationOtherThanPackCountVolumeWeight:shipment)"
                                           [package]="packs[0]"
                                           [shipment]="shipment"></rt-shipment-package>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="shipment | rtIsFclOrBuyersConsol | not">
              <ng-container *ngIf="shipment.goods.packages | rtPacksWithInformation:shipment as packs">
                <ng-container *ngIf="packs | rtHasInformationOtherThanPackCount:shipment">
                  <!--                For correct order of background colors-->
                  <rt-shipment-package style="display: none;"></rt-shipment-package>
                  <ng-container *ngIf="packs.length > 1">
                    <rt-shipment-package *ngFor="let pack of packs" [package]="pack" [shipment]="shipment"></rt-shipment-package>
                  </ng-container>
                  <ng-container *ngIf="packs.length === 1">
                    <rt-shipment-package [shipment]="shipment"
                                         *ngIf="packs[0].packCount > 1 || (packs[0] | rtHasInformationOtherThanPackCountVolumeWeight:shipment)"
                                         [package]="packs[0]"></rt-shipment-package>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </rt-collapsible-box>


          <!-- ================ NOTES ================ -->
          <rt-collapsible-box [openStateStoreAccessor]="boxStateAccessors.notes"
                              [title]="'Notes'"
                              [isempty]="!shipment.notes.any()">
            <div class="padding wysiwyg notes">
              <p *ngFor="let note of shipment.notes">
                <span class="text-light">{{note | rtNoteTypeName}}</span><br>
                <rt-html-container [html]="note | rtNoteText | rtString"></rt-html-container>
              </p>
            </div>
          </rt-collapsible-box>


          <!-- ================ ORDERS ================ -->
          <rt-collapsible-box [isempty]="shipment.orders.length === 0 && shipment.orderReferences.length === 0"
                              [openStateStoreAccessor]="boxStateAccessors.orders"
                              [title]="'Orders'">
            <table class="table-default table-responsive" *ngIf="shipment.orders.length > 0">
              <thead>
              <tr>
                <th>Order Number</th>
                <th>Supplier</th>
                <th>Buyer</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let order of shipment.orders">
                <td data-header="Order Number" [rt-reference-link]="order"></td>
                <td data-header="Supplier">{{order.supplier | rtString}}</td>
                <td data-header="Buyer">{{order.buyer | rtString}}</td>
              </tr>
              </tbody>
            </table>
            <div class="padding" *ngIf="shipment.orderReferences.length">
              <table class="table-clean">
                <tbody>
                <tr>
                  <th>Order Numbers</th>
                  <td [innerHTML]="shipment.orderReferences | pureFunction:toOrderNumberList | rtString"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </rt-collapsible-box>

          <!-- ================ DOCUMENTS ================ -->
          <rt-collapsible-box *ngIf="hasDocumentRights$ | async"
                              [isempty]="shipment | pureFunction:hasNoDocuments"
                              [openStateStoreAccessor]="boxStateAccessors.documents"
                              [title]="'Documents'">
            <rt-shipment-documents [documents]="[shipment.documents, shipment.customsDeclarationDocuments]"
                                   [invoices]="(hasInvoiceDocumentRights$ | async) ? shipment.invoiceDocuments: []"></rt-shipment-documents>
          </rt-collapsible-box>

          <!-- ================ MAP ================ -->

          <rt-collapsible-box [openStateStoreAccessor]="boxStateAccessors.map"
                              [wide]="true"
                              [title]="'Map'">
            <rt-shipment-map [shipment]="shipment"></rt-shipment-map>
          </rt-collapsible-box>
        </div>
        <footer>
        </footer>
      </ng-container>
      <ng-template #skeletonView>
        <div class="summary no-shrink">
          <div class="static">
            <a routerLink="/Shipment" class="button back-button secondary"><span class="mat-icon-back"></span></a>
          </div>
          <div>
            <p><span class="shimmer-content" style="width: 60%;"></span></p>
            <p><span class="shimmer-content" style="width: 80%;"></span></p>
          </div>
          <div>
            <p><span class="shimmer-content" style="width:100%"></span></p>
            <p><span class="shimmer-content" style="width:40%"></span></p>
          </div>
          <div>
            <p><span class="shimmer-content" style="width:100%"></span></p>
            <p><span class="shimmer-content" style="width:40%"></span></p>
          </div>
          <div>
            <p><span class="shimmer-content" style="width:100%"></span></p>
            <p><span class="shimmer-content" style="width:40%"></span></p>
          </div>
          <div class="shrink">
            <p><span class="shimmer-content" style="width:100%"></span></p>
            <p><span class="shimmer-content" style="width:70%"></span></p>
            <p><span class="shimmer-content" style="width:30%"></span></p>
          </div>
        </div>
        <div #grid class="boxes grid grid-padding">
          <div class="grid-gutter" #gridGutter></div>
          <rt-skeleton-box class="skeleton-large"></rt-skeleton-box>
          <rt-skeleton-box></rt-skeleton-box>
          <rt-skeleton-box class="skeleton-large"></rt-skeleton-box>
          <rt-skeleton-box></rt-skeleton-box>
        </div>
      </ng-template>
    </section>
  </main>
</div>
