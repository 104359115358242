import {Injectable} from "@angular/core";
import {UserRightsStore} from "@app/stores/user-rights-store.service";
import {AccountStore} from "@app/stores/account-store.service";
import {EntitySetInfo, UserGroup, UserInformation, UserType} from "@api";
import {UserRightsStoreState} from "@app/stores/userRightsStoreState";

@Injectable({providedIn: "root"})
export class StoreConnectorService {
  private readonly _userRightsStore: UserRightsStore;

  private readonly _accountStore: AccountStore;

  constructor(accountStore: AccountStore, userRightsStore: UserRightsStore) {
    this._accountStore = accountStore;
    this._userRightsStore = userRightsStore;
    accountStore
      .observe()
      .subscribe(u => this._updateRights(u))
  }

  private _updateRights(u: Partial<UserInformation>): void {
    const entityRights: Partial<EntitySetInfo> = u.currentEntitySet ?? {} as Partial<EntitySetInfo>;
    const rightsState: UserRightsStoreState = {
      administration: u.hasAdministrationRights ?? false,
      bookings: entityRights.hasBookingRights ?? false,
      canChangeUserGroups: u.type === UserType.SuperUser || [UserGroup.LocalAdmin, UserGroup.GlobalAdmin].contains(u.group),
      canCreateSites: u.canCreateSites,
      canCreateUsers: u.canCreateUsers,
      canHaveBookingRights: u.type !== UserType.Rohlig,
      documents: entityRights.hasDocumentRights ?? false,
      entityManagement: u.hasEntityManagementRights ?? false,
      exceptions: entityRights.hasExceptionRights ?? false,
      exportToExcel: entityRights.hasExportToExcelRights ?? false,
      invoices: entityRights.hasInvoiceRights ?? false,
      isAuthenticated: !!u.name,
      isSuperUser: u.type === UserType.SuperUser,
      messaging: entityRights.hasMessagingRights ?? false,
      orders: entityRights.hasOrderRights ?? false,
      products: entityRights.hasProductRights ?? false,
      shipments: entityRights.hasShipmentRights ?? false,
      userManagement: u.hasUserManagementRights ?? false,
      userGroup: u.group || null,
      userType: u.type
    }
    this._userRightsStore.update(rightsState);
  }
}
