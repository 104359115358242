import { Component, HostBinding, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {SlideInOut} from "@utils/Angular/AnimationTemplates";

@Component({
             selector: 'rt-accordion',
             templateUrl: './accordion.component.html',
             styleUrls: ['./accordion.component.scss'],
             host: {
               class: "accordion"
             },
             animations: [SlideInOut()]
           })
export class AccordionComponent {

  @Input()
  title: string;
  @HostBinding("class.accordion-animated")
  @Input()
  animated: boolean;
  @HostBinding("class.closed")
  isClosed: boolean;
  @Output()
  readonly openStateChange = new EventEmitter<boolean>();

  toggle(): void {
    this.isClosed = !this.isClosed;
    window.setTimeout(()=> this.openStateChange.emit(!this.isClosed), 50);
  }
}
