interface IBrowser extends IIndexable<boolean | number | undefined> {
    webkit?: boolean;
    version?: number;
    playbook?: boolean;
    silk?: boolean;
    edge?: boolean;
    chrome?: boolean;
    firefox?: boolean;
    safari?: boolean;
    webview?: boolean;
    ie?: boolean;
}

interface IOs extends IIndexable<boolean | number | undefined> {

    ipod?: boolean;
    android?: boolean;
    ios?: boolean;
    ipad?: boolean;
    iphone?: boolean;
    kindle?: boolean;
    version?: number;
    webos?: boolean;
    touchpad?: boolean;
    blackberry?: boolean;
    bb10?: boolean;
    rimtabletos?: boolean;
}

interface IDevice extends IIndexable<boolean | undefined> {

    tablet?: boolean;
    phone?: boolean;
    desktop?: boolean;
}


let touch: boolean = false;
let browser: IBrowser = {};
let os: IOs = {};
const device: IDevice = {};
let ua: string;

ua = navigator.userAgent;

var webkit = ua.match(/Web[kK]it[\/]{0,1}([\d.]+)/),
    android = ua.match(/(Android);?[\s\/]+([\d.]+)?/),
    ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
    ipod = ua.match(/(iPod)(.*OS\s([\d_]+))?/),
    iphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
    webos = ua.match(/(webOS|hpwOS)[\s\/]([\d.]+)/),
    touchpad = webos && ua.match(/TouchPad/),
    kindle = ua.match(/Kindle\/([\d.]+)/),
    silk = ua.match(/Silk\/([\d._]+)/),
    blackberry = ua.match(/(BlackBerry).*Version\/([\d.]+)/),
    bb10 = ua.match(/(BB10).*Version\/([\d.]+)/),
    rimtabletos = ua.match(/(RIM\sTablet\sOS)\s([\d.]+)/),
    playbook = ua.match(/PlayBook/),
    chrome = ua.match(/Chrome\/([\d.]+)/) || ua.match(/CriOS\/([\d.]+)/),
    firefox = ua.match(/Firefox\/([\d.]+)/),
    ie = ua.match(/(Trident|MSIE\s([\d.])+)/),
    edge = ua.match(/(Edge\/([\d.])+)/),
    //webview = ua.match(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/),
    wp8 = ua.match(/Trident\/([\d.]+)/) && ua.match(/IEMobile\/([\d.]+)/),
    safari = webkit && ua.match(/Safari\//) && !chrome && !wp8;

const touchDevice = !!(android || iphone || ipad || ipod || webos || blackberry || bb10 || rimtabletos ||
                    playbook || kindle);

if (browser.webkit = !!webkit)
    browser.version = Number(webkit[1]);
if (android) {
    os.android = true;
    os.version = Number(android[2]);
}
if (iphone && !ipod) {
    os.ios = os.iphone = true;
    os.version = Number(iphone[2].replace(/_/g, '.'));
}
if (ipad) {
    os.ios = os.ipad = true;
    os.version = Number(ipad[2].replace(/_/g, '.'));
}
if (ipod) {
    os.ios = os.ipod = true;
    os.version = Number(ipod[3] ? ipod[3].replace(/_/g, '.') : null);
}
if (webos) {
    os.webos = true;
    os.version = Number(webos[2]);
}
if (touchpad)
    os.touchpad = true;
if (blackberry) {
    os.blackberry = true;
    os.version = Number(blackberry[2]);
}
if (bb10) {
    os.bb10 = true;
    os.version = Number(bb10[2]);
}
if (rimtabletos) {
    os.rimtabletos = true;
    os.version = Number(rimtabletos[2]);
}
if (playbook)
    browser.playbook = true;
if (kindle) {
    os.kindle = true;
    os.version = Number(kindle[1]);
}
if (silk) {
    browser.silk = true;
    browser.version = Number(silk[1]);
}
if (!silk && os.android && ua.match(/Kindle Fire/))
    browser.silk = true;
if (edge) {
    browser.edge = true;
    browser.version = Number(edge[0].split('/')[1]);
    delete browser.webkit;
}
if (chrome && !edge) {
    browser.chrome = true;
    browser.version = Number(chrome[1]);
}
if (firefox) {
    browser.firefox = true;
    browser.version = Number(firefox[1]);
}
if (ie) {
    browser.ie = true;
    browser.version = Number(ie[1]);
}
if (safari && (ua.match(/Safari/) || !!os.ios))
    browser.safari = true;
//if (webkit && android && !chrome && !edge)
//browser.webview = true;
if (wp8) {
    browser.ie = true;
    os.android = os.ios = os.ipad = os.iphone = browser.webkit = browser.safari = browser.webview = false;
}

device.tablet = !!(ipad || playbook || (android && !ua.match(/Mobile/)) ||
                   (firefox && ua.match(/Tablet/)) || (ie && !ua.match(/Phone/) && ua.match(/Touch/)));
device.phone = !!(!device.tablet && !os.ipod && (android || iphone || webos || blackberry || bb10 ||
                  (chrome && ua.match(/Android/)) || (chrome && ua.match(/CriOS\/([\d.]+)/)) ||
                  (firefox && ua.match(/Mobile/)) || (ie && ua.match(/Touch/))) || (wp8));
device.desktop = !device.tablet && !device.phone;

document.body.className += ' ' + toString();


function toString(): string {
    const classes = new Array<string>();

    for (let prop in os) {
        if (!os.hasOwnProperty(prop))
            continue;
        if (os[prop] === true)
            classes.push(prop);
    }

    for (let prop in browser) {
        if (!browser.hasOwnProperty(prop))
            continue;
        if (browser[prop] === true)
            classes.push(prop);
    }

    for (let prop in device) {
        if (!device.hasOwnProperty(prop))
            continue;
        if (device[prop] === true)
            classes.push(prop);
    }

    if (device.desktop) {
        if (touch = isTouchDevice() === true)
            classes.push('touch');

    } else {
        touch = true;
        classes.push('touch');
    }


    return classes.join(' ');
}

function isTouchDevice(): boolean {
    try {
        document.createEvent("TouchEvent");
        return true;
    } catch (e) {
        return false;
    }

}

const detect = {
    'os': os,
    'browser': browser,
    'device': device,
    'touch': touch,
    touchDevice: touchDevice
};

export = detect;

