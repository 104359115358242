import { Component } from '@angular/core';
import { AppBaseComponent } from "@app/appBaseComponent";

@Component({
  selector: 'rt-skeleton-box',
  templateUrl: './skeleton-box.component.html',
  styleUrls: ['./skeleton-box.component.scss'],
  host: {
    class: "box"
  },
})
export class SkeletonBoxComponent extends AppBaseComponent {
}
