import { openAlert } from "./Alert";
require("../utils/JqueryLoader");
export class CustomerserviceModal {

    jobnumber: string;
    type: string;
    header = 'Message to Customer Service';
    text: string;
    id: string;

    $element: JQuery;

    mWrapper: HTMLDivElement;
    mWindow: HTMLDivElement;
    textarea: HTMLTextAreaElement;

    constructor($element: JQuery) {
        this.$element = $element;

        let settings;
        if ($element.data('settings'))
            settings = $element.data('settings');
        $.extend(true, this, settings);

        $('.modal-cs-window-wrapper.' + this.id).remove();
        this.initialize();
    }

    initialize() {
        // Create modal box

        this.mWrapper = document.createElement('div');
        this.mWrapper.classList.add('modal-cs-window-wrapper');
        this.mWrapper.classList.add(this.id.toString());

        this.mWindow = document.createElement('div');
        this.mWindow.classList.add('modal-cs-window');


        // Header

        let headerString = "";
        if (this.type || this.jobnumber) {
            headerString += "";
        }
        if (this.type) {
            headerString += ' ' + this.type + ' ';
        }
        if (this.jobnumber) {
            headerString += this.jobnumber
        }

        let mHeader = document.createElement('div');
        mHeader.classList.add('header');
        let headerText = document.createElement('h2');
        headerText.appendChild(document.createTextNode(this.header))
        mHeader.appendChild(headerText)
        let secondLine = document.createElement('div');
        secondLine.appendChild(document.createTextNode(headerString));
        mHeader.appendChild(secondLine)
        this.mWindow.appendChild(mHeader);


        // Body
        let mBody = document.createElement('div');
        mBody.classList.add('body');
        let bodyText = document.createElement('textarea');
        bodyText.setAttribute('placeholder', 'Please let us know how we can help you.');
        $(bodyText).bind('input propertychange', function () { if (this.value.length) { $(".footer #messageDiv").remove(); } });
        this.textarea = bodyText;
        mBody.appendChild(bodyText);
        this.mWindow.appendChild(mBody);

        // Footer
        let mFooter = document.createElement('div');
        mFooter.classList.add('footer');


        $(document.createElement('a'))
            .addClass('button primary')
            .text('Send')
            .on('click', this.doAction.bind(this))
            .appendTo(mFooter);


        $(document.createElement('a'))
            .addClass('button secondary')
            .text('Cancel')
            .on('click', this.closeModal.bind(this))
            .appendTo(mFooter);


        this.mWindow.appendChild(mFooter);
        this.mWrapper.appendChild(this.mWindow);

        // Add modal window to document at the beginning
        $(document.body).prepend(this.mWrapper);

        window.addEventListener('resize', this.setPosition.bind(this));
        this.setPosition();
        this.$element.on('click', this.openModal.bind(this));
    }

    openModal(event: JQueryEventObject) {
        event.preventDefault();
        this.mWrapper.classList.add('show');
        $('<div class="modal-overlay"></div>').insertAfter(this.mWrapper);
    }

    closeModal(action: Function) {
        $(".footer #messageDiv").remove();
        this.mWrapper.classList.remove('show');
        $('.modal-overlay').remove();
        this.textarea.value = "";
    }

    setPosition(event?: JQueryEventObject) {
        //$window = $(this.mWindow);

        //this.mWindow.style.top = 'calc(50% - ' + $window.height() / 2 + 'px)';
        //this.mWindow.style.left = 'calc(50% - ' + $window.width() / 2 + 'px)';
    }

    doAction() {

        $(".footer #messageDiv").remove();
        let $textarea = $(this.textarea);

        let text = $textarea.val();

        if (text) {
            let errorDiv = $('<div id="messageDiv"></div>')
                .addClass('error-message')
                .text('You need to enter a message.');
            let footer = $(this.mWrapper).find('.footer');
            if (footer.has('#messageDiv').length === 0) {
                footer.append(errorDiv);
            }
        } else {

            this.text = text;

            $.ajax({
                type: 'POST',
                url: window.baseApplicationUrl + '/WebMethod/SendCustomerServiceRequest',
                data: { id: this.id, text: this.text, jobnumber: this.jobnumber, header: this.header, type: this.type },
                async: false,
                context: this
            })
                .done(function (data) {
                    this.textarea.value = "";
                    this.closeModal();
                    this.showAlert('success', 'Message sent to Customer Service.', 5000, null);
                })
                .fail(function () {
                    let errorDiv = $('<div id="messageDiv"></div>')
                        .addClass('error-message')
                        .text('Ooops. Something went wrong. Please try to send request again.');
                    if ($(this.mWindow).find('.footer #messageDiv').length === 0) {
                        $(this.mWindow).find('.footer').append(errorDiv);
                    }
                });
        }
    }

    showAlert = (type: "info" | "success" | "warning" | "error", message: string, timeout: number, dontDisplayX?: boolean): void => {
        openAlert({ type, content: message, timeout, dontDisplayX });
    };

}

