import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountStore } from '@app/stores/account-store.service';
import { Observable } from 'rxjs';

@Injectable()
export class EntityIDInterceptor implements HttpInterceptor {
  
  private readonly _accountEntity: AccountStore;
  constructor(accountEntity: AccountStore) {
    this._accountEntity = accountEntity;
  }
  
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>{
    const currentEntityId = this._accountEntity.get("currentEntitySet")?.id;
    if (currentEntityId){
      const reqWithAuthen = req.clone({
        headers: req.headers.append("Entity-ID", currentEntityId)});
      return  next.handle(reqWithAuthen);
    }
    else{
      return next.handle(req)
    }
  }
}