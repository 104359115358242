import {
  AccountApiClient,
  API_BASE_URL, ContainerApiClient,
  EntityApiClient,
  OrderApiClient,
  OrganizationApiClient,
  SettingsApiClient,
  ShipmentApiClient,
  UserApiClient,
  WebMethodClient
} from "./api";
import {HttpClient} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {timespanRegex} from "@utils/Utils";

export const jsonReviverDateRegex = /^(\d{4})-([01]\d)-([0123]\d)T([012]\d):([012345]\d):([012345]\d)(?:\.(\d{4}))?(Z|[+-]\d+:\d+)?$/;

export const jsonDateReviver = (key: string, value: unknown): unknown => {
  if (!value || typeof value !== "string")
    return value;
  if (jsonReviverDateRegex.test(value)) {
    const groups = jsonReviverDateRegex.exec(value)
    const numbers: number[] = [];
    for (let i = 1; i < 8; i++) {
      numbers.push(parseInt(groups[i] || "0"));
    }
    return new Date(numbers[0], numbers[1] - 1, numbers[2], numbers[3], numbers[4], numbers[5], numbers[6]);
  }
  if (timespanRegex.test(value)) {
    const groups = timespanRegex.exec(value);
    return new Date(0, 0, 0, parseInt(groups[1]), parseInt(groups[2]), parseInt(groups[3]))
  }
  return value;
};

@Injectable({providedIn: "root"})
export class EntityApi extends EntityApiClient {

  protected jsonParseReviver = jsonDateReviver;

  constructor(http: HttpClient, @Inject(API_BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }
}


@Injectable({providedIn: "root"})
export class OrganizationApi extends OrganizationApiClient {

  protected jsonParseReviver = jsonDateReviver;

  constructor(http: HttpClient, @Inject(API_BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }
}

@Injectable({providedIn: "root"})
export class ShipmentApi extends ShipmentApiClient {

  protected jsonParseReviver = jsonDateReviver;

  constructor(http: HttpClient, @Inject(API_BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }
}

@Injectable({providedIn: "root"})
export class ContainerApi extends ContainerApiClient {

  protected jsonParseReviver = jsonDateReviver;

  constructor(http: HttpClient, @Inject(API_BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }
}

@Injectable({providedIn: "root"})
export class OrderApi extends OrderApiClient {

  protected jsonParseReviver = jsonDateReviver;

  constructor(http: HttpClient, @Inject(API_BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }
}

@Injectable({providedIn: "root"})
export class UserApi extends UserApiClient {

  protected jsonParseReviver = jsonDateReviver;

  constructor(http: HttpClient, @Inject(API_BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }
}

@Injectable({providedIn: "root"})
export class WebMethodApi extends WebMethodClient {

  protected jsonParseReviver = jsonDateReviver;

  constructor(http: HttpClient, @Inject(API_BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }
}

@Injectable({providedIn: "root"})
export class AccountApi extends AccountApiClient {

  protected jsonParseReviver = jsonDateReviver;

  constructor(http: HttpClient, @Inject(API_BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }
}

@Injectable({providedIn: "root"})

export class SettingsApi extends SettingsApiClient {

  protected jsonParseReviver = jsonDateReviver;

  constructor(http: HttpClient, @Inject(API_BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }
}
