import {Component, Input} from '@angular/core';
import {DangerousGoodsData} from "@api";

@Component({
  selector: '[rt-dangerous-goods-list]',
  templateUrl: './dangerous-goods-list.component.html'
})
export class DangerousGoodsListComponent {

  @Input("rt-dangerous-goods-list")
  dangerousGoodsList: Grouping<DangerousGoodsData, string>[];

}
