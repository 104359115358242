import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot} from "@angular/router";
import {IView} from "@utils/Angular/View";
import {GetActiveViewIndex, GetView, ViewArrangement} from "@booking/booking-views";
import {BookingServiceListener} from "@services/booking-service-listener.service";

@Injectable()
export class ActivationService implements CanActivate, CanDeactivate<IView> {
  private readonly _bookingServiceListener: BookingServiceListener;

  constructor(bookingServiceListener: BookingServiceListener) {
    this._bookingServiceListener = bookingServiceListener;
  }

  private static _error(reason: string) {
    const error = new Error(reason);
    (error as any)["ngNavigationCancelingError"] = true;
    return error;

  }

  async canDeactivate(component: IView,
                      currentRoute: ActivatedRouteSnapshot,
                      currentState: RouterStateSnapshot,
                      nextState?: RouterStateSnapshot): Promise<boolean> {

    const activeView = ViewArrangement.activeView;
    if (!activeView)
      // Site initialization
    {
      return true;
    }

    const model = this._bookingServiceListener.currentBookingModel;
    if (!model)
      return true;

    if (model.enableNavigatingAway)
      return true;

    if (nextState && !nextState.url.contains("booking")) {
      // noinspection ES6MissingAwait
      this._bookingServiceListener.requestCancellation(nextState.url);
      throw ActivationService._error("booking cancellation requested");
    }
    if (!await activeView.canLeave()) {
      throw ActivationService._error(activeView.name + " does not allow leaving");
    }
    if (!nextState && model.disableNavigationValidation === false) {
      const isValid = await activeView.validate();
      if (isValid)
        return true;
      throw ActivationService._error(activeView.name + " validation failed");
    }
    const address = nextState!.url;
    const newView = GetView(address);
    if (!newView)
      throw ActivationService._error("View not defined");

    const newViewIndex = ViewArrangement.indexOf(newView);
    const activeViewIndex = GetActiveViewIndex();
    if (newViewIndex <= activeViewIndex)
      // step back is always allowed if the current view allows it
    {
      const canGoBack = activeView.canExit && activeView.canNavigateBack;
      if (canGoBack)
        return true;
      throw ActivationService._error(activeView.name + " is not allowing going back");
    }
    if (ViewArrangement.slice(activeViewIndex + 1, newViewIndex - 1)
                       .firstOrDefault(v => !v.isActivated)) {
      throw ActivationService._error("User attempts to skip a step that is not yet filled");
    }

    if (activeView && !await activeView.validate()) {
      throw ActivationService._error("leaving an invalid view in forward direction is not allow");
    }
    // validate all views between the currently active one and the new view
    for (let i = activeViewIndex + 1; i < newViewIndex; i++) {
      ViewArrangement[i].valid = ViewArrangement[i].validate(model, this._bookingServiceListener.currentBookingMode);
    }
    return true;

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const activeView = ViewArrangement.activeView;
    if (!activeView)
      //Site initialization
    {
      return true;
    }

    const model = this._bookingServiceListener.currentBookingModel;

    if (model?.booking.templateInformation.templateID) {
      return true;
    }
    const address = route.url[0].path;
    const newView = GetView(address);
    if (!newView?.visible) {
      throw ActivationService._error("View not defined");
    }
    const newViewIndex = ViewArrangement.indexOf(newView);
    const activeViewIndex = GetActiveViewIndex();
    if (newViewIndex <= activeViewIndex) {
      if (activeView.canExit)
        return true;
      throw ActivationService._error(activeView.name + " does not allow to exit");
    }
    if (ViewArrangement.slice(activeViewIndex + 1, newViewIndex - 1)
                       .firstOrDefault(v => !v.isActivated)) {
      throw ActivationService._error("User attempts to skip a step that is not yet filled");
    }
    if (activeView && !await activeView.validate()) {
      throw ActivationService._error("leaving an invalid view in forward direction is not allowed");
    }
    // validate all views between the currently active one and the new view
    for (let i = activeViewIndex + 1; i < newViewIndex; i++) {
      ViewArrangement[i].valid = await ViewArrangement[i].validate(model, this._bookingServiceListener.currentBookingMode);
    }
    return true;
  }
}
