import {ViewData} from "./view-data";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class ProfileViewData extends ViewData {

  get pageTitle(): string {
    return TitleBuilder.account.myProfile;
  }

  get url(): string {
    return UrlBuilder.account.myProfile;
  }

  readonly bodyClasses = ["page-profile"];

}
