import {Route, Routes, UrlSegment, UrlSegmentGroup} from '@angular/router';

export function caseInsensitiveMatcher(url: string, canActivate?: () => boolean) {
  return function (
    segments: UrlSegment[],
    segmentGroup: UrlSegmentGroup,
    route: Route
  ) {
    if (canActivate && !canActivate())
      return null;
    const matchSegments = url.split('/');
    if (
      matchSegments.length > segments.length ||
      (matchSegments.length !== segments.length && route.pathMatch === 'full')
    ) {
      return null;
    }

    const consumed: UrlSegment[] = [];
    const posParams: { [name: string]: UrlSegment } = {};
    for (let index = 0; index < matchSegments.length; ++index) {
      const segment = segments[index].toString().toLowerCase();
      const matchSegment = matchSegments[index];

      if (matchSegment.startsWith(':')) {
        posParams[matchSegment.slice(1)] = segments[index];
        consumed.push(segments[index]);
      } else if (segment.toLowerCase() === matchSegment.toLowerCase()) {
        consumed.push(segments[index]);
      } else {
        return null;
      }
    }

    return {consumed, posParams};
  };
}

export function transformRoutesObject(routes: Routes) {
  for (let i = 0; i < routes.length; i++){
    let route = routes[i];
    if (route.path) {
      route.matcher = caseInsensitiveMatcher( route.path)
      delete route.path;
    }
    if (route.children instanceof Array)
      transformRoutesObject(route.children);
  }
  return routes;
}
