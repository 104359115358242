import {ContentComponent} from "@utils/Angular/ContentComponent";
import {ApplicationRef, Directive, ElementRef, Input} from "@angular/core";
import {Popover} from "@widgets/Popover";

@Directive({
    selector:"[rtPopover]"
           })
export class PopoverDirective extends ContentComponent{

    constructor(elementRef: ElementRef, app: ApplicationRef) {
        super(elementRef, app);
    }

    @Input("rtPopover")
    renderer:keyof typeof Popover.renderers;

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        // noinspection ObjectAllocationIgnored
         new Popover(this.element, this.renderer);
    }
}

