export const {document} = window;
export const DEFAULT_OPTIONS = {};

interface Options {
  r: number;
  color: string;
  x: number;
  context: CanvasRenderingContext2D;
  index: number;
  y: number;
  v?: number;
  content?: string;
  width?: number;
  height?: number;
  number?: number
}

const DEFAULT_SNOWPARTICLE_OPTIONS: Options = {
  index: 0,
  x: 0,
  y: 0,
  context: null,
  color: 'rgb(255, 255, 255)',
  r: 1,
};

function DEG(deg: number) {
  return Math.PI * (deg / 180);
}

function SINDEG(deg: number) {
  if (deg > DEG(165)) {
    deg -= (Math.PI / 4);
  } else if (deg < DEG(15)) {
    deg += (Math.PI / 4);
  }
  return deg;
}

function COSDEG(deg: number) {
  if (deg > DEG(15) && deg <= DEG(90)) {
    deg -= (Math.PI / 6);
  } else if (deg > DEG(90) && deg <= DEG(165)) {
    deg += (Math.PI / 6);
  }
  return deg;
}


class SnowParticle implements Options {
  option: Options;
  angle: number;
  v: number;
  r: number;
  color: string;
  x: number;
  context: CanvasRenderingContext2D;
  index: number;
  y: number;

  /**
   * Creates an instance of SnowParticle.
   * @param {Object} option content, x, y, color
   * @memberof Snow
   */
  constructor(option: Partial<Options> = {}) {
    this.option = Object.assign({}, DEFAULT_SNOWPARTICLE_OPTIONS, option);
    const {
      context, color, x, y, r, v,
    } = this.option;
    this.color = `${color.replace('rgb', 'rgba').split(')')[0]},${(Math.floor(Math.random() * 50) + 50) / 100})`;
    this.context = context
    this.r = r * (((Math.random() * 0.4) + 0.6));
    this.x = x;
    this.y = y;
    this.v = v;
    this.angle = (Math.PI * Math.random());
    // this.init();
  }

  // init() {

  // }
  draw() {
    const {
      context, color, x, y, r,
    } = this;
    context.beginPath();
    context.arc(Math.floor(x), Math.floor(y), r, 0, 2 * Math.PI, true);
    context.closePath();
    context.fillStyle = color;
    context.fill();
  }

  move() {
    const {
      width, height,
    } = this.option;
    this.x += this.v * (Math.cos(COSDEG(this.angle))) * 0.3;
    this.y += this.v * (Math.sin(SINDEG(this.angle)));
    if (this.y > height || this.x > width || this.x < 0) {
      this.y = 0;
      this.x = Math.random() * width;
      this.angle = (Math.PI * Math.random());
    }
  }
}

export class Snow {
  element: Element;
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  width: number;
  option: Partial<Options>;
  height: number;
  numberPerKiloPixel: number;
  particles: SnowParticle[] =[];
  destroyed = false;

  /**
   * Creates an instance of Snow.
   * @param {Element} element target
   * @param {Object} [option={}] options
   * @memberof Snow
   */
  constructor(element: Element, option: Partial<Options> = {}) {
    this.element = element;
    this.canvas = document.createElement("canvas");
    this.ctx = null;
    this.width = 0;
    this.height = 0;
    this.option = Object.assign({}, DEFAULT_OPTIONS, option);
    this.numberPerKiloPixel = this.option.number;
    this.particles = [];
    this.init();
  }

  init() {
    const {element} = this;
    this.width = element.clientWidth;
    this.height = element.clientHeight;
    this.createCanvas();
    this.createParticles();
    this.animate();
  }

  createCanvas() {
    const {element, width, height} = this;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    canvas.style.cssText = 'position:absolute;top:0;left:0;background:rgba(0,0,0,0);pointer-events:none;z-index:1;';
    element.appendChild(canvas);
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');
  }

  createParticles() {

    const { particles} = this;
    const particleNumber = this._getParticleNumber()
    for (let i = particles.length; i < particleNumber; i += 1) {
      const particle = this.createSnowParticle();
      particles.push(particle);
      particle.draw();
    }

  }

  private _getParticleNumber() {
    return this.width / 1000 * this.numberPerKiloPixel;
  }

  private createSnowParticle() {
    const {r, v} = this.option;
    const {
      ctx, width, height} = this;
    return new SnowParticle({
                              color: 'rgb(255,255,255)',
                              context: ctx,
                              y: Math.floor(Math.random() * height),
                              x: Math.floor(Math.random() * width),
                              r,
                              v,
                              width: this.width,
                              height: this.height,
                              // angle: Math.PI,
                            });
  }

  animate = () => {
    if (this.destroyed)
      return;
    this.ctx.clearRect(0, 0, this.width, this.height);
    let element = this.element;
    if (this.height !== element.clientHeight || this.width !== element.clientWidth)
      this._refreshDimensions();
    for (let i = 0; i < this._getParticleNumber(); i++){
      const item = this.particles[i];
      if (!item)
        break;
      item.move();
      item.draw();
    }
    requestAnimationFrame(this.animate);
  };

  destroy() {
    this.destroyed = true;
  }

  private _refreshDimensions() {
    let element = this.element;
    this.height = element.clientHeight;
    this.width = element.clientWidth;
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    for (const particle of this.particles) {
      particle.option.width = this.width;
      particle.option.height = this.height;
    }
    this.createParticles();
  }
}
