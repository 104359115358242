/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-explicit-any */
// noinspection CommaExpressionJS

import {Inject, Injectable} from '@angular/core';
import {AccountService} from "@services/account-service/account.service";
import {BookingViewData, ProfileViewData, ShipmentViewData, ViewData} from "@view-data";
import {EntitySetInfo} from "@api";
import {DOCUMENT} from "@angular/common";
import {OrderViewData} from "@services/view-data/data-objects/order-view-data";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {TypeFilter} from "@utils/rxjs-extensions";
import {BehaviorSubject} from 'rxjs';

import config = require("./../app.config");
import {RoutingStore} from "@stores";

@Injectable({
              providedIn: 'root'
            })
export class ChatService {
  isInitialized = false;
  isVisible$: BehaviorSubject<boolean>;
  private _messagingAllowed = false;
  private _messagingAvailableOnSite = false;
  readonly _document: Document;

  private _userName: string;
  private _mailAddress: string;
  private _entityName: string;

  constructor(accountService: AccountService, routingStore: RoutingStore, @Inject(DOCUMENT) document: Document, router: Router) {
    //this.isVisible$ = new BehaviorSubject<boolean>(false);
    //this._document = document;
    //accountService
    //  .accountStore
    //  .observe(["name", "mail"])
    //  .subscribe(u => {
    //    this._userName = u?.name;
    //    this._mailAddress = u?.mail
    //  });
    //router.events
    //      .pipe(filter(TypeFilter(NavigationEnd)))
    //      .subscribe(e => this._updateChatPage(e.url));
    //accountService
    //  .accountStore
    //  .observe("currentEntitySet")
    //  .subscribe(e => this._entitySetChanged(e))
    //routingStore
    //  .subscribe("currentViewData", d => this._viewDataChanged(d))
  }

  private _setupChat() {
    if (this.isInitialized)
      return;
    this.isInitialized = true;
    this.isVisible$.next(true);
    this._loadLpTag();
    window.lpTag.sdes.push(
      {
        "type": "ctmrinfo",
        "info": {
          "ctype": this._entityName,
          "customerId": this._mailAddress,
          "userName": this._userName
        }
      }
    );
  }

  private _loadLpTag() {
    window.lpTag = window.lpTag || {};
    window.lpTag = {
      wl: window.lpTag.wl || null,
      scp: window.lpTag.scp || null,
      site: config.livePersonId,
      section: window.lpTag.section || '',
      tagletSection: window.lpTag.tagletSection || null,
      autoStart: window.lpTag.autoStart !== !1,
      ovr: window.lpTag.ovr || {},
      _v: '1.10.0',
      _tagCount: 1,
      protocol: 'https:',
      events: {
        bind: function (t: any, e: any, i: any) {
          window.lpTag.defer(function () {
            window.lpTag.events.bind(t, e, i)
          }, 0)
        },
        trigger: function (t: any, e: any, i: any) {
          window.lpTag.defer(function () {
            window.lpTag.events.trigger(t, e, i)
          }, 1)
        }
      },
      defer: function (t: any, e: any) {
        e === 0 ? (this._defB = this._defB || [], this._defB.push(t)) : e === 1 ? (this._defT = this._defT || [], this._defT.push(t)) : (this._defL =
          this._defL || [], this._defL.push(t))
      },
      load: function (t: any, e: any, i: any) {
        const n = this;
        setTimeout(function () {
          n._load(t, e, i)
        }, 0)
      },
      _load: function (t: any, e: any, i: any) {
        let n = t;
        t || (n = this.protocol + '//' + (this.ovr && this.ovr.domain ? this.ovr.domain : 'lptag.liveperson.net') + '/tag/tag.js?site=' + this.site);
        const o = document.createElement('script');
        o.setAttribute('charset', e ? e : 'UTF-8'), i && o.setAttribute('id', i), o.setAttribute('src', n), document.getElementsByTagName('head')
                                                                                                                    .item(0)
                                                                                                                    .appendChild(o)
      },
      init: function () {
        this._timing = this._timing || {}, this._timing.start = (new Date).getTime();
        const t = this;
        window.attachEvent ? window.attachEvent('onload', function () {
          t._domReady('domReady')
        }) : (window.addEventListener('DOMContentLoaded', function () {
          t._domReady('contReady')
        }, !1), window.addEventListener('load', function () {
          t._domReady('domReady')
        }, !1)), typeof window._lptStop === 'undefined' && this.load()
      },
      start: function () {
        this.autoStart = !0
      },
      _domReady: function (t: any) {
        this.isDom || (this.isDom = !0, this.events.trigger('LPT', 'DOM_READY', {t: t})), this._timing[t] = (new Date).getTime()
      },
      vars: window.lpTag.vars || [],
      dbs: window.lpTag.dbs || [],
      ctn: window.lpTag.ctn || [],
      sdes: window.lpTag.sdes || [],
      hooks: window.lpTag.hooks || [],
      identities: window.lpTag.identities || [],
      ev: window.lpTag.ev || []
    };
    window.lpTag.init();
  }

  private _entitySetChanged(entitySetInfo: EntitySetInfo) {
    this._messagingAllowed = entitySetInfo?.hasMessagingRights;
    this._entityName = entitySetInfo?.name;
    this._refreshChatActivationStatus();
  }

  private _refreshChatActivationStatus() {
    const chatShouldBeActive = this._messagingAllowed && this._messagingAvailableOnSite;
    if (chatShouldBeActive) {
      if (!this.isInitialized)
        this._setupChat();
      this.isVisible$.next(true);
      this._document.body.classList.remove("hide-chat");
    } else {
      this.isVisible$.next(false);
      this._document.body.classList.add("hide-chat");
    }
  }

  private _viewDataChanged(data: ViewData) {
    this._messagingAvailableOnSite =
      data instanceof ShipmentViewData ||
      data instanceof BookingViewData ||
      data instanceof OrderViewData ||
      data instanceof ProfileViewData;
    if (this.isInitialized)
      this._updateChatPage(data.url);
    this._refreshChatActivationStatus();
  }

  private _updateChatPage(absoluteUrl: string) {
    if (window.lpTag && window.lpTag.newPage)
      window.lpTag.newPage(`${location.protocol}//${location.host}${absoluteUrl}`, {
        sdes: [
          {
            "type": "ctmrinfo",
            "info": {
              "ctype": this._entityName,
              "customerId": this._mailAddress,
              "userName": this._userName
            }
          }
        ]
      });
  }
}
