<img class="rohlig-logo" src="/content/images/Rohlig_blue.svg" width="100%" alt="Logo">
<figure class="realtime-logo logologin">
  <a [routerLink]="''">
    <img src="/content/images/Real Time_blue.svg" width="100%" alt="Logo">
  </a>
</figure>

<h1>Reset Your Password</h1>
<ul class="steps forgot-password">
  <li>
    <p>
      <small>Step 1:</small>
      Enter email
    </p>
  </li>
  <li class="current end">
    <p>
      <small>Step 2:</small>
      Change password
    </p>
  </li>
</ul>
<p class="margin-bottom">We have sent you a mail if the account exists. Please follow the provided instructions.</p>
<div class="wysiwyg form-center">
  <div rt-change-password-form [requestId]="requestId" [authenticationMode]="'requestId'"></div>
  <p class="error-message" *ngIf="showUnknownErrorField">
    Unknown error. Please contact the administrator.
  </p>
  <p class="buttons phone-auto-width">
    <button class="button"
            [rtLoadingAnimation]="isChangingPassword"
            [loadingText]="'saving'"
    (click)="resetPassword()">
      Save
    </button>
    <a [routerLink]="''" class="button secondary">Cancel</a>
  </p>
</div>
