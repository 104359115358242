<div class="clip">
  <div class="search-bar button-container no-wrap">
    <span class="title">Users</span>
    <div class="search-input">
      <div class="grid">
        <button class="button mat-icon-search text-hide" (click)="startSearch()">Search</button>
        <p class="input-search">
          <input name="PrimarySearchObject.PrimarySearchNumber"
                 type="search"
                 placeholder="Search Users"
                 (keydown.enter)="startSearch()"
                 [ngModel]="reference.observe | async"
                 (ngModelChange)="reference.set($event)"/>
        </p>
      </div>
    </div>
    <div class="button-group grow phone-block">
      <div type="button"
           class="button secondary fake-select filters-toggle"
           [ngClass]="{'active': filtersOpen.observe | async}"
           (click)="toggleFiltersOpenState()"
           [attr.data-filters-count]="(filterCount$ | async ) || null ">
        <span>Filters</span>
        <span
          *ngIf="filterCount$ | async as filterCount"
          class="filter-count"
          [attr.data-filters-count]="filterCount"></span>
      </div>
      <a *ngIf="canCreateUsers$ | async" class="button navigation-button" routerLink="/Account/AddUser"><span class="mat-icon-add"></span>Add user</a>
    </div>
  </div>

  <div class="filters" [@slideInOut]="(filtersOpen.observe | async) ? 'visible' : 'hidden'">
    <div class="filter-columns">
      <div>
        <p>
          <label for="select-country">Country</label>
          <select id="select-country"
                  ngSelect
                  [storeAccessor]="countryCodes"
                  [settings]="selectSettings.countries"
                  [items]="countryItems">
          </select>
        </p>
      </div>
      <div>
        <p>
          <label for="select-entity-sets">Entity Sets</label>
          <select id="select-entity-sets"
                  ngSelect
                  [storeAccessor]="entityIds"
                  [settings]="selectSettings.entitySets"
                  [items]="entitySetItemsCache$ | async"
                  (selectionChanged)="addEntitySetToCache($event.selection)">
          </select>
        </p>
      </div>
      <div>
        <p>
          <label for="select-user-group">User Group</label>
          <select id="select-user-group"
                  ngSelect
                  [storeAccessor]="userGroups"
                  [settings]="selectSettings.default"
                  [items]="userGroupSearchItems">
          </select>
        </p>
      </div>
      <div>
        <p>
          <label for="select-deactivated">Deactivated</label>
          <select id="select-deactivated"
                  ngSelect
                  [storeAccessor]="activationStatus"
                  [settings]="selectSettings.single"
                  [items]="activationStatusSearchItems">
          </select>
        </p>
      </div>
    </div>
    <div class="grid padding">
      <div class="buttons phone-auto-width">
        <button class="button submit-form" type="submit" (click)="startSearchAndCloseFilters()">
          <span class="mat-icon-done">Apply</span>
        </button>
        <button class="button secondary reset-form disable-when-offline" type="reset" (click)="clearFilters()">
          <span class="mat-icon-close">Clear</span>
        </button>
      </div>
    </div>
  </div>
</div>
