<ng-container *ngIf="messageVisible | async">
  <div class="chat-bubble">
    <div class="bubble bubble-position" (click)="openChat()">
      <h5> Röhlig Real Time Chat</h5>
      <svg>
        <filter id="onHoverblack" style="color-interpolation-filters:sRGB">
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
        </filter>
        <image class="close" (click)="close()" xlink:href="/Content/images/X-3x.png" alt="x Symbol" width="14" height="14"/>
      </svg>
      <p> Click here to start chatting with one of our staff.</p>
    </div>
    <div class="pointer pointer-position"> </div>
  </div>
  <div class="chat-bubble">
    <div class="bubble shadow"> </div>
    <div class="pointer shadow"> </div>
  </div>
</ng-container>
