
// ReSharper disable InconsistentNaming
/*
 * Put global configuration options here.
 */
const settings: {
    [index:string]:any;
    JQUERY_NO_CONFLICT: boolean;
    VIEWPORTS: { [index: string]: [number, number]; };
    SMART_BLOCKS: IIndexable<IIndexable<[number, number, string]>>;
    LANGUAGE: string;
    TRANSLATIONS: { [index: string]: ITranslation },
    DROPZONE_CONSTS: {
        FILE_TOO_BIG: string;
        INVALID_FILE_TYPE: string;
        RESPONSE_ERROR: string;
        MAX_FILES_EXCEEDED: string;
    }
} = {

    // Enable or disable jQuery 'no conflict' mode. Defaults to `true`.
    JQUERY_NO_CONFLICT: true,

    // Some libraries use naming that is not compatible with our strict naming rules. Using the mapping below you
    // can make these libraries load properly under a new, compatible name.
    //'ALIASES': {
    //    'Phaser': 'phaser',
    //    'PIXI': 'pixi'
    //},

    VIEWPORTS: {
        '320': [0, 479],
        '480': [480, 719],
        '720': [720, 959],
        '960': [960, 1279],
        '1280': [1280, 1599],
        '1600': [1600, 1919],
        '1920': [1920, Infinity]
    } as {[index:string]:[number, number]},
    // Uncomment this to disable 'viewport-portrait' and 'viewport-landscape' classes added automatically to body.
    // 'VIEWPORT_ORIENTATION_CLASS': true,

    SMART_BLOCKS: {
        // Example smart block configuration.
        // Initial element selector.
        // '.block': {
        //     //Additional classes that will be applied when width of parent is between given numbers.
        //     'small': [0, 299],
        //     'medium': [300, 699],
        //     'big': [700, Infinity],
        // },
        // '.self-block': {
        //    // Additional classes that will be applied when width of element is between given numbers.
        //    'small': [0, 299, 'self'],
        //    'medium': [300, 699, 'self'],
        //    'big': [700, Infinity, 'self'],
        //}
        },

        DROPZONE_CONSTS: {
            FILE_TOO_BIG: "FILE_TOO_BIG",
            INVALID_FILE_TYPE: "INVALID_FILE_TYPE",
            RESPONSE_ERROR: "RESPONSE_ERROR",
            MAX_FILES_EXCEEDED:"MAX_FILES_EXCEEDED"
        },

    LANGUAGE: "ENG",

    TRANSLATIONS: {
        'ENG': {
            'file_upload': 'Drop your picture here or click to upload',
            'file_remove': 'Remove',
            'no_file': 'Click to select',
            'characters': 'characters',
            'hide': 'hide',
            'message_delete_confirm': 'Do you really want to delete this message?',
            'validation_required': '{0} is required.',
            'validation_email': 'Invalid email format.',
            'validation_minlength': 'Min. {0} characters.',
            'validation_maxlength': '{0} can not have more than {1} characters.',
            'validation_equal': 'Passwords don\'t match.',
            'validation_phone': 'Invalid phone number.',
            'validation_numbers': 'Numbers only.',
            'validation_letters-only': 'Letters only.',
            'validation_min': '{0} must be at least {1}.',
            'validation_max': '{0} can be at most {1}.',
            'validation_ascii_email':'Email contains unsupported characters.'
        }
    } as {[index:string]:ITranslation}
};

declare global {
    interface ITranslation {
        [index: string]:string;
        file_upload: string;
        file_remove: string;
        no_file: string;
        characters: string;
        hide: string;
        message_delete_confirm: string;
        /** [name] */
        validation_required: string;
        validation_email: string;
        /** [min-length] */
        validation_minlength: string;
        /** [name, max-length] */
        validation_maxlength: string;
        validation_equal: string;
        validation_phone: string;
        validation_numbers: string;
        /** [name, min] */
        'validation_min': string;
        /** [name, max] */
        'validation_max': string;
    }
}
export = settings;
// ReSharper restore InconsistentNaming
