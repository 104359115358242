<div class="page-wrap">
  <main>
    <section class="padding">
      <ng-container *ngIf="container$ | async as container; else skeletonView">
        <div class="summary">
          <div class="static">
            <a routerLink="/Shipment" class="button back-button secondary"><span class="mat-icon-back"></span></a>
          </div>
          <div>
            <header>
              <p>
                Container Number
              </p>
            </header>
            <footer>
              <p>{{container.containerNumber | rtTbd}}</p>
            </footer>
          </div>
          <div>
            <header>
              <p>Transport Mode</p>
            </header>
            <footer>
              <p *ngFor="let transportMode of container.transportModes">{{transportMode}}</p>
              <p>
                <ng-container *ngFor="let containerMode of container.containerModes; first as first">
                  <ng-container *ngIf="!first">{{' '}}</ng-container>
                  <span class="label grey">{{containerMode}}</span>
                </ng-container>
                {{' '}}
                <span *ngIf="container.isCustomsDeclaration" class="label grey">Customs Clearance Only</span>
              </p>
            </footer>
          </div>
          <div>
            <header>
              <p>Incoterm</p>
            </header>
            <footer>
              <p>{{container.incoterms | rtStringArray}}</p>
            </footer>
          </div>
          <div>
            <header>
              <p>Status</p>
            </header>
            <footer>
              <p [class]="container | rtDeliveryStatusTextColorClass">{{container.status | rtDeliveryStatus}}</p>
            </footer>

          </div>
          <div class="shrink">
            <header>
              <p>Last Event</p>
            </header>
            <footer style="flex-direction: column;">
              <ng-container *ngIf="container.events.last() as lastEvent; else noEvent">
                <p>{{lastEvent.description}}</p>
                <p>
                  <small class="text-light">{{lastEvent.actualDate | rtDate}}</small>
                </p>
              </ng-container>
              <ng-template #noEvent>
                {{'' | rtString }}
              </ng-template>
            </footer>
          </div>
        </div>
        <div #grid class="boxes grid grid-padding">
          <div class="grid-gutter" #gridGutter></div>

          <!-- ================ TIME LINE ================ -->
          <rt-collapsible-box [openStateStoreAccessor]="boxStateAccessors.timeline"
                              [title]="'Timeline'"
                              [isempty]="container.timelineLegs.length === 0">
            <div class="padding">
              <rt-vertical-timeline [shipment]="container"></rt-vertical-timeline>
            </div>
          </rt-collapsible-box>

          <!-- ================ Container Information ================ -->
          <rt-collapsible-box [isempty]="container | pureFunction:hasNoGoodsInformation"
                              title="Container Information"
                              [openStateStoreAccessor]="boxStateAccessors.containerInformation"
                              contentClasses="packing">
            <header>
              <div class="container-icon" [ngClass]="container | rtContainerIconClass">
              </div>
              <table class="table-clean">
                <tbody>
                <tr>
                  <th>Container Number</th>
                  <td>{{container.containerNumber | rtTbd}}</td>
                </tr>
                <tr>
                <tr>
                  <th>Container Type</th>
                  <td>{{container.type | rtTbd}}</td>
                </tr>
                <tr>
                  <th>Seal</th>
                  <td>{{container.sealNumber | rtTbd}}</td>
                </tr>
                <tr>
                  <th>Weight</th>
                  <td>{{container.goods[0]?.grossWeight | rtDimension}}</td>
                </tr>
                <tr>
                  <th>Volume</th>
                  <td>{{container.goods| rtMerge:'volumes' | rtMergeDimensions | rtDimension}}</td>
                </tr>
                </tbody>
              </table>
            </header>
          </rt-collapsible-box>

          <!-- ================ PARTIES ================ -->
          <rt-collapsible-box [title]="'Parties'"
                              [openStateStoreAccessor]="boxStateAccessors.parties"
                              [isempty]="container | pureFunction:hasNoParties">
            <div class="padding">
              <div class="shipment-information">
                <address>
                  <ng-container *ngIf="container.shipperLocations | any">
                    <p class="text-light">Shipper</p>
                    <rt-html-container [html]="container.shipperLocations | rtAddress:'Multiple Parties'"></rt-html-container>
                  </ng-container>
                </address>
                <address>
                  <ng-container *ngIf="container.consigneeLocations | any">
                    <p class="text-light">Consignee</p>
                    <rt-html-container [html]="container.consigneeLocations | rtAddress:'Multiple Parties'"></rt-html-container>
                  </ng-container>
                </address>
                <address>
                  <ng-container *ngIf="container.pickupLocations | any">
                    <p class="text-light">Pickup Location</p>
                    <rt-html-container [html]="container.pickupLocations | rtAddress"></rt-html-container>
                  </ng-container>
                </address>
                <address>
                  <ng-container *ngIf="container.deliveryLocations | any">
                    <p class="text-light">Delivery Location</p>
                    <rt-html-container [html]="container.deliveryLocations | rtAddress"></rt-html-container>
                  </ng-container>
                </address>
              </div>
            </div>
          </rt-collapsible-box>

          <!-- ================ Client References ================ -->
          <rt-collapsible-box [openStateStoreAccessor]="boxStateAccessors.clientReferences"
                              title="Client References"
                              [isempty]="container | rtHasNoClientReferences">
            <div class="padding">
              <table class="table-clean">
                <tbody>
                <tr *ngIf="container.shipperReferences.any()">
                  <th>Shipper</th>
                  <td>
                    <p>{{container.shipperReferences | rtStringArray}}</p>
                  </td>
                </tr>
                <tr *ngIf="container.consigneeReferences.any()">
                  <th>Consignee</th>
                  <td>
                    <p>{{container.consigneeReferences | rtStringArray}}</p>
                  </td>
                </tr>
                <tr *ngIf="container.pickupReferences.any()">
                  <th>Pickup From Party Reference</th>
                  <td>
                    <p>{{container.pickupReferences | rtStringArray}}</p>
                  </td>
                </tr>
                <tr *ngIf="container.deliveryReferences.any()">
                  <th>Deliver To Party Reference</th>
                  <td>
                    <p>{{container.deliveryReferences | rtStringArray}}</p>
                  </td>
                </tr>
                <tr *ngIf="container.notifyReferences.any()">
                  <th>Notify Party</th>
                  <td>
                    <p>{{container.notifyReferences | rtStringArray}}</p>
                  </td>
                </tr>

                </tbody>
              </table>
            </div>
          </rt-collapsible-box>

          <!-- ================ EVENTS ================ -->
          <rt-collapsible-box [openStateStoreAccessor]="boxStateAccessors.events"
                              [title]="'Events'"
                              [isempty]="container.events.length === 0">
            <div class="padding">
              <table class="table-clean events">
                <tbody>
                <tr *ngFor="let event of container.events">
                  <th>{{event.actualDate | rtDate}}</th>
                  <td>
                    <p>{{event.description}}</p>
                    <small *ngIf="event.address" [innerHTML]="event.address  | rtAddress">
                    </small>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </rt-collapsible-box>

          <!-- =========== SHIPMENT INFORMATION ========== -->
          <rt-collapsible-box [isempty]="false"
                              [openStateStoreAccessor]="boxStateAccessors.shipmentInformation"
                              [title]="'Shipment Information'">

            <div class="padding">
              <table class="table-clean">
                <tbody>
                <tr>
                  <th>
                    <ng-container *ngIf="container | rtIsAirShipment">Master Airway Bill</ng-container>
                    <ng-container *ngIf="container | rtIsAirShipment | not">Master Bill of Lading</ng-container>
                  </th>
                  <td>
                    {{container.masterBills | rtStringArray | rtTbd}}
                  </td>
                </tr>
                <tr>
                  <th>Bill of Lading</th>
                  <td>
                    <p *ngFor="let shipment of container.relatedShipments; last as lastShipment;" style="display:inline">
                      <span [rt-reference-link]="shipment"></span>
                      <span *ngIf="!lastShipment">{{', '}}</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>Röhlig References</th>
                  <td>
                    {{container.rohligReferences | rtStringArray | rtTbd}}
                  </td>
                </tr>
                <tr>
                  <th>Origin</th>
                  <td class="shipment-information-location"
                      [innerHTML]="container.origins | rtCityValue | rtTbd">
                  </td>
                </tr>
                <tr>
                  <th>Destination</th>
                  <td class="shipment-information-location"
                      [innerHTML]="container.destinations | rtCityValue | rtTbd">
                  </td>
                </tr>
                <tr>
                  <th>Port of Loading</th>
                  <td [innerHTML]="container.portsOfLoading | rtPortValue:container">
                  </td>
                </tr>
                <tr>
                  <th>Port of Discharge</th>
                  <td [innerHTML]="container.portsOfDischarge | rtPortValue:container">
                </tr>
                </tbody>
              </table>
              <!--              <rt-shipment-consolidation [shipment]="container"></rt-shipment-consolidation>-->
            </div>
          </rt-collapsible-box>

          <!-- ================ Goods ================ -->
          <rt-collapsible-box [isempty]="container | pureFunction:hasNoGoodsInformation"
                              [title]="'Goods'"
                              [openStateStoreAccessor]="boxStateAccessors.goods"
                              contentClasses="packing">
            <header>
              <table class="table-clean">
                <tbody>
                <tr>
                  <th>Pieces</th>
                  <td>{{container.goods | rtMerge:'packCount' | rtSum | rtInteger}}</td>
                </tr>

                <!--                <tr *ngIf="container | rtIsFclOrBuyersConsol | not ">-->
                <!--                  <th>Chargeable</th>-->
                <!--                  <td>{{container.goods.chargeable | rtDimension}}</td>-->
                <!--                </tr>-->
                <tr>
                  <th>Dangerous Goods</th>
                  <td [rt-dangerous-goods-list]="container.goods | rtMerge:'dangerousGoods' | rtFlatten | rtGetDangerousGoods">
                  </td>
                </tr>
                <tr>
                  <th>Goods Description</th>
                  <td>{{container.goods | rtMerge:'goodsDescription' | rtDistinct | rtStringArray}}</td>
                </tr>
                <tr>
                  <th>Marks &amp; Numbers</th>
                  <td>{{container.goods | rtMerge:'marksAndNumbers' | rtDistinct | rtStringArray}}</td>
                </tr>
                <tr *ngIf="container.goods[0]?.isOperatingReefer">
                  <th>Temperature</th>
                  <td>{{container.goods[0]?.temperature | rtDimension}}</td>
                </tr>
                </tbody>
              </table>
            </header>
            <ng-container *ngIf="container.goods | rtMerge:'packages' | rtFlatten | rtPacksWithInformation:container as packs">
              <ng-container *ngIf="packs | rtHasInformationOtherThanPackCount:container">
                <ng-container *ngIf="packs.length > 1">
                  <rt-shipment-package *ngFor="let pack of packs"
                                       [package]="pack"
                                       [shipment]="container"></rt-shipment-package>
                </ng-container>
                <ng-container *ngIf="packs.length === 1">
                  <rt-shipment-package *ngIf="packs[0].packCount > 1 || (packs[0] | rtHasInformationOtherThanPackCountVolumeWeight:container)"
                                       [package]="packs[0]"
                                       [shipment]="container"></rt-shipment-package>
                </ng-container>
              </ng-container>
            </ng-container>
          </rt-collapsible-box>

          <!-- ================ NOTES ================ -->
          <rt-collapsible-box [openStateStoreAccessor]="boxStateAccessors.notes"
                              [title]="'Notes'"
                              [isempty]="!container.notes.any()">
            <div class="padding wysiwyg notes">
              <p *ngFor="let note of container.notes">
                <span class="text-light">{{note | rtNoteTypeName}}</span><br>
                <rt-html-container [html]="note | rtNoteText | rtString"></rt-html-container>
              </p>
            </div>
          </rt-collapsible-box>

          <!-- ================ ORDERS ================ -->
          <rt-collapsible-box [isempty]="container.orders.length === 0 && container.orderReferences.length === 0"
                              [openStateStoreAccessor]="boxStateAccessors.orders"
                              [title]="'Orders'">
            <table class="table-default table-responsive" *ngIf="container.orders.length > 0">
              <thead>
              <tr>
                <th>Order Number</th>
                <th>Supplier</th>
                <th>Buyer</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let order of container.orders">
                <td data-header="Order Number" [rt-reference-link]="order"></td>
                <td data-header="Supplier">{{order.supplier | rtString}}</td>
                <td data-header="Buyer">{{order.buyer | rtString}}</td>
              </tr>
              </tbody>
            </table>
            <div class="padding" *ngIf="container.orderReferences.length">
              <table class="table-clean">
                <tbody>
                <tr>
                  <th>Order Numbers</th>
                  <td [innerHTML]="container.orderReferences | pureFunction:toOrderNumberList | rtString"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </rt-collapsible-box>

          <!-- ================ DOCUMENTS ================ -->
          <rt-collapsible-box *ngIf="hasDocumentRights$ | async"
                              [isempty]="container | pureFunction:hasNoDocuments"
                              [openStateStoreAccessor]="boxStateAccessors.documents"
                              [title]="'Documents'">
            <rt-shipment-documents [documents]="[container.documents, container.customsDeclarationDocuments]"
                                   [invoices]="(hasInvoiceDocumentRights$ | async) ? container.invoiceDocuments: []"></rt-shipment-documents>
          </rt-collapsible-box>

          <!-- ================ MAP ================ -->

          <rt-collapsible-box [openStateStoreAccessor]="boxStateAccessors.map"
                              [wide]="true"
                              [title]="'Map'">
            <rt-shipment-map [shipment]="container"></rt-shipment-map>
          </rt-collapsible-box>
        </div>
        <footer>
        </footer>
      </ng-container>
      <ng-template #skeletonView>
        <div class="summary no-shrink">
          <div class="static">
            <a routerLink="/Shipment" class="button back-button secondary"><span class="mat-icon-back"></span></a>
          </div>
          <div>
            <p><span class="shimmer-content" style="width: 60%;"></span></p>
            <p><span class="shimmer-content" style="width: 80%;"></span></p>
          </div>
          <div>
            <p><span class="shimmer-content" style="width:100%"></span></p>
            <p><span class="shimmer-content" style="width:40%"></span></p>
          </div>
          <div>
            <p><span class="shimmer-content" style="width:100%"></span></p>
            <p><span class="shimmer-content" style="width:40%"></span></p>
          </div>
          <div>
            <p><span class="shimmer-content" style="width:100%"></span></p>
            <p><span class="shimmer-content" style="width:40%"></span></p>
          </div>
          <div class="shrink">
            <p><span class="shimmer-content" style="width:100%"></span></p>
            <p><span class="shimmer-content" style="width:70%"></span></p>
            <p><span class="shimmer-content" style="width:30%"></span></p>
          </div>
        </div>
        <div #grid class="boxes grid grid-padding">
          <div class="grid-gutter" #gridGutter></div>
          <rt-skeleton-box class="skeleton-large"></rt-skeleton-box>
          <rt-skeleton-box></rt-skeleton-box>
          <rt-skeleton-box class="skeleton-large"></rt-skeleton-box>
          <rt-skeleton-box></rt-skeleton-box>
        </div>
      </ng-template>
    </section>
  </main>
</div>
