import {normalizeId} from "@utils/normalize-Id";
import {UserViewData} from "./user-view-data";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class UserEditViewData extends UserViewData {
  readonly userId: string | null;

  constructor();
  constructor(userId: string);
  constructor(userId?: string) {
    super();
    this.userId = normalizeId(userId);
  }

  isNewUser(): boolean {
    return this.userId === null;
  }

  hasNewInformationComparedTo(compare: this): boolean {
    const userIdChanged = this.userId !== compare.userId;
    return userIdChanged ;
  }

  get pageTitle(): string {
    if (this.isNewUser())
      return TitleBuilder.account.addUser;
    // if (this.hasDefinedUserName())
    //   return TitleBuilder.account.editUser(this.userName!);
    return TitleBuilder.account.users;
  }

  get url(): string {
    if (this.isNewUser())
      return UrlBuilder.account.addUser;
    return UrlBuilder.account.editUser(this.userId!);
  }

  readonly bodyClasses = ["page-add-user"];
}
