import {Injectable} from "@angular/core";
import {BookingStore} from "@app/stores/booking-store.service";
import {ShipmentStore} from "@app/stores/shipment-store.service";
import {OrderStore} from "@app/stores/order-store.service";
import {UserStore} from "@stores/user-store.service";
import {EntityStore} from "@stores/entity-store.service";
import {ContainerStore} from "@stores/container-store.service";

@Injectable({providedIn: "root"})
export class EntityStores {
  readonly userStore: UserStore;
  readonly entityStore: EntityStore;
  readonly shipmentStore: ShipmentStore;
  readonly orderStore: OrderStore;
  readonly bookingStore: BookingStore;
  readonly containerStore: ContainerStore;

  constructor(userStore: UserStore,
              entityStore: EntityStore,
              shipmentStore: ShipmentStore,
              orderStore: OrderStore,
              bookingStore: BookingStore,
              containerStore: ContainerStore) {
    this.bookingStore = bookingStore;
    this.orderStore = orderStore;
    this.shipmentStore = shipmentStore;
    this.entityStore = entityStore;
    this.userStore = userStore;
    this.containerStore = containerStore;
  }

}
