import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Authenticated, DefaultRedirect, NotAuthenticated} from "@guards";
import {ErrorRoutes, LoginRoutes, MainRoutes} from "@routes";
import {CommonModule} from "@angular/common";
import {transformRoutesObject} from "@app/utils/case-insensitive-route-matcher";
import {ErrorViewData, NoUserRightsViewData} from "@view-data";
import {MainMasterComponent} from "@master-components";
import {NoUserRightsComponent} from "@app/page-components/no-user-rights/no-user-rights.component";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    canActivate: [DefaultRedirect],
    component: MainMasterComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        canActivate: [DefaultRedirect],
        component: NoUserRightsComponent,
        data: {viewData: new NoUserRightsViewData()}
      }
    ]
  },
  {
    path: "",
    children: transformRoutesObject(LoginRoutes),
    canActivate: [NotAuthenticated]
  },
  {
    path: "",
    children: transformRoutesObject(MainRoutes),
    canActivate: [Authenticated]
  },
  {
    path: "",
    children: transformRoutesObject(ErrorRoutes)
  },
  {
    path: "**",
    redirectTo: new ErrorViewData("NotFound").url
  },
];

@NgModule({
            imports: [
              RouterModule.forRoot(routes, {
                enableTracing: true,
                relativeLinkResolution: 'legacy',
                onSameUrlNavigation: "reload",
                scrollPositionRestoration: "enabled"
              }),
              CommonModule],
            exports: [RouterModule]
          })
export class AppRoutingModule {

}
