import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {UserRightsStore} from "@stores";

@Injectable({
              providedIn: 'root'
            })
export class Authenticated implements CanActivate {
  private readonly _userRightsStore: UserRightsStore;
  private readonly _router: Router;

  constructor(userRightsStore: UserRightsStore, router: Router) {
    this._userRightsStore = userRightsStore;
    this._router = router;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._userRightsStore
               .observe("isAuthenticated")
               .pipe(map(isAuthenticated => {
                 if (isAuthenticated)
                   return true;
                 return this._router
                            .createUrlTree(['Account/Login'],
                                           {queryParams: {returnUrl: state.url}});
               }));
  }

}

