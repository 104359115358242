import {Injectable} from "@angular/core";
import {Store} from "@app/stores/store";
import {RoutingStoreState} from "@app/stores/routingStoreState";

@Injectable({providedIn: "root"})
export class RoutingStore extends Store<RoutingStoreState> {

  getName(): string {
    return "RoutingStore";
  }

  constructor() {
    super({},{});
  }

}
