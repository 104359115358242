import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {UserRightsStore} from "@stores";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {EntityListViewData, LoginViewData, OrderListViewData, SettingsViewData, ShipmentListViewData, UserListViewData} from "@view-data";
import {UserRightsStoreState} from "@stores/userRightsStoreState";

@Injectable({
              providedIn: 'root'
            })
export class DefaultRedirect implements CanActivate {
  protected readonly userRightsStore: UserRightsStore;
  private readonly _router: Router;

  constructor(userRightsStore: UserRightsStore, router: Router) {
    this.userRightsStore = userRightsStore;
    this._router = router;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userRightsStore
               .observe()
               .pipe(map(userRights => {
                 const isAuthenticated = userRights.isAuthenticated;
                 if (!isAuthenticated)
                   return this._router.createUrlTree([new LoginViewData().url]);
                 const returnUrl = route.queryParams["returnUrl"] as string;
                 return this.performRedirectForAuthenticatedUser(userRights, returnUrl);
               }));
  }

  protected performRedirectForAuthenticatedUser(userSessionInfo: Partial<UserRightsStoreState>, returnUrl: string): boolean | UrlTree {
    if (returnUrl)
      return this._router.createUrlTree([returnUrl]);
    if (userSessionInfo.shipments)
      return this._router.createUrlTree([new ShipmentListViewData().url]);
    if (userSessionInfo.orders)
      return this._router.createUrlTree([new OrderListViewData().url]);
    if (userSessionInfo.userManagement)
      return this._router.createUrlTree([new UserListViewData().url]);
    if (userSessionInfo.entityManagement)
      return this._router.createUrlTree([new EntityListViewData().url]);
    if (userSessionInfo.administration)
      return this._router.createUrlTree([new SettingsViewData().url]);
    return true;
  }
}


