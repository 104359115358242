import {
  CityLocation,
  DangerousGoodsData,
  DeliveryStatus,
  DimensionValue,
  PortLocation,
  Container,
  ShipmentEvent,
  ContainerGoods,
  TimelineLeg,
  TimelineLegType,
  TimelineLocation,
  TransportMode,
  ShipmentReference,
  ShipmentDocument,
  AddressLocation,
} from "@api";
import {GetDangerousGoodsPipe, IsContainerGroup} from "@app/utils/rt-date.pipe";
import {contains} from "jquery";

export interface DateContainer {
  actual: Date | null;
  estimated: Date | null;
}

export class ContainerOverviewItem {
  readonly arrivalDate: DateContainer | null;
  readonly chargeable: DimensionValue [];
  readonly clientReferences: string[];
  readonly consigneeLocations: AddressLocation[];
  readonly consigneeName: string[];
  readonly containerCount: number;
  readonly containerId: string;
  readonly containerModes: string[];
  readonly containerNumber: string;
  readonly customsEntryNumbers: string[];
  readonly dangerousGoods: Grouping<DangerousGoodsData, string>[];
  readonly deliveryDate: DateContainer | null;
  readonly deliveryLocations: AddressLocation[];
  readonly departureDate: DateContainer | null;
  readonly destinations: CityLocation[];
  readonly goodsDescriptions: string [];
  readonly hasDangerousGoods: boolean;
  readonly hidePorts: boolean;
  readonly houseBills: string[];
  readonly incoTerms: string[];
  readonly invoiceNumbers: string[];
  readonly isCustomsDeclaration: boolean;
  readonly lastEvent: ShipmentEvent | null;
  readonly orderNumbers: string[];
  readonly origins: CityLocation[];
  readonly pickupDate: DateContainer | null;
  readonly pickupLocations: AddressLocation[];
  readonly pieces: number;
  readonly portsOfDischarge: PortLocation[];
  readonly portsOfLoading: PortLocation[];
  readonly portTypes: "IATA" | "UNLOCO";
  readonly rohligReferences: string[];
  readonly sealNumber: string | null;
  readonly shipperLocations: AddressLocation[];
  readonly shipperNames: string[];
  readonly status: DeliveryStatus;
  readonly timelineLegs: TimelineLeg[];
  readonly transportModes: TransportMode[];
  readonly type: string;
  readonly vesselFlightNumbers: string[];
  readonly volume: DimensionValue [];
  readonly weight: DimensionValue [];


  constructor(container: Container) {
    const pickupTimelineLegLocation = ContainerOverviewItem._getPickupTimelineLegLocation(container);
    const deliveryTimelineLocation = ContainerOverviewItem._getDeliveryTimelineLegLocation(container);
    this.arrivalDate = this._getArrivalDate(container);
    this.chargeable = this.getContainerGoodItems(container.goods.map(c => c.chargeable));
    this.clientReferences = container.clientReferences;
    this.consigneeLocations = container.consigneeLocations;
    this.consigneeName = container.consigneeLocations.map(c => c.name);
    this.containerId = container.containerId;
    this.containerCount = container.relatedContainers.length + 1;
    this.containerModes = container.containerModes;
    this.containerNumber = container.containerNumber;
    this.customsEntryNumbers = container.customsEntryNumbers;
    this.dangerousGoods = ContainerOverviewItem._getDangerousGoodsList(container.goods)
    this.deliveryDate = ContainerOverviewItem._getDateFromTimeLineLocation(deliveryTimelineLocation);
    this.deliveryLocations = container.deliveryLocations;
    this.departureDate = this._getDepartureDate(container);
    this.destinations = container.destinations;
    this.goodsDescriptions = container.goods.map(d => d.goodsDescription);
    this.hasDangerousGoods = this.dangerousGoods.any();
    this.hidePorts = container.transportModes.contains(TransportMode.Courier) || container.transportModes.contains(TransportMode.Road);
    this.houseBills = container.houseBills;
    this.incoTerms = container.incoterms;
    this.invoiceNumbers = container.invoiceDocuments.map(i => i.invoiceNumber).distinct();
    this.isCustomsDeclaration = container.isCustomsDeclaration;
    this.lastEvent = container.events.filter(e => !!e.actualDate).last();
    this.orderNumbers = ContainerOverviewItem._getOrderNumbers(container);
    this.origins = container.origins;
    this.pickupDate = ContainerOverviewItem._getDateFromTimeLineLocation(pickupTimelineLegLocation)
    this.pickupLocations = container.pickupLocations;
    this.pieces = this.getPieceCount(container.goods);
    this.portsOfDischarge = container.portsOfDischarge;
    this.portsOfLoading = container.portsOfLoading;
    this.portTypes = container.transportModes.contains(TransportMode.Air) ? "IATA" : "UNLOCO";
    this.rohligReferences = container.rohligReferences;
    this.sealNumber = container.sealNumber;
    this.shipperLocations = container.shipperLocations;
    this.shipperNames = container.shipperLocations.map(s => s.name);
    this.status = container.status;
    this.timelineLegs = container.timelineLegs;
    this.transportModes = container.transportModes;
    this.type = container.type;
    this.vesselFlightNumbers = this.getVesselFlightNumbers(container.timelineLegs);
    this.volume = this.getContainerGoodItems(container.goods.map(v => v.volumes).flat());
    this.weight = this.getContainerGoodItems(container.goods.map(g => g.grossWeight))
  }

  private static _getDeliveryTimelineLegLocation(container: Container) {
    const lastTimelineLeg = container.timelineLegs.last();
    return lastTimelineLeg?.type === TimelineLegType.Delivery
           ? lastTimelineLeg.endLocation
           : null;
  }

  private static _getPickupTimelineLegLocation(container: Container) {
    const lastTimelineLeg = container.timelineLegs.firstOrDefault();
    return lastTimelineLeg?.type === TimelineLegType.Pickup
           ? lastTimelineLeg.startLocation
           : null;
  }

  private static _getDateFromTimeLineLocation(location: TimelineLocation | undefined): DateContainer | null {
    return {
      actual: location?.actualDate || null,
      estimated: location?.estimatedDate || null,
    }
  }

  private static _getDangerousGoodsList(goods: ContainerGoods[]): Grouping<DangerousGoodsData, string>[] {
    return new GetDangerousGoodsPipe().transform(goods.map(d => d.dangerousGoods).flat());
  }

  private static _getClientReferences({consigneeReferences, deliveryReferences, notifyReferences, pickupReferences, shipperReferences}: Container): string[] {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
    return shipperReferences.concat(consigneeReferences, notifyReferences, pickupReferences, deliveryReferences)
  }

  // noinspection ParameterNamingConventionJS
  private static isSamePort(port1: PortLocation [], port2: PortLocation) {
    if (!port1 || !port2)
      return false;
    for (const port of port1) {
      if (port.unloco && port.unloco === port2.unloco)
        return true;
      if (port.iataCode && port.iataCode === port2.iataCode)
        return true;
      return port.name && port.name.toLowerCase() === port2.name?.toLowerCase()
    }
  }

  private static _getOrderNumbers(container: Container) {
    return container.orderReferences
                    .concat(container.orders.map(o => o.orderNumber))
                    .distinct();
  }

  private getContainerGoodItems(values: DimensionValue[]) {
    return values
      .filter(w => !!w?.value)
      .groupBy(w => w.dimension)
      .map(g => {
        return {
          value: g.items.sum(w => w.value),
          dimension: g.key
        }
      });
  }

  private getPieceCount(containerGoods: ContainerGoods[]) {
    return containerGoods.sum(g => g.packCount);
  }

  private getVesselFlightNumbers(timelineLegs: TimelineLeg[]) {
    return timelineLegs
      .map(l => {
        if (l.type === TimelineLegType.Sea)
          return l.vessel;
        if (l.type === TimelineLegType.Air)
          return l.flightNumber;
        return null;
      })
      .filter(n => !!n)
      .distinct();
  }

  private _getArrivalDate(container: Container) {
    const endLocation =
      container.timelineLegs.filter(t => ContainerOverviewItem.isSamePort(container.portsOfDischarge, t.endLocation.port)).last()?.endLocation;
    return ContainerOverviewItem._getDateFromTimeLineLocation(endLocation);
  }

  private _getDepartureDate(container: Container) {
    const startLocation =
      container.timelineLegs
               .filter(l => ContainerOverviewItem.isSamePort(container.portsOfLoading, l.startLocation.port)).last()?.startLocation;
    return ContainerOverviewItem._getDateFromTimeLineLocation(startLocation);
  }
}
