<div class="">
  <div class="page-wrap">
    <main>
      <section class="padding">
        <div class="order-box-list real-order">
          <div class="search-bar button-container">
            <span class="title">Orders</span>
            <div class="search-input">
              <div class="grid">
                <p class="category-select">
                  <select class="select-button"
                          ngSelect
                          [storeAccessor]="selectedReferenceType"
                          [items]="referenceTypeSearchItems"
                          [settings]='{"allowNoSelection":false, "class": "search-select droplist-button width-182px-important", "noScroll":true}'
                          data-display-name="Search By">
                  </select>
                </p>
                <button class="button mat-icon-search text-hide" (click)="startSearch()">Search</button>
                <p class="input-search">
                  <input name="PrimarySearchObject.PrimarySearchNumber"
                         type="search"
                         [placeholder]="(showMobileSearchHint$ | async)
                              ? (mobileSearchHint$ | async)
                              : 'Search Orders'"
                         (keydown.enter)="startSearch()"
                         [ngModel]="selectedReference.observe | async"
                         (ngModelChange)="selectedReference.set($event)"/>
                </p>
              </div>
            </div>
            <div class="button-group grow space-below">
              <div type="button"
                   class="button secondary fake-select filters-toggle"
                   [ngClass]="{'active': filtersOpen.observe | async}"
                   (click)="toggleFiltersOpenState()"
                   [attr.data-filters-count]="(filterCount$ | async ) || null ">
                <span>Filters</span>
                <span *ngIf="filterCount$ | async as filterCount" class="filter-count" [attr.data-filters-count]="filterCount"></span>
              </div>
              <select id="select-filter-preset"
                      class="button secondary select-button"
                      [rtFilterPresets]="'order'">
              </select>
            </div>
            <div class="button-group phone-block">
              <span class="double-button" *ngIf="hasExportToExcelRights$ | async">
            <button
              id="b-export-to-excel"
              class="button configure-button secondary"
              [disabled]="canStartNewExcelReport$ | async | not"
              (click)="startNewExcelReport()">Export to Excel</button>
            <span class="button secondary mat-icon-settings dropdown-toggle" id="export-settings-toggle" tabindex="0">
                <select id="export-settings"
                        name="exportSettings"
                        data-display-name="Export Settings"
                        ngSelect
                        [storeAccessor]="exportToExcelModeAccessor"
                        [items]="excelExportItems"
                        [settings]="excelExportSettings">
                </select>
            </span>
          </span>
            </div>
          </div>


          <rt-order-search-bar></rt-order-search-bar>

          <div class="order-box-line-container header" *ngIf="(displayListHeader$ | async) || (isSearching$ | async)">
            <div class="order-box-line">
              <div class="cell">Order Number</div>
              <div class="spacing-helper"></div>
              <div class="cell">Status</div>
              <div class="spacing-helper"></div>
              <div class="cell large hide-4">Supplier</div>
              <div class="spacing-helper"></div>
              <div class="cell large hide-3">Buyer</div>
              <div class="spacing-helper"></div>
              <div class="cell hide-2">Order Date</div>
              <div class="spacing-helper"></div>
              <div class="cell hide-2"></div>
              <div class="spacing-helper"></div>
            </div>
            <div class="clearfix"></div>
          </div>

          <div class="ajax-target clearfix">

            <ng-container *ngIf="isSearching$ | async; else searchResults">
              <div class="order-box-line-container" *ngFor="let item of [].constructor(skeletonItemCount);">
                <div class="order-box-line">
                  <div class="cell"><span class="shimmer-content" style="width:50%"></span></div>
                  <div class="spacing-helper"></div>
                  <div class="cell status"><span class="shimmer-content" style="width:50%"></span></div>
                  <div class="spacing-helper"></div>
                  <div class="cell large hide-4"><span class="shimmer-content" style="width:50%"></span></div>
                  <div class="spacing-helper"></div>
                  <div class="cell large hide-3"><span class="shimmer-content" style="width:50%"></span></div>
                  <div class="spacing-helper"></div>
                  <div class="cell hide-2"><span class="shimmer-content" style="width:50%"></span></div>
                  <div class="spacing-helper"></div>
                  <div class="cell hide-2">
                    <span class="shimmer-content" style="width:30%"></span>
                  </div>
                  <div class="spacing-helper"></div>
                </div>
                <div class="clearfix"></div>
              </div>
            </ng-container>

            <ng-template #searchResults>
              <ng-container *ngIf="orders$ | async as orders;">
                <div class="order-box-line-container" *ngFor="let order of orders; trackBy: trackById ">
                  <a class="order-box-line no-focus-highlight"
                     routerLink="/Order/OrderDetail"
                     [queryParams]="{pk: order.id}">
                    <div class="cell" [innerHTML]="order.orderNumber | rtTbd"></div>
                    <div class="spacing-helper"></div>
                    <div class="cell status" [ngClass]="order.consolidatedStatus | rtOrderStatusTextColorClass">{{order.consolidatedStatus | rtOrderStatusName}}</div>
                    <div class="spacing-helper"></div>
                    <div class="cell large hide-4">{{order.supplier?.name | rtString | unescapeHtml}}</div>
                    <div class="spacing-helper"></div>
                    <div class="cell large hide-3">{{order.buyer?.name | rtString | unescapeHtml}}</div>
                    <div class="spacing-helper"></div>
                    <div class="cell hide-2">{{order.orderDate | rtDate}}</div>
                    <div class="spacing-helper"></div>
                    <div class="cell hide-2" *ngIf="order.transportModes">
                      <div class="label">{{order.transportModes.join(" / ")}}</div>
                    </div>
                    <div class="spacing-helper"></div>
                  </a>
                  <div class="clearfix"></div>
                </div>
              </ng-container>
            </ng-template>

            <p *ngIf="noResultsFound$ | async" class="no-results-found">No orders found</p>

            <p class="load-more" *ngIf="showLoadMoreButton$ | async" [ngClass]="{loading: isLoadingMore$ | async}">
              <button class="button"
                      (click)="loadMore()"
                      *ngIf="showLoadMoreButton$ | async"
                      [disabled]="isLoadingMore$ | async">Load more
              </button>
            </p>
          </div>
        </div>
      </section>
    </main>
  </div>
</div>

<div style="display: none;">
  <fieldset id="filterPresetsControls">
    <legend>Save Preset</legend>
    <label for="droplist-filterpreset-name">Preset Name</label>
    <p class="input-group input-search">
      <input id="droplist-filterpreset-name" class="save-filter-preset" type="text">
      <button class="button mat-icon-save text-hide save-filter-preset"></button>
    </p>
  </fieldset>
</div>
