import * as Utils from "../utils/Utils";
import {FormValidator, ValidationField} from "./Validate";
import {Select} from "./Select";
import {Site} from "../Site";
import {Observable} from "rxjs";
import {JsonAddress, JSONData} from "@api";

require("../utils/JqueryLoader");

export interface IAddressEditOptions {
  addressTypeSelectionEnabled?: boolean;
  entities$?: Observable<JSONData[]>;
  newAddressTemplate?: FunctionOrValue<Partial<JsonAddress>>;
  allowEntitySelection?:  () => boolean;
  entitySetId?: () => string;
  $header?: JQuery;
  countryWarning?: FunctionOrValue<string>;
}

export class AddressEdit {
  static template = `
<form class="address-edit preserve-error-margin" novalidate>
    <h2 id="header">Add Address</h2>
    <input type="hidden" name="code"/>
    <div class="form-section">
        <div class="form-group" style="margin-top: 0;">
            <span class="form-label">
                <label for="Name">(Company) Name</label>
            </span>
            <input name="name" id="Name" type="text" maxlength="100" required data-display-name="(Company) Name"/>
        </div>
        <div class="form-group">
            <span class="form-label">
                <label for="Address1">Address</label>
            </span>
            <input name="address1" id="Address1" type="text" maxlength="100" required/>
            <input name="address2" type="text" maxlength="100"/>
        </div>
        <div class="form-group">
            <span class="form-label">
                <label for="City">City</label>
            </span>
            <input name="city" id="City" type="text" maxlength="100" required/>
        </div>
            <div class="form-group">
                <span class="form-label">
                    <label for="Country">Country</label>
                </span>
                <select name="country" id="Country" required></select>
            </div>
        <div class="form-group-wrapper">
            <div class="form-group small">
                <span class="form-label">
                    <label for="State" class="optional">State</label>
                </span>
                <select name="state" id="State"></select>
            </div>
        <div class="form-group small">
            <span class="form-label">
                <label for="PostCode">Postcode</label>
            </span>
            <input name="postCode" id="PostCode" type="text" maxlength="50" required/>
        </div>
        </div>
    </div>
    <div class="form-section">
        <h3>Address Details</h3>
        <div class="form-group">
            <span class="form-label">
                <label for="DisplayName" class="optional">Display Name</label>
            </span>
            <input name="displayName" id="DisplayName" type="text" maxlength="200"/>
            <small class="text-light">This name will be displayed when searching for an address</small>
        </div>
        <div class="form-group">
            <input type="radio" id="isExternalAddress" name="addressType" required data-display-name="Address Type" data-exclude-from-json="true"/>
            <label for="isExternalAddress">External address (business partner)</label>
            <input type="radio" id="isInternalAddress" name="addressType" data-exclude-from-json="true"/>
            <label for="isInternalAddress">Internal address (my company)</label>
        </div>
        <div class="form-group" style="display: hidden;">
            <span class="form-label">
                <label for="SitePK">Entity</label>
            </span>
            <select name="sitePK" id="SitePK" required></select>
        </div>
    </div>
    <span style="display: block; text-align: right; font-size:12px" class="text-light" id="lastEdit"></span>
</form>
`;


  private static countriesWithoutPostcodes = ["AO", "AG", "AW", "BS", "BZ", "BJ", "BW", "BF", "BI", "CM", "CF", "KM", "CG", "CD", "CK", "CI", "DJ", "DM", "GQ",
                                              "ER", "FJ", "TF", "GM", "GH", "GD", "GN", "GY", "HK", "IE", "JM", "KE", "KI", "MO", "MW", "ML", "MR", "MU", "MS",
                                              "NR", "AN", "NU", "KP", "PA", "QA", "RW", "KN", "LC", "ST", "SC", "SL", "SB", "SO", "ZA", "SR", "SY", "TZ", "TL",
                                              "TK", "TO", "TT", "TV", "UG", "AE", "VU", "YE", "ZW", "CL"];

  private static addressEditCount = 1;
  private static cachedStates: IIndexable<JSONData[]> = {};
  protected readonly $element: JQuery;
  protected readonly $header: JQuery;
  protected readonly $lastEdit: JQuery;
  protected readonly rbtnIsExternalAddress: HTMLInputElement;
  protected readonly rbtnIsInternalAddress: HTMLInputElement;
  protected readonly countrySelect: Select;
  protected readonly stateSelect: Select<JSONData>;
  protected readonly $entitySelect: JQuery;
  protected readonly entitySelect: Select<JSONData>;
  protected readonly $displayName: JQuery;
  protected readonly $name: JQuery;
  protected readonly validator: FormValidator;
  protected isLoadingAddress = false;
  protected addressEditCount = AddressEdit.addressEditCount++;
  protected lastLoadedAddress: Partial<JsonAddress>;
  private readonly $lblPostCode: JQuery;
  private readonly postCodeValidationField: ValidationField;
  private entities: JSONData[];
  private options: IAddressEditOptions;
  _initialState: JsonAddress = {} as any;

  constructor(protected readonly container: HTMLElement | JQuery, options?: IAddressEditOptions) {
    this.options = options || {};
    const wrapper = document.createElement("div");
    wrapper.innerHTML = AddressEdit.template;
    this.$element = $(wrapper.children[0])
      .data("address-edit", this);
    this.validator = new FormValidator(this.$element);
    this.$header = this.$element.find("#header");
    if (this.options.$header) {
      this.$header.remove();
      this.$header = this.options.$header;
    }
    this.$lastEdit = this.$element.find("#lastEdit");
    this.$entitySelect = this.$element.find("#SitePK");
    this.$lblPostCode = this.$element.find("label[for=PostCode]");
    this.postCodeValidationField = ValidationField.getValidationField(this.$element.find("#PostCode")[0] as HTMLInputElement);
    this.rbtnIsExternalAddress = ((this.$element.find("#isExternalAddress")[0]) as HTMLInputElement);
    this.rbtnIsExternalAddress.addEventListener("change", () => {
      this.onAddressTypeSelectionChanged();
    });
    this.rbtnIsInternalAddress = ((this.$element.find("#isInternalAddress")[0]) as HTMLInputElement);
    this.rbtnIsInternalAddress.addEventListener("change", () => {
      this.onAddressTypeSelectionChanged();
    });
    this.countrySelect = new Select(this.$element.find("#Country")[0] as HTMLSelectElement,
                                    {
                                      displayIcon: true,
                                      "class": "flags",
                                      itemClass: "flags",
                                      closeOnSelect: true,
                                      search: true,
                                      container: this.$element,
                                      autoPosition: true,
                                      autoChangeDirection: false,
                                      noTouchButtons: true,
                                      allowGrowing: () => !document.body.classList.contains("viewport-320")
                                    });
    this.countrySelect.onSelectionChanged.on(
      e => {
        this.onCountrySelectionChanged(e.addedItems.length ? e.addedItems[0].value : null);
        this.countrySelect.$fakeSelect.nextAll(".warning-message").remove();
        if (e.addedItems.length && e.addedItems[0].ajaxData.showWarningOnSelect) {
          const message = Utils.UnwrapFunction(this.options.countryWarning);
          if (message)
            this.countrySelect.$fakeSelect.after(`<span class="warning-message">${message}</span>`);
        }
      });
    this.stateSelect = new Select(this.$element.find("#State")[0] as HTMLSelectElement,
                                  {
                                    closeOnSelect: true,
                                    search: true,
                                    container: this.$element,
                                    autoPosition: false,
                                    autoChangeDirection: false,
                                    //alignment: () => document.body.classList.contains("viewport-320") ? Alignment.Stretch : Alignment.Start,
                                    allowGrowing: () => !document.body.classList.contains("viewport-320")
                                  });
    this.countrySelect.processJsonData(AddressEdit.countries);
    if (this.lastLoadedAddress && this.lastLoadedAddress.country) {
      this.isLoadingAddress = true;
      this.countrySelect.selectItems(
        this.countrySelect.getItems()
            .firstOrDefault(i => i.value === this.lastLoadedAddress.country),
        true,
        true);
      this.isLoadingAddress = false;
    }
    this.countrySelect.renderItems(true)

    this.$name = $("#Name", this.$element);
    const $city = $("#City", this.$element);
    this.$displayName = $("#DisplayName", this.$element);
    this.$name.add($city)
        .on("input",
            () => {
              if (this.rbtnIsInternalAddress.checked)
                this.$displayName.prop("placeholder", [this.$name.val(), $city.val()].join(" - ", true));
              else if (this.rbtnIsExternalAddress.checked)
                this.$displayName.prop("placeholder", this.$name.val());
            });

    const entitySelect = new Select(this.$entitySelect[0] as HTMLSelectElement,
                                    {
                                      container: this.$element,
                                      allowNoSelection: false,
                                      closeOnSelect: true
                                    });
    this.entitySelect = entitySelect;

    Site.parseContent(this.$element[0]);
    this.options.entities$.subscribe(entities => this.entitiesChanged(entities))
    this.$element.find("label")
        .each((index, elem: HTMLLabelElement) => {
          let control = this.$element[0].querySelector(`#${elem.htmlFor}`) as HTMLElement;
          if (!control)
            return;
          const newId = `addressEdit${this.addressEditCount}_${control.id}`;
          control.id = newId;
          elem.htmlFor = newId;
        });

    $(container)
      .data("address-edit", this)
      .append(this.$element);
    this.addressTypeSelectionEnabled = this.options.addressTypeSelectionEnabled;
  }

  // ReSharper disable once InconsistentNaming
  private static _countries: JSONData[];

  static get countries() {
    if (!AddressEdit._countries)
      throw "Countries not loaded yet";
    return AddressEdit._countries;
  }

  static set countries(countries) {
    this._countries = countries;
  }

  get hasChanged(): boolean {
    return this._initialState.name != this.address.name ||
           this._initialState.address1 != this.address.address1 ||
           this._initialState.address2 != this.address.address2 ||
           this._initialState.city != this.address.city ||
           this._initialState.country != this.address.country ||
           this._initialState.state != this.address.state ||
           this._initialState.postCode != this.address.postCode ||
           this._initialState.displayName != this.address.displayName;
  }

  get address(): Partial<JsonAddress> {
    const address = Utils.ConvertFormToJson<JsonAddress>(this.$element);
    address.isExternalAddress = this.rbtnIsExternalAddress.checked;
    address.isInternalAddress = this.rbtnIsInternalAddress.checked;
    address.primarySitePK = this.options.entitySetId();
    address.state = address.state ? address.state : "";
    address.country = address.country ? address.country : "";
    if (String.isFilled(address.country))
      address.countryName = this.countrySelect.getSelectedItems()[0].ajaxData.label;
    if (String.isFilled(address.state))
      address.stateName = this.stateSelect.getSelectedItems()[0].ajaxData.label;
    return address;
  }

  set address(value: Partial<JsonAddress> | null) {
    this.isLoadingAddress = true;
    this.validator.disableValidation = true;
    this.lastLoadedAddress = value;
    if (!value && this.options.newAddressTemplate)
      value = Utils.UnwrapFunction(this.options.newAddressTemplate);
    Utils.ConvertJsonToForm(this.$element, value);
    if (value) {
      if (value.createDateString && value.createDateString !== "-") {
        this.$lastEdit.text(
          `Last edited on ${value.createDateString} by ${value.createUser}`);
        this.$header.text("Edit Address");
      } else {
        this.$lastEdit.text("");
        this.$header.text("Add Address");
      }
      this.rbtnIsExternalAddress.checked = value.isExternalAddress;
      this.rbtnIsInternalAddress.checked = value.isInternalAddress;
      if (!value.sitePK && this.entities.length === 1) {
        this.entitySelect.selectItems(this.entitySelect.getCurrentItems(), true, true);
      }
    } else {
      this.$header.text("Add Address");
      this.$lastEdit.text("");
      this.rbtnIsExternalAddress.checked = false;
      this.rbtnIsInternalAddress.checked = false;
      if (this.entities.length === 1) {
        this.entitySelect.selectItems(this.entitySelect.getCurrentItems(), true, true);
      }
    }
    if (!this.addressTypeSelectionEnabled) {
      this.rbtnIsExternalAddress.disabled = !this.rbtnIsExternalAddress.checked;
      this.rbtnIsInternalAddress.disabled = !this.rbtnIsInternalAddress.checked;
    }
    this.$name.trigger("input");
    this.onAddressTypeSelectionChanged();
    this.validator.disableValidation = false;
    this.isLoadingAddress = false;
    if (value)
      this.onCountrySelectionChanged(value.country, value.state);
    else
      this.onCountrySelectionChanged(null, null);
    this.validator.resetValidation();
  }

  private _addressTypeSelectionEnabled: boolean = true;

  get addressTypeSelectionEnabled(): boolean {
    return this._addressTypeSelectionEnabled;
  }

  set addressTypeSelectionEnabled(value: boolean) {
    this._addressTypeSelectionEnabled = value;
    if (value) {
      this.rbtnIsExternalAddress.disabled = false;
      this.rbtnIsInternalAddress.disabled = false;
      this.rbtnIsExternalAddress.classList.remove("disabled");
      this.rbtnIsInternalAddress.classList.remove("disabled");
      this.entitySelect.enable();
    } else {
      this.rbtnIsExternalAddress.disabled = !this.rbtnIsExternalAddress.checked;
      this.rbtnIsInternalAddress.disabled = !this.rbtnIsInternalAddress.checked;
      this.rbtnIsExternalAddress.classList.add("disabled");
      this.rbtnIsInternalAddress.classList.add("disabled");
      this.entitySelect.disable();
    }
  }

  get isValid() {
    return this.validator.validate();
  }

  focus() {
    window.setTimeout(() => this.$element.find("input[type=text]:first").focus(), 100);
  }

  resetValidation() {
    this.validator.resetValidation();
  }

  private entitiesChanged(entities: JSONData[]) {
    this.entities = entities;
    this.entitySelect.clearItems();
    this.entitySelect.processJsonData(entities);
    this.entitySelect.renderItems(true);
    this.entitySelect.selectItems(this.entitySelect.getCurrentItems()[0], undefined, true);
    if (!this.options.allowEntitySelection()) {
      this.entitySelect.disable(true);
    } else if (entities.length === 1) {
      this.entitySelect.disable();
    }
  }

  private async onCountrySelectionChanged(country: string, state?: string) {
    if (this.isLoadingAddress)
      return;
    if (String.isNullOrEmpty(country)) {
      this.stateSelect.disable();
      this.stateSelect.clearItems();
      this.stateSelect.renderPlaceholderText("Not Available");
      this.$lblPostCode.removeClass("optional");
      this.postCodeValidationField.validateData.validators.required.isActive = true;
      return;
    }
    if (AddressEdit.cachedStates.hasOwnProperty(country)) {
      this.stateSelect.unselectItems(true, true);
      this.stateSelect.processJsonData(AddressEdit.cachedStates[country]);
      if (this.stateSelect.getCurrentItems()
        .length) {
        this.stateSelect.enable();
        this.stateSelect.renderPlaceholderText();
      } else {
        this.stateSelect.disable();
        this.stateSelect.renderPlaceholderText("Not Available");

      }
    } else {
      try {
        this.stateSelect.disable();
        if (window.isOffline) {
          window.addEventListener("online",
                                  () => this.onCountrySelectionChanged(country, state),
                                  {once: true});
          return;
        }
        this.stateSelect.$fakeSelect.addClass("loading");
        const states: JSONData[] = await $.get(
          window.baseApplicationUrl + "/WebMethod/GetStatesForCountry",
          {iso2Country: country});
        this.stateSelect.unselectItems();
        this.stateSelect.processJsonData(states);
        AddressEdit.cachedStates[country] = states;
        if (states.length) {
          this.stateSelect.enable();
          this.stateSelect.renderPlaceholderText();
        } else
          this.stateSelect.renderPlaceholderText("Not Available");
      } catch (e) {
        console.error(e);
      } finally {
        this.stateSelect.$fakeSelect.removeClass("loading");
      }
    }
    if (AddressEdit.countriesWithoutPostcodes.contains(country)) {
      this.$lblPostCode.addClass("optional");
      this.postCodeValidationField.validateData.validators.required.isActive = false;
      this.postCodeValidationField.resetValidation();
    } else {
      this.$lblPostCode.removeClass("optional");
      this.postCodeValidationField.validateData.validators.required.isActive = true;
    }
    if (String.isFilled(state)) {
      for (let item of this.stateSelect.getCurrentItems()) {
        if (item.value === state) {
          this.stateSelect.selectItems(item, true, true);
          break;
        }
      }
    }
  }

  private onAddressTypeSelectionChanged() {
    if (this.rbtnIsInternalAddress.checked) {
      this.$entitySelect
          .parent()
          .show();
      if (this.entities.length === 1)
        this.entitySelect.disable();
      else if (this.options.allowEntitySelection)
        this.entitySelect.enable();
    } else {
      this.$entitySelect
          .parent()
          .hide();
      this.entitySelect.disable(true);
    }
    this.$element.find(":text:first").trigger("input");
  }
}

declare global {
  interface JQuery {
    addressEdit(): JQuery;
  }
}

jQuery.fn.addressEdit = function (this: JQuery) {
  return this.each((index, elem) => {
    if ($(elem).data("address-edit"))
      return;
    // ReSharper disable once WrongExpressionStatement
    new AddressEdit(elem as HTMLElement);
  });
};
