import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {ReplaySubject} from "rxjs";
import {EntityEditViewData, EntityListViewData, UserDetailViewData, UserListViewData} from "@view-data";
import {AlertService} from "@services/alert.service";
import {RoutingService} from "@app/services/routing.service";

interface FormPostSubmit {
  targetUrl: string;
  formData?: FormData;
}

@Injectable({
              providedIn: 'root'
            })
export class FormSubmitService {
  static readonly replaySubject: ReplaySubject<FormPostSubmit> = new ReplaySubject<FormPostSubmit>();
  private readonly _httpClient: HttpClient;
  private readonly _routingService: RoutingService;
  private readonly _alertService: AlertService;

  constructor(httpClient: HttpClient, routingService: RoutingService, alertService: AlertService) {
    this._httpClient = httpClient;
    this._routingService = routingService;
    this._alertService = alertService;
    FormSubmitService.replaySubject.subscribe(d => this._handleFormSubmit(d))
  }


  private async _handleFormSubmit(formPostSubmit: FormPostSubmit) {
    const response = await this._httpClient
                               .post(formPostSubmit.targetUrl, formPostSubmit.formData, {observe: "response"}).toPromise()
    if (response.ok)
      this.handleResponse(response);
  }

  private handleResponse(response: HttpResponse<unknown>) {
    const url = response.url;
    if (url.contains("/DeleteSite"))
      this._handleDeletedEntitySet(response.body as any);
    else if (url.contains("/DeleteUser"))
      this._handleDeletedUser(response.body as { deletedUser: string });
    else if (url.contains("/SaveUser") || url.contains("/AddUser"))
      this._handleSavedUser(response.body as any);
    // else if (url.contains("MyProfile"))
    //   this._routingService.requestReload();
    else if (url.contains("SiteManagement/Views/Save"))
      this._handleEntitySave(response.body as any);
    // else if (url.contains("SiteManagement/SiteDetails"))
    //   this._routingService.requestReload();
  }

  private _handleEntitySave({id, siteName}:{id: string, siteName: string}) {
    const currentViewData = this._routingService.getCurrentViewData();

    if (currentViewData instanceof EntityEditViewData && currentViewData.entityId === id)
      this._routingService.requestReload(currentViewData);
    this._routingService.navigateTo(new EntityEditViewData(id))
  }

  private _handleDeletedUser({deletedUser}: { deletedUser: string }) {
    this._routingService.navigateTo(new UserListViewData());
    this._alertService.openDefaultSuccessAlert(`User <b>${deletedUser}</b> was deleted.`)
  }

  private _handleDeletedEntitySet({name}: { name: string }) {
    this._routingService.navigateTo(new EntityListViewData());
    this._alertService.openDefaultSuccessAlert(`Entity <b>${name}</b> was deleted.`)
  }

  private _handleSavedUser({
                             mailAddress,
                             passwordMailSent,
                             username,
                             welcomeMailSent,
                             created,
                             userId
                           }: { username: string; mailAddress: string; passwordMailSent: boolean; welcomeMailSent: boolean, created: boolean, userId: string }) {
    this._routingService.navigateTo(new UserDetailViewData(userId))
    if (welcomeMailSent && passwordMailSent)
      this._alertService.openDefaultSuccessAlert("Welcome and Password Mails have been sent.");
    else if (welcomeMailSent)
      this._alertService.openDefaultSuccessAlert("Welcome Mail has been sent.");
    else if (passwordMailSent)
      this._alertService.openDefaultSuccessAlert("Password Mail has been sent.");
    else if (created)
      this._alertService.openDefaultSuccessAlert(`User ${mailAddress} created.`);
  }
}
