<a
  *ngIf="boxLayout$ | async as boxLayout"
        class="no-focus-highlight"
        routerLink="/Shipment/ShipmentDetail" [queryParams]="{JobID:shipment.id, ObjectType:shipment.jobType | rtShipmentTypeUrlParam}">
    <header>
        <h3>
            <ng-container *ngIf="shipment.isAir">Airway Bill</ng-container>
            <ng-container *ngIf="!shipment.isAir">Bill of Lading</ng-container>
            <strong>{{shipment.billOfLading | rtTbd }}</strong>
        </h3>
        <p class="status" [ngClass]="shipment | rtDeliveryStatusTextColorClass">{{shipment.status | rtDeliveryStatus}}</p>
    </header>
    <div class="progress">
        <rt-shipment-list-timeline [shipment]="shipment"></rt-shipment-list-timeline>
        <p *ngIf="shipment.lastEvent" class="description">
            {{shipment.lastEvent.description}}, <span class="text-light">{{shipment.lastEvent.actualDate | rtDate}}</span>
        </p>
        <p *ngIf="!shipment.lastEvent" class="description">&nbsp;</p>
    </div>
    <div class="informations"
         [ngClass]="{empty: !boxLayout.length}"
         [innerHTML]="shipment | pureFunction:renderFields:boxLayout:renderer">
    </div>
    <div class="labels">
        <p *ngFor="let transportMode of shipment.transportModes" class="label">{{transportMode}}</p>
        <p *ngIf="shipment.containerMode" class="label">{{shipment.containerMode}}</p>
        <p *ngIf="shipment.isCustomsDeclaration" class="label">Customs Clearance Only</p>
        <p *ngIf="shipment.dangerousGoods.length" class="label">Dangerous Goods</p>
        <p *ngIf="shipment.isLead" class="label">Lead</p>
    </div>
</a>
