import {Component} from '@angular/core';

@Component({
             selector: 'rt-login-master',
             templateUrl: './login-master.component.html',
             styleUrls: ['./login-master.component.scss']
           })
export class LoginMasterComponent {

}
