import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";
import {PreLoginViewData} from "@services/view-data";

export class ChangePasswordViewData extends PreLoginViewData {
  readonly requestId: string | null;

  constructor();
  constructor(requestId: string) ;
  constructor(requestId?: string) {
    super();
    this.requestId = requestId || null;
  }

  hasRequestId(): boolean {
    return this.requestId !== null;
  }

  hasNewInformationComparedTo(compare: this): boolean {
    const requestIdChanged = this.requestId !== compare.requestId;
    return requestIdChanged
  }

  get pageTitle(): string {
    return TitleBuilder.account.changePassword;
  }

  get url(): string {
    if (this.hasRequestId())
      return UrlBuilder.account.changePasswordWithRequestId(this.requestId!);
    return UrlBuilder.account.changePassword;
  }

  readonly bodyClasses = ["page-pre-login", "page-request-account"];
}

