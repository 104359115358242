<div>
  <div class="ajax-target">
    <ng-container *ngIf="isSearching$ | async | not; else skeletonView">
      <ng-container *ngFor="let item of (items$ | async); trackBy: trackById">
        <rt-container-box
          id="shipment-dummy-box"
          *ngIf="item | rtIsContainerOverviewItem"
          [container]="item //noinspection ValidateTypes"></rt-container-box>
        <rt-shipment-list-box
          id="container-dummy-box"
          *ngIf="item | rtIsShipmentOverviewItem"
                              [shipment]="item //noinspection ValidateTypes"></rt-shipment-list-box>
      </ng-container>
    </ng-container>
    <ng-template #skeletonView>
      <div *ngFor="let item of [].constructor(skeletonItemCount);" class="shipment-box">
        <header class="skeleton">
          <p><span class="shimmer-content" style="width:35%"></span></p>
          <p><span class="shimmer-content" style="width:55%"></span></p>
        </header>
        <div class="progress skeleton">
          <p><span class="shimmer-content" style="width:100%"></span></p>
          <p><span class="shimmer-content" style="width:35%"></span></p>
        </div>
        <div class="informations">
          <p>
            <span class="shimmer-content" style="width:50%"></span>
            <span class="shimmer-content" style="width:70%"></span>
          </p>
          <p>
            <span class="shimmer-content" style="width:50%"></span>
            <span class="shimmer-content" style="width:70%"></span>
          </p>
          <p>
            <span class="shimmer-content" style="width:50%"></span>
            <span class="shimmer-content" style="width:70%"></span>
          </p>
          <p>
            <span class="shimmer-content" style="width:50%"></span>
            <span class="shimmer-content" style="width:70%"></span>
          </p>
          <p>
            <span class="shimmer-content" style="width:50%"></span>
            <span class="shimmer-content" style="width:70%"></span>
          </p>
          <p>
            <span class="shimmer-content" style="width:50%"></span>
            <span class="shimmer-content" style="width:70%"></span>
          </p>
          <p>
            <span class="shimmer-content" style="width:50%"></span>
            <span class="shimmer-content" style="width:70%"></span>
          </p>
        </div>
        <div class="labels">
          <span class="label shimmer-content" style="width:20%"></span>
        </div>
      </div>
    </ng-template>
  </div>
</div>

