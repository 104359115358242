import {Directive, ElementRef, Input, ApplicationRef, OnInit, forwardRef, Provider} from "@angular/core";
import { ContentComponent } from "@utils/Angular/ContentComponent";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms"
import { INumericInputOptions, NumericInput } from "@widgets/NumericInput";

const NUMBER_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NumericInputDirective),
    multi: true
};
@Directive({
    selector: 'input[ngNumericInput]',
    host: {
        '(blur)': '_onTouched()',
        '(change)': '_onChange()',
        '(input)': '_onChange()'
    },
    providers: [NUMBER_VALUE_ACCESSOR]
})
export class NumericInputDirective extends ContentComponent implements ControlValueAccessor, OnInit {
    private _onChange: () => void = () => { };
    private _onTouched: () => void = () => { };

    writeValue(obj: any): void {
        this.numericInput.value = obj;
    }
    registerOnChange(fn: any): void {
        this._onChange = () => {fn(this.numericInput.value)};
    }
    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled)
            this.numericInput.disable();
        else
            this.numericInput.enable();
    }

    @Input()
    settings: INumericInputOptions;

    numericInput: NumericInput;

    constructor(element: ElementRef, app: ApplicationRef) {
        super(element, app);
    }

    ngOnInit(): void {
        const $input = $(this.element);
        if ($input.data("numeric-input"))
            this.numericInput = $input.data("numeric-input");
        else
            this.numericInput = new NumericInput($input[0] as HTMLInputElement, this.settings || $input.getJsonFromAttribute<INumericInputOptions>("data-settings"));
        this.numericInput.onValueChanged.on((e) => {
            this._onChange();
                this._onTouched();
            }
        );
    }
}
