import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {CountryService} from "@services/country.service";
import {FormValidatorDirective, NgSelectionChangedEvent} from "@directives";
import {SettingsService} from "@services/settings.service";
import { BookingSetting, InvoiceDisputeSetting, JSONData, Settings} from "@api";
import {ISelectOptions, Select} from "@widgets/Select";
import {AlertService} from "@services/alert.service";

type Entry = {entryType: string, countryCode: string, MailAddressWithoutDomain: string, hasValue: boolean, isGhost: boolean, key?: number }

@Component({
             selector: 'rt-settings',
             templateUrl: './settings.component.html',
             styleUrls: ['./settings.component.scss'],
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class SettingsComponent implements OnInit {
  readonly selectOptions: ISelectOptions = {
    displayIcon: true,
    "class": "flags",
    itemClass: "flags",
    closeOnSelect: true,
    search: true,
    autoPosition: true,
    showOnlyPreviewItemsWithoutSearch: false,
    disabledItemToolTip: "This country already has an address assigned",
    allowGrowing: () => !document.body.classList.contains("viewport-320")
  };
  isSaving: boolean;
  @ViewChild(FormValidatorDirective)
  formValidatorDirective: FormValidatorDirective;
  bookingEntries: Entry[] = [];
  invoiceDisputeEntries: Entry[] = [];
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _countryService: CountryService;
  private readonly _selects: Select<JSONData>[] = [];
  private _bookingEntryCount = 0;
  private _invoiceDisputeEntryCount = 0;
  private readonly _selectedBookingCountries: string[] = [];
  private readonly _selectedInvoiceCountries: string[] = [];
  private readonly _settingsService: SettingsService;
  private readonly _alertService: AlertService;

  constructor(changeDetectorRef: ChangeDetectorRef, countryService: CountryService, settingsService: SettingsService, alertService: AlertService) {
    this._changeDetectorRef = changeDetectorRef;
    this._countryService = countryService;
    this._settingsService = settingsService;
    this._alertService = alertService;
  }

  trackBy(index: number, bookingEntry: Entry) {
    return bookingEntry.key;
  }

  getCountryItems(isoAlpha2: string, entryType: string) {
    return this._countryService
               .countries
               .map<Partial<JSONData>>(c => {
                 const selected = c.isoAlpha2 === isoAlpha2;
                  const disabled = !selected &&
                    (entryType == "Booking" ? this._selectedBookingCountries.contains(c.isoAlpha2) : this._selectedInvoiceCountries.contains(c.isoAlpha2));
                 return {
                   value: c.isoAlpha2,
                   icon: "spriteCountryBorder " + c.isoAlpha2.toLowerCase(),
                   label: c.common,
                   disabled: disabled,
                   selected: selected
                 }
               });
  }

  async ngOnInit() {
    const settings = await this._settingsService.getSettings();
    const alreadySelectedBookingCountries = settings.bookingSettings.map(e => e.country);
    const alreadySelectedInvoiceCountries = settings.invoiceDisputeSettings.map(e => e.country);
    this._selectedBookingCountries.push(...alreadySelectedBookingCountries);
    this._selectedInvoiceCountries.push(...alreadySelectedInvoiceCountries);
    const bookingEntriesFromServer: Entry[] =
      settings.bookingSettings
              .map(s => {
                return {
                  entryType: "Booking",
                  hasValue: true,
                  key: ++this._bookingEntryCount,
                  countryCode: s.country,
                  isGhost: false,
                  MailAddressWithoutDomain: s.fallbackMailAddress.replace(/@.+/, "")
                }
              })
    const disputeInvoiceEntriesFromServer: Entry[] =
      settings.invoiceDisputeSettings
              .map(s => {
                return {
                  entryType: "InvoiceDispute",
                  hasValue: true,
                  key: ++this._invoiceDisputeEntryCount,
                  countryCode: s.country,
                  isGhost: false,
                  MailAddressWithoutDomain: s.mailAddress.replace(/@.+/, "")
                }
              })
    this.bookingEntries.push(...bookingEntriesFromServer);
    this.invoiceDisputeEntries.push(...disputeInvoiceEntriesFromServer);
    this.addGhost(this.bookingEntries, "Booking");
    this.addGhost(this.invoiceDisputeEntries, "InvoiceDispute");
    this._changeDetectorRef.detectChanges();
  }

  removeEntry(entry: Entry) {
    switch (entry.entryType) {
      case "Booking":
        this.bookingEntries.remove(entry);
        if (entry.isGhost)
          this.addGhost(this.bookingEntries, "Booking");
        break;
      case "InvoiceDispute":
        this.invoiceDisputeEntries.remove(entry);
        if (entry.isGhost)
          this.addGhost(this.invoiceDisputeEntries, "InvoiceDispute");
        break;
    }
    this._changeDetectorRef.detectChanges();
    if (entry.countryCode) {
      this.removeSelectedCountryCode(entry.countryCode, entry.entryType);
    }
  }

  onChange(entry: Entry) {
    const hasValueAfterChange = !!entry.countryCode || !!entry.MailAddressWithoutDomain;
    if (hasValueAfterChange === entry.hasValue)
      return;
    entry.hasValue = hasValueAfterChange;
    if (entry.hasValue && entry.isGhost) {
      if(entry.entryType=="Booking")
        this.addGhost(this.bookingEntries, "Booking");
      if (entry.entryType == "InvoiceDispute")
        this.addGhost(this.invoiceDisputeEntries, "InvoiceDispute");
      entry.isGhost = false;
    }
    this._changeDetectorRef.detectChanges();
  }

  onEditEnd(entry: Entry) {
    if (entry.hasValue || entry.isGhost)
      return;
    if (entry.entryType == "Booking")
      this.bookingEntries.remove(entry);
    if (entry.entryType == "InvoiceDispute")
      this.invoiceDisputeEntries.remove(entry);
    this._changeDetectorRef.detectChanges();
  }

  onCountrySelectionChanged(e: NgSelectionChangedEvent, entryType: string) {
    const removedItem = e.removedItems[0];
    if (removedItem)
      this.removeSelectedCountryCode(removedItem.value, entryType)
    const addedItem = e.addedItems[0];
    if (addedItem)
      this.addSelectedCountryCode(addedItem.value, entryType)
  }

  removeSelectedCountryCode(isoAlpha2: string, entryType: string) {
    switch (entryType) {
      case "Booking":
        if (!this._selectedBookingCountries.contains(isoAlpha2))
          return;
        this._selectedBookingCountries.remove(isoAlpha2);
        break;
      case "InvoiceDispute":
        if (!this._selectedInvoiceCountries.contains(isoAlpha2))
          return;
        this._selectedInvoiceCountries.remove(isoAlpha2);
        break;
    }

    for (const select of this._selects) {
      select.enableItem(isoAlpha2);
    }
  }

  addSelectedCountryCode(isoAlpha2: string, entryType: string) {
    switch (entryType) {
      case "Booking":
        if (this._selectedBookingCountries.contains(isoAlpha2))
          return;
        this._selectedBookingCountries.push(isoAlpha2);
        break;
      case "InvoiceDispute":
        if (this._selectedInvoiceCountries.contains(isoAlpha2))
          return;
        this._selectedInvoiceCountries.push(isoAlpha2);
        break;
    }
    for (const select of this._selects) {
      const item = select.getItems().firstOrDefault(i => i.value === isoAlpha2);
      if (item.selected)
        continue;
      select.disableItem(item);
    }
  }

  initializeSelect(select: Select, entry: Entry) {
    this._selects.push(select);
    select.processJsonData(this.getCountryItems(entry.countryCode, entry.entryType));
  }

  async save() {
    if (!(await this.formValidatorDirective.validate()))
      return;
    const bookingSettings:BookingSetting[] =
      this.bookingEntries
      .filter(e => !e.isGhost)
      .map(e => {
        return {
          fallbackMailAddress: e.MailAddressWithoutDomain + "@rohlig.com",
          country: e.countryCode
        };
      });
    const invoiceDisputeSettings: InvoiceDisputeSetting[] =
      this.invoiceDisputeEntries
      .filter(e => !e.isGhost)
      .map(e => {
        return {
          mailAddress: e.MailAddressWithoutDomain + "@rohlig.com",
          country: e.countryCode
        };
      });
    const settings: Settings = {
      bookingSettings: bookingSettings,
      invoiceDisputeSettings: invoiceDisputeSettings
    };
    this.isSaving = true;
    await this._settingsService.saveSettings(settings);
    this._alertService.openDefaultSuccessAlert("Settings saved")
    this.isSaving = false;
    this._changeDetectorRef.detectChanges();
  }

  private addGhost(entryList: Entry[], type: string) {
    entryList.push({
                      entryType: type,
                      isGhost: true,
                      countryCode: "",
                      MailAddressWithoutDomain: "",
                      hasValue: false,
                      key: ++this._bookingEntryCount
                    });
  }
}
