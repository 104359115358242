require("../utils/JqueryLoader");

export class Placeholder {

    readonly $element: JQuery;
    $placeholder: JQuery;
    readonly id: string;

    constructor($element: JQuery) {
        this.$element = $element;
        this.$placeholder = $element.siblings("label.placeholder");

        this.id = this.$element.id();

        if (this.$placeholder.length === 0)
            this.buildPlaceholder();

        this.$element.removeAttr("placeholder");

        this.setPosition();

        this.$element
            .focus(this.hidePlaceholder.bind(this))
            .blur(this.showPlaceholder.bind(this));

        // FF fix
        this.hidePlaceholder();
        this.showPlaceholder();

        $(window).resize(this.setPosition.bind(this));
    }

    buildPlaceholder() {
        this.$element.wrap("<span style=\"position: relative; display: inline-block; width: 100%; height: 100%\" />");
        const placeholderString = this.$element.attr("placeholder");

        this.$placeholder = $(`<label for="${this.id}" class="placeholder">${placeholderString}</label>`)
            .insertBefore(this.$element);
    }

    setPosition() {
        this.$placeholder.css({
                                  "width": this.$element.outerWidth(),
                                  "height": this.$element.outerHeight()
                              });
    }

    hidePlaceholder() {
        this.$placeholder.hide();
    }

    showPlaceholder() {
        if (!this.$element.val())
            this.$placeholder.show();
        this.$placeholder.toggleClass("error", this.$element.hasClass("error"));
    }

}

declare global {
    interface JQuery {
        placeholders(): JQuery;
        data(key: "placeholders", value: Placeholder): JQuery;
    }
}

jQuery.fn.placeholders = function (this: JQuery) {
    return this.each(function () {
        new Placeholder($(this));
    });
};