import {Component, HostListener} from '@angular/core';
import {Observable} from "rxjs";
import {JSONData, ShipmentSearchPreset, ShipmentSearchReferenceType} from "@api";
import {AppBaseComponent} from "@app/appBaseComponent";
import {StoreAccessor} from "@stores/store-accessor";
import {ShipmentSearchUiStore} from "@app/page-components/shipment/shipment-list/shipment-search-ui-store.service";
import {AccountStore} from "@stores";
import {ShipmentService} from "@services";
import * as ShipmentSearchCodeMapper from "../shipment-search-code-mapper"
import {toJsonData, trimPaste} from "@utils/Utils";
import {SlideInOut} from "@utils/Angular/AnimationTemplates";
import {ISelectOptions} from "@widgets/Select";
import {CountryService} from "@services/country.service";
import {filter, map, mergeMap} from "rxjs/operators";
import {hasValue} from "@utils/rxjs-extensions";
import {RtDatePipe} from "@app/utils/rt-date.pipe";
import {IDropdownOptions} from "@widgets/Dropdown";
import {UnlocodeSearchService} from "@app/utils/unlocode-search.service";
import {fromPromise} from "rxjs/internal-compatibility";

@Component({
             selector: 'rt-shipment-search-bar',
             templateUrl: './shipment-search-filters.component.html',
             styleUrls: ['./shipment-search-filters.component.scss'],
             host: {
               'class': "search-bar"
             },
             animations: [
               SlideInOut()
             ]
           })
export class ShipmentSearchFiltersComponent extends AppBaseComponent {

  private static readonly _rohligUserId = "00000000-0000-0000-0000-000000000000"
  readonly store: ShipmentSearchUiStore;
  readonly selectedTransportModes = new StoreAccessor(this, "transportMode");
  readonly selectedContainerMode = new StoreAccessor(this, "containerMode");
  readonly selectedStatus = new StoreAccessor(this, "status");
  readonly selectedReferenceType = new StoreAccessor(this, "searchReferenceType");
  readonly selectedOriginCountry = new StoreAccessor(this, "originCountryCodes");
  readonly selectedDestinationCountry = new StoreAccessor(this, "destinationCountryCodes");
  readonly selectedPortOfLoading = new StoreAccessor(this, "portsOfLoadingUnLocodes");
  readonly selectedPortOfDischarge = new StoreAccessor(this, "portsOfDischargeUnLocodes");
  readonly selectedDateType = new StoreAccessor(this, "dateType");
  readonly selectedEndDate = new StoreAccessor(this, "endDate");
  readonly selectedStartDate = new StoreAccessor(this, "startDate");
  readonly selectedCalendarDateType = new StoreAccessor(this, "calendarDateType");
  readonly filtersOpen = new StoreAccessor(this, "filtersOpen");
  readonly bookedByItems$: Observable<JSONData[]>;
  readonly selectedBookedByIds$: Observable<string[]>;
  readonly transportModeSearchItems = toJsonData(ShipmentSearchCodeMapper.transportModes);
  readonly containerModeSearchItems = toJsonData(ShipmentSearchCodeMapper.containerModeSearches);
  readonly deliveryStatusSearchItems = toJsonData(ShipmentSearchCodeMapper.deliveryStatuses);
  readonly dateTypeSearchItems = toJsonData(ShipmentSearchCodeMapper.dateTypes);
  readonly countryItems: JSONData[]
  readonly endDateFocused$: Observable<boolean>;
  readonly startDateFocused$: Observable<boolean>;
  readonly dateTypeTitleAppendix$: Observable<string>;
  readonly selectedDatesString$: Observable<string>;
  readonly selectSettings = {
    ports: {
      class: "flags",
      search: true,
      placeholderNone: "Any",
      minSearchChars: 2,
      displayIcon: true,
      showSelectedValuesOnLoad: true,
      maxResults: 25,
      searchOptions: {searchAllPorts: true},
      ajaxResultUrl: "/WebMethod/SearchUNLOCOs",
      multiple: true
    } as ISelectOptions,
    countries: {
      multiple: true,
      placeholderNone: 'Any',
      search: true,
      displayIcon: true,
      class: 'flags'
    } as ISelectOptions,

    default: {
      multiple: true,
      placeholderNone: 'Any',
    } as ISelectOptions,
    date: {
      allowNoSelection: false,
      selectedOnTop: false
    } as ISelectOptions,
  }
  readonly portOfLoadingItems$: Observable<JSONData[]>;
  readonly portOfDischargeItems$: Observable<JSONData[]>;
  readonly dateDropdownOptions: IDropdownOptions = {
    allowGrowing: () => {
      return window.innerWidth >= 720
    },
    class: "select"
  };
  private readonly _shipmentService: ShipmentService;
  private readonly _accountStore: AccountStore;
  private readonly _countryService: CountryService;
  private readonly _rtDatePipe: RtDatePipe;
  private readonly _unlocodeSearchService: UnlocodeSearchService;

  constructor(shipmentSearchUiStore: ShipmentSearchUiStore,
              shipmentService: ShipmentService,
              accountStore: AccountStore,
              countryService: CountryService,
              rtDatePipe: RtDatePipe,
              unlocodeSearchService: UnlocodeSearchService) {
    super();
    this._unlocodeSearchService = unlocodeSearchService;
    this._rtDatePipe = rtDatePipe;
    this._countryService = countryService;
    this._accountStore = accountStore;
    this._shipmentService = shipmentService;
    this.store = shipmentSearchUiStore;
    this.countryItems = countryService.getJsonData();
    this.portOfLoadingItems$ = this.selectedPortOfLoading.observe.pipe(this._mapPortToJsonData());
    this.portOfDischargeItems$ = this.selectedPortOfDischarge.observe.pipe(this._mapPortToJsonData());
    this.selectedBookedByIds$ = this._createBookedByObservable();
    this.bookedByItems$ = this._createBookedByItemsObservable();
    this.startDateFocused$ = this.store.observe("calendarStartDateFocused");
    this.endDateFocused$ = this.store.observe("calendarEndDateFocused");
    this.dateTypeTitleAppendix$ = this.store.observe("dateSearchTitleAppendix");
    this.selectedDatesString$ = this._createSelectedDatesStringObservable();
    this._windowResized()
  }

  private _mapPortToJsonData() {
    return mergeMap((ports: string[]) =>
                      fromPromise(this._unlocodeSearchService.getUnlocodeItems(ports)));
  }

  bookedByIdsChanged(ids: string[]): void {
    const bookedByRohligSelected = ids.contains(ShipmentSearchFiltersComponent._rohligUserId);
    const selectedUserIds = ids.filter(id => id !== ShipmentSearchFiltersComponent._rohligUserId);
    this.store.update({
                        bookedByRohlig: bookedByRohligSelected,
                        bookedByUserIds: selectedUserIds
                      });
  }

  toggleFiltersOpenState(): void {
    this.store.negate("filtersOpen")
  }

  clearFilters(): void {
    this.store.reset();
    this.store.update({filtersOpen: true});
    this.startSearch();
  }

  startSearch(): void {
    const searchObject = this.store.get();
    delete searchObject.filtersOpen;
    this._shipmentService.startSearch(searchObject)
  }

  selectStartDate(): void {
    this.store.update("calendarDateType", "startDate")
  }

  selectEndDate(): void {
    this.store.update("calendarDateType", "endDate")
  }

  clearDates(): void {
    this.store.update({startDate: null, endDate: null})
  }

  startSearchAndCloseFilters(): void {
    this.startSearch();
    this.store.update({filtersOpen: false});
  }

  private _createSelectedDatesStringObservable(): Observable<string> {
    return this.store
               .observe(["endDate", "startDate"])
               .pipe(map(({endDate, startDate}) => {
                 if (!endDate && !startDate)
                   return "Any";
                 let output = "";
                 if (startDate)
                   output += this._rtDatePipe.transform(startDate);
                 output += " – ";
                 if (endDate)
                   output += this._rtDatePipe.transform(endDate);
                 return output;
               }));
  }

  @HostListener("window:resize")
  private _windowResized() {
    this.store.update("showMobileSearchHint", window.innerWidth < 480)
  }

  private _createBookedByObservable() {
    return this.store
               .observe(["bookedByRohlig", "bookedByUserIds"])
               .pipe(map(s => {
                       if (s.bookedByRohlig)
                         return s.bookedByUserIds.concat([ShipmentSearchFiltersComponent._rohligUserId])
                       else
                         return s.bookedByUserIds;
                     })
               );
  }

  private _createBookedByItemsObservable() {
    return this._accountStore
               .observe("currentEntitySet")
               .pipe(filter(hasValue),
                     map(e => {
                       const items = toJsonData(e.bookingUsers);
                       items.unshift({
                                       value: ShipmentSearchFiltersComponent._rohligUserId,
                                       label: "Röhlig"
                                     });
                       const meItem = items.firstOrDefault(i => i.label === "Myself")
                       items.remove(meItem);
                       items.unshift(meItem)
                       return items;
                     }));
  }
}
