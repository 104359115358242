import {AfterViewChecked, Component, HostListener} from '@angular/core';
import {LoginViewData} from "@view-data";
import { checkScrollMargin, trimPaste, closeModalOnESC} from '../utils/Utils';
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'rt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked{
  title = new LoginViewData().pageTitle;

  private readonly _resizeRequestedSubject = new Subject();

  constructor() {
    this._resizeRequestedSubject.pipe(debounceTime(10)).subscribe(() => checkScrollMargin())
    checkScrollMargin();
  }

  @HostListener('document:paste', ['$event'])
  onPaste($event: ClipboardEvent): void {
    trimPaste($event, $event.target as HTMLInputElement);
  }

  @HostListener('window:resize')
  onResize(): void {
    this._resizeRequestedSubject.next();
  }

  @HostListener('document:keyup', ['$event'])
  onKeyup($event: KeyboardEvent): void {
    closeModalOnESC($event);
  }
  @HostListener('document:keydown', ['$event'])
  onKeydown($event: KeyboardEvent): void {
    if ($event.keyCode == 27)
      $event.preventDefault();
  }

  ngAfterViewChecked(): void {
    this._resizeRequestedSubject.next();
  }
}
