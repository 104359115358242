import {ControlDataEvent} from "../libs/ExtendableEvent";
import {EventEmitter} from "@utils/EventEmitter";
import * as Utils from "../utils/Utils"
import {getFormFieldDisplayName, getOrdinalIndicator, sleep} from "@utils/Utils"
import {ITimepickerOptions, Timepicker} from "./Timepicker";
import {Dropdown, IDropdownOptions} from "./Dropdown";
import * as moment from "moment-mini-ts";
import {GenerateDefaultOptions} from "../libs/DatepickDefaultOptions";
import {ValidationField} from "./Validate";
import {Select} from "./Select";
import {NumericInput} from "./NumericInput";
import {Position} from "@utils/Enums";
import {TemplateDateObject} from "@api";
import {DateRangeType} from "../api/custom-types";

require("../utils/JqueryLoader");


export class Datetimepicker extends Dropdown {
    private static defaultDateTimePickerOptions: IDateTimePickerOptions = {
        "class": "select",
        required: true,
        autoChangeDirection: false,
        autoPosition: false,
        position: Position.Top,
        enableTimePicker: true
    };

    private static relativeDatepickerTemplate = `
<div>
<input type="radio" name="dateType" id="dateTypeRelative" data-no-validation-message>
<label for="dateTypeRelative">
    In <input class="small" max="99" min="0" data-disable-validation data-visual-element="#dummy" type="number" id="relativeValue" data-no-validation-message value="0" />
    <select class="small" data-disable-validation id="relativeType"  data-no-validation-message>
        <option value="days">days</option>
        <option value="weeks">weeks</option>
        <option value="months">months</option>
    </select>
</label>
<input type="radio" name="dateType" id="dateTypeWeekday" data-no-validation-message>
<label for="dateTypeWeekday">
<div class="number-spinner" style="width: 54px;">
    <span class="mat-icon-expandless"></span>
    <div class="input-group">
        <input class="small" max="99" min="1" data-visual-element="#dummy" style="width: 28px; padding-right:0; text-align: right;" data-disable-validation type="number" id="weekdayValue" data-no-validation-message="" value="0">
        <span class="input-appendix small" id="countLabel" style="padding-left: 2px; margin-left:0; width:28px;">st</span>
    </div>
    <span class="mat-icon-expandmore"></span>
</div>
<!--<input class="small" max="99" min="0" required type="number" id="weekdayValue" data-no-validation-message value="0" /> -->
<!--<span id="weeksLabel">weeks</span>-->
    <select class="small" data-disable-validation id="weekdayDay" data-no-validation-message>
        <option>Sunday</option>
        <option>Monday</option>
        <option>Tuesday</option>
        <option>Wednesday</option>
        <option>Thursday</option>
        <option>Friday</option>
        <option>Saturday</option>
    </select>
    from today
</label>
<div id="absoluteValueLabel">&nbsp;</div>
</div>
    `;
    readonly options: IDateTimePickerOptions;
    readonly onDateChanged = new EventEmitter<ControlDataEvent<this, Date>>("datechanged", () => this.eventElements());
    private lblCountAppendix: HTMLElement;
    private rbtnDateTypeRelative: HTMLInputElement;
    private rbtnDateTypeWeekday: HTMLInputElement;
    private txtRelativeValue: NumericInput;
    private txtWeekdayValue: NumericInput;
    private lblAbsoluteValue: HTMLElement;
    private weekdayDaySelect: Select;
    private relativeTypeSelect: Select;
    private $datepicker: JQuery;
    private timepicker: Timepicker;
    private $placeholder: JQuery;
    private $okButton: JQuery;
    private dateSetterIsActive = false;
    private readonly validator: ValidationField;
    private readonly datepickOptions: IDatepickOptions = $.extend(GenerateDefaultOptions(), {
        minDate: new Date(),
        onSelect: () => {
            if (this.dateSetterIsActive) {
                return;
            }
            this.setOkButtonDisabledState();
            this.onDateChanged.fire(new ControlDataEvent(this, this.date));
            if (this.validator.isValid === false) {
                this.validator.validate();
            }
        },
        pickerClass: "booking"
    } as IDatepickOptions);

    constructor(private readonly input: HTMLInputElement,
                options?: IDateTimePickerOptions) {
        super($(document.createElement("form")),
              Datetimepicker.createToggleControl(input),
              Utils.ExtendOptions(Datetimepicker.defaultDateTimePickerOptions, options));
        this.validator = ValidationField.getValidationField(this.input);
        this.createDropdownContent();
        this.onOpened.on(() => this.popup.$element.find(".header > h2").text(getFormFieldDisplayName($(this.input))));
        if (!options.prefilledTime) {
            this.$okButton.prop("disabled", true);
        }
        this.$placeholder = this.$toggleControl.find("span");
        this.onDateChanged.on((e) => {
                                  if (this.dateSetterIsActive) {
                                      return;
                                  }
                                  this.updatePlaceholder(e.data);
                                  this.input.dispatchEvent(new CustomEvent("input"));
                              }
        );
        $(this.input)
            .attr("type", "date")
            .hide()
            .typedData("visual-element", this.$toggleControl);
    }

    get datepick() {
        return this.$datepicker.datepick.bind(this.$datepicker);
    }

    private _maxDate: Date;

    get maxDate() {
        return this._maxDate;
    }

    set maxDate(value) {
        this._maxDate = value;
        if (this.options.datePickerMode === "absolute") {
            this.$datepicker.datepick("option", "maxDate", value);
        }
    }

    get isTimeSet() {
        return this.options.enableTimePicker && this.timepicker.hasValue();
    }

    get date() {
        if (this.options.datePickerMode !== "none" && !this.hasDateValue()) {
            return null;
        }
        let date: Date;

        if (this.options.datePickerMode === "absolute") {
            date = new Date(this.$datepicker.datepick("getDate")[0].getTime());
        } else if (this.options.datePickerMode === "relative") {
            date = Datetimepicker.ConvertToAbsoluteValue(this.relativeDate);
        } else {
            date = new Date(0, 0, 0, 0, 0, 0, 0);
        }
        if (this.isTimeSet) {
            const time = this.timepicker.time;
            date.setHours(time.getHours());
            date.setMinutes(time.getMinutes());
            date.isTimeSet = true;
        } else {
            date.setHours(0);
            date.setMinutes(0);
            date.isTimeSet = false;
        }
        return date;
    }

    set date(value) {
        this.dateSetterIsActive = true;
        if (!value) {
            if (this.options.enableTimePicker) {
                this.timepicker.clear();
            }
            if (this.options.datePickerMode === "absolute") {
                this.$datepicker.datepick("setDate", null);
            } else if (this.options.datePickerMode === "relative") {
                this.weekdayDaySelect.selectElement.options[0].selected = true;
                this.weekdayDaySelect.syncItems();
                this.relativeTypeSelect.selectElement.value = "days";
                this.relativeTypeSelect.syncItems();
                this.txtRelativeValue.value = null;
                this.txtWeekdayValue.value = null;
                this.rbtnDateTypeRelative.checked = false;
                this.rbtnDateTypeWeekday.checked = false;
            }
            this.resetValidation();
        } else {
            if (this.options.enableTimePicker) {
                this.timepicker.time = value;
            }
            if (this.options.datePickerMode === "absolute") {
                this.$datepicker.datepick("setDate", value);
            } else if (this.options.datePickerMode === "relative") {
                const now = moment()
                    .set({
                             hour: value.getHours(),
                             minute: value.getMinutes(),
                             second: value.getSeconds(),
                             millisecond: value.getMilliseconds()
                         });
                const relativeTypeChecked = this.rbtnDateTypeRelative.checked;
                const weekdayChecked = this.rbtnDateTypeWeekday.checked;
                this.weekdayDaySelect.selectElement.value = moment(value).format("dddd");
                this.weekdayDaySelect.syncItems();
                this.relativeTypeSelect.selectElement.value = "days";
                this.relativeTypeSelect.syncItems();
                this.txtRelativeValue.value = Math.abs(now.diff(value, "day"));
                if (this.txtRelativeValue.value === 0 && new Date().getDate() !== value.getDate()) {
                    this.txtRelativeValue.value = 1;
                }
                this.txtWeekdayValue.value = Math.abs(now.diff(value, "week"));
                this.txtWeekdayValue.value++;
                this.rbtnDateTypeRelative.checked = relativeTypeChecked || !weekdayChecked;
                this.rbtnDateTypeWeekday.checked = weekdayChecked;
            }
        }
        this.dateSetterIsActive = false;
        this.setOkButtonDisabledState();
        this.onDateChanged.fire(new ControlDataEvent(this, value));
        if (!this.validator.isValid) {
            // noinspection JSIgnoredPromiseFromCall
          this.validator.validate()
        }
    }

    get relativeDate(): TemplateDateObject {
        if (this.options.datePickerMode !== "relative") {
            return null;
        }
        if (this.rbtnDateTypeRelative.checked) {
            if (this.relativeTypeSelect.getSelectedItems().length === 0 || this.txtRelativeValue.value === null) {
                return null;
            }
            return {
                dateRangeType: this.relativeTypeSelect.getSelectedItems()[0].option.innerText as any,
                number: this.txtRelativeValue.value,
                time: this.options.enableTimePicker ? this.timepicker.time : null
            }
        } else if (this.rbtnDateTypeWeekday.checked) {
            if (this.weekdayDaySelect.getSelectedItems().length === 0 || this.txtWeekdayValue.value === null) {
                return null;
            }
            return {
                weekday: this.weekdayDaySelect.getSelectedItems()[0].option.innerText as DateRangeType,
                number: this.txtWeekdayValue.value,
                time: this.options.enableTimePicker ? this.timepicker.time : null
            }
        }
        return null;
    }

    set relativeDate(value) {
        this.dateSetterIsActive = true;
        this.txtWeekdayValue.value = !!value.weekday ? value.number : null;
        this.txtRelativeValue.value = !!value.dateRangeType ? value.number : null;
        let weekdayOptions = this.weekdayDaySelect.selectElement.options;
        for (let i = 0; i < weekdayOptions.length; i++) {
            if (weekdayOptions[i].innerText === value.weekday) {
                weekdayOptions[i].selected = true;
                this.weekdayDaySelect.syncItems();
                break;
            }
        }
        let typeOptions = this.relativeTypeSelect.selectElement.options;
        for (let i = 0; i < typeOptions.length; i++) {
            if (typeOptions[i].innerText === value.dateRangeType) {
                typeOptions[i].selected = true;
                this.relativeTypeSelect.syncItems();
                break;
            }
        }
        this.rbtnDateTypeRelative.checked = !!value.dateRangeType;
        this.rbtnDateTypeWeekday.checked = !!value.weekday;
        if (this.options.enableTimePicker) {
            if (value.time) {
                this.timepicker.time = value.time;
            } else {
                this.timepicker.clear();
            }
        }
        this.dateSetterIsActive = false;
        this.updatePlaceholder();
        this.resetValidation();
        this.setOkButtonDisabledState();
    }

    private _minDate: Date;

    get minDate() {
        return this._minDate;
    }

    set minDate(value) {
        this._minDate = value;
        if (this.options.datePickerMode === "absolute") {
            this.$datepicker.datepick("option", "minDate", value);
        }
    }

    static ConvertToAbsoluteValue(relativeDate: TemplateDateObject): Date {
        if (!relativeDate || relativeDate.number === undefined || relativeDate.number === null) {
            return null;
        }
        let today = new Date();
        today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        let momentObject: moment.Moment;
        if (relativeDate.dateRangeType) {
            momentObject = moment(today)
                .add(relativeDate.number, relativeDate.dateRangeType);
        } else if (relativeDate.weekday) {
            momentObject = moment(today)
                .day(relativeDate.weekday);
            if (momentObject.isBefore(new Date(), "day")) {
                momentObject.add(1, "week");
            }
            momentObject.add(relativeDate.number - 1, "weeks");
        }
        if (!momentObject) {
            return null;
        }
        if (relativeDate.time) {
            momentObject
                .hour(relativeDate.time.getHours())
                .minute(relativeDate.time.getMinutes())
                .second(relativeDate.time.getSeconds());
        }
        let result = momentObject.toDate();
        result.isTimeSet = !!relativeDate.time;
        return result

    }

    static ConvertToRelativeValue(date: Date): TemplateDateObject {
        if (!date) {
            return null;
        }
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        return {
            dateRangeType: "days",
            number: Math.floor((new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() -
                                today.getTime()) / 1000 / 60 / 60 / 24),
            time: date.hasTime()
                  ? new Date(0, 0, 0, date.getHours(), date.getMinutes(), date.getSeconds())
                  : null
        }
    }

    static RelativeDateToString(relativeDate: TemplateDateObject) {
        if (relativeDate === null) {
            return null;
        }
        let output = "";
        if (relativeDate.weekday) {
            output =
                `${relativeDate.number + getOrdinalIndicator(relativeDate.number)} ${relativeDate.weekday} from today`;
        }// placeholderText = `${relativeDate.Weekday} in ${relativeDate.Number} ${relativeDate.Number === 0
        //                                                                        ? "week"
        //                                                                        : "weeks"}`;
        else if (relativeDate.dateRangeType) {
            output = `In ${relativeDate.number} ${relativeDate.number === 1
                                                  ? relativeDate.dateRangeType.trim("s")
                                                  : relativeDate.dateRangeType}`;
        } else {
            return null;
        }
        if (relativeDate.time) {
            output +=
                ", " + moment(relativeDate.time).format(window.timeShortFormat.replace(/t+/, "A"));
        }
        return output;
    }

    private static createToggleControl(input: HTMLInputElement) {
        return $(document.createElement("div"))
            .addClass("fake-select")
            .insertAfter(input)
            .css({"maxWidth": "313px"})
            .append($(document.createElement("span")).html("&nbsp;"));
    }

    eventElements() {
        return super.eventElements().concat([$(this.input)]);
    }

    clearTime() {
        this.timepicker.clear();
    }

    resetValidation() {
        this.validator.resetValidation();
        this.popup.$element.find(".error").removeClass("error")
    }

    validate(focusFailedField?: boolean) {
        return this.validator.validate();
    }

    private hasDateValue() {
        if (this.options.datePickerMode === "relative") {
            return this.hasRelativeValue();
        }
        if (this.options.datePickerMode === "absolute") {
            return !!this.$datepicker.datepick("getDate")[0];
        }
        return false;
    }

    private createDropdownContent() {
        const $form = this.popup.$element.children();
        $form.attr("novalidate", "novalidate").addClass("datetimepicker");
        if (this.options.enableTimePicker) {
            const $timepickerContainer = $(document.createElement("div"))
                .addClass("timepicker-container")
                .appendTo($form);
            this.timepicker =
                new Timepicker($timepickerContainer, $.extend(this.options, {required: false}));
            this.timepicker.onTimeChanged.on((e) => {
                if (this.dateSetterIsActive) {
                    return;
                }
                this.setOkButtonDisabledState();
                if (this.hasDateValue()) {
                    this.onDateChanged.fire(new ControlDataEvent(this, this.date))
                }
            });
        }
        if (this.options.datePickerMode === "absolute") {
            this.$datepicker =
                $(document.createElement("div"))
                    .prependTo($form);
            this.$datepicker
                .datepick(this.datepickOptions)
        } else if (this.options.datePickerMode === "relative") {
            this.createRelativeDatepicker();
            $form.addClass("relative-datepicker")
        }
        $(document.createElement("div"))
            .addClass("buttons equal-size")
            .append(
                this.$okButton = $(document.createElement("button"))
                    .text("OK")
                    .addClass("button")
                    .attr("type", "button")
                    .click(async (e) => {
                        e.preventDefault();
                        if (await this.validator.validate()) {
                            this.onDateChanged.fire(new ControlDataEvent(this, this.date, false), $(this.input));
                            this.close();
                        }
                    }))
            .append(
                $(document.createElement("button"))
                    .text("Clear")
                    .addClass("button secondary")
                    .click((e) => {
                        e.preventDefault();
                        this.date = null;
                    }))
            .appendTo($form);
        $form.prepend(`<div class="header hide-desktop"><h2>${getFormFieldDisplayName($(this.input))}</h2></div>`);
    }

    private createRelativeDatepicker() {
        const $container = this.popup.$element.children();
        $container.prepend($(Datetimepicker.relativeDatepickerTemplate));
        this.lblCountAppendix = $container.find("#countLabel")[0];
        this.lblAbsoluteValue = $container.find("#absoluteValueLabel").appendTo($container)[0];
        this.rbtnDateTypeRelative = $container.find("#dateTypeRelative")[0] as HTMLInputElement;
        this.rbtnDateTypeRelative.addEventListener("change", () => this.updatePlaceholder());
        this.rbtnDateTypeWeekday = $container.find("#dateTypeWeekday")[0] as HTMLInputElement;
        this.txtRelativeValue = new NumericInput($container.find("#relativeValue")[0] as HTMLInputElement,
                                                 {
                                                     decimalPrecision: 0,
                                                     spinnerButtons: "topBottomArrows",
                                                     enableGoingAround: false
                                                 });
        this.txtWeekdayValue = new NumericInput($container.find("#weekdayValue")[0] as HTMLInputElement,
                                                {
                                                    decimalPrecision: 0,
                                                    spinnerButtons: "topBottomArrows",
                                                    enableGoingAround: false,
                                                    prerendered: true
                                                });
        let weekdaySelectElement = $container.find("#weekdayDay")[0] as HTMLSelectElement;
        if (window.firstDayOfWeek === 1) {
            $(weekdaySelectElement.options[0]).appendTo(weekdaySelectElement);
        }
        weekdaySelectElement.options[0].selected = true;
        this.weekdayDaySelect =
            new Select(weekdaySelectElement, {allowNoSelection: false});
        this.weekdayDaySelect.$fakeSelect.css({width: 110});
        this.weekdayDaySelect.onSelectionChanged.on(() => {
            this.rbtnDateTypeWeekday.checked = true
        });

        this.relativeTypeSelect =
            new Select($container.find("#relativeType")[0] as HTMLSelectElement, {allowNoSelection: false});
        this.relativeTypeSelect.$fakeSelect.css({width: 85});
        this.relativeTypeSelect.onSelectionChanged.on(() => {
            this.rbtnDateTypeRelative.checked = true;
        });
        this.txtRelativeValue.onValueChanged.on((e) => {
            this.rbtnDateTypeRelative.checked = true;
            if (e.data.oldValue === 1) {
                this.relativeTypeSelect.getCurrentItems().map(i => i.contentString = i.contentElement.innerText += "s");
                this.relativeTypeSelect.renderPlaceholderText()
            } else if (e.data.newValue === 1) {
                this.relativeTypeSelect.getCurrentItems()
                    .filter(i => !!i.contentElement)
                    .map(i => i.contentString = i.contentElement.innerText = i.contentElement.innerText.trim("s"));
                this.relativeTypeSelect.renderPlaceholderText()
            }
        });
        this.txtWeekdayValue.onValueChanged.on((e) => {
            this.rbtnDateTypeWeekday.checked = true;
            this.lblCountAppendix.innerText = getOrdinalIndicator(e.data.newValue);
        });
        const $timePickerFields = $container.find(".timepicker input");
        $timePickerFields.attr("data-visual-element", "#dummy");
        const $relativeValueFields = this.txtRelativeValue.$input.add(this.relativeTypeSelect.$fakeSelect);
        const $weekdayFields = this.txtWeekdayValue.$input.parent().add(this.weekdayDaySelect.$fakeSelect);
        $container.on("change input selection-changed", () => {
            if (this.dateSetterIsActive) {
                return;
            }
            this.onDateChanged.fire(new ControlDataEvent(this,
                                                         Datetimepicker.ConvertToAbsoluteValue(this.relativeDate)));
            this.validator.validate();
        });
        const validatorFunction = async () => {
            if (this.dateSetterIsActive) {
                $weekdayFields.removeClass("error");
                $relativeValueFields.removeClass("error");
                $timePickerFields.removeClass("error");
                return true;
            }
            await sleep(10);
            if (!this.rbtnDateTypeWeekday.checked) {
                $weekdayFields.removeClass("error");
            }
            if (!this.rbtnDateTypeRelative.checked) {
                $relativeValueFields.removeClass("error");
            }
            const relativeDate = Datetimepicker.ConvertToAbsoluteValue(this.relativeDate);
            //this.isTimeSet && this.minDate.hasTime() ? undefined : "days")
            if (!relativeDate || !this.minDate || !moment(relativeDate)
                .isBefore(this.minDate, this.isTimeSet && this.minDate.hasTime() ? "minutes" : "days")) {
                $weekdayFields.removeClass("error");
                $relativeValueFields.removeClass("error");
                $timePickerFields.removeClass("error");
                this.setOkButtonDisabledState(true);
                return true;
            } else {
                if (this.rbtnDateTypeRelative.checked) {
                    $relativeValueFields.addClass("error");
                } else {
                    $weekdayFields.addClass("error");
                }
                if (this.isTimeSet) {
                    $timePickerFields.addClass("error");
                } else {
                    $timePickerFields.removeClass("error");
                }
                this.setOkButtonDisabledState(false);
                if (this.isOpen())
                    this.popup.$element.find(".fake-select.error, input.error, .input-group.error > input")
                        .first()
                        .focus();
                return false;
            }
        };
        this.validator.validateData.validators["datepicker"] = {
            errorMessage: () => {
                let dateFormat = window.dateShortFormat.toUpperCase();
                if (this.isTimeSet && this.minDate && this.minDate.hasTime()) {
                    dateFormat += `, ${window.timeShortFormat.replace(/t+/, "A")}`;
                }
                return "Selected date must not be before " + moment(this.minDate).format(dateFormat);

            },
            isActive: true,
            validator: validatorFunction
        };
        // const weekDayValidationField = ValidationField.getValidationField(this.weekdayDaySelect.selectElement);
        // const relativeTypeValidationField = ValidationField.getValidationField(this.relativeTypeSelect.selectElement);
        // const relativeValueValidationField = ValidationField.getValidationField(this.txtRelativeValue.$input[0] as HTMLInputElement);
        // const weekdayValueValidationField = ValidationField.getValidationField(this.txtWeekdayValue.$input[0] as HTMLInputElement);
        // weekDayValidationField.validateData.disableValidation = () => !this.rbtnDateTypeWeekday.checked;
        // relativeTypeValidationField.validateData.disableValidation = () => !this.rbtnDateTypeRelative.checked;
        // relativeValueValidationField.validateData.disableValidation = () => !this.rbtnDateTypeRelative.checked;
        // weekdayValueValidationField.validateData.disableValidation = () => !this.rbtnDateTypeWeekday.checked;
        // for (const validationField of [weekDayValidationField,
        //                                relativeTypeValidationField,
        //                                relativeValueValidationField,
        //                                weekdayValueValidationField,
        //                                this.validator]) {
        //     const validators = validationField.validateData.validators;
        //     validators["minDate"] = {
        //         isActive: () => !!this._minDate,
        //         validator: () => this.relativeMinDateValidator(),
        //         errorMessage: () => {
        //             let dateFormat = window.dateShortFormat.toUpperCase();
        //             if (this.isTimeSet && this.minDate.getHours() !== 0) {
        //                 dateFormat += `, ${window.timeShortFormat.replace(/t+/, "A")}`;
        //             }
        //             return "Selected date must not be before " + moment(this.minDate).format(dateFormat);
        //         }
        //     };
        //     // validators["maxDate"] = {
        //     //     isActive: () => !!this._maxDate,
        //     //     validator: () => this.relativeMaxDateValidator(),
        //     //     errorMessage: () => {
        //     //                     let dateFormat = window.dateShortFormat.toUpperCase();
        //     //                     if (this.isTimeSet && this.minDate.getHours() !== 0) {
        //     //                         dateFormat += `, ${window.timeShortFormat.replace(/t+/, "A")}`;
        //     //                     }
        //     //                     return "Selected date must not be after " + moment(this.minDate).format(dateFormat);
        //     //
        //     //                 }
        // }
    }

    private relativeDateInputChangeHandler() {
        if (this.dateSetterIsActive) {
            return;
        }
        this.setOkButtonDisabledState();
        this.onDateChanged.fire(new ControlDataEvent(this, this.date));
        //await this.validator.validate(false);
    };

    private relativeMinDateValidator() {
        if (this.options.datePickerMode !== "relative" || !this.minDate) {
            return true;
        }
        const relativeDate = Datetimepicker.ConvertToAbsoluteValue(this.relativeDate);
        if (!relativeDate) {
            return true;
        }
        return !moment(relativeDate).isBefore(this.minDate);
    }

    private relativeMaxDateValidator() {
        if (this.options.datePickerMode !== "relative" || !this.maxDate) {
            return true;
        }
        const relativeDate = Datetimepicker.ConvertToAbsoluteValue(this.relativeDate);
        if (!relativeDate) {
            return true;
        }
        return !moment(relativeDate).isAfter(this.maxDate);
    }

    private setOkButtonDisabledState(state?: boolean) {
        if (typeof state === "boolean") {
            this.$okButton.prop("disabled", state);
        }
        if (this.options.datePickerMode === "none") {
            this.$okButton.prop("disabled", false);
        } else if (this.options.datePickerMode === "absolute") {
            this.$okButton.prop("disabled", !this.datepick("getDate")[0]);
        } else {
            this.$okButton.prop("disabled", !this.relativeDate);
        }
    }

    private updatePlaceholder(date = this.date) {
        this.input.value = moment(date).format("YYYY-MM-DD");
        try {
            this.input.valueAsDate = date;
        } catch (e) {

        }
        let placeholderText = "";
        if (this.options.datePickerMode === "relative") {
            this.lblAbsoluteValue.innerHTML = "&nbsp;";
        }
        if (date) {
            const momentDate = moment(date);
            if (this.options.datePickerMode === "absolute" && this.$datepicker.datepick("getDate").length) {
                placeholderText = momentDate.format(window.dateShortFormat.toUpperCase());
                if (this.options.enableTimePicker && this.timepicker.hasValue()) {
                    placeholderText +=
                        "&nbsp;&nbsp;&nbsp;" + momentDate.format(window.timeShortFormat.replace(/t+/, "A"));
                }
            } else if (this.options.datePickerMode === "relative" && this.hasRelativeValue()) {
                placeholderText = Datetimepicker.RelativeDateToString(this.relativeDate);
                const absoluteLabel = this.options.absoluteLabel;
                if (absoluteLabel) {
                    let dateString = this.isTimeSet ?
                                     momentDate.format(window.dateShortFormat.toUpperCase() + ", " +
                                                       window.timeShortFormat.replace(/t+/g, "A")) :
                                     momentDate.format(window.dateShortFormat.toUpperCase());
                    if (typeof absoluteLabel === "boolean") {
                        this.lblAbsoluteValue.innerText = dateString;
                    } else if (typeof absoluteLabel === "string") {
                        this.lblAbsoluteValue.innerText =
                            absoluteLabel.format(dateString);
                    } else if (typeof absoluteLabel === "function") {
                        this.lblAbsoluteValue.innerText = absoluteLabel(this.relativeDate);
                    }
                }
            }
        }
        this.$placeholder.html(placeholderText);
    }

    private hasRelativeValue() {
        return this.rbtnDateTypeWeekday.checked && this.txtWeekdayValue.value !== null &&
               this.weekdayDaySelect.getSelectedItems().length ||
               this.rbtnDateTypeRelative.checked && this.txtRelativeValue.value !== null &&
               this.relativeTypeSelect.getSelectedItems().length;
    }
}

export interface IDateTimePickerOptions extends IDropdownOptions, ITimepickerOptions {
    datePickerMode?: "none" | "absolute" | "relative";
    absoluteLabel?: string | boolean | ((relativeDate: TemplateDateObject) => string);
    enableTimePicker?: boolean;
    defaultTime?: Date;
}

declare global {
    interface JQuery {
        datetimepicker(options?: IDateTimePickerOptions): JQuery;
    }
}

jQuery.fn.datetimepicker = function (this: JQuery, options?: IDateTimePickerOptions) {
    this.each((i, elem: HTMLInputElement) => {
        if ($(elem).data("datetimepicker")) {
            return;
        }
        // ReSharper disable once WrongExpressionStatement
        new Datetimepicker(elem, options);
    });
    return this;
};
