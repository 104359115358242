import {Component, ElementRef, HostListener, OnDestroy, ViewChild} from '@angular/core';
import {AppBaseComponent} from "@app/appBaseComponent";
import {BookingCancelModal} from "@app/page-components/shipment/shipment-detail/bookingCancelModal";
import {AccountStore, StoreCutoutAccessors, UserRightsStore} from "@stores";
import {
  Container,
  ContainerGoods,
  DangerousGoodsData,
  DeliveryStatus, JobType,
  Shipment, ShipmentReference, UserInformation
} from "@api";
import {Observable} from "rxjs";
import * as Isotope from "isotope-layout";
import { RtDatePipe} from "@app/utils/rt-date.pipe";
import {checkScrollMargin, sleep} from "@utils/Utils";
import {delay, takeUntil} from "rxjs/operators";
import {ContainerStore} from "@stores/container-store.service";
import {ContainerService} from "@services/container.service";

@Component({
  selector: 'rt-container-details',
  templateUrl: './container-detail.component.html',
  styleUrls: ['./container-detail.component.scss']
})
export class ContainerDetailComponent extends AppBaseComponent implements OnDestroy {

  readonly boxStateAccessors: StoreCutoutAccessors<UserInformation, "containerBoxOpenStates">;
  readonly hasDocumentRights$: Observable<boolean>;
  readonly hasInvoiceDocumentRights$: Observable<boolean>;
  readonly container$: Observable<Container>;
  readonly JobType = JobType;
  readonly deliveryStatuses = DeliveryStatus;
  private readonly _shipmentService: ContainerService;
  private readonly _accountStore: AccountStore;
  private readonly _containerStore: ContainerStore;
  private readonly _userRightsStore: UserRightsStore;
  private readonly _elementRef: ElementRef;
  private _iso: Isotope;
  private readonly _datePipe: RtDatePipe;

  constructor(accountStore: AccountStore,
              userRightsStore: UserRightsStore,
              containerStore: ContainerStore,
              containerService: ContainerService,
              elementRef: ElementRef,
              datePipe: RtDatePipe) {
    super();
    this._datePipe = datePipe;
    this._elementRef = elementRef;
    this._userRightsStore = userRightsStore;
    this._containerStore = containerStore;
    this._accountStore = accountStore;
    this._shipmentService = containerService;
    this.boxStateAccessors = accountStore.getStoreCutoutAccessors("containerBoxOpenStates", [
      "map",
      "shipmentInformation",
      "documents",
      "orders",
      "events",
      "goods",
      "notes",
      "clientReferences",
      "parties",
      "timeline",
      "containerInformation"
    ]);

    this.hasDocumentRights$ = userRightsStore.observe("documents");
    this.hasInvoiceDocumentRights$ = userRightsStore.observe("invoices");

    this.container$ = this._containerStore.observe("currentContainer");
  }

  @ViewChild("grid")
  set gridRef(value: ElementRef) {
    this._setupIsotype(value?.nativeElement)
  }

  hasNoGoodsInformation({goods}: Container): boolean {
    for (const good of Object.values(goods)) {
      if (good instanceof Array) {
        if (good.any())
          return false;
      } else if (good)
        return false;
    }
    return true;
  }

  getContainerDangerousGoods(container: ContainerGoods): Grouping<DangerousGoodsData, string>[] {
    return container.dangerousGoods.groupBy(dg => dg.unNumber)
  }

  hasNoDocuments(shipment: Container): boolean {
    return !shipment.documents?.any() &&
           // !shipment.customsDeclarationDocuments?.any() &&
           !shipment.invoiceDocuments?.any();
  }

  hasNoParties(shipment: Container): boolean {
    return !shipment.shipperLocations.any()&&
           !shipment.consigneeLocations.any() &&
           !shipment.pickupLocations.any() &&
           !shipment.deliveryLocations.any();
  }

  @HostListener("window:resize")
  @HostListener("window:layout")
  async _updateLayout(): Promise<void> {
    if (!this._iso)
      return;
    await sleep(1);
    this._iso.layout();
    checkScrollMargin();
  }

  ngOnDestroy(): void {
    this._iso?.destroy()
  }

  private _setupIsotype(grid: HTMLElement) {
    this._iso?.destroy();
    if (!grid)
      return;
    this._iso = new Isotope(grid,
                            {
                              percentPosition: false,
                              itemSelector: ".box",
                              layoutMode: "packery",
                              transitionDuration: "0.2s",
                              packery: {
                                gutter: ".grid-gutter"
                              }
                            });
    this._accountStore
        .observe("containerBoxOpenStates")
        .pipe(
          takeUntil(this.destroyed$),
          delay(1)
        )
        .subscribe(() => void this._updateLayout());
  }

  toOrderNumberList(orderNumbers: string[]): string {
    return orderNumbers.join("<br />");
  }
}
