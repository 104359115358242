import {ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {
  BookingViewData,
  EntityListViewData,
  EntityViewData,
  OnlineMonitorViewData,
  OrderListViewData,
  ProfileViewData,
  SettingsViewData,
  ShipmentListViewData,
  ShipmentViewData, TemplateViewData,
  UserListViewData,
  UserViewData,
  ViewData
} from "@view-data";
import {NavMenuQuery, RoutingStore} from "@stores";
import {OrderViewData} from "@services/view-data/data-objects/order-view-data";
import {MainMasterUiService} from "@app/master-components/main/main-master-ui.service";

@Component({
             selector: 'rt-nav-menu',
             templateUrl: './nav-menu.component.html',
             styleUrls: ['./nav-menu.component.scss'],
             host: {class: "sidebar-menu mobile-menu-sidebar"},
             providers: [
               NavMenuQuery
             ]
           })
export class NavMenuComponent {
  readonly shipmentsActive = new BehaviorSubject(false);
  readonly ordersActive = new BehaviorSubject(false);
  readonly usersActive = new BehaviorSubject(false);
  readonly entitiesActive = new BehaviorSubject(false);
  readonly settingsActive = new BehaviorSubject(false);
  readonly onlineMonitorActive = new BehaviorSubject(false);

  @HostBinding("class.open")
  isOpen = false;
  readonly onlineMonitorUrl = new OnlineMonitorViewData().url;
  readonly shipmentListUrl = new ShipmentListViewData().url;
  readonly orderListUrl = new OrderListViewData().url;
  readonly userListUrl = new UserListViewData().url;
  readonly entityListUrl = new EntityListViewData().url;
  readonly settingsUrl = new SettingsViewData().url;
  readonly navMenuQuery: NavMenuQuery;

  private readonly _mainMasterUiService: MainMasterUiService;

  constructor(routingStore: RoutingStore,
              mainMasterUiService: MainMasterUiService,
              navMenuQuery: NavMenuQuery) {
    this._mainMasterUiService = mainMasterUiService;
    routingStore
      .observe("currentViewData")
      .subscribe((d: ViewData) => this.viewDataReceived(d));
    this.navMenuQuery = navMenuQuery;
    mainMasterUiService.store
                       .subscribe("navMenuOpen", o => {this.isOpen = o || false});

  }

  private viewDataReceived(data: ViewData) {
    this.shipmentsActive.next(data instanceof ShipmentViewData || data instanceof BookingViewData || data instanceof TemplateViewData);
    this.ordersActive.next(data instanceof OrderViewData);
    this.usersActive.next(data instanceof UserViewData || data instanceof ProfileViewData);
    this.entitiesActive.next(data instanceof EntityViewData);
    this.settingsActive.next(data instanceof SettingsViewData);
    this.onlineMonitorActive.next(data instanceof OnlineMonitorViewData);
  }


  close(): void {
    this._mainMasterUiService.closeNavMenu();
  }
}
