import {checkScrollMargin} from "../utils/Utils";
import {Site} from "../Site";

require("../utils/JqueryLoader");

function openAccordion($accordion: JQuery) {
    // if ($accordion.hasClass("accordion-animated")) {
    //     $accordion.addClass("animating");
    //     let $clip = $accordion.children(".clip");
    //     var height = ($clip.children().is(".accordion-content") ?
    //                   $clip.children() : $clip)[0].scrollHeight;
    //     $clip.height(height);
    //     window.setTimeout(() => {
    //         $accordion.removeClass("animating");
    //         $clip.height("");
    //         checkScrollMargin();
    //         Site.UpdateLayout();
    //     }, 500);
    //     $accordion.removeClass("closed");
    // } else {
    //     $accordion.removeClass("closed");
    //     checkScrollMargin();
    //     Site.UpdateLayout();
    // }
}

function closeAccordion($accordion: JQuery) {
    // if ($accordion.hasClass("accordion-animated")) {
    //     let $clip = $accordion.children(".clip");
    //     $clip.height($clip.height());
    //     $accordion.addClass("animating closed");
    //     $clip.height(0);
    //     window.setTimeout(() => {
    //         $accordion.removeClass("animating");
    //         $clip.height("");
    //         checkScrollMargin();
    //         Site.UpdateLayout();
    //     }, 250);
    // } else {
    //     $accordion.addClass("closed");
    //     checkScrollMargin();
    //     Site.UpdateLayout();
    // }
}

$(document.body).on("click", ".accordion-toggle", function (this: HTMLElement) {
    // const $this = $(this);
    // let $accordion: JQuery;
    // if ($this.hasData("accordion-target")) {
    //     $accordion = $($this.typedData("accordion-target"));
    // } else {
    //     $accordion = $(this.parentElement);
    //     if ($accordion.is("header")) {
    //         $accordion = $accordion.parent();
    //     }
    // }
    // $this.toggleClass("closed", !$accordion.hasClass("closed"));
    // if ($accordion.hasClass("closed")) {
    //     openAccordion($accordion);
    // } else {
    //     closeAccordion($accordion);
    // }
});

