import {ViewData} from "@services/view-data";

export function hasNewInformationFilter() {
  let lastValue: ViewData | null = null;
  return (data: ViewData) => {
    return true;
    if (!data) {
      console.error("empty view data received");
      return false;
    }
    const isFirstValue = lastValue === null;
    if (isFirstValue) {
      lastValue = data;
      return true;
    } else {
      let isDifferentType = data.constructor !== lastValue!.constructor;
      const hasNewInformation = isDifferentType || data.hasNewInformationComparedTo(lastValue!);
      if (hasNewInformation)
        lastValue = data;

      return hasNewInformation;
    }
  }
}


// export function filteredMap <TObservable, TFilterType, TMapResult>(constructor: Constructor<TFilterType>, project: (value: TFilterType, index: number) => R)
// // :
//
//
// type t1 = { _: string, a: string, b: string };
// type t2 = { _: string, a: string, b: string, c: string };
// type t3 = { _: string, d: string };
// type t4 = { _: string, e: string };
//
//
// var f: <T, T2, T3>(p1: T, p2: Constructor<T2>, p3: (p1: T2) => T3) => Exclude<T, T2> | T3;
//
// var p1: t2;
// var p2: Constructor<t2>
// var p3: (p1: t2) => t4;
//
// var r = f(p1, p2, p3);
//
// export function map<T, R>(project: (value: T, index: number) => R, thisArg?: any): OperatorFunction<T, R> {
//   return function mapOperation(source: Observable<T>): Observable<R> {
//     if (typeof project !== 'function') {
//       throw new TypeError('argument is not a function. Are you looking for `mapTo()`?');
//     }
//     return source.lift(new FilteredMapOperator(project, thisArg));
//   };
// }
//
// export class FilteredMapOperator<T, R> implements Operator<T, R> {
//   constructor(private project: (value: T, index: number) => R, private thisArg: any) {
//   }
//
//   call(subscriber: Subscriber<R>, source: any): any {
//     return source.subscribe(new FilteredMapSubscriber(subscriber, this.project, this.thisArg));
//   }
// }
//
// /**
//  * We need this JSDoc comment for affecting ESDoc.
//  * @ignore
//  * @extends {Ignored}
//  */
// class FilteredMapSubscriber<T, R> extends Subscriber<T> {
//   count: number = 0;
//   private thisArg: any;
//
//   constructor(destination: Subscriber<R>,
//               private project: (value: T, index: number) => R,
//               thisArg: any) {
//     super(destination);
//     this.thisArg = thisArg || this;
//   }
//
//   // NOTE: This looks unoptimized, but it's actually purposefully NOT
//   // using try/catch optimizations.
//   protected _next(value: T) {
//     let result: R;
//     try {
//       result = this.project.call(this.thisArg, value, this.count++);
//     } catch (err) {
//       this.destination.error(err);
//       return;
//     }
//     this.destination.next(result);
//   }
// }
