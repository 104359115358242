import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TimelineLocation, TimeLineLocationType} from "@api";

@Component({
             selector: 'rt-vertical-timeline-port',
             templateUrl: './vertical-timeline-port.component.html',
             styleUrls: ['./vertical-timeline-port.component.scss'],
             host: {
               class: "port"
             },
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class VerticalTimelinePortComponent {

  @Input()
  location: TimelineLocation

  readonly locationTypes = TimeLineLocationType;
}
