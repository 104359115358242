import {Component, HostListener} from '@angular/core';
import {OrderSearchUiStore} from "@app/page-components/order/order-list/order-search-bar/order-search-ui-store.service";
import {StoreAccessor} from "@stores/store-accessor";
import {Observable} from "rxjs";
import {JSONData} from "@api";
import {toJsonData} from "@utils/Utils";
import * as OrderSearchCodeMapper from "@app/page-components/order/order-list/order-search-bar/order-search-code-mapper";
import * as ShipmentSearchCodeMapper from "@app/page-components/shipment/shipment-list/shipment-search-code-mapper";
import {OrderService} from "@services";
import {AccountStore} from "@stores";
import {RtDatePipe} from "@app/utils/rt-date.pipe";
import {map} from "rxjs/operators";
import {ISelectOptions} from "@widgets/Select";
import {IDropdownOptions} from "@widgets/Dropdown";
import {SlideInOut} from "@utils/Angular/AnimationTemplates";
import {Alignment} from "@utils/Enums";

@Component({
             selector: 'rt-order-search-bar',
             templateUrl: './order-search-bar.component.html',
             styleUrls: ['./order-search-bar.component.scss'],
             host: {
               'class': "search-bar"
             },
             animations: [
               SlideInOut()
             ]
           })
export class OrderSearchBarComponent {

  readonly store: OrderSearchUiStore;

  readonly selectedTransportModes = new StoreAccessor(this, "transportMode");
  readonly selectedStatus = new StoreAccessor(this, "status");
  readonly selectedEndDate = new StoreAccessor(this, "endDate");
  readonly selectedStartDate = new StoreAccessor(this, "startDate");
  readonly selectedCalendarDateType = new StoreAccessor(this, "calendarDateType");

  readonly filtersOpen = new StoreAccessor(this, "filtersOpen");

  readonly transportModeSearchItems = toJsonData(ShipmentSearchCodeMapper.transportModes);
  readonly orderStatusSearchItems = toJsonData(OrderSearchCodeMapper.orderSearchStatuses);
  readonly dateTypeSearchItems: JSONData[] = [
    {label: "Order Date", value: "default", selected: true}
  ];

  private readonly _orderService: OrderService;

  private readonly _accountStore: AccountStore;

  readonly endDateFocused$: Observable<boolean>;
  readonly startDateFocused$: Observable<boolean>;
  readonly dateTypeTitleAppendix$: Observable<string>;
  readonly selectedDatesString$: Observable<string>;

  private readonly _rtDatePipe: RtDatePipe;

  constructor(orderSearchUiStore: OrderSearchUiStore,
              orderService: OrderService,
              accountStore: AccountStore,
              rtDatePipe: RtDatePipe) {
    this._rtDatePipe = rtDatePipe;
    this._accountStore = accountStore;
    this._orderService = orderService;
    this.store = orderSearchUiStore;
    this.startDateFocused$ = this.store.observe("calendarStartDateFocused");
    this.endDateFocused$ = this.store.observe("calendarEndDateFocused");
    this.selectedDatesString$ = this._createSelectedDatesStringObservable();
    this._windowResized()
  }

  private _createSelectedDatesStringObservable(): Observable<string> {
    return this.store
               .observe(["endDate", "startDate"])
               .pipe(map(({endDate, startDate}) => {
                 if (!endDate && !startDate)
                   return "Any";
                 let output = "";
                 if (startDate)
                   output += this._rtDatePipe.transform(startDate);
                 output += " – ";
                 if (endDate)
                   output += this._rtDatePipe.transform(endDate);
                 return output;
               }));
  }

  @HostListener("window:resize")
  private _windowResized() {
    this.store.update("showMobileSearchHint", window.innerWidth < 480)
  }
  readonly selectSettings = {
    default: {
      multiple: true,
      placeholderNone: 'Any',
    } as ISelectOptions,
    date: {
      allowNoSelection: false,
      selectedOnTop: false
    } as ISelectOptions,
    presets: {
      placeholderNone: "Presets",
      content: "#filterPresetsControls",
      class: "search-select droplist-presets droplist-button",
      alwaysPlaceholder: true,
      submitOnEnter: false,
      noResultsPlaceholder: "No saved presets",
      multiple: false,
      alignment: "start",
      closeOnSelect: false
    } as ISelectOptions
  }
  readonly dateDropdownOptions: IDropdownOptions = {
    allowGrowing: () => {
      return window.innerWidth >= 720
    },
    class: "select",
    alignment: Alignment.Start
  };

  toggleFiltersOpenState(): void {
    this.store.negate("filtersOpen")
  }

  clearFilters(): void {
    this.store.reset();
    this.store.update({filtersOpen: true});
    this.startSearch();
  }

  startSearch(): void {
    const searchObject = this.store.get();
    delete searchObject.filtersOpen;
    this._orderService.startSearch(searchObject)
  }

  selectStartDate(): void {
    this.store.update("calendarDateType", "startDate")
  }

  selectEndDate(): void {
    this.store.update("calendarDateType", "endDate")
  }

  clearDates(): void {
    this.store.update({startDate: null, endDate: null})
  }

  startSearchAndCloseFilters(): void {
    this.startSearch();
    this.store.update({filtersOpen: false});
  }

}
