<header>
  <button class="button secondary text-hide accordion-toggle" type="button" (click)="toggleOpen()">Collapse/Expand</button>
  <h4>{{entityPermissions.name | unescapeHtml}}</h4>
  <a class="mat-icon-info text-hide info-button" (click)="toggleEntityInfoSidebar(entityPermissions.id)">Information</a>
</header>
<div class="content clip" [@slideInOut]="open ? 'visible' : 'hidden'">
  <div class="options">
    <label>
      <input type="checkbox"
             [disabled]="!entityPermissions.canBeManagedByCurrentUser"
             [(ngModel)]="entityPermissions.shipments"
             (ngModelChange)="setShipmentRights($event)"/>
      <span class="mat-icon-done"></span>
      Shipments
    </label>
    <label>
      <input type="checkbox"
             [disabled]="!entityPermissions.canBeManagedByCurrentUser"
             [(ngModel)]="entityPermissions.orders"
             (ngModelChange)="setOrderRights($event)"/>
      <span class="mat-icon-done"></span>
      Orders
    </label>
    <label [title]="hasDocumentsPermissions ? '' : 'Please give permissions to view documents in order to view invoices'">
      <input type="checkbox"
             [disabled]="!entityPermissions.canBeManagedByCurrentUser || !hasDocumentsPermissions"
             [checked]="entityPermissions.invoiceDocuments && hasDocumentsPermissions"
             [ngModel]="entityPermissions.invoiceDocuments && hasDocumentsPermissions"
             (ngModelChange)="setInvoiceDocumentsRights($event)"/>
      <span class="mat-icon-done"></span>
      Invoice Documents
    </label>
    <label>
      <ng-container *ngIf="entityPermissions.bookingAvailable && (userCanHaveBookingPermissions$ | async); else bookingUnavailable">
        <input type="checkbox"
               [disabled]="!entityPermissions.canBeManagedByCurrentUser"
               [(ngModel)]="entityPermissions.bookings"
               (ngModelChange)="setBookingRights($event)"/>
        <span class="mat-icon-done"></span>
        Booking
      </ng-container>
      <ng-template #bookingUnavailable>
        <input type="checkbox" disabled/>
        <span class="mat-icon-done"
              title="This permission is only available for Client Users"
              *ngIf="userCanHaveBookingPermissions$ | async | not"></span>
        <span class="mat-icon-done"
              title="No booking recipients have been defined for this Entity. Please contact your Local Real Time Admin."
              *ngIf="userCanHaveBookingPermissions$ | async"></span>
        Booking
      </ng-template>
    </label>
  </div>
  <!--suppress TypeScriptValidateTypes -->
  <rt-user-entity-permission-edit *ngFor="let subSite of (entityPermissions.childEntities | orderBy:'name')"
                                  [hasDocumentsPermissions]="hasDocumentsPermissions"
                                  [entityPermissions]="subSite"
  ></rt-user-entity-permission-edit>
</div>
