import {Injectable} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {
  AdministrationViewData,
  BookingViewData,
  ChangePasswordViewData, ContainerDetailViewData,
  EntityEditViewData,
  EntityViewData,
  ErrorViewData,
  ForgotPasswordViewData,
  LoginViewData,
  OnlineMonitorViewData,
  OrderDetailViewData,
  PreLoginViewData,
  ProfileViewData, RequestAccessViewData,
  SettingsViewData,
  ShipmentDetailViewData,
  ShipmentViewData,
  TemplateViewData,
  UserDetailViewData,
  UserEditViewData,
  UserViewData,
  ViewData
} from "@view-data";
import {filter} from "rxjs/operators";
import {TitleBuilder} from "@utils/builders/title-builder";
import {OrderViewData} from "@services/view-data/data-objects/order-view-data";
import {ErrorType} from "@Enums";
import {EntityStores} from "@app/stores/entity-stores.service";
import {RoutingStore} from "@app/stores/routing-store.service";
import {Observable} from "rxjs";
import {BookingServiceListener} from "@services/booking-service-listener.service";

@Injectable({
              providedIn: 'root'
            })
export class TitleSetterService {
  private readonly _titleService: Title;
  private readonly _metaService: Meta;

  private readonly _entityStores: EntityStores;
  private readonly _routingStore: RoutingStore;

  private get _currentEntityTitle(): string | null {
    return this._entityStores.entityStore.get("entityTitle");
  }

  private get _currentShipmentTitle(): string | null {
    return this._entityStores.shipmentStore.get("shipmentTitle");
  }

  private get _currentContainerTitle(): string | null {
    return this._entityStores.containerStore.get("containerTitle");
  }

  private get _currentOrderTitle(): string | null {
    return this._entityStores.orderStore.get("orderTitle");
  }

  private get _currentUserTitle(): string | null {
    return this._entityStores.userStore.get("userTitle");
  }

  private get _currentIsNewTemplate(): boolean {
    const templateId = this._bookingServiceListener.currentBookingModel?.booking.templateInformation.templateID;
    return !templateId;
  }

  private get _currentTemplateName(): string | null {
    return this._bookingServiceListener.currentBookingModel?.booking.templateInformation.templateName || null;
  }

  private readonly _bookingServiceListener: BookingServiceListener;

  constructor(titleService: Title, metaService: Meta, routingStore: RoutingStore, entityStores: EntityStores,
              bookingServiceListener: BookingServiceListener) {
    this._bookingServiceListener = bookingServiceListener;
    this._routingStore = routingStore;
    this._entityStores = entityStores;
    this._metaService = metaService;
    this._titleService = titleService;

    this._setupObservables();
  }

  private _setupObservables() {
    const {
      entityStore,
      orderStore,
      shipmentStore,
      userStore,
      bookingStore,
      containerStore
    } = this._entityStores
    this._routingStore
        .observe("currentViewData")
        .subscribe(v => this._updateTitle(v));
    this._addTitleObserver(entityStore.observe("entityTitle"), EntityEditViewData);
    this._addTitleObserver(orderStore.observe("orderTitle"), OrderDetailViewData);
    this._addTitleObserver(shipmentStore.observe("shipmentTitle"), ShipmentDetailViewData);
    this._addTitleObserver(containerStore.observe("containerTitle"), ContainerDetailViewData);
    this._addTitleObserver(userStore.observe("userTitle"), UserEditViewData);
    this._addTitleObserver(userStore.observe("userTitle"), UserDetailViewData);
    this._addTitleObserver(bookingStore.observe("templateName"), TemplateViewData);
  }

  private _addTitleObserver<T extends ViewData>(observable: Observable<string>, constructor: Constructor<T>) {
    observable
      .pipe(filter(() => this._routingStore.get("currentViewData") instanceof constructor))
      .subscribe(() => this._reprocessCurrentViewData())
  }

  private readonly _appendix = window.isDemo ? " · Demo · Röhlig Real Time" : " · Röhlig Real Time";

  private _updateTitle(viewData: ViewData) {
    const tempTitle = this._getTitleForViewData(viewData);
    const title = tempTitle === null ? null :
      tempTitle.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">");
    if (title === null) {
      console.error("Title could not be determined for ViewData", viewData);
      this._setTitle(TitleBuilder.account.login);
    } else if (viewData instanceof LoginViewData) {
      this._setTitle(title);
    } else {
      this._setTitle(title + this._appendix);
    }
  }

  setTitle(title:string): void {
    this._setTitle(title + this._appendix)
  }

  private _setTitle(newTitle: string) {
    this._titleService.setTitle(newTitle);
    this._metaService.updateTag({name: 'og:title', content: newTitle});
  }

  private _reprocessCurrentViewData() {
    this._updateTitle(this._routingStore.get("currentViewData"));
  }

  private _getTitleForViewData(viewData: ViewData): string | null {
    if (viewData instanceof ContainerDetailViewData)
      return this._getContainerTitle(viewData);
    if (viewData instanceof ShipmentViewData)
      return this._getShipmentTitle(viewData);
    if (viewData instanceof OrderViewData)
      return this._getOrderTitle(viewData);
    if (viewData instanceof UserViewData)
      return this._getUserTitle(viewData);
    if (viewData instanceof EntityViewData)
      return this._getEntityTitle(viewData);
    if (viewData instanceof BookingViewData)
      return TitleBuilder.booking;
    if (viewData instanceof TemplateViewData)
      return this._getTemplateTitle();
    if (viewData instanceof ErrorViewData)
      return TitleSetterService._getErrorTitle(viewData.errorType)
    if (viewData instanceof ProfileViewData)
      return TitleBuilder.account.myProfile;
    if (viewData instanceof AdministrationViewData)
      return this._getAdministrationTitle(viewData);
    if (viewData instanceof PreLoginViewData)
      return this._getPreLoginViewData(viewData);
    return null;
  }

  private _getShipmentTitle(viewData: ShipmentViewData) {
    const shipmentLoaded = this._currentShipmentTitle !== null;
    if (viewData instanceof ShipmentDetailViewData && shipmentLoaded) {
      return TitleBuilder.shipment.shipmentDetail(this._currentShipmentTitle);
    }
    return TitleBuilder.shipment.list;
  }

  private _getContainerTitle(viewData: ContainerDetailViewData) {
    const containerLoaded = this._currentContainerTitle !== null;
    if (viewData instanceof ContainerDetailViewData && containerLoaded) {
      return TitleBuilder.containerDetail(this._currentContainerTitle);
    }
    return TitleBuilder.shipment.list;
  }

  private _getUserTitle(viewData: UserViewData) {
    const userLoaded = this._currentUserTitle !== null;
    if (userLoaded) {
      if (viewData instanceof UserDetailViewData) {
        return TitleBuilder.account.viewUser(this._currentUserTitle);
      } else if (viewData instanceof UserEditViewData)
        return TitleBuilder.account.editUser(this._currentUserTitle);
    }
    return TitleBuilder.account.users;
  }

  private _getEntityTitle(viewData: EntityViewData) {
    if (viewData instanceof EntityEditViewData) {
      if (viewData.isNewEntity())
        return TitleBuilder.entities.addSite;
      const entityLoaded = !!this._currentEntityTitle;
      if (entityLoaded)
        return TitleBuilder.entities.siteDetails(this._currentEntityTitle);
    }
    return TitleBuilder.entities.list;
  }

  private _getOrderTitle(viewData: OrderViewData) {
    const orderLoaded = this._currentOrderTitle !== null;
    if (viewData instanceof OrderDetailViewData && orderLoaded) {
      return TitleBuilder.order.orderDetail(this._currentOrderTitle);
    }
    return TitleBuilder.order.list;
  }

  private _getTemplateTitle() {
    if (this._currentIsNewTemplate)
      return TitleBuilder.template.newTemplate;
    if (this._currentTemplateName)
      return TitleBuilder.template.withName(this._currentTemplateName);
    return TitleBuilder.template.editTemplateWithoutName;
  }

  private static _getErrorTitle(errorType: ErrorType) {
    switch (errorType) {
      case "AccessDenied":
        return TitleBuilder.errors.accessDenied;
      case "Offline":
        return TitleBuilder.errors.offline;
      case "NotFound":
        return TitleBuilder.errors.notFound;
      case "UnexpectedError":
      default:
        return TitleBuilder.errors.unexpectedError;
    }
  }

  // noinspection JSMethodCanBeStatic
  private _getAdministrationTitle(viewData: AdministrationViewData): string | null {
    if (viewData instanceof SettingsViewData)
      return TitleBuilder.settings;
    if (viewData instanceof OnlineMonitorViewData)
      return TitleBuilder.onlineMonitor;
    return null;
  }

  // noinspection JSMethodCanBeStatic
  private _getPreLoginViewData(viewData: PreLoginViewData): string | null {
    if (viewData instanceof LoginViewData)
      return TitleBuilder.account.login;
    if (viewData instanceof ForgotPasswordViewData)
      return TitleBuilder.account.forgotPassword;
    if (viewData instanceof ChangePasswordViewData)
      return TitleBuilder.account.changePassword;
    if (viewData instanceof RequestAccessViewData)
      return TitleBuilder.account.requestAccess;
    return null;
  }
}
