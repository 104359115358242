'use strict'
import {
  AddressLocation,
  CityLocation,
  Container,
  Country,
  DangerousGoodsData,
  DeliveryStatus,
  DimensionValue,
  PhoneWithCountry,
  PortLocation,
  Shipment,
  ShipmentEvent,
  TransportMode
} from "@api";
import {formatDate} from "@angular/common";
import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {DateContainer} from "@app/page-components/shipment/shipment-list/shipment-overview-item";
import {deliveryStatusClassMapper, deliveryStatusDisplayMapper} from "@app/utils/enum-display-name-mapper";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {CountryService} from "@services/country.service";

@Injectable({providedIn: "root"})
export class FieldRenderer {

  private readonly _localeId: string;

  private readonly _domSanitizer: DomSanitizer;

  private readonly _countryService: CountryService;

  constructor(@Inject(LOCALE_ID) localeId: string, domSanitizer: DomSanitizer, countryService: CountryService) {
    this._countryService = countryService;
    this._domSanitizer = domSanitizer;
    this._localeId = localeId;
  }

  static readonly multipleLocations = "Multiple Locations";

  renderString(value: string): string {
    return value && /\S/.test(value) ? value : "–";
  }

  renderInteger(value: number): string {
    return value ? value.toString() : "–";
  }

  renderStringArray(value: string[], placeholder = "–"): string {
    return value?.filter(s => !!s).join(", ") || placeholder;
  }

  renderPortLocationTitle(value: PortLocation | PortLocation[], type: "IATA" | "UNLOCO" | "both" | "none"): string {
    if (value instanceof Array) {
      if (value.length > 1)
        return FieldRenderer.multipleLocations;
      else
        value = value[0];
    }
    if (!value?.name) {
      return "T.B.D.";
    }
    let output = "";
    output += value.name.toUpperCase();
    if (type !== "none") {
      output += FieldRenderer.getCodeString(type, value);
    }
    return output;
  }

  renderPortLocationValue(value: PortLocation | PortLocation[], type: Shipment | Container | "IATA" | "UNLOCO" | "both" | "none" = "none", countryPlacing: 'first' | 'last' = 'first'): string {
    if (value instanceof Array) {
      if (value.length > 1)
        return FieldRenderer.multipleLocations;
      else
        value = value[0];
    }
    if (!value?.name) {
      return "T.B.D.";
    }
    if (typeof type === "object")
      type = type.transportModes.contains(TransportMode.Air)
             ? "IATA"
             : "UNLOCO"
    let output = "";
    if (countryPlacing === "first")
      output += this.renderCountryFlag(value.country);
    output += `${value.name.toUpperCase()}`;
    if (type !== "none") {
      output += FieldRenderer.getCodeString(type, value);
    }
    if (countryPlacing === "last")
      output += this.renderCountryFlag(value.country);
    return output;
  }

  private static getCodeString(type: "IATA" | "UNLOCO" | "both", value: PortLocation) {
    const codes = [] as string[];
    if (type !== "IATA" && value.unloco)
      codes.push(value.unloco);
    if (type !== "UNLOCO" && value.iataCode)
      codes.push(value.iataCode);
    return codes.length
           ? ` (${codes.join(" / ")})`
           : "";
  }

  renderCountryFlag(country: Country): string {
    if (!country)
      return "";
    else
      return `<span class="spriteCountryBorder ${country.isoAlpha2?.toLowerCase()}" title="${country.name}"></span>`
  }

  renderCountry(country: Country): string {
    if (!country)
      return "";
    else
      return `<span class="spriteCountryBorder ${country.isoAlpha2?.toLowerCase()}"></span> ${country.name}`
  }

  renderCityLocationValue(value: CityLocation | CityLocation[] ): string {
    if (value instanceof Array) {
      if (value.length > 1)
        return FieldRenderer.multipleLocations;
      else
        value = value[0];
    }
    if (!value?.city) {
      return this._emptyPlaceholder;
    }
    let output = "";
    if (value.country) {
      output += this.renderCountryFlag(value.country)
    }
    output += value.city.toUpperCase();
    return output;
  }

  renderAddressLocationName(value: AddressLocation | AddressLocation[] ): string {
    if (value instanceof Array) {
      if (value.length > 1)
        return FieldRenderer.multipleLocations;
      else
        value = value[0];
    }
    if (!value?.name) {
      return this._emptyPlaceholder;
    }
    return value.name.toUpperCase();
  }

  renderCityLocationTitle(value: CityLocation|CityLocation[]): string {
    if (value instanceof Array) {
      if (value.length > 1)
        return FieldRenderer.multipleLocations;
      else
        value = value[0];
    }
    if (!value?.city) {
      return this._emptyPlaceholder;
    }
    return value.city.toUpperCase();
  }

  private readonly _emptyPlaceholder = "–";

  renderDimensionValue(value: DimensionValue | DimensionValue [] | null): string {
    if (value instanceof Array)
       return this.renderStringArray(value.map(this.renderDimensionValue))
    if (!value )
      return this._emptyPlaceholder;
    return `${value.value.toFixed(3).toString()} ${value.dimension}`;
  }

  renderDateValue(value: DateContainer | null): string {
    if (!value)
      return this._emptyPlaceholder;
    if (value.actual)
    return this._formatDate(value.actual);
    if (value.estimated)
      return `<span class="estimated-date"><span class="material-icons">schedule</span>${this._formatDate(value.estimated)}</span>`;
    return this._emptyPlaceholder;
  }

  renderDateTitle(value: DateContainer | null): string {
    if (!value)
      return this._emptyPlaceholder;
    if (value.actual)
      return this._formatDate(value.actual);
    if (value.estimated)
      return this._formatDate(value.estimated);
    return this._emptyPlaceholder;
  }

  renderEventValue(value: ShipmentEvent | null): string {
    if (!value)
      return this._emptyPlaceholder;
    return `${value.description}, <span class="text-light">${this._formatDate(value.actualDate)}</span>`
  }

  renderEventTitle(value: ShipmentEvent | null): string {
    if (!value)
      return this._emptyPlaceholder;
    return `${value.description}, ${this._formatDate(value.actualDate)}`
  }

  renderDecimal(value: number | DimensionValue, precision = 3): string {
    const number = typeof value === "object"
                   ? value.value
                   : value;
    return number ? number.toFixed(precision).toString() : this._emptyPlaceholder;
  }

  private _formatDate(value: Date) {
    return formatDate(value, window.dateShortFormat.replace(/m/g, "M"), this._localeId)
  }

  private sanitize(value: string): SafeHtml {
    return this._domSanitizer.bypassSecurityTrustHtml(value);
  }

  renderDeliveryStatusValue(value: DeliveryStatus): string {
    const cssClass = deliveryStatusClassMapper[value];
    const displayName = deliveryStatusDisplayMapper[value];
    return `<span class="status ${cssClass}">${displayName}</span>`;
  }

  renderDangerousGoodsTitle(value: Grouping<DangerousGoodsData, string>[]): string {
    if (!value || value.any())
      return this._emptyPlaceholder;
    return value.map(d => `UN ${d.key}`).join(", ") || this._emptyPlaceholder;
  }

  renderDeliveryStatusName(value: DeliveryStatus): string {
    return deliveryStatusDisplayMapper[value];
  }

  renderDeliveryStatusClass(value: DeliveryStatus): string {
    return deliveryStatusClassMapper[value];
  }

  renderPhone(phone: PhoneWithCountry): string {
    if (!phone)
      return this._emptyPlaceholder;
    const country = this._countryService.getByIsoAlpha2(phone.countryCode);
    return this.renderCountryFlag({ name: country.common, isoAlpha2: country.isoAlpha2 }) + phone.number
  }
}
