import {Site} from "./Site";
import {Modal} from "@widgets/modal";

require("cropperjs")

export module BlueUser {
    export let site: Site;

    export function AddEvents(root: JQuery): void {
        const $avatarUpload = $(".avatar-upload");
        if ($avatarUpload.is())
            new Avatar($avatarUpload);

        const $entitySelect = $("#entity-select");
        if ($entitySelect.length) {
            const $userType = $(".user-details input[name=\"user-type\"]");
            const validationField = $entitySelect.data("validation-field");
            const $permissionsList = $("#entity-list");
            validationField.validateData.validators["entities"] = {
                errorMessage: "Entity Set is required.",
                isActive: () => {
                    return $userType.val() === "CLIENT";
                },
                validator: () => {
                    console.log($permissionsList
                                    .find(
                                        "input[type=\"checkbox\"]:not([name^=ExportToExcel]):checked, input[type=\"hidden\"]:not([name^=ExportToExcel]):not(:disabled)"));
                    return $permissionsList
                        .find(
                            "input[type=\"checkbox\"]:not([name^=ExportToExcel]):checked, input[type=\"hidden\"]:not([name^=ExportToExcel]):not(:disabled)")
                        .is();
                }
            };
            // validationField.validateData.validators.required.isActive = function () {
            //     return $userType.val() === "CLIENT";
            // };
            validationField.validateData.errorMessageElement = $(document.createElement("p"))
                .addClass("error-message")
                .hide()
                .appendTo($entitySelect.closest(".select-item-container"));
            $permissionsList.on("change", () => {
                validationField.validate();
            });
            // validationField.onValidated.on(function (e) {
            //    if (!e.data)
            //        self.site.openAlert('error', 'The user could not be saved. Please check the form for errors.', 5000, false);
            // });
        }
    }
}

interface AvatarOptions {
    hintText?: string;
    imgError?: string;
}

export class Avatar {
    static DEFAULTS: AvatarOptions = {
        hintText: "Drop your picture here or click to upload",
        imgError: "Please choose image for uploading process"
    };

    options: AvatarOptions;

    private $img: JQuery;
    private $hint: JQuery;
    private $input: JQuery;
    private modal: Modal;

    constructor($element: JQuery, options?: AvatarOptions) {
        this.options = $.extend({}, Avatar.DEFAULTS, options);
        this.init($element);
    }

    init($element: JQuery): void {
        const picture: string = $element.data("picture");

        // Create hint box
        this.$hint = $(document.createElement("div"))
            .addClass("hint")
            .html(`<div><p>${this.options.hintText}</p></div>`)
            .toggleClass("invisible", !!picture);

        // Create image element
        this.$img = $(document.createElement("img"))
            .attr("src", picture)
            .addClass("avatar-picture");


        // Create remove link
        const $avRemove = $(document.createElement("a"))
            .addClass("avatar-remove")
            .text("Remove")
            .on("click", e => this.remove(e));

        // Create input element for upload after crop
        this.$input = $(document.createElement("input"))
            .attr({
                      name: $element.attr("data-name"),
                      type: "hidden"
                  });
        if (picture) {
            this.$input.val(picture.split(",")[1]);
        }

        // Crate input element for image upload to cropper
        const $avInputPictureUpload =
            $(document.createElement("input"))
                .attr({
                          type: "file",
                          accept: "image/*"
                      })
                .css({
                         width: "0",
                         height: "0",
                         visibility: "hidden"
                     })
                .on("change", (event: JQueryEventObject) => {
                    const input = event.delegateTarget as HTMLInputElement;
                    if (input.files.length > 0) {
                        this.upload(input.files[0]);
                        input.value = "";
                    }
                });

        $element.append(
            this.$hint,
            this.$img,
            $avRemove,
            this.$input,
            $avInputPictureUpload
        );

        $element
            .on("click", () => {
                $avInputPictureUpload[0].click();
                this.$hint.css("opacity", 0.8);
                const handler = () => {
                    if (this.modal && this.modal.isOpen())
                        return;
                    this.$hint.css("opacity", "");
                    $(window).off("mousemove touchstart focus", handler);
                };
                $(window).on("mousemove touchstart focus", handler);
            })
            .on("dragover", (event: JQueryEventObject) => {
                event.preventDefault();
            })
            .on("drop", (e) => this.drop(e));

        this.initCrop();
    }

    initCrop(): void {

        // Create cropbox container
        const $cropbox = $(document.createElement("div"))
            .addClass("cropbox")
            .attr("id", "cropbox");

        // Create image container element
        const $image = $(document.createElement("img"))
            .attr("id", "cropbox-image")
            .appendTo($cropbox);

        // Initiate cropper with sittings
        let cropper: Cropper;
        $image.cropper({
                           dragMode: "move",
                           movable: false,
                           zoomable: false,
                           guides: false,
                           background: false,
                           cropBoxResizable: true,
                           aspectRatio: 1,
                           ready: () => {
                               $image.cropper("zoomTo", 1);
                               let imageData = $image.cropper("getImageData");
                               $image.cropper("zoomTo", 1);
                               imageData = $image.cropper("getImageData");
                               // const canvasSize = Math.min(300,
                               //                             Math.max(imageData.naturalHeight, imageData.naturalWidth));
                               // $image.next().css({
                               //                       height: canvasSize,
                               //                       width: canvasSize
                               //                   });
                               // // cropper.reset();
                               // cropper.setCanvasData({height: canvasSize, width: canvasSize});
                               const cropBoxWidth = Math.min(imageData.width, imageData.height);
                               const containerData = $image.cropper("getContainerData");

                               $image.cropper("setCropBoxData", {
                                   left: (containerData.width - cropBoxWidth) / 2,
                                   top: (containerData.height - cropBoxWidth) / 2,
                                   width: cropBoxWidth
                               });

                               this.modal.openModal();
                           }
                       });
        cropper = $image.data("cropper");
        this.modal = new Modal(null, {
            header: null,
            contentElement: $cropbox,
            buttons: [
                // Create OK button
                $(document.createElement("a"))
                    .addClass("button")
                    .text("OK")
                    .on("click", () => this.acceptCrop()),

                // Create CANCEL button
                $(document.createElement("a"))
                    .addClass("button secondary")
                    .append("Cancel")
                    .on("click", () => this.modal.closeModal())
            ]
        });
    }

    drop(event: JQueryEventObject): void {
        const files = (event.originalEvent as DragEvent).dataTransfer.files;
        if (files)
            this.upload(files[0]);
        event.preventDefault();
    }

    upload(file: File): void {
        $("#cropbox-image")
            .cropper("replace", window.URL.createObjectURL(file));
    }

    acceptCrop(): void {
        const $image = $("#cropbox-image");
        const canvas = $image.cropper("getCroppedCanvas");

        if (canvas.width > 200)
            canvas.resizeTo(200, 200);

        const base64 = canvas.toDataURL();
        this.$input.val(base64.split(",")[1]);

        this.$img.attr("src", base64);
        this.$img
            .siblings(".hint")
            .addClass("invisible");

        this.modal.closeModal();
    }

    remove(event: JQueryEventObject): void {
        event.stopPropagation();
        this.$img.attr("src", "");
        this.$input.val("");
        this.$hint.removeClass("invisible");
    }
}
