<aside class="sidebar-menu mobile-menu-sidebar" [ngClass]="{open: isOpen}">
  <div class="sidebar-overlay" (click)="close()"></div>
  <div class="sidebar">
    <ng-container *ngIf="navMenuQuery.forwarding$ | async">
      <header>
        <p class="mat-icon-public">Forwarding</p>
      </header>
      <ul>
        <li *ngIf="navMenuQuery.shipments$ | async" [ngClass]="{active: shipmentsActive | async}">
          <a [routerLink]="shipmentListUrl">Shipments</a>
        </li>
        <li *ngIf="navMenuQuery.orders$ | async" [ngClass]="{active: ordersActive | async}">
          <a [routerLink]="orderListUrl">Orders</a>
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="navMenuQuery.userManagement$ | async">
      <header>
        <p class="mat-icon-person">User management</p>
      </header>
      <ul>
        <li [ngClass]="{active: usersActive | async}" *ngIf="navMenuQuery.users$ | async">
          <a [routerLink]="userListUrl">Users</a>
        </li>
        <li [ngClass]="{active: entitiesActive | async}" *ngIf="navMenuQuery.entities$ | async">
          <a [routerLink]="entityListUrl">Entities</a>
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="navMenuQuery.administration$ | async">
      <header>
        <p class="mat-icon-settings">Administration</p>
      </header>
      <ul>
        <li [ngClass]="{active: settingsActive | async}">
          <a [routerLink]="settingsUrl">Settings</a>
        </li>
        <li [ngClass]="{active: onlineMonitorActive | async}">
          <a [routerLink]="onlineMonitorUrl">Online Monitor</a>
        </li>
      </ul>
    </ng-container>
  </div>
  <div id="sidebar-extender"></div>
</aside>
