import {Directive, ElementRef, Renderer2, forwardRef, Provider} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms"
import * as Utils from "../utils/Utils";

const NUMBER_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomNumberValueAccessor),
    multi: true
};
@Directive({
    selector:
        'input[type=number][formControlName]:not([ngNumericInput]),'
            + 'input[type=number][formControl]:not([ngNumericInput]),'
            + 'input[type=number][ngModel]:not([ngNumericInput])',
    host: {
        '(change)': 'onChange($event.target.value)',
        '(input)': 'onChange($event.target.value)',
        '(blur)': 'onTouched()'
    },
    providers: [NUMBER_VALUE_ACCESSOR]
})
export class CustomNumberValueAccessor implements ControlValueAccessor {
    onChange = (_: any) => { };
    onTouched = () => { };

    constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {

    }

    writeValue(value: number): void {
        // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
        let normalizedValue: string | number = value == null ? '' : value;
        if (typeof normalizedValue === "number") {
            const decimalPrecision =
                parseInt((this._elementRef.nativeElement as HTMLInputElement).getAttribute("data-decimal-precision"));
            if (!isNaN(decimalPrecision))
                normalizedValue = Math.round(normalizedValue, decimalPrecision).toFixed(decimalPrecision);
        }
        this._renderer.setProperty(this._elementRef.nativeElement, 'value', normalizedValue);
    }

    registerOnChange(fn: (_: number | null) => void): void {
        this.onChange = (value) => { fn(value === '' ? null : Utils.ParseNumber(value)); };
    }
    registerOnTouched(fn: () => void): void { this.onTouched = fn; }

    setDisabledState(isDisabled: boolean): void {
        this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
    }
}
