import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rt-change-password-complete',
  templateUrl: './change-password-complete.component.html',
  styleUrls: ['./change-password-complete.component.scss']
})
export class ChangePasswordCompleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
