import {Injectable} from "@angular/core";
import {Store, StoreProjectionMapping} from "@app/stores/store";
import {ShipmentStoreState} from "@app/stores/shipmentStoreState";
import {ShipmentOverviewItem} from "@app/page-components/shipment/shipment-list/shipment-overview-item";

export type ShipmentStoreProjection = {
  shipmentTitle: string | null;
  shipmentOverviewList: ShipmentOverviewItem[];
  showLoadMoreButton: boolean;
  filterCount: number;
  noResultsFound: boolean;
  viewCustomizerOverviewItem: ShipmentOverviewItem;
};

@Injectable({providedIn: "root"})
export class ShipmentStore extends Store<ShipmentStoreState, ShipmentStoreProjection> {

  private static readonly _initialState: Partial<ShipmentStoreState> = {
    currentSearch: {},
    shipmentList: null,
    isLoadingMore: false,
    isSearching: false,
    firstShipmentInEntitySet: null
  }
  private static readonly _projectionMapping: StoreProjectionMapping<ShipmentStoreState, ShipmentStoreProjection> = {
    shipmentTitle: ({currentShipment}) => {
      if (!currentShipment)
        return null;
      else
        return currentShipment.houseBill || "T.B.D.";
    },
    shipmentOverviewList: u => {
      return u.shipmentList?.map(s => new ShipmentOverviewItem(s)) || []
    },
    showLoadMoreButton: u =>
      !u.isSearching &&
      u.shipmentList &&
      u.shipmentList.any() &&
      u.moreShipmentsAvailable,

    filterCount: ({currentSearch}: Partial<ShipmentStoreState>) => {
      if (!currentSearch)
        return 0;
      const {
        bookedByRohlig,
        bookedByUserIds,
        containerMode,
        destinationCountryCodes,
        endDate,
        originCountryCodes,
        portsOfDischargeUnLocodes,
        portsOfLoadingUnLocodes,
        startDate,
        status,
        transportMode
      } = currentSearch;
      let count = [
        containerMode,
        destinationCountryCodes,
        originCountryCodes,
        portsOfDischargeUnLocodes,
        portsOfLoadingUnLocodes,
        status,
        transportMode
      ]
        .filter(a => a && a.any())
        .length;
      if (startDate || endDate)
        count++;
      if (bookedByRohlig || bookedByUserIds && bookedByUserIds.any())
        count++;
      return count;
    },
    noResultsFound: state => !state.isSearching && state.shipmentList && !state.shipmentList.any(),
    viewCustomizerOverviewItem: s => {
      let viewCustomizerItem = s.shipmentList?.firstOrDefault() || s.firstShipmentInEntitySet;
      if (!viewCustomizerItem)
        return null;
      return new ShipmentOverviewItem(viewCustomizerItem);
    }

  }

  constructor() {
    super(ShipmentStore._projectionMapping, ShipmentStore._initialState);
  }

  getName(): string {
    return "ShipmentStore";
  }
}
