import {Routes} from "@angular/router";
import {
  BookingViewData, ContainerDetailViewData,
  EntityEditViewData,
  EntityListViewData,
  ErrorViewData,
  OnlineMonitorViewData,
  OrderDetailViewData,
  OrderListViewData,
  ProfileViewData,
  SettingsViewData,
  ShipmentDetailViewData,
  ShipmentListViewData,
  UserDetailViewData,
  UserEditViewData,
  UserListViewData
} from "@view-data";
import {DefaultRedirect, HasAdministrationRights, HasBookingRights, HasOrderRights, HasShipmentRights} from "@guards";
import {
  SettingsComponent,
  UnexpectedErrorComponent,

} from "@page-components";
import {ViewManager} from "@booking/ViewManager";
import {BookingComponent} from "@booking/booking.component";
import {MainMasterComponent} from "@master-components";
import {transformRoutesObject} from "@app/utils/case-insensitive-route-matcher";
import {ViewArrangement} from "@booking/booking-views";
import {ActivationService} from "@booking/ActivationService";
import {OnlineMonitorComponent} from "@app/page-components/administration/online-monitor/online-monitor.component";
import {ShipmentListComponent} from "@app/page-components/shipment/shipment-list/shipment-list.component";
import {ShipmentDetailComponent} from "@app/page-components/shipment/shipment-detail/shipment-detail.component";
import {OrderListComponent} from "@app/page-components/order/order-list/order-list.component";
import {OrderDetailsComponent} from "@app/page-components/order/order-details/order-details.component";
import {MyProfileComponent} from "@app/page-components/my-profile/my-profile.component";
import {UserListComponent} from "@app/page-components/account/user-list/user-list.component";
import {UserDetailComponent} from "@app/page-components/account/user-detail/user-detail.component";
import {UserEditComponent} from "@app/page-components/account/user-edit/user-edit.component";
import {EntityListComponent} from "@app/page-components/Entities/entity-list/entity-list.component";
import {EntityEditComponent} from "@app/page-components/Entities/entity-edit/entity-edit.component";
import {ContainerDetailComponent} from "../page-components/shipment/container-details/container-detail.component";

function addAccessRights(routes: Routes) {
  routes
    .filter(r => !!r.component)
    .forEach(r => {
      r.canActivate = [ActivationService];
      r.canDeactivate = [ActivationService];
    });
  return routes;
}

export const MainRoutes: Routes = [
  {
    path: "",
    component: MainMasterComponent,
    children: [
      {
        path: "Shipment",
        pathMatch: "full",
        component: ShipmentListComponent,
        data: {viewData: new ShipmentListViewData()},
        canActivate: [HasShipmentRights]
      },
      {
        canActivate: [HasShipmentRights],
        path: "Shipment/ShipmentDetail",
        component: ShipmentDetailComponent,
        data: {viewData: new ShipmentDetailViewData(null, null)}
      },
      {
        canActivate: [HasShipmentRights],
        path: "Shipment/ContainerDetail",
        component: ContainerDetailComponent,
        data: {viewData: new ContainerDetailViewData(null)}
      },
      {
        path: "Account",
        children: [
          {
            path: "MyProfile",
            component: MyProfileComponent,
            data: {viewData: new ProfileViewData()}
          },
          {
            path: "Users",
            component: UserListComponent,
            data: {viewData: new UserListViewData()}
          },
          {
            path: "AddUser",
            component: UserEditComponent,
            data: {viewData: new UserEditViewData()}
          },
          {
            path: "EditUser",
            component: UserEditComponent,
            data: {viewData: new UserEditViewData()}
          },
          {
            path: "ViewUser",
            component: UserDetailComponent,
            data: {viewData: new UserDetailViewData(null)}
          },
          {
            path: "ChangePassword",
            canActivate: [DefaultRedirect],
            component: UnexpectedErrorComponent,
            data: {viewData: new ErrorViewData("UnexpectedError")}
          },
          {
            path: "ResetPassword",
            canActivate: [DefaultRedirect],
            component: UnexpectedErrorComponent,
            data: {viewData: new ErrorViewData("UnexpectedError")}
          },
          {
            path: "ChangePasswordComplete",
            canActivate: [DefaultRedirect],
            component: UnexpectedErrorComponent,
            data: {viewData: new ErrorViewData("UnexpectedError")}
          },
          {
            path: "Login",
            canActivate: [DefaultRedirect],
            component: UnexpectedErrorComponent,
            data: {viewData: new ErrorViewData("UnexpectedError")}
          },
          {
            path: "",
            pathMatch: "full",
            canActivate: [DefaultRedirect],
            component: UnexpectedErrorComponent,
            data: {viewData: new ErrorViewData("UnexpectedError")}
          }
        ]
      },
      {
        path: "Order",
        pathMatch: "full",
        component: OrderListComponent,
        data: {viewData: new OrderListViewData()},
        canActivate: [HasOrderRights],
        children: []
      },
      {
        path: "Order/OrderDetail",
        component: OrderDetailsComponent,
        data: {viewData: new OrderDetailViewData(null)}
      },
      {
        path: "SiteManagement",
        pathMatch: "full",
        component: EntityListComponent,
        data: {viewData: new EntityListViewData()},
      },
      {
        path: "SiteManagement/AddSite",
        component: EntityEditComponent,
        data: {viewData: new EntityEditViewData()}
      },
      {
        path: "SiteManagement/SiteDetails",
        component: EntityEditComponent,
        data: {viewData: new EntityEditViewData()}
      },
      {
        path: "Settings",
        component: SettingsComponent,
        data: {viewData: new SettingsViewData()},
        canActivate: [HasAdministrationRights]
      },
      {
        path: "Admin",
        component: OnlineMonitorComponent,
        data: {viewData: new OnlineMonitorViewData()},
        canActivate: [HasAdministrationRights]
      },
      {
        path: "Booking",
        component: BookingComponent,
        data: {viewData: new BookingViewData("Service")},
        children: transformRoutesObject(addAccessRights(ViewManager.GenerateRoutes(ViewArrangement))),
        canActivate: [HasBookingRights]
      },
      {
        path: "Shipment",
        redirectTo: new ErrorViewData("AccessDenied").url
      },
      {
        path: "Order",
        redirectTo: new ErrorViewData("AccessDenied").url
      },
      {
        path: "SiteManagement",
        redirectTo: new ErrorViewData("AccessDenied").url
      },
      {
        path: "Settings",
        redirectTo: new ErrorViewData("AccessDenied").url
      },
      {
        path: "Admin",
        redirectTo: new ErrorViewData("AccessDenied").url
      }
    ]
  },
];
