import {Injectable} from "@angular/core";
import {Store, StoreProjectionMapping} from "@stores/store";
import {UserGroup, UserSaveRequest} from "@api";

interface UserEditStoreProjections {
  canHaveAdminCountries: boolean;
}

@Injectable({providedIn: "any"})
export class UserEditStore extends Store<UserSaveRequest, UserEditStoreProjections> {
  getName(): string {
    return "UserEditStore";
  }

  private static _projections: StoreProjectionMapping<UserSaveRequest, UserEditStoreProjections> = {
    canHaveAdminCountries: u => (u.group === UserGroup.LocalAdmin || u.group === UserGroup.AccountManager)
  }


  constructor() {
    super(UserEditStore._projections, {});
  }
}
